/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Displays a warning message when the chat connection is lost
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.ChatWarning
 */

import React, { FC } from "react";
import { useStrings } from "~/hooks";
import styles from "./ChatWarning.module.scss";

export enum ChatWarningTestIds {
	self = "ChatWarning",
}

enum TokenNames {
	chatWarningBannerText = "ChatWarningBannerText",
}

export const ChatWarning: FC = () => {
	const strings = useStrings("ChatWarning", Object.values(TokenNames));
	return (
		<div aria-live="polite" data-testid={ChatWarningTestIds.self} className={styles["ChatWarning"]}>
			{strings[TokenNames.chatWarningBannerText]}
		</div>
	);
};
