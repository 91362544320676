/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Manages the state of the chat messages in the application
 * @author Noah Allen
 * @module Epic.VideoApp.State.Messages
 */

import { IMessage } from "@epic/chat";
import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";

/// TYPES

export interface IMessageState {
	messages: IMessage[];
	numUnreadMessages: number;
	lastToastId: number;
	numberOfFailedMessagesInARow: number;
}

/// INIT ///

export function getInitialState(): IMessageState {
	return {
		messages: [],
		numUnreadMessages: 0,
		lastToastId: -1,
		numberOfFailedMessagesInARow: 0,
	};
}

/// REDUCERS ///

export function addMessage(state: IMessageState, newMessage: IMessage): IMessageState {
	const existingMessage = state.messages.find((m) => m.messageId === newMessage.messageId);

	if (existingMessage) {
		return state;
	}
	const newMessages = [...state.messages];
	const position = binarySearch(newMessages, newMessage.sequentialId);
	newMessages.splice(position, 0, newMessage);
	const newState = updateHasUnreadMessage(state, true);
	return { ...newState, messages: newMessages };
}

export function setLastToastId(state: IMessageState, seqId: number): IMessageState {
	return { ...state, lastToastId: seqId };
}

export function updateHasUnreadMessage(state: IMessageState, hasUnreadMessage: boolean): IMessageState {
	let newNumUnreadMessages = 0;
	if (hasUnreadMessage) {
		newNumUnreadMessages = state.numUnreadMessages + 1;
	}
	return { ...state, numUnreadMessages: newNumUnreadMessages };
}

export function updateMessage(
	state: IMessageState,
	updatedMessage: Partial<IMessage> & { messageId: string },
): IMessageState {
	const existingMessage = state.messages.find((m) => m.messageId === updatedMessage.messageId);

	const messageFailed = updatedMessage.messageDeliveryStatus === "failed";

	if (existingMessage) {
		let updatedMessageArray;
		if (existingMessage.sequentialId !== updatedMessage.sequentialId) {
			updatedMessageArray = state.messages.filter(
				(message) => message.messageId !== existingMessage.messageId,
			);
			const updatedMessageWithId = { ...existingMessage, ...updatedMessage };
			const position = binarySearch(updatedMessageArray, updatedMessageWithId.sequentialId);
			updatedMessageArray.splice(position, 0, updatedMessageWithId);
		} else {
			// If sequentialId is the same, just update the properties without changing the order
			updatedMessageArray = state.messages.map((message) =>
				message.messageId === existingMessage.messageId ? { ...message, ...updatedMessage } : message,
			);
		}
		const newState = {
			...state,
			messages: updatedMessageArray,
		};

		if (messageFailed) {
			newState.numberOfFailedMessagesInARow++;
		}

		return newState;
	} else {
		console.warn("Edit message called, but it doesn't exist in the state.");
		return state;
	}
}

function addOrUpdateMessage(state: IMessageState, newOrUpdatedMessage: IMessage): IMessageState {
	const { messageId, ...otherParamsOfMessage } = newOrUpdatedMessage;
	const existingMessage = state.messages.find((m) => m.messageId === newOrUpdatedMessage.messageId);

	if (existingMessage) {
		return updateMessage(state, { messageId, ...otherParamsOfMessage });
	} else {
		return addMessage(state, newOrUpdatedMessage);
	}
}

export function addOrUpdateMessages(
	state: IMessageState,
	newOrUpdatedMessageList: IMessage[],
): IMessageState {
	let newState = state;
	newOrUpdatedMessageList.forEach((message) => {
		newState = addOrUpdateMessage(newState, message);
	});
	return newState;
}

export function resetNumberOfFailedMessagesInARow(state: IMessageState): IMessageState {
	return { ...state, numberOfFailedMessagesInARow: 0 };
}

/// SELECTORS ///

export function getMessages(state: IMessageState): IMessage[] {
	return state.messages;
}

export function getNumUnreadMessages(state: IMessageState): number {
	return state.numUnreadMessages;
}

export function getNumberOfFailedMessagesInARow(state: IMessageState): number {
	return state.numberOfFailedMessagesInARow;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		addMessage,
		updateMessage,
		updateHasUnreadMessage,
		addOrUpdateMessages,
		setLastToastId,
		resetNumberOfFailedMessagesInARow,
	},
	selectors: {
		getMessages,
		getNumUnreadMessages,
		getNumberOfFailedMessagesInARow,
	},
});

store.addSharedState(builtState.sharedState, "messages");

export const {
	actionCreators: messageActions,
	useSharedState: useMessageState,
	sharedState: state,
} = builtState;

function binarySearch(messages: IMessage[], sequentialId: number | null): number {
	if (sequentialId === null) {
		return messages.length;
	}
	let left = 0;
	let right = messages.length - 1;
	while (left <= right) {
		const mid = left + Math.floor((right - left) / 2);
		const midSequentialId = messages[mid].sequentialId !== null ? messages[mid].sequentialId : Infinity;
		if (midSequentialId !== null && midSequentialId < sequentialId) {
			left = mid + 1;
		} else if (midSequentialId !== null && midSequentialId > sequentialId) {
			right = mid - 1;
		} else {
			return mid;
		}
	}
	return left;
}
