/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file hook to determine the volume level of a stream
 * @author Tara Feldstein
 * @module Epic.VideoApp.Hooks.UseVolumeLevels
 */

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";
import { useLocalTrackState } from "~/state";
import { isIOS } from "~/utils/os";
import { IStream } from "~/web-core/interfaces";

/**
 * Whenever we create a new source node, we store it in this interface
 * so we can clean up cloned media streams when required
 */
interface ICloneableSourceNode {
	isCloned: boolean;
	sourceNode: MediaStreamAudioSourceNode;
}

/**
 * Create a potentially cloned ICloneableSourceNode from a MediaStreamTrack, cloning the track if it is local
 * @param context AudioContext in which to create the node
 * @param track MediaStreamTrack used as the source for the MediaStreamAudioSourceNode
 * @param isLocalTrack boolean to indicate whether the track should be cloned first (true) or not (false)
 * @returns a MediaStreamAudioSourceNode
 */
function createSourceNode(
	context: AudioContext,
	track: MediaStreamTrack,
	isLocalTrack: boolean,
): ICloneableSourceNode {
	if (isLocalTrack) {
		// A clone is created to allow multiple instances of this component for a single AudioTrack on iOS Safari.
		// It is stored in the returned ref so that the cloned track can be stopped.
		// We should stop the clone whenever the audio analyzer is torn down or reinitialized see isolateNodes().
		return {
			isCloned: true,
			sourceNode: context.createMediaStreamSource(new MediaStream([track.clone()])),
		};
	}
	// RemoteAudioTracks: no need to clone https://bugs.chromium.org/p/chromium/issues/detail?id=1338973#c11
	return { isCloned: false, sourceNode: context.createMediaStreamSource(new MediaStream([track])) };
}
/**
 * Create an AnalyserNode from a MediaStreamAudioSourceNode
 * @param context AudioContext in which to create the node
 * @returns an AnalyserNode
 */
function createAnalyserNode(context: AudioContext | null): AnalyserNode | null {
	if (!context) {
		return null;
	}
	const analyser = context.createAnalyser();
	analyser.fftSize = fftSize;
	analyser.maxDecibels = -10;
	analyser.minDecibels = -110;
	analyser.smoothingTimeConstant = 0.25;

	return analyser;
}

// AudioAnalyser param; smaller size => less precision but smaller Uint8Array to process
const fftSize = 256;

/** Output Interface */
interface IVolumeLevelData {
	volumeLevel: number;
	isTrackEnabled: boolean;
}

/**
 * Get the volume level, enabled state, and flags indicating whether we've recently (or ever) detected audio
 * @param stream Stream object to get the volume level of
 * @returns IVolumeLevelData:
 * 		{ volumeLevel isTrackEnabled }
 */
export function useVolumeLevels(stream?: IStream): IVolumeLevelData {
	const [volumeLevel, setVolumeLevel] = useState<number>(0);
	const isLocalTrackRef = useRef<boolean>(stream ? "toggleState" in stream : false);
	const mediaStreamTrack = stream?.getMediaStreamTrack("audio");

	const { audioContext } = useContext(DeviceContext);
	const audioContextRunning = useLocalTrackState((sel) => sel.getIsAudioContextRunning(), []);

	const isTrackEnabled = mediaStreamTrack?.enabled ?? false;

	const sourceNodeRef = useRef<ICloneableSourceNode | null>(null);
	const analyserNodeRef = useRef<AnalyserNode | null>(null);
	const sampleArrayRef = useRef<Uint8Array>(new Uint8Array(fftSize / 2));
	const frameRef = useRef<number>();

	useEffect(() => {
		// Keep an active reference for whether the current track is local or not
		isLocalTrackRef.current = stream?.isLocal ?? false;
	}, [stream]);

	/**
	 *	Disconnect and dereference analyserNodeRef.current to avoid wasting CPU cycles.
	 *	Also cleans up the sourceNodeRef of any cloned MediaStreamTracks
	 */
	const isolateNodes = (): void => {
		if (sourceNodeRef.current) {
			sourceNodeRef.current.sourceNode.disconnect();
			// Clean-up any tracks on a sourceNode that were cloned.
			// This ensures we don't leak any tracks when switching which participant is being monitored
			// Or when the audio analyzer is torn down (on disconnect, on stream share end, etc.)
			if (sourceNodeRef.current.isCloned) {
				const mediaStream = sourceNodeRef.current.sourceNode.mediaStream;
				mediaStream.getAudioTracks().forEach((track) => {
					track.stop();
					mediaStream.removeTrack(track);
				});
				sourceNodeRef.current = null;
			} else if (!isLocalTrackRef.current) {
				// Remote audio analyser source nodes can be removed without stopping the track
				// Stopping a remote audio track (that isn't closed) will cause audio to stop being played for the remote participant
				sourceNodeRef.current = null;
			}
		}

		// For Local or Remote, disconnect & dereference the analyserNode
		if (analyserNodeRef.current) {
			analyserNodeRef.current.disconnect();
			analyserNodeRef.current = null;
		}
	};

	/**	Calculate volume for recent period. Update state / refs on new volumeLevel
	 	Calculation algorithm "inspired by" Twilio's reference app */
	const calculateVolume = useCallback(() => {
		if (!isTrackEnabled) {
			setVolumeLevel(0);
			return;
		}
		if (!analyserNodeRef.current || document.hidden) {
			return;
		}

		analyserNodeRef.current.getByteFrequencyData(sampleArrayRef.current);
		let values = 0;
		const length = sampleArrayRef.current.length;

		for (let i = 0; i < length; i++) {
			values += sampleArrayRef.current[i];
		}

		const volume = Math.min(10, Math.max(0, Math.log10(values / length / 3) * 7));

		frameRef.current = window.requestAnimationFrame(() => {
			setVolumeLevel(volume);
		});
	}, [isTrackEnabled]);

	/**	Inflate audio nodes and/or set up the MediaStream source */
	const initializeRefs = useCallback((): void => {
		if (!mediaStreamTrack || !audioContext || !audioContextRunning || audioContext.state === "closed") {
			return;
		}

		if (sourceNodeRef.current) {
			// unplug any existing nodes referencing a media stream to prevent orphaned references
			isolateNodes();
		}
		//Initialize the source node & analyser node
		sourceNodeRef.current = createSourceNode(audioContext, mediaStreamTrack, isLocalTrackRef.current);

		analyserNodeRef.current = createAnalyserNode(audioContext);
		sourceNodeRef.current?.sourceNode.connect(analyserNodeRef.current as AnalyserNode);
	}, [mediaStreamTrack, audioContext, audioContextRunning]);

	/** Handle AudioContext closing: whenever we create a new AudioContext we need to re-create all nodes
	 * throughout the app from the new context. So we teardown the old nodes on the closed state change
	 */
	useEffect(() => {
		if (!audioContextRunning && (!audioContext || audioContext.state === "closed")) {
			isolateNodes();
		}
	}, [audioContext, audioContextRunning]);

	/** Handle source audioTrack 'stopped' event */
	useEffect(() => {
		/** Listen for the "stopped" event on the audioTrack (LocalAudioTrack only). When the audioTrack is stopped,
		 *  we should stop the cloned track that feeds the sourceNodeRef. It is important that we stop all tracks
		 *  when they aren't in use. Browsers like Firefox don't let you create a stream from a new audio device
		 *  while the active audio device still has active tracks. We can only stop the clones when the source stops (due
		 *  to https://bugs.chromium.org/p/chromium/issues/detail?id=1338973) so leave the listener attached until then.
		 */
		if (isLocalTrackRef.current) {
			const handleStopped = (): void => {
				isolateNodes();
			};

			mediaStreamTrack?.addEventListener("stopped", handleStopped);

			return () => {
				isolateNodes();
				mediaStreamTrack?.removeEventListener("stopped", handleStopped);
			};
		}
	}, [mediaStreamTrack]);

	/** Set up & tear down animation */
	useEffect(() => {
		if (mediaStreamTrack && isTrackEnabled) {
			initializeRefs();

			// reinitialize the MediaStream and AnalyserNode on focus to avoid an issue in Safari where
			// analysers stop functioning when the user switches to a new tab and switches back to the app
			if (isIOS()) {
				window.addEventListener("focus", initializeRefs);
			}
			const handle = window.setInterval(calculateVolume, 125);
			return () => {
				window.clearInterval(handle);
				if (frameRef.current) {
					window.cancelAnimationFrame(frameRef.current);
				}
				if (isIOS()) {
					window.removeEventListener("focus", initializeRefs);
				}
				isolateNodes();
				setVolumeLevel(0);
			};
		} else {
			isolateNodes();
			setVolumeLevel(0);
		}
	}, [mediaStreamTrack, isTrackEnabled, initializeRefs, calculateVolume]);

	/** Add proper teardown effect */
	useEffect(() => {
		return () => {
			isolateNodes();
			if (frameRef.current) {
				window.cancelAnimationFrame(frameRef.current);
			}
		};
	}, []);

	return { volumeLevel, isTrackEnabled };
}
