/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interfaces for typed event emitter class
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Events.Interfaces.EvcEmitter
 */

export interface IEVCEvent<TType> {
	type: TType;
}

export interface IEVCEmitter<TEventMap> {
	on: <TEventType extends keyof TEventMap & string>(
		type: TEventType,
		handler: (args: TEventMap[TEventType]) => void,
	) => void;

	once: <TEventType extends keyof TEventMap & string>(
		type: TEventType,
		handler: (args: TEventMap[TEventType]) => void,
	) => void;

	off: <TEventType extends keyof TEventMap & string>(
		type: TEventType,
		handler: (args: TEventMap[TEventType]) => void,
	) => void;

	removeAllListeners: (type?: keyof TEventMap & string) => void;

	emit: <TEventType extends keyof TEventMap & string>(
		type: TEventType,
		event: TEventMap[TEventType],
	) => void;

	listenerCount: (event: keyof TEventMap & string) => number;
}
