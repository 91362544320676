/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Custom global functions exposed in App.tsx
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Utils.WindowGlobals
 */

/**
 * Check used by embedded mobile views to determine if a user is connected to their video call.
 * Used to determine whether to show a warning pop-up before closing the view in mobile apps.
 * @returns True if the user is connected to a video call, false otherwise
 */
export const embeddedMobileConnectedCheck = (): boolean => window.session.connectionStatus === "connected";

/**
 * Check to see whether EVC is being shown within a mobile embedded view from an Epic mobile app.
 * @returns True if the website is being shown within a mobile embedded view
 */
export const isEmbeddedMobileView = (): boolean => {
	return !!window?.Android?.closeWindow || !!window?.webkit?.messageHandlers?.closeWindow;
};

/**
 * Try to close the embedded mobile view, if EVC is being displayed inside one.
 * If EVC is shown embedded in a mobile application, will cause the view to close.
 * Otherwise, will do nothing.
 */
export const tryCloseEmbeddedMobileView = (): void => {
	if (window?.Android?.closeWindow) {
		window.Android.closeWindow();
	} else if (window?.webkit?.messageHandlers?.closeWindow?.postMessage) {
		window.webkit.messageHandlers.closeWindow.postMessage("");
	}
};
