/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interface for a remote video user
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.RemoteUser
 */

import { Quality, SubscriptionStatus, VideoType } from "../types";
import { IUser } from "./user";

/**
 * @interface IRemoteUser
 * @extends IUser
 * @description Interface for a remote user in the video application. It extends the IUser interface and adds
 * properties to control subscription settings for attached streams.
 */
export interface IRemoteUser extends IUser {
	readonly isLocal: false;

	/**
	 * Set the quality of the remote deviceStream. This may be unused on non-Daily vendors.
	 * @param quality Enumerable quality value between low and high
	 */
	setStreamQuality(quality: Quality): void;

	/**
	 * Set whether a remote stream is being subscribed to.
	 * @param type Whether to target the participant's camera or screen share streams
	 * @param subscribed What status to set the subscription to
	 */
	setStreamSubscribed(type: VideoType, subscribed: SubscriptionStatus): void;
}
