/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file The chat view component for the video app
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.ChatView
 */

import {
	ChatScrollingSection,
	CurrentlyTypingUsers,
	MessageComposer,
	MessageList,
	UserInteractionMessages,
} from "@epic/chat";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useStrings } from "~/hooks";
import { MAX_MESSAGE_LENGTH } from "~/types/chat";
import { isIOS } from "~/utils/os";
import { isEmbeddedMobileView } from "~/utils/windowGlobals";
import { VideoSessionContext } from "~/web-core/components";
import { useChatUserState, useMessageState, useSettingsState } from "../../state";
import { ChatDisclaimer } from "./ChatDisclaimer";
import { ChatWarning } from "./ChatWarning";
import ChatWrapper from "./ChatWrapper";
import { ConnectionContext } from "./ConnectionManagement";
import { useChatConnectionStatus } from "./hooks/useChatConnectionStatus";
import { useChatHeartbeat } from "./hooks/useChatHeartbeat";
import { useChatMessageHandling } from "./hooks/useChatMessageHandling";
import { useChatSendMessagesCallbacks } from "./hooks/useChatSendMessagesCallbacks";

interface IChatViewProps {
	isOpen: boolean;
}

enum TokenNames {
	newMessage = "NewMessage",
	unknownSenderName = "UnknownSenderName",
	systemSenderName = "SystemSenderName",
	sentMessageStatus = "SentMessageStatus",
	selfSenderName = "SelfSenderName",
	sendingMessageStatus = "SendingMessageStatus",
	retryMessageStatus = "RetryMessageStatus",
	singleUserIsTyping = "SingleUserIsTyping",
	multipleUsersAreTyping = "MultipleUsersAreTyping",
	blankMessageError = "BlankMessageError",
	overLimitCharacterCounter = "OverLimitCharacterCounter",
	underLimitCharacterCounter = "UnderLimitCharacterCounter",
	placeholderMessage = "PlaceholderMessage",
	sendButtonText = "SendButtonText",
}

/**
 * The chat view component for the video app that contains
 * the business logic and uses the chat client library
 * @param isOpen Whether the chat sidebar is open
 */
const ChatView: FC<IChatViewProps> = ({ isOpen }) => {
	// --------------- Users handling ---------------
	const { session } = useContext(VideoSessionContext);
	const localUserId = session?.localUser?.getUserIdentity() ?? "";

	const { chatConnectionStatus } = useContext(ConnectionContext);
	const usersTyping = useChatUserState((selectors) => selectors.getTypingUsersArray(), []);
	const [shouldShowChatWarning, setShouldShowChatWarning] = useState(false);
	const chatUserMap = useChatUserState((selectors) => selectors.getUsers(), []);

	useChatConnectionStatus(localUserId, chatConnectionStatus, setShouldShowChatWarning);

	// --------------- End Users handling ---------------

	// --------------- Messages handling hooks/callbacks ---------------

	const [pendingMessages, setPendingMessages] = useState<string[]>([]);
	const [lastSequentialId, setLastSequentialId] = useState<number>(0);
	const sortedMessages = useMessageState((selectors) => selectors.getMessages(), []);
	const sendHeartbeatMessage = useChatHeartbeat(Math.floor(lastSequentialId || 0));
	const [shouldReadNewMessageAriaAlert, setShouldReadNewMessageAriaAlert] = useState(false);

	const [mostRecentUserMessageIdCallback, isLastMessageFromSelfCallback] = useChatMessageHandling({
		sortedMessages,
		setLastSequentialId,
		pendingMessages,
		setPendingMessages,
		sendHeartbeatMessage,
		setShouldReadNewMessageAriaAlert,
	});

	// --------------- End Message Handling ---------------

	// --------------- Sending Messages Hooks/Callbacks ---------------

	const [shouldSendBeEnabled, setShouldSendBeEnabled] = useState(false);

	const {
		onUserTrySendNewMessage,
		onRetrySendMessage,
		onNewMessageTextChange,
		canSendMessage,
		handleScrollToEnd,
	} = useChatSendMessagesCallbacks({
		localUserId,
		lastSequentialId,
		setShouldSendBeEnabled,
		setPendingMessages,
	});

	// --------------- End Sending Messages Hooks/Callbacks ---------------

	// --------------- UI Hooks ---------------
	const locale = useSettingsState((selectors) => selectors.getLocale(), []);
	const isIos = isIOS();

	//This is used to ensure that we scroll all the way down when the chat is opened
	// to fix a bug where saying "scroll to the bottom of this ref" was scrolling 50px above the bottom
	const END_OF_SCREEN = 9e9;

	//This handles scrolling down and focusing whenever the chat is reopened
	const maximizeScrollPositionRef = useRef(0);
	useEffect(() => {
		const scrollChat = (): void => {
			const chatScrollingSection = document.getElementById("ChatScrollingSection");
			if (chatScrollingSection) {
				if (!isOpen) {
					maximizeScrollPositionRef.current = chatScrollingSection.scrollTop;
				}
				if (isIos) {
					// On iOS, use scrollTop
					chatScrollingSection.scrollTop = END_OF_SCREEN;
				} else {
					// On other platforms, use scroll
					chatScrollingSection.scroll(0, maximizeScrollPositionRef.current);
				}
			}
		};

		// Delay the scroll operation to allow the chat content to render
		const timer = setTimeout(scrollChat, isIos ? 20 : 1);
		return () => clearTimeout(timer);
	}, [isIos, isOpen]);

	// --------------- End UI Hooks ---------------

	// --------------- Strings ---------------
	const strings = useStrings("ChatView", Object.values(TokenNames));

	const newMessageString = strings[TokenNames.newMessage];
	const chatScrollingStrings = { newMessage: newMessageString };

	const unknownSenderNameString = strings[TokenNames.unknownSenderName];
	const selfSenderNameString = strings[TokenNames.selfSenderName];
	const systemSenderNameString = strings[TokenNames.systemSenderName];
	const determineSenderNameStrings = {
		unknown: unknownSenderNameString,
		self: selfSenderNameString,
		system: systemSenderNameString,
	};

	const sentMessageStatusString = strings[TokenNames.sentMessageStatus];
	const sendingMessageStatusString = strings[TokenNames.sendingMessageStatus];
	const retryMessageStatusString = strings[TokenNames.retryMessageStatus];
	const messageStatusStrings = {
		sent: sentMessageStatusString,
		sending: sendingMessageStatusString,
		retryButtonText: retryMessageStatusString,
	};

	const singleUserIsTypingString = strings[TokenNames.singleUserIsTyping];
	const multipleUsersAreTypingString = strings[TokenNames.multipleUsersAreTyping];
	const currentlyTypingUsersStrings = {
		typing: singleUserIsTypingString,
		multiple: multipleUsersAreTypingString,
	};

	const throttledSendMessageErrorString = "";
	const blankMessageErrorString = strings[TokenNames.blankMessageError];
	const determineSendMessageErrorStrings = {
		throttled: throttledSendMessageErrorString,
		blankMessage: blankMessageErrorString,
	};

	const overLimitCharacterCounterString = strings[TokenNames.overLimitCharacterCounter];
	const underLimitCharacterCounterString = strings[TokenNames.underLimitCharacterCounter];
	const characterCounterStrings = {
		overLimit: overLimitCharacterCounterString,
		underLimit: underLimitCharacterCounterString,
	};

	const placeholderMessageString = strings[TokenNames.placeholderMessage];
	const sendButtonTextString = strings[TokenNames.sendButtonText];
	const messageComposerStrings = { placeholder: placeholderMessageString, send: sendButtonTextString };

	// --------------- End Strings ---------------

	return (
		<ChatWrapper>
			<ChatDisclaimer />
			<ChatScrollingSection
				numberOfMessages={sortedMessages.length}
				isLastMessageFromSelf={isLastMessageFromSelfCallback()}
				isChatOpen={isOpen}
				shouldNotDisplayNewMessage={false}
				onScrolledToTheEnd={handleScrollToEnd}
				shouldLoadFromBottom
				strings={chatScrollingStrings}
			>
				<MessageList>
					<UserInteractionMessages
						allowRetry
						sortedMessages={sortedMessages}
						chatUsers={chatUserMap}
						mostRecentUserMessageId={mostRecentUserMessageIdCallback()}
						onMessageRetry={onRetrySendMessage}
						shouldNotDisplayMessages={false}
						shouldRenderAllLinks
						shouldShowRetryIcon={false}
						strings={[determineSenderNameStrings, messageStatusStrings]}
						locale={locale}
						shouldForceLinksOpenInNewTab
						shouldLastMessageBeTabbable
						shouldOverrideRenderNoLinks={isEmbeddedMobileView()}
					/>
				</MessageList>
				<CurrentlyTypingUsers usersTyping={usersTyping} strings={currentlyTypingUsersStrings} />
			</ChatScrollingSection>
			{shouldShowChatWarning && <ChatWarning />}
			<MessageComposer
				messageCharacterLimit={MAX_MESSAGE_LENGTH}
				onUserTrySendNewMessage={onUserTrySendNewMessage}
				onNewMessageTextChange={onNewMessageTextChange}
				shouldSendBeEnabled={chatConnectionStatus === "Connected" && shouldSendBeEnabled}
				validateMessage={canSendMessage}
				isThrottled={false}
				strings={[determineSendMessageErrorStrings, characterCounterStrings, messageComposerStrings]}
			/>
			<div aria-live="assertive" role="status" style={{ position: "absolute", left: "-9999px" }}>
				{shouldReadNewMessageAriaAlert ? newMessageString : ""}
			</div>
		</ChatWrapper>
	);
};

export default ChatView;
