/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Button to show options menu in the participant name bar
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Moderation.ShowMoreButton
 */

import React, { FC } from "react";
import BaseButton from "~/components/Utilities/BaseButton";
import { useStrings } from "~/hooks";
import ThreeDots from "~/icons/threeDots";
import { useUserState } from "~/state/user";
import { isMobile } from "~/utils/os";
import { isPictureInPictureSupported } from "~/utils/pictureInPicture";

/**
 * Props for ShowMoreButton Component
 */
interface IShowMoreButtonProps {
	onClick: () => void;
	className: string;
	isLocal?: boolean;
	pressed: boolean;
	isTrackEnabled?: boolean;
}

enum TokenNames {
	ariaLabel = "AriaLabel",
	title = "Title",
}

/**
 * The SaveButton component
 * @param props - the props
 */
const ShowMoreButton: FC<IShowMoreButtonProps> = (props) => {
	const { onClick, className, isLocal, pressed, isTrackEnabled } = props;
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);
	const strings = useStrings("ShowMoreButton", Object.values(TokenNames));

	// Don't render the button or the accompanying menu,
	// 1. on mobile
	// 2. for local participant
	// 3. a. when we don't allow moderator actions and
	// 3. b. if the browser doesn't support PiP or the video/screen share tracks are not enabled
	if (isMobile() || isLocal || (!canModerate && !(isPictureInPictureSupported() && isTrackEnabled))) {
		return null;
	}
	return (
		<BaseButton
			aria-label={strings[TokenNames.ariaLabel]}
			rawClass={className}
			onClick={onClick}
			icon={ThreeDots}
			aria-pressed={pressed}
			title={strings[TokenNames.title]}
		/>
	);
};

ShowMoreButton.displayName = "ShowMoreButton";

export default ShowMoreButton;
