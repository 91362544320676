/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Implements a rate limiting mechanism to limit the number of negotiation attempts within a certain time window.
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.Hooks.UseCanGetToken
 */

import { BACK_OFF_TIMES_PER_RETRY } from "~/types/chat";
import { secondsToMs } from "~/utils/dateTime";

export async function canGetToken(
	lastCallTime: React.MutableRefObject<number>,
	callCounter: React.MutableRefObject<number>,
): Promise<boolean> {
	const now = Date.now();

	if (callCounter.current > 15) {
		return false;
	}

	if (lastCallTime.current === 0) {
		lastCallTime.current = now;
		callCounter.current += 1;
		return true;
	}

	const timeSinceLastCall = now - lastCallTime.current;
	const WINDOW_DURATION = secondsToMs(BACK_OFF_TIMES_PER_RETRY[callCounter.current]);

	if (timeSinceLastCall < WINDOW_DURATION) {
		const timeToWait = WINDOW_DURATION - timeSinceLastCall;
		await new Promise((resolve) => setTimeout(resolve, timeToWait));
	}

	lastCallTime.current = Date.now();
	callCounter.current += 1;

	return true;
}
