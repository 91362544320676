/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to initialize disconnection event handlers
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.UseDisconnectHandler
 */
import { useCallback, useEffect, useRef } from "react";
import { useDisconnect, useStrings } from "~/hooks";
import { useLeavePageHandler } from "./useLeavePageHandler";

export function useDisconnectHandler(): void {
	const tokenNames = ["DisconnectWarning"];
	const strings = useStrings("useDisconnectHandler", tokenNames);
	const stringsRef = useRef(strings);
	useEffect(() => {
		stringsRef.current = strings;
	}, [strings]);

	// Log the disconnect back to cosmos
	const disconnect = useDisconnect();

	const doDisconnect = useCallback(() => {
		disconnect(false);
	}, [disconnect]);

	// Listen to the "beforeunload" event on window to leave the Room when the tab/browser is being closed.
	// change per https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#Syntax
	const onWarning = useCallback((event: BeforeUnloadEvent) => {
		event = event || window.event;
		//User is not disconnected yet, return a message so the browser will warn the user before navigating away
		//Most browsers override this text and may block the attempt to display if the user has not interacted with the page
		if (event) {
			event.returnValue = stringsRef.current["DisconnectWarning"];
		}
		return stringsRef.current["DisconnectWarning"];
	}, []);

	useLeavePageHandler(onWarning, doDisconnect);
}
