/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Output audio through a Web Audio processing chain
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Components.Participants.Audio.WebAudioOutput
 */
/* eslint-disable compat/compat */

import { FC, useContext, useEffect, useRef } from "react";
import { LocalAudioTrack, RemoteAudioTrack } from "twilio-video";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";
import { useMediaStreamTrack } from "~/hooks";
import { useLocalTrackState } from "~/state";
import { iOSWebAudioGainValue } from "~/utils/audio";

/**
 * Props for WebAudioOutput Component
 */
interface IProps {
	track: LocalAudioTrack | RemoteAudioTrack;
	enabled: boolean;
}

/**
 * The WebAudioOutput component
 * @param props The props ;)
 */
const WebAudioOutput: FC<IProps> = (props) => {
	const { track, enabled } = props;
	const mediaStreamTrack = useMediaStreamTrack(track);

	const { audioContext: context, overrideIOSRoute } = useContext(DeviceContext);
	const audioContextRunning = useLocalTrackState((sel) => sel.getIsAudioContextRunning(), []);

	const sourceNodeRef = useRef<MediaStreamAudioSourceNode | null>(null);
	const gainNodeRef = useRef<GainNode | null>(null);

	/** Teardown helper function */
	const handleStopped = (): void => {
		if (sourceNodeRef.current) {
			sourceNodeRef.current.mediaStream.getAudioTracks().forEach((t) => t.stop());
			sourceNodeRef.current.disconnect();
			sourceNodeRef.current = null;
		}
		if (gainNodeRef.current) {
			gainNodeRef.current.disconnect();
			gainNodeRef.current = null;
		}
	};

	useEffect(() => {
		track.on("stopped", handleStopped);
		return () => {
			track.off("stopped", handleStopped);
			handleStopped();
		};
	}, [track]);

	useEffect(() => {
		if (!mediaStreamTrack) {
			return;
		}
		mediaStreamTrack.addEventListener("ended", handleStopped);
		return () => {
			mediaStreamTrack.removeEventListener("ended", handleStopped);
		};
	}, [mediaStreamTrack]);

	useEffect(() => {
		handleStopped();

		if (!mediaStreamTrack || !context || !audioContextRunning || !enabled || overrideIOSRoute) {
			return;
		}

		// Create SourceNode
		sourceNodeRef.current = context.createMediaStreamSource(new MediaStream([mediaStreamTrack.clone()]));

		// Create GainNode
		gainNodeRef.current = context.createGain();
		gainNodeRef.current.gain.value = iOSWebAudioGainValue;

		// Connect SourceNode -> GainNode -> AudioContext.destination
		sourceNodeRef.current.connect(gainNodeRef.current);
		gainNodeRef.current.connect(context.destination);

		return () => {
			handleStopped();
		};
	}, [context, mediaStreamTrack, enabled, overrideIOSRoute, audioContextRunning]);

	return null;
};

WebAudioOutput.displayName = "WebAudioOutput";

export default WebAudioOutput;
