/**
 * @copyright Copyright 2021 - 2024 Epic Systems Corporation
 * @file SpinnerIcon component
 * @author Razi Rais
 * @module Epic.VideoApp.Components.Loading.SpinnerIcon
 */

import React, { FC } from "react";
import styles from "./SpinnerIcon.module.scss";

/**
 * Props for the SpinnerIcon Component
 */
interface IProps {
	small?: boolean;
}

export enum SpinnerIconTestIds {
	self = "SpinnerIcon",
}

/**
 * The SpinnerIconIcon component
 */
const SpinnerIcon: FC<IProps> = (props) => {
	const { small } = props;

	const spinnerClass = small ? styles["spinnerIconSmall"] : styles["spinnerIconLarge"];

	//The following returns a div containing an array of 12 divs.
	// Each of the 12 divs corresponds to a "spoke" on the Spinner wheel
	return (
		<div className={spinnerClass} data-testid={SpinnerIconTestIds.self}>
			{[...Array(12).keys()].map((key) => (
				<div key={key} />
			))}
		</div>
	);
};

SpinnerIcon.displayName = "SpinnerIcon";

export default SpinnerIcon;
