/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file button to start sharing a user's screen
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.ScreenShareButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useScreenShareToggle, useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import { uiActions, useUserState } from "~/state";
import { resolveClassName } from "~/utils/className";
import { getScreenShareIcon } from "~/utils/device";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import styles from "../ControlsHeader.module.scss";
import ControlButton from "./ControlButton";

/**
 * Props for ScreenShareButton component
 */
interface IProps {
	/** Whether or not the button should be rendered without a label */
	noLabel?: boolean;

	/** Whether or not the button should be rendered without a tooltip */
	noTooltip?: boolean;
}

enum TokenNames {
	startSharingScreen = "StartSharingScreen",
	stopSharingScreen = "StopSharingScreen",
	label = "Label",
	locked = "Locked",
	notEnabled = "NotEnabled",
}

/**
 * The ScreenShareButton component
 * @param props The props ;)
 */
const ScreenShareButton: FC<IProps> = (props) => {
	const { noLabel, noTooltip } = props;
	// canShareScreen checks if a user has had screen sharing disabled in FDI configuration, where screenShareLocked is for in-call moderation
	const [canShareScreen, screenShareLocked] = useUserState(
		(selectors) => [selectors.getUserPermission("canShareScreen"), selectors.getScreenShareLock()],
		[],
	);
	const [isSharing, toggleScreenSharing] = useScreenShareToggle();

	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const dispatch = useDispatch();
	const screenShareSupport = useIsScreenShareSupported();

	const strings = useStrings("ScreenShareButton", Object.values(TokenNames));

	// wrapper to ignore the onClick handler's event param
	const onClick = useCallback(() => toggleScreenSharing(), [toggleScreenSharing]);
	const onDisabledClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			if (inWaitingRoom) {
				return;
			}
			event?.nativeEvent.stopImmediatePropagation();
			dispatch(uiActions.setRequestMenuOpen(true));
		},
		[dispatch, inWaitingRoom],
	);

	const shareKeyboardShortcut = ["alt", "s"];
	const labelBase = strings[getTooltipToken(isSharing, screenShareLocked, inWaitingRoom)];
	const label = useGetFormattedHotkeyString(labelBase, shareKeyboardShortcut);

	// check that screen sharing is supported and that the user has permissions before showing the button
	if (!screenShareSupport || !canShareScreen) {
		return null;
	}
	const screenShareDisabled = screenShareLocked || inWaitingRoom;

	const icon = getScreenShareIcon(isSharing, screenShareLocked);

	const buttonClassName = resolveClassName(styles, {
		buttonDisabled: inWaitingRoom,
	});

	return (
		<ControlButton
			ariaLabel={label}
			keyboardShortcut={shareKeyboardShortcut}
			icon={icon}
			onClick={screenShareDisabled ? onDisabledClick : onClick}
			pressed={isSharing}
			buttonText={!noLabel ? strings[TokenNames.label] : undefined}
			tooltipText={!noTooltip ? label : undefined}
			buttonClassName={buttonClassName}
		/>
	);
};

function getTooltipToken(isSharing: boolean, isLocked: boolean, inWaitingRoom: boolean): string {
	if (isLocked) {
		return TokenNames.locked;
	}
	if (inWaitingRoom) {
		return TokenNames.notEnabled;
	}
	return isSharing ? TokenNames.stopSharingScreen : TokenNames.startSharingScreen;
}

ScreenShareButton.displayName = "ScreenShareButton";

export default ScreenShareButton;
