/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Refactor event listeners to handle attempted navigation away from the page
 * @author Max Harkins
 * @module Epic.VideoApp.Hooks.UseLeavePageHandler
 */
import { useEffect } from "react";

/**
 * Hook to handle when users leave the site. The hook listens to events from the user's browser, and executes a callback function if it detects the user
 * has chosen to leave the page or in the process of navigating away.
 * @param onWarning Function which triggers a warning for a user before they leave the page.
 * @param onLeave Function to attempt any clean up before the user leaves the page. The user cannot return to the page at the point this is called, and cleanup is not guaranteed to succeed.
 */
export function useLeavePageHandler(
	onWarning: (event: BeforeUnloadEvent) => void,
	onLeave: () => void,
): void {
	// setup event listeners for page close / navigation away
	useEffect(() => {
		window.addEventListener("onpopstate", onLeave);
		// Listen to the "beforeunload" event when the tab/browser is being closed. At this point, the event can still be cancelled.
		// change per https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#Syntax
		window.addEventListener("beforeunload", onWarning);
		// per MDN https://developer.mozilla.org/en-US/docs/Web/API/Window/unload_event
		// the 'unload' event should not be used due to bfcache issues (cause of #1033 / QAN 6417930)
		// better to listen to 'pagehide' events per above
		window.addEventListener("pagehide", onLeave);

		// Unregister all of the listeners before navigating away from the component calling the hook
		return () => {
			window.removeEventListener("onpopstate", onLeave);
			window.removeEventListener("beforeunload", onWarning);
			window.removeEventListener("pagehide", onLeave);
		};
	}, [onLeave, onWarning]);
}
