/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Shared hook to check page size to safely include the participants button in the header
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Hooks.UseShowParticipantButtonInHeader
 */

import { useWindowSize } from "~/hooks";
export const MinWidthForParticipantsInHeader = 1000;

/**
 * Checks the page width to determine if the button may show
 * @returns whether or not the current page is wide enough to show the participant button in the header
 */
export function useShowParticipantButtonInHeader(): boolean {
	const { width } = useWindowSize();
	return width >= MinWidthForParticipantsInHeader;
}
