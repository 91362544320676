/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Initial feedback question showing happy/sad faces
 * @author Max Harkins
 * @module Epic.VideoApp.Components.FeedbackSurvey.FeedbackIntroQuestion
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import HappySmiley from "~/icons/happySmiley";
import SadSmiley from "~/icons/sadSmiley";
import { combinedActions } from "~/state";
import { feedbackSurveyActions } from "~/state/feedbackSurvey";
import { FeedbackPageStep, FeedbackReaction } from "~/types/survey";
import CardButton from "../Utilities/CardButton";
import styles from "./FeedbackIntroQuestion.module.scss";
import { useFeedbackTimeoutWarning } from "./hooks/useFeedbackTimeoutWarning";
import { useSaveUserReaction } from "./hooks/useSaveUserReaction";

enum TokenNames {
	positiveLabel = "PositiveLabel",
	negativeLabel = "NegativeLabel",
	feedbackLinkText = "FeedbackLinkText",
}

/**
 * The QuestionGroup component
 */
const FeedbackIntroQuestion: FC = () => {
	const iconSize = 75;

	// Handle clearing survey state when survey times out
	useFeedbackTimeoutWarning();

	const dispatch = useDispatch();
	const saveReaction = useSaveUserReaction();
	const strings = useStrings("IntroQuestion", Object.values(TokenNames));

	const positiveButtonAction = useCallback(() => {
		void saveReaction(FeedbackReaction.positive, false);
		dispatch(combinedActions.clearSurveyState(true));
	}, [dispatch, saveReaction]);

	const negativeButtonAction = useCallback(() => {
		void saveReaction(FeedbackReaction.negative, false);
		dispatch(feedbackSurveyActions.setFeedbackStep(FeedbackPageStep.survey));
	}, [dispatch, saveReaction]);

	return (
		<div className={styles["buttonContainer"]}>
			<CardButton onClick={positiveButtonAction} tone="positive">
				<HappySmiley height={iconSize} width={iconSize} aria-hidden="true" />
				<div id="positiveLabel">{strings[TokenNames.positiveLabel]}</div>
			</CardButton>
			<CardButton onClick={negativeButtonAction} tone="negative">
				<SadSmiley height={iconSize} width={iconSize} aria-hidden="true" />
				<div id="negativeLabel">{strings[TokenNames.negativeLabel]}</div>
			</CardButton>
		</div>
	);
};

FeedbackIntroQuestion.displayName = "FeedbackIntroQuestion";

export default FeedbackIntroQuestion;
