/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Hook to setup an interval to re-request peer information until it is received
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Hooks.UseRequestPeerInfoInterval
 */

import { useCallback, useEffect, useRef } from "react";
import { INamelessParticipantData } from "~/components/VideoCall/hooks/useNamelessParticipantData";
import { useRequestParticipantInfo, useSendNamelessParticipantSyncRequest } from "~/hooks/messaging";
import { IParticipantInfo } from "~/types";
import { secondsToMs } from "~/utils/dateTime";

export const ParticipantInfoIntervalMs = secondsToMs(1);

/**
 * Hook that sets up an interval to re-request peer information until it is received
 * @param participantIdentity The identity of the participant to request information for
 * @param namelessParticipantData The currently received nameless participant data
 * @param participantInfo The currently received participant info
 */
export function useRequestPeerInfoInterval(
	participantIdentity: string,
	namelessParticipantData: INamelessParticipantData,
	participantInfo: IParticipantInfo | null,
): void {
	const sendNamelessParticipantSyncRequest = useSendNamelessParticipantSyncRequest();
	const namelessRef = useRef<INamelessParticipantData>(namelessParticipantData);

	const requestParticipantInfo = useRequestParticipantInfo();
	const participantInfoRef = useRef(participantInfo);

	const makeInfoRequest = useCallback(() => {
		// If we do not have a valid entry of participant info for a given user, make a request
		// Does not need a response
		if (!participantInfoRef.current) {
			requestParticipantInfo(participantIdentity);
		}

		// If nameless participant data hasn't been shared, make a request
		if (!namelessRef.current?.dataIsShared) {
			sendNamelessParticipantSyncRequest(namelessParticipantData);
		}
	}, [
		namelessParticipantData,
		participantIdentity,
		requestParticipantInfo,
		sendNamelessParticipantSyncRequest,
	]);

	// Update participant info reference when we get a value from a remote party
	useEffect(() => {
		participantInfoRef.current = participantInfo;
	}, [participantInfo]);

	// Update information from nameless participants
	useEffect(() => {
		namelessRef.current = namelessParticipantData;
	}, [namelessParticipantData]);

	const requestRef = useRef(makeInfoRequest);
	// Track updates to the request function as well
	useEffect(() => {
		requestRef.current = makeInfoRequest;
	}, [makeInfoRequest]);

	// Run this timer to periodically request info broadcast -- after we have participant info, stop.
	useEffect(() => {
		if (!participantInfoRef.current || !namelessRef.current?.dataIsShared) {
			// Send an initial request
			requestRef.current();

			const newIntervalId = setInterval(() => {
				// Request until we have information, then clear the interval
				if (!participantInfoRef.current || !namelessRef.current?.dataIsShared) {
					requestRef.current();
				} else {
					clearInterval(newIntervalId);
				}
			}, ParticipantInfoIntervalMs);
			return () => {
				clearInterval(newIntervalId);
			};
		}
	}, [namelessParticipantData.dataIsShared]);
}
