/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interface for local daily media.
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Implementations.DailyLocalStream
 */

import Daily, {
	DailyCall,
	DailyCallOptions,
	DailyCameraErrorObject,
	DailyCameraErrorType,
	DailyEventObjectCameraError,
	DailyEventObjectParticipant,
	DailyEventObjectTrack,
	DailyTrackState,
} from "@daily-co/daily-js";
import { IDeviceInitialization } from "~/state/hardwareTest";
import { IDimensions } from "~/types";
import { BackgroundSettings } from "~/types/backgrounds";
import { captureImage } from "~/utils/imageCapture";
import { EVCEmitter, IEVCStreamEventMap } from "~/web-core/events";
import { constrainToString } from "~/web-core/functions";
import { ILocalStream } from "~/web-core/interfaces";
import { DeviceKind, ISwitchDeviceResponse, VideoType } from "~/web-core/types";
import {
	isDailyCamError,
	isDailyMicError,
	makeDailyCamErrorGeneric,
	validateAndReportInvalidDevices,
} from "../functions/dailyErrorUtils";
import { getMediaDeviceInfoFromDailyDeviceInfos, isTrackEnabled } from "../functions/utils";

export class DailyLocalStream extends EVCEmitter<IEVCStreamEventMap> implements ILocalStream {
	call: DailyCall;
	videoDevice?: DailyTrackState;
	audioDevice?: DailyTrackState;
	// This is used for screen share tracks to allow us to publish custom tracks with the screen share API
	mediaStream?: MediaStream;
	readonly isLocal: true = true;
	deviceInitializationError?: IDeviceInitialization;
	private _type: VideoType;
	// It's necessary to internally track the in-use device info because when applying a virtual background the
	// MediaStreamTrack attached to the persistentTrack becomes a stand-in track that lacks real info
	private _deviceInfo: Record<DeviceKind, MediaDeviceInfo | undefined>;
	private _onCustomCallParticipantUpdatedHandler: (event: DailyEventObjectParticipant) => void;
	private _backgroundInformation: {
		settings: BackgroundSettings | null;
		arrayBuffer: ArrayBuffer | null;
		isApplied: boolean;
	};

	/**
	 * DailyLocalStream constructor
	 * @param type The type of stream to create (either camera or screen share)
	 * @param customDailyCall A custom DailyCall object to use for the stream.
	 *                        Should only be used for instances where the stream is not attached to the
	 *                        DailyCall object used by the session (e.g. background preview)
	 */
	constructor(type?: VideoType, customDailyCall?: DailyCall) {
		super();
		this._onCustomCallParticipantUpdatedHandler = this.handleCustomCallParticipantUpdated.bind(this);
		if (customDailyCall) {
			this.call = customDailyCall;
			this.call.on("participant-updated", this._onCustomCallParticipantUpdatedHandler);
		} else {
			this.call =
				Daily.getCallInstance() ?? Daily.createCallObject({ subscribeToTracksAutomatically: false });
		}
		this._backgroundInformation = { settings: null, arrayBuffer: null, isApplied: false };
		this._type = type ?? "camera";
		this._deviceInfo = { audio: undefined, video: undefined };
	}

	getDeviceInitializationError(): IDeviceInitialization | undefined {
		return this.deviceInitializationError;
	}

	async cleanUp(): Promise<void> {
		// Turn off the background for camera streams
		if (this._type === "camera") {
			await this.call.updateInputSettings({ video: { processor: { type: "none" } } });
		}
		this.videoDevice?.persistentTrack?.stop();
		this.audioDevice?.persistentTrack?.stop();
		this.videoDevice?.track?.stop();
		this.audioDevice?.track?.stop();
		this.call.off("participant-updated", this._onCustomCallParticipantUpdatedHandler);
	}

	hasAudio(): boolean {
		return !!this.audioDevice?.persistentTrack;
	}

	renderVideo(element: HTMLVideoElement): HTMLVideoElement | null {
		if (this.videoDevice?.persistentTrack) {
			element.srcObject = new MediaStream([this.videoDevice.persistentTrack]);
			element.load();
		}

		element.onresize = this.handleResizeEvent.bind(this);
		return element;
	}

	cleanupVideo(element: HTMLVideoElement): HTMLVideoElement | null {
		element.srcObject = null;
		element.onresize = null;
		return element;
	}

	attachAudio(element: HTMLAudioElement): HTMLAudioElement | null {
		if (this.audioDevice?.persistentTrack) {
			element.srcObject = new MediaStream([this.audioDevice?.persistentTrack]);
			element.load();
		}

		return element;
	}

	detachAudio(element: HTMLAudioElement): HTMLAudioElement | null {
		element.srcObject = null;
		return element;
	}

	async initialize(
		videoId?: string,
		audioId?: string,
	): Promise<DailyCameraErrorObject<DailyCameraErrorType>[] | undefined> {
		// Call to StartCamera initializes the hardware test
		const options: DailyCallOptions = {};
		void this.call.updateSendSettings({ video: "bandwidth-and-quality-balanced" });
		if (videoId) {
			options.videoSource = videoId;
		}

		if (audioId) {
			options.audioSource = audioId;
		}

		const hwError: DailyCameraErrorObject<DailyCameraErrorType>[] = [];
		const onError = (event: DailyEventObjectCameraError | undefined): void => {
			if (event?.error) {
				hwError.push(event?.error);
			}
		};
		this.call.on("camera-error", onError);

		const deviceInfo = await this.call.startCamera(options);
		this._deviceInfo.video = getMediaDeviceInfoFromDailyDeviceInfos(deviceInfo, "camera");
		this._deviceInfo.audio = getMediaDeviceInfoFromDailyDeviceInfos(deviceInfo, "mic");

		// Since Daily is only reporting one HW error at a time using "camera-error" event, we need to check for missing media separately so it's reported accurately in the HW test page
		validateAndReportInvalidDevices(deviceInfo, hwError);

		const localUser = this.call.participants().local;
		this.videoDevice = localUser.tracks.video;
		this.audioDevice = localUser.tracks.audio;

		this.call.off("camera-error", onError);
		return hwError;
	}

	getDeviceId(kind: DeviceKind): string {
		return this._deviceInfo[kind]?.deviceId ?? "";
	}

	getDeviceName(kind: DeviceKind): string {
		return this._deviceInfo[kind]?.label ?? "";
	}

	async switchVideoDeviceAsync(device: MediaDeviceInfo): Promise<ISwitchDeviceResponse> {
		const deviceId = device.deviceId;

		const info = await this.call.setInputDevicesAsync({
			videoDeviceId: deviceId,
			audioDeviceId: null,
		});

		const newDeviceInfo = getMediaDeviceInfoFromDailyDeviceInfos(info, "camera");

		// Don't attempt to publish if we don't have info about the new device
		if (!newDeviceInfo) {
			const error = {
				name: "CameraAcquisitionFailed",
				message: "Failed to acquire a camera",
			};
			this.call.setLocalVideo(false);
			return { result: false, switchedDevices: false, error: error };
		}

		// Apply the current background effect before turning the camera on to prevent a flicker showing the real background
		await this.applyVideoBackground(
			this._backgroundInformation.settings,
			this._backgroundInformation.arrayBuffer ?? undefined,
		);

		// If local video was already enabled, then setInputDevicesAsync already validated the camera switch, so we can return
		if (this.call.localVideo()) {
			// If we requested a new device but received a different device, then the switch failed
			if (newDeviceInfo.deviceId !== deviceId) {
				const error = { name: "CameraSwitchFailed", message: "Failed to switch to new camera" };
				return {
					result: false,
					switchedDevices: false,
					error: error,
				};
			}
			this._deviceInfo.video = newDeviceInfo;
			return { result: true, switchedDevices: false, error: undefined };
		}

		// Otherwise, we need to wait for a track-started or error event to confirm the switch was successful
		return this.initializeDevice(newDeviceInfo, deviceId);
	}

	async removeLocalVideoTrack(): Promise<string | undefined> {
		if (this.call.isDestroyed()) {
			return undefined;
		}
		const oldDevice = this._deviceInfo.video?.deviceId;
		this.call.setLocalVideo(false);
		this.emit("videoDisabled", { type: "videoDisabled" });

		await this.call.setInputDevicesAsync({
			videoDeviceId: undefined,
			audioDeviceId: null,
		});
		// Update internal references
		this.videoDevice = this.call.participants().local?.tracks?.video;
		return oldDevice;
	}

	isEnabled(kind: DeviceKind): boolean {
		const result =
			kind === "audio"
				? isTrackEnabled(this.audioDevice?.persistentTrack)
				: isTrackEnabled(this.videoDevice?.persistentTrack);
		return result ?? false;
	}

	toggleState(kind: DeviceKind, turnOn: boolean): void {
		if (this._type === "camera") {
			if (kind === "audio") {
				this.call = this.call.setLocalAudio(turnOn);
				if (turnOn) {
					if (this.call.localAudio() && this.audioDevice?.persistentTrack?.enabled) {
						this.emit("audioEnabled", { type: "audioEnabled" });
					}
				} else {
					this.emit("audioDisabled", { type: "audioDisabled" });
				}
			}

			if (kind === "video") {
				this.call = this.call.setLocalVideo(turnOn);
				if (turnOn) {
					if (this.call.localVideo()) {
						this.emit("videoEnabled", { type: "videoEnabled" });
					}
				} else {
					this.emit("videoDisabled", { type: "videoDisabled" });
				}
			}
		} else {
			if (kind === "audio") {
				this.call.updateScreenShare({
					screenVideo: { enabled: true },
					screenAudio: { enabled: turnOn },
				});
				if (turnOn) {
					this.emit("audioEnabled", { type: "audioEnabled" });
				} else {
					this.emit("audioDisabled", { type: "audioDisabled" });
				}
			}
			// No current workflow temporarily disables screen share video
		}
	}

	getMediaStreamTrack(kind: DeviceKind): MediaStreamTrack | undefined {
		return kind === "audio" ? this.audioDevice?.persistentTrack : this.videoDevice?.persistentTrack;
	}

	async switchAudioDeviceAsync(
		device?: MediaDeviceInfo | undefined,
		constraints?: MediaTrackConstraintSet | undefined,
	): Promise<ISwitchDeviceResponse> {
		try {
			const deviceId = device?.deviceId ?? constrainToString(constraints?.deviceId) ?? undefined;
			const currentGroupId = this.getMediaStreamTrack("audio")?.getSettings()?.groupId || "1";
			const newGroupId = device?.groupId || "2";
			const switchedPhysicalDevices = currentGroupId !== newGroupId;
			let newDeviceInfo: MediaDeviceInfo | undefined;

			if (!deviceId) {
				const info = await this.call.cycleMic();
				newDeviceInfo = info?.device ?? undefined;
			} else {
				const micDevice = await this.call.setInputDevicesAsync({
					videoDeviceId: null,
					audioDeviceId: deviceId,
				});

				newDeviceInfo = getMediaDeviceInfoFromDailyDeviceInfos(micDevice, "mic");
			}

			/**
			 * If there is no established audio device, we don't know if the switch was successful or not.
			 * In this case we need to handle starting the audio track manually and waiting to see if it
			 * successfully starts.
			 */
			const shouldInitialize = this.getMediaStreamTrack("audio") === undefined;
			if (shouldInitialize) {
				// If we do not have valid device info after the switch, we are unable to fully initialize said device
				if (!newDeviceInfo) {
					return {
						result: false,
						switchedDevices: false,
						error: { message: "Failed to switch microphones", name: "MicrophoneSwitchFailed" },
					};
				}
				return this.initializeDevice(newDeviceInfo, deviceId);
			}

			const wasEnabled = this.audioDevice?.persistentTrack?.enabled;

			// Ensure audio state matches the current device
			this.call.setLocalAudio(wasEnabled ?? false);
			this._deviceInfo.audio = newDeviceInfo;

			// Daily doesn't emit track events when switching to a new audio device that's muted, so emit it ourselves
			if (this.audioDevice?.persistentTrack && !this.isEnabled("audio")) {
				this.emit("audioDisabled", { type: "audioDisabled" });
				this.emit("audioReady", {
					type: "audioReady",
					track: this.audioDevice.persistentTrack,
				});
			}
			return { result: true, switchedDevices: switchedPhysicalDevices, error: undefined };
		} catch (error) {
			console.error("Error switching audio device: ", error);
			return { result: false, switchedDevices: false, error: error as Error };
		}
	}

	async applyVideoBackground(
		settings: BackgroundSettings | null,
		arrayBuffer?: ArrayBuffer,
	): Promise<void> {
		// If the settings are the same as the current settings and the background is already applied, return
		if (
			this._backgroundInformation.settings?.path === settings?.path &&
			this._backgroundInformation.isApplied
		) {
			return;
		}
		this._backgroundInformation.isApplied = true;
		if (settings === null) {
			await this.call.updateInputSettings({ video: { processor: { type: "none" } } });
			this._backgroundInformation.settings = null;
			this._backgroundInformation.arrayBuffer = null;
			return;
		}
		if (settings.type === "image") {
			if (!arrayBuffer) {
				return;
			}
			await this.call.updateInputSettings({
				video: {
					processor: {
						type: "background-image",
						config: {
							source: arrayBuffer,
						},
					},
				},
			});
			this._backgroundInformation.settings = settings;
			this._backgroundInformation.arrayBuffer = arrayBuffer;

			return;
		}
		if (settings.type === "blur") {
			await this.call.updateInputSettings({
				video: { processor: { type: "background-blur", config: { strength: 1 } } },
			});
			this._backgroundInformation.settings = settings;
			this._backgroundInformation.arrayBuffer = null;

			return;
		}
	}

	async removeLocalAudioTrack(): Promise<void> {
		if (this.call.isDestroyed()) {
			return;
		}
		await this.call.setInputDevicesAsync({
			videoDeviceId: null,
			audioDeviceId: undefined,
		});

		// Update internal references
		this.audioDevice = this.call.participants().local?.tracks?.audio;
		this._deviceInfo.audio = undefined;
	}

	getVideoDimensions(): IDimensions | undefined {
		const videoSettings = this.videoDevice?.persistentTrack?.getSettings();
		if (videoSettings?.height && videoSettings?.width) {
			return {
				height: videoSettings.height,
				width: videoSettings.width,
			};
		}
		return undefined;
	}

	captureImage(): Promise<string | null> {
		if (this.videoDevice?.persistentTrack) {
			return captureImage(this.videoDevice?.persistentTrack);
		}

		return Promise.resolve(null);
	}

	/**
	 * Set the background effect information for the stream
	 * @param backgroundSettings the settings for the background
	 * @param backgroundArrayBuffer the background image as an ArrayBuffer
	 */
	setBackgroundEffectInformation(
		backgroundSettings: BackgroundSettings | null,
		backgroundArrayBuffer: ArrayBuffer | null,
	): void {
		const isApplied =
			this._backgroundInformation.isApplied &&
			backgroundSettings?.path === this._backgroundInformation.settings?.path;
		this._backgroundInformation = {
			settings: backgroundSettings,
			arrayBuffer: backgroundArrayBuffer,
			isApplied: isApplied,
		};
	}

	/**
	 * This method is used to handle stream updates for the Daily background preview stream.
	 * If the local user's video stream has been updated, then emit an event to update the rendered video on app level.
	 * @param event Daily "participant-updated" event object
	 */
	private handleCustomCallParticipantUpdated(event: DailyEventObjectParticipant): void {
		if (event.participant.local && event.participant.tracks.video.persistentTrack) {
			this.videoDevice = event.participant.tracks.video;
			this.emit("videoReady", {
				type: "videoReady",
				track: event.participant.tracks.video.persistentTrack,
			});
		}
	}

	/**
	 * Event handler for the video element's resize event
	 */
	private handleResizeEvent(): void {
		const newDim = this.getVideoDimensions() ?? { height: 0, width: 0 };
		this.emit("videoDimensionsChanged", { type: "videoDimensionsChanged", newDim });
	}

	/**
	 * Helper function to handle tracking if a device switch was successful in fully publishing to the call
	 * @param newDeviceInfo - The MediaDeviceInfo of the new device
	 * @param expectedDeviceId - The expected device we switched to
	 * @returns - A promise that resolves with the result of the switch
	 */
	private async initializeDevice(
		newDeviceInfo: MediaDeviceInfo,
		expectedDeviceId?: string,
	): Promise<ISwitchDeviceResponse> {
		const expectedType = newDeviceInfo.kind === "videoinput" ? "video" : "audio";

		// Wait for the expected device to throw an error or start playing before returning
		return new Promise((resolve) => {
			// Create multiple started handlers so we can differentiate between video and audio events for device switching
			const onVideoStarted = (event: DailyEventObjectTrack | undefined): void => {
				onStarted(event);
			};

			const onAudioStarted = (event: DailyEventObjectTrack | undefined): void => {
				onStarted(event);
			};

			const onStarted = (event: DailyEventObjectTrack | undefined): void => {
				if (!event?.participant?.local) {
					return;
				}

				// Confirm we have a matching event before cleaning up listeners
				if (event?.type === "video" && expectedType === "video") {
					this.call.off("track-started", onVideoStarted);
					this.call.off("camera-error", onVideoError);
					this.emit("videoEnabled", { type: "videoEnabled" });
					this._deviceInfo.video = newDeviceInfo;
				} else if (event?.type === "audio" && expectedType === "audio") {
					this.call.off("track-started", onAudioStarted);
					this.call.off("camera-error", onAudioError);
					this.emit("audioEnabled", { type: "audioEnabled" });
					this._deviceInfo.audio = newDeviceInfo;
					this.audioDevice = this.call.participants().local?.tracks?.audio;
				} else {
					// If we didn't find an event, return so the promise doesn't yet resolve
					return;
				}

				// Validate we found the device we expected
				if (!!expectedDeviceId && newDeviceInfo?.deviceId !== expectedDeviceId) {
					const error = {
						name: "CameraSwitchFailed",
						message: "The selected device was not able to be selected",
					};
					resolve({ result: false, switchedDevices: false, error: error });
				} else {
					resolve({ result: true, switchedDevices: true, error: undefined });
				}
			};

			// Create multiple error handlers so we can differentiate between video and audio errors for device switching
			const onVideoError = (event: DailyEventObjectCameraError): void => {
				onError(event);
			};

			const onAudioError = (event: DailyEventObjectCameraError): void => {
				onError(event);
			};

			const onError = (event: DailyEventObjectCameraError): void => {
				// Daily sends a "camera-error" event for any type of device acquisition error (including microphone)
				// Verify that the error is specifically for the expected device type before proceeding
				const eventType = isDailyCamError(event.error)
					? "video"
					: isDailyMicError(event.error)
					? "audio"
					: undefined;

				// Clean-up the corresponding event listeners
				if (eventType === "video" && expectedType === "video") {
					this.call.off("track-started", onVideoStarted);
					this.call.off("camera-error", onVideoError);
				} else if (eventType === "audio" && expectedType === "audio") {
					this.call.off("track-started", onAudioStarted);
					this.call.off("camera-error", onAudioError);
				} else {
					// If the error is not for the expected device type, ignore it
					return;
				}

				const error = makeDailyCamErrorGeneric(event?.error);
				resolve({ result: false, switchedDevices: false, error: error });
			};

			if (expectedType === "video") {
				this.call.on("track-started", onVideoStarted);
				this.call.on("camera-error", onVideoError);
				this.call.setLocalVideo(true);
			} else {
				this.call.on("track-started", onAudioStarted);
				this.call.on("camera-error", onAudioError);
				this.call.setLocalAudio(true);
			}
		});
	}
}
