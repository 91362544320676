/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file BubbleBanner component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Utilities.BubbleBanner
 */

import React, { ComponentType, FC } from "react";
import { IconProps } from "~/icons";
import InformationCircle from "~/icons/informationCircle";
import Success from "~/icons/success";
import WarningYield from "~/icons/warningYield";
import { resolveClassName } from "~/utils/className";
import styles from "./BubbleBanner.module.scss";
import ActionButton from "./ActionButton";
import { BackgroundStyleContextProvider } from "./BackgroundStyleContext";
import { StyleContext } from "~/types";
import WarningYieldLight from "~/icons/warningYieldLight";
import { SharedStringTokens } from "~/utils/strings";
import { useStrings } from "~/hooks/useStrings";

export type BannerType = "warning" | "info" | "success" | "critical";

/**
 * Props for BubbleBanner Component
 */
interface IProps {
	/** The type of banner, indicating severity */
	type: BannerType;
	/** Header text for the banner */
	title: string;
	/** Body text of the banner */
	message?: string;
	/** Button to include on the banner, if any */
	button?: IBannerButton;
	/** Extra class to apply to banner */
	rawClass?: string;
	/** Whether the banner is displayed inline, for instance within a card alongside other text or elements */
	inline?: boolean;
}

/**
 * Button to be included on a banner
 */
interface IBannerButton {
	/** Button text */
	text: string;
	/** Function to be called when the button is clicked */
	onClick: () => void;
}

/**
 * The BubbleBanner component
 * @param props The props ;)
 */
const BubbleBanner: FC<IProps> = (props) => {
	const { type, title, message, button, rawClass, inline = false, children } = props;

	const Icon = getIconForBannerType(type);
	const className = resolveClassName(styles, { banner: true, [type]: true, inline }, rawClass);

	const strings = useStrings("", [], [SharedStringTokens.warningIconAriaLabel]);
	const isAriaHidden = type !== "critical" && type !== "warning";

	return (
		<BackgroundStyleContextProvider style={StyleContext.light}>
			<div className={className}>
				<div className={styles["iconAndTextWrapper"]}>
					<div className={styles["iconWrapper"]}>
						<Icon
							aria-hidden={isAriaHidden}
							className={styles["bannerIcon"]}
							aria-label={strings[SharedStringTokens.warningIconAriaLabel]}
						/>
					</div>
					<div className={styles["textWrapper"]}>
						<span className={styles["title"]}>{title}</span>
						<span>{message}</span>
						{children}
					</div>
				</div>
				{button && (
					<div className={styles["buttonWrapper"]}>
						<ActionButton {...button} tone="neutral" priority="secondary" />
					</div>
				)}
			</div>
		</BackgroundStyleContextProvider>
	);
};

/**
 * Get the icon that should be displayed on the banner type
 * @param type banner type to get the icon for
 * @returns the icon to display for a specific type of banner
 */
function getIconForBannerType(type: BannerType): ComponentType<IconProps> {
	switch (type) {
		case "info":
			return InformationCircle;
		case "warning":
			return WarningYield;
		case "critical":
			return WarningYieldLight;
		case "success":
			return Success;
	}
}

BubbleBanner.displayName = "BubbleBanner";

export default BubbleBanner;
