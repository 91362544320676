/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file three dots icon (more menu dots)
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.ThreeDots
 */

import React, { FC } from "react";
import { IconProps } from ".";

/**
 * The ThreeDots component
 * @param props The props ;)
 */
const ThreeDots: FC<IconProps> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
			<g>
				<circle cx="28" cy="16" r="3.5" />
				<circle cx="16" cy="16" r="3.5" />
				<circle cx="4" cy="16" r="3.5" />
			</g>
		</svg>
	);
};

ThreeDots.displayName = "ThreeDots";

export default React.memo(ThreeDots);
