/* */

/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Bell Icon, used for notifications
 * @author Will Cooper
 * @module Epic.VideoApp.Icons.Bell
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum BellIconTestIds {
	self = "Bell",
}

/**
 * The Bell component
 * @param props The props ;)
 */
const Bell: FC<IconProps> = memo((props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			{...props}
			data-testid={BellIconTestIds.self}
		>
			<g>
				<path d="M18.7,24.64A2.57,2.57,0,0,1,16,27.05a2.57,2.57,0,0,1-2.7-2.41Z" />
				<path d="M25.64,22.12c0-.24.09-.47.13-.71v-.19c0-.08,0-.17,0-.25s0-.09,0-.13a.57.57,0,0,1-.08-.13,1,1,0,0,1-.06-.16c-1.27-.43-1.65-2-2.18-4.68a36.52,36.52,0,0,0-.9-3.89,7,7,0,0,0-4.51-5V6.87A2,2,0,0,0,16,5a2.05,2.05,0,0,0-2.13,1.92V7a7,7,0,0,0-4.51,5c-.41,1.39-.67,2.72-.9,3.89-.54,2.72-.91,4.27-2.21,4.69a5.92,5.92,0,0,1,.38.9c0,.15.08.3.12.45,1.34.08,2.69,0,4,.17a9.75,9.75,0,0,0,2.85.12,6.69,6.69,0,0,1,2.36,0,20.29,20.29,0,0,0,2.78.42,19.26,19.26,0,0,0,2.49-.4,14.94,14.94,0,0,1,2.91.2q.74,0,1.47.06C25.61,22.33,25.63,22.22,25.64,22.12Z" />
				<rect x="5" y="20.24" width="22" height="3.12" rx="1.53" />
			</g>
		</svg>
	);
});

Bell.displayName = "Bell";

export default Bell;
