/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file the SimpleCard component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Cards.SimpleCard
 */

import React, { FC } from "react";
import Card from "./Card";
import CardContainer from "./CardContainer";

interface IProps {
	/* If the card should always be centered, rather than moving out of the way of video previews */
	centered?: boolean;
}

/**
 * The SimpleCard component
 * @param props The props ;)
 */
const SimpleCard: FC<IProps> = (props) => {
	const { centered = true, children } = props;

	return (
		<CardContainer shortCard>
			<Card centered={centered}>{children}</Card>
		</CardContainer>
	);
};

SimpleCard.displayName = "SimpleCard";

export default SimpleCard;
