/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file About EVC Menu Component
 * @author Sasha Chackalackal
 * @module Epic.VideoApp.Components.Copyright.AboutEvc
 */

import React, { FC } from "react";
import { useStrings } from "~/hooks";
import { fullCopyrightNotice, fullCopyrightNoticeAriaLabel } from "~/utils/copyright";
import styles from "./Copyright.module.scss";

enum TokenNames {
	subtitle = "Subtitle",
}

export const AboutEvcTestIds = {
	self: "AboutEvc",
	notice: "AboutEvcNotice",
};

const AboutEvc: FC = () => {
	// Copyright notices aren't translated, only the subtitle
	const strings = useStrings("AboutEvc", Object.values(TokenNames));
	return (
		<div className={styles["aboutEvcContainer"]} data-testid={AboutEvcTestIds.self}>
			<h2 className={styles["aboutEvcSubtitle"]}>{strings[TokenNames.subtitle]}</h2>
			<p data-testid={AboutEvcTestIds.notice} aria-label={fullCopyrightNoticeAriaLabel} dir="ltr">
				{fullCopyrightNotice}
			</p>
		</div>
	);
};

AboutEvc.displayName = "AboutEvc";

export default AboutEvc;
