/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Map User Audio to AudioStreams via Output
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Components.UserAudio
 */

import React, { FC } from "react";

import { useStream } from "../hooks/useStream";
import { IUser } from "../interfaces";
import AudioStream from "./AudioStream";

interface IProps {
	user: IUser;
}

/**
 * The UserAudio component
 */
const UserAudio: FC<IProps> = React.memo(
	(props: IProps) => {
		const { user } = props;
		const deviceStream = useStream(user, "camera");
		const shareStream = useStream(user, "screen");

		if (!deviceStream && !shareStream) {
			return null;
		}

		return (
			<>
				{deviceStream && <AudioStream audioStream={deviceStream} />}
				{shareStream && <AudioStream audioStream={shareStream} />}
			</>
		);
	},
	(prevProps, nextProps) => prevProps.user.getUserGuid() === nextProps.user.getUserGuid(),
);

export default UserAudio;
