/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Hook that provides the callback to run when receiving a message from a moderator
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Messaging.Moderation.UseModeratedMessageHandler
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback } from "react";
import { getModeratedMessageToken, useModeratorAlert } from "~/components/VideoCall/hooks/useModeratorAlert";
import { useLocalAudioToggle } from "~/hooks/useLocalAudioToggle";
import { useLocalVideoToggle } from "~/hooks/useLocalVideoToggle";
import { useScreenShareToggle } from "~/hooks/useScreenShareToggle";
import { alertActions } from "~/state";
import { useUserState, userActions } from "~/state/user";
import { EpicUserType, IModeratorPayload } from "~/types";
import { useBroadcastParticipantInfo } from "../useBroadcastParticipantInfo";

export function useModeratedMessageHandler(): (messagePayload: IModeratorPayload) => Promise<void> {
	const broadcastInfo = useBroadcastParticipantInfo();
	const [oldCameraLockState, oldMicLockState, oldScreenShareLockState, userType] = useUserState(
		(selectors) => [
			selectors.getCameraLock(),
			selectors.getMicLock(),
			selectors.getScreenShareLock(),
			selectors.getUserType(),
		],
		[],
	);
	const [, toggleMic] = useLocalAudioToggle();
	const [, toggleVideo] = useLocalVideoToggle();
	const [, toggleScreenShare] = useScreenShareToggle();
	const dispatch = useDispatch();
	const { postDeviceModerationAlerts } = useModeratorAlert();

	const handleModerateMessage = useCallback(
		async (messagePayload: IModeratorPayload) => {
			if (!messagePayload) {
				return;
			}

			const { audioLock, videoLock, screenShareLock } = messagePayload;

			// Validate the message has actions and these actions are meaningful
			if (audioLock === undefined && videoLock === undefined && screenShareLock === undefined) {
				return;
			}

			if (
				audioLock === oldMicLockState &&
				videoLock === oldCameraLockState &&
				screenShareLock === oldScreenShareLockState
			) {
				return;
			}

			// control media
			if (audioLock) {
				await toggleMic("off");
			}

			if (videoLock) {
				await toggleVideo("off");
			}

			if (screenShareLock) {
				toggleScreenShare("off");
			}

			if (userType === EpicUserType.emp) {
				// Don't update lock state or send a status update, this is just a toggle off/on for providers
				// Show them a toast so they know an action was performed
				dispatch(
					alertActions.postToastAlert({
						type: "user-moderated",
						messageToken:
							getModeratedMessageToken(
								audioLock ?? false,
								videoLock ?? false,
								screenShareLock ?? false,
							) || "",
					}),
				);
				return;
			}

			/** Handle the core logic of locking local media and managing application state */
			dispatch(userActions.setLockState({ audioLock, videoLock, screenShareLock }));

			postDeviceModerationAlerts(audioLock, videoLock, screenShareLock);

			// Broadcast a status update so all moderators have up to date knowledge on who was muted/disabled
			broadcastInfo({
				newDisabledState: videoLock,
				newMutedState: audioLock,
				newScreenState: screenShareLock,
			});
		},
		[
			broadcastInfo,
			dispatch,
			oldCameraLockState,
			oldMicLockState,
			oldScreenShareLockState,
			postDeviceModerationAlerts,
			toggleMic,
			toggleScreenShare,
			toggleVideo,
			userType,
		],
	);

	return handleModerateMessage;
}
