/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file speaker icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Speaker
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum SpeakerTestIds {
	self = "Speaker",
}

/**
 * The Speaker component
 * @param props The props ;)
 */
const Speaker: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg
			data-testid={SpeakerTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
		>
			{title && <title>{title}</title>}
			<g>
				<path d="M13.7,7,9.55,11.15a1.23,1.23,0,0,1-.85.35H7.4a1.21,1.21,0,0,0-1.2,1.21v6.58A1.21,1.21,0,0,0,7.4,20.5H8.7a1.23,1.23,0,0,1,.85.35L13.7,25h1.5V7Z" />
				<path d="M18.3,11.75V13.4a3,3,0,0,1,1.09,4.11,2.9,2.9,0,0,1-1.09,1.08v1.65a4.49,4.49,0,0,0,2.76-5.73A4.55,4.55,0,0,0,18.3,11.75Z" />
				<path d="M21.3,7.69V9.44a8,8,0,0,1,3,6.56,8,8,0,0,1-3,6.55v1.76C23.94,23,25.8,19.75,25.8,16S23.94,9,21.3,7.69Z" />
			</g>
		</svg>
	);
});

Speaker.displayName = "Speaker";

export default Speaker;
