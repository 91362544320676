/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file disabled speaker icon
 * @author Trevor Roussel
 * @module Epic.VideoApp.Icons.DisabledSpeaker
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum DisabledSpeakerTestIds {
	self = "DisabledSpeaker",
}

/**
 * The DisabledSpeaker component
 * @param props The props ;)
 */
const DisabledSpeaker: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg
			data-testid={DisabledSpeakerTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
		>
			{title && <title>{title}</title>}
			<rect x="2.21" y="14.85" width="27.58" height="2.3" transform="translate(-6.63 16) rotate(-45)" />
			<path d="M23.51,12.24c.5,1.11,.79,2.39,.79,3.76,0,2.82-1.21,5.28-3,6.56v1.75c2.64-1.35,4.5-4.56,4.5-8.31,0-1.8-.44-3.47-1.18-4.88l-1.12,1.12Z" />
			<path d="M19.79,15.96c0,.53-.11,1.06-.39,1.55-.26,.45-.64,.83-1.09,1.09v1.65c2.3-.81,3.51-3.29,2.79-5.59l-1.3,1.3Z" />
			<g>
				<path d="M7.4,20.5h.35l7.44-7.44V7h-1.5l-4.15,4.15c-.23,.23-.53,.35-.85,.35h-1.3c-.67,0-1.21,.54-1.21,1.21v6.59c0,.67,.54,1.21,1.21,1.21Z" />
				<polygon points="12.22 23.52 13.7 25 15.2 25 15.2 20.55 12.22 23.52" />
			</g>
		</svg>
	);
});

DisabledSpeaker.displayName = "DisabledSpeaker";

export default DisabledSpeaker;
