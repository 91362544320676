/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file button tray used on narrow screens
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.ButtonTray
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useEffect } from "react";
import { useWindowSize } from "~/hooks";
import { uiActions, useUIState, useUserState } from "~/state";
import { useChatState } from "~/state/chat";
import Collapse from "../Utilities/Collapse";
import ChangeCameraButton from "./Buttons/ChangeCameraButton";
import ChatButton from "./Buttons/ChatButton";
import DisableCameraButton from "./Buttons/DisableCameraButton";
import LeaveCallButton from "./Buttons/LeaveCallButton";
import MoreMenuButton from "./Buttons/MoreMenuButton";
import MuteButton from "./Buttons/MuteButton";
import OpenRequestDeviceMenuButton from "./Buttons/OpenRequestDeviceMenuButton";
import styles from "./ControlsHeader.module.scss";
import { MinWidthForChangeCamInDrawer } from "./Menu/BaseMenu";
import { useHeaderDisplayMode } from "./hooks/useHeaderDisplayMode";

interface IProps {
	id?: string;
}

export enum ButtonTrayTestIds {
	tray = "ButtonTrayTray",
	collapse = "ButtonTrayCollapse",
	moreMenu = "ButtonTrayMoreMenuButton",
	changeCamera = "ButtonTrayChangeCameraButton",
	mute = "ButtonTrayMuteButton",
	disableCamera = "ButtonTrayDisableCameraButton",
	openRequestDevice = "ButtonTrayOpenRequestDeviceMenuButton",
	leaveCall = "ButtonTrayLeaveCallButton",
}

/**
 * The ButtonTray component
 */
const ButtonTray: FC<IProps> = (props) => {
	const { id } = props;
	const { width } = useWindowSize();
	const buttonTrayCollapsed = useUIState((selectors) => selectors.getButtonTrayCollapsed(), []);
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const chatEnabled = useChatState((selectors) => selectors.getChatEnabled(), []);
	const headerDisplayMode = useHeaderDisplayMode();
	const dispatch = useDispatch();

	useEffect(() => {
		// if the screen size shrinks to the point where there's no header, keep the button tray open
		if (headerDisplayMode === "no-header") {
			dispatch(uiActions.forceButtonTrayOpen());
		}
	}, [headerDisplayMode, dispatch]);

	const showChangeCamera = width >= MinWidthForChangeCamInDrawer;
	return (
		<Collapse collapsed={buttonTrayCollapsed} id={id}>
			<div className={styles["buttonTray"]} data-testid={ButtonTrayTestIds.tray}>
				<MoreMenuButton noLabel noTooltip disabled={buttonTrayCollapsed} />
				{showChangeCamera && <ChangeCameraButton noLabel noTooltip />}
				<MuteButton noTooltip />
				<DisableCameraButton noTooltip />
				<OpenRequestDeviceMenuButton noTooltip />
				{!inWaitingRoom && chatEnabled && <ChatButton noLabel noTooltip />}
				<LeaveCallButton noLabel noTooltip />
			</div>
		</Collapse>
	);
};

ButtonTray.displayName = "ButtonTray";

export default ButtonTray;
