/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Helper functions for creating and managing WebSocket messages
 * @author Noah Allen
 * @module Epic.VideoApp.Components.WebSocket.Helpers.WebSocketHelpers
 */

import { ICloudEvent, IOutboundMessagePayload, SocketMessageEventType } from "~/types/websockets";

// A function to create a CloudEvent message
export function createCloudEvent(
	id: string,
	eventType: SocketMessageEventType,
	version: string,
	requiresAck: boolean,
	data: IOutboundMessagePayload,
): ICloudEvent<IOutboundMessagePayload> {
	const VIDEO_SOURCE = "video";
	const type = `com.epic.${VIDEO_SOURCE}.${eventType}.${version}`;
	const specVer = "1.0";
	const datacontenttype = "application/json";
	const source = `/epic/${VIDEO_SOURCE}/chat`;

	const event: ICloudEvent<IOutboundMessagePayload> = {
		specversion: specVer,
		id: id,
		source: source,
		type: type,
		time: new Date().toISOString(),
		ack: requiresAck,
		datacontenttype: datacontenttype,
		data: data,
	};

	return event;
}

// A function to check if a WebSocket is open and ready
export function socketIsOpenAndReady(socket?: WebSocket): boolean {
	if (!socket || socket.readyState !== WebSocket.OPEN) {
		return false;
	}
	return true;
}
