/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Mic row for the hardware test page
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.MicSetup
 */

import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useStrings } from "~/hooks";
import { useVolumeLevels } from "~/hooks/useVolumeLevels";
import Mic from "~/icons/mic";
import { useHardwareTestState } from "~/state";
import { DisplayContext, Timeout } from "~/types";
import { VideoSessionContext } from "~/web-core/components/VideoSessionProvider";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { HardwareSetupDisplayContext } from "../HardwareSetup";
import { useAudioDeviceMessage } from "../hooks/useAudioDeviceMessage";
import DeviceSetupRow from "./DeviceSetupRow";
import MicSelector from "./MicSelector";
import styles from "./MicSetup.module.scss";
import VolumeIndicator from "./VolumeIndicator";

enum TokenNames {
	microphone = "Microphone",
	muteMicrophone = "MuteMicrophone",
	recentAudio = "RecentAudio",
	noRecentAudio = "NoRecentAudio",
}

const VOLUME_LEVEL_DETECTION_THRESHOLD = 1; // 1/10 volume
const VOLUME_DETECTION_RECENT_TIMEOUT = 5000; // 5 seconds

export enum MicSetupTestIds {
	self = "MicSetup",
	volumeIndicator = "VolumeIndicator",
}

/**
 * The MicSetup component
 */
const MicSetup: FC = () => {
	const displayContext = useContext(HardwareSetupDisplayContext);
	const status = useHardwareTestState(
		(selectors) => selectors.getMicrophoneStatus(displayContext === DisplayContext.lobby),
		[],
	);

	const { localDeviceStream } = useContext(VideoSessionContext);

	const isTrackEnabled = useIsStreamEnabled("audio", localDeviceStream);
	const { volumeLevel } = useVolumeLevels(localDeviceStream);

	const message = useAudioDeviceMessage();

	const strings = useStrings("MicSetup", Object.values(TokenNames));

	const [noRecentVolume, setNoRecentVolume] = useState<boolean>(true);
	const timeoutRef = useRef<Timeout>();

	useEffect(() => {
		if (volumeLevel >= VOLUME_LEVEL_DETECTION_THRESHOLD) {
			setNoRecentVolume(false);
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
				timeoutRef.current = undefined;
			}
		}

		if (volumeLevel < VOLUME_LEVEL_DETECTION_THRESHOLD && isTrackEnabled) {
			if (!timeoutRef.current) {
				timeoutRef.current = setTimeout(() => {
					setNoRecentVolume(true);
				}, VOLUME_DETECTION_RECENT_TIMEOUT);
			}
		}
	}, [isTrackEnabled, volumeLevel]);

	const volumeIndicatorAriaLabel = noRecentVolume
		? strings[TokenNames.noRecentAudio]
		: strings[TokenNames.recentAudio];

	return (
		<DeviceSetupRow
			icon={Mic}
			labelText={strings["Microphone"]}
			status={status}
			deviceSelectorComponent={MicSelector}
			statusMessage={message}
			data-testid={MicSetupTestIds.self}
		>
			{displayContext !== DisplayContext.lobby && (
				<div
					className={styles["innerContentWrapper"]}
					aria-label={volumeIndicatorAriaLabel}
					role="img"
				>
					<VolumeIndicator
						volumeLevel={volumeLevel}
						isTrackEnabled={isTrackEnabled}
						data-testid={MicSetupTestIds.volumeIndicator}
					/>
				</div>
			)}
		</DeviceSetupRow>
	);
};

MicSetup.displayName = "MicSetup";

export default MicSetup;
