/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Combined state for chat messages, UI, and alerts
 * @author Noah Allen
 * @module Epic.VideoApp.State.Combined.Chat
 */

import { withSharedStates } from "@epic/react-redux-booster";
import * as alerts from "../alerts";
import * as messages from "../messages";
import * as ui from "../ui";

/// COMBINED STATE TYPES ///

type ChatCombinedState = [messages.IMessageState, ui.IUIState, alerts.IAlertState];

/// COMBINED REDUCERS ///

function newMessage([prevMessageState, prevUIState, prevAlertState]: ChatCombinedState): ChatCombinedState {
	const { numUnreadMessages, messages: prevMessages, lastToastId } = prevMessageState;
	const lastMessageSeqId = prevMessages[prevMessages.length - 1]?.sequentialId ?? 0;
	const isSidebarOpen = prevUIState.sidebarStatus === "open";

	if (numUnreadMessages > 0 && lastToastId !== lastMessageSeqId && !isSidebarOpen) {
		const newAlertState = alerts.postToastAlert(prevAlertState, {
			type: "chat-unread-message",
			messageToken: numUnreadMessages > 1 ? "UnreadMessagesText" : "UnreadMessageText",
			ttlSeconds: 7,
		});
		const newMessageState = messages.setLastToastId(prevMessageState, lastMessageSeqId);
		return [newMessageState, prevUIState, newAlertState];
	} else if (isSidebarOpen) {
		const newMessageState = messages.setLastToastId(prevMessageState, lastMessageSeqId);
		return [newMessageState, prevUIState, prevAlertState];
	}

	return [prevMessageState, prevUIState, prevAlertState];
}

function clearToast(state: ChatCombinedState): ChatCombinedState {
	const [prevMessageState, prevUIState, prevAlertState] = state;
	const newAlertState = alerts.clearToasts(prevAlertState, "chat-unread-message");
	return [prevMessageState, prevUIState, newAlertState];
}

/// BUILD IT ///

export const chatCombinedReducers = {
	clearToast: withSharedStates(messages.state, ui.state, alerts.state).buildReducer(clearToast),
	newMessage: withSharedStates(messages.state, ui.state, alerts.state).buildReducer(newMessage),
};
