/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Combined local track / room / ui / alerts state for screen sharing
 * @author Colin Walters
 * @module Epic.VideoApp.State.Combined.ScreenSharing
 */

import { withSharedStates } from "@epic/react-redux-booster";
import { IParticipantInfo, Toast } from "~/types";
import * as alerts from "../alerts";
import * as room from "../room";
import * as ui from "../ui";

/// COMBINED STATE TYPES ///

type RemoteScreenShareState = [room.IRoomState, ui.IUIState, alerts.IAlertState];

/// COMBINED REDUCERS ///

interface IRemoteSharingParams {
	/** ID of the participant that started or stopped sharing */
	id: string;

	/** whether or not the participant is currently sharing  */
	active: boolean;
}

/**
 * Pin shared screen and post a toast message that a user is sharing their screen, name used in alert will come from participant info
 * If no participant is sharing, clearing the toast and unpin the shared screen
 * @param state shared state prior to this action
 * @param params sid of participant that started or stopped sharing their screen and which of those actions too place
 * @returns the new shared state after this action is applied
 */
function setRemoteSharingParticipant(
	state: RemoteScreenShareState,
	params: IRemoteSharingParams,
): RemoteScreenShareState {
	const { id, active } = params;
	const [prevRoomState, prevUIState, prevAlertState] = state;

	let newUIState = prevUIState;
	let newAlertState: alerts.IAlertState;

	if (active) {
		// pin the shared screen
		newUIState = ui.toggleVideoPinned(newUIState, {
			pinnedVideo: { identity: id, videoType: "screen" },
			updateSource: "Default",
		});

		// grab the user's name from participant info to be used in the toast
		const info = prevRoomState.participantInfo.find((p) => p.identity === id);
		newAlertState = alerts.postToastAlert(prevAlertState, getRemoteScreenShareToast(id, info));
	} else {
		// usePinnedParticipant handles unpinning when feeds no longer exist, but
		// won't for screens to avoid a race condition when someone takes over sharing
		if (newUIState.pinningState.pinnedVideo?.identity === id) {
			newUIState = ui.toggleVideoPinned(newUIState, null);
		}

		// clear the toast that the user was sharing
		newAlertState = alerts.clearToasts(prevAlertState, "remote-screen-shared");
	}

	return [prevRoomState, newUIState, newAlertState];
}

/**
 * Helper function to get a remote screen sharing toast
 * @param identity participant identity
 * @param info participant info, if known
 * @returns remote screen sharing toast that should be posted
 */
export function getRemoteScreenShareToast(id: string, info?: IParticipantInfo): Toast {
	let messageToken = "RemoteScreenShare_DefaultName";
	let messageArgs: string[] | undefined;
	if (info?.displayName) {
		messageToken = "RemoteScreenShare";
		messageArgs = [info.displayName];
	}
	return {
		type: "remote-screen-shared",
		messageToken,
		messageArgs,
		extraInfo: { identity: id },
	};
}

/// BUILD IT ///

export const screenSharingCombinedReducers = {
	setRemoteSharingParticipant: withSharedStates(room.state, ui.state, alerts.state).buildReducer(
		setRemoteSharingParticipant,
	),
};
