/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file buildWrappedSelector function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\store\build-wrapped-selector
 */

import { getStateSlice } from "./create-meta-reducer/get-state-slice";
import { areSlicesTheSame } from "./are-slices-the-same";
import { ISliceKeyCollection } from "./types";

interface IAccessors {
	[key: string]: (...args: any[]) => any;
}

/**
 * Wrap the user's selector with one that memoizes the store slice and the last selected value.
 * If the given store slices haven't change, don't run the user's selector, and just return the
 * last known value instead.
 *
 * This is our poor man's selector memoization for now.
 * It will avoid re-renders when other store slices that you don't care about change.
 * However, it will not provide any memoization for pieces *within* the slice you care about.
 *
 * @param selector The user's selector
 * @param accessors The collection of accessors to be passed to the user's selector
 * @param sliceKeyCollection The collection mapping IDs to state slice keys
 * @param id The id of the accessor/selector group that this selector is for
 * @param prevSlice A ref holding the slice of the state from last time this function was called
 * @param prevValue A ref holding the previously selected value from the last time this function was called
 * @param prevSelector A ref holding the previous user-provided selector function so we can decide whether the incoming selector is new
 * @param requiredSliceIds The slice IDs required by *all* the accessors in this group (if it's a combined selector)
 * @returns A new selector that will only run the actual selector code when the slice has changed.
 */
export function buildWrappedSelector(
	selector: (accessors: IAccessors) => any,
	accessors: IAccessors,
	sliceKeyCollection: ISliceKeyCollection,
	id: number,
	prevSlice: React.MutableRefObject<any>,
	prevValue: React.MutableRefObject<any>,
	prevSelector: React.MutableRefObject<any>,
	requiredSliceIds?: number[],
): (state: any) => any {
	return (wholeState: any) => {
		// Sanity check. Make sure we have the given accessors
		if (!accessors) {
			throw Error(
				`There are no accessors for id ${id}. Did you forget to call addSharedState or addCombinedSelectors?`,
			);
		}
		// Check to see if the slice has actually changed. This is our poor man's memoization for now.
		// It will avoid re-renders when other store slices that you don't care about change.
		// However, it will not provide any memoization for pieces *within* the slice you care about.
		const currentSlice = getStateSlice(wholeState, sliceKeyCollection, id, requiredSliceIds);
		if (currentSlice) {
			if (
				prevSelector.current !== selector ||
				typeof prevValue.current === "undefined" ||
				!areSlicesTheSame(currentSlice, prevSlice.current)
			) {
				prevSlice.current = currentSlice;
				prevValue.current = selector(accessors);
				prevSelector.current = selector;
			}
			return prevValue.current;
		} else {
			// If the slice is not even defined, then the accessors are definitly not going to work,
			// so throw an error.
			throw Error(`Required state slice for selector group ${id} is undefined.`);
		}
	};
}
