/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file hook to retrieve the currently selected camera ID from the LocalVideoTrack or disabled camera in shared state
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseSelectedCameraId
 */

import { useContext } from "react";
import { useLocalTrackState } from "~/state";
import { VideoSessionContext } from "~/web-core/components/VideoSessionProvider";

/**
 * Get the selected camera ID from shared state (either from the LocalVideoTrack or disabled camera)
 */
export function useSelectedCameraId(): string | null {
	const { localDeviceStream } = useContext(VideoSessionContext);

	const isVideoEnabled = localDeviceStream?.getMediaStreamTrack("video")?.enabled;
	const disabledCamera = useLocalTrackState((selectors) => selectors.getDisabledCamera(), []);
	const videoId = localDeviceStream?.getDeviceId("video");

	return isVideoEnabled ? videoId || null : disabledCamera?.deviceId || null;
}
