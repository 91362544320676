/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file mic icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Mic
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum MicIconTestIds {
	self = "Mic",
}

/**
 * The Mic component
 * @param props The props ;)
 */
const Mic: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
			data-testid={MicIconTestIds.self}
		>
			{title && <title>{title}</title>}
			<path d="M16,4a4.49,4.49,0,0,0-4.5,4.5v9a4.5,4.5,0,0,0,9,0v-9A4.49,4.49,0,0,0,16,4Z" />
			<path d="M23.35,16H21.8a6.17,6.17,0,0,1,.2,1.5,6,6,0,0,1-12,0,5.71,5.71,0,0,1,.19-1.5H5.5v1.5h3a7.5,7.5,0,0,0,6,7.35V28h3V24.85a7.5,7.5,0,0,0,6-7.35h3V16Z" />
		</svg>
	);
});

Mic.displayName = "Mic";

export default Mic;
