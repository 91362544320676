/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file participant that is not the main participant in a two-party call
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Participants.FloatingParticipant
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { uiActions } from "~/state";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { IUser } from "~/web-core/interfaces";
import VideoPreview from "../Utilities/VideoPreview";
import ParticipantNameBar from "./ParticipantNameBar";
import { useParticipantName } from "./hooks/useParticipantName";

export enum FloatingParticipantTestIds {
	self = "FloatingParticipant",
}

/**
 * Props for the FloatingParticipant component
 */
interface IProps {
	/** The participant to display as the hovering participant */
	participant: IUser;

	/** Whether or not the participant is the local participant */
	isLocal?: boolean;

	/** Whether or not pinning should be disabled */
	disablePinning?: boolean;
}

/**
 * The FloatingParticipant component
 * @param props The props ;)
 */
const FloatingParticipant: FC<IProps> = (props) => {
	const { participant, isLocal, disablePinning } = props;

	const displayName = useParticipantName(participant, isLocal);
	const dispatch = useDispatch();
	const timeoutRef = useRef<number>();

	const [hidden, setHidden] = useState(false);
	const stream = useStream(participant, "camera");

	/**	with non-null but disabled tracks possible, use isEnabled to determine whether a null videoTrack
	 * should be passed as a prop */
	const isEnabled = useIsStreamEnabled("video", stream);

	useEffect(() => {
		// hide the preview briefly to allow resizing
		setHidden(true);
		timeoutRef.current = setTimeout(setHidden, 200, false);
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [participant]);

	const togglePinned = useCallback(() => {
		dispatch(
			uiActions.toggleVideoPinned({
				pinnedVideo: { identity: participant.getUserIdentity(), videoType: "camera" },
				updateSource: "Default",
			}),
		);
	}, [dispatch, participant]);

	return (
		<VideoPreview
			stream={stream && isEnabled ? stream : null}
			isLocal={isLocal}
			canToggleSize
			onClick={disablePinning ? undefined : togglePinned}
			hidden={hidden}
			participantDisplayName={displayName}
		>
			<ParticipantNameBar
				participant={participant}
				isLocal={isLocal}
				location="preview"
				videoType="camera"
				disablePinning={disablePinning}
			/>
		</VideoPreview>
	);
};

FloatingParticipant.displayName = "FloatingParticipant";

export default FloatingParticipant;
