/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Generic text input component which displays an error if unsupported characters are entered
 * @author Hanita Haller
 * @module Epic.VideoApp.Components.Utilities.TextInput
 */
import React, { FC } from "react";
import { useStrings } from "~/hooks";
import { resolveClassName } from "~/utils/className";
import { stringFormat } from "~/utils/strings";
import styles from "./TextInput.module.scss";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	invalidCharacters?: string;
}

enum TokenNames {
	invalidCharactersMessage = "InvalidCharactersMessage",
}

export enum TextInputTestIds {
	self = "TextInput",
	invalidAlert = "TextInputInvalidCharactersAlert",
}
const TextInput: FC<IProps> = (props) => {
	const { invalidCharacters, className, ...inputProps } = props;

	const strings = useStrings("TextInput", Object.values(TokenNames));
	const inputClass = resolveClassName(styles, { invalidInput: !!invalidCharacters }, className);

	return (
		<>
			<input type="text" className={inputClass} {...inputProps} data-testid={TextInputTestIds.self} />
			{!!invalidCharacters && (
				<div
					className={styles["invalidCharactersMessage"]}
					role="alert"
					data-testid={TextInputTestIds.invalidAlert}
				>
					{stringFormat(strings[TokenNames.invalidCharactersMessage], invalidCharacters)}
				</div>
			)}
		</>
	);
};

TextInput.displayName = "TextInput";

export default TextInput;
