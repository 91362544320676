/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file #MyChart selection control component
 * @author Max Harkins
 * @module Epic.VideoApp.Components.Utilities.SelectionChoice
 */

import React, { FC, ReactElement, useContext } from "react";
import { StyleContext } from "~/types";
import { resolveClassName } from "~/utils/className";
import { BackgroundStyleContext } from "./BackgroundStyleContext";
import styles from "./SelectionChoice.module.scss";

/**
 * Props for SelectionChoice Component
 */
interface IProps {
	/** Function to be called when the action button is clicked */
	onClick: () => void;
	checked: boolean;
	label: string;
	id: string;
	/** How many choices can be selected at once in the control */
	selectType: "single" | "multi";
	useButtonCheck?: boolean;
	disabled?: boolean;
	icon?: ReactElement;
	wide?: boolean;
	hardwareTest?: boolean;
}

export enum SelectionChoiceTestIds {
	self = "SelectionChoice",
}

/**
 * The SelectionChoice component
 * This component is designed to replicate the MyChart selection control:
 * https://uxguide/MyChart/components__selection_controls_mychart.php?mode=standard&version=current&designer=false
 *
 * Link to analogous MyChart Choice component:
 * https://codesearch/default.aspx?view=search/client#versionid=1&namefiltertext=choice.tsx&namefilter=0&name=PatientEngagement%5CPackages%5Cclient-sdk%5Csrc%5Cui%5Cdata-entry%5Cchoice%5Ccomponents%5CChoice.tsx
 *
 * @param props The props ;)
 */
const SelectionChoice: FC<IProps> = (props) => {
	const {
		onClick,
		checked,
		label,
		id,
		selectType,
		useButtonCheck: buttonCheck = false,
		disabled = false,
		icon = null,
		wide = false,
		hardwareTest = false,
	} = props;

	const isInDarkMode = useContext(BackgroundStyleContext) === StyleContext.dark;

	const classNames: Record<string, boolean> = {
		toggleButton: true,
		buttonCheck,
		disabled,
		hasIcon: icon ? true : false,
		wide,
		isInDarkMode,
		hardwareTest,
	};

	const buttonClassName = resolveClassName(styles, classNames);
	const inputClassName = resolveClassName(styles, { ...classNames, _input: true });
	const labelClassName = resolveClassName(styles, { ...classNames, _dataEntryLabel: true });

	return (
		<span className={buttonClassName} data-testid={SelectionChoiceTestIds.self}>
			<input
				type={selectType === "single" ? "radio" : "checkbox"}
				className={inputClassName}
				id={id}
				onClick={onClick}
				defaultChecked={checked}
			/>
			<label htmlFor={id} className={labelClassName}>
				{icon}
				<span className={styles["labelText"]}>{label}</span>
			</label>
		</span>
	);
};

SelectionChoice.displayName = "SelectionChoice";

export default SelectionChoice;
