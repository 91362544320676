/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Utility functions for Daily implementation
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Functions.Utils
 */

import { DailyDeviceInfos } from "@daily-co/daily-js";
import { DeviceType } from "~/web-core/types";

/**
 * Check if a MediaStreamTrack can play media
 * @param track MediaStreamTrack to check if it is enabled, or undefined if it doesn't exist
 * @returns True if the track is playing media, false otherwise
 */
export function isTrackEnabled(track: MediaStreamTrack | undefined): boolean {
	if (!track) {
		return false;
	}
	return track.enabled && track.readyState !== "ended";
}

/**
 * Returns a usable MediaDeviceInfo object from a DailyDeviceInfos object
 * @param info DailyDeviceInfos object to attempt to get a MediaDeviceInfo from
 * @param kind DeviceType (either "camera" or "mic") to get the MediaDeviceInfo for
 * @returns MediaDeviceInfo for the specified camera or mic, or undefined if the switch failed
 */
export function getMediaDeviceInfoFromDailyDeviceInfos(
	info: DailyDeviceInfos,
	kind: DeviceType,
): MediaDeviceInfo | undefined {
	// Validate that this is not an empty object - if it isn't, assume it's a MediaDeviceInfo object
	if ("deviceId" in info[kind]) {
		// Need to make this assertion because otherwise the potential empty object return causes errors
		return info[kind] as MediaDeviceInfo;
	} else {
		return undefined;
	}
}
