/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Hook to monitor updates to a session's connection status
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.UseConnectionStatus
 */

import { useEffect, useState } from "react";
import { IEVCSessionConnectionEvent } from "~/web-core/events";
import { SessionConnectionStatus } from "~/web-core/types";
import { ISession } from "../web-core/interfaces/session";

/**
 * Monitors the user's connection status in the session
 * @param session the session to monitor
 * @returns user's connection status
 */
export function useConnectionStatus(session?: ISession): SessionConnectionStatus {
	const [status, setStatus] = useState<SessionConnectionStatus>(
		session?.connectionStatus ?? "not-connected",
	);

	useEffect(() => {
		setStatus(session?.connectionStatus ?? "not-connected");

		const handleSessionConnected = (args: IEVCSessionConnectionEvent): void =>
			setStatus(args.session.connectionStatus);

		if (session) {
			session.on("sessionConnected", handleSessionConnected);
		}

		return () => {
			if (session) {
				session.off("sessionConnected", handleSessionConnected);
			}
		};
	}, [session]);

	return status;
}
