/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file hook to check for a list of required user permissions
 * @author Max Harkins
 * @module Epic.VideoApp.Hooks.UseCheckUserPermissions
 */

import { useCallback } from "react";
import { useUserState } from "~/state";
import { IUserPermissions } from "~/types";

export interface ICheckUserPermissions {
	(permissions: (keyof IUserPermissions)[]): boolean;
}

/**
 * Checks whether the user has all supplied permissions.
 * @param permissions A string array of the permissions to check for
 * @returns True if the user has all permissions, false otherwise
 */
export function useCheckUserPermissions(): ICheckUserPermissions {
	const userPermissions = useUserState((selectors) => selectors.getAllUserPermissions(), []);

	const checkUserPermissions = useCallback(
		(permissions: (keyof IUserPermissions)[]) => {
			for (const permission of permissions) {
				if (!userPermissions[permission]) {
					return false;
				}
			}
			return true;
		},
		[userPermissions],
	);
	return checkUserPermissions;
}
