/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interface for a local user
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.LocalUser
 */

import { BackgroundSettings } from "../../types/backgrounds";
import { ILocalStream } from "./localStream";
import { IUser } from "./user";

/**
 * @interface ILocalUser
 * @extends IUser
 * @description Interface for a local user in the video application. It extends the IUser interface
 * and adds properties and methods related to device and share streams, video backgrounds, and virtual backgrounds.
 */
export interface ILocalUser extends IUser {
	/**
	 * @property deviceStream
	 * @type ILocalStream
	 * @description The stream from the local user's device.
	 */
	deviceStream: ILocalStream;

	/**
	 * @property shareStream
	 * @type ILocalStream | null
	 * @description The stream that the local user is sharing with others. It can be null if the user
	 * is not currently sharing a stream.
	 */
	shareStream: ILocalStream | null;

	readonly isLocal: true;

	/**
	 * @method createDevicePreviewStream
	 * @param {string | null} disabledCameraId - The ID of the user's disabled camera.
	 * @param {BackgroundSettings | undefined} backgroundSettings - The initial background settings to apply to the preview stream.
	 * @returns the stream
	 * @description Creates a preview stream from the local user's device.
	 */
	createDevicePreviewStream: (
		disabledCameraId: string | null,
		backgroundSettings?: BackgroundSettings,
	) => Promise<ILocalStream>;

	/**
	 * @method applyVideoBackground
	 * @param {BackgroundSettings | null} settings - The settings for the video background.
	 * @param {ILocalStream | null} stream - The stream to which the background should be applied.
	 * @returns Promise<void>
	 * @description Applies a video background to a stream based on the provided settings.
	 */
	applyVideoBackground: (settings: BackgroundSettings | null, stream: ILocalStream | null) => Promise<void>;

	/**
	 * @method createShareStream
	 * @param {MediaStreamTrack} videoStream - The video stream to share.
	 * @param {MediaStreamTrack | null} audioStream - The audio stream to share. It can be null if there is no audio to share.
	 * @param {MediaStream} mediaStream - The media stream source of the tracks.
	 * @returns Promise<ILocalStream>
	 * @description Creates a share stream from the provided video and audio streams and returns it as a Promise.
	 */
	createShareStream(
		videoStream: MediaStreamTrack,
		audioStream: MediaStreamTrack | null,
		mediaStream: MediaStream,
	): Promise<ILocalStream>;

	/**
	 * @method cleanupShareStream
	 * @description Cleans up the share stream when it is no longer needed.
	 */
	cleanupShareStream(): void;

	/**
	 * @method initializeVirtualBackgrounds
	 * @param {BackgroundSettings[] | undefined} backgroundSettings - An array of settings for the virtual backgrounds.
	 * @param {number | undefined} availableBackgroundsCount - The number of available virtual backgrounds.
	 * @description Initializes the virtual backgrounds based on the provided settings and count.
	 */
	initializeVirtualBackgrounds(
		backgroundSettings?: BackgroundSettings[],
		availableBackgroundsCount?: number,
	): void;

	/**
	 * @method setAudioOutput
	 * @param speaker The output device to switch to
	 */
	setAudioOutput(speaker: MediaDeviceInfo): void;
}
