/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Network test data table component
 * @author Noah Allen
 * @module Epic.VideoApp.Features.TestNetwork.DataTable
 */

import React from "react";

export interface IData {
	maxRoundTripTime: number | null;
	avgRoundTripTime: number | null;
	avgSendPacketLoss: number | null;
	avgAvailableOutgoingBitrate: number | null;
	avgSendBitsPerSecond: number | null;
}

interface IDataTableProps {
	data: IData;
}

const NetworkDataResultsTable: React.FC<IDataTableProps> = ({ data }) => {
	return (
		<table>
			<tbody>
				{data.maxRoundTripTime !== null && (
					<tr>
						<td style={{ textAlign: "left", fontWeight: "bold" }}>Max Round Trip Time:</td>
						<td style={{ textAlign: "left" }}>{`${(data.maxRoundTripTime * 1000).toFixed(
							3,
						)}ms`}</td>
					</tr>
				)}
				{data.avgRoundTripTime !== null && (
					<tr>
						<td style={{ textAlign: "left", fontWeight: "bold" }}>Avg Round Trip Time:</td>
						<td style={{ textAlign: "left" }}>{`${(data.avgRoundTripTime * 1000).toFixed(
							3,
						)}ms`}</td>
					</tr>
				)}
				{data.avgSendPacketLoss !== null && (
					<tr>
						<td style={{ textAlign: "left", fontWeight: "bold" }}>Avg Send Packet Loss:</td>
						<td style={{ textAlign: "left" }}>{`${data.avgSendPacketLoss}%`}</td>
					</tr>
				)}
				{data.avgAvailableOutgoingBitrate !== null && (
					<tr>
						<td style={{ textAlign: "left", fontWeight: "bold" }}>
							Avg Available Outgoing Bitrate:
						</td>
						<td style={{ textAlign: "left" }}>{`${data.avgAvailableOutgoingBitrate}bps`}</td>
					</tr>
				)}
				{data.avgSendBitsPerSecond !== null && (
					<tr>
						<td style={{ textAlign: "left", fontWeight: "bold" }}>Avg Send Bits Per Second:</td>
						<td style={{ textAlign: "left" }}>{`${data.avgSendBitsPerSecond}bps`}</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default NetworkDataResultsTable;
