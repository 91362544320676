/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Chat Icon
 * @author Will Cooper
 * @module Epic.VideoApp.Icons.Chat
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

const Chat: FC<IconProps> = memo((props) => {
	return (
		<svg
			id="_Layer_"
			data-name="&lt;Layer&gt;"
			xmlns="http://www.w3.org/2000/svg"
			height={25}
			width={25}
			viewBox="0 0 32 32"
			{...props}
		>
			<g>
				<path
					id="Path_456"
					data-name="Path 456"
					d="M24,13a7.66,7.66,0,0,1-7.58,7H15.07l-1.86,1.86A5.84,5.84,0,0,0,17.73,24H19.2l2.88,2.86a.49.49,0,0,0,.68-.12.47.47,0,0,0,.09-.28V23.76A5.87,5.87,0,0,0,27,18.13h0A5.89,5.89,0,0,0,24,13Z"
				/>
				<path
					id="Path_457"
					data-name="Path 457"
					d="M15.94,5h-4A7,7,0,0,0,5,12H5A7,7,0,0,0,9.89,18.7V22a.58.58,0,0,0,.58.58.54.54,0,0,0,.33-.1L14.21,19h1.67a7,7,0,0,0,7-7h0a7,7,0,0,0-6.91-7Z"
				/>
			</g>
		</svg>
	);
});

Chat.displayName = "Chat";

export default Chat;
