/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file Hardware Test status banner
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.Banners.HardwareStatusBanner
 */

import React, { FC, PropsWithChildren, useMemo } from "react";
import { useHardwareTestState } from "~/state";
import { HardwareTestStatus } from "~/types";
import Collapse from "../../Utilities/Collapse";
import { useDeviceSpecificBanner } from "../hooks/useDeviceSpecificBanner";
import { useGetStatusBannerInfo } from "../hooks/useGetStatusBannerInfo";
import Android12VideoWarning from "./Android12VideoWarning";
import BrowserSupportWarning from "./BrowserSupportWarning";
import DeviceStatusBanner from "./DeviceStatusBanner";
import HeadphoneAudioWarning from "./HeadphoneAudioWarning";

interface IProps {
	isLobby?: boolean;
	speakerMessage: string;
	cameraMessage: string;
	audioMessage: string;
}

export enum HardwareStatusBannerTestIds {
	self = "HardwareStatusBanner",
}

/**
 * The HardwareStatusBanner component
 *
 * Renders the inline banner for the new hardware test showing information about potential hardware/browser/OS/permissions errors, or indicating a successful test.
 * @param props the props ;)
 */
const HardwareStatusBanner: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { isLobby, cameraMessage, audioMessage, speakerMessage } = props;

	const status = useHardwareTestState(
		(selectors) => selectors.getTestStatus({ allowOneError: isLobby || false, isStandalone: !isLobby }),
		[],
	);

	const deviceSpecificBanner = useDeviceSpecificBanner();

	const bannerInfo = useGetStatusBannerInfo(!!isLobby);

	const statusBanner = useMemo(() => {
		const messageList = [];
		if (speakerMessage) {
			messageList.push(speakerMessage);
		}
		if (audioMessage) {
			messageList.push(audioMessage);
		}
		if (cameraMessage) {
			messageList.push(cameraMessage);
		}
		return <DeviceStatusBanner messageList={messageList} bannerInfo={bannerInfo} />;
	}, [audioMessage, bannerInfo, cameraMessage, speakerMessage]);

	return (
		<Collapse collapsed={status === HardwareTestStatus.testing}>
			<div data-testid={HardwareStatusBannerTestIds.self}>
				{/* Do not show a "success" banner if one of the OS warning banners will also be shown. */}
				{(deviceSpecificBanner === "None" || bannerInfo.type !== "success") && statusBanner}
				{deviceSpecificBanner === "BrowserSupportBanner" && <BrowserSupportWarning inline />}
				{deviceSpecificBanner === "HeadphoneAudioBanner" && <HeadphoneAudioWarning inline />}
				{deviceSpecificBanner === "Android12Banner" && <Android12VideoWarning inline />}
			</div>
		</Collapse>
	);
};

HardwareStatusBanner.displayName = "HardwareStatusBanner";

export default HardwareStatusBanner;
