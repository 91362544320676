/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Component used to select a microphone
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.MicSelector
 */

import React, { FC, useCallback } from "react";
import { useMicrophoneDevices, useStrings } from "~/hooks";
import { useAudioTrackActions } from "~/hooks/localTracks";
import { useSelectedMicId } from "~/hooks/useSelectedMicId";
import DeviceSelector from "./DeviceSelector";

/**
 * The MicSelector component
 */
const MicSelector: FC = () => {
	const { switchAudioDevice } = useAudioTrackActions();

	const mics = useMicrophoneDevices();
	const selectedMicId = useSelectedMicId();

	const onMicChange = useCallback(
		(mic: MediaDeviceInfo) => {
			// no need to wait for errors, if the switch fails it'll reset the audio track
			void switchAudioDevice(mic, true);
		},
		[switchAudioDevice],
	);

	const tokenNames = ["Microphone"];
	const strings = useStrings("MicSelector", tokenNames);

	return (
		<DeviceSelector
			selectedDeviceId={selectedMicId}
			devices={mics}
			fallbackDeviceName={strings["Microphone"]}
			onDeviceSelectionChange={onMicChange}
		/>
	);
};

MicSelector.displayName = "MicSelector";

export default MicSelector;
