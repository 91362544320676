/**
 * @copyright Copyright 2021 - 2024 Epic Systems Corporation
 * @file toasts component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Toasts.Toasts
 */

import React, { FC, memo } from "react";
import { useAlertState } from "~/state";
import Toast from "./Toast";
import styles from "./Toasts.module.scss";

export enum ToastsTestIds {
	self = "Toasts",
}

/**
 * The Toasts component
 */
const Toasts: FC = () => {
	const toasts = useAlertState((selectors) => selectors.getToastAlerts(), []);

	return toasts.length ? (
		<div className={styles["toastsContainer"]} data-testid={ToastsTestIds.self}>
			{toasts.map((toast) => (
				<Toast key={toast.id} toast={toast} />
			))}
		</div>
	) : null;
};

Toasts.displayName = "Toasts";

export default memo(Toasts);
