/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file addSharedState function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\add-shared-state
 */

import { Reducer } from "react";
import { AnyAction } from "redux";
import { ISharedState, IBoosterInfo } from "../types";
import { getSliceKey } from "../get-slice-key";
import { createMetaReducer } from "../create-meta-reducer/create-meta-reducer";
import { buildAccessors } from "./build-accessors";

/**
 * Add a new piece of shared state to the store
 * @param sliceInfo The shared state slice to be added
 * @param displayName A display name for the shared state slice that is being added
 * @param replaceReducer The store's replaceReducer function
 * @param getState The store's getState function
 */
export function addSharedState(
	prevBoosterInfo: IBoosterInfo,
	sliceInfo: ISharedState,
	displayName: string,
	replaceReducer: (nextReducer: Reducer<any, AnyAction>) => void,
	getState: () => any,
): IBoosterInfo {
	// Sanity check	to make sure we haven't already seen this ID.
	const { id } = sliceInfo;
	if (prevBoosterInfo.sliceInfoCollection[id]) {
		throw Error(`Shared state with ID ${id} has already been added to the store.`);
	}

	// Make sure we're creating a new copy...
	const nextBoosterInfo: IBoosterInfo = { ...prevBoosterInfo };
	nextBoosterInfo.sliceInfoCollection = { ...prevBoosterInfo.sliceInfoCollection };
	nextBoosterInfo.sliceKeyCollection = { ...prevBoosterInfo.sliceKeyCollection };

	// Add the slice info
	nextBoosterInfo.sliceInfoCollection[id] = {
		id: sliceInfo.id,
		initializer: sliceInfo.initializer,
	};

	// Construct the full slice name (i.e. with display name)
	nextBoosterInfo.sliceKeyCollection[id] = getSliceKey(sliceInfo.id, displayName);

	const { reducers, selectors } = sliceInfo;

	// Add the reducers
	if (reducers) {
		nextBoosterInfo.reducerCollection = { ...prevBoosterInfo.reducerCollection };
		nextBoosterInfo.reducerCollection[id] = reducers;
	}

	// Build up the accessor functions from the selectors
	if (selectors) {
		nextBoosterInfo.accessorCollection = { ...prevBoosterInfo.accessorCollection };
		nextBoosterInfo.accessorCollection[id] = buildAccessors(
			selectors,
			getState,
			id,
			nextBoosterInfo.sliceKeyCollection,
		);
	}

	// Update the redux meta reducer
	replaceReducer(createMetaReducer(
		nextBoosterInfo.sliceInfoCollection,
		nextBoosterInfo.sliceKeyCollection,
		nextBoosterInfo.reducerCollection,
	) as any);

	return nextBoosterInfo;
}
