/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Functions related to the WebAudio API
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Utils.Audio
 */

import { isSafari } from "./browser";
import { debug } from "./logging";
import {
	iOSVerAudioGainWorkarounds,
	iOSVerRenderingWorkarounds,
	isDeviceNameInUA,
	isIOS,
	UserAgentDevice,
} from "./os";

// create a single audioContext instance to be used throughout the app
export const getAudioContext = (): AudioContext => {
	// eslint-disable-next-line compat/compat, @typescript-eslint/no-unsafe-member-access
	const AudioContext = window.AudioContext || (window as any).webkitAudioContext;

	return new AudioContext();
};

/**	Restart playback after interruption https://bugs.webkit.org/show_bug.cgi?id=235762 */
export function handlePause(this: HTMLAudioElement, _ev: Event): void {
	if (isIOS() || isSafari()) {
		try {
			void this.play();
		} catch (e: any) {
			const err = "message" in e ? (e as Error) : null;
			debug(`Unable to play paused <audio> element: ${err?.message ?? ""}`);
		}
	}
}

/**
 * Provides a value for a GainNode when used on iOS
 * @returns gain.value as number
 */
const getIOSWebAudioGainValue = (): number => {
	// UserAgentDevice definitions in ~/utils/os
	const iOS15GainValues: Record<UserAgentDevice, number> = {
		iPhone: 16,
		default: iOSVerRenderingWorkarounds ? 8 : 4,
	};

	// Default/no gain is value 1. for iOS15, iPhone will get higher value and iPad/anything else will get lower
	if (!iOSVerAudioGainWorkarounds) {
		return 1;
	}
	// seek out the iPhone- iPad more likely to appear as Desktop
	if (isDeviceNameInUA(["iPhone"])) {
		return iOS15GainValues["iPhone"];
	}
	return iOS15GainValues.default;
};

export const iOSWebAudioGainValue = getIOSWebAudioGainValue();
