/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file PageSelector
 * @author Liam Liden
 * @module Epic.VideoApp.Components.Utilities.Carousel.PageSelector
 */

import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import Circle from "~/icons/circle";
import { resolveClassName } from "~/utils/className";
import { stringFormat } from "~/utils/strings";
import BaseButton from "../BaseButton";
import styles from "./PageSelector.module.scss";

interface IProps {
	/** Function to run on button click*/
	onClick: (page: number) => void;

	/** Total pages in carousel to know how many buttons to create */
	totalPages: number;

	/** Current page to know which circle to fill in */
	currentPage: number;

	/** Custom CSS class for PageSelectorButtons */
	buttonClassName?: string;
}

enum TokenNames {
	currentPage = "CurrentPage",
	switchToPage = "SwitchToPage",
}

const PageSelector: FC<IProps> = (props) => {
	const { onClick, totalPages, currentPage, buttonClassName } = props;
	const strings = useStrings("PageSelector", Object.values(TokenNames));

	const switchToPage = useCallback(
		(page: number): (() => void) => {
			return () => onClick(page);
		},
		[onClick],
	);

	return (
		<div className={styles["pageSelector"]}>
			{[...Array(totalPages).keys()].map((key, index) => {
				const isCurrent = index === currentPage;
				const ariaLabel = isCurrent
					? stringFormat(strings[TokenNames.currentPage], index + 1, totalPages)
					: stringFormat(strings[TokenNames.switchToPage], index + 1);

				const buttonClass = resolveClassName(
					styles,
					{
						pageSelectorButton: true,
						selected: isCurrent,
					},
					buttonClassName,
				);

				return (
					<BaseButton
						key={key}
						rawClass={buttonClass}
						onClick={switchToPage(index)}
						aria-label={ariaLabel}
						icon={Circle}
						title={ariaLabel}
						aria-pressed={isCurrent}
					/>
				);
			})}
		</div>
	);
};

PageSelector.displayName = "PageSelector";

export default PageSelector;
