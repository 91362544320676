/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Device status banner
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.Banners.DeviceStatusBanner
 */

import React, { FC, PropsWithChildren } from "react";
import { IStatusBannerInfo } from "~/types";
import BubbleBanner from "../../Utilities/BubbleBanner";
import styles from "./../HardwareTest.module.scss";

interface IProps {
	messageList: string[];
	bannerInfo: IStatusBannerInfo;
}

/**
 * The DeviceStatusBanner component
 *
 * Renders a banner indicating device/test status given a list of status messages and banner information
 *
 * @param props the props ;)
 */
const DeviceStatusBanner: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { messageList, bannerInfo } = props;

	return (
		<BubbleBanner
			type={bannerInfo.type}
			title={bannerInfo.message}
			message={bannerInfo.additionalDetails}
			inline
		>
			{(bannerInfo.type === "warning" || bannerInfo.type === "success") &&
				/* Show individual device messages in the case that the user is still allowed to join. Otherwise it's too much clutter.
				   Specifically, hide device messages for error or in-progress tests. */
				(messageList.length > 1 ? (
					<ul className={styles["messageList"]}>
						{messageList.map((message, index) => (
							<li key={index}>{message}</li>
						))}
					</ul>
				) : (
					<div>{messageList[0]}</div>
				))}
		</BubbleBanner>
	);
};

DeviceStatusBanner.displayName = "DeviceStatusBanner";

export default DeviceStatusBanner;
