/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Hook to calculate if a participant notification is necessary
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Hooks.UseShowParticipantNotification
 */

import { useContext } from "react";
import { useModerationState, useUserState } from "~/state";
import { ParticipantDataContext } from "~/web-core/components";

/**
 * Hook to calculate whether or not there are actions to take within the participant list
 * @returns True if there are actions available, false otherwise
 */
export function useShowParticipantNotification(): boolean {
	const { waitingParticipants } = useContext(ParticipantDataContext);
	const canAdmit = useUserState((selectors) => selectors.getUserPermission("canStartEndVisit"), []);
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);
	const listOfRequesters = useModerationState((selectors) => selectors.getRequestingParticipants(), []);
	return (waitingParticipants.length > 0 && canAdmit) || (canModerate && listOfRequesters.length > 0);
}
