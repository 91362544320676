/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file shared background processor state
 * @author Liam Liden
 * @module Epic.VideoApp.State.BackgroundProcessors
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import {
	BackgroundProcessorLoadStatus,
	BackgroundProcessorsMap,
	BackgroundResourceType,
	BackgroundSettingKvp,
	BackgroundSettings,
	DefaultImageNames,
	ICustomBackgroundProcessor,
} from "~/types/backgrounds";

/// TYPES ///
export interface IBackgroundProcessorsState {
	readonly backgroundProcessors: BackgroundProcessorsMap;

	readonly publishedBackgroundProcessor: string;

	readonly processorLoadStatus: BackgroundProcessorLoadStatus;
}

/// INIT ///

export function getInitialState(): IBackgroundProcessorsState {
	return {
		backgroundProcessors: new Map<string, ICustomBackgroundProcessor>(),
		processorLoadStatus: "notStarted",
		publishedBackgroundProcessor: DefaultImageNames.none,
	};
}

/// HELPERS ///
function getOrderForResourceType(type: BackgroundResourceType | undefined): number {
	switch (type) {
		case undefined:
			return 0;
		case "RemoteImage":
			return 1;
		case "BackgroundImage":
			return 2;
	}
}

/**
 * Orders two entries in the background processor map in sorted order
 * @param a First key-value-pair to sort
 * @param b Second key-value-pair to sort
 * @returns -1 if a sorts before b, 1 if b sorts before a
 */
function sortBackgroundProcessors(a: BackgroundSettingKvp, b: BackgroundSettingKvp): number {
	const typeDifference =
		getOrderForResourceType(a[1].resourceType) - getOrderForResourceType(b[1].resourceType);

	if (typeDifference !== 0) {
		return typeDifference > 0 ? 1 : -1;
	}
	return a[0] > b[0] ? 1 : -1;
}

/// REDUCERS ///

export function setPublishedBackgroundProcessor(
	state: IBackgroundProcessorsState,
	newProcessorPath: string,
): IBackgroundProcessorsState {
	return {
		...state,
		publishedBackgroundProcessor: newProcessorPath,
	};
}

export function handleBackgroundLoadComplete(
	state: IBackgroundProcessorsState,
	update: {
		status: BackgroundProcessorLoadStatus;
		processorSettings: BackgroundSettingKvp[];
	},
): IBackgroundProcessorsState {
	// Make a combined array of all new processors and existing processors in state
	const allProcessorSettings = [...update.processorSettings, ...state.backgroundProcessors.entries()];
	// Sort the combined array and convert back to a map so processors display in a consistent order
	const sortedBackgroundProcessors = new Map(allProcessorSettings.sort(sortBackgroundProcessors));
	return { ...state, backgroundProcessors: sortedBackgroundProcessors, processorLoadStatus: update.status };
}

export function setProcessorLoadStatus(
	state: IBackgroundProcessorsState,
	newStatus: BackgroundProcessorLoadStatus,
): IBackgroundProcessorsState {
	return { ...state, processorLoadStatus: newStatus };
}

/// SELECTORS ///

function getBackgroundProcessors(state: IBackgroundProcessorsState): BackgroundProcessorsMap {
	return state.backgroundProcessors;
}

function getDefaultBackgroundProcessor(state: IBackgroundProcessorsState): string {
	const [firstProcessor] = state.backgroundProcessors.keys();
	return firstProcessor;
}

function getPublishedBackgroundProcessor(state: IBackgroundProcessorsState): string {
	return state.publishedBackgroundProcessor;
}

function getProcessorLoadStatus(state: IBackgroundProcessorsState): BackgroundProcessorLoadStatus {
	return state.processorLoadStatus;
}

function getPublishedProcessorFromMap(state: IBackgroundProcessorsState): BackgroundSettings | undefined {
	if (state.publishedBackgroundProcessor) {
		return state.backgroundProcessors.get(state.publishedBackgroundProcessor)?.processor;
	}

	return undefined;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		setProcessorLoadStatus,
		setPublishedBackgroundProcessor,
		handleBackgroundLoadComplete,
	},
	selectors: {
		getBackgroundProcessors,
		getDefaultBackgroundProcessor,
		getPublishedBackgroundProcessor,
		getPublishedProcessorFromMap,
		getProcessorLoadStatus,
	},
});

store.addSharedState(builtState.sharedState, "backgroundProcessors");

export const {
	actionCreators: backgroundProcessorsActions,
	useSharedState: useBackgroundProcessorsState,
	sharedState: state,
} = builtState;
