/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Utilities for interacting with WebRTC Core Layer / Vendors
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Functions.Utils
 */

// Helper to determine if we should show the warning message before we request device permissions
export function shouldShowPermissionWarning(deviceInfos: MediaDeviceInfo[]): boolean {
	const cameraDevices = deviceInfos.filter((deviceInfo) => deviceInfo.kind === "videoinput");
	const microphoneDevices = deviceInfos.filter((deviceInfo) => deviceInfo.kind === "audioinput");

	const hasCamera = cameraDevices.length > 0;
	const hasMicrophone = microphoneDevices.length > 0;

	const cameraPermissionsAllowed = cameraDevices.filter((deviceInfo) => deviceInfo.label !== "").length > 0;
	const microphonePermissionsAllowed =
		microphoneDevices.filter((deviceInfo) => deviceInfo.label !== "").length > 0;

	// Browsers don't seem to ask for permissions if devices are missing
	const willAskForCamera = hasCamera && !cameraPermissionsAllowed;
	const willAskForMicrophone = hasMicrophone && !microphonePermissionsAllowed;

	return willAskForCamera || willAskForMicrophone;
}

/**
 * Given a ConstrainDOMString, returns a string (or undefined if given undefined)
 * @param constrain the ConstrainDOMString value
 * @returns A string representation of the given ConstrainDOMString value, or undefined if given undefined
 */
export function constrainToString(constrain: ConstrainDOMString | undefined): string | undefined {
	if (!constrain || typeof constrain === "string") {
		return constrain;
	}

	//Constrain is an array
	if (Array.isArray(constrain) && constrain.length > 0) {
		return constrain[0];
	}

	let stringOrArray: string | string[] | undefined;
	if (Object.getPrototypeOf(constrain) === Object.prototype) {
		if (Object.keys(constrain).length === 0) {
			// Object is empty
			return undefined;
		}
		if ("exact" in constrain) {
			stringOrArray = constrain.exact;
		} else if ("ideal" in constrain) {
			stringOrArray = constrain.ideal;
		} else {
			// Object is not empty, but doesn't have exact or ideal
			return undefined;
		}
	}

	if (!stringOrArray) {
		return undefined;
	}

	if (Array.isArray(stringOrArray)) {
		return stringOrArray[0];
	}

	return stringOrArray;
}
