import React, { FC } from "react";
import { useWindowSize } from "~/hooks";
import Camera from "~/icons/camera";
import Gear from "~/icons/gear";
import Speaker from "~/icons/speaker";
import { ScreenWidthInflectionPoint } from "~/types"; // Identical to $screen-width-inflection constant from _dimensions.scss

/**
 * Props for QuestionIcon Component
 */
interface IProps {
	iconName: string;
	shrinkMobileIcons?: boolean;
}

/**
 * The QuestionIcon component
 * @param _props The props ;)
 */
const QuestionIcon: FC<IProps> = (props) => {
	const { iconName, shrinkMobileIcons = false } = props;
	const windowSize = useWindowSize();

	const mobile = windowSize.width < ScreenWidthInflectionPoint;

	const size = shrinkMobileIcons && mobile ? 32 : 70;

	switch (iconName) {
		case "speaker":
			return <Speaker width={size} height={size} />;
		case "camera":
			return <Camera width={size} height={size} />;
		case "gear":
			return <Gear width={size} height={size} />;
		default:
			return null;
	}
};

QuestionIcon.displayName = "QuestionIcon";

export default QuestionIcon;
