/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file hook to save survey responses
 * @author Max Harkins
 * @module Epic.VideoApp.Components.FeedbackSurvey.Hooks.UseSaveFeedbackSurvey
 */

import { useDispatch } from "@epic/react-redux-booster/dist/use-dispatch";
import { useCallback } from "react";
import { combinedActions } from "~/state";
import { useFeedbackSurveyState } from "~/state/feedbackSurvey";
import { ISaveSurvey, ISurveyResponses } from "~/types/survey";
import { warn } from "~/utils/logging";
import { makeRequest } from "~/utils/request";

// Exported for use in unit tests
export interface ISurveyAnswers {
	answered: string[];
	notAnswered: string[];
	isFinal: boolean;
}

export function makeAnswersForClientResponses(
	responses: ISurveyResponses,
	isFinalSubmission: boolean,
): ISurveyAnswers {
	const answers: ISurveyAnswers = { answered: [], notAnswered: [], isFinal: isFinalSubmission };
	for (const question in responses) {
		if (responses[question]) {
			answers.answered.push(question);
		} else {
			answers.notAnswered.push(question);
		}
	}
	return answers;
}

/**
 * Clear the authentication state and submit a survey
 */
export function useSaveFeedbackSurvey(): ISaveSurvey {
	const JWT = useFeedbackSurveyState((selectors) => selectors.getFeedbackJWT(), []);
	const dispatch = useDispatch();

	const saveSurvey = useCallback(
		(responses: ISurveyResponses, isFinalSubmission: boolean) => {
			if (!JWT) {
				dispatch(combinedActions.clearSurveyState(false));
				return;
			}
			const answers = makeAnswersForClientResponses(responses, isFinalSubmission);

			// Don't call storeSurveyAnswers synchronously, behavior doesn't change if the call fails.
			void storeSurveyAnswers(answers, JWT).catch((error) => {
				warn("Could not save feedback survey", error);
			});
		},
		[JWT, dispatch],
	);

	return saveSurvey;
}

async function storeSurveyAnswers(answers: ISurveyAnswers, jwt: string): Promise<void> {
	// Use keepalive = true to handle case where we save surveys immediately before closing tab/window
	return makeRequest("/api/FeedbackSurvey/StoreSurveyAnswers", "POST", jwt, answers, { keepalive: true });
}
