/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file start screen sharing icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.ScreenShareStart
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum ScreenShareStartIconTestIds {
	self = "ScreenShareStart",
}

/**
 * The ScreenShareStart component
 * @param props The props ;)
 */
const ScreenShareStart: FC<IconProps> = memo((props) => {
	return (
		<svg
			data-testid={ScreenShareStartIconTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
		>
			<path d="M25.52,7.27h-19A1.6,1.6,0,0,0,4.89,8.86V20a1.59,1.59,0,0,0,1.59,1.58h6.35v1.59H9.65v1.59h12.7V23.14H19.17V21.55h6.35A1.59,1.59,0,0,0,27.11,20V8.86A1.6,1.6,0,0,0,25.52,7.27Zm-6,6.16H17.09v5.81a.35.35,0,0,1-.35.35H15.27a.35.35,0,0,1-.35-.35V13.43H12.49a.35.35,0,0,1-.25-.59l3.52-3.51a.33.33,0,0,1,.48,0l3.52,3.51A.35.35,0,0,1,19.51,13.43Z" />
		</svg>
	);
});

ScreenShareStart.displayName = "ScreenShareStart";

export default ScreenShareStart;
