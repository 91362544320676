/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file base banner component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Banner.BannerBase
 */

import React, { ComponentType, FC, memo } from "react";
import { IconProps } from "~/icons";
import { resolveClassName } from "~/utils/className";
import styles from "./Banner.module.scss";

/**
 * Props for BannerBase Component
 */
interface IProps {
	/** Icon to include in the banner */
	icon?: ComponentType<IconProps>;

	/** Props that should be passed to the icon */
	iconProps?: IconProps;

	/** Header string */
	header?: string;

	/** Banner text */
	text?: string;
}

export enum BannerBaseTestIds {
	self = "BannerBase",
}

/**
 * The BannerBase component
 * @param props The props ;)
 */
const BannerBase: FC<IProps> = memo((props) => {
	const { icon: Icon, header, text, children, iconProps = {} } = props;

	const headerClassname = resolveClassName(styles, { noText: !text }, styles["header"]);

	return (
		<div data-testid={BannerBaseTestIds.self} className={styles["banner"]}>
			<span className={styles["iconWrapper"]}>
				{Icon && (
					<div>
						<Icon className={styles["icon"]} height={34} width={34} {...iconProps} />
					</div>
				)}
				<div>
					<div className={headerClassname}>{header}</div>
					<div className={styles["text"]}>{text}</div>
				</div>
			</span>
			{children}
		</div>
	);
});

BannerBase.displayName = "BannerBase";

export default BannerBase;
