/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file the Devices Context
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Components.VideoCall.DeviceContext
 */

import React, { FC, useEffect, useState } from "react";
import { isMobile } from "~/utils/os";
import MobileBackgroundingHandler from "./MobileBackgroundingHandler";
import { useDeviceList } from "./hooks/useDeviceList";
import { useIsDocumentVisible } from "./hooks/useIsDocumentVisible";
import { IAudioContextState, useSingletonAudioContext } from "./hooks/useSingletonAudioContext";

/**
 * Injected context providing a single AudioContext instance
 * so useVolumeLevels hooks will spawn nodes from single source
 */

export interface IDeviceContext extends IAudioContextState {
	devices: MediaDeviceInfo[];
	isVisible: boolean;
}

export const DeviceContext = React.createContext<IDeviceContext>({
	audioContext: null,
	restartAudioContext: () => Promise.resolve(),
	overrideIOSRoute: false,
	devices: [],
	isVisible: !document.hidden,
});

/**
 * Context to allow lobby and call to utilize the same devices hooks and
 * a single AudioContext throughout. Use this wrapper so lobby and video room
 * won't attach these separately, but we won't use them until we've authenticated
 * @param props the props ;)
 */
export const DeviceContextProvider: FC = (props) => {
	const { children } = props;

	// get a list of device and subscribe to updates; will also kick off device auto-selection procedures
	const devices = useDeviceList();
	// provide source-of-truth for single AudioContext & helper flag/function to manage iOS AudioContext lifecycle
	const { audioContext, overrideIOSRoute, restartAudioContext } = useSingletonAudioContext();
	// provide single source-of-truth for 'document.hidden' in response to 'visibilitychange' events
	const isVisible = useIsDocumentVisible();

	// set the devices and audioContext into local state to establish referential identity for the Provider
	const [deviceContext, setDeviceContext] = useState<IDeviceContext>({
		audioContext,
		overrideIOSRoute,
		restartAudioContext,
		devices,
		isVisible,
	});

	useEffect(() => {
		setDeviceContext({ audioContext, overrideIOSRoute, restartAudioContext, devices, isVisible });
	}, [audioContext, devices, overrideIOSRoute, restartAudioContext, isVisible]);

	return (
		<DeviceContext.Provider value={deviceContext}>
			{
				/** In order for some of the mobile backgrounding functions to work properly, that handler must
				 * be a	descendant (child in this case) of the useContext from which AudioContext is provided */
				isMobile() && <MobileBackgroundingHandler isVisible={isVisible} />
			}
			{children}
		</DeviceContext.Provider>
	);
};
DeviceContextProvider.displayName = "DeviceContextProvider";
