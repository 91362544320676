/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file State for holding captured images and associated meta data
 * @author Will Cooper
 * @module Epic.VideoApp.State.ImageCapture
 */
import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { alphabetizeDocTypes } from "~/utils/imageCapture";
import { ITelemedicineDocType } from "../types/imageCapture";

/// TYPES ///

interface ICapturedImage {
	participantId: string;
	imageData: string;
}

export interface IImageCaptureState {
	readonly documentTypes: ITelemedicineDocType[];
	readonly image: string | null;
	readonly participantIdentifier: string;
	readonly isImageBeingSaved: boolean;
	readonly notifyImageCapture: boolean;
}

/// INIT ///

export function getInitialState(): IImageCaptureState {
	return {
		image: null,
		documentTypes: [],
		participantIdentifier: "",
		isImageBeingSaved: false,
		notifyImageCapture: false,
	};
}

/// REDUCERS ///

export function setDocumentTypes(
	state: IImageCaptureState,
	documents: ITelemedicineDocType[],
): IImageCaptureState {
	if (!documents || documents.length === 0) {
		return {
			...state,
			documentTypes: [],
		};
	}

	/** Always initialize with a sorted array */
	return {
		...state,
		documentTypes: documents.sort(alphabetizeDocTypes),
	};
}

export function setImageData(state: IImageCaptureState, params: ICapturedImage | null): IImageCaptureState {
	if (!params) {
		return {
			...state,
			image: null,
			participantIdentifier: "",
		};
	}

	const { imageData, participantId } = params;
	return {
		...state,
		image: imageData,
		participantIdentifier: participantId,
	};
}

function setIsImageBeingSaved(state: IImageCaptureState, result: boolean): IImageCaptureState {
	return {
		...state,
		isImageBeingSaved: result,
	};
}

export function setNotifyImageCapture(state: IImageCaptureState, result: boolean): IImageCaptureState {
	return {
		...state,
		notifyImageCapture: result,
	};
}

/// SELECTORS ///

function getImageData(state: IImageCaptureState): string | null {
	return state.image;
}

function getDocumentTypes(state: IImageCaptureState): ITelemedicineDocType[] {
	return state.documentTypes;
}

function getParticipantId(state: IImageCaptureState): string {
	return state.participantIdentifier;
}

function getIsImageBeingSaved(state: IImageCaptureState): boolean {
	return state.isImageBeingSaved;
}

function getNotifyImageCapture(state: IImageCaptureState): boolean {
	return state.notifyImageCapture;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		setImageData,
		setDocumentTypes,
		setIsImageBeingSaved,
		setNotifyImageCapture,
	},
	selectors: {
		getImageData,
		getDocumentTypes,
		getParticipantId,
		getIsImageBeingSaved,
		getNotifyImageCapture,
	},
});

store.addSharedState(builtState.sharedState, "imageCapture");

export const { actionCreators: imageActions, useSharedState: useImageState, sharedState: state } = builtState;
