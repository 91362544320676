/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file buildSelectorHook function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\store\build-selector-hook
 */

import { IUseSharedStateHook } from "..";
import { IInteralStore } from "./types";
import { useCallback, useRef, useMemo, DependencyList } from "react";
import { useStore, useSelector } from "react-redux";
import { buildWrappedSelector } from "./build-wrapped-selector";

export function buildSelectorHook(id: number): IUseSharedStateHook<any> {
	return (selector, deps: DependencyList) => {
		const { _$_boosterInfo } = (useStore() as unknown) as IInteralStore;
		const { accessorCollection, accessorDependencies, sliceKeyCollection } = _$_boosterInfo;
		const requiredSliceIds = accessorDependencies[id];
		const accessors = accessorCollection[id];

		// The refs have to be obtained unconditionally at the "top level" of this custom hook, according the Rules of Hooks.
		const prevSlice = useRef(undefined as any);
		const prevValue = useRef(undefined as any);

		// Keep track of the given selector, so we can tell when it has changed
		const prevSelector = useRef(undefined as any);
		const memoizedSelector = useCallback(selector, deps);

		// The creation of the selector wrapper itself is memoized so that we only recreate the wrapper when necessary
		const actualSelector = useMemo(
			() =>
				buildWrappedSelector(
					memoizedSelector,
					accessors,
					sliceKeyCollection,
					id,
					prevSlice,
					prevValue,
					prevSelector,
					requiredSliceIds,
				),
			[
				memoizedSelector,
				accessors,
				sliceKeyCollection,
				id,
				prevSlice,
				prevValue,
				prevSelector,
				requiredSliceIds,
			],
		);

		// We need to call useSelector to set up a subscription to the Store.
		return useSelector(actualSelector) as any;
	};
}
