/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file in call controls header component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.ControlsHeader
 */

import React, { FC } from "react";
import HeaderBar from "./HeaderBar";
import InHeaderControls from "./InHeaderControls";
import Trays from "./Trays";

/**
 * The ControlsHeader component
 */
const ControlsHeader: FC = () => {
	return (
		<header>
			<HeaderBar>
				<InHeaderControls />
			</HeaderBar>
			<Trays />
		</header>
	);
};

ControlsHeader.displayName = "ControlsHeader";

export default ControlsHeader;
