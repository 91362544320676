/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Modal alert for image display
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Alerts.DiscardAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { alertActions, imageActions } from "~/state";
import { IChoiceAlert } from "../../types/alerts";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IChoiceAlert;
}

export enum DiscardAlertTestIds {
	self = "DiscardAlert",
}

/**
 * Discard Alert
 *
 * A warning to ensure end users know they will lose the current image if they close the preview pane
 */
const DiscardAlert: FC<IProps> = (props) => {
	const { alert } = props;
	const dispatch = useDispatch();

	const confirm = useCallback(() => {
		dispatch(imageActions.setImageData(null));
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	return (
		<ChoiceAlertBase
			data-testid={DiscardAlertTestIds.self}
			alert={alert}
			confirm={confirm}
			cancel={cancel}
		/>
	);
};

DiscardAlert.displayName = "DiscardAlert";

export default DiscardAlert;
