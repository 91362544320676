/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Supported Browser List Component
 * @author Matt Panico
 * @module Epic.VideoApp.Components.BrowserList.BrowserList
 */

import React, { FC } from "react";
import { getSupportedBrowsers } from "~/models/browsers/supportedBrowsers";
import { detectOS } from "../../utils/os";
import BrowserLink from "./BrowserLink";
import styles from "./BrowserList.module.scss";

/**
 * The BrowserList component
 */
const BrowserList: FC = () => {
	const browsers = getSupportedBrowsers(detectOS());

	return (
		<div className={styles["browserList"]}>
			{browsers.map((browser, index) => (
				<BrowserLink browser={browser} key={index} />
			))}
		</div>
	);
};

BrowserList.displayName = "BrowserList";

export default BrowserList;
