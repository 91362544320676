/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Helper component to manage which icons to display in the Participant List for a given participant
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.ParticipantList.ParticipantIcons
 */

import React, { FC } from "react";
import Bell from "~/icons/bell";
import { useModerationState, useUserState } from "~/state";
import { IStreamStatus } from "~/types";
import { getCameraIcon, getMicIcon, getScreenShareIcon } from "~/utils/device";
import { IUser } from "~/web-core/interfaces";
import styles from "./ParticipantList.module.scss";

interface IProps {
	participant: IUser;
	streamStatus: { audio: IStreamStatus; video: IStreamStatus; screenShare: IStreamStatus };
}

/**
 * The ParticipantIcons component
 */
const ParticipantIcons: FC<IProps> = (props) => {
	const { participant, streamStatus } = props;
	const { audio: micStatus, video: camStatus, screenShare: screenShareStatus } = streamStatus;
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);
	const requestInfo = useModerationState(
		(selectors) => selectors.getParticipantRequest(participant.getUserIdentity()),
		[participant.getUserIdentity()],
	);

	const requested = requestInfo.audio || requestInfo.video || requestInfo.screenShare;

	const cameraIcon = getCameraIcon(camStatus.enabled, camStatus.locked);
	const micIcon = getMicIcon(micStatus.enabled, micStatus.locked);
	// In the header, we display the 'start' icon when the user is not currently sharing,
	// but in this moderation context, we want to display the 'stop' icon and vice versa
	const screenShareIcon = getScreenShareIcon(!screenShareStatus.enabled, screenShareStatus.locked);

	// For moderators, show a request/raise hand icon for any locked out devices
	// Also show whether the devices are on or off
	return (
		<div className={styles["iconsWrapper"]}>
			{canModerate && requested && (
				<Bell className={styles["bell"]} height={25} width={25} aria-hidden />
			)}
			{!micStatus.enabled && React.createElement(micIcon)}
			{!camStatus.enabled && React.createElement(cameraIcon)}
			{(screenShareStatus.enabled || screenShareStatus.locked) && React.createElement(screenShareIcon)}
		</div>
	);
};

ParticipantIcons.displayName = "ParticipantIcons";

export default ParticipantIcons;
