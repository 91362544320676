/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Button used to open a simple drop down that has options to request audio and video access from a moderator
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Buttons.OpenRequestDeviceMenuButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useState } from "react";
import SimpleRequestMenu from "~/components/Participants/Moderation/SimpleRequestMenu";
import { useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import chevron from "~/icons/chevron";
import { uiActions, useUIState } from "~/state";
import { useUserState } from "~/state/user";
import { EpicUserType } from "~/types";
import { resolveClassName } from "~/utils/className";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import styles from "../ControlsHeader.module.scss";
import GenericMenuPage from "../Menu/GenericMenuPage";
import ControlButton from "./ControlButton";

interface IProps {
	noTooltip?: boolean;
}

enum TokenNames {
	label = "Label",
	labelGeneric = "LabelGeneric",
}
/**
 * The OpenRequestDeviceMenuButton  component
 */
const OpenRequestDeviceMenuButton: FC<IProps> = (props) => {
	const { noTooltip } = props;
	const [micLocked, cameraLocked, screenShareLocked, userType, screenShareAllowed] = useUserState(
		(selectors) => [
			selectors.getMicLock(),
			selectors.getCameraLock(),
			selectors.getScreenShareLock(),
			selectors.getUserType(),
			selectors.getUserPermission("canShareScreen"),
		],
		[],
	);
	const screenShareSupported = useIsScreenShareSupported();
	const displayScreenShareOptions = screenShareSupported && screenShareAllowed;
	const visibleMenu = useUIState((selectors) => selectors.getVisibleMenu(), []);
	const menuOpen = useUIState((selectors) => selectors.getRequestMenuOpen(), []);
	const [menuOpened, setWasOpened] = useState<boolean>(false);

	const strings = useStrings("RequestOptions", Object.values(TokenNames));
	const menuClassName = resolveClassName(styles, { soloMenu: true });
	const dispatch = useDispatch();

	const onClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			event?.nativeEvent.stopImmediatePropagation();
			dispatch(uiActions.setRequestMenuOpen(!menuOpen));
		},
		[dispatch, menuOpen],
	);

	const onClickOutsideMenu = useCallback(() => {
		dispatch(uiActions.setRequestMenuOpen(false));
	}, [dispatch]);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLButtonElement>) => {
			// when the menu is open, if the user reverse tabs, close the menu
			if (menuOpen && event.key.toLowerCase() === "tab" && event.shiftKey) {
				dispatch(uiActions.setRequestMenuOpen(false));
			}
		},
		[dispatch, menuOpen],
	);

	useEffect(() => {
		if (menuOpen) {
			dispatch(uiActions.toggleVisibleMenu({ menu: null }));
			setWasOpened(true);
		}
	}, [dispatch, menuOpen]);

	/** Clean-up useEffect */
	useEffect(() => {
		if (!cameraLocked && !micLocked) {
			// Reset notification state when moderation state was cleared
			dispatch(uiActions.setRequestMenuOpen(false));
		}

		if (visibleMenu) {
			// Clean up this menu if another opens
			dispatch(uiActions.setRequestMenuOpen(false));
		}
	}, [cameraLocked, dispatch, micLocked, visibleMenu]);

	const pressed = menuOpen;

	const className = resolveClassName(styles, {
		notification: !menuOpened,
		toggleChevron: true,
		collapsed: !menuOpen,
		minimized: true,
	});

	const openKeyboardShortcut = ["alt", "q"];
	let label = displayScreenShareOptions ? strings[TokenNames.labelGeneric] : strings[TokenNames.label];
	label = useGetFormattedHotkeyString(label, openKeyboardShortcut);

	if (
		(!micLocked && !cameraLocked && !(displayScreenShareOptions && screenShareLocked)) ||
		userType === EpicUserType.emp
	) {
		return null;
	}

	return (
		<div>
			<ControlButton
				icon={chevron}
				ariaLabel={label}
				onClick={onClick}
				pressed={pressed}
				tooltipText={pressed || noTooltip ? undefined : label}
				keyboardShortcut={openKeyboardShortcut}
				buttonClassName={className}
				onKeyDown={onKeyDown}
			/>
			<GenericMenuPage
				enabled={menuOpen}
				closeCallback={onClickOutsideMenu}
				wrapperClassName={menuClassName}
			>
				<SimpleRequestMenu />
			</GenericMenuPage>
		</div>
	);
};

OpenRequestDeviceMenuButton.displayName = "OpenRequestDeviceMenuButton";

export default OpenRequestDeviceMenuButton;
