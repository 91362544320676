/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file ParticipantList menu header buttons for allowing access to audio and performing mute all functions
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.ParticipantList.ParticipantActionHeader
 */

import React, { FC, useCallback } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { IBasicAuditEvent, useAuditFeatureUse, useStrings } from "~/hooks";
import { useSendModeratorAction } from "~/hooks/messaging";
import { EventType, IParticipantWithName } from "~/types";
import styles from "./ParticipantList.module.scss";

interface IProps {
	sortedParticipants: IParticipantWithName[];
}

/** String tokens used by the ParticipantMenuSection -> ParticipantActionHeader component */
export enum TokenNames {
	allowAll = "AllowAll",
	disableAll = "DisableAll",
	allowAllAriaLabel = "AllowAllAriaLabel",
	disableAllAriaLabel = "DisableAllAriaLabel",
}

/**
 * The ParticipantActionHeader component
 */
const ParticipantActionHeader: FC<IProps> = (props) => {
	const { sortedParticipants } = props;
	const sendModeratorMessage = useSendModeratorAction();
	const auditFeatureUse = useAuditFeatureUse();

	const strings = useStrings("ParticipantMenuSection", Object.values(TokenNames));

	const onDisableClick = useCallback(() => {
		const events: IBasicAuditEvent[] = [];
		sortedParticipants.forEach((participant) => {
			sendModeratorMessage({ audioLock: true }, participant.participant.getUserIdentity());
			events.push({
				feature: EventType.microphoneLocked,
				targetParticipantId: participant.participant.getUserIdentity(),
			});
		});

		void auditFeatureUse(events);
	}, [sortedParticipants, auditFeatureUse, sendModeratorMessage]);

	const onAllowClick = useCallback(() => {
		const events: IBasicAuditEvent[] = [];

		sortedParticipants.forEach((participant) => {
			sendModeratorMessage({ audioLock: false }, participant.participant.getUserIdentity());
			events.push({
				feature: EventType.microphoneUnlocked,
				targetParticipantId: participant.participant.getUserIdentity(),
			});
		});

		void auditFeatureUse(events);
	}, [sortedParticipants, auditFeatureUse, sendModeratorMessage]);

	return (
		<div className={styles["actionButtonRow"]}>
			<ActionButton
				tone="negative"
				priority="primary"
				onClick={onDisableClick}
				aria-label={strings[TokenNames.disableAllAriaLabel]}
			>
				{strings[TokenNames.disableAll]}
			</ActionButton>
			<ActionButton
				tone="neutral"
				priority="primary"
				onClick={onAllowClick}
				aria-label={strings[TokenNames.allowAllAriaLabel]}
			>
				{strings[TokenNames.allowAll]}
			</ActionButton>
		</div>
	);
};

ParticipantActionHeader.displayName = "ParticipantActionHeader";

export default ParticipantActionHeader;
