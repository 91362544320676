/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file resolve complex classNames
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.ClassName
 */

interface IStyleModule {
	[className: string]: string;
}

interface IOptionalClassNames {
	[className: string]: boolean | undefined;
}

/**
 * Resolve a set of optional classNames to a single className string.
 * Class names passed to optionalClassNames should be included as { <CSS class name>: <should be included> } (ex: { disabled: true })
 * For variable class names this can be done as { [classNameVariable]: <should be included> }
 * @param styles style module that the classNames come from
 * @param optionalClasses classNames to be optionally included in the className string
 * @param rawClassName base className that should always be included in className string, but doesn't come from styles module
 * @returns className string
 */
export function resolveClassName(
	styles: IStyleModule,
	optionalClasses: IOptionalClassNames,
	rawClassName?: string,
): string {
	const classNames = Object.keys(optionalClasses)
		.filter((className) => optionalClasses[className])
		.map((className) => styles[className]);

	if (rawClassName) {
		classNames.push(rawClassName);
	}

	return classNames.join(" ");
}
