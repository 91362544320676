/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file buildCombinedReducers function
 * @author Roland Scott
 * @module react-redux-booster\src\build-combined-reducers
 */

import { buildActionCreators } from "./internal/build-action-creators";
import { InferActionCreatorsFromReducers, IReducerCollection } from "./types";
import { getNextId } from "./internal/get-next-id";
import { IHaveCombinedReducersMetadata } from "./internal/types";

/**
 * Build an object containing a group of combined reducers (reducers which have access to multiple
 * pieces of shared state).
 *
 * This object also acts as the action creators for your store, so you use these to dispatch the combined
 * actions to your store.
 *
 * @param reducers The reducer functions to be included in this group
 * @returns an object containing actions creators whose results can be dispatched to the store
 */
export function buildCombinedReducers<TReducers extends IReducerCollection<any>>(
	reducers: TReducers,
): InferActionCreatorsFromReducers<TReducers> {
	const id = getNextId();
	const retVal = buildActionCreators<any, TReducers>(id, reducers);
	((retVal as any) as IHaveCombinedReducersMetadata).__$$crmd = { id, reducers };
	return retVal;
}
