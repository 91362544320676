/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Processes Twilio-surfaced errors into meaningful errors for logging
 * @author Chance Overberg
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.Functions.ProcessTwilioError
 */

import { TwilioError } from "twilio-video";
import { DeviceStatus, DeviceStatusSubtype, HardwareTestError } from "~/types";
import { warn } from "~/utils/logging";
import { SessionErrorCodes } from "~/web-core/interfaces";
import { DeviceType } from "~/web-core/types";
import { RoomErrorCodes } from "../twilioValues";

export function processTwilioError(error: TwilioError): IDeviceUpdate[] {
	const { name, message } = error;

	// useAudioTrackActions & useVideoTrackActions will pass a source to indicate what devices should have errors
	// from useInitialLocalTracks, even specific errors will lead to the other device having an unknown status
	const twilioError = parseTwilioError(message || "");

	const updates = buildErrorResponse(twilioError);

	warn(`${name}: ${message}`);

	return updates;
}

export interface IDeviceUpdate {
	status: DeviceStatus;
	errorType: DeviceStatusSubtype;
	device: DeviceType;
}

export function isTwilioError(error: unknown): boolean {
	return (error as TwilioError).code !== undefined && (error as TwilioError).message !== undefined;
}

function buildErrorResponse(testError: HardwareTestError): IDeviceUpdate[] {
	const errorUpdates: IDeviceUpdate[] = [];
	switch (testError) {
		case HardwareTestError.generalError:
			errorUpdates.push({
				device: "camera",
				status: DeviceStatus.error,
				errorType: DeviceStatusSubtype.general,
			});

			errorUpdates.push({
				device: "mic",
				status: DeviceStatus.error,
				errorType: DeviceStatusSubtype.general,
			});
			break;
		case HardwareTestError.unknown:
			errorUpdates.push({
				device: "camera",
				status: DeviceStatus.error,
				errorType: DeviceStatusSubtype.unknown,
			});
			errorUpdates.push({
				device: "mic",
				status: DeviceStatus.error,
				errorType: DeviceStatusSubtype.unknown,
			});
			break;
		case HardwareTestError.permissionsError:
			errorUpdates.push({
				device: "camera",
				status: DeviceStatus.error,
				errorType: DeviceStatusSubtype.permissionsError,
			});
			errorUpdates.push({
				device: "mic",
				status: DeviceStatus.error,
				errorType: DeviceStatusSubtype.permissionsError,
			});
			break;
		case HardwareTestError.cameraError:
			errorUpdates.push({
				device: "camera",
				status: DeviceStatus.error,
				errorType: DeviceStatusSubtype.hardwareError,
			});
			errorUpdates.push({
				device: "mic",
				status: DeviceStatus.unknown,
				errorType: DeviceStatusSubtype.unknown,
			});
			break;
		case HardwareTestError.microphoneError:
			errorUpdates.push({
				device: "camera",
				status: DeviceStatus.unknown,
				errorType: DeviceStatusSubtype.unknown,
			});
			errorUpdates.push({
				device: "mic",
				status: DeviceStatus.error,
				errorType: DeviceStatusSubtype.hardwareError,
			});
			break;
	}

	return errorUpdates;
}

function parseTwilioError(twilioErrorMessage: string): HardwareTestError {
	switch (twilioErrorMessage) {
		// Generic device acquisition
		case "The I/O read operation failed.":
		case "Requested device not found":
		case "Device in use":
			return HardwareTestError.generalError;
		// Microphone
		case "Could not start audio source":
		case "Failed starting capture of a audio track":
		case "Failed to allocate audiosource":
			return HardwareTestError.microphoneError;
		// Camera
		case "Could not start video source":
		case "Failed starting capture of a video track":
		case "Failed to allocate videosource":
			return HardwareTestError.cameraError;
		// Permissions
		case "Permission denied":
		case "Permission dismissed":
		case "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.":
		case "The request is not allowed by the user agent or the platform in the current context.":
			return HardwareTestError.permissionsError;
		// Default
		default:
			return HardwareTestError.unknown;
	}
}

/**
 * Map error codes from Twilio to the generic error types EVC runs on.
 * @param twilioErrorCode - The int value of the Twilio error code.
 * @returns - The corresponding EVC error code, or unknown if not found.
 */
export function twilioRoomErrorToSessionError(twilioErrorCode: RoomErrorCodes): SessionErrorCodes {
	switch (twilioErrorCode) {
		case RoomErrorCodes.participantDuplicateIdentityError:
			return SessionErrorCodes.participantDuplicateIdentityError;
		case RoomErrorCodes.roomCompletedError:
			return SessionErrorCodes.roomCompletedError;
		case RoomErrorCodes.roomMaxParticipantsExceededError:
			return SessionErrorCodes.roomMaxParticipantsExceededError;
		default:
			return SessionErrorCodes.unknown;
	}
}
