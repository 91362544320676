/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file change camera icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.ChangeCamera
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The ChangeCamera component
 * @param props The props ;)
 */
const ChangeCamera: FC<IconProps> = memo((props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
			<path
				fill="#000000"
				d="M26.65,10h-4.3l-3-3H12.62l-3,3H5.35S3.72,10,3.72,12.18v11A1.72,1.72,0,0,0,5.53,25H26.47a1.72,1.72,0,0,0,1.81-1.81v-11C28.28,10,26.65,10,26.65,10ZM9.48,15.63a.26.26,0,0,1,.24-.14h1.53a1.42,1.42,0,0,1,0-.2V12.75a1.1,1.1,0,0,1,1.1-1.1H19.5a1.1,1.1,0,0,1,0,2.19H13.4v1.45a1.42,1.42,0,0,1,0,.2h1.53a.26.26,0,0,1,.22.42l-2.59,3.41a.26.26,0,0,1-.22.1.25.25,0,0,1-.21-.1L9.5,15.91A.26.26,0,0,1,9.48,15.63ZM22.32,19a.26.26,0,0,1-.24.15H20.55a.71.71,0,0,1,0,.2v2.54A1.09,1.09,0,0,1,19.5,23H12.31a1.1,1.1,0,1,1,0-2.19H18.4V19.35a1.42,1.42,0,0,1,0-.2H16.91a.27.27,0,0,1-.24-.15.24.24,0,0,1,0-.28l2.58-3.41a.29.29,0,0,1,.22-.1.28.28,0,0,1,.21.1l2.59,3.41A.26.26,0,0,1,22.32,19Z"
			/>
			<path
				fill="#ffffff"
				d="M28.14,9.15h-4.9L19.85,5.77h-7.7L8.76,9.15H3.86S2,9.1,2,11.65V24.17a2,2,0,0,0,2.06,2.06H27.94A2,2,0,0,0,30,24.17V11.65C30,9.1,28.14,9.15,28.14,9.15ZM8.27,15.4a.33.33,0,0,1,.3-.19h1.72V12.29a1.5,1.5,0,0,1,1.5-1.5H20a1.5,1.5,0,1,1,0,3h-6.7v1.4a0,0,0,0,0,0,0H15a.33.33,0,0,1,.3.19.35.35,0,0,1,0,.34L12.05,20a.33.33,0,0,1-.26.13.34.34,0,0,1-.27-.13L8.31,15.74A.32.32,0,0,1,8.27,15.4ZM23.5,19.71a.34.34,0,0,1-.3.18H21.48s0,0,0,0v2.9a1.5,1.5,0,0,1-1.5,1.5h-8.2a1.5,1.5,0,0,1,0-3h6.7V19.89H16.77a.35.35,0,0,1-.3-.18.34.34,0,0,1,0-.35l3.21-4.23A.35.35,0,0,1,20,15a.33.33,0,0,1,.26.13l3.22,4.23A.34.34,0,0,1,23.5,19.71Z"
			/>
		</svg>
	);
});

ChangeCamera.displayName = "ChangeCamera";

export default ChangeCamera;
