/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Component to facilitate peer-to-peer communication
 * @author Max Harkins
 * @module Epic.VideoApp.WebCore.Components.PeerSignaler
 */

import React, { FC } from "react";
import DataTracksListener from "~/components/VideoCall/DataTracks/DataTracksListener";
import { useReceivedDataTrackMessage } from "~/hooks/messaging";
import { INamelessParticipantSyncRequestPayload, INamelessParticipantSyncResponsePayload } from "~/types";
import { IRemoteUser, ISession } from "../interfaces";
import { TwilioRemoteUser, TwilioSession } from "../vendor/twilio/implementations";
import RemoteUserSignalHandler from "./RemoteUserSignalHandler";

interface IProps {
	handleNamelessRequest: (payload: INamelessParticipantSyncRequestPayload) => void;
	handleNamelessResponse: (payload: INamelessParticipantSyncResponsePayload) => void;
	localUserIdentity: string;
	participants: IRemoteUser[];
	session: ISession;
}

/**
 * The PeerSignaler component
 */
const PeerSignaler: FC<IProps> = (props: IProps) => {
	const { handleNamelessRequest, handleNamelessResponse, localUserIdentity, session, participants } = props;

	// Create one interface so we only ever process one "message received event"
	const messageHandler = useReceivedDataTrackMessage(
		localUserIdentity,
		handleNamelessRequest,
		handleNamelessResponse,
	);

	// Twilio data tracks has additional special case handling to attempt to resubscribe to data tracks
	// and also relies on handling data track publications which were not yet implemented on the web-core layer
	//
	if (session instanceof TwilioSession) {
		return (
			<DataTracksListener users={participants as TwilioRemoteUser[]} handleMessage={messageHandler} />
		);
	}

	return (
		<div>
			{participants.map((participant) => {
				return (
					<RemoteUserSignalHandler
						key={participant.getUserIdentity()}
						participant={participant}
						messageHandler={messageHandler}
					/>
				);
			})}
		</div>
	);
};

PeerSignaler.displayName = "PeerSignaler";

export default PeerSignaler;
