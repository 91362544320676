/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Entry point for the application
 * @author Erv Walter
 * @module Epic.VideoApp.Index
 */

import React from "react";
import { render } from "react-dom";
import "regenerator-runtime/runtime";
import App from "~/app/App";
import "~/styles/index.scss";

render(<App />, document.getElementById("root"));
