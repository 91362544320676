/**
 * @copyright Copyright 2020-201 Epic Systems Corporation
 * @file Modal alert for confirming user disconnection
 * @author Spencer Eanes + Will Cooper
 * @module Epic.VideoApp.Components.Alerts.TimeoutChoiceAlert
 */
import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import { useDisconnect } from "~/hooks";
import { useJoinRoom } from "~/hooks/useJoinRoom";
import { alertActions, errorPageActions, useHardwareTestState } from "~/state";
import { HardwareTestStatus, IChoiceAlert, LobbyPopupTimeMS, Timeout } from "~/types";
import AlertLocalTimer from "./AlertLocalTimer";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IChoiceAlert;
}

export enum TimeoutChoiceAlertTestIds {
	self = "TimeoutChoiceAlert",
}

/**
 * Disconnection Choice alert component
 *
 * Renders a confirmation message that the user intends to disconnect and two buttons to confirm the action or cancel it.
 * Timeout waits five minutes.
 */
const TimeoutChoiceAlert: FC<IProps> = (props) => {
	const { alert } = props;
	const [handle, setHandle] = useState<Timeout>();
	const dispatch = useDispatch();
	const joinRoom = useJoinRoom();
	const disconnect = useDisconnect();
	const disconnectRef = useRef(disconnect);

	const status = useHardwareTestState(
		(selectors) => selectors.getTestStatus({ isStandalone: false, allowOneError: true }),
		[],
	);

	const testPassed = useMemo(() => status === HardwareTestStatus.passed, [status]);

	useEffect(() => {
		disconnectRef.current = disconnect;
	}, [disconnect]);

	// This timeout is created when the alert is rendered (after four minutes in the lobby)
	// It is cleared below when the user chooses to join the call
	// If the user dismisses the pop-up, they will still have this event waiting to fire.
	// It should also be cleared when joining the visit via the "Join Call" button.
	useEffect(() => {
		const timeoutDisconnect: Timeout = setTimeout(() => {
			dispatch(alertActions.clearAlert());
			dispatch(
				errorPageActions.setErrorCard({
					title: ErrorTokenNames.timeoutHeader,
					message: ErrorTokenNames.timeoutMessage,
				}),
			);
			disconnectRef.current(true);
		}, LobbyPopupTimeMS);
		setHandle(timeoutDisconnect);
	}, [dispatch]);

	const confirm = useCallback(() => {
		dispatch(alertActions.clearAlert());
		if (handle) {
			dispatch(alertActions.setDisconnectionTimeout(handle));
		}
	}, [dispatch, handle]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
		if (status === HardwareTestStatus.passed) {
			if (handle) {
				clearTimeout(handle);
			}
			joinRoom();
		} else {
			// If the hardware test has not been passed, save the handle off to allow user to attempt to pass test and join before the timer runs out
			if (handle) {
				dispatch(alertActions.setDisconnectionTimeout(handle));
			}
		}
	}, [dispatch, status, handle, joinRoom]);

	return (
		<ChoiceAlertBase
			data-testid={TimeoutChoiceAlertTestIds.self}
			alert={alert}
			confirm={confirm}
			cancel={cancel}
			cancelDisabled={!testPassed}
		>
			<AlertLocalTimer initialTime={LobbyPopupTimeMS} />
		</ChoiceAlertBase>
	);
};

TimeoutChoiceAlert.displayName = "TimeoutChoiceAlert";

export default TimeoutChoiceAlert;
