/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Hook to sort and track all participants within the grid view
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.UseBucketedParticipants
 */

import { useContext, useMemo, useRef } from "react";
import { useStrings } from "~/hooks";
import { IParticipantWithName } from "~/types";
import { stringFormat } from "~/utils/strings";
import { ParticipantDataContext } from "~/web-core/components";
import { IUser } from "~/web-core/interfaces";
import { useRoomState } from "../state";

enum TokenNames {
	genericName = "DefaultName_Generic",
	numberedName = "DefaultName_Numbered",
}

interface IIndexedParticipant {
	index: number;
	participant: IUser;
}

/**
 * Hook to return a combined set of participants containing all users that have set names, and those with default names (Caller X)
 * These will separated into two groups to allow for better sorting and more graceful insertion into areas like the grid.
 * @param participants - The list of participants to retrieve names for and place into two sorted buckets
 * @returns - The combined list of participants in their sorted buckets
 */
export function useBucketedParticipants(participants: IUser[]): IParticipantWithName[] {
	const setNames = useRoomState((selectors) => selectors.getAllParticipantNames(), []);
	const strings = useStrings("useParticipantName", Object.values(TokenNames), [], "en-us");
	const stringsRef = useRef(strings);
	const { namelessParticipantData } = useContext(ParticipantDataContext);

	const allSortedParticipants = useMemo(() => {
		const participantsWithSetName: IParticipantWithName[] = [];
		const namelessParticipants: IParticipantWithName[] = [];
		const namelessIndexes: IIndexedParticipant[] = [];

		participants.forEach((participant) => {
			const identity = participant.getUserIdentity();
			const displayName = identity ? setNames[identity] : "";
			if (displayName) {
				participantsWithSetName.push({ displayName, participant });
			} else if (namelessParticipantData.dataIsShared) {
				// Add this participant based on their index if found
				const index = namelessParticipantData.idList.findIndex((current) => current === identity);

				if (index > -1) {
					// Track any nameless participants that we can confirm will be (Caller X), these will be sorted by number
					namelessIndexes.push({
						index,
						participant,
					});
				} else {
					// Track all other nameless participants (append to end)
					namelessParticipants.push({
						displayName: stringsRef.current[TokenNames.genericName],
						participant,
					});
				}
			} else {
				// Track all other nameless participants (append to end)
				namelessParticipants.push({
					displayName: stringsRef.current[TokenNames.genericName],
					participant,
				});
			}
		});

		// Sort all participants with shared display names (those set at the hardware test card) in alphabetical order
		const namedParticipants = participantsWithSetName.sort((a, b) =>
			a.displayName.localeCompare(b.displayName),
		);

		// Put all unnamed callers in index order
		const numberedParticipants = namelessIndexes
			.sort((a, b) => a.index - b.index)
			.map((current): IParticipantWithName => {
				return {
					participant: current.participant,
					displayName: stringFormat(stringsRef.current[TokenNames.numberedName], current.index + 1),
				};
			});

		// Bucket participants to first show named participants, then numbered participants, then any out of sync default named participants
		return namedParticipants.concat(numberedParticipants).concat(namelessParticipants);
	}, [namelessParticipantData.dataIsShared, namelessParticipantData.idList, participants, setNames]);

	return allSortedParticipants;
}
