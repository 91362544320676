/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file the Video Call component
 * @author Matt Panico
 * @module Epic.VideoApp.Features.VideoCall.VideoCall
 */

import { RouteComponentProps } from "@reach/router";
import { EventEmitter } from "events";
import React, { FC, useEffect } from "react";
import { useLoadFeedbackSurveyQuestionnaire } from "~/components/FeedbackSurvey/hooks/useLoadFeedbackSurveyQuestionnaire";
import Loading from "~/components/Loading/Loading";
import FeatureWrapper from "~/components/StandardLayout/FeatureWrapper";
import { DeviceContextProvider } from "~/components/VideoCall/DeviceContext";
import { useClientLogging, useDocumentTitle, useSharedStrings, useVideoCallAuthentication } from "~/hooks";
import { useAuthState, useRoomState } from "~/state";
import { SharedStringTokens } from "~/utils/strings";
import { VideoSessionProvider } from "~/web-core/components";
import DisconnectionPage from "../disconnection-page/DisconnectionPage";
import InnerCall from "./InnerCall";

export enum VideoCallTestIds {
	lobbyHardwareTest = "VideoCallLobbyHardwareTest",
	videoRoom = "VideoCallVideoRoom",
	disconnectionPage = "VideoCallDisconnectionPage",
	loading = "VideoCallLoading",
}

/**
 * The VideoCall component
 * @param _props The props ;)
 */
const VideoCall: FC<RouteComponentProps> = (_props) => {
	EventEmitter.defaultMaxListeners = 25;
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);
	const isDisconnecting = useRoomState((selectors) => selectors.getIsDisconnecting(), []);

	useClientLogging();

	// pre-load the questionnaire and save into state
	useLoadFeedbackSurveyQuestionnaire();

	const { tryAuthenticate } = useVideoCallAuthentication();
	useEffect(() => {
		void tryAuthenticate();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const strings = useSharedStrings([
		SharedStringTokens.defaultPageTitle,
		SharedStringTokens.connectingToOrganization,
	]);
	useDocumentTitle(strings[SharedStringTokens.defaultPageTitle]);

	if (!JWT || isDisconnecting) {
		return (
			<FeatureWrapper>
				{isDisconnecting && <DisconnectionPage />}
				{!isDisconnecting && (
					<Loading loadingMessage={strings[SharedStringTokens.connectingToOrganization]} />
				)}
			</FeatureWrapper>
		);
	}

	return (
		<VideoSessionProvider isVideoCall>
			<FeatureWrapper>
				<DeviceContextProvider>
					<InnerCall />
				</DeviceContextProvider>
			</FeatureWrapper>
		</VideoSessionProvider>
	);
};

VideoCall.displayName = "VideoCall";

export default VideoCall;
