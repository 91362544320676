/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Hook to monitor changes to an instance of media's audio or video status
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Hooks.UseStream
 */

import { useEffect, useRef, useState } from "react";
import { IEVCParticipantUpdatedEvent } from "~/web-core/events";
import { ILocalStream, ILocalUser, IRemoteStream, IRemoteUser, IStream, IUser } from "~/web-core/interfaces";
import { VideoType } from "~/web-core/types";

type StreamOfUser<TUser> = TUser extends ILocalUser
	? ILocalStream
	: TUser extends IRemoteUser
	? IRemoteStream
	: IStream;

export function useStream<TUser extends IUser>(
	user: TUser | undefined,
	videoType: VideoType,
): StreamOfUser<TUser> | undefined {
	const [stream, setStream] = useState<IStream | undefined>(getStreamFromUser(user, videoType) as IStream);

	const videoTypeRef = useRef(videoType);
	const userRef = useRef(user);

	useEffect(() => {
		videoTypeRef.current = videoType;
		userRef.current = user;
	}, [user, videoType]);

	useEffect(() => {
		const updateStream = (args: IEVCParticipantUpdatedEvent): void => {
			/*	When the event fires, this callback runs, and that can happen while the useEffect 
				is re-rendering. Doing this check allows us to confirm the event is still valid for 
				this specific user's stream. 
			*/
			if (
				userRef.current?.getUserIdentity() !== user?.getUserIdentity() ||
				videoTypeRef.current !== args.videoType
			) {
				return;
			}

			const stream = getStreamFromUser(args.participant, videoTypeRef.current);
			setStream(stream ? stream : undefined);
		};

		const newStream = getStreamFromUser(user, videoTypeRef.current);
		setStream(newStream);
		user?.on("participantUpdated", updateStream);

		return () => {
			user?.off("participantUpdated", updateStream);
		};
	}, [user, videoType]);

	return stream as StreamOfUser<TUser>;
}

function getStreamFromUser(user: IUser | undefined, type: VideoType): IStream | undefined {
	if (!user) {
		return undefined;
	}

	const stream = type === "camera" ? user.deviceStream : user.shareStream;

	return stream ?? undefined;
}
