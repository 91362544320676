/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Test Network routes
 * @author Noah Allen
 * @module Epic.VideoApp.Features.TestNetwork.TestNetwork
 */

import { IAddRouteFunction } from "~/app/routes";
import TestNetwork from "./TestNetwork";

/*
 *
 * IMPORTANT! READ THIS!
 *
 * The contents of this file are loaded and executed during the initial load of the application
 * in the browser even if the route is never actually accessed. DO NOT put any additional code
 * in this file, or import any addition modules as doing so would just add extra bloat to the
 * initial load / startup time for the application.
 *
 * IMPORTANT! READ THIS!
 *
 */

/**
 * Return the URL used to access this page
 */
export function getTestModeUrl(): string {
	return "/TestNetwork";
}

/**
 * Return any URLs that should redirect to this page
 */
export function getTestModeRedirectPaths(): string[] {
	return ["/Home/TestNetwork"];
}

/**
 * Return whether or not this page requires WebRTC
 */
export function getTestModeRequiresWebRTC(): boolean {
	return true;
}

/**
 * Register the routes associated with this page
 *
 * @param addRoute A function used to add a route
 */
export default function register(addRoute: IAddRouteFunction): void {
	addRoute(getTestModeUrl(), TestNetwork, getTestModeRedirectPaths(), getTestModeRequiresWebRTC());
}
