/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to get the pages in the menu
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Hooks.UseMenuPages
 */

import React, { useMemo, useRef } from "react";
import BackgroundSelector from "~/components/BackgroundSelector/BackgroundSelector";
import AboutEvc from "~/components/Copyright/AboutEvc";
import HardwareSetup from "~/components/HardwareSetup/HardwareSetup";
import LanguageSelector from "~/components/LanguageSelector/LanguageSelector";
import AdmitOptionsMenuPage from "~/components/Participants/Moderation/AdmitOptionsMenu";
import ParticipantControlMenu from "~/components/Participants/Moderation/ParticipantControlMenu";
import ParticipantList from "~/components/Participants/ParticipantList/ParticipantList";
import { useStrings } from "~/hooks";
import { DisplayContext, Menu } from "~/types";
import { aboutEvcButtonId } from "../Buttons/AboutEvcButton";
import { admitOptionsButtonId } from "../Buttons/AdmitOptionsButton";
import { changeBackgroundButtonId } from "../Buttons/ChangeBackgroundButton";
import { languagesButtonId } from "../Buttons/LanguageSelectorButton";
import { manageDevicesButtonId } from "../Buttons/ManageDevicesButton";
import { participantsButtonId } from "../Buttons/ParticipantsButton";
import BaseMenu from "../Menu/BaseMenu";
import { useShowParticipantButtonInHeader } from "../hooks/useShowParticipantButtonInHeader";

export interface IMenuPage {
	/** Which menu page it is */
	menu: Menu;

	/** Which menu page the back button should take you to, if not the main menu */
	parentMenu?: Menu | null;

	/** The page contents or a function to get the contents */
	contents: React.ReactNode | ((isActive: boolean) => React.ReactNode);

	/** Ref to the MenuPage element */
	ref: React.RefObject<HTMLDivElement>;

	/** Menu page's title */
	title?: string;

	/** Whether or not the page uses a dynamic title */
	dynamicTitle?: boolean;

	/** Aria label for the menu page's back button */
	backButtonLabel?: string;

	/** Whether or not the page should always be rendered */
	alwaysRender?: boolean;
}

type PageRefMap = Record<Menu, React.RefObject<HTMLDivElement>>;

interface IMenuPages {
	/** Mapping from Menu to a reference to the MenuPage containing the menu contents */
	pageRefs: React.MutableRefObject<PageRefMap>;

	/** Ordered array of the menu pages to render */
	menuPages: IMenuPage[];
}

// the IDs of the buttons on the base menu, used for giving them focus back after navigation
export const baseMenuButtonIds: Record<Menu, string | null> = {
	menu: null,
	devices: manageDevicesButtonId,
	participants: participantsButtonId,
	languages: languagesButtonId,
	participantOptions: "",
	admitOptions: admitOptionsButtonId,
	background: changeBackgroundButtonId,
	about: aboutEvcButtonId,
};

enum TokenNames {
	devicesTitle = "DevicesTitle",
	devicesBackButtonLabel = "DevicesBackButtonLabel",
	participantListTitle = "ParticipantListTitle",
	participantListBackButtonLabel = "ParticipantListBackButtonLabel",
	languagesTitle = "LanguagesTitle",
	languagesBackButtonLabel = "LanguagesBackButtonLabel",
	backgroundTitle = "BackgroundTitle",
	backgroundBackButtonLabel = "BackgroundBackButtonLabel",
	admitOptionsTitle = "AdmitOptionsTitle",
	admitOptionsBackButtonLabel = "AdmitOptionsBackButtonLabel",
	participantOptionsBackButtonLabel = "ParticipantOptionsBackButtonLabel",
	aboutEvcTitle = "AboutEvcTitle",
	aboutEvcBackButtonLabel = "AboutEvcBackButtonLabel",
}

/**
 * Get the current menu pages to render
 * @returns an object containing references to each of the pages and an array of their definitions
 */
export function useMenuPages(): IMenuPages {
	/// 7 STEPS TO ADD A NEW MENU PAGE ///

	// 1. Create a component for the contents of the pages and add a value
	//	  to the union type, Menu, in types/layout.ts

	// 2. Add reference to the MenuPage element that will contain the page
	const baseMenuRef = useRef<HTMLDivElement>(null);
	const devicesMenuRef = useRef<HTMLDivElement>(null);
	const participantsMenuRef = useRef<HTMLDivElement>(null);
	const languagesMenuRef = useRef<HTMLDivElement>(null);
	const participantOptionsRef = useRef<HTMLDivElement>(null);
	const admitOptionsRef = useRef<HTMLDivElement>(null);
	const backgroundMenuRef = useRef<HTMLDivElement>(null);
	const aboutEvcRef = useRef<HTMLDivElement>(null);
	const pageRefs = useRef<PageRefMap>({
		menu: baseMenuRef,
		devices: devicesMenuRef,
		participants: participantsMenuRef,
		languages: languagesMenuRef,
		participantOptions: participantOptionsRef,
		admitOptions: admitOptionsRef,
		background: backgroundMenuRef,
		about: aboutEvcRef,
	});

	const participantsButtonInHeader = useShowParticipantButtonInHeader();
	// 3. Add any strings that the MenuPage will need (title and aria label for the back button)
	const strings = useStrings("useMenuPages", Object.values(TokenNames));

	// 4. Add the menu page's definition to the array of menu pages that will be rendered
	const menuPages: IMenuPage[] = useMemo(
		() => [
			{
				menu: "menu",
				contents: (isActive: boolean) => <BaseMenu active={isActive} />,
				ref: baseMenuRef,
				alwaysRender: true,
			},
			{
				menu: "devices",
				contents: <HardwareSetup context={DisplayContext.devicesTray} />,
				ref: devicesMenuRef,
				title: strings[TokenNames.devicesTitle],
				backButtonLabel: strings[TokenNames.devicesBackButtonLabel],
			},
			{
				menu: "participants",
				parentMenu: participantsButtonInHeader ? null : "menu",
				contents: <ParticipantList />,
				ref: participantsMenuRef,
				title: strings[TokenNames.participantListTitle],
				backButtonLabel: strings[TokenNames.participantListBackButtonLabel],
			},
			{
				menu: "participantOptions",
				parentMenu: "participants",
				contents: <ParticipantControlMenu />,
				ref: participantOptionsRef,
				dynamicTitle: true,
				backButtonLabel: strings[TokenNames.participantOptionsBackButtonLabel],
			},
			{
				menu: "languages",
				contents: <LanguageSelector />,
				ref: languagesMenuRef,
				title: strings[TokenNames.languagesTitle],
				backButtonLabel: strings[TokenNames.languagesBackButtonLabel],
			},
			{
				menu: "admitOptions",
				contents: <AdmitOptionsMenuPage />,
				ref: admitOptionsRef,
				title: strings[TokenNames.admitOptionsTitle],
				backButtonLabel: strings[TokenNames.admitOptionsBackButtonLabel],
			},
			{
				menu: "background",
				contents: <BackgroundSelector />,
				ref: backgroundMenuRef,
				title: strings[TokenNames.backgroundTitle],
				backButtonLabel: strings[TokenNames.backgroundBackButtonLabel],
			},
			{
				menu: "about",
				contents: <AboutEvc />,
				ref: aboutEvcRef,
				title: strings[TokenNames.aboutEvcTitle],
				backButtonLabel: strings[TokenNames.aboutEvcBackButtonLabel],
			},
		],
		[participantsButtonInHeader, strings],
	);

	// 5. Create a button to open the menu through the uiActions.toggleVisibleMenu() reducer.

	// 6. Add the button to the <BaseMenu> component.

	// 7. Add the ID of the button to baseMenuButtonIds above.

	return { pageRefs, menuPages };
}
