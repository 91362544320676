/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file getNextId function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\get-next-id
 */

let nextId = 1;

/**
 * Get the next slice ID.
 * @returns A number to be used as the next slice ID.
 */
export function getNextId(): number {
	return nextId++;
}
