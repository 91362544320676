/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Hook to modularize the web service and error handling for saving images to a patient chart
 * @author Will Cooper
 * @module Epic.VideoApp.Components.ImageCapture.Hooks.UseSaveImageToChart
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext } from "react";
import { useStrings } from "~/hooks";
import {
	alertActions,
	combinedActions,
	imageActions,
	useAuthState,
	useImageState,
	useRoomState,
} from "~/state";
import { AlertType, MessageActionType, MessageType } from "~/types";
import { storeCapturedImage } from "~/utils/imageCapture";
import { sendMessage } from "~/utils/sendMessage";
import { VideoSessionContext } from "~/web-core/components";

enum TokenNames {
	failedToSave = "FailedToSave",
	failedToSaveAfterRetry = "FailedToSaveAfterRetry",
	retry = "Retry",
	discard = "Discard",
}

/**
 * Hook used to modularize functionality on storing an image and handling the results of the web service call
 */
export function useSaveImageToChart(): (
	image: string,
	docType: string,
	description: string,
	fromRetry?: boolean,
) => Promise<void> {
	const participantId = useImageState((selectors) => selectors.getParticipantId(), []);
	const notifyImageCapture = useImageState((selectors) => selectors.getNotifyImageCapture(), []);
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);
	const dispatch = useDispatch();
	const strings = useStrings("useSaveImageToChart", Object.values(TokenNames));
	const { session } = useContext(VideoSessionContext);
	const displayName = useRoomState((selectors) => selectors.getLocalDisplayName(), []);

	const save = useCallback(
		async (image: string, docType: string, description: string, fromRetry?: boolean) => {
			try {
				dispatch(imageActions.setIsImageBeingSaved(true));
				await storeCapturedImage(image, JWT || "", docType, description, participantId);
				dispatch(imageActions.setIsImageBeingSaved(false));
				// Send data track message with participant ID of person who had their image captured
				if (notifyImageCapture) {
					sendMessage(session, {
						action: MessageActionType.request,
						payload: displayName,
						type: MessageType.imageCaptured,
						needsACK: false,
						recipients: [participantId],
					});
				}
				// Post image capture success toast
				dispatch(
					alertActions.postToastAlert({
						type: "image-capture-successful",
						messageToken: "ImageCaptureSuccess",
						toastStyle: "success",
					}),
				);
				setTimeout(() => {
					dispatch(alertActions.clearToasts("image-capture-successful"));
				}, 3000);
				dispatch(imageActions.setImageData(null));
			} catch {
				dispatch(imageActions.setIsImageBeingSaved(false));
				// Allow one retry via a warning modal pop-up before clearing image data
				if (fromRetry) {
					dispatch(alertActions.postGenericAlert(strings[TokenNames.failedToSaveAfterRetry]));
					dispatch(imageActions.setImageData(null));
				} else {
					dispatch(
						alertActions.postChoiceAlert({
							message: strings[TokenNames.failedToSave],
							cancelText: strings[TokenNames.retry],
							confirmText: strings[TokenNames.discard],
							confirmHotkey: "D",
							cancelHotkey: "R",
							type: AlertType.imageCaptureRetry,
							alertData: { docTypeId: docType, description },
						}),
					);
				}
			}
			dispatch(combinedActions.clearImageCaptureActive());
		},
		[dispatch, JWT, participantId, notifyImageCapture, session, displayName, strings],
	);

	return save;
}
