/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Modal alert for concurrent session choice
 * @author Tara Feldstein
 * @module Epic.VideoApp.Components.Alerts.ConcurrentSessionChoiceAlert
 */
import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import { useDisconnect, useVideoCallAuthentication } from "~/hooks";
import { errorPageActions } from "~/state";
import { IChoiceAlert } from "~/types";
import { getCookie, sessionStatusCookieKey, setCookie } from "~/utils/cookies";
import { minutesToMs } from "~/utils/dateTime";
import { alertActions } from "../../state/alerts";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IChoiceAlert;
}

export enum ConcurrentSessionChoiceAlertTestIds {
	self = "ConcurrentSessionChoiceAlert",
}

/**
 * Concurrent Session Choice alert component
 *
 * Warns the user that they have another open call in the video client, and asks them to choose which one to use.
 */
const ConcurrentSessionChoiceAlert: FC<IProps> = (props) => {
	const { alert } = props;

	const dispatch = useDispatch();

	const { authenticate } = useVideoCallAuthentication();

	const disconnect = useDisconnect();

	// These callbacks can not be set in the redux store, therefor we need to have different components
	// to handle the different cases of this type of Choice alert, each with hardcoded button callbacks.
	const confirm = useCallback(() => {
		// "Keep previous visit" / Cancel connecting
		// Clear the alert and send the user to a meaningful error card
		dispatch(alertActions.clearAlert());

		dispatch(
			errorPageActions.setErrorCard({
				title: ErrorTokenNames.concurrentErrorHeader,
				message: ErrorTokenNames.concurrentErrorMessage,
			}),
		);
		disconnect(true);
	}, [dispatch, disconnect]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
		// Disconnect the other tab
		const otherSessionId = getCookie("active-session");
		if (otherSessionId) {
			setCookie(sessionStatusCookieKey + otherSessionId, "disconnected", minutesToMs(5));
		}

		// Close modal and directly authenticate now that this is the active session
		void authenticate();
	}, [dispatch, authenticate]);

	return (
		<ChoiceAlertBase
			data-testid={ConcurrentSessionChoiceAlertTestIds.self}
			alert={alert}
			confirm={confirm}
			cancel={cancel}
		/>
	);
};

ConcurrentSessionChoiceAlert.displayName = "ConcurrentSessionChoiceAlert";

export default ConcurrentSessionChoiceAlert;
