/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Reusable Warning Row to display an error to end users
 * @author Will Cooper
 * @module Epic.VideoApp.Components.HardwareSetup.WarningBanner
 */

import React, { ComponentType, FC, useCallback, useState } from "react";
import { IconProps } from "~/icons";
import Chevron from "~/icons/chevron";
import Warning from "~/icons/warning";
import Collapse from "../Utilities/Collapse";
import HardwareSetupRow from "./HardwareSetupRow";
import styles from "./WarningBanner.module.scss";

interface IWarningRowProps {
	warningTitle: string;
	hidden?: boolean;
	icon?: ComponentType<IconProps>;
	ariaLabel: string;
}
/**
 * The WarningBanner component
 */
const WarningBanner: FC<IWarningRowProps> = (props) => {
	const { warningTitle, icon: Icon, children, hidden, ariaLabel } = props;
	const [collapsed, setCollapsed] = useState<boolean>(hidden || false);
	const flipCollapsedCallback = useCallback(() => setCollapsed(!collapsed), [collapsed]);

	const className = Icon ? "" : styles["defaultIcon"];

	return (
		<>
			<button
				className={styles["warningRow"]}
				onClick={flipCollapsedCallback}
				aria-label={ariaLabel}
				aria-pressed={!collapsed}
			>
				<HardwareSetupRow
					icon={Icon ?? Warning}
					label={warningTitle ?? ""}
					centerLabel
					iconClassName={className}
				>
					<Chevron
						height={20}
						width={20}
						chevronDirection={collapsed ? "down" : "up"}
						className={styles["chevron"]}
						aria-hidden
					/>
				</HardwareSetupRow>
			</button>
			<Collapse collapsed={collapsed}>{children}</Collapse>
		</>
	);
};

WarningBanner.displayName = "WarningBanner";

export default WarningBanner;
