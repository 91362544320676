/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to subscribe to and return the track associated with a publication
 * @author Matt Panico and Colin Walters
 * @module Epic.VideoApp.Hooks.TrackUtilities.UseTrack
 */

import { useEffect, useState } from "react";
import {
	LocalAudioTrack,
	LocalDataTrack,
	LocalTrackPublication,
	LocalVideoTrack,
	RemoteAudioTrack,
	RemoteDataTrack,
	RemoteTrackPublication,
	RemoteVideoTrack,
} from "twilio-video";

type Track =
	| LocalVideoTrack
	| RemoteVideoTrack
	| LocalAudioTrack
	| RemoteAudioTrack
	| LocalDataTrack
	| RemoteDataTrack;

export function useTrack(publication: LocalTrackPublication | RemoteTrackPublication | null): Track | null {
	const [track, setTrack] = useState(publication && publication.track);

	useEffect(() => {
		setTrack(publication && publication.track);

		const removeTrack = (): void => setTrack(null);

		publication?.on("subscribed", setTrack);
		publication?.on("unsubscribed", removeTrack);

		return () => {
			publication?.off("subscribed", setTrack);
			publication?.off("unsubscribed", removeTrack);
		};
	}, [publication]);

	return track;
}
