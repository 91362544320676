/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Video overlay icon for any user who's track has been switched off due to bandwidth considerations
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Video.TrackSwitchedOffOverlay
 */

import React, { ReactElement } from "react";
import { useStrings } from "~/hooks";
import WarningYield from "~/icons/warningYield";
import styles from "./TrackSwitchedOffOverlay.module.scss";

export enum TrackSwitchedOffOverlayTestIds {
	warningYield = "WarningYield",
}

/**
 * The TrackSwitchedOffOverlay component
 */
const TrackSwitchedOffOverlay = (): ReactElement | null => {
	const strings = useStrings("TrackSwitchedOffOverlay", ["IconText"]);
	return (
		<div className={styles["overlay"]}>
			<WarningYield
				height={25}
				width={25}
				fill="goldenrod"
				className={styles["warning"]}
				tabIndex={0}
				title={strings["IconText"]}
				aria-label={strings["IconText"]}
				role="img"
				data-testid={TrackSwitchedOffOverlayTestIds.warningYield}
			/>
		</div>
	);
};

TrackSwitchedOffOverlay.displayName = "TrackSwitchedOffOverlay";

export default React.memo(TrackSwitchedOffOverlay);
