/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Modal alert for confirming user disconnection
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Alerts.DisconnectionChoiceAlert
 */
import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useDisconnect } from "~/hooks";
import { IChoiceAlert } from "~/types";
import { alertActions } from "../../state/alerts";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IChoiceAlert;
}

export enum DisconnectionChoiceAlertTestIds {
	self = "DisconnectionChoiceAlert",
}

/**
 * Disconnection Choice alert component
 *
 * Renders a confirmation message that the user intends to disconnect and two buttons to confirm the action or cancel it.
 */
const DisconnectionChoiceAlert: FC<IProps> = (props) => {
	const { alert } = props;

	const dispatch = useDispatch();
	const disconnect = useDisconnect();

	// These callbacks can not be set in the redux store, therefor we need to have different components
	// to handle the different cases of this type of Choice alert, each with hardcoded button callbacks.
	const confirm = useCallback(() => {
		// Clicking the leave call button should prevent the user from reconnecting
		// Disconnecting also clears alerts
		disconnect(false, true);
	}, [disconnect]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	return (
		<ChoiceAlertBase
			data-testid={DisconnectionChoiceAlertTestIds.self}
			alert={alert}
			confirm={confirm}
			cancel={cancel}
		/>
	);
};

DisconnectionChoiceAlert.displayName = "DisconnectionChoiceAlert";

export default DisconnectionChoiceAlert;
