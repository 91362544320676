/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file image Capture pane exit button
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.ImageCapture.Controls.ExitButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, RefObject, useCallback, useEffect, useRef } from "react";
import { useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import { alertActions } from "~/state";
import { AlertType, IChoiceAlert } from "~/types";
import Error from "../../../icons/error";
import BaseButton from "../../Utilities/BaseButton";
import styles from "../ImagePreviewPane.module.scss";

interface IExitButtonProps {
	exitRef?: RefObject<HTMLButtonElement>;
}

enum TokenNames {
	cancel = "Cancel",
	closeWindowAria = "CloseWindowAria",
	closeWindowTooltip = "CloseWindowTooltipNoHotkey",
	discard = "Discard",
	discardConfirm = "DiscardConfirm",
}

export enum ExitButtonTestIds {
	self = "ExitButton",
}

/**
 * The ExitButton component
 * @param props - the props
 */
const ExitButton: FC<IExitButtonProps> = (props) => {
	const { exitRef } = props;
	const dispatch = useDispatch();
	const strings = useStrings("ExitButton", Object.values(TokenNames));

	const stringsRef = useRef(strings);

	useEffect(() => {
		stringsRef.current = strings;
	}, [strings]);

	const closeWindow = useCallback(() => {
		const discardAlert: IChoiceAlert = {
			message: stringsRef.current[TokenNames.discardConfirm],
			confirmText: stringsRef.current[TokenNames.discard],
			confirmHotkey: "D",
			cancelText: stringsRef.current[TokenNames.cancel],
			cancelHotkey: "C",
			type: AlertType.discardChoice,
		};
		dispatch(alertActions.postChoiceAlert(discardAlert));
	}, [dispatch]);

	const closeHotkey = ["alt", "x"];
	const tooltipFormatted = useGetFormattedHotkeyString(strings[TokenNames.closeWindowTooltip], closeHotkey);
	const ariaFormatted = useGetFormattedHotkeyString(strings[TokenNames.closeWindowAria], closeHotkey);

	return (
		<BaseButton
			aria-label={ariaFormatted}
			rawClass={styles["exitButton"]}
			onClick={closeWindow}
			buttonRef={exitRef}
			icon={Error}
			keyboardShortcut={closeHotkey}
			tooltipInfo={{ text: tooltipFormatted, direction: "right" }}
			forImagePreviewPane
			data-testid={ExitButtonTestIds.self}
		/>
	);
};

ExitButton.displayName = "ExitButton";

export default ExitButton;
