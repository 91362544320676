/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Shared state for Picture-in-Picture feature
 * @author Arun Vasireddy
 * @module Epic.VideoApp.State.PictureInPicture
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { VideoType } from "~/web-core/types";

/// TYPES ///

type PiPSource = "Auto" | "Manual";

export interface IPictureInPictureState {
	isPiPModeEnabled: boolean;
	isActiveParticipantInPiP: boolean;
	videoID: string;
	videoType: VideoType | "";
	pipSource: PiPSource;
}

/// INIT ///

function getInitialState(): IPictureInPictureState {
	return {
		isPiPModeEnabled: false,
		isActiveParticipantInPiP: false,
		videoID: "",
		videoType: "",
		pipSource: "Manual",
	};
}

/// REDUCERS ///

function togglePiPMode(
	state: IPictureInPictureState,
	value: { videoID?: string; videoType?: VideoType; activeMode: boolean; source?: PiPSource },
): IPictureInPictureState {
	if (value.activeMode) {
		return {
			...state,
			videoID: value.videoID ? value.videoID : state.videoID,
			videoType: value.videoType ? value.videoType : state.videoType,
			isPiPModeEnabled: !state.isActiveParticipantInPiP,
			isActiveParticipantInPiP: !state.isActiveParticipantInPiP,
			pipSource: value?.source ?? "Manual",
		};
	} else if (
		(state.videoID && value.videoID && value.videoID !== state.videoID) ||
		(state.videoType && value.videoType && value.videoType !== state.videoType)
	) {
		return {
			...state,
			videoID: value.videoID ? value.videoID : "",
			videoType: value.videoType ? value.videoType : "",
			isPiPModeEnabled: true,
			isActiveParticipantInPiP: false,
			pipSource: value?.source ?? "Manual",
		};
	}
	return {
		...state,
		videoID: value.videoID ? value.videoID : "",
		videoType: value.videoType ? value.videoType : "",
		isPiPModeEnabled: !state.isPiPModeEnabled,
		isActiveParticipantInPiP: false,
		pipSource: value?.source ?? "Manual",
	};
}

function enterPiPMode(
	state: IPictureInPictureState,
	value: { newVideoID: string; newVideoType: VideoType },
): IPictureInPictureState {
	return { ...state, videoID: value.newVideoID, videoType: value.newVideoType, isPiPModeEnabled: true };
}

function leavePiPMode(
	state: IPictureInPictureState,
	value: { videoID?: string; videoType?: string; isMainParticipant: boolean },
): IPictureInPictureState {
	return {
		...state,
		isPiPModeEnabled:
			value.videoID === state.videoID && value.videoType === state.videoType
				? false
				: state.isPiPModeEnabled,
		isActiveParticipantInPiP:
			value.isMainParticipant ||
			(value.videoID === state.videoID && value.videoType === state.videoType) ||
			!state.isPiPModeEnabled
				? false
				: state.isActiveParticipantInPiP,
	};
}

function leaveAutoPiPMode(state: IPictureInPictureState): IPictureInPictureState {
	if (state.pipSource === "Auto") {
		return {
			...state,
			isPiPModeEnabled: false,
			isActiveParticipantInPiP: false,
			videoID: "",
			videoType: "",
			pipSource: "Manual",
		};
	}

	return state;
}

/// SELECTORS ///

function getPipStatus(state: IPictureInPictureState): boolean {
	return state.isPiPModeEnabled;
}

function getPipVideoID(state: IPictureInPictureState): string {
	return state.videoID;
}

function getPipVideoType(state: IPictureInPictureState): string {
	return state.videoType;
}

function getInActiveParticipantMode(state: IPictureInPictureState): boolean {
	return state.isActiveParticipantInPiP;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		togglePiPMode,
		enterPiPMode,
		leavePiPMode,
		leaveAutoPiPMode,
	},
	selectors: {
		getPipStatus,
		getPipVideoID,
		getPipVideoType,
		getInActiveParticipantMode,
	},
});

store.addSharedState(builtState.sharedState, "pictureInPicture");

export const {
	actionCreators: pipActions,
	useSharedState: usePictureInPictureState,
	sharedState: state,
} = builtState;
