/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Contains copyright notice strings, which don't need to be translated
 * @author Sasha Chackalackal
 * @module Epic.VideoApp.Utils.Copyright
 */

// Copyright notice at the bottom of the screen
export const copyrightNotice = "© 2020-2024 Epic Systems Corporation.";

export const fullCopyrightNotice =
	copyrightNotice + " Epic® is a registered trademark of Epic Systems Corporation.";

export const fullCopyrightNoticeAriaLabel =
	"Copyright 2020-2024 Epic Systems Corporation. Epic is a registered trademark of Epic Systems Corporation.";
