/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Utilities for manually updating the URL
 * @author Will Cooper
 * @module Epic.VideoApp.Utils.QueryParameters
 */

import { navigate } from "@reach/router";
import { QueryParameters } from "~/types";
import { getPseudoRandomId } from "./general";

/**
 * Clear the url query string and add new values
 * @param queryParams key and value pairs to be added to the query parameter url string
 */
export function resetQueryParameter(queryParams: Record<string, string>): void {
	const queryString = new URLSearchParams("");

	for (const key in queryParams) {
		// TypeGuard of "for in" loop (ESLint)
		if (key in queryParams) {
			queryString.set(key, queryParams[key]);
		}
	}
	// Call to history.replaceState does not affect the reach router state that we use to parse URL parameters
	void navigate(window.location.pathname + "?" + queryString.toString(), { replace: true });
}

/**
 * Remove the entire query string from the url
 */
export function clearQueryParameters(): void {
	void navigate(window.location.pathname);
}

/**
 * Adds any query params to the current query string
 * @param queryParams key and value pairs to be added to the query parameter url string
 */
export function addQueryParameters(queryParams: Record<string, string>): void {
	const queryString = new URLSearchParams(window.location.search);

	for (const key in queryParams) {
		// TypeGuard of "for in" loop (ESLint)
		if (key in queryParams) {
			queryString.set(key, queryParams[key]);
		}
	}
	void navigate(window.location.pathname + "?" + queryString.toString(), { replace: true });
}

/**
 * Remove all params from the url query string except those with keys in queryParams
 * @param queryParams array of string query parameter keys that should be not be removed
 */
export function removeAllQueryParamsExcept(queryParams: string[]): void {
	// convert the query parameters to lower case to make the below case insensitive
	const lowerQueryParams = queryParams.map((q) => q.toLowerCase());

	// it breaks if you delete params in the same query string you're iterating so make two, one to iterate and one to modify
	const queryStringKeys = new URLSearchParams(window.location.search).keys();
	const queryString = new URLSearchParams(window.location.search);

	for (const key of queryStringKeys) {
		if (!lowerQueryParams.includes(key.toLowerCase())) {
			queryString.delete(key);
		}
	}

	void navigate(window.location.pathname + "?" + queryString.toString(), { replace: true });
}

/**
 *Add a unique sessionID into the URL query string
 */
export function setSessionID(): string {
	// Generate a session ID and update the URL for future retrieval
	const sessionID = getPseudoRandomId();
	addQueryParameters({ [QueryParameters.sessionId]: sessionID });
	return sessionID;
}
