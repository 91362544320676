/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Component used to react to clicks made outside of the component
 * @author Razi Rais
 * @module Epic.VideoApp.Components.Utilities.ClickOutsideSection
 */

import React, { FC, useEffect, useRef } from "react";

/**
 * Props for ClickOutsideSectionComponent
 */
interface IProps {
	onClickOutside: () => void;
}

/**
 * The ClickOutsideSection component
 * @param props The props ;)
 */
const ClickOutsideSection: FC<IProps> = (props) => {
	const { onClickOutside, children } = props;

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const listener = (event: { target: any }): void => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}

			//Run event handler if click is detected outside ref
			onClickOutside();
		};

		document.addEventListener("click", listener);

		return () => {
			document.removeEventListener("click", listener);
		};
	}, [onClickOutside]);

	return <div ref={ref}>{children}</div>;
};

ClickOutsideSection.displayName = "ClickOutsideSection";

export default ClickOutsideSection;
