/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file useDispatch hook
 * @author Roland Scott
 * @module react-redux-booster\src\use-dispatch
 */

import { useDispatch as useReduxDispatch } from "react-redux";
import { IAction } from "./types";

/**
 * Get a reference to the store's dispatch function.
 *
 * This won't work unless you've surrounded your React tree with a store <Provider>.
 * @returns The dispatch function for your store
 */
export function useDispatch(): <T extends IAction>(action: T) => T {
	return useReduxDispatch();
}
