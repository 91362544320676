/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Custom hooks for chat connection status
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.Hooks.UseChatConnectionStatus
 */

import { IUser } from "@epic/chat";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { chatUserActions, useRoomState } from "~/state";

/**
 * React hook that manages the chat connection status of a user. It updates the user's chat status
 * and controls the visibility of a chat warning based on the connection status.
 */
export function useChatConnectionStatus(
	localUserId: string,
	chatConnectionStatus: string,
	setShouldShowChatWarning: React.Dispatch<React.SetStateAction<boolean>>,
): void {
	const dispatch = useDispatch();
	const localDisplayName = useRoomState((selectors) => selectors.getLocalDisplayName(), []);

	useEffect(() => {
		const user: IUser = {
			displayName: localDisplayName,
			id: localUserId,
			isInChat: chatConnectionStatus === "Connected",
			isTyping: false,
			color: 0,
		};
		dispatch(chatUserActions.addOrUpdateUser(user));
		switch (chatConnectionStatus) {
			case "Disconnected":
				setShouldShowChatWarning(true);
				break;
			case "Connected":
				setShouldShowChatWarning(false);
				break;
			default:
				break;
		}
	}, [dispatch, chatConnectionStatus, localDisplayName, localUserId, setShouldShowChatWarning]);
}
