/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Initialization function to create Daily-specific session
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Functions.InitializeDailyAsVendor
 */

import { isMobile } from "~/utils/os";
import { constrainToString } from "~/web-core/functions";
import { SessionErrorCodes, VendorError } from "~/web-core/interfaces";
import { DailyLocalStream, DailyLocalUser, DailyRemoteUser, DailySession } from "../implementations";
import { processDailyError } from "./dailyErrorUtils";

export async function initializeDailyAsVendor(
	videoDeviceId: ConstrainDOMString | undefined,
	audioDeviceId: ConstrainDOMString | undefined,
): Promise<DailySession> {
	const dailyStream = new DailyLocalStream();
	const hwInitError = await dailyStream.initialize(
		constrainToString(videoDeviceId),
		constrainToString(audioDeviceId),
	);
	if (hwInitError && hwInitError.length > 0) {
		const updates = processDailyError(hwInitError);
		dailyStream.deviceInitializationError = {
			deviceUpdates: updates,
			error: new VendorError(hwInitError[0].type, SessionErrorCodes.unknown, hwInitError[0].msg),
		};
	}

	const user = new DailyLocalUser(dailyStream);
	const session = new DailySession(user);

	// On mobile, use lower quality settings for received video
	if (isMobile()) {
		DailyRemoteUser.setUseMobileQuality();
	}

	return session;
}
