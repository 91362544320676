/**
 * @copyright Copyright 2020-201 Epic Systems Corporation
 * @file Generic alert for showing a message with a visible countdown
 * @author Max Harkins
 * @module Epic.VideoApp.Components.Alerts.GenericCountdownAlert
 */
import React, { FC } from "react";
import { AlertType, IGenericAlert, IGenericCountdownAlert } from "~/types";
import AlertLocalTimer from "./AlertLocalTimer";
import GenericAlert from "./GenericAlert";

interface IProps {
	alert: IGenericCountdownAlert;
}

/**
 * Generic timeout alert component
 *
 * Renders a message and a countdown. The component which initiates the alert is responsible for keeping a timer to do any necessary work.
 */
const GenericCountdownAlert: FC<IProps> = (props) => {
	const { alert } = props;
	const genericAlert: IGenericAlert = {
		message: alert.message,
		type: AlertType.genericMessage,
	};

	return (
		<GenericAlert alert={genericAlert}>
			<AlertLocalTimer initialTime={alert.countdown} />
		</GenericAlert>
	);
};

GenericCountdownAlert.displayName = "GenericCountdownAlert";

export default GenericCountdownAlert;
