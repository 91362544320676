/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Control to update and display which page of users are visible within the grid.
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Grid.PagingSection
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import PageArrowButton from "~/components/Utilities/Carousel/PageArrowButton";
import PageSelector from "~/components/Utilities/Carousel/PageSelector";
import { useStrings, useWindowSize } from "~/hooks";
import { uiActions, useUIState } from "~/state";
import styles from "./PagingSection.module.scss";

enum TokenNames {
	previousButtonLabel = "PreviousPage",
	nextButtonLabel = "NextPage",
}

/**
 * The PagingSection component
 */
const PagingSection: FC = () => {
	const currentPage = useUIState((selectors) => selectors.getCurrentPage(), []);
	const totalPageCount = useUIState((selectors) => selectors.getPageCount(), []);
	const dispatch = useDispatch();

	const strings = useStrings("PagingSection", Object.values(TokenNames));

	const back = useCallback(() => {
		dispatch(uiActions.setCurrentPage(currentPage - 1));
	}, [currentPage, dispatch]);

	const advance = useCallback(() => {
		dispatch(uiActions.setCurrentPage(currentPage + 1));
	}, [currentPage, dispatch]);

	// All screens fit on one page. Don't need to show (can be 0 or 1 depending on static vs remote participant numbers)
	if (totalPageCount < 2) {
		return null;
	}

	const leftDisabled = currentPage === 0;
	const rightDisabled = currentPage === totalPageCount - 1;

	return (
		<div className={styles["footer"]}>
			<PageArrowButton
				onClick={back}
				pageDirection="previous"
				ariaLabel={strings[TokenNames.previousButtonLabel]}
				disabled={leftDisabled}
			/>
			<PageManagement totalPages={totalPageCount} currentPage={currentPage} />
			<PageArrowButton
				onClick={advance}
				pageDirection="next"
				ariaLabel={strings[TokenNames.nextButtonLabel]}
				disabled={rightDisabled}
			/>
		</div>
	);
};

PagingSection.displayName = "PagingSection";

export default PagingSection;

// HELPER COMPONENTS

// Keep this value in synch with the paging button sizes defined in _dimensions.scss
const PageButtonSize = 30;

// Account for two potential arrows and a bit of margin
const PagingSectionReservedWidth = PageButtonSize * 2 + 30;

interface IPagingProps {
	/** Total pages for how many buttons to create */
	totalPages: number;

	/** Current page to know which circle to fill in */
	currentPage: number;
}

/**
 * The PageManagement component
 */
export const PageManagement: FC<IPagingProps> = (props: IPagingProps) => {
	const { totalPages, currentPage } = props;
	const dispatch = useDispatch();
	const displayCurrentPage = currentPage + 1;
	const { width } = useWindowSize();

	const setPage = useCallback(
		(newPage: number) => {
			dispatch(uiActions.setCurrentPage(newPage));
		},
		[dispatch],
	);

	const spaceForPageSelector = width - PagingSectionReservedWidth;
	const pagingSectionButtonAreaWidth = totalPages * PageButtonSize;

	// Determine if there is enough space to show the set of page selector buttons
	// We can only shrink them so small, so at some point switch this for something informative
	if (spaceForPageSelector < pagingSectionButtonAreaWidth) {
		return (
			<span className={styles["text"]}>
				{displayCurrentPage} / {totalPages}
			</span>
		);
	}

	return <PageSelector onClick={setPage} currentPage={currentPage} totalPages={totalPages} />;
};
