/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to encapsulate the logic of sending a participant information to all other users in the call
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Messaging.UseBroadcastParticipantInfo
 */

import { useCallback, useContext } from "react";
import { useRoomState, useUserState } from "~/state";
import { IInfoUpdateMessage, MessageActionType, MessageType } from "~/types";
import { VideoSessionContext } from "~/web-core/components";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import { sendMessage } from "../../utils/sendMessage";

export interface IBroadcastInfo {
	(params?: IStatusParams): void;
}

interface IStatusParams {
	newMutedState?: boolean;
	newDisabledState?: boolean;
	newWaitingState?: boolean;
	newScreenState?: boolean;
}

/**
 * Broadcasts display names and other participant information to all others users within the call. Does not expect a response.
 * We apply a delay before sending out these values so we can ensure remote parties have a listener ready.
 * Uses the underlying sendMessage hook to send a specific message format
 * @returns a function to broadcast display names to all other users in the video visit.
 */
export function useBroadcastParticipantInfo(): IBroadcastInfo {
	const { session } = useContext(VideoSessionContext);
	const displayName = useRoomState((selectors) => selectors.getLocalDisplayName(), []);
	const userType = useUserState((selectors) => selectors.getUserType(), []);
	const usingWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const cameraLocked = useUserState((selectors) => selectors.getCameraLock(), []);
	const micLock = useUserState((selectors) => selectors.getMicLock(), []);
	const screenShareLock = useUserState((selectors) => selectors.getScreenShareLock(), []);
	// Configuration allows screen sharing
	const screenShareAllowed = useUserState((selectors) => selectors.getUserPermission("canShareScreen"), []);
	// Device supports screen share
	const screenShareSupport = useIsScreenShareSupported();

	// This user type will need to be pulled from each user's FDI as part of launching the call
	// We don't check user type anywhere at the moment so a hardcoded value is fine.
	const broadcastInfo = useCallback(
		(params?: IStatusParams) => {
			// Use the new state, or the previously saved value if we haven't made an explicit change
			const newMicValue = params?.newMutedState ?? micLock;
			const newCamValue = params?.newDisabledState ?? cameraLocked;
			const newWaitingValue = params?.newWaitingState ?? usingWaitingRoom;
			const newScreenValue = params?.newScreenState ?? screenShareLock;
			const message: IInfoUpdateMessage = {
				action: MessageActionType.broadcast,
				payload: {
					displayName: displayName,
					userType: userType,
					messageID: "",
					micLocked: newMicValue,
					cameraLocked: newCamValue,
					screenShareLocked: newScreenValue,
					screenShareAllowed: screenShareAllowed,
					deviceSupportsScreenShare: screenShareSupport,
					inWaitingRoom: newWaitingValue,
				},
				type: MessageType.participantInfo,
				needsACK: false,
			};
			// If a remote participant does not have information from the local user, they will request it, triggering another broadcast
			sendMessage(session, message);
		},
		[
			micLock,
			cameraLocked,
			usingWaitingRoom,
			screenShareLock,
			displayName,
			userType,
			screenShareAllowed,
			screenShareSupport,
			session,
		],
	);

	return broadcastInfo;
}
