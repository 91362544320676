/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Shared state for the error card
 * @author Will Cooper
 * @module Epic.VideoApp.State.ErrorPage
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";

/// TYPES ///

export interface IErrorPage {
	title?: ErrorTokenNames;
	message: ErrorTokenNames;
}

export interface IErrorPageState {
	page: IErrorPage | null;
}

/// INIT ///

function getInitialState(): IErrorPageState {
	return {
		page: null,
	};
}

/// REDUCERS ///

//
/**
 * Sets the error page tile and text
 * @param state current state
 * @param page Contents for the error page, should only include string keys.
 *   Title is optional and will fall back to the default "Something went wrong" if not provided
 * @returns Updated state
 */
function setErrorCard(state: IErrorPageState, page: IErrorPage): IErrorPageState {
	const newError: IErrorPageState = {
		...state,
		page: page,
	};
	return newError;
}

/// SELECTORS ///

function getErrorPageTitle(state: IErrorPageState): string | null {
	return state.page?.title ?? null;
}

function getErrorPageText(state: IErrorPageState): string | null {
	return state.page?.message ?? null;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		setErrorCard,
	},
	selectors: {
		getErrorPageTitle,
		getErrorPageText,
	},
});

store.addSharedState(builtState.sharedState, "errorPage");

export const {
	actionCreators: errorPageActions,
	useSharedState: useErrorPageState,
	sharedState: state,
} = builtState;
