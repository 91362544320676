/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file List with header utility
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.Utilities.ListWithDescription
 */

import React, { FC } from "react";
import styles from "./ListWithDescription.module.scss";

interface IProps {
	bodyText: string;
	listItems: string[];
}

/**
 * ListWithDescription component
 * @param props The props
 *
 * Renders body text, followed by a numbered list of strings
 */
const ListWithDescription: FC<IProps> = (props) => {
	const { bodyText, listItems } = props;

	return (
		<>
			<p className={styles["bodyText"]}>{bodyText}</p>
			<ol className={styles["steps"]}>
				{listItems.map((itemText, index) => {
					return <li key={index}>{itemText}</li>;
				})}
			</ol>
		</>
	);
};

ListWithDescription.displayName = "ListWithDescription";

export default ListWithDescription;
