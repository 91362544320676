/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Component to render the main view of Grid mode. Divide logic up so the VideoGrid & GridWrapper components can be more general
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Grid.GridSection
 */

import React, { FC } from "react";
import GridWrapper from "./GridWrapper";
import PagingSection from "./PagingSection";
import styles from "./VideoGrid.module.scss";

/**
 * The GridSection component
 */
const GridSection: FC = () => {
	/**
	 *  This component wraps the underlying VideoGrid and the additional components that feature in the Grid Layout UI
	 */
	return (
		<div className={styles["sectionWrapper"]}>
			<GridWrapper />
			<PagingSection />
		</div>
	);
};

GridSection.displayName = "GridSection";

export default GridSection;
