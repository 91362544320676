/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file drop down menu for language selection to be used on pages without the full menu
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Menu.SinglePageMenu
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { uiActions, useUIState } from "~/state";
import { Menu } from "~/types";
import GenericMenuPage from "./GenericMenuPage";

/**
 * Props for SinglePageMenu component
 */
interface IProps {
	/** The menu page that should be displayed as a part of this menu */
	menu: Menu;
}

/**
 * The SinglePageMenu component
 * @param props The props ;)
 */
const SinglePageMenu: FC<IProps> = (props) => {
	const { menu, children } = props;
	const collapsed = useUIState((s) => s.getVisibleMenu(), []) !== menu;
	const dispatch = useDispatch();

	const onClickOutsideTrays = useCallback(() => {
		if (!collapsed) {
			dispatch(uiActions.toggleVisibleMenu({ menu: null }));
		}
	}, [collapsed, dispatch]);

	return (
		<GenericMenuPage closeCallback={onClickOutsideTrays} enabled={!collapsed}>
			{children}
		</GenericMenuPage>
	);
};

SinglePageMenu.displayName = "SinglePageMenu";

export default SinglePageMenu;
