/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Invisible component that attaches a message handler to all data tracks. Only used for Twilio sessions.
 * @author Tara Feldstein
 * @module Epic.VideoApp.Components.VideoCall.DataTracks.DataTracksListener
 */

import React, { FC, useEffect, useMemo, useRef } from "react";
import { RemoteDataTrack, RemoteTrackPublication } from "twilio-video";
import { usePublications, useTrack } from "~/hooks";
import { useAuthState } from "~/state";
import { Timeout } from "~/types";
import { minutesToMs, secondsToMs } from "~/utils/dateTime";
import { ResponseError, makeRequest } from "~/utils/request";
import { TwilioRemoteUser } from "~/web-core/vendor/twilio/implementations";
import DataTrackListener from "./DataTrackListener";

const RecoverTimerInitialIntervalInMS: number = secondsToMs(2); // 2 seconds initial timer
const RecoverTimerMaxIntervalInMS: number = minutesToMs(1); // 1 minute max timer
const RecoverTimerIntervalGrowth = 1.2; // 20% growth with each timer interval
const RecoverTimerMaxRetries = 25; // Max retries for recover data track timer

/**
 * Props for DataTracksListener Component
 */
interface IProps {
	users: TwilioRemoteUser[];
	handleMessage: (message: string, participantIdentity: string) => void;
}

/**
 * The DataTracksListener component
 * Attaches the specified message handler to every data track on the given participants.
 * Only used for Twilio sessions.
 */

const DataTracksListener: FC<IProps> = (props) => {
	const { users, handleMessage } = props;
	/*
		Because there's potentially multiple participants, and potentially multiple data tracks per participant,
		  and we need the usePublications and useTrack hooks (which can't go within a loop),
		  we'll use three levels of nested components to create all the instances of the necessary hooks

		In this first level, we attack a Participant Listener for each Participant
 	*/
	return (
		<>
			{users.map((user) => (
				<ParticipantListener
					key={user.getUserIdentity()}
					participant={user}
					handleMessage={handleMessage}
				/>
			))}
		</>
	);
};

DataTracksListener.displayName = "DataTracksListener";

export default DataTracksListener;

// Private/Internal use components

interface IParticipantListenerProps {
	participant: TwilioRemoteUser;
	handleMessage: (message: string, participantIdentity: string) => void;
}

/**
 * The Participant listener
 * Extracts data track publications for a given participant, and attaches a Publication Listener to each
 * In theory, one participant could have multiple data tracks, although this shouldn't actually happen
 */
const ParticipantListener: FC<IParticipantListenerProps> = (props) => {
	const { participant, handleMessage } = props;
	const publications = usePublications(participant.participant);
	const dataTrackPublications = useMemo(
		() => publications.filter((publication) => publication.kind === "data") as RemoteTrackPublication[],
		[publications],
	);

	const JWT = useAuthState((selectors) => selectors.getJWT(), []);

	const timeoutRef = useRef<Timeout | null>();
	const jwtRef = useRef<string | null>(JWT);

	useEffect(() => {
		jwtRef.current = JWT;
	}, [JWT]);

	// Handle recovering remote data tracks if needed (QAN 7237482).
	useEffect(() => {
		if (!jwtRef.current) {
			return;
		}

		// Clear pending timeout if any.
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}

		// Helper function to check if a given data track publication needs subscription.
		const isSubscriptionNeeded = (remoteTrackPublication: RemoteTrackPublication): boolean => {
			return (
				remoteTrackPublication &&
				remoteTrackPublication.kind === "data" && // Is it a data track publication?
				"isSubscribed" in remoteTrackPublication && // Is it a remote track publication (which has isSubscribed property)?
				!remoteTrackPublication.isSubscribed // Is it subscribed yet?
			);
		};

		// Helper function to recover remote data tracks on a timeout.
		const recoverDataTracks = async (prevIntervalInMS: number, retryCount: number): Promise<void> => {
			timeoutRef.current = null;

			if (!jwtRef.current) {
				return;
			}

			let foundUnsubscribedTracks = false;

			// Recover remote data tracks if any of them are not subscribed yet.
			for (const remoteTrackPublication of dataTrackPublications) {
				if (isSubscriptionNeeded(remoteTrackPublication)) {
					foundUnsubscribedTracks = true;
					try {
						await recoverRemoteDataTracks(jwtRef.current, participant.getUserIdentity());
					} catch (error: any) {
						if ("status" in error) {
							const responseError = error as ResponseError;
							if (responseError.status === 404) {
								// For 404s (Not found), it's more likely that the call is not active anymore. Stop recovering data tracks in that case.
								// Otherwise, it was triggering this timer every two seconds and was submitting up to 1000+ Twilio requests in certain
								// edge cases, even after the call was finished (most likely due to some memory leak).
								return;
							}
							if (responseError.status === 429) {
								// For 429s (Too many requests), break out of the loop and wait for another timer interval before submitting recover requests.
								break;
							}
						}
					}
				}
			}

			// If we had one or more unsubscribed tracks, trigger another timer to recheck the data tracks.
			if (foundUnsubscribedTracks && retryCount < RecoverTimerMaxRetries) {
				const intervalInMS =
					prevIntervalInMS < RecoverTimerMaxIntervalInMS
						? prevIntervalInMS * RecoverTimerIntervalGrowth
						: RecoverTimerMaxIntervalInMS;
				timeoutRef.current = setTimeout(() => {
					void recoverDataTracks(intervalInMS, retryCount + 1);
				}, intervalInMS);
			}
		};

		// Check if there are any remote data tracks that needs subscription.
		// If so, trigger a timeout to handle recovering data tracks.
		const hasUnsubscribedTracks = dataTrackPublications.some((trackPublication) =>
			isSubscriptionNeeded(trackPublication),
		);
		if (hasUnsubscribedTracks) {
			timeoutRef.current = setTimeout(() => {
				void recoverDataTracks(RecoverTimerInitialIntervalInMS, 1);
			}, RecoverTimerInitialIntervalInMS);
		}

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
				timeoutRef.current = null;
			}
		};
	}, [dataTrackPublications, participant]);

	return (
		<>
			{dataTrackPublications.map((publication) => (
				<PublicationListener
					key={publication.trackSid}
					publication={publication}
					participantIdentity={participant.getUserIdentity()}
					handleMessage={handleMessage}
				/>
			))}
		</>
	);
};

interface IPublicationListenerProps {
	publication: RemoteTrackPublication;
	participantIdentity: string;
	handleMessage: (message: string, participantIdentity: string) => void;
}

/**
 * The Publication listener
 * Extracts the actual track from a single publication, and attaches the message handler to it
 */
const PublicationListener: FC<IPublicationListenerProps> = (props) => {
	const { publication, handleMessage, participantIdentity } = props;
	const track = useTrack(publication) as RemoteDataTrack;

	if (!track) {
		return null;
	}
	return (
		<DataTrackListener
			track={track}
			participantIdentity={participantIdentity}
			messageHandler={handleMessage}
		/>
	);
};

/**
 * Recovers the current user's track subscriptions of the remote data tracks for a remote participant
 * @param jwt Used to authenticate the request
 * @param identity The identity of the remote participant
 * @returns void
 */
async function recoverRemoteDataTracks(jwt: string, identity: string): Promise<void> {
	return makeRequest<void>("api/VideoCall/MediaSubscriptions/RecoverDataTracks", "POST", jwt, {
		remoteIdentity: identity,
	});
}
