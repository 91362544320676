/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Function to send a heartbeat message to the server to maintain the connection
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.Hooks.UseChatHeartbeat
 */
import { useCallback, useContext, useEffect, useRef } from "react";
import { TIME_FOR_HEARTBEAT_INTERVAL_SECONDS } from "~/types/chat";
import { IHeartbeatMessage } from "~/types/websockets";
import { secondsToMs } from "~/utils/dateTime";
import { useInterval } from "../../../hooks/useInterval";
import { WebSocketContext } from "../../WebSocket/WebSocketConnection";
import { ConnectionContext } from "../ConnectionManagement";

/**
 * React hook that sends a heartbeat message to the server to maintain the connection
 * and keep the chat messages up to date.
 */
export function useChatHeartbeat(highestSequentialId: number): (sequentialId: number) => void {
	const { chatConnectionStatus } = useContext(ConnectionContext);
	const { sendSocketMessage } = useContext(WebSocketContext);

	const highestSequentialIdRef = useRef(highestSequentialId);
	useEffect(() => {
		highestSequentialIdRef.current = highestSequentialId;
	}, [highestSequentialId]);

	const sendHeartbeatMessage = useCallback(
		(sequentialId: number) => {
			if (chatConnectionStatus !== "Connected") {
				return;
			}

			// eslint-disable-next-line @typescript-eslint/naming-convention
			const heartbeatData = { SequentialId: sequentialId };
			const heartbeatMessage: IHeartbeatMessage = {
				data: heartbeatData,
				messageId: crypto.randomUUID(),
				messageType: "Heartbeat",
				version: "1.0",
				requiresAck: false,
			};
			sendSocketMessage(heartbeatMessage);
			return;
		},
		[chatConnectionStatus, sendSocketMessage],
	);

	useInterval(
		() => sendHeartbeatMessage(highestSequentialIdRef.current),
		secondsToMs(TIME_FOR_HEARTBEAT_INTERVAL_SECONDS),
	);

	return sendHeartbeatMessage;
}
