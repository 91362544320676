/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Camera row for the hardware test page
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.CameraSetup
 */

import React, { FC, useContext } from "react";
import { useStrings } from "~/hooks";
import Camera from "~/icons/camera";
import { useHardwareTestState } from "~/state";
import { DisplayContext } from "~/types";
import { HardwareSetupDisplayContext } from "../HardwareSetup";
import { useCameraDeviceMessage } from "../hooks/useCameraDeviceMessage";
import CameraSelector from "./CameraSelector";
import DeviceSetupRow from "./DeviceSetupRow";

enum TokenNames {
	camera = "Camera",
}

export enum CameraSetupTestIds {
	self = "CameraSetup",
}

/**
 * The CameraSetup component
 */
const CameraSetup: FC = () => {
	const displayContext = useContext(HardwareSetupDisplayContext);
	const status = useHardwareTestState(
		(selectors) => selectors.getCameraStatus(displayContext === DisplayContext.lobby),
		[],
	);

	const message = useCameraDeviceMessage();

	const strings = useStrings("CameraSetup", Object.values(TokenNames));

	return (
		<DeviceSetupRow
			icon={Camera}
			labelText={strings[TokenNames.camera]}
			status={status}
			deviceSelectorComponent={CameraSelector}
			statusMessage={message}
			data-testid={CameraSetupTestIds.self}
		/>
	);
};

CameraSetup.displayName = "CameraSetup";

export default CameraSetup;
