/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Speaker row for the hardware test page
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.SpeakerSetup
 */

import React, { FC, useContext } from "react";
import { useStrings } from "~/hooks";
import Speaker from "~/icons/speaker";
import { useHardwareTestState } from "~/state";
import { DeviceStatus, DisplayContext } from "~/types";
import { HardwareSetupDisplayContext } from "../HardwareSetup";
import { useSpeakerDeviceMessage } from "../hooks/useSpeakerDeviceMessage";
import DeviceSetupRow from "./DeviceSetupRow";
import SpeakerSelector from "./SpeakerSelector";
import TestSpeakerButton, { TestSpeakerButtonTestIds } from "./TestSpeakerButton";

enum TokenNames {
	rowLabel = "Speaker",
	noSpeakerDetected = "NoSpeakerDetected",
	issueResolution = "IssueResolution",
	issueResolutionMobile = "IssueResolution_Mobile",
}

export enum SpeakerSetupTestIds {
	self = "SpeakerSetup",
}

/**
 * The SpeakerSetup component
 */
const SpeakerSetup: FC = () => {
	const { message, onSpeakerTest } = useSpeakerDeviceMessage();
	const displayContext = useContext(HardwareSetupDisplayContext);
	const status = useHardwareTestState((selectors) => selectors.getSpeakerStatus(), []);

	const strings = useStrings("SpeakerSetup", Object.values(TokenNames));

	// speaker warnings happen when there are no speakers, just don't show an icon as this
	// happens frequently on some operating systems (e.g. iOS)
	const displayStatus = status === DeviceStatus.warning ? DeviceStatus.testing : status;

	return (
		<DeviceSetupRow
			icon={Speaker}
			labelText={strings[TokenNames.rowLabel]}
			status={displayStatus}
			deviceSelectorComponent={SpeakerSelector}
			statusMessage={message}
			data-testid={SpeakerSetupTestIds.self}
		>
			{displayContext === DisplayContext.devicesTray && (
				<TestSpeakerButton
					onAudioPlayed={onSpeakerTest}
					data-testid={TestSpeakerButtonTestIds.self}
				/>
			)}
		</DeviceSetupRow>
	);
};

SpeakerSetup.displayName = "SpeakerSetup";

export default SpeakerSetup;
