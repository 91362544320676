/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Warning banner to inform the user that they are a device impacted by android 12 corrupted video encoding bug
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.HardwareTest.Banners.Android12VideoWarning
 */

import React, { FC, useMemo } from "react";
import { useStrings } from "~/hooks";
import { isChrome } from "~/utils/browser";
import BubbleBanner from "../../Utilities/BubbleBanner";

interface IProps {
	inline?: boolean;
}

/**
 * The Android Warning banner component
 */
const Android12VideoWarning: FC<IProps> = (props) => {
	const { inline = false } = props;
	const chrome = useMemo(() => isChrome(), []);
	const strings = useStrings("Android12VideoWarning", Object.values(TokenNames));

	return (
		<BubbleBanner
			type="warning"
			title={strings[TokenNames.title]}
			message={chrome ? strings[TokenNames.messageChrome] : strings[TokenNames.messageNonChrome]}
			inline={inline}
		/>
	);
};

enum TokenNames {
	title = "Title",
	messageChrome = "MessageChrome",
	messageNonChrome = "MessageNonChrome",
}

Android12VideoWarning.displayName = "Android12VideoWarning";

export default Android12VideoWarning;
