/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file DataTrack Messaging types
 * @author Will Cooper
 * @module Epic.VideoApp.Types.Messaging
 */

import { INamelessParticipantData } from "~/components/VideoCall/hooks/useNamelessParticipantData";
import { EpicUserType } from "./user";

// generic type for messages
// includes message type, payload, and any other metadata headers that all messages should provide
interface IDataTrackMessageBase<T> {
	type: MessageType;
	payload: T;
	action: MessageActionType; //If "request" must also send recipient
	needsACK: boolean; //Flag for whether this message needs acknowledgement
	messageSID?: string; // Unique message ID (only needed for messages that need ACKs)
	recipients?: string[]; //Should only be included if type is not broadcast
}

// Generic
export interface IAckMessagePayload {
	messageID: string; //the exact message we wish to inform the sender that we successfully received
}

// Participant information update message
export interface IParticipantUpdatePayload extends IAckMessagePayload {
	cameraLocked: boolean;
	micLocked: boolean;
	screenShareLocked: boolean;
	// Whether screen share has been disabled by configuration
	screenShareAllowed: boolean;
	// Whether screen share has been disabled by device support
	deviceSupportsScreenShare: boolean;
	displayName: string;
	userType: EpicUserType;
	inWaitingRoom: boolean;
}

/**
 * Values for messages that will perform moderator actions.
 *
 * Undefined - make no update
 * False - remove a lock on the associated media
 * True - lock the associated media
 */
export interface IModeratorPayload {
	audioLock?: boolean;
	videoLock?: boolean;
	screenShareLock?: boolean;
}

/**
 *
 * Undefined -> Don't update state
 * False -> Not requesting
 * True -> Requesting access
 */
export interface IAccessRequestPayload {
	audioRequest?: boolean;
	videoRequest?: boolean;
	screenShareRequest?: boolean;
}

export interface IInfoUpdateMessage extends IDataTrackMessageBase<IParticipantUpdatePayload> {
	type: MessageType.participantInfo;
}

// Info request
export interface IInfoRequestMessage extends IDataTrackMessageBase<null> {
	type: MessageType.infoRequest;
}

// Blank name list synchronization
export interface INamelessParticipantSyncRequestPayload {
	namelessParticipantData: INamelessParticipantData;
}
export interface INamelessParticipantSyncRequest
	extends IDataTrackMessageBase<INamelessParticipantSyncRequestPayload> {
	type: MessageType.namelessParticipantSyncRequest;
}

export interface INamelessParticipantSyncResponsePayload {
	namelessParticipantData: INamelessParticipantData;
}
export interface INamelessParticipantSyncResponse
	extends IDataTrackMessageBase<INamelessParticipantSyncResponsePayload> {
	type: MessageType.namelessParticipantSyncResponse;
}

export interface IModeratorActionMessage extends IDataTrackMessageBase<IModeratorPayload> {
	type: MessageType.moderate;
}

export interface IVisitAccessMessage extends IDataTrackMessageBase<IModeratorPayload> {
	type: MessageType.visitAccess;
}

export interface IMediaAccessRequestMessage extends IDataTrackMessageBase<IAccessRequestPayload> {
	type: MessageType.accessRequest;
}

export interface IImageCapturedMessage extends IDataTrackMessageBase<string> {
	type: MessageType.imageCaptured;
}

export interface IVisitEnded extends IDataTrackMessageBase<null> {
	type: MessageType.visitEnded;
}

export enum MessageType {
	participantInfo,
	infoRequest,
	acknowledge,
	namelessParticipantSyncRequest,
	namelessParticipantSyncResponse,
	moderate,
	visitAccess,
	accessRequest,
	imageCaptured,
	visitEnded,
}

export enum MessageActionType {
	broadcast,
	request,
}

export type IDataTrackMessage =
	| IInfoRequestMessage
	| IInfoUpdateMessage
	| INamelessParticipantSyncRequest
	| INamelessParticipantSyncResponse
	| IModeratorActionMessage
	| IVisitAccessMessage
	| IMediaAccessRequestMessage
	| IImageCapturedMessage
	| IVisitEnded;

export const initialCallDelay = 500;
