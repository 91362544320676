/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Shared state for the user's local settings
 * @author Razi Rais
 * @module Epic.VideoApp.State.Settings
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { getPreferredLocale } from "~/utils/i18n/i18nUtils";

/// TYPES ///

export interface ISettingsState {
	readonly locale: string;
	loadingLocale: string | null;
}

/// INIT ///

export function getInitialState(): ISettingsState {
	const locale = getPreferredLocale();
	return { locale, loadingLocale: null };
}

/// REDUCERS ///

export function setLocale(state: ISettingsState, newLocale: string): ISettingsState {
	return { ...state, locale: newLocale, loadingLocale: null };
}

export function setLoadingLocale(state: ISettingsState, locale: string | null): ISettingsState {
	return { ...state, loadingLocale: locale };
}

/// SELECTORS ///

function getLocale(state: ISettingsState): string {
	return state.locale;
}

function getLoadingLocale(state: ISettingsState): string | null {
	return state.loadingLocale;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	selectors: { getLocale, getLoadingLocale },
	reducers: { setLoadingLocale },
});

store.addSharedState(builtState.sharedState, "settings");

export const {
	actionCreators: settingsActions,
	useSharedState: useSettingsState,
	sharedState: state,
} = builtState;
