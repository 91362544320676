/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file restart LocalAudioTrack on interval to avoid distortion (currently iOS15.4 only)
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Hooks.LocalTracks.Workarounds.UseLocalAudioRestartInterval
 */

import { useEffect, useRef } from "react";
import { LocalAudioTrack } from "twilio-video";
import { minutesToMs } from "~/utils/dateTime";
import { debug } from "~/utils/logging";

const RESTART_INTERVAL_MINUTES = 3;

/**
 * For devices on iOS15.4, we've observed the transmitted audio becoming distorted after ~6 min on the call. Restarting
 * the LocalAudioTrack eliminates the distortion, and restarting before that ~6 min threshold prevents the issue from occurring.
 * @param track LocalAudioTrack if on iOS15.4; null provided on other platforms
 */
export function useLocalAudioRestartInterval(track: LocalAudioTrack | null): void {
	// track the window.setInterval value
	const intervalRef = useRef(0);

	useEffect(() => {
		if (!track) {
			return;
		}

		// function to invoke at each interval
		const intervalCallback = (): void => {
			debug("Restarting track on interval (iOS workaround)...");
			void track.restart();
		};

		// clear any current interval; create new interval and track its identifier
		const intervalSetup = (): void => {
			window.clearInterval(intervalRef.current);
			intervalRef.current = window.setInterval(intervalCallback, minutesToMs(RESTART_INTERVAL_MINUTES));
		};

		// listen for started events, which indicate a new MediaStreamTrack (cue to reset the interval)
		track.on("started", intervalSetup);
		intervalSetup();

		return () => {
			window.clearInterval(intervalRef.current);
			track.off("started", intervalSetup);
		};
	}, [track]);
}
