/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Generic class that validates background processor images for any vendor
 *       that loads virtual backgrounds using image urls
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Helpers.VirtualBackgroundLoader
 */

import { BackgroundSettings } from "~/types/backgrounds";
import { EVCEmitter, IEVCUserEventMap } from "../events";

export type BlurBackgroundOnLoad = (background: BackgroundSettings) => Promise<boolean>;
export type ImageBackgroundOnLoad = (
	background: BackgroundSettings,
	img: HTMLImageElement,
) => Promise<boolean>;

export class VirtualBackgroundLoader extends EVCEmitter<IEVCUserEventMap> {
	private _loadAttempts: number = 0;
	private _loadAttemptsSuccess: number = 0;
	private _totalBackgrounds: number = 0;
	private _loadedProcessors: string[] = [];

	constructor() {
		super();
	}

	/**
	 * Method to call to validate and optionally initialize processors
	 * @param backgrounds Array of background metadata used to initialize a processor
	 * @param availableBackgroundsCount Total number of backgrounds to attempt to load
	 * @param blurOnLoad Optional callback to execute when a blur processor is loaded, can be used for extra initialization
	 *                   The function should return 'true' if the processor should be considered loaded, 'false' otherwise
	 * @param imgOnLoad Optional callback to execute when an image processor is loaded, can be used for extra initialization
	 *                  The function should return 'true' if the processor should be considered loaded, 'false' otherwise
	 */
	async validateProcessors(
		backgrounds: BackgroundSettings[],
		availableBackgroundsCount: number,
		blurOnLoad?: BlurBackgroundOnLoad,
		imgOnLoad?: ImageBackgroundOnLoad,
	): Promise<void> {
		this._totalBackgrounds = availableBackgroundsCount;
		const promises = backgrounds.map(async (background) => {
			if (background.type === "blur") {
				// No validation needed for blur processors
				this._loadedProcessors.push(background.path);
				if (blurOnLoad !== undefined) {
					this.updateLoadStatus(await blurOnLoad(background));
				} else {
					this.updateLoadStatus(true);
				}
				return;
			}
			try {
				if (await this.tryLoadImage(background, imgOnLoad)) {
					this._loadedProcessors.push(background.path);
					this.updateLoadStatus(true);
				} else {
					this.updateLoadStatus(false);
				}
			} catch {
				this.updateLoadStatus(false);
			}
		});
		await Promise.all(promises);
	}

	/**
	 * Private method to attempt to load an image used for backgrounds
	 * @param background Processor metadata
	 * @param onLoad Optional callback to call after the image is loaded for additional initialization
	 * @returns True if the image was loaded successfully, false otherwise
	 */
	private tryLoadImage(background: BackgroundSettings, onLoad?: ImageBackgroundOnLoad): Promise<boolean> {
		// This should be impossible but it makes typescript realize the setting has a src property
		if (background.type !== "image") {
			throw new Error("Invalid background type");
		}
		const img = new Image();
		return new Promise((resolve) => {
			img.onload = () => {
				if (onLoad !== undefined) {
					resolve(onLoad(background, img));
				} else {
					resolve(true);
				}
			};
			img.onerror = () => {
				resolve(false);
			};
			img.src = background.src;
		});
	}

	/**
	 * Method to call to update the load status of the processors
	 * When all processors have attempted to be loaded, emit an event so the app layer can handle the result
	 * @param wasSuccessful Pass true to indicate a successful load, false otherwise
	 */
	private updateLoadStatus(wasSuccessful: boolean): void {
		this._loadAttempts++;
		if (wasSuccessful) {
			this._loadAttemptsSuccess++;
		}
		if (this._loadAttempts === this._totalBackgrounds) {
			if (this._loadAttemptsSuccess === 0) {
				this.emit("backgroundProcessorsDone", {
					type: "backgroundProcessorsDone",
					status: "failed",
					successfulProcessors: this._loadedProcessors,
				});
			} else {
				this.emit("backgroundProcessorsDone", {
					type: "backgroundProcessorsDone",
					status: "finished",
					successfulProcessors: this._loadedProcessors,
				});
			}
		}
	}
}
