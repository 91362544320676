/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Card component
 * @author Matt Panico
 * @module Epic.VideoApp.Components.Cards.Card
 */

import React, { FC } from "react";
import { resolveClassName } from "~/utils/className";
import { BackgroundStyleContextProvider } from "../Utilities/BackgroundStyleContext";
import styles from "./Card.module.scss";

import { StyleContext } from "../../types/layout";

interface IProps {
	/* If there's a button at the bottom of this card */
	hasButton?: boolean;
	/* If the card should always be centered, rather than moving out of the way of video previews */
	centered?: boolean;
	/* If a rawClass is provided, it is used as a class for the card element to style the card*/
	rawClass?: string;
}

export enum CardTestIds {
	self = "Card",
}

/**
 * The Card component
 * @param _props The props ;)
 */
const Card: FC<IProps> = (props) => {
	const { hasButton, centered, rawClass, children } = props;
	const className = resolveClassName(styles, { card: true, hasButton, centered }, rawClass);

	return (
		<BackgroundStyleContextProvider style={StyleContext.light}>
			<div className={className} data-testid={CardTestIds.self}>
				{children}
			</div>
		</BackgroundStyleContextProvider>
	);
};

Card.displayName = "Card";

export default Card;
