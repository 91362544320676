/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file blurry background icon
 * @author Liam Liden
 * @module Epic.VideoApp.Icons.BlurryBackground
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum BlurryBackgroundTestIds {
	self = "BlurryBackground",
}

/**
 * The Blurry Background component
 * @param props The props ;)
 */
const BlurryBackground: FC<IconProps> = memo((props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
			data-testid={BlurryBackgroundTestIds.self}
		>
			<circle cx="16" cy="13.24" r="3.71" />
			<path d="M22.13,25.43V20.68a2,2,0,0,0-2-2H12.05a2,2,0,0,0-2,2v4.75Z" />
			<path d="M6.83,6.57H6.76A1.76,1.76,0,0,0,5,8.33V8.4Z" />
			<polygon points="4.99 13.26 11.69 6.57 10.19 6.57 4.99 11.76 4.99 13.26" />
			<path d="M27,23.77l-1.56,1.56A1.77,1.77,0,0,0,27,23.77Z" />
			<path d="M15.3,7.82l1.24-1.25H15L12.82,8.79A5.39,5.39,0,0,1,15.3,7.82Z" />
			<path d="M11.55,10.06,5,16.62v1.5l5.59-5.59A5.51,5.51,0,0,1,11.55,10.06Z" />
			<path d="M19.18,8.79,21.4,6.57H19.9L18.23,8.25A6,6,0,0,1,19.18,8.79Z" />
			<path d="M10.86,17.11l.7-.7a4.71,4.71,0,0,1-.55-.95l-6,6V23l3.49-3.49A3.76,3.76,0,0,1,10.86,17.11Z" />
			<path d="M21.22,11.61l4.83-4.84a1.8,1.8,0,0,0-.81-.2h-.48l-4,4A5.45,5.45,0,0,1,21.22,11.61Z" />
			<path d="M8.27,23.06,6.11,25.22a1.75,1.75,0,0,0,.65.13h.72l.79-.79Z" />
			<path d="M21.16,15a5.32,5.32,0,0,1-1.11,1.88h.06a4.07,4.07,0,0,1,.61.06L27,10.68V9.18Z" />
			<path d="M22.9,18.15a3.66,3.66,0,0,1,.6.89L27,15.54V14Z" />
			<polygon points="23.89 22.01 23.89 23.51 27.01 20.39 27.01 18.89 23.89 22.01" />
		</svg>
	);
});

BlurryBackground.displayName = "BlurryBackground";

export default BlurryBackground;
