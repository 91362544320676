/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Hardware Test Lower card contents
 * @author Jonathon Moore
 * @module Epic.VideoApp.Components.HardwareTest.HardwarePreviewJoinOptions
 */
import React, { FC, PropsWithChildren } from "react";
import { useLocalTrackState } from "~/state";
import DisplayNameInput from "../HardwareSetup/DisplayNameInput";
import { useAudioDeviceMessage } from "../HardwareSetup/hooks/useAudioDeviceMessage";
import { useCameraDeviceMessage } from "../HardwareSetup/hooks/useCameraDeviceMessage";
import { useSpeakerDeviceMessage } from "../HardwareSetup/hooks/useSpeakerDeviceMessage";
import HardwareStatusBanner from "./Banners/HardwareStatusBanner";
import styles from "./HardwareTest.module.scss";
import SkipHardwareTestSection from "./SkipHardwareTestSection";

interface IProps {
	isLobby?: boolean;
}

const HardwarePreviewJoinOptions: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { isLobby, children } = props;

	const localTrackAcquisitionStatus = useLocalTrackState(
		(selectors) => selectors.getLocalTrackAcquisitionStatus(),
		[],
	);
	const showingWarning = localTrackAcquisitionStatus === "showingWarning"; // True if the alert requesting hardware permissions is being shown.

	// For message panel
	const { message: speakerMessage } = useSpeakerDeviceMessage();
	const audioMessage = useAudioDeviceMessage();
	const cameraMessage = useCameraDeviceMessage();

	return (
		<div className={styles["actionContent"]}>
			<div aria-live="polite">
				<HardwareStatusBanner
					isLobby={isLobby}
					speakerMessage={speakerMessage}
					audioMessage={audioMessage}
					cameraMessage={cameraMessage}
				/>
			</div>
			<SkipHardwareTestSection styling="lobby" />
			<div className={styles["nameAndVideoContainer"]}>
				{isLobby && <DisplayNameInput disabled={showingWarning} />}
				{children}
			</div>
		</div>
	);
};

HardwarePreviewJoinOptions.displayName = "HardwarePreviewJoinOptions";

export default HardwarePreviewJoinOptions;
