/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Event handlers for Daily call events
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Implementations.DailyEventHandlers
 */

import {
	DailyEventObject,
	DailyEventObjectActiveSpeakerChange,
	DailyEventObjectFatalError,
	DailyEventObjectParticipant,
	DailyEventObjectTrack,
} from "@daily-co/daily-js";
import { IInfoRequestMessage, MessageActionType, MessageType } from "~/types";
import { sendMessage } from "~/utils/sendMessage";
import { MediaEvent } from "~/web-core/events";
import { IUser } from "~/web-core/interfaces";
import { DeviceKind, VideoType } from "~/web-core/types";
import { DailyRemoteStream, DailyRemoteUser, DailySession } from ".";
import { dailyErrorToVendorError } from "../functions/dailyErrorUtils";

/**
 * Handles the event when a participant joins the call
 * @param event The event object for the participant joining
 * @param session The session object for the call
 * @returns void
 */
export function handleDailyParticipantJoined(
	event: DailyEventObject<"participant-joined">,
	session: DailySession,
): void {
	// No need to handle local participants
	if (event.participant.local) {
		return;
	}
	const participant = event.participant;
	const remoteUser = new DailyRemoteUser(participant, session.call);

	// Attempt to remove the user from the participant list if they were already connected from another session
	// This resolves a possible race condition where the wrong user is cleaned up when a duplicate user joins
	removeUserIfDuplicate(event.participant.user_id, session);

	session.participants.push(remoteUser);

	const message: IInfoRequestMessage = {
		action: MessageActionType.request,
		payload: null,
		recipients: [remoteUser.getUserIdentity()],
		type: MessageType.infoRequest,
		needsACK: false,
	};

	if (session.getIsAdmitted()) {
		// Assume screen share should always be shown, so subscribe automatically
		session.call.updateParticipant(event.participant.session_id, {
			setSubscribedTracks: { screenVideo: true, screenAudio: true, audio: true },
		});
	}

	sendMessage(session, message);

	session.emit("participantConnected", { type: "participantConnected", participant: remoteUser });
}

/**
 * Helper function to remove a user from the participants array if they are already connected
 * @param userIdentity Identity of the new connecting user. Used to determine if the user is a duplicate
 * @param session DailySession object to signal that the user has been removed
 */
function removeUserIfDuplicate(userIdentity: string, session: DailySession): void {
	const duplicateUserIndex = session.participants.findIndex(
		(participant) => participant.getUserIdentity() === userIdentity,
	);

	if (duplicateUserIndex !== -1) {
		session.handleParticipantDisconnectFromArrayIndex(duplicateUserIndex);
	}
}

/**
 * Handles the event when a participant disconnects from the call
 * @param event The event object for the participant leaving
 * @param session The session object for the call
 * @returns void
 */
export function handleDailyParticipantDisconnected(
	event: DailyEventObject<"participant-left">,
	session: DailySession,
): void {
	const disconnectedUserIndex = session.participants.findIndex(
		(participant) => participant.getUserGuid() === event.participant.session_id,
	);
	// If the user is not found in the participants array, they have already been removed
	if (disconnectedUserIndex === -1) {
		return;
	}

	session.handleParticipantDisconnectFromArrayIndex(disconnectedUserIndex);
}

/**
 * Handles the event when a participant's information is updated
 * @param event The event object for the participant update
 * @param session The session object for the call
 * @returns void
 */
export function handleDailyParticipantUpdate(
	event: DailyEventObjectParticipant,
	session: DailySession,
): void {
	if (event.participant.local) {
		session.localUser.participant = event.participant;
		return;
	}
	const remoteUser = session.participants.find(
		(current) => current.getUserGuid() === event.participant.session_id,
	);

	if (!remoteUser) {
		return;
	}

	remoteUser.participant = event.participant;
}

/**
 * Handles the event when the active speaker changes
 * @param event The event object for the active speaker change
 * @param session The session object for the call
 */
export function handleDailyActiveSpeakerChanged(
	event: DailyEventObjectActiveSpeakerChange,
	session: DailySession,
): void {
	const user = session.participants.find(
		(participant) => participant.getUserGuid() === event.activeSpeaker.peerId,
	);

	if (!user) {
		return;
	}

	session._dominantSpeaker = user;
	session.emit("dominantSpeakerChanged", { type: "dominantSpeakerChanged", participant: user });
}

/**
 * Handles the event when a fatal error occurs (will always disconnect the user)
 * @param event The event object for the fatal error
 * @param session The session object for the call
 */
export function handleDailyErrorEvent(event: DailyEventObjectFatalError, session: DailySession): void {
	const vendorError = dailyErrorToVendorError(event.error);
	session.emit("disconnected", { type: "disconnected", error: vendorError });
}

/**
 * Handles the event when a message is received
 * @param event The event object for the message object
 * @param session The session object for the call
 */
export function handleDailyDataMessageEvent(
	event: DailyEventObject<"app-message">,
	session: DailySession,
): void {
	const userGuid = event.fromId;
	const message = event.data as string;
	const user = session.participants.find((participant) => participant.getUserGuid() === userGuid);

	// Don't surface a message if we can't determine who sent it
	if (!user) {
		return;
	}

	user.emit("dataMessageReceived", {
		type: "dataMessageReceived",
		message: message,
	});
}

/**
 * Handles the event when a track is updated (started or stopped)
 * @param event The event object for the track update
 * @param session The session object for the call
 * @returns void
 */
export function handleDailyTrackUpdatedEvent(event: DailyEventObjectTrack, session: DailySession): void {
	// Associate a track event with a user
	const user = event.participant?.local
		? session.localUser
		: session.participants.find(
				(participant) => participant.getUserIdentity() === event.participant?.user_id,
		  );

	if (!user || !event.participant) {
		return;
	}

	const type: VideoType = determineTrackType(event);

	// Update the user's device stream with the updated track
	if (type === "camera") {
		user.deviceStream.videoDevice = event.participant.tracks?.video ?? null;
		user.deviceStream.audioDevice = event.participant.tracks?.audio ?? null;
	} else if (type === "screen") {
		if (user instanceof DailyRemoteUser) {
			updateScreenShareForUser(event, user, event.action === "track-started", session);
		}
		if (user.shareStream) {
			user.shareStream.videoDevice = event.participant.tracks?.screenVideo ?? null;
			user.shareStream.audioDevice = event.participant.tracks?.screenAudio ?? null;
		}
	}

	// Emit the various stream specific events and user-based events
	emitStreamStatusEvents(event, user, event.action === "track-started", type);
	user.emit("participantUpdated", {
		type: "participantUpdated",
		participant: user,
		videoType: type,
	});
}

export function handleCpuLoadChangeEvent(
	event: DailyEventObject<"cpu-load-change">,
	session: DailySession,
): void {
	if (event.cpuLoadState === "high") {
		if (event.cpuLoadStateReason === "decode" && !session.isInLowQualityMode) {
			// Performance is low due to decoding, drop received video quality
			session.isInLowQualityMode = true;
			session.setRemoteParticipantQuality("low");
		}
		if (event.cpuLoadStateReason === "encode") {
			// Performance is low due to encoding, stop sending high quality video
			void session.call.updateSendSettings({ video: "bandwidth-optimized" });
		}
	}
	if (event.cpuLoadState === "low") {
		if (session.isInLowQualityMode) {
			// Disable low quality mode
			session.setRemoteParticipantQuality("normal");
			session.isInLowQualityMode = false;
		}
		void session.call.updateSendSettings({ video: "bandwidth-and-quality-balanced" });
	}
}

/**
 * Handles a screen share track being published/unpublished
 * @param trackEvent The event object for the track update
 * @param user The user associated with the track event
 * @param started Whether the track was started or stopped
 * @param session The session object for the call
 */
function updateScreenShareForUser(
	trackEvent: DailyEventObjectTrack,
	user: DailyRemoteUser,
	started: boolean,
	session: DailySession,
): void {
	if (started && trackEvent.participant?.tracks && !user.shareStream) {
		user.shareStream = new DailyRemoteStream(trackEvent.participant?.tracks, "screen");
		session.emit("screenShareStarted", { type: "screenShareStarted", participant: user });
	} else if (!started && trackEvent.participant?.tracks.screenVideo.state === "off") {
		user.shareStream = null;
		session.emit("screenShareStopped", { type: "screenShareStopped", participant: user });
	}
}

/**
 * Handles a emitting events for the track being started or stopped
 * @param trackEvent The event object for the track update
 * @param user The user associated with the track event
 * @param started Whether the track was started or stopped
 * @param videoType The type of video track that was updated
 */
function emitStreamStatusEvents(
	trackEvent: DailyEventObjectTrack,
	user: IUser,
	started: boolean,
	videoType: VideoType,
): void {
	const kind = determineTrackKind(trackEvent);

	// Daily sends track-stopped events whenever local tracks enable a background.
	// Catch those cases here so we don't emit app track status events for enabling/disabling backgrounds.
	if (
		!started &&
		trackEvent.track.kind === "video" &&
		trackEvent.participant?.local &&
		trackEvent.track &&
		("canvas" in trackEvent.track || trackEvent.track?.readyState === "live")
	) {
		return;
	}

	// Emit the matching event for turning a specific track on/off
	const eventType: MediaEvent = started ? `${kind}Enabled` : `${kind}Disabled`;
	const trackType = kind === "video" ? "videoReady" : "audioReady";
	if (videoType === "screen") {
		user.shareStream?.emit(eventType, { type: eventType });
		user.shareStream?.emit(trackType, { type: trackType, track: trackEvent.track });
	} else {
		user.deviceStream.emit(eventType, { type: eventType });
		user.deviceStream.emit(trackType, { type: trackType, track: trackEvent.track });
	}
}

/**
 * Determines if a track received in an event is a camera or screen share track
 * @param track Track object received in Daily event
 * @returns "screen" if the track is a screen share track, "camera" otherwise
 */
function determineTrackType(track: DailyEventObjectTrack): VideoType {
	if (track.type === "screenVideo" || track.type === "screenAudio") {
		return "screen";
	}

	return "camera";
}

/**
 * Determines if a track received in an event is an audio or video track
 * @param track Track object received in Daily event
 * @returns "audio" if the track is an audio track, "video" otherwise
 */
function determineTrackKind(track: DailyEventObjectTrack): DeviceKind {
	if (track.type === "audio" || track.type === "screenAudio") {
		return "audio";
	}

	return "video";
}
