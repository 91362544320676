/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file buildCombinedAccessorDependencies function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\store\build-combined-accessor-dependencies
 */

import { ISelectorCollection } from "../../types";
import { SelectorWithMetadata } from "../types";

/**
 * Add the required slice IDs for all the given selectors to a map for easy access later.
 * @param selectors The collection of selectors to grab the required slice IDs from
 */
export function buildCombinedAccessorDependencies(selectors: ISelectorCollection<any>): number[] {
	const collection: { [id: number]: true | undefined } = {};
	Object.keys(selectors).forEach(key => {
		const { requiredSliceIds } = selectors[key] as SelectorWithMetadata;
		if (requiredSliceIds) {
			requiredSliceIds.forEach(id => {
				collection[id] = true;
			});
		}
	});
	return Object.keys(collection).map(val => +val);
}
