/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to handle sending out messages on a TwilioDataTrack
 * @author Will Cooper
 * @module Epic.VideoApp.Utils.SendMessage
 */

import { warn } from "~/utils/logging";
import { isValidDataTrackMessage } from "~/utils/messaging";
import { ISession } from "~/web-core/interfaces";
import { IDataTrackMessage } from "../types";

/**
 * Function to access messaging state for the purpose of sending out data track messages to remote participants.
 * Validates message fields before sending them out.
 * When needing to respond to a request-based message in the future, we should require this hook to return a promise to be properly awaited.
 * @returns the function to send any properly formatted data track message out to remote participants.
 */
export function sendMessage(session: ISession | undefined, message: IDataTrackMessage): void {
	// Anything request based (display name request, image capture) needs to know when its requested data is available.
	if (!session) {
		return;
	}

	//Setup messageSID (unique value for each message sent)
	message.messageSID =
		session.localUser.getUserIdentity() + Math.random().toString() + Date.now().toString();

	if (!isValidDataTrackMessage(message)) {
		warn("Invalid data track message:", message);
		return;
	}

	// Send the message using vendor-specific logic
	const stringMessage = JSON.stringify(message);
	session.sendDataMessage(stringMessage);

	// Broadcast type messages do not need ACK's, we can resolve after we send the message out on the data track.
	if (!message.needsACK) {
		return;
	}
}
