/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Carousel
 * @author Liam Liden
 * @module Epic.VideoApp.Components.Utilities.Carousel.Carousel
 */

import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { HardwareSetupDisplayContext } from "~/components/HardwareSetup/HardwareSetup";
import Spinner from "~/components/Loading/Spinner";
import { useStrings } from "~/hooks";
import { DisplayContext } from "~/types";
import styles from "./Carousel.module.scss";
import PageArrowButton from "./PageArrowButton";
import PageSelector from "./PageSelector";

export enum CarouselTestIds {
	carousel = "Carousel",
}

interface IProps {
	/** Positive integer number of elements on each page;  */
	elementsPerPage: number;

	/** If true displays the loading spinner instead of any components*/
	loading?: boolean;

	/** Custom CSS class for PageSelectorButtons */
	buttonClassName?: string;

	/** Custom CSS class for page style (content inside the Carousel) */
	pageWrapperClassName?: string;
}

enum TokenNames {
	previousPage = "PreviousPage",
	nextPage = "NextPage",
}

const Carousel: FC<IProps> = (props) => {
	const { elementsPerPage, loading, buttonClassName, pageWrapperClassName, children } = props;
	const strings = useStrings("PagingSection", Object.values(TokenNames));

	const [currentPage, setCurrentPage] = useState(0);

	const inDevicesTray = useContext(HardwareSetupDisplayContext) === DisplayContext.devicesTray;

	// Using React.Children.toArray() here since 'false' children are counted with React.Children.count()
	// Using React.Children.count() would count the conditionally rendered children that are not being rendered
	const totalChildren = React.Children.toArray(children).length;
	const totalPages = Math.ceil(totalChildren / elementsPerPage);
	const isMultiplePages = totalPages > 1;

	// If resizing causes less total pages, set current page to first page
	useEffect(() => {
		if (currentPage > 0 && currentPage >= totalPages) {
			setCurrentPage(totalPages - 1);
		}
	}, [totalPages, currentPage]);

	const nextPage = useCallback(() => {
		if (currentPage + 1 >= totalPages) {
			setCurrentPage(0);
		} else {
			setCurrentPage(currentPage + 1);
		}
	}, [currentPage, totalPages]);

	const prevPage = useCallback(() => {
		if (currentPage - 1 < 0) {
			setCurrentPage(totalPages - 1);
		} else {
			setCurrentPage(currentPage - 1);
		}
	}, [currentPage, totalPages]);

	const pages = useMemo(() => {
		const arr = [];
		for (let index = 0; index < totalPages; index++) {
			const startIndex = index * elementsPerPage;
			arr[index] = (
				<div className={pageWrapperClassName} key={index}>
					{React.Children.toArray(children).slice(startIndex, startIndex + elementsPerPage)}
				</div>
			);
		}

		return arr;
	}, [children, elementsPerPage, totalPages, pageWrapperClassName]);

	return (
		<div className={styles["carousel"]} data-testid={CarouselTestIds.carousel}>
			{loading ? (
				<Spinner />
			) : (
				<>
					<div className={styles["list"]}>
						{isMultiplePages && (
							<PageArrowButton
								onClick={prevPage}
								pageDirection="previous"
								ariaLabel={strings[TokenNames.previousPage]}
								lighten={inDevicesTray}
							/>
						)}
						{pages[currentPage]}
						{isMultiplePages && (
							<PageArrowButton
								onClick={nextPage}
								pageDirection="next"
								ariaLabel={strings[TokenNames.nextPage]}
								lighten={inDevicesTray}
							/>
						)}
					</div>
					{isMultiplePages && (
						<PageSelector
							onClick={setCurrentPage}
							totalPages={totalPages}
							currentPage={currentPage}
							buttonClassName={buttonClassName}
						/>
					)}
				</>
			)}
		</div>
	);
};

Carousel.displayName = "Carousel";

export default Carousel;
