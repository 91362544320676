/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Hook to determine the current main participant
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Hooks.UseMainParticipant
 */

import { useEffect, useState } from "react";
import { IRemoteUser, IUser } from "~/web-core/interfaces";

export function useMainParticipant(
	participants: IRemoteUser[],
	pinnedParticipant: IUser | null,
	dominantSpeaker: IUser | null,
): IUser | null {
	const [mainParticipant, setMainParticipant] = useState<IUser | null>(null);

	useEffect(() => {
		const newMainParticipant = pinnedParticipant || dominantSpeaker || participants[0];
		if (newMainParticipant) {
			setMainParticipant((prev) => {
				if (prev?.getUserIdentity() !== newMainParticipant.getUserIdentity()) {
					return newMainParticipant;
				}
				return prev;
			});
		} else {
			setMainParticipant(null);
		}
	}, [dominantSpeaker, participants, pinnedParticipant]);

	return mainParticipant;
}
