/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file gear icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Gear
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum GearIconTestIds {
	self = "Gear",
}

/**
 * The Gear component
 * @param props The props ;)
 */
const Gear: FC<IconProps> = memo((props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
			data-testid={GearIconTestIds.self}
		>
			<g>
				<path d="M14.37,6h3.38l.37,2.5H14Zm8,1.5L24.63,10l-3.38,3.62-2.88-3ZM26,14.37v3.38l-2.5.37V14Zm-.87,7L23,24l-2.87-1.88,2.5-3.25ZM14.37,26h3.38l.37-3.75H14ZM10.12,7.25,7.88,9.75l3.62,3.87,2.87-3ZM6,14.37v3.38l3.75.37V14Zm1.38,7.5,2.25,2.38,3-2.38-2.76-3Z" />
				<path d="M16,8.13a7.75,7.75,0,1,0,7.75,7.75h0a7.71,7.71,0,0,0-7.67-7.74A.11.11,0,0,0,16,8.13Zm.13,11.74A3.89,3.89,0,0,1,12.25,16a3.79,3.79,0,0,1,3.83-3.75h.05A3.89,3.89,0,0,1,20,16.13,4,4,0,0,1,16.13,19.87Z" />
			</g>
		</svg>
	);
});

Gear.displayName = "Gear";

export default Gear;
