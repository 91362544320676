/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Wraps Twilio video library calls
 * @author Will Cooper
 * @module Epic.VideoApp.Utils.Video
 */

/**
 * Determines whether the provided media stream track is a front facing camera. If no metadata available, assumes front facing.
 * @param track
 * @returns True if front facing/unknown direction
 */
export function isTrackFrontFacing(track: MediaStreamTrack): boolean {
	return track?.getSettings().facingMode !== "environment";
}
