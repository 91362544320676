/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Hook to modularize the logic to get status banner information
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.Hooks.UseGetStatusBannerInfo
 */

import { useDeviceList } from "~/components/VideoCall/hooks/useDeviceList";
import { useStrings } from "~/hooks";
import { useHardwareTestState } from "~/state";
import { HardwareTestError, HardwareTestStatus, IStatusBannerInfo } from "~/types";
import { IS_SET_SINK_ID_SUPPORTED } from "~/utils/browser";
import { isOnIOSVersion } from "~/utils/os";

enum TokenNames {
	defaultMessage = "DefaultMessage",
	iOSWarningMessage = "IOSWarningMessage",
	multipleErrorsWarningMessage = "MultipleErrorsWarningMessage",
	successMessage = "SuccessMessage",
	outdatedOSFailure = "OutdatedOSFailure",
	generalErrorContentTest = "GeneralErrorContent_Test",
	generalErrorContentCall = "GeneralErrorContent_Call",
	generalErrorTitle = "GeneralErrorTitle",
	microphoneErrorContentTest = "MicrophoneErrorContent_Test",
	microphoneErrorContentCall = "MicrophoneErrorContent_Call",
	microphoneErrorTitle = "MicrophoneErrorTitle",
	cameraErrorContentCall = "CameraErrorContent_Call",
	cameraErrorContentTest = "CameraErrorContent_Test",
	cameraErrorTitle = "CameraErrorTitle",
	defaultErrorCall = "DefaultError_Call",
	defaultErrorTest = "DefaultError_Test",
	permissionsErrorTitle = "PermissionsErrorTitle",
	permissionsErrorContentCall = "PermissionsErrorContent_Call",
	permissionsErrorContentTest = "PermissionsErrorContent_Test",
	standaloneTestSuccessTip = "StandaloneTestSuccessTip",
}

/**
 * Hook used to modularize functionality on storing an image and handling the results of the web service call
 */
export function useGetStatusBannerInfo(isLobby: boolean): IStatusBannerInfo {
	const status = useHardwareTestState(
		(selectors) => selectors.getTestStatus({ allowOneError: isLobby || false, isStandalone: !isLobby }),
		[],
	);
	const hasWarningsOrErrors = useHardwareTestState(
		(selectors) => selectors.hasDetectedIssues(!IS_SET_SINK_ID_SUPPORTED),
		[],
	);
	const error = useHardwareTestState((selectors) => selectors.getHardwareTestError(), []);
	const isOnIOSErrorVersion = isOnIOSVersion("14_2");

	const strings = useStrings("HardwareTest", Object.values(TokenNames));

	const devices = useDeviceList();

	switch (status) {
		case HardwareTestStatus.testing:
			return { message: strings[TokenNames.defaultMessage], type: "info" };
		case HardwareTestStatus.passed:
			if (isOnIOSErrorVersion) {
				return { message: strings[TokenNames.iOSWarningMessage], type: "warning" };
			} else if (hasWarningsOrErrors) {
				return { message: strings[TokenNames.multipleErrorsWarningMessage], type: "warning" };
			} else {
				const successBanner: IStatusBannerInfo = {
					message: strings[TokenNames.successMessage],
					type: "success",
				};
				if (!isLobby) {
					return {
						...successBanner,
						additionalDetails: strings[TokenNames.standaloneTestSuccessTip],
					};
				} else {
					return successBanner;
				}
			}
		case HardwareTestStatus.failed:
			// Validating permissions errors to verify if both devices actually have permisison issues.
			if (error === HardwareTestError.permissionsError) {
				// If both devices are experiencing permissions issues then there will be no devices present
				// If we have at least one device that means that only one device type is having permissions issues and
				// we should return a different error
				if (devices.length !== 0) {
					return { message: strings[TokenNames.multipleErrorsWarningMessage], type: "warning" };
				}
			}
			if (isOnIOSErrorVersion) {
				return { message: strings[TokenNames.outdatedOSFailure], type: "critical" };
			} else {
				const [message, additionalDetails] = translateToFriendlyError(error, strings, isLobby);
				return { message: message, additionalDetails: additionalDetails, type: "critical" };
			}
	}
}

/**
 * Translate the current error into a friendly error message
 * @param hardwareError the type of hardware test error that was encountered
 * @param strings dictionary of strings for this component
 * @param isLobby whether or not this is being displayed in the lobby or a standalone hardware test
 * @returns friendly error message given the current error
 */
function translateToFriendlyError(
	hardwareError: HardwareTestError,
	strings: Record<string, string>,
	isLobby?: boolean,
): [string, string | undefined] {
	let title: string;
	let content: string | undefined = undefined;
	switch (hardwareError) {
		// Generic device acquisition
		case HardwareTestError.generalError:
			title = strings[TokenNames.generalErrorTitle];
			content = isLobby
				? strings[TokenNames.generalErrorContentCall]
				: strings[TokenNames.generalErrorContentTest];
			break;
		// Microphone
		case HardwareTestError.microphoneError:
			title = strings[TokenNames.microphoneErrorTitle];
			content = isLobby
				? strings[TokenNames.microphoneErrorContentCall]
				: strings[TokenNames.microphoneErrorContentTest];
			break;
		// Camera
		case HardwareTestError.cameraError:
			title = strings[TokenNames.cameraErrorTitle];
			content = isLobby
				? strings[TokenNames.cameraErrorContentCall]
				: strings[TokenNames.cameraErrorContentTest];
			break;
		// Permissions
		case HardwareTestError.permissionsError:
			title = strings[TokenNames.permissionsErrorTitle];
			content = isLobby
				? strings[TokenNames.permissionsErrorContentCall]
				: strings[TokenNames.permissionsErrorContentTest];
			break;
		// Default
		default:
			title = isLobby ? strings[TokenNames.defaultErrorCall] : strings[TokenNames.defaultErrorTest];
			break;
	}
	return [title, content];
}
