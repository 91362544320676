/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to update string token values
 * @author Razi Rais
 * @module Epic.VideoApp.Hooks.UseStrings
 */

import { useMemo } from "react";
import { useSettingsState } from "~/state";
import { getSharedStrings, getStrings } from "~/utils/strings";

/**
 * Obtains a memoized dictionary of the string translations for a component
 * @param component the component name
 * @param tokenNames A string array of the token names
 * @param sharedTokenNames a string array of token names shared between components
 * @param hardCodedLocale an optional locale override
 * @returns A dictionary containing the string values for each token
 */
export function useStrings(
	component: string,
	tokenNames: string[],
	sharedTokenNames: string[] = [],
	hardCodedLocale?: string,
): Record<string, string> {
	const locale = useSettingsState((selectors) => selectors.getLocale(), []);
	let localeToUse = locale;
	if (hardCodedLocale) {
		localeToUse = hardCodedLocale;
	}

	return useMemo(() => {
		return getStrings(component, tokenNames, sharedTokenNames, localeToUse);
	}, [component, tokenNames, sharedTokenNames, localeToUse]);
}

/**
 * Get a dictionary of strings that are shared between components
 * @param tokenNames A string array of the shared strings to get
 * @returns A dictionary containing the string values for each shared string token
 */
export function useSharedStrings(tokenNames: string[]): Record<string, string> {
	const locale = useSettingsState((selectors) => selectors.getLocale(), []);

	return useMemo(() => getSharedStrings(tokenNames, locale), [tokenNames, locale]);
}
