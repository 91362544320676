/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Component to render sections of the participant menu
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.ParticipantList.ParticipantMenuSection
 */

import React, { FC, useContext } from "react";
import { useBucketedParticipants, useStrings } from "~/hooks";
import { useUserState } from "~/state";
import { EpicUserType } from "~/types";
import { VideoSessionContext } from "~/web-core/components/VideoSessionProvider";
import { IUser } from "~/web-core/interfaces";
import { useParticipantName } from "../hooks/useParticipantName";
import ParticipantActionHeader from "./ParticipantActionHeader";
import styles from "./ParticipantList.module.scss";
import ParticipantRow from "./ParticipantRow";
import ParticipantSectionHeader from "./ParticipantSectionHeader";

interface IProps {
	participantList: IUser[];
	sectionUserType: EpicUserType;
}

/** String tokens used by the ParticipantMenuSection component */
export enum TokenNames {
	careTeamHeader = "CareTeamHeader",
	patientsHeader = "PatientsHeader",
}

/**
 * The ParticipantMenuSection component
 */
const ParticipantMenuSection: FC<IProps> = (props: IProps) => {
	const { sectionUserType, participantList } = props;
	const currentUserType = useUserState((selectors) => selectors.getUserType(), []);
	const { session } = useContext(VideoSessionContext);
	const localUser = session?.getLocalParticipant();
	const localName = useParticipantName(localUser || null, true);

	const sortedParticipants = useBucketedParticipants(participantList);
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);
	const userInWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);

	// Determine if we render the local participant within the current group
	const canUseLocalParticipant = getUserTypeForSection(currentUserType) === sectionUserType && !!localUser;

	const strings = useStrings("ParticipantMenuSection", Object.values(TokenNames));

	const headerTextToken = getHeaderText(sectionUserType);

	// Don't show empty sections or sections we aren't allowed to show
	if ((userInWaitingRoom || sortedParticipants.length < 1) && !canUseLocalParticipant) {
		return null;
	}
	return (
		<div className={styles["listWrapper"]}>
			<ParticipantSectionHeader headerText={strings[headerTextToken]}>
				{canModerate && sectionUserType !== EpicUserType.emp && (
					<ParticipantActionHeader sortedParticipants={sortedParticipants} />
				)}
			</ParticipantSectionHeader>
			{canUseLocalParticipant && localUser && (
				<ParticipantRow
					key={localUser.getUserIdentity()}
					participant={localUser}
					displayName={localName}
					isLocal
				/>
			)}
			{!userInWaitingRoom &&
				sortedParticipants.map(({ participant, displayName }) => (
					<ParticipantRow
						key={participant.getUserIdentity()}
						participant={participant}
						displayName={displayName}
					/>
				))}
		</div>
	);
};

/**
 * We show all WPR / RLA / and unknown in one menu section. Treat all of these as WPR types for sorting the menus
 * @param type - The user type of the current row
 * @returns - The effective user type for the current menu row
 */
function getUserTypeForSection(type: EpicUserType): EpicUserType {
	if (type === EpicUserType.emp) {
		return EpicUserType.emp;
	}
	return EpicUserType.wpr;
}

/**
 * Helper function to choose the proper token to use for the section header
 *
 * @param type - Effective User type of the current section
 * @returns - The proper header that represents the menu section
 */
function getHeaderText(type: EpicUserType): string {
	switch (type) {
		case EpicUserType.emp:
			return TokenNames.careTeamHeader;
		default:
			return TokenNames.patientsHeader;
	}
}

ParticipantMenuSection.displayName = "ParticipantMenuSection";

export default ParticipantMenuSection;
