/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to toggle local video track background processor enable/disable
 * @author Liam Liden
 * @module Epic.VideoApp.Hooks.BackgroundEffects.UseBackgroundToggle
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext } from "react";
import { backgroundProcessorsActions, useBackgroundProcessorsState } from "~/state/backgroundProcessors";
import { ToggleState } from "~/types";
import { DefaultImageNames } from "~/types/backgrounds";
import { boolMatchesToggleState } from "~/utils/general";
import { VideoSessionContext } from "~/web-core/components";
import { ILocalStream } from "~/web-core/interfaces";

interface IBackgroundToggle {
	isEnabled: boolean;
	toggleProcessor: (forceState?: ToggleState) => void;
}

/**
 * Hook to get the status of if the local video track background processor is enabled and toggle enable/disable
 * @param path The path of the image used for the background processor and the key used to locate the background processor in shared state map
 * @param applyStream Stream that the background processor should be applied to; if not given processor is applied to local device stream
 * @returns tuple with the current enabled/disabled status and function to toggle that status
 */
export function useBackgroundToggle(path: string, applyStream: ILocalStream | null): IBackgroundToggle {
	const { session } = useContext(VideoSessionContext);
	const localUser = session?.localUser;

	const dispatch = useDispatch();

	const publishedProcessor = useBackgroundProcessorsState(
		(selectors) => selectors.getPublishedBackgroundProcessor(),
		[],
	);
	const processorMap = useBackgroundProcessorsState((selectors) => selectors.getBackgroundProcessors(), []);

	// Check if processor type is already set on the published track
	const isEnabled = path === publishedProcessor;

	// Callback to toggle the processor on/off
	const toggleProcessor = useCallback(
		async (forceState?: ToggleState) => {
			const newProcessorPath = isEnabled ? DefaultImageNames.none : path;

			if (!localUser) {
				return;
			}

			if (boolMatchesToggleState(isEnabled, forceState)) {
				return;
			}

			dispatch(backgroundProcessorsActions.setPublishedBackgroundProcessor(newProcessorPath));

			const processor = processorMap.get(newProcessorPath)?.processor ?? null;
			await localUser.applyVideoBackground(processor, applyStream);
		},
		[dispatch, isEnabled, localUser, path, applyStream, processorMap],
	);

	return { isEnabled, toggleProcessor };
}
