/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file types used for chat
 * @author Noah Allen
 * @module Epic.VideoApp.Types.Chat
 */

export interface IChatConfig {
	chatEnabled: boolean;
}

export const MAX_MESSAGE_LENGTH = 1000;

export const SEND_GROUP_MESSAGE = "SendGroupMessage";
export const TYPING = "Typing";
export const HEARTBEAT = "Heartbeat";
export const ACK = "ACK";
export const LOAD_CHAT = "LoadChat";
export const UPDATE_USER_MAP = "UpdateUserMap";

export const TIME_UNTIL_NEXT_TYPING_EVENT_SECONDS = 5;
export const TIME_UNTIL_CAN_SEND_ANOTHER_TYPING_SECONDS = 1;
export const TIME_FOR_HEARTBEAT_INTERVAL_SECONDS = 30;
export const BACK_OFF_TIMES_PER_RETRY = [1, 5, 10, 15, 15, 20, 20, 30, 30, 30, 60, 60, 60, 60, 120];
export const TIME_TO_MESSAGE_FAIL_SECONDS = 5;

export const NUMBER_OF_TIMES_BEFORE_NOT_TRYING_TO_RECONNECT = 15;
