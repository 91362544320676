/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Calculates how large a given video feed can be within the grid view
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Grid.Hooks.UseVisibleGridParticipants
 */

import { useMemo } from "react";
import { useBucketedParticipants } from "~/hooks";
import { IParticipantWithName } from "~/types";
import { IRemoteUser } from "~/web-core/interfaces";
import { SubscriptionStatus } from "~/web-core/types";

/**
 * Returns a list of participants to be rendered to the screen
 *
 * @param currentPage - The current page of video feeds
 * @param remoteFeedLimit - The number of video feeds that can be used to show remote participants
 * @param availableParticipants - The current participants that could be rendered
 * @returns - The list of remote participants that will be rendered
 */
export function useVisibleGridParticipants(
	currentPage: number,
	remoteFeedLimit: number,
	availableParticipants: IRemoteUser[],
): IParticipantWithName[] {
	const participants = useBucketedParticipants(availableParticipants);

	const onScreenParticipants = useMemo(() => {
		if (!participants || participants.length <= 0 || currentPage < 0) {
			return [];
		}
		// Determine if we are rendering all participants or just the number that will safely fit on screen
		const startingPoint = currentPage * remoteFeedLimit;
		const stopPoint = startingPoint + remoteFeedLimit;
		// Render to the end of the array or to the end of the current page
		const endIndex = Math.min(participants.length, stopPoint);

		/** For now we will set participants in chunks based on page. */
		const participantsToShow = participants.slice(startingPoint, endIndex);

		// Update subscription status to make sure only displayed feeds are subscribed
		for (const [index, participant] of participants.entries()) {
			// Since we only pass remoteParticipants to useBucketedParticipants, we can safely assume that the participant is an IRemoteUser
			const remoteParticipant = participant.participant as IRemoteUser;
			// If the participant is in the range of the current page, set the subscription status to subscribed
			if (index >= startingPoint && index < endIndex) {
				remoteParticipant.setStreamSubscribed("camera", SubscriptionStatus.subscribed);
			} else {
				remoteParticipant.setStreamSubscribed("camera", SubscriptionStatus.unsubscribed);
			}
		}

		return participantsToShow;
	}, [currentPage, participants, remoteFeedLimit]);

	return onScreenParticipants;
}
