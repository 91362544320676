/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file buildActionCreators function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\build-action-creators
 */

import { ActionCreator, InferActionCreatorsFromReducers, IReducerCollection } from "../types";

/**
 * Given a set of reducers, build the corresponding action creators and return them.
 * @param sliceId The ID of the state slice used by these reducers
 * @param reducers The reducer functions to be used
 * @returns A collection of action creators for the given reducers
 */
export function buildActionCreators<TState, TReducers extends IReducerCollection<TState>>(
	sliceId: number,
	reducers?: TReducers,
): InferActionCreatorsFromReducers<TReducers> {
	const retVal: { [key: string]: ActionCreator } = {};
	if (reducers) {
		Object.keys(reducers).forEach(reducerName => {
			retVal[reducerName] = payload => ({
				payload,
				sliceId,
				type: reducerName,
			});
		});
	}
	return retVal as any;
}
