/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to determine how the in-call header should be displayed
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Hooks.UseHeaderDisplayMode
 */

import { useWindowSize } from "~/hooks";
import { MinimumScreenHeightForHeader, ScreenWidthInflectionPoint } from "~/types";

type DisplayMode = "no-header" | "button-tray" | "controls-in-header";

/**
 * Determine how the in-call controls should be displayed based on the current screen size
 * @returns the way that the in-call controls should be displayed
 */
export function useHeaderDisplayMode(): DisplayMode {
	const { height, width } = useWindowSize();

	if (height < MinimumScreenHeightForHeader) {
		return "no-header";
	}

	if (width > ScreenWidthInflectionPoint) {
		return "controls-in-header";
	}

	return "button-tray";
}
