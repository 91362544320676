/**
 * @copyright Copyright 2021 - 2024 Epic Systems Corporation
 * @file toast message
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Toasts.Toast
 */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React, { ComponentType, FC } from "react";
import { useStrings } from "~/hooks";
import { IconProps } from "~/icons";
import Success from "~/icons/success";
import { ToastStyle, ToastWithId } from "~/types";
import { resolveClassName } from "~/utils/className";
import { stringFormat } from "~/utils/strings";
import LanguageLoadingToast from "./LanguageLoadingToast";
import ToastButtons from "./ToastButtons";
import styles from "./Toasts.module.scss";

/**
 * Props for Toast Component
 */
interface IProps {
	toast: ToastWithId;
}

export enum ToastTestIds {
	self = "Toast",
}

/**
 * The Toast component
 * @param props The props ;)
 */
const Toast: FC<IProps> = (props) => {
	const { messageToken, messageArgs, toastStyle, type } = props.toast;

	const strings: Record<string, string> = useStrings("Toasts", [messageToken]);

	const message = messageArgs?.length
		? stringFormat(strings[messageToken], ...messageArgs)
		: strings[messageToken];

	const Icon = getToastIcon(toastStyle);

	const className = resolveClassName(styles, {
		toast: true,
		success: toastStyle === "success",
		active: type === "active-speaker",
		solid: true,
	});

	return toastStyle === "language-loading" ? (
		<LanguageLoadingToast messageArgs={messageArgs} />
	) : (
		<div role="alert" className={className} tabIndex={0} data-testid={ToastTestIds.self}>
			{Icon && <Icon height={20} width={20} aria-hidden />}
			<label>{message}</label>
			<ToastButtons toast={props.toast} />
		</div>
	);
};

/**
 * Get the icon to use for a particular toast style
 * @param style the toast style to get the icon for
 * @returns the icon to use for a particular toast style
 */
function getToastIcon(style?: ToastStyle): ComponentType<IconProps> | null {
	switch (style) {
		case "success":
			return Success;
		default:
			return null;
	}
}

Toast.displayName = "Toast";

export default React.memo(Toast);
