﻿/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Background component
 * @author Matt Panico
 * @module Epic.VideoApp.Components.StandardLayout.Background
 */

import React, { FC } from "react";
import { useBrandingState } from "~/state";
import BrandedBackground from "../Branding/BrandedBackground";
import styles from "./Background.module.scss";

/**
 * The Background component
 */
const Background: FC = () => {
	const backgroundImg = useBrandingState((sel) => sel.getBackgroundImage(), []);
	const brandedColors = useBrandingState((sel) => sel.getColors(), []);

	return (
		<div id="background" className={styles["background"]}>
			{backgroundImg || brandedColors?.brandBackground ? (
				<BrandedBackground src={backgroundImg} />
			) : (
				<>
					<div className={styles["couch"]} />
					<div className={styles["telescope"]} />
				</>
			)}
		</div>
	);
};

Background.displayName = "Background";

export default Background;
