/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Component to handle received data messages from a single remote participant
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Components.RemoteUserSignalHandler
 */

import { FC, useContext, useEffect } from "react";
import { useRoomState } from "~/state";
import { IEVCDataMessageEvent } from "../events";
import { useRequestPeerInfoInterval } from "../hooks/useRequestPeerInfoInterval";
import { IRemoteUser } from "../interfaces";
import { ParticipantDataContext } from "./ParticipantDataProvider";

interface IProps {
	participant: IRemoteUser;
	messageHandler: (message: string, participantIdentity: string) => void;
}

/**
 * The RemoteUserSignalHandler component
 */
const RemoteUserSignalHandler: FC<IProps> = (props: IProps) => {
	const { participant, messageHandler } = props;

	const { namelessParticipantData } = useContext(ParticipantDataContext);
	const participantInfo =
		useRoomState((selectors) => selectors.getParticipantInfo(participant.getUserIdentity()), []) ?? null;

	// useEffect that sets up the basic signal handling for a remote user
	useEffect(() => {
		const handleMessage = (event: IEVCDataMessageEvent): void => {
			messageHandler(event.message, participant.getUserIdentity());
		};

		participant.on("dataMessageReceived", handleMessage);
		return () => {
			participant.off("dataMessageReceived", handleMessage);
		};
	}, [messageHandler, participant]);

	useRequestPeerInfoInterval(participant.getUserIdentity(), namelessParticipantData, participantInfo);

	return null;
};

RemoteUserSignalHandler.displayName = "RemoteUserSignalHandler";

export default RemoteUserSignalHandler;
