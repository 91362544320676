/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Component containing welcome greeting message for the hardware test
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.WelcomeHeading
 */

import React, { FC, PropsWithChildren, useMemo } from "react";
import { useStrings } from "~/hooks";
import { useRoomState, useSettingsState } from "~/state";
import { formatDateDisplayString } from "~/utils/dateTime";
import { stringFormat } from "~/utils/strings";
import styles from "./HardwareTest.module.scss";

interface IProps {
	isLobby?: boolean;
}

enum TokenNames {
	welcome = "Welcome",
	readyMessageWithTime = "ReadyMessageWithTime",
	readyMessage = "ReadyMessage",
	readyMessageStandaloneTest = "ReadyMessageStandaloneTest",
}

/**
 * A welcome message for the new hardware test with information about the date and time, if available
 *
 * @param props the props ;)
 */
const WelcomeHeading: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { isLobby } = props;

	const strings = useStrings("HardwareTest", Object.values(TokenNames));

	const conferenceDateIso = useRoomState((selectors) => selectors.getConferenceDateIso(), []);
	const locale = useSettingsState((selectors) => selectors.getLocale(), []);

	const displayTime = useMemo(() => {
		return formatDateDisplayString(conferenceDateIso, locale, "timeOnly");
	}, [conferenceDateIso, locale]);
	const WelcomeMessage = displayTime
		? stringFormat(strings[TokenNames.readyMessageWithTime], displayTime)
		: strings[TokenNames.readyMessage];

	return (
		<div className={styles["welcomeMessage"]}>
			<p className={styles["hardwareSubtitle"]}>{strings[TokenNames.welcome]}</p>
			<h1 className={styles["hardwareTitle"]}>
				{isLobby ? WelcomeMessage : strings[TokenNames.readyMessageStandaloneTest]}
			</h1>
		</div>
	);
};

WelcomeHeading.displayName = "WelcomeHeading";

export default WelcomeHeading;
