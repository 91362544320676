/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Function to handle incoming socket messages from the server
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.Hooks.UseChatSocketMessageHandler
 */

import {
	IChatUserStartupData,
	IIncomingLoadChatData,
	IIncomingSocketEventNewMessage,
	IMessage,
	IUser,
	convertMessagesToStateFormat,
	convertParticipantsToFormatToAddToState,
} from "@epic/chat";
import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useEffect, useRef } from "react";
import {
	ACK,
	HEARTBEAT,
	LOAD_CHAT,
	SEND_GROUP_MESSAGE,
	TIME_UNTIL_NEXT_TYPING_EVENT_SECONDS,
	TYPING,
	UPDATE_USER_MAP,
} from "~/types/chat";
import { secondsToMs } from "~/utils/dateTime";
import { userIdHash } from "~/utils/userIdHash";
import { chatUserActions, combinedActions } from "../../../state";
import { messageActions } from "../../../state/messages";
import {
	IInboundCloudEvent,
	IIncomingSocketEventTyping,
	IVideoClientAckData,
} from "../../../types/websockets";

// Function that handles incoming socket messages from the server
export function useChatSocketMessageHandler(
	userId: string,
	participantsTypingTimeoutsRef: React.MutableRefObject<{
		[participantId: string]: NodeJS.Timeout | number;
	}>,
): (event: WebSocketEventMap["message"]) => void {
	const dispatch = useDispatch();

	const messageQueueRef = useRef<IIncomingSocketEventNewMessage[]>([]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (messageQueueRef.current.length > 0) {
				const newChatMessageData = messageQueueRef.current.shift();
				if (!newChatMessageData) {
					return;
				}
				const messageArray = convertMessagesToStateFormat([newChatMessageData], userId);
				dispatch(messageActions.addOrUpdateMessages(messageArray));
				dispatch(
					chatUserActions.setUserProperty({
						property: "isTyping",
						userId: messageArray[0].senderId,
						value: false,
					}),
				);
				dispatch(combinedActions.newMessage());
			}
		}, secondsToMs(0.2));

		return () => clearInterval(interval);
	}, [dispatch, userId]);

	const messageCallback = useCallback(
		(event: WebSocketEventMap["message"]) => {
			if (typeof event.data !== "string") {
				console.warn("Could not parse cloud event for: ", event);
				return;
			}
			let cloudEvent: IInboundCloudEvent;
			try {
				cloudEvent = JSON.parse(event.data) as IInboundCloudEvent;
			} catch (error) {
				console.warn("Could not parse cloud event for: ", event);
				return;
			}

			const eventHandle = parseEventType(cloudEvent.type);

			if (!eventHandle) {
				console.warn("Could not parse cloud event type for: ", event.type, eventHandle);
				return;
			}
			switch (eventHandle) {
				// Handler to process incoming group messages
				case versionedHandler(SEND_GROUP_MESSAGE, "1.0"): {
					const newChatMessageData: IIncomingSocketEventNewMessage | undefined =
						cloudEvent.data as IIncomingSocketEventNewMessage;
					if (newChatMessageData) {
						messageQueueRef.current.push(newChatMessageData);
					}
					break;
				}

				// Handler to process incoming ACK messages
				case versionedHandler(ACK, "1.0"): {
					const ackData: IVideoClientAckData = cloudEvent.data as IVideoClientAckData;
					if (ackData.AckId) {
						const newDeliveryStatus = ackData.StatusCode === "1" ? "sent" : "failed";
						dispatch(
							messageActions.updateMessage({
								messageId: ackData.AckId,
								sequentialId: ackData.SequentialId,
								messageDeliveryStatus: newDeliveryStatus,
							}),
						);
					}

					break;
				}

				// Handler to process incoming heartbeat messages
				case versionedHandler(HEARTBEAT, "1.0"): {
					const heartbeatData: IIncomingLoadChatData = cloudEvent.data as IIncomingLoadChatData;

					const [messages, users] = convertStartupDataToStateFormat(heartbeatData, userId);
					const clientUsers = convertStartupUserToUser(users);
					dispatch(messageActions.addOrUpdateMessages(messages));
					dispatch(chatUserActions.addOrUpdateUsers(clientUsers));
					dispatch(combinedActions.newMessage());

					break;
				}

				// Handler to process incoming user map updates
				case versionedHandler(UPDATE_USER_MAP, "1.0"): {
					const userMapData: IIncomingLoadChatData = cloudEvent.data as IIncomingLoadChatData;
					const users = convertParticipantsToFormatToAddToState(userMapData.Users);
					const clientUsers = convertStartupUserToUser(users);

					dispatch(chatUserActions.addOrUpdateUsers(clientUsers));
					break;
				}

				// Handler to process incoming typing messages
				case versionedHandler(TYPING, "1.0"): {
					const typingData: IIncomingSocketEventTyping =
						cloudEvent.data as IIncomingSocketEventTyping;
					const participantId = typingData.UserId;
					const timeout = participantsTypingTimeoutsRef.current[participantId];

					dispatch(
						chatUserActions.setUserProperty({
							property: "isTyping",
							userId: typingData.UserId,
							value: true,
						}),
					);
					if (timeout !== undefined) {
						// We want to refresh the timeout if it exists and we are calling this again
						clearTimeout(timeout);
						delete participantsTypingTimeoutsRef.current[participantId];
					}
					participantsTypingTimeoutsRef.current[participantId] = setTimeout(() => {
						dispatch(
							chatUserActions.setUserProperty({
								property: "isTyping",
								userId: typingData.UserId,
								value: false,
							}),
						);
						delete participantsTypingTimeoutsRef.current[participantId];
					}, secondsToMs(TIME_UNTIL_NEXT_TYPING_EVENT_SECONDS + 1));
					break;
				}

				// Handler to process incoming load chat messages
				case versionedHandler(LOAD_CHAT, "1.0"): {
					const newChatMessageData: IIncomingLoadChatData =
						cloudEvent.data as IIncomingLoadChatData;
					const messageArray = convertMessagesToStateFormat(newChatMessageData.Messages, userId);
					dispatch(messageActions.addOrUpdateMessages(messageArray));
					dispatch(combinedActions.newMessage());

					Object.values(newChatMessageData.Users).forEach((userData) => {
						dispatch(
							chatUserActions.addOrUpdateUser({
								id: userData.Id,
								displayName: userData.Name,
								isInChat: userData.IsActive,
							}),
						);
					});

					break;
				}

				default: {
					break;
				}
			}
		},
		[dispatch, participantsTypingTimeoutsRef, userId],
	);

	return messageCallback;
}

// Function to parse the event type from the incoming socket message
function parseEventType(input: string): string | undefined {
	const fieldCount = 6;
	const parsed = input.split(".");

	if (parsed.length !== fieldCount) {
		return;
	}

	try {
		const app = parsed[2];
		if (app.toLocaleLowerCase() !== "video") {
			console.warn("Found non-video socket event");
			return;
		}
		const eventType = parsed[3];
		const majorVersion = parsed[4];
		const minorVersion = parsed[5];

		return buildSocketIdentifierString(eventType, majorVersion, minorVersion);
	} catch (error) {
		console.warn(error, "Event Type could not be parsed from: ", input);
		return;
	}
}

// Function to build the socket identifier string
function buildSocketIdentifierString(eventType: string, majorVersion: string, minorVersion: string): string {
	return `${eventType}.${majorVersion}.${minorVersion}`;
}

// Function to build the versioned handler string
function versionedHandler(base: string, version: string): string {
	return `${base}.${version}`;
}

// Function to convert the incoming startup data to the state format
function convertStartupDataToStateFormat(
	data: IIncomingLoadChatData,
	currentUserId: string,
): [IMessage[], IChatUserStartupData[]] {
	const usersArray = convertParticipantsToFormatToAddToState(data.Users);
	const messageArray = convertMessagesToStateFormat(data.Messages, currentUserId);
	return [messageArray, usersArray];
}

// Function to convert the incoming startup user data to the state format
function convertStartupUserToUser(data: IChatUserStartupData[]): IUser[] {
	return data.map((current) => {
		return {
			displayName: current.userName ?? "Unknown",
			id: current.userId,
			isInChat: true,
			isTyping: false,
			color: userIdHash(current.userId),
		};
	});
}
