/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Test Network component
 * @author Noah Allen
 * @module Epic.VideoApp.Features.TestNetwork.TestNetwork
 */

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */

import { DailyProvider } from "@daily-co/daily-react";
import { RouteComponentProps } from "@reach/router";
import React, { FC } from "react";
import CopyrightLinkButton from "~/components/Copyright/CopyrightLinkButton";
import HeaderBar from "~/components/Header/HeaderBar";
import FeatureWrapper from "~/components/StandardLayout/FeatureWrapper";
import { BackgroundStyleContextProvider } from "~/components/Utilities/BackgroundStyleContext";
import { useDocumentTitle, useSharedStrings } from "~/hooks";
import { StyleContext } from "~/types";
import { SharedStringTokens } from "~/utils/strings";
import styles from "./TestNetwork.module.scss";
import TestPage from "./TestPage";

/**
 * The TestNetwork component
 * Tests the network connections and configuration for the video call endpoints
 *
 * @param _props The props ;)
 */

const TestNetwork: FC<RouteComponentProps> = (_props) => {
	const strings = useSharedStrings([SharedStringTokens.defaultPageTitle]);
	useDocumentTitle(strings[SharedStringTokens.defaultPageTitle]);

	return (
		<DailyProvider>
			<FeatureWrapper>
				<header>
					<HeaderBar alwaysVisible />
				</header>
				<main>
					<BackgroundStyleContextProvider style={StyleContext.light}>
						<div className={styles["testNetworkDiv"]}>
							<TestPage />
						</div>
					</BackgroundStyleContextProvider>
					<CopyrightLinkButton />
				</main>
			</FeatureWrapper>
		</DailyProvider>
	);
};

TestNetwork.displayName = "TestNetwork";

export default TestNetwork;
