/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Custom hooks for chat view callbacks
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.Hooks.UseChatMessageHandling
 */

import { IMessage } from "@epic/chat";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { messageActions } from "~/state";
import { Timeout } from "~/types";
import { TIME_TO_MESSAGE_FAIL_SECONDS } from "~/types/chat";
import { secondsToMs } from "~/utils/dateTime";

interface IUseChatMessageHandlingProps {
	sortedMessages: IMessage[];
	setLastSequentialId: React.Dispatch<React.SetStateAction<number>>;
	pendingMessages: string[];
	setPendingMessages: React.Dispatch<React.SetStateAction<string[]>>;
	sendHeartbeatMessage: (sequentialId: number) => void;
	setShouldReadNewMessageAriaAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * React hook that manages the message state
 * @returns A callback to get the most recent user message and a callback to check
 * if the last message is from the local user
 */
export const useChatMessageHandling = (
	props: IUseChatMessageHandlingProps,
): [mostRecentUserMessageIdCallback: () => string, isLastMessageFromSelfCallback: () => boolean] => {
	const {
		sortedMessages,
		setLastSequentialId,
		pendingMessages,
		setPendingMessages,
		sendHeartbeatMessage,
		setShouldReadNewMessageAriaAlert,
	} = props;
	const dispatch = useDispatch();
	const sendingMessageTimeout = useRef<{ [messageId: string]: Timeout }>({});

	// If we detect a gap in sequentialIds in the chat, send a heartbeat message to the server
	// to get the missed message
	useEffect(() => {
		let previousId: number | null = null;
		for (const message of sortedMessages) {
			if (message.sequentialId === null) {
				return;
			}

			if (previousId !== null && message.sequentialId - previousId > 1) {
				sendHeartbeatMessage(message.sequentialId - 1 ?? 0);
				break;
			}

			previousId = message.sequentialId;
		}
	}, [sortedMessages, sendHeartbeatMessage]);

	// Sets the last sequentialID to the most recent message
	useEffect(() => {
		if (sortedMessages.length > 0) {
			setLastSequentialId(sortedMessages[sortedMessages.length - 1].sequentialId ?? 0);
		}
	}, [setLastSequentialId, sortedMessages]);

	// Callback that returns the most recent message from the local user
	const mostRecentUserMessageIdCallback = useCallback(() => {
		for (let index = sortedMessages.length - 1; index >= 0; index--) {
			const message = sortedMessages[index];
			if (message === undefined) {
				continue;
			}
			if (message.messageType === "self") {
				return message.messageId;
			}
		}
		return "";
	}, [sortedMessages]);

	useEffect(() => {
		let timeoutId: number | NodeJS.Timeout;
		if (sortedMessages.length > 0) {
			const lastMessage = sortedMessages[sortedMessages.length - 1];
			if (lastMessage.messageType !== "self") {
				// The reason we set to false then true in a timeout is to ensure that the screen reader doesn't get interrupted
				// Without this way of doing it, occasionally the screen reader just won't read out the alert
				setShouldReadNewMessageAriaAlert(false);

				timeoutId = setTimeout(() => {
					setShouldReadNewMessageAriaAlert(true);
				}, secondsToMs(1));
			} else {
				setShouldReadNewMessageAriaAlert(false);
			}
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [setShouldReadNewMessageAriaAlert, sortedMessages]);

	// Callback that returns whether the most recent message is from the local user
	const isLastMessageFromSelfCallback = useCallback(() => {
		return sortedMessages[sortedMessages.length - 1]?.messageType === "self";
	}, [sortedMessages]);

	// Updates the messageListRef when the sortedMessages change
	const messageListRef = useRef<IMessage[] | undefined>();
	useEffect(() => {
		messageListRef.current = sortedMessages;
	}, [sortedMessages]);

	// Checks if we have gotten an ack back for a message within 5 seconds.
	// If it doesn't, count the message as failed
	useEffect(() => {
		// Capture the current state of sendingMessageTimeout.current
		const currentTimeouts = { ...sendingMessageTimeout.current };

		if (pendingMessages.length > 0) {
			const messageId = pendingMessages[0];

			sendingMessageTimeout.current[messageId] = setTimeout(() => {
				if (!messageListRef.current) {
					return;
				}
				const messageCurrentlyBeingSent = messageListRef.current.find(
					(message) => message.messageId === messageId,
				);

				if (
					messageCurrentlyBeingSent &&
					messageCurrentlyBeingSent.messageDeliveryStatus === "sending"
				) {
					console.warn("We didn't get an ACK back for this message on time, failing the message!");
					dispatch(
						messageActions.updateMessage({
							messageId: messageId,
							messageDeliveryStatus: "failed",
						}),
					);
				}
				setPendingMessages((queue) => queue.filter((id) => id !== messageId));
			}, secondsToMs(TIME_TO_MESSAGE_FAIL_SECONDS));
		}

		// Cleanup function
		return () => {
			Object.values(currentTimeouts).forEach(clearTimeout);
		};
	}, [dispatch, pendingMessages, setPendingMessages]);

	return [mostRecentUserMessageIdCallback, isLastMessageFromSelfCallback];
};
