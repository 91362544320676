/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Component to show a "No Devices" warning message
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.NoDevicesMessage
 */

import React, { FC, useContext } from "react";
import { MenuContext } from "~/components/Utilities/MenuContext";
import styles from "./DeviceSelector.module.scss";

interface IProps {
	message: string;
}

export enum NoDevicesMessageTestIds {
	self = "NoDevicesMessage",
}

/**
 * The NoDevicesMessage component
 * @param props The props ;)
 */
const NoDevicesMessage: FC<IProps> = (props) => {
	const { message } = props;
	const { isInMenu: isFromMenu } = useContext(MenuContext);

	const noDeviceMessageSpan = (
		<span className={styles["deviceSelectorSpan"]} data-testid={NoDevicesMessageTestIds.self}>
			{message}
		</span>
	);

	return isFromMenu ? (
		<div className={styles["deviceSelectorOptionContainer"]} role="menuitemradio" aria-checked="true">
			{noDeviceMessageSpan}
		</div>
	) : (
		// Fragment is needed so that Typescript can correctly infer the return type
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>{noDeviceMessageSpan}</>
	);
};

NoDevicesMessage.displayName = "NoDevicesMessage";

export default NoDevicesMessage;
