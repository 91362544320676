/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Calculate the video dimensions that will fully utilize the grid space
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Grid.Hooks.UseScaleVideoGrid
 */

import { useMemo } from "react";
import { IDimensions, VideoAspectRatio, VideoMargin } from "~/types";

/**
 * Calculate the size of the video feed to fit all participants in the grid
 *
 * @param containerHeight - The height of the container
 * @param containerWidth - The width of the container
 * @param numberOfFeeds - The number of participants
 * @returns - The size of the video feed
 */
function getGalleryViewLayout(
	containerHeight: number,
	containerWidth: number,
	numberOfFeeds: number,
): IDimensions {
	let participantVideoWidth = 0;
	let participantVideoHeight = 0;

	let minVideoWidth = 0;
	let maxVideoWidth = containerWidth + 1;

	while (maxVideoWidth - minVideoWidth > 1) {
		const mid = Math.floor((maxVideoWidth + minVideoWidth) / 2);
		const isLower = layoutIsTooSmall(mid, numberOfFeeds, containerWidth, containerHeight);

		if (isLower) {
			minVideoWidth = mid + 1;
		} else {
			maxVideoWidth = mid;
		}
	}

	const newParticipantVideoWidth = Math.ceil(minVideoWidth);
	const newParticipantVideoHeight = Math.ceil(newParticipantVideoWidth * VideoAspectRatio);

	participantVideoWidth = newParticipantVideoWidth - VideoMargin * 2;
	participantVideoHeight = newParticipantVideoHeight - VideoMargin * 2;
	return { width: participantVideoWidth, height: participantVideoHeight };
}

/**
 * Check if the layout is too small to fit all participants
 *
 * @param newVideoSize - The size of the video feed
 * @param participantCount - The number of participants
 * @param containerWidth - The width of the container
 * @param containerHeight - The height of the container
 * @returns - Whether or not the layout is too small
 */
const layoutIsTooSmall = (
	newVideoSize: number,
	participantCount: number,
	containerWidth: number,
	containerHeight: number,
): boolean => {
	const videoWidth = newVideoSize + VideoMargin * 2;
	const videoHeight = newVideoSize * VideoAspectRatio + VideoMargin * 2;

	const columns = Math.floor(containerWidth / videoWidth);
	const rows = Math.ceil(participantCount / columns);

	return rows * videoHeight <= containerHeight;
};

/**
 * Hooks to manage the size of all visible video feeds within the grid
 *
 * @param gridHeight - Available height
 * @param gridWidth - Available width
 * @param numberOfFeeds - The number of videos to fit into the space
 * @returns - The size to render all within the video grid to maximize space
 */
export function useScaleVideoGrid(gridHeight: number, gridWidth: number, numberOfFeeds: number): IDimensions {
	const size = useMemo(() => {
		const value = getGalleryViewLayout(gridHeight, gridWidth, numberOfFeeds);
		return value;
	}, [gridHeight, gridWidth, numberOfFeeds]);

	return size;
}
