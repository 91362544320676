/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Hook to determine whether device is affected by an Android 12 specific bug
 *
 * 		**NOTE THAT THIS HOOK IS NOT UNIT TESTABLE** -- the complexity in testing
 *      this unit test comes from the fact that we set state with a useEffect,
 * 		and return the state value.
 *
 * 		So, the hook will always return false at first while promises resolve in the
 * 		useEffect, and then may return later. This creates a nightmare scenario
 * 		with the testing harness, which returns the first value from the hook
 * 		and afaik doesn't have a way to wait for effects.
 *
 * 		We *could* split out getIsAndroid12 as an exported, public named function and
 * 		test that, but it seems like diminishing returns.
 *
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.Hooks.UseIsAndroid12
 */

import { useEffect, useState } from "react";
import { browserVersionUAHints } from "~/utils/browser";
import { isAndroid12 } from "~/utils/os";

// Chrome 97 fixed this android 12 related bug: https://bugs.chromium.org/p/chromium/issues/detail?id=1237677
const MinimumWorkingChromiumVersion = 97;

/**
 * Hook to get if device is Android 12
 */

export function useIsAndroid12(): boolean {
	const [android12BrokenBrowser, setIsAndroid12BrokenBrowser] = useState(false);

	useEffect(() => {
		const getIsAndroid12 = async (): Promise<void> => {
			const android12 = await isAndroid12();
			const isChromium97Plus = browserVersionUAHints(
				"Chromium",
				MinimumWorkingChromiumVersion,
				(uaVersion: number, checkVersion: number) => uaVersion >= checkVersion,
			);
			// only show the chrome warning banner if on android 12, browser non chromium 97+
			setIsAndroid12BrokenBrowser(android12 && !isChromium97Plus);
		};
		void getIsAndroid12();
	}, []);

	return android12BrokenBrowser;
}
