/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Hook to retrieve a specific type of user from state
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Hooks.UseParticipantsOfType
 */

import { useRoomState } from "~/state";
import { EpicUserType } from "~/types/user";
import { IUser } from "~/web-core/interfaces";

/**
 * Get all of the participants for the current room that match a specific user type
 * @returns an object containing all room participants and their display names
 */
export function useParticipantsOfType(participants: IUser[], userTypes: EpicUserType[]): IUser[] {
	// check shared state for any shared user type info from data track messaging
	const identitiesOfType = useRoomState((selectors) => selectors.getAllParticipantsByType(userTypes), []);

	if (!identitiesOfType || identitiesOfType.length <= 0) {
		return [];
	}
	return buildParticipantList(identitiesOfType, participants);
}

function buildParticipantList(identities: string[], participants: IUser[]): IUser[] {
	return participants.filter((current) => identities.some((id) => id === current.getUserIdentity()));
}
