/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to ensure that input fields are not blocked by the keyboard
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Hooks.UseKeepInputInView
 */

import { useCallback, useEffect } from "react";
import { detectOS, OS } from "~/utils/os";

/**
 * Hook used to ensure that input fields do not get blocked by the keyboard when the screen resizes
 * Resizes occur both on rotation and when the keyboard shrinks the view
 */
export function useKeepInputInView(): void {
	const scrollIntoView = useCallback(() => {
		// if the currently active element is an input field, ensure that it is in view
		const { activeElement } = document;
		if (activeElement?.tagName === "INPUT") {
			// set a timeout so the action is queued to execute after the keyboard finishes showing
			setTimeout(() => {
				activeElement.scrollIntoView();
			}, 25);
		}
	}, []);

	useEffect(() => {
		// according to this stack overflow post, this is a bug in the Android browser, so only apply to that for now
		// https://stackoverflow.com/questions/23757345/android-does-not-correctly-scroll-on-input-focus-if-not-body-element/25032766#25032766
		if (detectOS() !== OS.android) {
			return;
		}

		window.addEventListener("resize", scrollIntoView);

		return () => window.removeEventListener("resize", scrollIntoView);
	}, [scrollIntoView]);
}
