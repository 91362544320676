/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file button to make the call take a user's full screen
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.FullscreenButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useStrings } from "~/hooks";
import ExitFullscreen from "~/icons/exitFullscreen";
import Fullscreen from "~/icons/fullscreen";
import { uiActions, useUIState } from "~/state";
import { FullScreenContainerId } from "~/types";
import { isMobile } from "~/utils/os";
import { isEmbeddedMobileView } from "~/utils/windowGlobals";
import ControlButton from "./ControlButton";

/** String tokens used by the FullscreenButton component */
enum TokenNames {
	startLabel = "StartLabel",
	stopLabel = "StopLabel",
	ariaLabel = "AriaLabel",
}

/**
 * The FullscreenButton component
 */
const FullscreenButton: FC = () => {
	const dispatch = useDispatch();
	const isFullscreenMode = useUIState((selectors) => selectors.getIsFullScreenMode(), []);
	const mobile = useMemo(() => isMobile(), []);
	const embeddedMobileView = useMemo(() => isEmbeddedMobileView(), []);
	const strings = useStrings("FullscreenButton", Object.values(TokenNames));

	const chatScrollTop = useRef<number | null>(null);

	useEffect(() => {
		// update whether or not the app is full screen anytime a fullscreenchange event is received
		const onFullscreenChange = (): void => {
			dispatch(uiActions.setIsFullScreenMode(!!document.fullscreenElement));

			const chatComponent = document.getElementById("ChatScrollingSection");
			if (document.fullscreenElement && chatComponent && chatScrollTop.current !== null) {
				// After entering fullscreen, restore the scrollTop value of the chat component
				chatComponent.scrollTop = chatScrollTop.current;
			} else if (!document.fullscreenElement && chatComponent && chatScrollTop.current !== null) {
				// After exiting fullscreen, restore the scrollTop value of the chat component
				chatComponent.scrollTop = chatScrollTop.current;
			}
		};
		document.addEventListener("fullscreenchange", onFullscreenChange);

		return () => document.removeEventListener("fullscreenchange", onFullscreenChange);
	}, [dispatch]);

	useEffect(() => {
		try {
			const videoRoom = document.getElementById(FullScreenContainerId);
			const chatComponent = document.getElementById("ChatScrollingSection");

			if (isFullscreenMode) {
				if (!document.fullscreenElement && videoRoom) {
					// Save the current scrollTop value of the chat component before entering fullscreen
					if (chatComponent) {
						chatScrollTop.current = chatComponent.scrollTop;
					}
					void videoRoom.requestFullscreen();
				}
			} else if (document.fullscreenElement) {
				void document.exitFullscreen();
			}
		} catch {
			return;
		}
	}, [isFullscreenMode]);

	const onFullscreen = useCallback(() => {
		if (!document.fullscreenElement) {
			dispatch(uiActions.setIsFullScreenMode(true));
		} else {
			dispatch(uiActions.setIsFullScreenMode(false));
		}
	}, [dispatch]);

	if (!document.fullscreenEnabled || mobile || embeddedMobileView) {
		return null;
	}

	return (
		<ControlButton
			icon={isFullscreenMode ? ExitFullscreen : Fullscreen}
			ariaLabel={strings[TokenNames.ariaLabel]}
			onClick={onFullscreen}
			keyboardShortcut={["alt", "u"]}
			buttonText={isFullscreenMode ? strings[TokenNames.stopLabel] : strings[TokenNames.startLabel]}
		/>
	);
};

FullscreenButton.displayName = "FullscreenButton";

export default FullscreenButton;
