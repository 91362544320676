/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Window size hook
 * @author Matt Panico
 * @module Epic.VideoApp.Hooks.UseWindowSize
 */
import { useEffect, useState } from "react";
import { IDimensions } from "~/types";

export function useWindowSize(): IDimensions {
	// store the size
	const [windowSize, setWindowSize] = useState<IDimensions>({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	// use an effect
	useEffect(() => {
		// callback to set the size
		function onResize(): void {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		// add the listener to the window
		window.addEventListener("resize", onResize);
		window.addEventListener("orientationchange", onResize);

		// call it once in case the window resized between the initial value and render
		onResize();

		// return a function to unregister the listener
		return () => {
			window.removeEventListener("resize", onResize);
			window.removeEventListener("orientationchange", onResize);
		};
	}, []); // only run this once, it'll never change

	return windowSize;
}
