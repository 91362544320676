/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to load background processors
 * @author Liam Liden
 * @module Epic.VideoApp.Hooks.BackgroundEffects.UseLoadBackgroundProcessors
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext } from "react";
import { useBrandingState, useUserState } from "~/state";
import { backgroundProcessorsActions, useBackgroundProcessorsState } from "~/state/backgroundProcessors";
import { useCombinedSelectors } from "~/state/combined";
import {
	BackgroundProcessorsMap,
	BackgroundResourceType,
	BackgroundSettingKvp,
	BackgroundSettings,
	DefaultImageNames,
	ICustomBackgroundProcessor,
} from "~/types/backgrounds";
import { IRemoteResource } from "~/types/branding";
import { IBackgroundsAvailable, getSeasonalImagePath } from "~/utils/backgroundEffects";
import { makeResourceLink } from "~/utils/general";
import { VideoSessionContext } from "~/web-core/components";

export const defaultImageNames: string[] = [
	DefaultImageNames.office,
	DefaultImageNames.planks,
	DefaultImageNames.space,
	DefaultImageNames.dark,
	getSeasonalImagePath(),
];

/**
 * Hook to load processors into shared state
 * @returns function to load processors
 */
export function useLoadBackgroundProcessors(): () => void {
	const dispatch = useDispatch();
	const { session } = useContext(VideoSessionContext);
	const localUser = session?.localUser;

	// Pull state map to make sure we don't reinitialize already loaded processors in a failure case
	const stateProcessorMap = useBackgroundProcessorsState(
		(selectors) => selectors.getBackgroundProcessors(),
		[],
	);

	const { blurAccess, epicBackgroundsAccess } = useUserState(
		(selectors) => selectors.getBackgroundAccess(),
		[],
	);
	const isBackgroundsSupported = useCombinedSelectors(
		(selectors) => selectors.getIsBackgroundsSupported(),
		[],
	);
	const brandedBackgrounds = useBrandingState((selectors) => selectors.getVirtualBackgroundImages(), []);

	const loadProcessors = useCallback(() => {
		if (!isBackgroundsSupported || localUser === undefined) {
			return;
		}
		// Map of all processors that attempt to load- all successful processors will be set into state
		const newProcessorsMap = new Map<string, ICustomBackgroundProcessor>();

		localUser.once("backgroundProcessorsDone", (args): void => {
			const filteredBackgroundProcessors: BackgroundSettingKvp[] = [];
			// Filter each successfully loaded processor into a new array.
			// Processors in the array will be added to the processor metadata map in state.
			args.successfulProcessors.forEach((path) => {
				const settings = newProcessorsMap.get(path);
				if (settings) {
					filteredBackgroundProcessors.push([path, settings]);
				}
			});
			dispatch(
				backgroundProcessorsActions.handleBackgroundLoadComplete({
					status: args.status,
					processorSettings: filteredBackgroundProcessors,
				}),
			);
		});

		dispatch(backgroundProcessorsActions.setProcessorLoadStatus("acquiring"));
		let totalBackgrounds = 0;

		const backgroundsAvailable = {
			blur: blurAccess,
			epic: epicBackgroundsAccess,
			branded: brandedBackgrounds.length > 0,
		};

		// Set up metadata for processors that need to load in newProcessorsMap
		setupProcessorsToLoad(backgroundsAvailable, brandedBackgrounds, stateProcessorMap, newProcessorsMap);

		// Can show blur, branded BG, default BGs, or some combination
		totalBackgrounds = getTotalBackgrounds(
			backgroundsAvailable.blur,
			backgroundsAvailable.epic,
			brandedBackgrounds.length,
		);

		// Pull processor settings from map and pass to localUser to load processors
		const processorSettingsArray = Array.from(newProcessorsMap.values()).map(
			(processorMetaData) => processorMetaData.processor,
		);
		localUser.initializeVirtualBackgrounds(processorSettingsArray, totalBackgrounds);
	}, [
		isBackgroundsSupported,
		localUser,
		blurAccess,
		epicBackgroundsAccess,
		brandedBackgrounds,
		stateProcessorMap,
		dispatch,
	]);

	return loadProcessors;
}

/**
 * Helper interface to add consistent typing for the loadOneImageBackground function
 */
interface ILoadOneImageBackground {
	(
		path: string,
		type: BackgroundSettings["type"],
		map: BackgroundProcessorsMap,
		resourceType?: BackgroundResourceType,
		name?: string,
		bannerTextToken?: string,
	): void;
}

// Function to add processor settings into a map
/**
 * Function to set up metadata for a single background processor
 * @param path Identifying key for the processor
 * @param type "image" | "blur"
 * @param processorMap Maps processor path to processor metadata
 * @param resourceType Identifies if image background is remote or Epic-released
 * @param name Optional name for the processor
 */
const loadProcessor: ILoadOneImageBackground = (path, type, processorMap, resourceType, name) => {
	let src: string;
	if (type === "image") {
		if (!resourceType) {
			throw new Error("Resource type is required for image processors");
		}
		src = makeResourceLink(resourceType, path);
	} else {
		src = path;
	}
	processorMap.set(path, {
		processor: { type: type, src: src, path: path },
		name: name,
		resourceType: resourceType,
	});
};

/**
 * Sets up metadata to be loaded for one image background
 * @param resourceType The type of image the virtual background is, e.g. remote or Epic-released
 * @param name The image name/path
 * @param stateProcessorMap Processor map object storing already loaded background processor metadata
 * @param newProcessorsMap Processor map object storing processor metadata to be loaded
 * @param updateLoadStatus function to record that an attempt was made to load an image
 * @param loadProcessor function to load a background processor
 * @param identifier unique ID if different from the name
 * @returns void
 */
function loadOneImageBackground(
	resourceType: BackgroundResourceType,
	name: string,
	stateProcessorMap: BackgroundProcessorsMap,
	newProcessorsMap: BackgroundProcessorsMap,
	identifier?: string,
): void {
	// Avoid reloading processors
	if (stateProcessorMap.get(name)) {
		return;
	}
	// Use the identifier as the path if it exists, and only include the name if different from the identifier
	loadProcessor(identifier ?? name, "image", newProcessorsMap, resourceType, identifier ? name : undefined);
}

/**
 * Function to set up metadata for background processors based on configured access
 * @param backgroundsAvailable Object representing which types of backgrounds are available
 * @param brandedBackgrounds Array of branded background resources
 * @param stateProcessorMap Processor map object storing already loaded background processor metadata
 * @param newProcessorsMap Processor map object storing processor metadata to be loaded
 */
function setupProcessorsToLoad(
	backgroundsAvailable: IBackgroundsAvailable,
	brandedBackgrounds: IRemoteResource[],
	stateProcessorMap: BackgroundProcessorsMap,
	newProcessorsMap: BackgroundProcessorsMap,
): void {
	if (backgroundsAvailable.blur) {
		// Avoid reloading processors
		if (!stateProcessorMap.get(DefaultImageNames.blur)) {
			loadProcessor(DefaultImageNames.blur, "blur", newProcessorsMap);
		}
	}

	if (backgroundsAvailable.branded) {
		brandedBackgrounds.forEach((resource: IRemoteResource) => {
			loadOneImageBackground(
				"RemoteImage",
				resource.name,
				stateProcessorMap,
				newProcessorsMap,
				resource.id,
			);
		});
	}

	if (backgroundsAvailable.epic) {
		// Load processors with images from defaultImageNames
		defaultImageNames.forEach((name: string) => {
			loadOneImageBackground("BackgroundImage", name, stateProcessorMap, newProcessorsMap);
		});
	}
}

/**
 * Function to return the total number of backgrounds for a respective BackgroundAccess
 * @param blurringAccess Boolean representing if user can access the blur background.
 * @param epicReleaseAccess Boolean representing if user can access Epic-released backgrounds.
 * @param brandedBackgroundCount Number of branded backgrounds available.
 * @returns The number of backgrounds we expect to load for a BackgroundAccess
 */
function getTotalBackgrounds(
	blurringAccess: boolean,
	epicReleaseAccess: boolean,
	brandedBackgroundCount: number,
): number {
	let totalBackgrounds = 0;
	if (blurringAccess) {
		totalBackgrounds += 1;
	}
	if (epicReleaseAccess) {
		totalBackgrounds += defaultImageNames.length;
	}
	if (brandedBackgroundCount) {
		totalBackgrounds += brandedBackgroundCount;
	}

	return totalBackgrounds;
}
