/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Moderator menu button for performing pinning
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Moderation.ModerationButtons.PinButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import ToggleSwitchButton from "~/components/Utilities/ToggleSwitchButton";
import { useStrings } from "~/hooks";
import { uiActions, useUIState } from "~/state";
import { VideoType } from "~/web-core/types";

interface IPinProps {
	identity: string;
	className: string;
	labelClassName?: string;
	videoType?: VideoType;
	toggleSwitchClassName?: string;
}

export enum PinButtonTestIds {
	self = "PinButton",
}

/** String tokens used by the ParticipantControlMenu -> PinButton component */
enum TokenNames {
	pinParticipant = "PinParticipant",
}

const PinButton: FC<IPinProps> = (props: IPinProps) => {
	const { identity, videoType = "camera", className, labelClassName, toggleSwitchClassName } = props;

	const dispatch = useDispatch();
	const pinnedVideo = useUIState((selectors) => selectors.getPinnedVideo(), []);
	const strings = useStrings("ParticipantControlMenu", Object.values(TokenNames));

	const isPinned = pinnedVideo?.identity === identity && pinnedVideo?.videoType === videoType;

	const pinText = strings[TokenNames.pinParticipant];

	const onPinClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			dispatch(
				uiActions.toggleVideoPinned({
					updateSource: "Default",
					pinnedVideo: { identity: identity, videoType: videoType },
				}),
			);
			event?.preventDefault();
		},
		[dispatch, identity, videoType],
	);

	return (
		<ToggleSwitchButton
			text={pinText}
			className={className}
			onClick={onPinClick}
			on={isPinned}
			labelClassName={labelClassName}
			toggleSwitchClassName={toggleSwitchClassName}
			data-testid={PinButtonTestIds.self}
			id="pinButton"
		/>
	);
};

PinButton.displayName = "PinButton";

export default PinButton;
