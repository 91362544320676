/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Happy Smiley, used for feedback surveys
 * @author Max Harkins
 * @module Epic.VideoApp.Icons.HappySmiley
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The Happy Smiley component
 * @param props The props ;)
 */
const HappySmiley: FC<IconProps> = memo((props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
			<g>
				<circle fill="#4ad18b" cx="32" cy="32" r="27.98" />
				<circle fill="#0f4949" cx="19.54" cy="28.18" r="4.46" />
				<circle fill="#0f4949" cx="44.46" cy="28.18" r="4.46" />
			</g>
			<path
				fill="#0f4949"
				d="M32.04,43.81c-3.79,0-7.31-2.29-8.94-5.89-.3-.66,0-1.44,.66-1.74,.66-.3,1.44,0,1.74,.66,1.28,2.84,4.14,4.57,7.12,4.32,2.54-.22,4.79-1.87,5.87-4.31,.29-.67,1.07-.97,1.74-.67s.97,1.07,.67,1.74c-1.47,3.31-4.55,5.56-8.05,5.86-.27,.02-.54,.03-.81,.03Z"
			/>
		</svg>
	);
});

HappySmiley.displayName = "HappySmiley";

export default HappySmiley;
