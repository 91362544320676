/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file The Browser Compatibility Error page
 * @author Matt Panico
 * @module Epic.VideoApp.Features.BrowserCompatibilityError.BrowserCompatibilityError
 */

import { RouteComponentProps } from "@reach/router";
import React, { FC } from "react";
import BrowserList from "~/components/BrowserList/BrowserList";
import edgeLogo from "~/components/BrowserList/edge.svg";
import safariLogo from "~/components/BrowserList/safari.svg";
import SimpleCard from "~/components/Cards/SimpleCard";
import SimpleHeader from "~/components/Header/SimpleHeader";
import ListWithDescription from "~/components/Utilities/ListWithDescription";
import { useCaseInsensitiveSearchParam, useDocumentTitle, useStrings } from "~/hooks";
import { isLegacyEdge } from "~/utils/browser";
import { detectOS, OS } from "~/utils/os";

const IOSErrorMessage: FC = () => {
	const strings = useStrings("BrowserCompatibilityError", Object.values(IOSTokenNames));
	const steps = [
		strings[IOSTokenNames.iOSChangeDefaultStep1],
		strings[IOSTokenNames.iOSChangeDefaultStep2],
		strings[IOSTokenNames.iOSChangeDefaultStep3],
	];

	return (
		<>
			<img src={safariLogo} alt={strings[IOSTokenNames.safariLogo]} width="60" height="60" />
			<ListWithDescription bodyText={strings[IOSTokenNames.iOSError]} listItems={steps} />
		</>
	);
};

enum IOSTokenNames {
	iOSError = "IOSError",
	safariLogo = "SafariLogo",
	iOSChangeDefaultStep1 = "IOSChangeDefaultStep1",
	iOSChangeDefaultStep2 = "IOSChangeDefaultStep2",
	iOSChangeDefaultStep3 = "IOSChangeDefaultStep3",
}

const LegacyEdgeErrorMessage: FC = () => {
	const tokenNames = ["LegacyError", "EdgeLogo"];
	const strings = useStrings("BrowserCompatibilityError", tokenNames);

	return (
		<>
			<img src={edgeLogo} alt={strings["EdgeLogo"]} width="60" height="60" />
			<p>{strings["LegacyError"]}</p>
		</>
	);
};

/**
 * The BrowserCompatibilityError component
 * @param _props The props ;)
 */
const BrowserCompatibilityError: FC<RouteComponentProps> = (_props) => {
	const tokenNames = [
		"BrowserDoesNotSupportVideo",
		"BrowserNotSupported",
		"BrowserNotRecommended",
		"UseSupportedBrowser",
		"ThisBrowserNotRecommended",
	];
	const strings = useStrings("BrowserCompatibilityError", tokenNames);

	// query string set when launching from the link in BrowserSupportWarning
	// in this case the browser is supported, but not recommended, so modify the strings
	const recommended = useCaseInsensitiveSearchParam("e") ?? "";
	useDocumentTitle(!recommended ? strings["BrowserNotSupported"] : strings["BrowserNotRecommended"]);

	let browserList: JSX.Element;
	const headerText = !recommended
		? strings["BrowserDoesNotSupportVideo"]
		: strings["ThisBrowserNotRecommended"];

	if (detectOS() === OS.ios) {
		browserList = <IOSErrorMessage />;
	} else if (isLegacyEdge()) {
		browserList = <LegacyEdgeErrorMessage />;
	} else {
		browserList = (
			<>
				<p>{strings["UseSupportedBrowser"]}</p>
				<BrowserList />
			</>
		);
	}

	return (
		<>
			<SimpleHeader />
			<main>
				<SimpleCard>
					<h1>{headerText}</h1>
					{browserList}
				</SimpleCard>
			</main>
		</>
	);
};

BrowserCompatibilityError.displayName = "BrowserCompatibilityError";

export default BrowserCompatibilityError;
