/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Home page component
 * @author Erv Walter
 * @module Epic.VideoApp.Features.Home.Home
 */

import { RouteComponentProps } from "@reach/router";
import React, { FC } from "react";
import SimpleHeader from "~/components/Header/SimpleHeader";
import { useDocumentTitle, useSharedStrings } from "~/hooks";
import { SharedStringTokens } from "~/utils/strings";

/**
 * The Home page component
 * @param _props The props ;)
 */
const Home: FC<RouteComponentProps> = (_props) => {
	const strings = useSharedStrings([SharedStringTokens.defaultPageTitle]);
	useDocumentTitle(strings[SharedStringTokens.defaultPageTitle]);

	return <SimpleHeader />;
};

Home.displayName = "Home";

export default Home;
