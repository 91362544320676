/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file get the participant that is sharing their screen
 * @author Colin Walters
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UseScreenShareParticipant
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useContext, useEffect, useRef, useState } from "react";
import { useScreenShareToggle } from "~/hooks";
import { useConnectionStatus } from "~/hooks/useConnectionStatus";
import { combinedActions, useUserState } from "~/state";
import { isSharingScreen } from "~/utils/screenSharing";
import { ParticipantDataContext } from "~/web-core/components";
import { IEVCParticipantConnectionEvent, IEVCScreenShareEvent } from "~/web-core/events";
import { IRemoteUser, ISession } from "~/web-core/interfaces";

/**
 * Get the participant in the call that is currently sharing their screen (if any)
 */
export function useScreenShareParticipant(session: ISession): IRemoteUser | null {
	const [screenShareParticipant, setScreenShareParticipant] = useState<IRemoteUser | null>(
		session.getRemoteParticipants().find(isSharingScreen) ?? null,
	);
	const prevSharing = useRef(screenShareParticipant);
	const connectionStatus = useConnectionStatus(session);
	const [, toggleScreenSharing] = useScreenShareToggle();
	const toggleScreenSharingRef = useRef<typeof toggleScreenSharing>();
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const { participants } = useContext(ParticipantDataContext);
	const dispatch = useDispatch();

	// determine the current remote participant that is sharing their screen, if any
	useEffect(() => {
		if (connectionStatus !== "connected") {
			setScreenShareParticipant(null);
			return;
		}

		const initializeScreenShareParticipant = (): void => {
			// from the list of participants, find the one that has a video track that is a shared screen
			const sharing = participants.find(isSharingScreen);
			setScreenShareParticipant(sharing || null);
		};

		const updateScreenShareParticipantStarted = (
			args: IEVCScreenShareEvent<"screenShareStarted">,
		): void => {
			setScreenShareParticipant(args.participant);
		};

		const updateScreenShareParticipantStopped = (
			args: IEVCScreenShareEvent<"screenShareStopped">,
		): void => {
			setScreenShareParticipant((previous) => {
				if (previous?.getUserIdentity() === args.participant.getUserIdentity()) {
					return null;
				}

				return previous;
			});
		};

		const updateScreenShareDisconnectedEvent = (
			args: IEVCParticipantConnectionEvent<"participantDisconnected">,
		): void => {
			const disconnectedUser = args.participant;
			setScreenShareParticipant((previous) => {
				if (previous?.getUserIdentity() === disconnectedUser.getUserIdentity()) {
					return null;
				}
				return previous;
			});
		};

		initializeScreenShareParticipant();

		session.on("participantDisconnected", updateScreenShareDisconnectedEvent);
		session.on("screenShareStarted", updateScreenShareParticipantStarted);
		session.on("screenShareStopped", updateScreenShareParticipantStopped);

		return () => {
			session.off("participantDisconnected", updateScreenShareDisconnectedEvent);
			session.off("screenShareStarted", updateScreenShareParticipantStarted);
			session.off("screenShareStopped", updateScreenShareParticipantStopped);
		};
	}, [session, connectionStatus, participants]);

	// keep an updated reference to the screen share toggle function to avoid it
	// being a dependency of the following useEffect
	useEffect(() => {
		toggleScreenSharingRef.current = toggleScreenSharing;
	}, [toggleScreenSharing]);

	useEffect(() => {
		if (screenShareParticipant) {
			// if a remote participant starts sharing their screen, stop sharing. Other users will also use this hook which only supports
			// one screen share participant, so we won't ever show two shared screens even if stopping sharing lags behind a bit
			toggleScreenSharingRef.current?.("off");
			if (!inWaitingRoom) {
				dispatch(
					combinedActions.setRemoteSharingParticipant({
						id: screenShareParticipant.getUserIdentity(),
						active: true,
					}),
				);
			}
		} else if (prevSharing.current) {
			dispatch(
				combinedActions.setRemoteSharingParticipant({
					id: prevSharing.current.getUserIdentity(),
					active: false,
				}),
			);
		}

		prevSharing.current = screenShareParticipant;
	}, [screenShareParticipant, dispatch, inWaitingRoom]);

	return screenShareParticipant;
}
