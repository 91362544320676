/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Combined survey state
 * @author Max Harkins
 * @module Epic.VideoApp.State.Combined.Survey
 */

import { withSharedStates } from "@epic/react-redux-booster";
import { FeedbackPageStep } from "~/types/survey";
import * as feedbackSurvey from "../feedbackSurvey";
import * as user from "../user";

/// COMBINED STATE TYPES ///

type CombinedState = [user.IUserState, feedbackSurvey.IFeedbackSurveyState];

/// COMBINED REDUCERS ///

/**
 * Clears unneeded state and leaves the user feedback workflow
 * @param state shared state prior to this action
 * @returns the new shared state after this action is applied
 */
function clearSurveyState(state: CombinedState, hasSubmittedSurvey: boolean): CombinedState {
	const [, prevFeedbackSurveyState] = state;

	const newUserState = user.getInitialState();
	const newFeedbackSurveyState = feedbackSurvey.setFeedbackStep(
		prevFeedbackSurveyState,
		hasSubmittedSurvey ? FeedbackPageStep.completed : FeedbackPageStep.hidden,
	);

	return [newUserState, newFeedbackSurveyState];
}

/// BUILD IT ///

export const surveyCombinedReducers = {
	clearSurveyState: withSharedStates(user.state, feedbackSurvey.state).buildReducer(clearSurveyState),
};
