/**
 * @copyright Copyright 2020-2023 Epic Systems Corporation
 * @file Utilities for managing browser cookies
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.Cookies
 */

// keys used by sessionSpecificCookies are defined here to avoid circular dependency
export const jwtCookieKey = "jwt-";
export const jwtHardwareTestCookieKey = "tst-jwt-";
export const jwtExpirationCookieKey = "expire-jwt-";
export const jwtHasRefreshCookieKey = "has-refresh-";
export const preferencesCookieKey = "preferences-";
export const sessionStatusCookieKey = "session-";
export const clientLoggingCookieKey = "loggingConfig";
export const configurationCookieKey = "configuration-";

// non-session specific cookie keys
export const recentLanguageKey = "recentLanguage";

// grouping of all cookies that belong to a specific session, used to clear cookies from a different session
// ensure no key is a prefix of another key (i.e. 'jwt-' is a prefix of 'jwt-expire-' which caused issues when checking session association)
const SESSION_SPECIFIC_COOKIES = [
	jwtCookieKey,
	jwtExpirationCookieKey,
	jwtHasRefreshCookieKey,
	jwtHardwareTestCookieKey,
	preferencesCookieKey,
	sessionStatusCookieKey,
	configurationCookieKey,
];

interface ICookieParams {
	[key: string]: string | boolean | undefined; // indexer key necessary to allow iterating over params
	path: string;
	secure?: boolean;
	expires?: string;
}

/**
 * Get standard parameters for a cookie
 * @param expiresIn number of milliseconds before a cookie should expire, or nothing if there is no set expiration
 * @returns parameters used to create a cookie
 */
function getCookieParams(expiresIn?: number): ICookieParams {
	const cookieParams: ICookieParams = { path: "/" };
	if (process.env.NODE_ENV !== "development") {
		cookieParams.secure = true;
	}
	if (expiresIn !== undefined) {
		const expirationInstant = Date.now() + expiresIn;
		cookieParams.expires = new Date(expirationInstant).toUTCString();
	}
	return cookieParams;
}

/**
 * Set a browser cookie
 * @param key cookie key
 * @param value value to set into cookie
 * @param expiresIn number of milliseconds before a cookie should expire, or nothing if there is no set expiration
 */
export function setCookie(key: string, value: string, expiresIn?: number): void {
	let cookie = `${key}=${encodeURIComponent(value)}`;

	const params = getCookieParams(expiresIn);

	Object.keys(params).forEach((paramKey) => {
		const paramVal = params[paramKey];
		if (paramVal === undefined) {
			return;
		}
		const paramString = typeof paramVal === "string" ? paramVal : paramVal.toString();
		cookie += `; ${paramKey}=${paramString}`;
	});

	document.cookie = cookie;
}

/**
 * Expires a cookie immediately
 * @param key Key of the cookie to expire
 */
export function expireCookie(key: string): void {
	setCookie(key, "", 0);
}

/**
 * Get a the value of a cookie from the browser
 * @param key cookie key
 * @returns the value of the cookie from the browser
 */
export function getCookie(key: string): string {
	if (!key) {
		return "";
	}
	const cookie = decodeURIComponent(document.cookie);

	// Get matches of "{key}={<string until next semi-colon>}" that occur at either the start of the
	// string (^) or after a semi-colon followed by some number of white space characters (;\s*).
	// The \s* after between {key} and = will catch 0 or more whitespace characters as the = can be
	// surrounded by whitespace (per https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#syntax)
	const regExp = new RegExp(`(^|;\\s*)${key}\\s*=[^;]*`);
	const matches = regExp.exec(cookie);

	if (matches?.length) {
		// the value of the cookie is everything after the first "=" but trim any whitespace
		// the user key in "preferences-<session ID>" is an encrypted value and ends in "=", so we
		// we can't just return matches[0].split("=")[1]
		const [, ...value] = matches[0].split("=");
		return value.join("=").trim();
	}
	return "";
}

/**
 * Removes any lingering cookies associated with other sessions
 *   For now, only clears other JWTs, since the only other session-specific cookie is the one used to tell that session it should close
 *   And the JWT cookies are the only ones that cause problems if they persist
 * @param sessionKey Current session id
 */
export function expireNonSessionCookies(sessionKey: string): void {
	const cookies = document.cookie.split("; ");
	const keys = cookies.map((cookie) => cookie.split("=")[0]);

	for (const key of keys) {
		if (cookieBelongsToAnotherSession(key, sessionKey)) {
			expireCookie(key);
		}
	}
}

/**
 * Determine if a cookie is session specific, but belongs to a session other than the current one
 * @param key cookie key
 * @param sessionKey current session ID
 * @returns true if the cookie is session specific and belongs to a different session
 */
function cookieBelongsToAnotherSession(key: string, sessionKey: string): boolean {
	if (!key) {
		return false;
	}

	for (const prefix of SESSION_SPECIFIC_COOKIES) {
		if (key.startsWith(prefix) && key.substring(prefix.length) !== sessionKey) {
			return true;
		}
	}

	return false;
}
