/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file more participants indicator displayed at the end of the participants strip
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Participants.MoreParticipantsIndicator
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import Participants from "~/icons/participants";
import { uiActions } from "~/state";
import { determineMenuActionType } from "~/state/ui";
import { stringFormat } from "~/utils/strings";
import styles from "./VideoStrip.module.scss";

interface IProps {
	hiddenParticipants: string[];
}

/**
 * The MoreParticipantsIndicator component
 */
const MoreParticipantsIndicator: FC<IProps> = (props) => {
	const { hiddenParticipants } = props;
	const dispatch = useDispatch();

	const strings = useStrings("MoreParticipantsIndicator", ["AriaLabel", "ButtonLabel"]);

	const onClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			const actionType = determineMenuActionType(event);
			dispatch(uiActions.toggleVisibleMenu({ menu: "participants", actionType }));

			// cancel the event propagation so it won't trigger the ClickOutsideSection in ControlsHeader
			event.nativeEvent.stopImmediatePropagation();
		},
		[dispatch],
	);

	if (!hiddenParticipants.length) {
		return null;
	}

	return (
		<button
			className={styles["moreParticipants"]}
			onClick={onClick}
			aria-label={stringFormat(strings["AriaLabel"], hiddenParticipants.length)}
		>
			<div tabIndex={-1}>
				<span>{stringFormat(strings["ButtonLabel"], hiddenParticipants.length)}</span>
				<div className={styles["iconWrapper"]}>
					<Participants height={24} width={24} />
				</div>
			</div>
		</button>
	);
};

MoreParticipantsIndicator.displayName = "MoreParticipantsIndicator";

export default MoreParticipantsIndicator;
