/**
 * @copyright Copyright 2020-2023 Epic Systems Corporation
 * @file Component used to select devices of a specific type
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.DeviceSelector
 */

import React, { FC, useCallback, useContext } from "react";
import { MenuContext } from "~/components/Utilities/MenuContext";
import { useSharedStrings } from "~/hooks";
import { DisplayContext } from "~/types";
import { resolveClassName } from "~/utils/className";
import { SharedStringTokens } from "~/utils/strings";
import { HardwareSetupDisplayContext } from "../HardwareSetup";
import styles from "./DeviceSelector.module.scss";
import NoDevicesMessage from "./NoDevicesMessage";
import SingleDeviceMessage from "./SingleDeviceMessage";
interface IOnDeviceSelectionChange {
	(device: MediaDeviceInfo): void;
}

/**
 * Props for DeviceSelector Component
 */
interface IProps {
	selectedDeviceId: string;
	devices: MediaDeviceInfo[];
	fallbackDeviceName: string;
	onDeviceSelectionChange: IOnDeviceSelectionChange;
	noDevicesMessage?: string;
}

export enum DeviceSelectorTestIds {
	option = "Option",
}

/**
 * The DeviceSelector component
 * @param props The props ;)
 */
const DeviceSelector: FC<IProps> = (props) => {
	const { selectedDeviceId, devices, fallbackDeviceName, onDeviceSelectionChange, noDevicesMessage } =
		props;

	const { isInMenu: isFromMenu } = useContext(MenuContext);
	const inDevicesTray = useContext(HardwareSetupDisplayContext) === DisplayContext.devicesTray;

	const strings = useSharedStrings([SharedStringTokens.systemDefault]);

	const onChangeCallback = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement>) => {
			if (!onDeviceSelectionChange) {
				return;
			}
			const device = devices.find((device) => device.deviceId === event.target.value);
			if (device) {
				onDeviceSelectionChange(device);
			}
		},
		[devices, onDeviceSelectionChange],
	);

	// without any options, show placeholder text or nothing
	if (!devices || !devices.length) {
		if (noDevicesMessage) {
			return <NoDevicesMessage message={noDevicesMessage} />;
		} else {
			return null;
		}
	}

	// with only one option, don't show as dropdown selector
	if (devices && devices.length === 1) {
		return (
			<SingleDeviceMessage
				deviceLabel={devices[0].label || strings[SharedStringTokens.systemDefault]}
			/>
		);
	}

	const options = (devices || []).map((device, index) => {
		const label = device.label || `${fallbackDeviceName} ${index + 1}`;

		return (
			<option
				role={isFromMenu ? "menuitemradio" : undefined}
				aria-checked={isFromMenu ? selectedDeviceId === device.deviceId : undefined}
				key={device.deviceId}
				value={device.deviceId}
				label={label}
				data-testid={DeviceSelectorTestIds.option}
			>
				{label}
			</option>
		);
	});

	const className = resolveClassName(styles, {
		deviceSelector: true,
		dark: inDevicesTray,
	});

	return (
		<select className={className} onChange={onChangeCallback} value={selectedDeviceId}>
			{options}
		</select>
	);
};

DeviceSelector.displayName = "DeviceSelector";

export default DeviceSelector;
