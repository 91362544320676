/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file createSharedState function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\create-shared-state
 */

import { getNextId } from "./get-next-id";
import { ISharedState, IInternalSliceInfo } from "./types";
import { IReducerCollection, ISelectorCollection } from "../types";

/**
 * Build a new state slice, and set the hidden properties on it. Remember - the data is not actually stored
 * in this object. This is just a placeholder with some metadata about your slice.
 * @param initializer The function used to initialize the state's value
 * @returns The new state slice.
 */
export function createSharedState<TState>(
	initializer: () => TState,
	reducers?: IReducerCollection<any>,
	selectors?: ISelectorCollection<any>,
): TState & ISharedState & IInternalSliceInfo {
	const retVal = {
		id: getNextId(),
		initializer,
		reducers,
		selectors,
	};
	// The return type is a lie. We don't actually return TState.
	return retVal as TState & ISharedState & IInternalSliceInfo;
}
