/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to determine the max number of remote participants that should be rendered on a given screen size
 * @author Colin Walters
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UseMaxVisibleRemoteParticipants
 */

import { useWindowSize } from "~/hooks";
import { MinSizeForMaxStripCapacity } from "~/types";

const fullParticipantStripCapacity = 4;

/**
 * Get the max number of remote participants that should be shown
 * @returns max number of remote participant video feeds that the current screen size can handle
 */
export function useMaxVisibleRemoteParticipants(): number {
	const { height, width } = useWindowSize();

	// if the direction of the participant strip is less than 500px, reduce number of participants shown
	if (Math.min(height, width) < MinSizeForMaxStripCapacity) {
		return fullParticipantStripCapacity - 1;
	}

	// more adjustments can get added here

	return fullParticipantStripCapacity;
}
