/**
 * @copyright Copyright 2021 - 2024 Epic Systems Corporation
 * @file hook to retrieve the request parameters for the /api/Auth/VideoCall request
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.Auth.UseVideoCallAuthRequestParams
 */

import { AuthRequestParameters, FDIQueryParameters, QueryParameters } from "~/types";
import { useCaseInsensitiveSearchParam } from "..";

/**
 * Get the auth request parameters based on the context passed in the query string
 * @returns request parameters that should be passed to the /api/Auth/VideoCall request
 */
export function useVideoCallAuthRequestParams(): Record<string, string> {
	// Organization ID
	const organization = useCaseInsensitiveSearchParam(FDIQueryParameters.orgId) ?? "";
	const org = useCaseInsensitiveSearchParam(FDIQueryParameters.orgIdShort) ?? "";
	const orgID = organization || org;

	// Web Pacs
	const useSecondaryEncryptionKey =
		useCaseInsensitiveSearchParam(FDIQueryParameters.useSecondaryEncryptionKey) === "1";
	const sKey = useCaseInsensitiveSearchParam(FDIQueryParameters.useSecondaryEncryptionKeyShort) === "1";
	const secondaryKey = useSecondaryEncryptionKey || sKey;

	// There can be different behavior when an encryption key is not set for an FDI
	// We want to inform users that this is the problem they are seeing (we usually report a different error due to not having enough context on the server).
	// Specifically a MyChart FDI will send a queryString formatted like "prevValue&encryptedString=&nextKey", with the key but lacking a value
	// Our code here will ignore an empty value, but valid key.
	// We do not want to set a default value of "" instead of null, since those are two unique cases.
	const encryptedString = useCaseInsensitiveSearchParam(FDIQueryParameters.encryptedString);

	// Smart on FHIR
	const launchToken = useCaseInsensitiveSearchParam(FDIQueryParameters.launchToken) ?? "";

	// Direct join links
	const fullToken = useCaseInsensitiveSearchParam(FDIQueryParameters.telehealthTokenLong) ?? "";
	const TT = useCaseInsensitiveSearchParam(FDIQueryParameters.telehealthToken) ?? "";
	const telehealthToken = fullToken || TT;

	// User type
	const fullUserType = useCaseInsensitiveSearchParam(FDIQueryParameters.userTypeLong) ?? "";
	const UT = useCaseInsensitiveSearchParam(FDIQueryParameters.userType) ?? "";
	const userType = fullUserType || UT;

	// Demo system
	const computerName = useCaseInsensitiveSearchParam(FDIQueryParameters.demoSystemComputerName) ?? "";
	const majorVersion = useCaseInsensitiveSearchParam(FDIQueryParameters.demoSystemMajorVersion) ?? "";

	// Low bandwidth mode
	const lowBandwidth = useCaseInsensitiveSearchParam(FDIQueryParameters.useLowBandwidthMode) ?? "";

	// Debugging settings
	let userId = useCaseInsensitiveSearchParam(FDIQueryParameters.userId) ?? "";
	let conferenceId = useCaseInsensitiveSearchParam(FDIQueryParameters.conferenceID) ?? "";
	let captureLogs = useCaseInsensitiveSearchParam(FDIQueryParameters.captureClientLogs) ?? "";

	const setName = useCaseInsensitiveSearchParam(QueryParameters.setName) ?? "";

	const skipHardwareTest = useCaseInsensitiveSearchParam(QueryParameters.skipHardwareTest) ?? "";

	if (orgID !== "0") {
		userId = "";
		conferenceId = "";
		captureLogs = "";
	}

	const params = buildVideoCallAuthenticationRequest(
		orgID,
		secondaryKey,
		encryptedString,
		launchToken,
		userType,
		telehealthToken,
		computerName,
		majorVersion,
		lowBandwidth,
		userId,
		conferenceId,
		captureLogs,
		setName,
		skipHardwareTest,
	);

	return params;
}

/**
 * Packages up the URL parameters for use in the API call. Maps keys in the query string
 * to what is expected on the server. Will decide what to send based on provided URL params
 *
 * @param org - Organization ID for the query string
 * @param encString - Encrypted string that holds launch model parameters
 * @param useSecondary - Which encryption key to use when validating parameters
 *
 * @returns The request object with the proper parameters from the URL
 */
function buildVideoCallAuthenticationRequest(
	org: string,
	useSecondary: boolean,
	encString: string | null,
	launch?: string,
	userType?: string,
	telehealthToken?: string,
	computerName?: string,
	majorVersion?: string,
	lowBandwidth?: string,
	userId?: string,
	conferenceId?: string,
	captureLogs?: string,
	setName?: string,
	skipHardwareTest?: string,
): Record<string, string> {
	const params: Record<string, string> = {};

	params[AuthRequestParameters.orgId] = org;
	params[AuthRequestParameters.useSecondaryEncryptionKey] = useSecondary ? "1" : "";

	// Only include valid parameters related to retrieving "call context"
	if (encString !== null) {
		params[AuthRequestParameters.encryptedString] = encString;
	}
	if (launch) {
		params[AuthRequestParameters.launchToken] = launch;
	}
	if (userType) {
		params[AuthRequestParameters.userType] = userType;
	}

	if (telehealthToken) {
		params[AuthRequestParameters.telehealthToken] = telehealthToken;
	}

	if (computerName) {
		params[AuthRequestParameters.demoSystemComputerName] = computerName;
	}

	if (majorVersion) {
		params[AuthRequestParameters.demoSystemMajorVersion] = majorVersion;
	}

	if (lowBandwidth) {
		params[AuthRequestParameters.useLowBandwidthMode] = lowBandwidth;
	}

	if (org === "0") {
		if (userId) {
			params[AuthRequestParameters.userId] = userId;
		}

		if (conferenceId) {
			params[AuthRequestParameters.conferenceID] = conferenceId;
		}

		if (captureLogs) {
			params[AuthRequestParameters.captureClientLogs] = captureLogs;
		}
	}

	if (setName) {
		params[AuthRequestParameters.setName] = setName;
	}

	if (skipHardwareTest) {
		params[AuthRequestParameters.skipHardwareTest] = skipHardwareTest;
	}

	return params;
}
