/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file chevron icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Chevron
 */

import React, { FC } from "react";
import { resolveClassName } from "~/utils/className";
import { IconProps } from ".";
import styles from "./chevron.module.scss";

export type Direction = "down" | "up" | "left" | "right";

interface IProps extends IconProps {
	/** The direction of the point of the chevron */
	// this must be optional or it will break ToggleButtonTrayButton
	chevronDirection?: Direction;
}

export enum ChevronTestIds {
	self = "Chevron",
}

/**
 * The Chevron component
 * @param props The props ;)
 */
const Chevron: FC<IProps> = (props) => {
	const { chevronDirection, className, ...iconProps } = props;

	const iconClassName = resolveClassName(styles, { [chevronDirection ?? "down"]: true }, className);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			{...iconProps}
			viewBox="0 0 30 30"
			className={iconClassName}
			data-testid={ChevronTestIds.self}
		>
			<g transform="rotate(180,16.5,16.5)">
				<path
					d="m 6,12 12,12 12,-12"
					fill="none"
					stroke={iconProps.stroke || "#ffffff"}
					strokeWidth="5"
					strokeLinecap={iconProps.strokeLinecap}
				/>
			</g>
		</svg>
	);
};

Chevron.displayName = "Chevron";

export default React.memo(Chevron);
