/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file globe icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.EpicLogo
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The EpicLogo component
 * @param props The props ;)
 */
const EpicLogo: FC<IconProps> = memo((props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 508.238 212.199"
			{...props}
		>
			<defs>
				<clipPath id="clip-epicLogo">
					<rect width="508.238" height="212.199" />
				</clipPath>
			</defs>
			<g id="epicLogo" clipPath="url(#clip-epicLogo)">
				<g id="Group_18067" data-name="Group 18067" transform="translate(19473 20384)">
					<path
						id="Path_11907"
						data-name="Path 11907"
						d="M651.6,256.5h0c-13-13.6-34-20.2-64.3-20.2-20.1,0-37.8,3.3-52.8,9.9l9-42.2H468.2l-9.5,44.6c-11.2-7.1-25.7-10.8-42.5-10.8a83.635,83.635,0,0,0-21.1,2.7H357l6.3-29.7h-172L157,378.5H293l-8,37.7h75.3l7.9-37a81.138,81.138,0,0,0,24.9,3.6A97.948,97.948,0,0,0,432,375l-.8,3.6h75.3l1.8-8.5c13.3,8.5,31.8,12.8,55.1,12.8,48.8,0,80.4-18,96.8-55.2a66.75,66.75,0,0,0,4.2-18.7l.8-13.3C665.7,279.7,661.2,266.6,651.6,256.5Z"
						transform="translate(-19630 -20588)"
						fill="#fff"
						opacity=".75"
					/>
					<path
						id="Path_11908"
						data-name="Path 11908"
						d="M522.6,221H481.9l-5.3,24.6h40.8Z"
						transform="translate(-19630 -20588)"
						fill="#ce1141"
					/>
					<path
						id="Path_11909"
						data-name="Path 11909"
						d="M305.9,399.3h40.7l10-47.1h.4c5.3,7.7,17.9,13.6,35.9,13.6,30.9,0,54.8-15.9,66.2-38L452,361.6h40.7l8-37.5c.2,28.4,22.8,41.7,62.7,41.7,44.9,0,68.6-16.3,81.3-45.1H601.6c-5.8,12.1-17,18.2-31.7,18.2-19.6,0-28.5-10.6-24.5-29.4s17.8-29.4,36.8-29.4c15.5,0,22.8,5.3,23.8,14.5h42.2c1.4-30.5-23.4-41.4-60.8-41.4-38.8,0-68.1,14-80.5,42.2l8.1-38H474.3l-7.5,35.3c-1.8-24.5-20.4-38-50.4-38-16.5,0-32.2,6-43.1,16.5h-.4l2.9-13.8H336.2l-16,74.6H229.7l4.3-23.4h80.8l6-28.4H240.1l4.4-22.9h91.7l6.3-29.6H205L177.7,361.6H313.9Zm59.8-89.6c3.4-16.1,15.6-26.5,34.6-26.5,18.6,0,26.9,9.2,23.2,26.5s-15.8,26.5-34.5,26.5C370,336.3,362.3,325.9,365.7,309.7Z"
						transform="translate(-19630 -20588)"
						fill="#ce1141"
					/>
				</g>
			</g>
		</svg>
	);
});

EpicLogo.displayName = "EpicLogo";

export default EpicLogo;
