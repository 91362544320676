/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file buildSelectorHook function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\build-selector-hook
 */

/**
 * Determine if the two given store slices are the same. If they are two objects, it's a simple identity
 * comparison. If they are two arrays, we compare the contents of the arrays.
 * @param slice1
 * @param slice2
 * @return True if they are the same, false if they are different.
 */
export function areSlicesTheSame<T = any>(slice1: T, slice2: T): boolean {
	if (!slice1 || !slice2) {
		return !slice1 && !slice2;
	} else if (Array.isArray(slice1) || Array.isArray(slice2)) {
		if (!Array.isArray(slice1) || !Array.isArray(slice2)) {
			return false;
		} else {
			if (slice1.length !== slice2.length) {
				return false;
			} else {
				let areSame = true;
				slice1.forEach((value, i) => {
					if (slice2[i] !== value) {
						areSame = false;
						return;
					}
				});
				return areSame;
			}
		}
	} else {
		return slice1 === slice2;
	}
}
