/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Initial feedback question showing happy/sad faces
 * @author Max Harkins
 * @module Epic.VideoApp.Components.FeedbackSurvey.OptionalFeedbackButton
 */

import { useDispatch } from "@epic/react-redux-booster/dist/use-dispatch";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { feedbackSurveyActions } from "~/state/feedbackSurvey";
import { FeedbackPageStep, FeedbackReaction } from "~/types/survey";
import ActionButton from "../Utilities/ActionButton";
import { useFeedbackTimeoutWarning } from "./hooks/useFeedbackTimeoutWarning";
import { useSaveUserReaction } from "./hooks/useSaveUserReaction";

import styles from "./OptionalFeedbackButton.module.scss";

enum TokenNames {
	feedbackLinkText = "FeedbackLinkText",
}

export enum OptionalFeedbackButtonTestIds {
	self = "OptionalFeedbackButton",
}

/**
 * A button styled to be optional that lets users submit feedback if they want
 */
const OptionalFeedbackButton: FC = () => {
	// Handle timing out when the survey expires
	useFeedbackTimeoutWarning();
	const saveReaction = useSaveUserReaction();

	const strings = useStrings("IntroQuestion", Object.values(TokenNames));
	const dispatch = useDispatch();

	const feedbackLinkAction = useCallback(() => {
		dispatch(feedbackSurveyActions.setFeedbackStep(FeedbackPageStep.survey));
		void saveReaction(FeedbackReaction.negative, true);
	}, [dispatch, saveReaction]);

	return (
		<div className={styles["optionalButtonContainer"]}>
			<ActionButton
				priority="secondary"
				tone="neutral"
				onClick={feedbackLinkAction}
				data-testid={OptionalFeedbackButtonTestIds.self}
			>
				{strings[TokenNames.feedbackLinkText]}
			</ActionButton>
		</div>
	);
};

OptionalFeedbackButton.displayName = "OptionalFeedbackButton";

export default OptionalFeedbackButton;
