/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Warning banner to inform the user they are using a browser without Twilio video recommendations
 * @author Will Cooper
 * @module Epic.VideoApp.Components.HardwareTest.Banners.BrowserSupportWarning
 */

import React, { FC, useCallback } from "react";
import { getEndpointUrl } from "~/app/routes";
import { useStrings } from "~/hooks";
import BubbleBanner from "../../Utilities/BubbleBanner";

interface IProps {
	inline?: boolean;
}

/**
 * The BrowserSupportWarning component
 */
const BrowserSupportWarning: FC<IProps> = (props) => {
	const { inline = false } = props;
	const strings = useStrings("BrowserSupportWarning", Object.values(TokenNames));

	const onClick = useCallback(() => {
		// on click open the supported browsers page in another tab
		window.open(getEndpointUrl("/BrowserCompatibilityError?e=1"), "_blank");
	}, []);

	return (
		<BubbleBanner
			type="warning"
			title={strings[TokenNames.title]}
			message={strings[TokenNames.message]}
			button={{ text: strings[TokenNames.buttonLabel], onClick }}
			inline={inline}
		/>
	);
};

enum TokenNames {
	title = "Title",
	message = "Message",
	buttonLabel = "ButtonLabel",
}

BrowserSupportWarning.displayName = "BrowserSupportWarning";

export default BrowserSupportWarning;
