/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Custom hooks for chat view callbacks
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.Hooks.UseChatSendMessagesCallbacks
 */

import { IMessage } from "@epic/chat";
import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { WebSocketContext } from "~/components/WebSocket/WebSocketConnection";
import { messageActions } from "~/state";
import {
	MAX_MESSAGE_LENGTH,
	TIME_UNTIL_CAN_SEND_ANOTHER_TYPING_SECONDS,
	TIME_UNTIL_NEXT_TYPING_EVENT_SECONDS,
} from "~/types/chat";
import { IChatMessage, ITypingMessage } from "~/types/websockets";
import { secondsToMs } from "~/utils/dateTime";
import { sanitizeInput } from "~/utils/text";
import { ConnectionContext } from "../ConnectionManagement";

interface IUseChatProps {
	localUserId: string;
	lastSequentialId: number;
	setShouldSendBeEnabled: (value: boolean) => void;
	setPendingMessages: (value: React.SetStateAction<string[]>) => void;
}

// React hook that manages the chat message sending callbacks
export const useChatSendMessagesCallbacks = (
	props: IUseChatProps,
): {
	onUserTrySendNewMessage: (newMsg: string, isNewMsgValid: boolean) => boolean;
	onRetrySendMessage: (message: IMessage | undefined) => void;
	onNewMessageTextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => string;
	canSendMessage: (newMsg: string) => boolean;
	handleScrollToEnd: () => void;
} => {
	const { localUserId, lastSequentialId, setShouldSendBeEnabled, setPendingMessages } = props;

	const { sendSocketMessage } = useContext(WebSocketContext);
	const { participantsTypingTimeoutsRef } = useContext(ConnectionContext);
	const dispatch = useDispatch();

	// Callback to clear the typing timeout
	const clearTypingTimeout = useCallback(
		(userId: string) => {
			clearTimeout(participantsTypingTimeoutsRef.current[userId]);
			delete participantsTypingTimeoutsRef.current[userId];
		},
		[participantsTypingTimeoutsRef],
	);

	// Callback to create a new message body
	const createMessageBody = useCallback(
		(messageBody: string, existingMessageId?: string): IMessage => {
			const sentTimeISOString = new Date().toISOString();
			const newMsgDate = new Date(sentTimeISOString);
			const sentDateTime = newMsgDate.getTime();
			const displayTime = newMsgDate.toLocaleTimeString(undefined, { timeStyle: "short" });

			const newMessage: IMessage = {
				messageBody: messageBody,
				messageId: existingMessageId ? existingMessageId : crypto.randomUUID(),
				sentTimeISOString: sentTimeISOString,
				sentDateTime: sentDateTime,
				displayTime: displayTime,
				senderId: localUserId,
				messageType: "self",
				sequentialId: (lastSequentialId ?? 0) + 0.01,
				messageDeliveryStatus: "sending",
			};
			return newMessage;
		},
		[lastSequentialId, localUserId],
	);

	// Callback to send a new message
	const onUserTrySendNewMessage = useCallback(
		(newMsg: string, isNewMsgValid: boolean) => {
			if (!isNewMsgValid) {
				return false;
			}
			const constructedMessageBody = createMessageBody(newMsg);

			dispatch(messageActions.addOrUpdateMessages([constructedMessageBody]));
			const chatMessage: IChatMessage = {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				data: { MessageBody: newMsg },
				messageId: constructedMessageBody.messageId,
				messageType: "SendGroupMessage",
				version: "1.0",
				requiresAck: true,
			};
			sendSocketMessage(chatMessage);
			setShouldSendBeEnabled(false);
			setPendingMessages((queue) => [...queue, constructedMessageBody.messageId]);

			//We stop typing when we hit send, but we should be able to send another typing indicator
			//if we start typing again, so clear the timeout ref
			clearTypingTimeout("me");
			participantsTypingTimeoutsRef.current["me"] = setTimeout(() => {
				clearTypingTimeout("me");
			}, secondsToMs(TIME_UNTIL_CAN_SEND_ANOTHER_TYPING_SECONDS));
			return true;
		},
		[
			clearTypingTimeout,
			createMessageBody,
			dispatch,
			participantsTypingTimeoutsRef,
			sendSocketMessage,
			setPendingMessages,
			setShouldSendBeEnabled,
		],
	);

	// Callback to retry sending a message
	const onRetrySendMessage = useCallback(
		(message: IMessage | undefined) => {
			if (!message?.messageId || message.messageDeliveryStatus !== "failed") {
				return;
			}
			const updatedMessageBody = createMessageBody(message?.messageBody, message?.messageId);

			dispatch(
				messageActions.updateMessage({
					...updatedMessageBody,
				}),
			);

			const chatMessage: IChatMessage = {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				data: { MessageBody: updatedMessageBody.messageBody },
				messageId: updatedMessageBody.messageId,
				messageType: "SendGroupMessage",
				version: "1.0",
				requiresAck: true,
			};
			sendSocketMessage(chatMessage);
			setPendingMessages((queue) => [...queue, updatedMessageBody.messageId]);
		},
		[createMessageBody, dispatch, sendSocketMessage, setPendingMessages],
	);

	// Callback to handle changes in the new message text
	const onNewMessageTextChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>): string => {
			let messageText = event.target.value;
			messageText = sanitizeInput(messageText, MAX_MESSAGE_LENGTH * 1.5);
			if (messageText.length === 0) {
				setShouldSendBeEnabled(false);
			} else {
				setShouldSendBeEnabled(true);
			}
			const recentlySentTypingIndicator = !!participantsTypingTimeoutsRef.current["me"];
			const shouldSendTypingIndicator = !recentlySentTypingIndicator;

			if (shouldSendTypingIndicator) {
				const typingMessage: ITypingMessage = {
					messageId: crypto.randomUUID(),
					messageType: "Typing",
					version: "1.0",
					requiresAck: false,
					data: undefined,
				};
				sendSocketMessage(typingMessage);

				participantsTypingTimeoutsRef.current["me"] = setTimeout(() => {
					clearTypingTimeout("me");
				}, secondsToMs(TIME_UNTIL_NEXT_TYPING_EVENT_SECONDS));
			}
			return messageText;
		},
		[clearTypingTimeout, participantsTypingTimeoutsRef, sendSocketMessage, setShouldSendBeEnabled],
	);

	// Callback to check if a new message can be sent
	const canSendMessage = useCallback((newMsg: string): boolean => {
		return newMsg.trim() !== "" && newMsg.length <= MAX_MESSAGE_LENGTH;
	}, []);

	// Callback to handle scrolling to the end of the chat
	const handleScrollToEnd = useCallback((): void => {
		dispatch(messageActions.updateHasUnreadMessage(false));
	}, [dispatch]);

	return {
		onUserTrySendNewMessage,
		onRetrySendMessage,
		onNewMessageTextChange,
		canSendMessage,
		handleScrollToEnd,
	};
};
