/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Supported Browser model
 * @author Matt Panico
 * @module Epic.VideoApp.Models.Browsers.SupportedBrowsers
 */
import { OS } from "~/utils/os";

export interface IBrowserDownload {
	name: string;
	downloadURL: string;
	supported: OS[];
}

const safari: IBrowserDownload = {
	name: "Apple Safari",
	downloadURL: "https://apple.com/safari",
	supported: [OS.mac, OS.ios],
};

const chromeDesktop: IBrowserDownload = {
	name: "Google Chrome",
	downloadURL: "https://www.google.com/chrome/",
	supported: [OS.windows, OS.mac, OS.linux],
};

const chromeAndroid: IBrowserDownload = {
	name: "Google Chrome",
	downloadURL: "https://play.google.com/store/apps/details?id=com.android.chrome",
	supported: [OS.android],
};

const edgeDesktop: IBrowserDownload = {
	name: "Microsoft Edge",
	downloadURL: "https://www.microsoft.com/edge/",
	supported: [OS.windows, OS.mac],
};

export const supportedBrowsers = {
	safari,
	chromeDesktop,
	chromeAndroid,
	edgeDesktop,
};

/**
 * Get the browsers supported by the current device
 * @param os operating system that is in use
 * @returns list of supported browsers on the current operating system
 */
export function getSupportedBrowsers(os?: OS): IBrowserDownload[] {
	const browsers = [safari, chromeDesktop, chromeAndroid, edgeDesktop];

	if (os === undefined) {
		return browsers;
	} else {
		return browsers.filter((browser) => browser.supported.includes(os));
	}
}
