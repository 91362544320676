/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file browser link component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.BrowserList.BrowserLink
 */

import React, { FC, memo } from "react";
import { IBrowserDownload, supportedBrowsers } from "~/models/browsers/supportedBrowsers";
import styles from "./BrowserList.module.scss";
import chromeLogo from "./chrome.svg";
import edgeLogo from "./edge.svg";
import safariLogo from "./safari.svg";

/**
 * Props for BrowserLink Component
 */
interface IProps {
	browser: IBrowserDownload;
}

/**
 * The BrowserLink component
 * @param props The props ;)
 */
const BrowserLink: FC<IProps> = memo((props) => {
	const { browser } = props;
	const { name, downloadURL } = browser;

	return (
		<a
			href={downloadURL}
			className={styles["browserLink"]}
			target="_blank"
			rel="noreferrer"
			aria-label={name}
		>
			<img
				src={getBrowserIcon(browser)}
				alt={name}
				className={styles["browserIcon"]}
				width="40"
				height="40"
			/>
			{name}
		</a>
	);
});

/**
 * Get a browser's icon
 */
function getBrowserIcon(browser: IBrowserDownload): string | undefined {
	switch (browser) {
		case supportedBrowsers.safari:
			return safariLogo;
		case supportedBrowsers.chromeAndroid:
		case supportedBrowsers.chromeDesktop:
			return chromeLogo;
		case supportedBrowsers.edgeDesktop:
			return edgeLogo;
		default:
			return undefined;
	}
}

BrowserLink.displayName = "BrowserLink";

export default BrowserLink;
