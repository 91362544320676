/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Combined State
 * @author Colin Walters
 * @module Epic.VideoApp.State.Combined.Index
 */

import { buildCombinedReducers, buildCombinedSelectors } from "@epic/react-redux-booster";
import store from "~/app/store";
import { chatCombinedReducers } from "./chat";
import { configurationCombinedReducers, configurationCombinedSelectors } from "./configuration";
import { connectionCombinedReducers } from "./connection";
import { disconnectCombinedReducers } from "./disconnect";
import { hardwareCombinedReducers } from "./hardware";
import { imageCaptureCombinedReducers } from "./imageCapture";
import { localeCombinedReducers } from "./locale";
import { participantCombinedReducers } from "./participant";
import { screenSharingCombinedReducers } from "./screenSharing";
import { surveyCombinedReducers } from "./survey";

/// BUILD IT ///

export const combinedActions = buildCombinedReducers({
	...connectionCombinedReducers,
	...disconnectCombinedReducers,
	...hardwareCombinedReducers,
	...screenSharingCombinedReducers,
	...imageCaptureCombinedReducers,
	...localeCombinedReducers,
	...participantCombinedReducers,
	...configurationCombinedReducers,
	...surveyCombinedReducers,
	...chatCombinedReducers,
});

export const useCombinedSelectors = buildCombinedSelectors({
	...configurationCombinedSelectors,
});

store.addCombinedReducers(combinedActions);
store.addCombinedSelectors(useCombinedSelectors);
