/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Calculates the total number of pages to show based on the number of remote participants and the grid size
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Grid.Hooks.UseGridPageInformation
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useEffect, useMemo } from "react";
import { alertActions, uiActions } from "~/state";
import {
	MinVideoHeight,
	MinVideoWidth,
	MinimumScreenHeightForHeader,
	ScreenWidthInflectionPoint,
	SmallScreenWidthInflectionPoint,
} from "~/types";
import { detectBrowser } from "~/utils/browser";
import { getVideoSizeWithMargin } from "~/utils/general";

const smallScreenGridCutoff = 6;
const mediumScreenGridCutoff = 12;
const largeScreenGridCutoff = 16;

/**
 * Hook to manage the total page count within the grid.
 * It is responsible for checking the device size maximum and responding to track switch-offs
 *
 * @param gridHeight - Grid height in pixels
 * @param gridWidth - Grid width in pixels
 * @param dedicatedTracks - The number of videos that will always be on screen (local preview & screen share track)
 * @param remoteParticipantLength - The number of remote participants in the visit
 * @returns - The number of remote participants we can safely show on one page
 */
export function useGridPageInformation(
	gridHeight: number,
	gridWidth: number,
	dedicatedTracks: number,
	remoteParticipantLength: number,
): number {
	const dispatch = useDispatch();
	const isFirefox = useMemo(() => {
		return detectBrowser() === "firefox";
	}, []);

	/**
	 * Determine how many participants we can show on one page of the grid
	 */
	const remoteFeedLimit = useMemo(() => {
		if (gridHeight <= 0 || gridWidth <= 0) {
			// Don't attempt to show participants when there is no valid grid.
			return 0;
		}

		const heightFactor = gridHeight / getVideoSizeWithMargin(MinVideoHeight);
		const widthFactor = gridWidth / getVideoSizeWithMargin(MinVideoWidth);
		const gridCapacity = Math.floor(heightFactor) * Math.floor(widthFactor);

		// We need to determine the number of remote participants we can show per-page
		// This can vary based on screen size or whether we have seen bandwidth problems occur
		// By default use the current maximum
		let remoteFeedCount = gridCapacity - dedicatedTracks;

		if (
			gridWidth < SmallScreenWidthInflectionPoint ||
			gridHeight < MinimumScreenHeightForHeader ||
			isFirefox
		) {
			// We are on a small screen device (phone or small tablet), or other constrained device (Firefox)
			// so set the cap at 6 feeds per page in good conditions
			remoteFeedCount = Math.min(remoteFeedCount, smallScreenGridCutoff - dedicatedTracks);
		} else if (gridWidth < ScreenWidthInflectionPoint) {
			// We are on a medium size screen device (tablet or smaller laptop/desktop screen), set the cap at 12 feeds per page in good conditions
			remoteFeedCount = Math.min(remoteFeedCount, mediumScreenGridCutoff - dedicatedTracks);
		} else {
			// We are on a large screen, cap at 16 total videos per page
			remoteFeedCount = Math.min(remoteFeedCount, largeScreenGridCutoff - dedicatedTracks);
		}

		// The number of remote feeds can be 0 when the page first loads, prevent setting infinite value into store
		// This also allows us to show a minimum of one remote feed at all times (assuming we have any)
		return Math.max(remoteFeedCount, 1);
	}, [dedicatedTracks, gridHeight, gridWidth, isFirefox]);

	/**
	 * Set the number of pages based on participants that can be shown per page and the total number of participants in the call
	 */
	useEffect(() => {
		if (remoteFeedLimit > 0) {
			const rawPageCount = remoteParticipantLength / remoteFeedLimit;
			dispatch(uiActions.setTotalPageCount(Math.ceil(rawPageCount)));
		} else {
			dispatch(uiActions.setTotalPageCount(0));
		}
	}, [dispatch, remoteFeedLimit, remoteParticipantLength]);

	/**
	 * Clean-up useEffect ot prevent memory leaks and use a safe minimum page value
	 */
	useEffect(() => {
		return () => {
			dispatch(uiActions.setTotalPageCount(0));
			dispatch(alertActions.clearToasts("bandwidth-congestion-grid"));
		};
	}, [dispatch]);

	return remoteFeedLimit;
}
