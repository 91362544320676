/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file more menu button
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.MoreMenuButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import ThreeDots from "~/icons/threeDots";
import { uiActions, useUIState } from "~/state";
import { determineMenuActionType } from "~/state/ui";
import { resolveClassName } from "~/utils/className";
import styles from "../ControlsHeader.module.scss";
import DropDownMenus from "../Menu/DropDownMenus";
import { useShowParticipantButtonInHeader } from "../hooks/useShowParticipantButtonInHeader";
import ControlButton from "./ControlButton";

enum TokenNames {
	label = "Label",
}

/**
 * Props for MoreMenuButton Component
 */
interface IProps {
	/** Whether or not the button should be disabled */
	disabled?: boolean;

	/** Whether or not the button should be rendered without a label */
	noLabel?: boolean;

	/** Whether or not the button should be rendered without a tooltip */
	noTooltip?: boolean;
}

// button ID in HTML used to focus next option when leaving the menu dropdown
const moreOptionsButtonId = "more-options";
// export the selector that can be used to the MoreMenuButton in the DOM
export const moreOptionsSelector = `#${moreOptionsButtonId}`;

/**
 * The MoreMenuButton component
 * @param props The props ;)
 */
const MoreMenuButton: FC<IProps> = (props) => {
	const { disabled, noLabel, noTooltip } = props;
	const visibleMenu = useUIState((selectors) => selectors.getVisibleMenu(), []);
	const dispatch = useDispatch();
	const showParticipantButtonInHeader = useShowParticipantButtonInHeader();
	const participantMenuOpen =
		showParticipantButtonInHeader &&
		(visibleMenu === "participants" || visibleMenu === "participantOptions");

	const strings = useStrings("MoreMenuButton", Object.values(TokenNames));

	const onClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			if (!disabled) {
				// If participant menu is separate from the main && current menu is participant-based,
				// or if there is no menu open, then open the menu
				const openMenu = participantMenuOpen || !visibleMenu;
				const menu = openMenu ? "menu" : null;
				const actionType = determineMenuActionType(event);
				dispatch(uiActions.toggleVisibleMenu({ menu, actionType }));

				// cancel the event propagation so it won't trigger the ClickOutsideSection in ControlsHeader
				event?.nativeEvent.stopImmediatePropagation();
			}
		},
		[disabled, dispatch, participantMenuOpen, visibleMenu],
	);

	const pressed = !!visibleMenu && !participantMenuOpen;
	const className = resolveClassName(styles, { buttonActive: pressed });

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLButtonElement>) => {
			// when the menu is open, if the user reverse tabs, close the menu
			if (pressed && event.key.toLowerCase() === "tab" && event.shiftKey) {
				dispatch(uiActions.toggleVisibleMenu({ menu: null }));
			}
		},
		[pressed, dispatch],
	);

	const moreMenuKeyboardShortcut = ["alt", "o"];
	const moreMenuButtonTooltipFormatted = useGetFormattedHotkeyString(
		strings[TokenNames.label],
		moreMenuKeyboardShortcut,
	);

	return (
		<div className={styles["moreMenuWrapper"]}>
			<ControlButton
				id={moreOptionsButtonId}
				icon={ThreeDots}
				ariaLabel={moreMenuButtonTooltipFormatted}
				onClick={onClick}
				pressed={pressed}
				buttonClassName={className}
				keyboardShortcut={moreMenuKeyboardShortcut}
				onKeyDown={onKeyDown}
				buttonText={!noLabel ? strings[TokenNames.label] : undefined}
				tooltipText={!noTooltip && !pressed ? moreMenuButtonTooltipFormatted : undefined}
			/>
			<DropDownMenus />
		</div>
	);
};

MoreMenuButton.displayName = "MoreMenuButton";

export default MoreMenuButton;
