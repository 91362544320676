/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file stop screen sharing icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.ScreenShareStop
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum ScreenShareStopIconTestIds {
	self = "ScreenShareStop",
}

/**
 * The ScreenShareStop component
 * @param props The props ;)
 */
const ScreenShareStop: FC<IconProps> = memo((props) => {
	return (
		<svg
			data-testid={ScreenShareStopIconTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
		>
			<path d="M25.52,7.27h-19A1.6,1.6,0,0,0,4.89,8.86V20a1.59,1.59,0,0,0,1.59,1.58h6.35v1.59H9.65v1.59h12.7V23.14H19.17V21.55h6.35A1.59,1.59,0,0,0,27.11,20V8.86A1.6,1.6,0,0,0,25.52,7.27ZM19.47,19.1,16,15.63l-3.45,3.45-1.72-1.72,3.45-3.45L10.8,10.44l1.73-1.72L16,12.17,19.46,8.7l1.72,1.73-3.45,3.48,3.47,3.46Z" />
		</svg>
	);
});

ScreenShareStop.displayName = "ScreenShareStop";

export default ScreenShareStop;
