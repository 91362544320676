/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file createMetaReducer function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\create-meta-reducer\create-meta-reducer
 */

import { Reducer } from "redux";
import {
	IInternalAction,
	ReducerWithMetadata,
	ISliceInfoCollection,
	ISliceKeyCollection,
	IInternalReducerCollection,
} from "../types";
import { getStateSlice } from "./get-state-slice";
import { initializeState } from "./initialize-state";
import { mergeNextSlice } from "./merge-next-slice";

/**
 * This is the function to call when adding or removing state slices from the store. It returns the actual
 * reducer function that will be passed to Redux as the meta-reducer.
 * @param sliceInfoCollection All the state slices that you want to be a part of the store.
 * @returns The meta reducer function to be passed to Redux
 */
export function createMetaReducer(
	sliceInfoCollection: ISliceInfoCollection,
	sliceKeys: ISliceKeyCollection,
	allReducers: IInternalReducerCollection,
): Reducer<any, IInternalAction> {
	return (prevState = {}, action: IInternalAction) => {
		// Run through all slices, and initialize their state, if necessary
		// Will return the exact same prevState if nothing was changed.
		let nextState = initializeState(prevState, sliceInfoCollection, sliceKeys);

		// NOTE: we are ONLY running the reducer code relevant for this action.
		// as a result, you cannot rely on reducers to initialize their own state.
		// The initialization of the state is expected to be handled by the init()
		// function passed into buildSharedState().

		// Core Redux code sends certain actions like @@init, which we won't have any any reducers for.
		// So don't panic unless we see that the action actually had a slice ID.
		if (action.sliceId) {
			const sliceReducers = allReducers[action.sliceId];
			if (sliceReducers) {
				const sliceReducer = sliceReducers[action.type] as ReducerWithMetadata;
				if (sliceReducer) {
					const { requiredSliceIds } = sliceReducer;
					const { sliceId } = action;

					// Grab the state slices needed for this reducer
					const prevSlice = getStateSlice(nextState, sliceKeys, sliceId, requiredSliceIds);

					// Run the reducer
					const nextSlice = sliceReducer(prevSlice, action.payload);

					// Add the results to the new state
					if (nextSlice !== prevSlice) {
						nextState = mergeNextSlice(
							nextState,
							nextSlice,
							sliceKeys,
							sliceId,
							requiredSliceIds,
						);
					}
				} else {
					throw Error(
						`Couldn't find the reducer for action ${
							action.type
						}. Did you forget to call addSharedState or addCombinedReducers for these reducers?`,
					);
				}
			} else {
				throw Error(
					`Couldn't find any reducers for id ${
						action.sliceId
					}. Did you forget to call addSharedState or addCombinedReducers for these reducers?`,
				);
			}
		}

		// Return exact same state if nothing changed
		return nextState;
	};
}
