/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Hooks relating to synchronizing the list of participants with blank names
 * @author Tara Feldstein
 * @module Epic.VideoApp.Hooks.Messaging.UseSynchronizeNamelessParticipantData
 */

import { useCallback, useContext } from "react";
import { INamelessParticipantData } from "~/components/VideoCall/hooks/useNamelessParticipantData";
import { VideoSessionContext } from "~/web-core/components";
import {
	INamelessParticipantSyncRequest,
	INamelessParticipantSyncResponse,
	MessageActionType,
	MessageType,
} from "../../types";
import { sendMessage } from "../../utils/sendMessage";

export interface ISendNamelessParticipantSyncRequest {
	(localData: INamelessParticipantData): void;
}

/**
 * Hook to send Nameless Participant Sync Request messages on the user's current data track
 * The returned function takes two parameters: The current NamelessParticipantData, and a flag for whether to delay sending the message
 *   If the request is being sent immediately on joining the room, it should be delayed to allow other users to attach to our data track
 * @returns A function to send a Nameless Participant Sync Request message along the data track
 */
export function useSendNamelessParticipantSyncRequest(): ISendNamelessParticipantSyncRequest {
	const { session } = useContext(VideoSessionContext);
	const sendNamelessParticipantSyncRequest = useCallback(
		(namelessParticipantData: INamelessParticipantData) => {
			const now = Date.now(); // Always send this request with a timestamp of now
			const message: INamelessParticipantSyncRequest = {
				action: MessageActionType.broadcast,
				payload: {
					namelessParticipantData: {
						...namelessParticipantData,
						timestamp: now,
					},
				},
				type: MessageType.namelessParticipantSyncRequest,
				needsACK: false,
			};

			sendMessage(session, message);
		},
		[session],
	);

	return sendNamelessParticipantSyncRequest;
}

export interface ISendNamelessParticipantSyncResponse {
	(namelessParticipantData: INamelessParticipantData): void;
}

/**
 * Hook to send Nameless Participant Sync Response messages on the user's current data track
 * The returned function takes one parameter: The current NamelessParticipantData
 * @returns A function to send a Nameless Participant Sync Response message along the data track
 */
export function useSendNamelessParticipantSyncResponse(): ISendNamelessParticipantSyncResponse {
	const { session } = useContext(VideoSessionContext);
	const sendNamelessParticipantSyncResponse = useCallback(
		(namelessParticipantData: INamelessParticipantData) => {
			const message: INamelessParticipantSyncResponse = {
				action: MessageActionType.broadcast,
				payload: { namelessParticipantData: namelessParticipantData },
				type: MessageType.namelessParticipantSyncResponse,
				needsACK: false,
			};

			sendMessage(session, message);
		},
		[session],
	);

	return sendNamelessParticipantSyncResponse;
}
