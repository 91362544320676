/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to get some functions to change the video track
 * @author Matt Panico
 * @module Epic.VideoApp.Hooks.LocalTracks.UseVideoTrackActions
 */

import { IAction, useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext } from "react";
import {
	alertActions,
	combinedActions,
	hardwareTestActions,
	useBackgroundProcessorsState,
	useRoomState,
	useUserState,
} from "~/state";
import { localTrackActions, useLocalTrackState } from "~/state/localTracks";
import { DeviceStatus, DeviceStatusSubtype, VideoSwitchResult } from "~/types";
import { getCameraPreference, setCameraPreference } from "~/utils/device";
import { VideoSessionContext } from "~/web-core/components/VideoSessionProvider";

interface IVideoTrackActions {
	switchVideoDevice: (
		device: MediaDeviceInfo,
		params?: ISwitchVideoDeviceParams,
	) => Promise<VideoSwitchResult>;
	removeLocalVideoTrack: (manual?: boolean) => void;
}

/** Options specified to switching local video track */
interface ISwitchVideoDeviceParams {
	/** Whether or not the user manually initiated the device switch */
	manual?: boolean;
	/** Whether or not the user's camera should be enabled with the switch */
	enable?: boolean;
	/** New background that should be added to video track once it is started */
	newBackground?: string;
}

export function useVideoTrackActions(): IVideoTrackActions {
	const { localDeviceStream, session } = useContext(VideoSessionContext);
	const disabledCamera = useLocalTrackState((selectors) => selectors.getDisabledCamera(), []);
	const publishedProcessor = useBackgroundProcessorsState(
		(selectors) => selectors.getPublishedBackgroundProcessor(),
		[],
	);
	const processorMap = useBackgroundProcessorsState((selectors) => selectors.getBackgroundProcessors(), []);
	const cameraLocked = useUserState((selectors) => selectors.getCameraLock(), []);
	const useLowBandwidth = useRoomState((selectors) => selectors.getIsLowBandwidthMode(), []);
	const dispatch = useDispatch();

	/**
	 * Switch what media device is being as the local video track
	 * The deviceId of the new device should be specified using the device parameter, not constraints
	 *
	 * Note that this function also serves to turn the video track on
	 *
	 * @param device the MediaDeviceInfo to switch to
	 * @param params video device switching options (see ISwitchVideoDeviceParams for options)
	 */
	const switchVideoDevice = useCallback(
		async (
			device: MediaDeviceInfo,
			params: ISwitchVideoDeviceParams = {},
		): Promise<VideoSwitchResult> => {
			const enable = params.enable;
			const manual = params.manual ?? false;

			if (disabledCamera?.deviceId && !enable) {
				// if the camera's disabled just update the device we should try to acquire on enabling
				dispatch(localTrackActions.setDisabledCameraId(device.deviceId || null));
				return VideoSwitchResult.noSwitchNeeded;
			}

			if (cameraLocked) {
				// If the user has had their camera locked by a moderated, don't allow them to change.
				// The disabledCamera tracking state should usually cover this scenario since locking the device also disables it.
				// Use this as a fallback catch
				return VideoSwitchResult.noSwitchNeeded;
			}

			// We would expect the local device stream to always be defined, but if it isn't, treat this as no switch needed since a switch would be impossible
			if (!localDeviceStream) {
				return VideoSwitchResult.noSwitchNeeded;
			}

			const resultAndCode = await localDeviceStream.switchVideoDeviceAsync(device, useLowBandwidth);
			if (resultAndCode.result === true) {
				saveDevicePreference(device, manual);

				const background = params.newBackground || publishedProcessor;
				const backgroundProcessor = processorMap.get(background)?.processor ?? null;
				if (background && backgroundProcessor) {
					await session?.localUser.applyVideoBackground(backgroundProcessor, localDeviceStream);
				}

				if (session?.connectionStatus === "connected") {
					await session?.publish("video", true, localDeviceStream);
				}

				// getUserMedia call succeeded, so allow creating audio context from this point
				dispatch(combinedActions.switchVideoDevice(device));
				onFinished(manual, dispatch);
				return VideoSwitchResult.switchSuccess;
			} else {
				onError(resultAndCode.error as DOMException, manual, dispatch);
				onFinished(manual, dispatch);
				return VideoSwitchResult.switchFailed;
			}
		},
		[
			disabledCamera,
			cameraLocked,
			localDeviceStream,
			dispatch,
			publishedProcessor,
			processorMap,
			session,
			useLowBandwidth,
		],
	);

	const removeLocalVideoTrack = useCallback(
		async (manual?: boolean): Promise<void> => {
			if (!localDeviceStream) {
				return;
			}

			await session?.publish("video", false, localDeviceStream);

			const disabledDeviceId = await localDeviceStream.removeLocalVideoTrack();

			if (manual && disabledDeviceId) {
				dispatch(localTrackActions.setDisabledCameraId(disabledDeviceId));
			} else {
				dispatch(
					hardwareTestActions.setCameraState({
						status: DeviceStatus.error,
						errorType: DeviceStatusSubtype.noDevice,
					}),
				);
				dispatch(localTrackActions.setHasAutoSelectedVideo());
			}
		},
		[localDeviceStream, session, dispatch],
	);

	return { switchVideoDevice, removeLocalVideoTrack };
}

const saveDevicePreference = (device: MediaDeviceInfo, manual: boolean): void => {
	// remember preference if this is a manual selection or if no preference is found. Also,
	// device Id may change, so if this is an auto-select based on label, update preference Id
	const pref = getCameraPreference();
	if (manual || !pref || (pref.label === device.label && pref.id !== device.deviceId)) {
		setCameraPreference(device);
	}
};

const onError = (
	error: DOMException,
	manual: boolean,
	dispatch: <T extends IAction>(action: T) => T,
): void => {
	// show a toast when manually changing devices
	if (manual) {
		const messageToken =
			error.name === "NotAllowedError" ? "CameraSwitchFailed_Browser" : "CameraSwitchFailed";

		dispatch(
			alertActions.postToastAlert({
				type: "camera-switch-failed",
				messageToken: messageToken,
				dismissTimeMS: 0,
			}),
		);
	}
};

const onFinished = (manual: boolean, dispatch: <T extends IAction>(action: T) => T): void => {
	if (!manual) {
		dispatch(localTrackActions.setHasAutoSelectedVideo());
	}
};
