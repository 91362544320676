/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Wrapper component for post-authentication workflows
 * @author Will Cooper
 * @module Epic.VideoApp.Features.VideoCall.InnerCall
 */

import React, { FC, useContext } from "react";
import ConnectionManagementContext from "~/components/Chat/ConnectionManagement";
import LobbyHardwareTest from "~/components/HardwareTest/LobbyHardwareTest";
import VideoRoom from "~/components/VideoCall/VideoRoom";
import WebSocketConnection from "~/components/WebSocket/WebSocketConnection";
import { useActiveSession, useDisconnectHandler, useStoreHardwareTest } from "~/hooks";
import { useConnectionStatus } from "~/hooks/useConnectionStatus";
import { useJoinRoom } from "~/hooks/useJoinRoom";
import { useUserState } from "~/state";
import { useChatState } from "~/state/chat";
import {
	ParticipantDataProvider,
	ParticipantStatusProvider,
	VideoSessionContext,
} from "~/web-core/components";
/**
 * The InnerCall component
 * @param _props The props ;)
 */
const InnerCall: FC = (_props) => {
	const joinRoom = useJoinRoom();
	const { session } = useContext(VideoSessionContext);
	const connectionStatus = useConnectionStatus(session);
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const chatEnabled = useChatState((selectors) => selectors.getChatEnabled(), []);

	useDisconnectHandler();
	useStoreHardwareTest(true);
	// begins as soon as sessionID url param is set
	useActiveSession();

	if (session === undefined || connectionStatus === "not-connected") {
		return <LobbyHardwareTest onJoinClick={joinRoom} />;
	}

	return (
		<ParticipantDataProvider session={session}>
			<ParticipantStatusProvider session={session}>
				<WebSocketConnection>
					<ConnectionManagementContext shouldConnectToChat={!inWaitingRoom && chatEnabled}>
						<VideoRoom session={session} />
					</ConnectionManagementContext>
				</WebSocketConnection>
			</ParticipantStatusProvider>
		</ParticipantDataProvider>
	);
};

export default InnerCall;
