/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Implements virtual background initialization and management for Twilio
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.Helpers.TwilioBackgroundManager
 */

import { GaussianBlurBackgroundProcessor, VirtualBackgroundProcessor } from "@twilio/video-processors";
import { BackgroundProcessor } from "@twilio/video-processors/es5/processors/background/BackgroundProcessor";
import {
	BlurBackgroundOnLoad,
	ImageBackgroundOnLoad,
	VirtualBackgroundLoader,
} from "~/web-core/helpers/virtualBackgroundLoader";
import { BackgroundSettings } from "../../../../types/backgrounds";
import { defaultGaussianOptions, defaultImageOptions } from "../twilioSettings";

export class TwilioBackgroundManager extends VirtualBackgroundLoader {
	backgroundProcessorMap: Map<string, BackgroundProcessor>;

	constructor() {
		super();
		this.backgroundProcessorMap = new Map<string, BackgroundProcessor>();
	}

	/**
	 * Creates a twilio processor object for the blur background
	 * @param background Background metadata for the processor
	 * @returns true if the processor was able to initialize, false otherwise
	 */
	private blurProcessorInitialize: BlurBackgroundOnLoad = async (
		background: BackgroundSettings,
	): Promise<boolean> => {
		const processor = new GaussianBlurBackgroundProcessor(defaultGaussianOptions);
		try {
			await processor.loadModel();
			this.backgroundProcessorMap.set(background.path, processor);
			return true;
		} catch {
			return false;
		}
	};

	/**
	 * Creates a twilio processor object for the image background
	 * @param background Background metadata for the processor
	 * @param img HTML image element that just loaded
	 * @returns true if the processor was able to initialize, false otherwise
	 */
	private imageProcessorInitialize: ImageBackgroundOnLoad = async (
		background: BackgroundSettings,
		img: HTMLImageElement,
	): Promise<boolean> => {
		try {
			const processor = new VirtualBackgroundProcessor({
				backgroundImage: img,
				...defaultImageOptions,
			});
			await processor.loadModel();
			this.backgroundProcessorMap.set(background.path, processor);
			return true;
		} catch (error) {
			return false;
		}
	};

	/**
	 * Method that will validate image processors and initialize Twilio processor objects for all valid
	 * backgrounds. When done emit an event to the app layer to update the UI
	 * @param backgrounds
	 * @param availableBackgroundsCount
	 */
	async initializeBackgroundProcessors(
		backgrounds: BackgroundSettings[],
		availableBackgroundsCount: number,
	): Promise<void> {
		await this.validateProcessors(
			backgrounds,
			availableBackgroundsCount,
			this.blurProcessorInitialize,
			this.imageProcessorInitialize,
		);
	}
}
