/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to acquire and store the publications from a participant
 * @author Matt Panico and Colin Walters
 * @module Epic.VideoApp.Hooks.UsePublications
 */

import { useEffect, useState } from "react";
import { LocalTrackPublication, Participant, RemoteTrackPublication } from "twilio-video";

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

export function usePublications(participant: Participant | undefined): TrackPublication[] {
	const [publications, setPublications] = useState<TrackPublication[]>([]);

	useEffect(() => {
		if (!participant) {
			return;
		}
		setPublications(Array.from(participant.tracks.values()) as TrackPublication[]);

		// callback when there's a new publication
		const publicationAdded = (publication: TrackPublication): void => {
			setPublications((oldPublications) => [...oldPublications, publication]);
		};

		// callback when a publication is removed
		const publicationRemoved = (publication: TrackPublication): void => {
			setPublications((oldPublications) => oldPublications.filter((p) => p !== publication));
		};

		// subscribe to changes
		participant.on("trackPublished", publicationAdded);
		participant.on("trackUnpublished", publicationRemoved);

		return () => {
			participant.off("trackPublished", publicationAdded);
			participant.off("trackUnpublished", publicationRemoved);
		};
	}, [participant]);

	return publications;
}
