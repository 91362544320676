/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Standard Layout component
 * @author Erv Walter
 * @module Epic.VideoApp.Components.StandardLayout.StandardLayout
 */

import { Redirect, RouteComponentProps } from "@reach/router";
import React, { ComponentType, FC, Suspense } from "react";
import { getEndpointUrl } from "~/app/routes";
import { useStrings } from "~/hooks";
import { isSupportedBrowser } from "~/utils/browser";
import Background from "./Background";

/**
 * The props interface ;)
 */
interface IProps extends RouteComponentProps {
	/**
	 * The component to be rendered as content
	 */
	component: ComponentType<RouteComponentProps>;

	/**
	 * Whether or not the component requires WebRTC to function
	 */
	requiresWebRTC?: boolean;
}

/**
 * A Standard Layout component that wraps a content component with the application's standard UI shell (header, footer, etc)
 * @param props The props ;)
 */
const StandardLayout: FC<IProps> = (props) => {
	const { component: Component, requiresWebRTC, ...routeProps } = props;

	const tokenNames = ["Loading"];
	const strings = useStrings("StandardLayout", tokenNames);
	const Loading = <div>{strings["Loading"]}</div>;

	if (requiresWebRTC && !isSupportedBrowser()) {
		return <Redirect to={getEndpointUrl("/BrowserCompatibilityError")} />;
	}

	return (
		<>
			<Background />
			<Suspense fallback={Loading}>
				<Component {...routeProps} />
			</Suspense>
		</>
	);
};
StandardLayout.displayName = "StandardLayout";

export default StandardLayout;
