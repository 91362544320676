/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Remote User for Twilio Sessions
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.Implementations.TwilioRemoteUser
 */

import {
	NetworkQualityLevel,
	RemoteAudioTrack,
	RemoteParticipant,
	RemoteTrack,
	RemoteTrackPublication,
	RemoteVideoTrack,
} from "twilio-video";
import { ScreenShareAudioTrackName, ScreenShareTrackName } from "~/utils/screenSharing";
import { EVCEmitter, IEVCUserEventMap } from "~/web-core/events";
import { Quality, SubscriptionStatus } from "~/web-core/types";
import { IRemoteUser } from "../../../interfaces/remoteUser";
import { TwilioRemoteStream } from "./twilioRemoteStream";

export class TwilioRemoteUser extends EVCEmitter<IEVCUserEventMap> implements IRemoteUser {
	private static qualityMap: Record<Quality, "low" | "standard" | "high" | undefined> = {
		[Quality.undefined]: undefined,
		[Quality.low]: "low",
		[Quality.standard]: "standard",
		[Quality.high]: "high",
	};

	participant: RemoteParticipant;
	deviceStream: TwilioRemoteStream;
	shareStream: TwilioRemoteStream | null;
	isLocal: false = false;
	private _streamQuality: Quality = Quality.undefined;

	cleanupFunction?: () => void;
	publications: RemoteTrackPublication[];

	constructor(user: RemoteParticipant) {
		super();
		this.participant = user;
		this.publications = Array.from(user.tracks.values());
		for (const pub of this.publications) {
			this.setupPublicationListener(pub);
		}
		this.cleanupFunction = this.constructEmitterInterface();
		this.deviceStream = new TwilioRemoteStream(this.publications);
		this.shareStream = null;
	}

	getUserIdentity(): string {
		return this.participant.identity;
	}

	getUserGuid(): string {
		return this.participant.sid;
	}

	isSharingScreen(): boolean {
		return this.shareStream !== null;
	}

	getNetworkQualityLevel(): number {
		return this.participant.networkQualityLevel ?? -1;
	}

	setStreamQuality(quality: Quality): void {
		if (this._streamQuality === quality) {
			return;
		}
		const mappedQuality = TwilioRemoteUser.qualityMap[quality];
		if (!mappedQuality || !this.deviceStream.remoteVideoTrack) {
			return;
		}
		this.deviceStream.remoteVideoTrack?.setPriority(mappedQuality);
		this._streamQuality = quality;
	}

	// This method will not be implemented for Twilio
	setStreamSubscribed(_type: "camera" | "screen", _subscribed: SubscriptionStatus): void {
		return;
	}

	/**
	 * Constructs an interface layer to convert vendor-constructed events into shared events as defined by evcEvent
	 */
	private constructEmitterInterface(): () => void {
		this.participant?.on("networkQualityLevelChanged", (networkQualityLevel: NetworkQualityLevel) => {
			this.emit("networkQualityLevelChanged", {
				type: "networkQualityLevelChanged",
				newValue: networkQualityLevel,
			});
		});

		this.participant?.on("trackPublished", (publication: RemoteTrackPublication) => {
			this.setupPublicationListener(publication);
			this.publications.push(publication);
		});

		this.participant?.on("trackUnpublished", (publication: RemoteTrackPublication) => {
			this.publications = this.publications.filter((p) => p !== publication);
			this.clearPublicationListener(publication);
		});

		return () => {
			this.participant?.removeAllListeners("networkQualityLevelChanged");
			this.participant?.removeAllListeners("trackPublished");
			this.participant?.removeAllListeners("trackUnpublished");
		};
	}

	/**
	 * Sets up event listeners for a given remote track publication
	 * @param publication The remote track publication to listen to
	 */
	private setupPublicationListener(publication: RemoteTrackPublication): void {
		publication.on("subscribed", (track: RemoteTrack) => {
			if (track.kind === "data") {
				return;
			}

			if (track.name === ScreenShareTrackName || track.name === ScreenShareAudioTrackName) {
				return this.setupRemoteScreenShare(track);
			} else {
				this.deviceStream.updateTrack(track.kind, track);
				this.emit("participantUpdated", {
					type: "participantUpdated",
					participant: this,
					videoType: "camera",
				});
				return;
			}
		});

		publication.on("unsubscribed", (track: RemoteTrack) => {
			if (track.kind === "data") {
				return;
			}

			if (track.name === ScreenShareTrackName || track.name === ScreenShareAudioTrackName) {
				return this.removeRemoteScreenShare();
			} else {
				this.deviceStream.updateTrack(track.kind, null);
				this.emit("participantUpdated", {
					type: "participantUpdated",
					participant: this,
					videoType: "camera",
				});
				return;
			}
		});
	}

	/**
	 * Updates or creates the shared stream and emits events to indicate the update and start of screen share
	 * @param track The remote video or audio track to use to update the share stream
	 */
	private setupRemoteScreenShare(track: RemoteVideoTrack | RemoteAudioTrack): void {
		if (this.shareStream) {
			this.shareStream.updateTrack(track.kind, track);
		} else {
			this.shareStream = new TwilioRemoteStream([]);
			this.shareStream.updateTrack(track.kind, track);
		}
		this.emit("participantUpdated", {
			type: "participantUpdated",
			participant: this,
			videoType: "screen",
		});
		this.emit("screenShareStarted", { type: "screenShareStarted", participant: this });
	}

	/**
	 * Remove the remote screen share track and fire events indicating that screen share has stopped
	 */
	private removeRemoteScreenShare(): void {
		if (this.shareStream) {
			this.shareStream.updateTrack("audio", null);
			this.shareStream.updateTrack("video", null);
			this.shareStream = null;
		}
		this.emit("participantUpdated", {
			type: "participantUpdated",
			participant: this,
			videoType: "screen",
		});
		this.emit("screenShareStopped", { type: "screenShareStopped", participant: this });
	}

	/**
	 * Remove event listeners from the given publication
	 * @param publication The publication to remove listeners from
	 */
	private clearPublicationListener(publication: RemoteTrackPublication): void {
		publication.removeAllListeners("subscribed");
		publication.removeAllListeners("unsubscribed");
	}
}
