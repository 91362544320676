/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file component that injects branding CSS variables into the page's header
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Branding.BrandingStyleSheet
 */

import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useBrandingState } from "~/state";
import { getEVCColorsFromBrandedColors } from "~/utils/branding";

/**
 * The BrandingStyleSheet component
 */
const BrandingStyleSheet: FC = () => {
	const brandedColors = useBrandingState((sel) => sel.getColors(), []);
	const [colorCss, setColorCss] = useState<string>();

	const headEl = document.getElementsByTagName("head");

	useEffect(() => {
		if (brandedColors) {
			const allColors = getEVCColorsFromBrandedColors(brandedColors);
			setColorCss(generateColorCss(allColors));
		}
	}, [brandedColors]);

	return headEl.length && colorCss ? ReactDOM.createPortal(<style>{colorCss}</style>, headEl[0]) : null;
};

/**
 * Generate the branded stylesheet contents from branded colors
 * @param brandedColors map of all branded colors to be applied
 * @returns the branded colors converted into a stylesheet
 */
function generateColorCss(brandedColors: Record<string, string>): string {
	const css = Object.entries(brandedColors)
		.filter(([, value]) => !!value)
		.map(([key, value]) => `\t${key}: ${value};\r\n`)
		.reduce((concatenated, val) => concatenated + val, "");

	return css && `:root {\r\n${css}}\r\n`;
}

BrandingStyleSheet.displayName = "BrandingStyleSheet";

export default BrandingStyleSheet;
