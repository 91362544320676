/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file createStore function
 * @author Roland Scott
 * @module react-redux-booster\src\create-store
 */

import { createStore as createReduxStore, Store } from "redux";
import {
	ISharedState,
	IInteralStore,
	IHaveCombinedSelectorsMetadata,
	IHaveCombinedReducersMetadata,
} from "./internal/types";
import { IStore, IUseSharedStateHook, ActionCreatorCollection } from "./types";
import { addSharedState } from "./internal/store/add-shared-state";
import { addCombinedSelectors } from "./internal/store/add-combined-selectors";
import { addCombinedReducers } from "./internal/store/add-combined-reducers";
import { getStoreEnhancers } from "./internal/get-store-enhancers";

/**
 * Creates the application store. There can only be one store per application.
 * @returns The new store
 */
export function createStore(): IStore & Store {
	////////////////// CREATE THE REDUX STORE ///////////////////////

	const store = createReduxStore(() => ({}), undefined, getStoreEnhancers()) as IStore &
		IInteralStore &
		Store;

	////////////////// ADD BOOSTER FUNCTIONS ///////////////////////

	store._$_boosterInfo = {
		accessorCollection: {},
		accessorDependencies: {},
		reducerCollection: {},
		sliceInfoCollection: {},
		sliceKeyCollection: {},
	};

	store.addSharedState = (sharedState: ISharedState, displayName: string) => {
		const { _$_boosterInfo, replaceReducer, getState } = store;
		store._$_boosterInfo = addSharedState(
			_$_boosterInfo,
			sharedState,
			displayName,
			replaceReducer,
			getState,
		);
	};

	store.addCombinedReducers = (actionCreators: ActionCreatorCollection<any>, displayName: string = "") => {
		const { _$_boosterInfo, replaceReducer } = store;
		const { __$$crmd } = (actionCreators as any) as IHaveCombinedReducersMetadata;
		store._$_boosterInfo = addCombinedReducers(_$_boosterInfo, __$$crmd, displayName, replaceReducer);
	};

	store.addCombinedSelectors = (hook: IUseSharedStateHook<any>, displayName: string = "") => {
		const { _$_boosterInfo, getState } = store;
		const { __$$csmd } = (hook as any) as IHaveCombinedSelectorsMetadata;
		store._$_boosterInfo = addCombinedSelectors(_$_boosterInfo, __$$csmd, displayName, getState);
	};

	// Implement these in a subsequet wave. They are not on the critical path right now.
	store.removeCombinedReducers = undefined as any;
	store.removeSharedState = undefined as any;

	return store;
}
