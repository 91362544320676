/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file image capture pane save button and image storing web request
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.ImageCapture.Controls.SaveButton
 */
import React, { FC, RefObject, useCallback } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { useImageState, useRoomState } from "~/state";
import { ITelemedicineDocType } from "~/types";
import { stringFormat } from "~/utils/strings";
import { IValidatedDescription } from "../ImagePreviewPane";
import { useSaveImageToChart } from "../hooks/useSaveImageToChart";

/**
 * Props for SaveButton Component
 */
interface ISaveButtonProps {
	image: string;
	docType: ITelemedicineDocType | null;
	validatedDescription: IValidatedDescription;
	saveRef?: RefObject<HTMLButtonElement>;
}

enum TokenNames {
	label = "Label",
	labelNoPatient = "LabelNoPatient",
	accessibleLabel = "AccessibleLabel",
	accessibleLabelNoPatient = "AccessibleLabelNoPatient",
}

export enum SaveButtonTestIds {
	self = "SaveButton",
}

/**
 * The SaveButton component
 * @param props - the props
 */
const SaveButton: FC<ISaveButtonProps> = (props) => {
	const { image, docType, validatedDescription, saveRef } = props;
	const patientName = useRoomState((selectors) => selectors.getPatientName(), []);

	const strings = useStrings("SaveButton", Object.values(TokenNames));
	const saveToChart = useSaveImageToChart();
	const isSaving = useImageState((selectors) => selectors.getIsImageBeingSaved(), []);

	const label = patientName
		? stringFormat(strings[TokenNames.label], patientName)
		: strings[TokenNames.labelNoPatient];
	const ariaLabel = patientName
		? stringFormat(strings[TokenNames.accessibleLabel], patientName)
		: strings[TokenNames.accessibleLabelNoPatient];

	const save = useCallback(() => {
		// Error handling is in shared hook
		void saveToChart(image, docType?.id || "", validatedDescription.text);
	}, [saveToChart, image, docType, validatedDescription.text]);

	return (
		<ActionButton
			aria-label={ariaLabel}
			buttonRef={saveRef}
			onClick={save}
			keyboardShortcut={["alt", "a"]}
			text={label}
			priority="primary"
			tone="positive"
			forImagePreviewPane
			disabled={!validatedDescription.isValid || isSaving}
			data-testid={SaveButtonTestIds.self}
		/>
	);
};

SaveButton.displayName = "SaveButton";

export default SaveButton;
