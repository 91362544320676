/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file disabled mic icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.DisabledMic
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum DisabledMicIconTestIds {
	self = "DisabledMic",
}

/**
 * The DisabledMic component
 * @param props The props ;)
 */
const DisabledMic: FC<IconProps> = memo((props) => {
	return (
		<svg
			data-testid={DisabledMicIconTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
		>
			<path d="M15.76,22H16a4.49,4.49,0,0,0,4.5-4.5V11.17Z" />
			<path d="M16,4a4.49,4.49,0,0,0-4.5,4.5v9A4.51,4.51,0,0,0,12,19.61l6.5-14.83A4.4,4.4,0,0,0,16,4Z" />
			<path d="M11.5,28H10L20.5,4H22Z" />
			<path d="M10.19,16H5.5v1.5h3a7.5,7.5,0,0,0,2.16,5.26l.66-1.51A6,6,0,0,1,10,17.5,5.71,5.71,0,0,1,10.19,16Zm13.16,0H21.8a6.17,6.17,0,0,1,.2,1.5,6,6,0,0,1-6,6,6.15,6.15,0,0,1-.87-.06L14.5,24.9V28h3V24.85a7.5,7.5,0,0,0,6-7.35h3V16Z" />
		</svg>
	);
});

DisabledMic.displayName = "DisabledMic";

export default DisabledMic;
