/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Component to hold questions for surveys
 * @author Max Harkins
 * @module Epic.VideoApp.Components.Utilities.Survey.QuestionsPage
 */

import React, { FC } from "react";
import { IQuestion, IQuestionGroup, isQuestionGroup, ISurveyResponses } from "~/types/survey";
import QuestionGroup from "../Survey/QuestionGroup";
import { useSurveyStrings } from "./hooks/useSurveyStrings";
import Question from "./Question";
import styles from "./QuestionsPage.module.scss";

/**
 * Props for QuestionsPage Component
 */
interface IProps {
	questions: (IQuestion | IQuestionGroup)[];
	currentlySelected: ISurveyResponses;
	previouslySelected: ISurveyResponses;
	handleChange: (question: IQuestion) => void;
}

/**
 * The QuestionsPage component
 */
const QuestionsPage: FC<IProps> = (props) => {
	const { questions, currentlySelected, previouslySelected, handleChange } = props;
	const strings = useSurveyStrings(questions);

	return (
		<ul className={styles["surveyQuestions"]}>
			{questions.map((questionOrGroup) =>
				isQuestionGroup(questionOrGroup) ? (
					!questionOrGroup.dependsOnQuestion ||
					previouslySelected[questionOrGroup.dependsOnQuestion] ? (
						<li key={questionOrGroup.id} className={styles["groupListItem"]}>
							<QuestionGroup
								key={questionOrGroup.id}
								group={questionOrGroup}
								handleInputChange={handleChange}
								currentlySelected={currentlySelected}
								className={styles["questionGroup"]}
								strings={strings}
							/>
						</li>
					) : null
				) : (
					<li key={questionOrGroup.id}>
						<Question
							key={questionOrGroup.id}
							question={questionOrGroup}
							handleInputChange={handleChange}
							isChecked={currentlySelected[questionOrGroup.id]}
							name={strings[questionOrGroup.name]}
						/>
					</li>
				),
			)}
		</ul>
	);
};

QuestionsPage.displayName = "QuestionsPage";

export default QuestionsPage;
