/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file buildCombinedSelectors function
 * @author Roland Scott
 * @module react-redux-booster\src\build-combined-selectors
 */

import { ISelectorCollection, IUseSharedStateHook } from "./types";
import { IHaveCombinedSelectorsMetadata } from "./internal/types";
import { getNextId } from "./internal/get-next-id";
import { buildSelectorHook } from "./internal/build-selector-hook";

/**
 * Build a custom React hook that uses a group of combined selectors (selectors which have access to multiple
 * pieces of shared state).
 *
 * When used inside a React component, the hook takes a callback that will be given your accessor functions as
 * an argument, and is expected to return the value you are selecting.
 *
 * @param selectors The selector functions to be included in the hook.
 * @returns a custom React hook that uses a group of combined selectors (selectors which have access to multiple
 * pieces of shared state).
 */
export function buildCombinedSelectors<TSelectors extends ISelectorCollection<any>>(
	selectors: TSelectors,
): IUseSharedStateHook<TSelectors> {
	const id = getNextId();
	const retVal = buildSelectorHook(id);
	// TODO - make sure __$$csmd doesn't show up in the final accessors collection
	((retVal as any) as IHaveCombinedSelectorsMetadata).__$$csmd = { id, selectors };
	return retVal;
}
