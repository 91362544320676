/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file pin icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Pin
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum PinTestIds {
	self = "Pin",
}

/**
 * The Pin component
 * @param props The props ;)
 */
const Pin: FC<IconProps> = memo((props) => {
	return (
		<svg
			data-testid={PinTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 160 160"
			fill="#ffffff"
			{...props}
		>
			<g transform="translate(5)">
				<path d="M120,100L90,70V40h10c6,0,10-4,10-10s-4-10-10-10H50c-6,0-10,4-10,10s4,10,10,10h10v30l-30,30h40v40h10v-40H120z" />
			</g>
		</svg>
	);
});

Pin.displayName = "Pin";

export default Pin;
