/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Reusable component to render standard functionality that should be present in most features, but needs to be rendered by them not their parents.
 * @author Will Cooper
 * @module Epic.VideoApp.Components.StandardLayout.FeatureWrapper
 */

import React, { FC } from "react";
import Alerts from "../Alerts/Alerts";
import ScreenShareContext from "../ScreenShareContext";

/**
 * The FeatureWrapper component
 * @param props The props
 */
const FeatureWrapper: FC = (props) => {
	const { children } = props;

	return (
		<ScreenShareContext>
			<Alerts />
			{children}
		</ScreenShareContext>
	);
};
FeatureWrapper.displayName = "FeatureWrapper";

export default FeatureWrapper;
