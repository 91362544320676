/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Spinner component
 * @author Matt Panico
 * @module Epic.VideoApp.Components.Loading.Spinner
 */

import React, { FC } from "react";
import { useStrings } from "~/hooks";
import styles from "./Spinner.module.scss";

export enum SpinnerTestIds {
	self = "Spinner",
}

/**
 * The Spinner component
 */
const Spinner: FC = () => {
	const tokenNames = ["Loading"];
	const strings = useStrings("Spinner", tokenNames);

	return (
		<span data-testid={SpinnerTestIds.self} className={styles["spinner"]}>
			{strings["Spinner"]}
		</span>
	);
};

Spinner.displayName = "Spinner";

export default Spinner;
