/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file buildSharedState function
 * @author Roland Scott
 * @module react-redux-booster\src\build-shared-state
 */

import { buildActionCreators } from "./internal/build-action-creators";
import {
	InferActionCreatorsFromReducers,
	IReducerCollection,
	ISelectorCollection,
	IStateConfig,
	IUseSharedStateHook,
} from "./types";
import { createSharedState } from "./internal/create-shared-state";
import { buildSelectorHook } from "./internal/build-selector-hook";

/**
 * The shape of a shared state piece.
 */
export interface ISharedState<
	TState,
	TReducers extends IReducerCollection<TState>,
	TSelectors extends ISelectorCollection<TState>
> {
	/**
	 * The action creator functions for this shared state, to be used when dispatching actions for this state.
	 */
	actionCreators: InferActionCreatorsFromReducers<TReducers>;
	/**
	 * The shared state object. THIS IS NOT THE DATA. YOUR DATA IS ACCESSED VIA SELECTORS, NOT VIA THIS OBJECT.
	 */
	sharedState: TState;
	/**
	 * A custom React hook to get access to this state's selectors.
	 */
	useSharedState: IUseSharedStateHook<TSelectors>;
}

/**
 * Build a piece of shared state to be added to the store.
 * @param config The configuration for this state piece.
 * @returns An object which contains your action creators, a custom React hook to get read access to
 * the state, and a sharedState object required by other pieces of the API.
 */
export function buildSharedState<
	TState,
	TReducers extends IReducerCollection<TState>,
	TSelectors extends ISelectorCollection<TState>
>(config: IStateConfig<TState, TReducers, TSelectors>): ISharedState<TState, TReducers, TSelectors> {
	const sharedState = createSharedState(config.init, config.reducers, config.selectors);
	return {
		actionCreators: buildActionCreators<TState, TReducers>(sharedState.id, config.reducers),
		sharedState,
		useSharedState: buildSelectorHook(sharedState.id),
	};
}
