/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Context for creating a single web socket
 * @author Noah Allen
 * @module Epic.VideoApp.Components.WebSocket.WebSocketConnection
 */

import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { IOutboundCloudEvent, ISocketActions, ISocketMessage, IWebSocketContext } from "~/types/websockets";
import { createCloudEvent, socketIsOpenAndReady } from "./helpers/webSocketHelpers";

interface IProps {
	children: React.ReactChild;
}

// The context for managing WebSocket connections.
export const WebSocketContext = React.createContext<IWebSocketContext>({
	openSocket: () => false,
	sendSocketMessage: () => Promise.resolve(),
	closeSocket: () => Promise.resolve(),
	isWebsocketOpen: () => false,
});

// This module exports a React component that provides a WebSocket connection context.
const WebSocketConnection: FC<PropsWithChildren<IProps>> = (props) => {
	const { children } = props;
	const [socketMessageQueue, adjustQueue] = useState<ISocketMessage[]>([]);
	const connectionRef = useRef<WebSocket>();

	// A function to open a WebSocket connection.
	const openSocket = useCallback((connectionURL: string, socketActions: ISocketActions): boolean => {
		if (connectionRef.current) {
			connectionRef.current = undefined;
		}

		if (!connectionURL) {
			return false;
		}

		try {
			const socket = new WebSocket(connectionURL);
			const {
				socketOnOpen: open,
				socketOnError: error,
				socketOnClose: close,
				socketOnMessage: message,
			} = socketActions;

			socket.onopen = (event: WebSocketEventMap["open"]) => {
				open(event);
			};
			socket.onclose = (event: WebSocketEventMap["close"]) => {
				close(event);
				connectionRef.current = undefined;
			};
			socket.onerror = (event: WebSocketEventMap["error"]) => {
				error(event);
				connectionRef.current = undefined;
			};
			socket.onmessage = (event: WebSocketEventMap["message"]) => {
				message(event);
			};
			connectionRef.current = socket;
		} catch (exception) {
			console.warn("WebSocket exception: ", exception);
			connectionRef.current = undefined;
			return false;
		}
		return true;
	}, []);

	// A function to close the current WebSocket connection.
	const closeSocket = useCallback(() => {
		connectionRef.current?.close();
	}, []);

	// A function to remove a message from the queue of messages to be sent.
	const removeMessageFromQueue = useCallback((messageId: string) => {
		adjustQueue((previousQueue: ISocketMessage[]) => {
			return previousQueue.filter((currentMessage) => {
				return currentMessage.messageId !== messageId;
			});
		});
	}, []);

	// A function to send a message over the WebSocket connection.
	const sendSocketMessage = useCallback((message: ISocketMessage): void => {
		adjustQueue((previousQueue: ISocketMessage[]) => {
			return [...previousQueue, message];
		});
	}, []);

	const isWebsocketOpen = useCallback(() => {
		return connectionRef.current?.readyState === WebSocket.OPEN;
	}, []);

	// A React effect that sends messages from the queue when the WebSocket connection is open and ready.
	useEffect(() => {
		if (socketMessageQueue.length < 1) {
			return;
		}

		if (!connectionRef.current || !socketIsOpenAndReady(connectionRef.current)) {
			return;
		}

		const socketMessage: ISocketMessage = socketMessageQueue[0];

		const cloudEvent: IOutboundCloudEvent = createCloudEvent(
			socketMessage.messageId,
			socketMessage.messageType,
			socketMessage.version,
			socketMessage.requiresAck,
			socketMessage.data,
		);

		connectionRef.current.send(JSON.stringify(cloudEvent));
		removeMessageFromQueue(socketMessage.messageId);
	}, [removeMessageFromQueue, socketMessageQueue]);

	return (
		<WebSocketContext.Provider value={{ sendSocketMessage, openSocket, closeSocket, isWebsocketOpen }}>
			{children}
		</WebSocketContext.Provider>
	);
};

export default WebSocketConnection;
