/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file network quality icon (fully filled with color)
 * @author Will Cooper
 * @module Epic.VideoApp.Icons.NetworkQuality
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * Props for QualityIcon Component
 */
interface IProps extends IconProps {
	/** The network quality level (0-4) */
	level: number;
	/** The color of any levels (the bars up to the current level)*/
	fillColor: string;
	/** The background color of the icon (the bars that are beneath full, above the current level)*/
	baseColor: string;
}

/**
 * The NetworkQuality component
 * @param props The props ;)
 */
const NetworkQuality: FC<IProps> = memo((props) => {
	const { level, fillColor, baseColor, ...iconProps } = props;

	// Apple VoiceOver will only focus the individual bars in the icon, so to avoid
	// repetitive reading all but the last bar is hidden from screen readers
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160" {...iconProps}>
			<rect y="110" width="30" height="40" aria-hidden fill={level > 0 ? fillColor : baseColor} />
			<rect x="40" y="80" width="30" height="70" aria-hidden fill={level > 1 ? fillColor : baseColor} />
			<rect
				x="80"
				y="50"
				width="30"
				height="100"
				aria-hidden
				fill={level > 2 ? fillColor : baseColor}
			/>
			<rect
				x="120"
				y="20"
				width="30"
				height="130"
				aria-hidden
				fill={level > 3 ? fillColor : baseColor}
			/>
		</svg>
	);
});

NetworkQuality.displayName = "NetworkQuality";

export default NetworkQuality;
