/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Image Capture Description Input
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.ImageCapture.Controls.DescriptionInput
 */

import React, { FC, useCallback, useState } from "react";
import TextInput from "~/components/Utilities/TextInput";
import { useStrings } from "~/hooks";
import { useSettingsState } from "~/state";
import { ENABLED_LOCALES } from "~/types/locale";
import { stringFormat } from "~/utils/strings";
import { getUnsupportedCharacters, sanitizeInput } from "~/utils/text";
import { DescriptionMaxLength } from "../../../types";
import { IValidatedDescription } from "../ImagePreviewPane";
import styles from "./DescriptionInput.module.scss";

/**
 * Props for DescriptionInput Component
 */
interface IProps {
	setValidatedDescription: (validatedDescription: IValidatedDescription) => void;
}

enum TokenNames {
	charactersWarning = "AllowedCharactersWarning",
	descriptionHelpText = "DescriptionHelpText",
	descriptionLength = "DescriptionLength",
}

export enum DescriptionInputTestIds {
	disabledLocaleWarning = "DisabledLocaleWarning",
}

/**
 * The DescriptionInput component
 * @param props The props
 */
const DescriptionInput: FC<IProps> = (props) => {
	const { setValidatedDescription } = props;
	const locale = useSettingsState((selectors) => selectors.getLocale(), []);
	const strings = useStrings("DescriptionInput", Object.values(TokenNames));
	const [invalidCharacters, setInvalidCharacters] = useState<string>("");
	const [descriptionLength, setDescriptionLength] = useState(0);

	// Keep track of the input on every keystroke
	const updateInput = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			// Escape markup, but don't remove unsupported characters.
			// Unsupported characters will be displayed in a warning message.
			const currentInput = sanitizeInput(event.target.value, DescriptionMaxLength);
			event.target.value = currentInput;
			const unsupported = getUnsupportedCharacters(currentInput);
			setInvalidCharacters(unsupported);
			setValidatedDescription({ text: currentInput.trim(), isValid: !unsupported });
			setDescriptionLength(currentInput.length);
		},
		[setValidatedDescription],
	);

	const disabledLocale = ENABLED_LOCALES[locale]?.isWin1252Incompatible;

	return (
		<>
			{disabledLocale && (
				<div
					className={styles["descriptionHelpText"]}
					data-testid={DescriptionInputTestIds.disabledLocaleWarning}
				>
					{strings[TokenNames.charactersWarning]}
				</div>
			)}
			<TextInput
				id="descriptionInput"
				invalidCharacters={invalidCharacters}
				onChange={updateInput}
				placeholder={strings[TokenNames.descriptionHelpText]}
			/>
			<div className={styles["descriptionHelpText"]}>
				{stringFormat(
					strings[TokenNames.descriptionLength],
					DescriptionMaxLength - descriptionLength,
				)}
			</div>
		</>
	);
};

DescriptionInput.displayName = "DescriptionInput";

export default DescriptionInput;
