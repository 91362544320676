/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file shared state related to the user (feature permissions, preferences, status, etc)
 * @author Steven Anderson & Will Cooper
 * @module Epic.VideoApp.State.User
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { IModeratorPayload } from "~/types";
import { DefaultImageNames } from "~/types/backgrounds";
import {
	EpicUserType,
	IBackgroundAccess,
	IUserGuardOptions,
	IUserPermissions,
	IUserPreferences,
} from "~/types/user";
import { saveUserPreferences } from "~/utils/userPreferences";

/// TYPES ///
interface IRequestTracker {
	audio: boolean;
	video: boolean;
	screenShare: boolean;
}

export interface IUserState {
	userKey: string;
	userPermissions: IUserPermissions;
	userType: EpicUserType;
	microphoneLock: boolean;
	cameraLock: boolean;
	screenShareLock: boolean;
	inWaitingRoom: boolean;
	preferences: IUserPreferences;
	deviceRequestStatus: IRequestTracker;
	isDemoSystem: boolean;
	guardOptions: IUserGuardOptions;
	hideGuardRails: boolean;
	guardDismissalTime: number;
}

/// INIT ///

export function getInitialState(): IUserState {
	return {
		userKey: "",
		preferences: {
			encryptedDisplayName: null,
			preferredLocale: null,
			lastBackgroundProcessor: DefaultImageNames.none,
		},
		userType: EpicUserType.unknown,
		microphoneLock: false,
		cameraLock: false,
		screenShareLock: false,
		inWaitingRoom: false,
		userPermissions: {
			canCaptureImage: false,
			canModerateVisit: false,
			canShareScreen: false,
			canStartEndVisit: false,
			canNotSetDisplayName: false,
			canAccessBlurring: true,
			canAccessEpicBackgrounds: false,
			canTestSpeaker: true,
			disableAllBackgrounds: false,
		},
		deviceRequestStatus: { audio: false, video: false, screenShare: false },
		isDemoSystem: false,
		guardOptions: {
			dismissedScreenShareWarning: false,
			skipHardwareTest: false,
		},
		hideGuardRails: false,
		guardDismissalTime: 0,
	};
}

/// REDUCERS ///

function setUserKey(state: IUserState, userKey: string): IUserState {
	return { ...state, userKey };
}

function setPreferences(state: IUserState, newPreferences: IUserPreferences | null): IUserState {
	if (!newPreferences) {
		return { ...getInitialState(), userKey: state.userKey };
	}

	// update the value in localStorage
	saveUserPreferences(state.userKey, newPreferences);

	return { ...state, preferences: newPreferences };
}

function setEncryptedDisplayName(state: IUserState, encryptedDisplayName: string | null): IUserState {
	return setPreferences(state, { ...state.preferences, encryptedDisplayName });
}

export function setPreferredLocale(state: IUserState, preferredLocale: string | null): IUserState {
	return setPreferences(state, { ...state.preferences, preferredLocale });
}

export function setUserPermission(state: IUserState, newPermissions: IUserPermissions): IUserState {
	const newState = {
		...state,
		userPermissions: {
			...state.userPermissions, // If we do not have a certain property in the newPermissions object, pull other parameters from existing user permissions object
			...newPermissions,
		},
	};
	return newState;
}

export function setUserType(state: IUserState, userType: EpicUserType): IUserState {
	return { ...state, userType };
}

export function setUserWaitingRoomStatus(state: IUserState, value: boolean): IUserState {
	return { ...state, inWaitingRoom: value };
}

export function setDisconnectedState(state: IUserState): IUserState {
	const newState = getInitialState();
	newState.userPermissions = state.userPermissions;
	newState.userType = state.userType;
	return newState;
}

function setLockState(state: IUserState, params: IModeratorPayload): IUserState {
	const { audioLock, videoLock, screenShareLock } = params;
	return {
		...state,
		microphoneLock: audioLock ?? state.microphoneLock,
		cameraLock: videoLock ?? state.cameraLock,
		screenShareLock: screenShareLock ?? state.screenShareLock,
	};
}

function setRequestTimeout(state: IUserState, value: IRequestTracker): IUserState {
	return { ...state, deviceRequestStatus: value };
}

function setUserActiveInCall(state: IUserState, params: IModeratorPayload): IUserState {
	const { audioLock, videoLock, screenShareLock } = params;

	return {
		...state,
		inWaitingRoom: false,
		microphoneLock: audioLock ?? state.microphoneLock,
		cameraLock: videoLock ?? state.cameraLock,
		screenShareLock: screenShareLock ?? state.screenShareLock,
	};
}

export function setIsDemoSystem(state: IUserState, flag: boolean): IUserState {
	return { ...state, isDemoSystem: flag };
}

export function setHideGuardrails(state: IUserState, flag: boolean): IUserState {
	return { ...state, hideGuardRails: flag };
}

export function setGuardDismissalTime(state: IUserState, time: number): IUserState {
	return { ...state, guardDismissalTime: time };
}

export function setGuardOptions(state: IUserState, newGuardOptions: IUserGuardOptions): IUserState {
	return { ...state, guardOptions: newGuardOptions };
}

export function setDismissedScreenShareWarning(state: IUserState, flag: boolean): IUserState {
	return setGuardOptions(state, { ...state.guardOptions, dismissedScreenShareWarning: flag });
}

/// SELECTORS ///

function getUserKey(state: IUserState): string {
	return state.userKey ?? "";
}

function getPreferences(state: IUserState): IUserPreferences {
	return state.preferences;
}

function getEncryptedDisplayName(state: IUserState): string | null {
	return state.preferences.encryptedDisplayName;
}

function getLastBackgroundProcessor(state: IUserState): string {
	return state.preferences.lastBackgroundProcessor;
}

function getUserPermission<T extends keyof IUserPermissions>(
	state: IUserState,
	permission: T,
): IUserPermissions[T] {
	// if for any reason any of this loaded improperly, return all permissions as false
	return state.userPermissions[permission] || false;
}

// Allows dynamically checking permissions (through useCheckUserPermissions hook)
function getAllUserPermissions(state: IUserState): IUserPermissions {
	return state.userPermissions;
}

function getUserType(state: IUserState): EpicUserType {
	return state.userType;
}

function getModerationLocks(state: IUserState): { audio: boolean; video: boolean; screenShare: boolean } {
	return {
		audio: state.microphoneLock,
		video: state.cameraLock,
		screenShare: state.screenShareLock,
	};
}

function getCameraLock(state: IUserState): boolean {
	return state.cameraLock;
}

function getMicLock(state: IUserState): boolean {
	return state.microphoneLock;
}

function getScreenShareLock(state: IUserState): boolean {
	return state.screenShareLock;
}

function getBackgroundAccess(state: IUserState): IBackgroundAccess {
	if (state.userPermissions.disableAllBackgrounds) {
		return {
			blurAccess: false,
			epicBackgroundsAccess: false,
		};
	}

	return {
		blurAccess: state.userPermissions.canAccessBlurring,
		epicBackgroundsAccess: state.userPermissions.canAccessEpicBackgrounds,
	};
}

function getPreferredLocale(state: IUserState): string | null {
	return state.preferences.preferredLocale;
}

function getIsUserInWaitingRoom(state: IUserState): boolean {
	return state.inWaitingRoom;
}

function getDeviceRequestStatus(state: IUserState): IRequestTracker {
	return state.deviceRequestStatus;
}

function getIsDemoSystem(state: IUserState): boolean {
	return state.isDemoSystem;
}

function getHideGuardrails(state: IUserState): boolean {
	return state.hideGuardRails;
}

function getGuardDismissalTime(state: IUserState): number {
	return state.guardDismissalTime;
}

function getGuardOptions(state: IUserState): IUserGuardOptions {
	return state.guardOptions;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	selectors: {
		getUserKey,
		getPreferences,
		getEncryptedDisplayName,
		getUserPermission,
		getAllUserPermissions,
		getModerationLocks,
		getUserType,
		getMicLock,
		getCameraLock,
		getScreenShareLock,
		getPreferredLocale,
		getIsUserInWaitingRoom,
		getLastBackgroundProcessor,
		getBackgroundAccess,
		getDeviceRequestStatus,
		getIsDemoSystem,
		getHideGuardrails,
		getGuardDismissalTime,
		getGuardOptions,
	},
	reducers: {
		setUserKey,
		/**
		 * Updates preferences shared state with new user preferences.
		 *
		 * SIDE-EFFECT: Saves the updated userPreferences to localStorage.
		 *
		 * @param userPreferences the preferences to update into state.
		 *
		 * @returns New state object to be dispatched to the store.
		 * */
		setPreferences,
		setEncryptedDisplayName,
		setUserPermission,
		setUserType,
		setLockState,
		setUserWaitingRoomStatus,
		setRequestTimeout,
		setUserActiveInCall,
		setIsDemoSystem,
		setHideGuardrails,
		setGuardDismissalTime,
		setGuardOptions,
		setDismissedScreenShareWarning,
	},
});
store.addSharedState(builtState.sharedState, "user");

export const { actionCreators: userActions, useSharedState: useUserState, sharedState: state } = builtState;
