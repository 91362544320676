/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Combined State for Image Capture actions
 * @author Will Cooper
 * @module Epic.VideoApp.State.Combined.ImageCapture
 */

import { withSharedStates } from "@epic/react-redux-booster";
import { IParticipantVideo, Toast } from "~/types";
import * as alerts from "../alerts";
import * as image from "../imageCapture";
import * as ui from "../ui";

/// COMBINED STATE TYPES ///

type UIActionState = [ui.IUIState, alerts.IAlertState, image.IImageCaptureState];

/// COMBINED REDUCERS ///

interface IImageCaptureActiveParams {
	/** Identifier of new potential pin source */
	newPin: IParticipantVideo | null;
}

/**
 * Set ImageCapture state active. Control toasts
 * @param state shared state prior to this action
 * @param params new data to set for pinning / toasts
 * @returns the new shared state after this action is applied
 */
function setImageCaptureActive(state: UIActionState, params: IImageCaptureActiveParams): UIActionState {
	const [prevUIState, prevAlertState, prevImageState] = state;

	let newUIState = ui.setImCapHovered(prevUIState, true);

	// Pin the video on-hover / on-click if they are is not already
	if (!prevUIState.pinningState.pinnedVideo && params.newPin) {
		newUIState = ui.toggleVideoPinned(newUIState, {
			pinnedVideo: params.newPin,
			updateSource: "ImageCapture",
		});
	}

	const newAlertState = alerts.postToastAlert(prevAlertState, getImageCaptureReadyToast());

	return [newUIState, newAlertState, prevImageState];
}

/**
 * Clear ImageCapture state active. Remove toasts and image capture highlight
 * @param state shared state prior to this action
 * @returns the new shared state after this action is applied
 */
function clearImageCaptureActive(state: UIActionState): UIActionState {
	const [prevUIState, prevAlertState, prevImageState] = state;

	let newUIState = ui.setImCapHovered(prevUIState, false);

	// If we pinned from activating the image capture button, release the pin here
	if (prevUIState.pinningState.updateSource === "ImageCapture") {
		newUIState = ui.toggleVideoPinned(newUIState, null);
	}

	const newAlertState = alerts.clearToasts(prevAlertState, "image-capture-ready");

	return [newUIState, newAlertState, prevImageState];
}

function clearImageCaptureAlert(state: UIActionState): UIActionState {
	const [prevUIState, prevAlertState, prevImageState] = state;

	const newAlertState = alerts.clearAlert(prevAlertState);
	const newImageState = image.setImageData(prevImageState, null);

	return [prevUIState, newAlertState, newImageState];
}

/**
 * Helper function to get a remote screen sharing toast
 * @returns image capture ready toast
 */
function getImageCaptureReadyToast(): Toast {
	const messageToken = "ImageCaptureReady";
	return {
		type: "image-capture-ready",
		messageToken,
	};
}

/// BUILD IT ///

export const imageCaptureCombinedReducers = {
	setImageCaptureActive: withSharedStates(ui.state, alerts.state, image.state).buildReducer(
		setImageCaptureActive,
	),
	clearImageCaptureActive: withSharedStates(ui.state, alerts.state, image.state).buildReducer(
		clearImageCaptureActive,
	),
	clearImageCaptureAlert: withSharedStates(ui.state, alerts.state, image.state).buildReducer(
		clearImageCaptureAlert,
	),
};
