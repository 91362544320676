/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file mergeNextSlice function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\combine-reducers\merge-next-slice
 */

import { IWholeState, ISliceKeyCollection } from "../types";

/**
 * Create a new state object, shallow copy it, and update the given slice(s).
 * @param state The entire state object
 * @param nextSlice The next slice or array of slices
 * @param sliceId The ID of the slice (if it's a single slice)
 * @param sliceIds Array of slice IDs (if it's an array of slices)
 * @returns The new state object.
 */
export function mergeNextSlice<TState extends IWholeState, TSlice>(
	state: TState,
	nextSlice: TSlice,
	sliceKeys: ISliceKeyCollection,
	sliceId: number,
	sliceIds?: number[],
): TState {
	const nextState = { ...state } as any;
	if (sliceIds) {
		if (!Array.isArray(nextSlice)) {
			throw Error("Slice is not an array, but multiple slide IDs are given");
		}
		sliceIds.map((id, i) => (nextState[sliceKeys[id]] = nextSlice[i]));
	} else {
		nextState[sliceKeys[sliceId]] = nextSlice;
	}
	return nextState;
}
