/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file hook to add a keyboard shortcut
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseKeyboardShortcut
 */

import { useCallback, useEffect, useRef } from "react";
import { useAlertState, useImageState } from "~/state";
import { detectOS, OS } from "~/utils/os";

const MODIFIER_KEYS = ["alt", "control", "shift"];

/**
 * Determine if all of the modifier keys necessary are pressed in the triggered keyboard event
 * @param modifiers modifier keys involved in the current shortcut
 * @param event keyboard event
 * @returns true if all necessary modifier keys are pressed, false otherwise
 */
function allRequiredModifiersPressed(modifiers: string[], event: KeyboardEvent): boolean {
	if (modifiers.includes("alt") && !event.altKey) {
		return false;
	}
	if (modifiers.includes("control") && !event.ctrlKey) {
		return false;
	}
	if (modifiers.includes("shift") && !event.shiftKey) {
		return false;
	}
	return true;
}

/**
 * Determine if the keyboard event is for the target key
 * @param event the keyboard event
 * @param key the key that is being listened for
 * @returns true if the keyboard event is for the specified key, false otherwise
 */
function isTargetKey(event: KeyboardEvent, key: string): boolean {
	// macOS translates Alt/Option + character as a special character, so use the key's code instead
	// we use key otherwise because code assumes keyboard layout is standard "QWERTY" (https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code)
	return detectOS() === OS.mac ? event.code.toLowerCase() === `key${key}` : event.key.toLowerCase() === key;
}

/**
 * Options that can be provided when creating a keyboard shortcut
 */
interface IShortcutOptions {
	/** Whether or not the shortcut should be available when a modal alert is present */
	forModalAlert?: boolean;

	/** Whether or not the shortcut should be available when the image preview pane is present */
	forImagePreviewPane?: boolean;
}

/**
 * Hook to add a keyboard shortcut to a page/component
 * @param shortcutKeys array of the keys that create the shortcut
 * @param callback callback to invoke when the shortcut is used
 * @param options flags to determine when the keyboard shortcut should be available
 */
export const useKeyboardShortcut = (
	shortcutKeys: string[],
	callback: () => void | Promise<void>,
	options: IShortcutOptions = {},
): void => {
	const { forModalAlert, forImagePreviewPane } = options;

	// determine if any alerts or image preview modals are present
	const hasAlert = !!useAlertState((selectors) => selectors.getCurrentAlert(), []);
	const hasImagePreview = !!useImageState((selectors) => selectors.getImageData(), []);

	// split the shortcut keys into modifier keys and the single non-modifier key
	const modifierKeys = useRef<string[]>();
	const shortcutKey = useRef<string>();
	useEffect(() => {
		modifierKeys.current = shortcutKeys.filter((key) => MODIFIER_KEYS.includes(key));
		const [nonModifierKey] = shortcutKeys.filter((key) => !MODIFIER_KEYS.includes(key));

		if (nonModifierKey) {
			shortcutKey.current = nonModifierKey.toLowerCase();
		} else {
			console.error(`Failed to attach keyboard shortcut (${shortcutKeys.join("+")})`);
		}
	}, [shortcutKeys]);

	// if callback, hasAlert, or hasImagePreview can change when the callback is invoked, making them
	// a dependency of the onKeydown callback will result in invoking the callback repeatedly on long
	// press, tracking them as refs allows us to keep an up to date value without the dependency
	const callbackRef = useRef<() => void>(callback);
	const hasAlertRef = useRef<boolean>(hasAlert);
	const hasImagePreviewRef = useRef<boolean>(hasImagePreview);
	useEffect(() => {
		callbackRef.current = callback;
		hasAlertRef.current = hasAlert;
		hasImagePreviewRef.current = hasImagePreview;
	}, [callback, hasAlert, hasImagePreview]);

	// callback for when a keypress is started
	const onKeydown = useCallback(
		(event: KeyboardEvent) => {
			// if disabled or the shortcut hasn't been parsed yet, quit
			if (!modifierKeys.current || !shortcutKey.current) {
				return;
			}

			// if there is a modal (alert or image capture) and the shortcut isn't for the modal, quit
			if (
				(hasAlertRef.current && !forModalAlert) ||
				(!hasAlertRef.current && hasImagePreviewRef.current && !forImagePreviewPane)
			) {
				return;
			}

			// if the keydown isn't a long press and the shortcut is satisfied, trigger the callback
			if (
				!event.repeat &&
				allRequiredModifiersPressed(modifierKeys.current, event) &&
				isTargetKey(event, shortcutKey.current)
			) {
				callbackRef.current();
			}
		},
		[forModalAlert, forImagePreviewPane],
	);

	useEffect(() => {
		// attach the keydown event listener
		window.addEventListener("keydown", onKeydown, true);
		return () => window.removeEventListener("keydown", onKeydown, true);
	}, [onKeydown]);
};
