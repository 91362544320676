/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file row in the participant list
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Participants.ParticipantList.ParticipantRow
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import Chevron from "~/icons/chevron";
import Pin from "~/icons/pin";
import { uiActions, useModerationState, useRoomState, useUIState, useUserState } from "~/state";
import { IStreamStatus } from "~/types";
import { resolveClassName } from "~/utils/className";
import { isMobile } from "~/utils/os";
import { SharedStringTokens, stringFormat } from "~/utils/strings";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { IUser } from "~/web-core/interfaces";
import ParticipantIcons from "./ParticipantIcons";
import styles from "./ParticipantList.module.scss";

/**
 * Props for ParticipantRow Component
 */
interface IProps {
	/** Participant to show in the row */
	participant: IUser;

	/** Participant's display name */
	displayName: string;

	/** Whether or not this participant is local */
	isLocal?: boolean;
}

/** String tokens used by the ParticipantRow component */
export enum TokenNames {
	clickToPin = "ClickToPin",
	clickToUnPin = "ClickToUnPin",
	moderatorAction = "ModeratorAction",
	participantRequested = "ParticipantRequested",
	micDisabled = "MicDisabled",
	micLocked = "MicLocked",
	camDisabled = "CamDisabled",
	camLocked = "CamLocked",
	screenEnabled = "ScreenEnabled",
	screenLocked = "ScreenLocked",
}

export enum ParticipantRowTestIds {
	rowButton = "ParticipantRowButton",
	nameLabel = "ParticipantRowNameLabel",
}

/**
 * The ParticipantRow component
 * @param props The props ;)
 */
const ParticipantRow: FC<IProps> = (props) => {
	const { participant, displayName, isLocal } = props;
	const pinnedVideo = useUIState((selectors) => selectors.getPinnedVideo(), []);
	const isPinned = pinnedVideo?.identity === participant.getUserIdentity();
	const dispatch = useDispatch();
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);
	const requestInfo = useModerationState(
		(selectors) => selectors.getParticipantRequest(participant.getUserIdentity()),
		[participant.getUserIdentity()],
	);

	const strings = useStrings("ParticipantRow", Object.values(TokenNames), [
		SharedStringTokens.localParticipantIndicator,
	]);

	const nameLabel = isLocal
		? stringFormat(strings[SharedStringTokens.localParticipantIndicator], displayName)
		: displayName;

	// Get stream status here to construct screen reader content and then pass to ParticipantIcons to render icons
	const deviceStream = useStream(participant, "camera");
	const shareStream = useStream(participant, "screen");

	const isMicEnabled = useIsStreamEnabled("audio", deviceStream);
	const isCameraEnabled = useIsStreamEnabled("video", deviceStream);
	const isScreenShareEnabled = useIsStreamEnabled("video", shareStream);

	// Fetch both remote and participant locks. Since hooks can't be called conditionally, we'll need to cover both cases.
	const [remoteParticipantLocks, remoteParticipantInfo] = useRoomState(
		(selectors) => [
			selectors.getParticipantModerationLocks(participant.getUserIdentity()),
			selectors.getParticipantInfo(participant.getUserIdentity()),
		],
		[participant.getUserIdentity() ?? ""],
	);

	const remoteParticipantCanShare =
		remoteParticipantInfo?.deviceSupportsScreenShare && remoteParticipantInfo.screenShareAllowed;

	const [localParticipantLocks, localParticipantShareAllowed] = useUserState(
		(selectors) => [selectors.getModerationLocks(), selectors.getUserPermission("canShareScreen")],
		[],
	);
	const localParticipantCanShare = useIsScreenShareSupported() && localParticipantShareAllowed;

	// We will display the lock state if the user is a moderator or is a local user, otherwise we just show enabled/disabled state
	const micLock = isLocal ? localParticipantLocks.audio : canModerate && remoteParticipantLocks.audio;
	const camLock = isLocal ? localParticipantLocks.video : canModerate && remoteParticipantLocks.video;

	let screenShareLock: boolean;
	if (isLocal) {
		// For local users, do not show locks if screen share is not supported on device since it is not actionable and could be confusing
		screenShareLock = localParticipantCanShare && localParticipantLocks.screenShare;
	} else {
		// For remote moderators, additionally show a lock if screen share is not supported since the mod menu will give context to why it is unavailable
		screenShareLock = canModerate && (!remoteParticipantCanShare || remoteParticipantLocks.screenShare);
	}

	const micStatus: IStreamStatus = { enabled: isMicEnabled, locked: micLock };
	const camStatus: IStreamStatus = { enabled: isCameraEnabled, locked: camLock };
	const screenShareStatus: IStreamStatus = { enabled: isScreenShareEnabled, locked: screenShareLock };

	const accessibleStatusLabel = constructDeviceStatusString(
		strings,
		micStatus,
		camStatus,
		screenShareStatus,
	);

	const onClick = useCallback(() => {
		// If we have access to moderator controls, then it's a navigation button to the control sub menu
		if (canModerate && !isLocal) {
			dispatch(
				uiActions.toggleVisibleMenu({
					menu: "participantOptions",
					menuData: {
						title: nameLabel,
						participant: participant,
					},
				}),
			);
		} else {
			//Otherwise treat this as a pinning button
			dispatch(
				uiActions.toggleVideoPinned({
					pinnedVideo: { identity: participant.getUserIdentity(), videoType: "camera" },
					updateSource: "Default",
				}),
			);
		}
	}, [canModerate, dispatch, isLocal, nameLabel, participant]);

	const accessiblePinLabel =
		nameLabel + ". " + (isPinned ? strings[TokenNames.clickToUnPin] : strings[TokenNames.clickToPin]);

	const accessibleControlLabel =
		(requestInfo.audio || requestInfo.video
			? stringFormat(strings[TokenNames.participantRequested], nameLabel)
			: nameLabel + ".") +
		" " +
		strings[TokenNames.moderatorAction];

	const accessibleLabel =
		(canModerate && !isLocal ? accessibleControlLabel : accessiblePinLabel) + " " + accessibleStatusLabel;

	const pinClassName = resolveClassName(styles, {
		pin: true,
		pinned: isPinned,
		mobile: isMobile(),
	});

	return (
		<div>
			<button
				className={styles["participantRow"]}
				onClick={onClick}
				aria-label={accessibleLabel}
				aria-pressed={isPinned}
				data-testid={ParticipantRowTestIds.rowButton}
			>
				<div tabIndex={-1} aria-hidden>
					<div className={styles["nameWrapper"]}>
						<span data-testid={ParticipantRowTestIds.nameLabel}>{nameLabel}</span>
						{(!canModerate || isLocal) && (
							<div className={pinClassName}>
								<Pin height={25} width={25} aria-hidden />
							</div>
						)}
					</div>
					<ParticipantIcons
						participant={participant}
						streamStatus={{ audio: micStatus, video: camStatus, screenShare: screenShareStatus }}
					/>
				</div>
				{canModerate && !isLocal && (
					<Chevron height={15} width={15} aria-hidden chevronDirection="right" />
				)}
			</button>
		</div>
	);
};

/**
 *
 * @param strings Record of localized strings for this component
 * @param micStatus Object including lock and enabled status for the mic
 * @param camStatus Object including lock and enabled status for the camera
 * @param shareStatus Object including lock and enabled status for the screen share
 * @returns String to be used as an accessible label to summarize the participant icon status
 */
function constructDeviceStatusString(
	strings: Record<string, string>,
	micStatus: IStreamStatus,
	camStatus: IStreamStatus,
	shareStatus: IStreamStatus,
): string {
	let label = "";
	if (micStatus.locked) {
		label = strings[TokenNames.micLocked];
	} else if (!micStatus.enabled) {
		label = strings[TokenNames.micDisabled];
	}
	if (camStatus.locked) {
		label = appendListString(label, strings[TokenNames.camLocked]);
	} else if (!camStatus.enabled) {
		label = appendListString(label, strings[TokenNames.camDisabled]);
	}
	if (shareStatus.locked) {
		label = appendListString(label, strings[TokenNames.screenLocked]);
	} else if (shareStatus.enabled) {
		label = appendListString(label, strings[TokenNames.screenEnabled]);
	}
	if (label !== "") {
		label = label + ".";
	}
	return label;
}

/**
 * Helper function to append a string to a base string with a separator
 * @param baseString Original string the appended string will add to
 * @param appendedString String to append. If the baseString is empty, this will be returned
 * @param separator Separator to insert between the base and appended string
 * @returns The base string with the appended string and separator, or just the appended string if the base string is empty
 */
function appendListString(baseString: string, appendedString: string, separator: string = ", "): string {
	if (baseString === "") {
		return appendedString;
	}
	return baseString + separator + appendedString;
}

ParticipantRow.displayName = "ParticipantRow";

export default React.memo(ParticipantRow);
