/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Component to hold questions for surveys
 * @author Max Harkins
 * @module Epic.VideoApp.Components.FeedbackSurvey.SurveyWorkflow
 */

import React, { FC, useCallback, useState } from "react";
import { ICheckUserPermissions, useCheckUserPermissions } from "~/hooks/useCheckUserPermissions";
import { useLeavePageHandler } from "~/hooks/useLeavePageHandler";
import { IUserPermissions } from "~/types";
import {
	IQuestion,
	IQuestionGroup,
	ISaveSurvey,
	ISurvey,
	ISurveyResponses,
	isQuestionGroup,
} from "~/types/survey";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import { useStrings } from "../../hooks";
import ActionButton from "../Utilities/ActionButton";
import QuestionsPage from "../Utilities/Survey/QuestionsPage";
import styles from "./SurveyWorkflow.module.scss";
import { useFeedbackTimeoutWarning } from "./hooks/useFeedbackTimeoutWarning";

enum TokenNames {
	submit = "Submit",
	exit = "Exit",
	next = "Next",
	back = "Back",
	leaveSurveyWarning = "LeaveSurveyWarning",
}

/**
 * Props for SurveyWorkflow Component
 */
interface IProps {
	/** Survey data model to be displayed */
	survey: ISurvey;
	/** Callback for when users abandon the survey partway through. The survey responses up until that point are saved before calling onExit */
	onExit: () => void;
	/** Callback for when user successfully completes the survey. This is called after saving the survey responses. */
	onEnd: () => void;
	/** Callback to save off responses once users complete each page */
	saveResponses: ISaveSurvey;
	/** Callback to set the focus as needed when page numbers change */
	setFocusOnPageChange: () => void;
}
/**
 * Builds the initial survey responses for a page. This can include responses that were previously answered, e.g. when navigating backwards
 * the initial responses shown on a page may come from responses the user submitted earlier.
 * @param survey The survey structure that we're showing. This defines all the pages of the survey and questions on each page.
 * @param pageNumber The page number to calculate the survey responses for
 * @param previousResponses The responses that were answered so far in the survey. This is necessary to determine whether to show question groups
 * based on branching behavior.
 * @param possibleResponses This is a map of responses that may be appropriate to show on this page. E.g. if a user is navigating backwards, the possible responses
 * will be all the previous responses the user has submitted. Moving forward through the survey, the possible responses are all the 'future' responses that have been
 * submitted for subsequent pages in the survey (this can happen if the user navigates forward, then backward, then forward).
 * @returns A tuple of survey response objects for the current page.
 * This has two data structure:
 *  - `pageResponses` contains a map of survey question IDs and response values for all questions that should be displayed on the page.
 *  - `excludedResponses` contains the leftover questions from `possibleResponses`. This represents questions that may be shown on the page in some situations, but
 *    should not be shown in the current state (e.g. questions that depend on answers from the previous page in order to be shown), or this could be responses from
 *    another page that could not be applied to the current page.
 */
function buildResponsesForPage(
	survey: ISurvey,
	pageNumber: number = 0,
	previousResponses: ISurveyResponses = {},
	possibleResponses: ISurveyResponses = {},
): { pageResponses: ISurveyResponses; excludedResponses: ISurveyResponses } {
	const includedResponses: ISurveyResponses = {};
	const excludedResponses: ISurveyResponses = {};
	const questionsOnPage = getQuestionsForPage(survey, pageNumber, previousResponses);

	questionsOnPage.forEach((questionId) => {
		includedResponses[questionId] = possibleResponses[questionId] ?? false;
	});

	Object.keys(possibleResponses).forEach((questionId: string) => {
		if (includedResponses[questionId] === undefined) {
			excludedResponses[questionId] = possibleResponses[questionId];
		}
	});

	return { pageResponses: includedResponses, excludedResponses: excludedResponses };
}

/**
 * Helper function to iterate through the specified page on the survey and get a list of question IDs on that page which should be shown.
 * @param survey The object containing survey information.
 * @param currentPage The page number of the page of interest
 * @param previousResponses A map of previous responses to questions. This is needed for branching behavior, since not all questions
 * will be shown to the user on each page.
 * @returns An array of question IDs that the user can see/respond to for a page.
 */
function getQuestionsForPage(
	survey: ISurvey,
	currentPage: number,
	previousResponses: ISurveyResponses,
): string[] {
	const questions: string[] = [];
	survey.pages[currentPage]?.questions.forEach((questionOrGroup: IQuestion | IQuestionGroup) => {
		if (isQuestionGroup(questionOrGroup)) {
			// Check that group has required dependencies before including in question array
			if (!questionOrGroup.dependsOnQuestion || previousResponses[questionOrGroup.dependsOnQuestion]) {
				questionOrGroup.subQuestions.forEach((question) => {
					questions.push(question.id);
				});
			}
		} else {
			questions.push(questionOrGroup.id);
		}
	});
	return questions;
}

/** Filters out questions from a Survey object which a user doesn't have permission for. Note, this is not a pure function, it updates
 * the survey object that it gets passed inline.
 */
function filterSurveyForUserPermissions(
	survey: ISurvey,
	checkUserPermissions: ICheckUserPermissions,
	screenShareSupport: boolean,
): void {
	const validatePermissions = (requiredPermissions: (keyof IUserPermissions)[]): boolean => {
		if (!checkUserPermissions(requiredPermissions)) {
			return false;
		}
		if (requiredPermissions?.includes("canShareScreen") && !screenShareSupport) {
			return false;
		}
		return true;
	};

	for (const page of survey.pages) {
		page.questions = page.questions.filter((question) => {
			return validatePermissions(question.requiredPermissions ?? []);
		});

		for (const question of page.questions) {
			if (isQuestionGroup(question)) {
				question.subQuestions = question.subQuestions.filter((question) => {
					return validatePermissions(question.requiredPermissions ?? []);
				});
			}
		}
	}
}

/**
 * The SurveyWorkflow component
 */
const SurveyWorkflow: FC<IProps> = (props) => {
	const { survey, onExit, onEnd, saveResponses, setFocusOnPageChange } = props;
	const checkUserPermissions = useCheckUserPermissions();
	const screenShareSupport = useIsScreenShareSupported();
	filterSurveyForUserPermissions(survey, checkUserPermissions, screenShareSupport); // Check the permissions for the survey before doing anything else

	const [currentPage, setCurrentPage] = useState<number>(0);
	const [currentResponses, setCurrentResponses] = useState(buildResponsesForPage(survey).pageResponses);
	const [previousResponses, setPreviousResponses] = useState<ISurveyResponses>({});
	const [futureResponses, setFutureResponses] = useState<ISurveyResponses>({});
	const strings = useStrings("SurveyWorkflow", Object.values(TokenNames));
	const [hasAnyResponses, setHasAnyResponses] = useState(false);

	const handleChange = useCallback(
		(question: IQuestion) => {
			const id = question.id;
			const currentValue = currentResponses[id];
			const newResponses = { ...currentResponses, [id]: !currentValue };
			setCurrentResponses(newResponses);

			// Check for any responses to set flag
			let newHasAnyResponses = false;
			for (const response in newResponses) {
				if (newResponses[response]) {
					newHasAnyResponses = true;
					break;
				}
			}
			setHasAnyResponses(newHasAnyResponses);
		},
		[currentResponses],
	);

	const onBack = useCallback(() => {
		const newCurrentPage = currentPage > 0 ? currentPage - 1 : 0;

		// Find which of the previous responses correspond to questions on the page we've returned to.
		const { pageResponses: newCurrentResponses, excludedResponses: newPreviousResponses } =
			buildResponsesForPage(survey, newCurrentPage, previousResponses, previousResponses);
		setFutureResponses({ ...currentResponses, ...futureResponses });
		setCurrentResponses(newCurrentResponses);
		setPreviousResponses(newPreviousResponses);
		setCurrentPage(newCurrentPage);
		setFocusOnPageChange();
	}, [currentPage, currentResponses, futureResponses, previousResponses, setFocusOnPageChange, survey]);

	const onAdvance = useCallback(() => {
		// Save the full survey responses each submission
		const nextPage = currentPage + 1;
		const isLastPage = nextPage >= survey.pages.length;
		const fullResponses = { ...previousResponses, ...currentResponses };
		saveResponses(fullResponses, isLastPage);

		if (isLastPage) {
			onEnd();
		} else {
			const { pageResponses: newCurrentResponses, excludedResponses: newFutureResponses } =
				buildResponsesForPage(survey, nextPage, fullResponses, futureResponses);
			setFutureResponses(newFutureResponses);
			setCurrentResponses(newCurrentResponses);
			setPreviousResponses(fullResponses);
		}
		setCurrentPage(nextPage);
		setFocusOnPageChange();
	}, [
		currentPage,
		currentResponses,
		futureResponses,
		onEnd,
		previousResponses,
		saveResponses,
		setFocusOnPageChange,
		survey,
	]);

	const onWarning = useCallback(
		(event: BeforeUnloadEvent) => {
			event = event || window.event;
			//User is not disconnected yet, return a message so the browser will warn the user before navigating away
			//Most browsers override this text and may block the attempt to display if the user has not interacted with the page
			if (event) {
				event.returnValue = strings[TokenNames.leaveSurveyWarning];
			}
			return strings[TokenNames.leaveSurveyWarning];
		},
		[strings],
	);

	const onLeaveSurvey = useCallback(() => {
		if (Object.keys(previousResponses).length > 0) {
			saveResponses(previousResponses, true);
		}
	}, [previousResponses, saveResponses]);

	useLeavePageHandler(onWarning, onLeaveSurvey);

	// Show pop-up and handle navigating away from survey when the survey expires
	useFeedbackTimeoutWarning(onLeaveSurvey);

	return (
		<form className={styles["surveyForm"]}>
			<QuestionsPage
				questions={survey?.pages[currentPage]?.questions ?? []}
				currentlySelected={currentResponses}
				previouslySelected={previousResponses}
				handleChange={handleChange}
			/>

			<div className={styles["buttonGroup"]}>
				{currentPage > 0 ? (
					<ActionButton priority="secondary" tone="neutral" onClick={onBack}>
						{strings[TokenNames.back]}
					</ActionButton>
				) : (
					<ActionButton priority="secondary" tone="neutral" onClick={onExit}>
						{strings[TokenNames.exit]}
					</ActionButton>
				)}

				<ActionButton
					priority="primary"
					tone="positive"
					onClick={onAdvance}
					disabled={currentPage === 0 && !hasAnyResponses}
				>
					{survey?.pages?.length === currentPage + 1
						? strings[TokenNames.submit]
						: strings[TokenNames.next]}
				</ActionButton>
			</div>
		</form>
	);
};

SurveyWorkflow.displayName = "SurveyWorkflow";

export default SurveyWorkflow;
