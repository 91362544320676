/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Root Redux store
 * @author Erv Walter
 * @module Epic.VideoApp.App.Store
 */

/* istanbul ignore file */

import { createStore } from "@epic/react-redux-booster";

const store = createStore();
export default store;
