/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Row for the standalone hardware test
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.HardwareSetupRow
 */

import React, { ComponentType, FC, PropsWithChildren, useContext } from "react";
import { IconProps } from "~/icons";
import { DeviceStatus, DisplayContext } from "~/types";
import { resolveClassName } from "~/utils/className";
import { HardwareSetupDisplayContext } from "./HardwareSetup";
import styles from "./HardwareSetupRow.module.scss";
import HardwareSetupStatusIcon from "./HardwareSetupStatusIcon";

/**
 * Props for HardwareSetupRow Component
 */
interface IProps {
	icon: ComponentType<IconProps>;
	status?: DeviceStatus;
	label: string;
	centerLabel?: boolean;
	iconClassName?: string;
}

export enum HardwareSetupRowTestIds {
	self = "HardwareSetupRow",
	centerLabel = "CenterLabel",
}

/**
 * The HardwareSetupRow component
 * @param _props The props ;)
 */
const HardwareSetupRow: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { icon, status, children, label, centerLabel } = props;
	const inDevicesTray = useContext(HardwareSetupDisplayContext) === DisplayContext.devicesTray;

	const rowClassName = resolveClassName(styles, { hardwareSetupRow: true, dark: inDevicesTray });

	return (
		<div className={rowClassName} data-testid={HardwareSetupRowTestIds.self}>
			<div className={styles["iconAndLabelWrapper"]}>
				<HardwareSetupStatusIcon icon={icon} status={status} />

				{!centerLabel && <label>{label}</label>}
			</div>

			{/* If we want the label centered, it should be bolded */}
			{centerLabel && (
				<label className={styles["centerLabel"]} data-testid={HardwareSetupRowTestIds.centerLabel}>
					{label}
				</label>
			)}

			{children}
		</div>
	);
};

HardwareSetupRow.displayName = "HardwareSetupRow";

export default HardwareSetupRow;
