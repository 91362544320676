/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to manage getting the pinned participant from state and listening for that participant leaving
 * @author Colin Walters
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UsePinnedParticipant
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useMemo, useRef } from "react";
import { uiActions, useUIState } from "~/state";
import { IParticipantVideo } from "~/types";
import { IUser } from "~/web-core/interfaces";

/**
 * Helper function to determine if a participant should be pinned
 * @param participant participant to check
 * @param pinnedVideo pinned video information
 * @returns whether or not that participant should be pinned
 */
function participantShouldBePinned(participant: IUser, pinnedVideo: IParticipantVideo): boolean {
	// sid must match and if the shared video is a screen they must be sharing
	return (
		participant.getUserIdentity() === pinnedVideo.identity &&
		(pinnedVideo.videoType !== "screen" || participant.isSharingScreen())
	);
}

/**
 * Hook to get the pinned participant from the room's list of participants
 * @param participants list of all participants (local & remote)
 * @returns the currently pinned participant or null if no participant is pinned
 */
export function usePinnedParticipant(participants: IUser[]): IUser | null {
	const pinnedVideo = useUIState((selectors) => selectors.getPinnedVideo(), []);
	const oldPinned = useRef<IUser | null>(null);

	const dispatch = useDispatch();

	return useMemo(() => {
		// get the pinned participant from the pinned participant SID in state
		let newPinned: IUser | null = null;
		if (pinnedVideo) {
			// find the participant that is currently pinned
			newPinned = participants.find((p) => participantShouldBePinned(p, pinnedVideo)) || null;

			// the pinned video feed doesn't exist anymore, clear the value out of state unless it was a
			// pinned screen. useScreenShareParticipant will handle clearing pinning when sharing stops.
			// In cases where someone takes over sharing, there was a race condition between recognizing
			// the new sharing participant and unpinning the old, leading to no one being pinned.
			if (!newPinned && pinnedVideo.videoType !== "screen") {
				dispatch(uiActions.toggleVideoPinned(null));
			}
		}
		// To avoid re-rendering video feeds check if the new identity and old identity are the same
		if (newPinned?.getUserIdentity() === oldPinned.current?.getUserIdentity()) {
			return oldPinned.current;
		}
		oldPinned.current = newPinned;
		return newPinned;
	}, [dispatch, participants, pinnedVideo]);
}
