/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Component used to select a speaker
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.SpeakerSelector
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext } from "react";
import { useSpeakerDevices, useStrings } from "~/hooks";
import { combinedActions, useHardwareTestState, useSpeakerState } from "~/state";
import { DeviceStatus } from "~/types";
import { IS_SET_SINK_ID_SUPPORTED } from "~/utils/browser";
import { setSpeakerPreference } from "~/utils/device";
import { SharedStringTokens } from "~/utils/strings";
import { VideoSessionContext } from "~/web-core/components";
import DeviceSelector from "./DeviceSelector";

enum TokenNames {
	fallbackName = "Speaker",
}

/**
 * The SpeakerSelector component
 */
const SpeakerSelector: FC = () => {
	const { session } = useContext(VideoSessionContext);
	const dispatch = useDispatch();

	const speakers = useSpeakerDevices();
	const selectedSpeakerId = useSpeakerState((selectors) => selectors.getSelectedSpeakerId(), []);
	const speakerStatus = useHardwareTestState((selectors) => selectors.getSpeakerStatus(), []);

	const strings = useStrings("SpeakerSelector", Object.values(TokenNames), [
		SharedStringTokens.systemDefault,
	]);

	const onSpeakerChanged = useCallback(
		(speaker: MediaDeviceInfo) => {
			dispatch(combinedActions.setSelectedSpeaker(speaker));
			// speaker selection currently can't fail, so for now we can set the preference here
			setSpeakerPreference(speaker);
			session?.localUser.setAudioOutput(speaker);
		},
		[dispatch, session],
	);

	// wait until we've tried to select a speaker, but show "System Default" for no speakers after that
	let noDevicesMessage: string | undefined;
	if (
		speakerStatus !== DeviceStatus.testing &&
		(speakerStatus !== DeviceStatus.warning || !IS_SET_SINK_ID_SUPPORTED || !!speakers.length)
	) {
		noDevicesMessage = strings[SharedStringTokens.systemDefault];
	}

	return (
		<DeviceSelector
			selectedDeviceId={selectedSpeakerId || ""}
			devices={speakers}
			fallbackDeviceName={strings[TokenNames.fallbackName]}
			onDeviceSelectionChange={onSpeakerChanged}
			noDevicesMessage={noDevicesMessage}
		/>
	);
};

SpeakerSelector.displayName = "SpeakerSelector";

export default SpeakerSelector;
