/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Initialization function to create vendor specific session and user objects
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Functions.InitializeVendor
 */

import { CreateLocalTrackOptions } from "twilio-video";
import { getCameraPreference, getMicPreference } from "~/utils/device";
import { isIOS } from "~/utils/os";
import { ISession } from "../interfaces/session";
import { VideoVendor } from "../types";
import { initializeDailyAsVendor } from "../vendor/daily/functions/initializeDailyAsVendor";
import { initializeTwilioAsVendor } from "../vendor/twilio/functions/initializeTwilioAsVendor";
import { getVideoTrackOptions, standardAudioTrackOptions } from "../vendor/twilio/twilioSettings";

/**
 * Initializes the session using the specified video vendor
 * @param limitResolution whether low bandwidth mode is enabled
 * @param vendor the vendor to use for the video visit
 * @throws Error if the vendor is unknown or not supported
 * @returns the initialized session
 */
export async function initializeVendor(limitResolution: boolean, vendor: VideoVendor): Promise<ISession> {
	const videoOverrideOptions: CreateLocalTrackOptions = { facingMode: { ideal: "user" } };
	const audioOverrideOptions: CreateLocalTrackOptions = {};

	// don't read preferences on iOS to help work around https://bugs.webkit.org/show_bug.cgi?id=226921
	// and https://bugs.webkit.org/show_bug.cgi?id=225344
	if (!isIOS()) {
		const cameraPreference = getCameraPreference();
		if (cameraPreference?.id) {
			videoOverrideOptions.deviceId = { ideal: cameraPreference.id };
		}

		const micPreference = getMicPreference();
		if (micPreference?.id) {
			audioOverrideOptions.deviceId = { ideal: micPreference.id };
		}
	}

	const videoOptions = getVideoTrackOptions(limitResolution, videoOverrideOptions);
	const audioOptions = standardAudioTrackOptions(audioOverrideOptions);

	switch (vendor) {
		case VideoVendor.twilio: {
			const twilioSession = await initializeTwilioAsVendor(videoOptions, audioOptions);
			return twilioSession;
		}

		case VideoVendor.daily: {
			const dailySession = await initializeDailyAsVendor(videoOptions.deviceId, audioOptions.deviceId);
			return dailySession;
		}

		default:
			throw Error("Could not create video session.");
	}
}
