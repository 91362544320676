/**
 * @copyright Copyright 2020-2023 Epic Systems Corporation
 * @file JWT/Authorization utilities
 * @author Will Cooper
 * @module Epic.VideoApp.Utils.Jwt
 */

import {
	configurationCookieKey,
	getCookie,
	jwtCookieKey,
	jwtExpirationCookieKey,
	jwtHardwareTestCookieKey,
	jwtHasRefreshCookieKey,
	setCookie,
} from "./cookies";
import { minutesToMs, secondsToMs } from "./dateTime";

export interface ISessionCookieInfo {
	/** The session's JWT */
	jwt: string;
	/** The expiration instant of the session, if none is found in cookies this will be set to 1 hour */
	expirationInstant: number;
	/** Whether or not the session has a refresh token */
	hasRefreshToken: boolean;
}

const HARDWARE_TEST_COOKIE_EXPIRATION = minutesToMs(10);

/**
 * Set the hardware test JWT in cookies
 * @param jwt JWT to set in cookies
 * @param sessionKey Session id of hardware test
 */
export function setHardwareTestJWTCookie(jwt: string, sessionKey: string): void {
	if (!jwt) {
		return;
	}
	setCookie(jwtHardwareTestCookieKey + sessionKey, jwt, HARDWARE_TEST_COOKIE_EXPIRATION);
}

/**
 * Set the session's encrypted configuration in cookies
 * @param encryptedConfiguration the session's encrypted configuration
 * @param sessionKey session ID of the hardware test
 */
export function setHardwareTestConfigurationCookie(encryptedConfiguration: string, sessionKey: string): void {
	if (!encryptedConfiguration) {
		return;
	}
	setCookie(configurationCookieKey + sessionKey, encryptedConfiguration, HARDWARE_TEST_COOKIE_EXPIRATION);
}

/**
 * Get the hardware test JWT in cookies
 * @param sessionKey Session id of hardware test
 */
export function getHardwareTestJWTCookie(sessionKey: string): string {
	if (!sessionKey) {
		return "";
	}
	return getCookie(jwtHardwareTestCookieKey + sessionKey);
}

/**
 * Get the JWT from cookies for a particular session
 * @param sessionKey the session to get the JWT for
 * @returns the JWT that is associated with sessionKey, if one exists
 */
function getJWTFromSessionCookie(sessionKey: string): string {
	if (!sessionKey) {
		return "";
	}
	return getCookie(jwtCookieKey + sessionKey);
}

/**
 * Get the JWT from cookies for a particular session
 * @param sessionKey the session to get the JWT for
 * @returns the JWT that is associated with sessionKey, if one exists
 */
export function getConfigFromSessionCookie(sessionKey: string): string {
	if (!sessionKey) {
		return "";
	}
	return getCookie(configurationCookieKey + sessionKey);
}

/**
 * Get the expiration instant of a session
 * @param sessionKey the session to get the expiration of
 * @returns the expiration instant of the session, or null if the expiration cookie didn't exist
 */
export function getSessionExpiration(sessionKey: string): number | null {
	if (!sessionKey) {
		return null;
	}
	const expirationInstantString = getCookie(jwtExpirationCookieKey + sessionKey);
	if (!expirationInstantString) {
		return null;
	}
	return parseInt(expirationInstantString, 10);
}

/**
 * Get whether or not a particular session has a refresh token
 * @param sessionKey the session that should be checked for a refresh token
 * @returns true if the session has a refresh token, false otherwise
 */
function getHasRefreshCookie(sessionKey: string): boolean {
	if (!sessionKey) {
		return false;
	}
	return getCookie(jwtHasRefreshCookieKey + sessionKey) === "true";
}

/**
 * Set the JWT in cookies for a particular session
 * @param jwt JWT to set in cookies
 * @param sessionKey the session that the JWT belongs to
 * @param expirationInstant instant that the JWT expires
 * @param hasRefreshToken whether or not the session has a refresh token
 * @param cookieExpiresIn duration that the cookie should expire in, if different than session expiration
 */
export function setSessionCookies(
	jwt: string,
	sessionKey: string,
	expirationInstant: number,
	canRefresh: boolean = false,
	encryptedConfiguration?: string,
	cookieExpiresIn?: number,
): void {
	if (!jwt || !sessionKey) {
		return;
	}

	// cookieExpiresIn is specified when expiring session cookies - use
	// that value if present, otherwise use the actual session expiration
	const expiresIn = cookieExpiresIn ?? expirationInstant - Date.now();

	// set all relevant session cookies together with the same expiration
	setCookie(jwtCookieKey + sessionKey, jwt, expiresIn);
	setCookie(jwtExpirationCookieKey + sessionKey, expirationInstant.toString(), expiresIn);
	setCookie(jwtHasRefreshCookieKey + sessionKey, canRefresh.toString(), expiresIn);
	if (encryptedConfiguration) {
		setCookie(configurationCookieKey + sessionKey, encryptedConfiguration, expiresIn);
	}
}

/**
 * Extend the cookie duration for cookies associated with a particular session
 * @param sessionKey the session that should be extended
 * @returns session information for the extended session
 */
export function tryExtendSessionCookies(sessionKey: string): ISessionCookieInfo | null {
	if (!sessionKey) {
		return null;
	}

	const jwt = getJWTFromSessionCookie(sessionKey);
	if (!jwt) {
		return null;
	}

	let expirationInstant = getSessionExpiration(sessionKey);
	if (expirationInstant === null) {
		expirationInstant = Date.now() + minutesToMs(60);
	}

	const hasRefreshToken = getHasRefreshCookie(sessionKey);
	const encryptedConfiguration = getConfigFromSessionCookie(sessionKey);

	setSessionCookies(jwt, sessionKey, expirationInstant, hasRefreshToken, encryptedConfiguration);

	return { jwt, expirationInstant, hasRefreshToken };
}

/**
 * Expire a particular session's cookies
 * @param sessionKey the session that should be expired
 * @param preventReconnect whether or not the user should be prevented from reconnecting
 * @param canRefresh whether or not the session has a refresh token
 */
export function expireSessionCookies(
	sessionKey: string,
	preventReconnect: boolean,
	canRefresh: boolean = false,
): void {
	const jwt = getJWTFromSessionCookie(sessionKey);
	const expirationInstant = getSessionExpiration(sessionKey);
	const encryptedConfiguration = getConfigFromSessionCookie(sessionKey);

	// If allowing for refresh, cookie can live for 20s more
	const cookieLifetime = preventReconnect ? 0 : secondsToMs(20);
	setSessionCookies(
		jwt,
		sessionKey,
		expirationInstant ?? Date.now(),
		canRefresh,
		encryptedConfiguration,
		cookieLifetime,
	);
}
