/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Types for Background Effects
 * @author Liam Liden
 * @module Epic.VideoApp.Types.Backgrounds
 */

/// CONSTANTS ///
export const DesktopElements = 6;
export const DesktopInflectionElements = 3;
export const SmallInflectionElements = 2;

export const MenuBackgroundColor = "#222";

/// TYPES ///
export type BackgroundResourceType = "RemoteImage" | "BackgroundImage";

export interface ICustomBackgroundProcessor {
	processor: BackgroundSettings;
	resourceType?: BackgroundResourceType; // Will be undefined for blur processor
	name?: string; // Alternate name if different from the path, e.g. for remote processors
}

export type BackgroundProcessorsMap = Map<string, ICustomBackgroundProcessor>;

export type BackgroundSettingKvp = [name: string, processor: ICustomBackgroundProcessor];

// Used for checking status of background processor load
export type BackgroundProcessorLoadStatus = "notStarted" | "acquiring" | "finished" | "failed";

export enum DefaultImageNames {
	// Used for thumbnail used in none option when loading <6 background options
	none = "0none.png",
	// Used for blurring option thumbnails
	blur = "1blur.png",
	// Virtual backgrounds
	office = "2office.png",
	dark = "3dark.png",
	planks = "4planks.png",
	space = "5space.png",
	// Seasonal backgrounds
	winter = "6winter.png",
	spring = "6spring.png",
	summer = "6summer.png",
	fall = "6fall.png",
}

export type BackgroundSettings = { path: string } & (IImageBackgroundSettings | IBlurBackgroundSettings);

interface IImageBackgroundSettings {
	type: "image";
	src: string;
}

interface IBlurBackgroundSettings {
	type: "blur";
	src: string;
}
