/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Timer to display as part of an alert
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Alerts.AlertLocalTimer
 */
import React, { FC, useRef, useState } from "react";
import { useInterval, useStrings } from "~/hooks";
import { getFormattedTime, msToSeconds } from "~/utils/dateTime";
import { stringFormat } from "~/utils/strings";
import styles from "./Alerts.module.scss";

interface ILocalTimerProps {
	initialTime: number;
	// the interval at which a screen reader will read the
	// value remaining on the timer, in seconds
	screenReaderTimeInterval?: number;
}

export enum AlertLocalTimerTestIds {
	timer = "AlertLocalTimer",
	screenReader = "AlertLocalTimerScreenReader",
}

/**
 * Local Timer for any Alerts that need to display a countdown to the users
 * This component is responsible for its own countdown state.
 */
const AlertLocalTimer: FC<ILocalTimerProps> = (props) => {
	const { initialTime, screenReaderTimeInterval = 10 } = props;

	const [timeMS, setTimeMS] = useState(initialTime);
	const lastUpdateInstant = useRef(Date.now());

	// The message to be read by the screen reader
	const [screenReaderMessage, setScreenReaderMessage] = useState("");

	enum TokenNames {
		timerSecondsAriaLabel = "TimerSecondsAriaLabel",
	}
	const strings = useStrings("AlertLocalTimer", Object.values(TokenNames));

	const onTick = (): void => {
		const now = Date.now();
		const msDiff = now - lastUpdateInstant.current;
		lastUpdateInstant.current = now;

		const currentTimeMS = timeMS - msDiff;
		setTimeMS(currentTimeMS);

		if (msToSeconds(currentTimeMS) % screenReaderTimeInterval === 0) {
			const timerJustInitialized: boolean = currentTimeMS > initialTime - 5000;
			const timerCompleted: boolean = currentTimeMS < 2000;
			if (!timerJustInitialized && !timerCompleted) {
				setScreenReaderMessage(
					stringFormat(strings[TokenNames.timerSecondsAriaLabel], msToSeconds(currentTimeMS)),
				);
			}
		}
	};
	useInterval(onTick, 1000);

	return (
		<>
			<span
				data-testid={AlertLocalTimerTestIds.timer}
				className={styles["timerText"]}
				aria-hidden="true"
			>
				{getFormattedTime(timeMS)}
			</span>
			<span
				className={styles["screenReaderOnly"]}
				aria-live="assertive"
				data-testid={AlertLocalTimerTestIds.screenReader}
			>
				{screenReaderMessage}
			</span>
		</>
	);
};

AlertLocalTimer.displayName = "AlertLocalTimer";

export default AlertLocalTimer;
