/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Displays a disclaimer message
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Chat.ChatDisclaimer
 */

import React, { FC } from "react";
import { useStrings } from "~/hooks";
import styles from "./ChatDisclaimer.module.scss";

export enum ChatDisclaimerTestIds {
	self = "ChatDisclaimer",
}

enum TokenNames {
	chatDisclaimerDisclaimerText = "DisclaimerText",
}

export const ChatDisclaimer: FC = () => {
	const strings = useStrings("ChatDisclaimer", Object.values(TokenNames));
	return (
		<div aria-live="polite" data-testid={ChatDisclaimerTestIds.self} className={styles["ChatDisclaimer"]}>
			{strings[TokenNames.chatDisclaimerDisclaimerText]}
		</div>
	);
};
