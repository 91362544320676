/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Returns whether screen sharing is supported for the current device
 * @author Chance Overberg
 * @module Epic.VideoApp.WebCore.Hooks.UseIsScreenShareSupported
 */

import { useIsVideoSupported } from "./useIsVideoSupported";

export function useIsScreenShareSupported(): boolean {
	const isVideoSupported = useIsVideoSupported();

	return isVideoSupported && !!navigator.mediaDevices?.getDisplayMedia;
}
