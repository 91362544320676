/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Shared state for moderator-specific settings
 * @author Will Cooper
 * @module Epic.VideoApp.State.Moderation
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { IParticipantRequest } from "./room";

export interface IParticipantRequestUpdate {
	identity: string;
	audio?: boolean;
	video?: boolean;
	screenShare?: boolean;
}

export interface IModerationState {
	readonly admitOptions: { audio: boolean; video: boolean; screenShare: boolean };
	readonly requestedParticipants: IParticipantRequest[];
	readonly requiresAccess: boolean;
}

/// INIT ///

export function getInitialState(): IModerationState {
	return {
		admitOptions: { audio: false, video: false, screenShare: false },
		requestedParticipants: [],
		requiresAccess: false,
	};
}

/// REDUCERS ///

export function setAdmitOptions(
	state: IModerationState,
	value: { audio: boolean; video: boolean; screenShare: boolean },
): IModerationState {
	return { ...state, admitOptions: value };
}

export function postParticipantRequest(
	state: IModerationState,
	entry: IParticipantRequestUpdate,
): IModerationState {
	const { identity } = entry;
	if (!identity) {
		return state;
	}

	// Attempt to remove any previous participant info for the current user (we will add the up to date object later)
	const prevEntry: IParticipantRequest | undefined = state.requestedParticipants.find(
		(current: IParticipantRequest) => entry.identity === current.identity,
	);

	const newRequests = state.requestedParticipants.filter(
		(current: IParticipantRequest) => entry.identity !== current.identity,
	);

	const audio = entry.audio ?? prevEntry?.audio ?? false;
	const video = entry.video ?? prevEntry?.video ?? false;
	const screenShare = entry.screenShare ?? prevEntry?.screenShare ?? false;

	const newEntry: IParticipantRequest = {
		identity: entry.identity,
		audio: audio,
		video: video,
		screenShare: screenShare,
	};

	newRequests.push(newEntry);

	return { ...state, requestedParticipants: newRequests };
}

export function clearParticipantRequest(state: IModerationState, identity: string): IModerationState {
	if (!identity) {
		return state;
	}

	// Attempt to remove any previous participant info for the current user
	const newRequests: IParticipantRequest[] = state.requestedParticipants.filter(
		(current: IParticipantRequest) => identity !== current.identity,
	);
	return { ...state, requestedParticipants: newRequests };
}

function clearAllParticipantRequests(state: IModerationState): IModerationState {
	return { ...state, requestedParticipants: [] };
}

export function setVisitRequiresAccess(state: IModerationState, value: boolean): IModerationState {
	return { ...state, requiresAccess: value };
}

/// SELECTORS ///

function getAudioAdmitOption(state: IModerationState): boolean {
	return state.admitOptions.audio;
}

function getVideoAdmitOption(state: IModerationState): boolean {
	return state.admitOptions.video;
}

function getScreenShareAdmitOption(state: IModerationState): boolean {
	return state.admitOptions.screenShare;
}

function getRequestingParticipants(state: IModerationState): IParticipantRequest[] {
	return state.requestedParticipants;
}

function getParticipantRequest(state: IModerationState, identity: string): IParticipantRequest {
	// Return the participants request status, or an object signifying no request was made
	return (
		state.requestedParticipants.find((participant) => participant.identity === identity) || {
			identity,
			audio: false,
			video: false,
			screenShare: false,
		}
	);
}

function getDoesVisitRequireAccess(state: IModerationState): boolean {
	return state.requiresAccess;
}

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		clearParticipantRequest,
		clearAllParticipantRequests,
		postParticipantRequest,
		setAdmitOptions,
		setVisitRequiresAccess,
	},
	selectors: {
		getAudioAdmitOption,
		getVideoAdmitOption,
		getScreenShareAdmitOption,
		getRequestingParticipants,
		getParticipantRequest,
		getDoesVisitRequireAccess,
	},
});

store.addSharedState(builtState.sharedState, "moderation");

export const {
	actionCreators: moderationActions,
	useSharedState: useModerationState,
	sharedState: state,
} = builtState;
