/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file An alert modal that allows a moderator to leave the call, remain connected, or attempt to end the call for all connected parties
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Alerts.EndVisitAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useRef } from "react";
import { useDisconnect, useStrings } from "~/hooks";
import { alertActions } from "~/state";
import { AlertType } from "~/types";
import ActionButton from "../Utilities/ActionButton";
import KeyboardShortcut from "../Utilities/KeyboardShortcut";
import styles from "./Alerts.module.scss";
import FocusTrap from "./FocusTrap";

enum TokenNames {
	message = "Message",
	stay = "Stay",
	end = "End",
	leave = "Leave",
	confirmation = "Confirmation",
}

export enum EndVisitAlertTestIds {
	self = "EndVisitAlert",
	message = "message",
	leaveCall = "leave-call",
	endCall = "end-call",
	stay = "stay",
}

/**
 * EndVisit alert component
 *
 * Renders a confirmation message to allow the user to choose to end the visit for all or leave the visit
 */
const EndVisitAlert: FC = () => {
	const dispatch = useDispatch();
	const strings = useStrings("EndVisitAlert", Object.values(TokenNames));

	const disconnect = useDisconnect();

	const endCall = useCallback(() => {
		dispatch(alertActions.clearAlert());
		dispatch(
			alertActions.postChoiceAlert({
				type: AlertType.endVisitConfirmation,
				message: strings[TokenNames.confirmation],
				confirmText: strings[TokenNames.end],
				cancelText: strings[TokenNames.stay],
				confirmHotkey: "a",
				cancelHotkey: "s",
			}),
		);
	}, [dispatch, strings]);

	const leaveVisit = useCallback(() => {
		// Disconnecting also clears alerts
		disconnect(false, true);
	}, [disconnect]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	const leaveCallRef = useRef<HTMLButtonElement>(null);
	const stayRef = useRef<HTMLButtonElement>(null);

	return (
		<div data-testid={EndVisitAlertTestIds.self} className={styles["modalCard"]}>
			<span id="message" data-testid={EndVisitAlertTestIds.message} className={styles["bodyText"]}>
				{strings[TokenNames.message]}
			</span>
			<div className={styles["buttons"]}>
				<FocusTrap firstElement={leaveCallRef} lastElement={stayRef}>
					<ActionButton
						priority="primary"
						tone="negative"
						onClick={leaveVisit}
						buttonRef={leaveCallRef}
						id="leave-call"
						data-testid={EndVisitAlertTestIds.leaveCall}
					>
						<KeyboardShortcut
							callback={leaveVisit}
							keyCombination={["alt", "l"]}
							forModalAlert
							text={strings[TokenNames.leave]}
						/>
					</ActionButton>
					<ActionButton
						priority="secondary"
						tone="negative"
						onClick={endCall}
						id="end-call"
						data-testid={EndVisitAlertTestIds.endCall}
					>
						<KeyboardShortcut
							callback={endCall}
							keyCombination={["alt", "a"]}
							forModalAlert
							text={strings[TokenNames.end]}
						/>
					</ActionButton>
					<ActionButton
						priority="primary"
						tone="neutral"
						onClick={cancel}
						buttonRef={stayRef}
						id="stay"
						data-testid={EndVisitAlertTestIds.stay}
					>
						<KeyboardShortcut
							callback={cancel}
							keyCombination={["alt", "s"]}
							forModalAlert
							text={strings[TokenNames.stay]}
						/>
					</ActionButton>
				</FocusTrap>
			</div>
		</div>
	);
};

EndVisitAlert.displayName = "EndVisitAlert";

export default EndVisitAlert;
