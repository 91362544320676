/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Network test page
 * @author Noah Allen
 * @module Epic.VideoApp.Features.TestNetwork.TestPage
 */

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { DailyCallQualityTestResults, DailyWebsocketConnectivityTestResults } from "@daily-co/daily-js";
import { useDaily } from "@daily-co/daily-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import Card from "~/components/Cards/Card";
import CardContainer from "~/components/Cards/CardContainer";
import Spinner from "~/components/Loading/Spinner";
import ActionButton from "~/components/Utilities/ActionButton";
import NetworkDataResultsTable, { IData } from "./DataTable";
import FormattedConnectionState from "./FormattedConnectionState";
import FormattedWebsocketConnectivity from "./FormattedWebsocketConnectivity";
import NetworkTester from "./NetworkTester";
import styles from "./TestPage.module.scss";

export const CONNECTION_MODES = {
	ALL: "all", // used to gather all candidates
	STUN: "stun",
	TURN_UDP: "turn-udp",
	TURN_TCP: "turn-tcp",
	TURN_TLS: "turn-tls",
	RELAY_ONLY: "relay",
};

type TestState =
	| "idle"
	| "starting_call_quality"
	| "running_call_quality"
	| "finished_call_quality"
	| "starting_advanced"
	| "running_advanced"
	| "finished_advanced";

const TestPage: FC = () => {
	const callMachine = useDaily();
	const [data, setData] = useState<IData | null>(null);
	const [testState, setTestState] = useState<TestState>("idle");
	const [callQuality, setCallQuality] = useState<DailyCallQualityTestResults | null>(null);
	const [websocketConnectivity, setWebsocketConnectivity] =
		useState<DailyWebsocketConnectivityTestResults | null>(null);
	const [connectionState, setConnectionState] = useState({
		[CONNECTION_MODES.ALL]: {
			result: null,
			iceCandidates: null,
		},
		[CONNECTION_MODES.RELAY_ONLY]: {
			result: null,
			iceCandidates: null,
		},
		[CONNECTION_MODES.STUN]: {
			result: null,
			iceCandidates: null,
		},
		[CONNECTION_MODES.TURN_UDP]: {
			result: null,
			iceCandidates: null,
		},
		[CONNECTION_MODES.TURN_TCP]: {
			result: null,
			iceCandidates: null,
		},
		[CONNECTION_MODES.TURN_TLS]: {
			result: null,
			iceCandidates: null,
		},
	});

	const startCallQuality = useCallback(async () => {
		setTestState("starting_call_quality");
		if (!callMachine) {
			console.error("DailyCall object is null");
			return;
		}
		callMachine.on("error", (e) => {
			console.log("daily-js error: ", e);
		});
		await callMachine?.preAuth({ url: "https://epic-demo.daily.co/NetworkTestRoom" });
		console.log("Starting call quality test");
		setTestState("running_call_quality");

		try {
			const callQuality = await callMachine?.testCallQuality();
			if (callQuality) {
				console.log("No call quality test results.");
				setCallQuality(callQuality);
			} else {
				console.log("No call quality test results.");
			}
		} catch (error) {
			console.error("An error occurred during the call quality test:", error);
		}
		setTestState("finished_call_quality");
	}, [callMachine, setCallQuality, setTestState]);

	const runWebsocketTest = useCallback(async () => {
		console.log("starting websocket test");
		const webSocket = await callMachine?.testWebsocketConnectivity();
		if (webSocket) {
			setWebsocketConnectivity(webSocket);
			console.log({ ws: webSocket });
		}
	}, [callMachine, setWebsocketConnectivity]);

	const startAdvanced = useCallback(async () => {
		setTestState("starting_advanced");
		const iceResp = await fetch("https://prod-ks.pluot.blue/tt-150331.json");
		const iceServers = await iceResp.json();
		setTestState("running_advanced");
		const promises = Object.keys(connectionState).map((test) => initiateTester(test, iceServers));
		promises.push(runWebsocketTest());
		await Promise.all(promises);
		setTestState("finished_advanced");
	}, [connectionState, runWebsocketTest]);

	async function initiateTester(connectionMode: any, iceServers: any): Promise<void> {
		const instance = new NetworkTester({
			natService: "twilio",
			connectionMode,
			iceServers,
		});
		console.log("created test instance: ", instance);

		const result = await instance.setupRTCPeerConnection();
		console.log("RTC Peer connection test result: ", result);
		setConnectionState((prevState) => ({
			...prevState,
			[connectionMode]: {
				result: result.status,
				iceCandidates: result.iceCandidates,
			},
		}));
	}
	const getResultMessage = (status?: string): string => {
		switch (status) {
			case "aborted":
				return "Test canceled.";
			case "failed":
				return "Test failed to run.";
			case "bad":
				return "Issues detected with your network.";
			case "warning":
				return "Issues detected with your network may impact video and audio quality.";
			case "good":
				return "No issues detected with your network.";
			default:
				return "The network test failed to complete.";
		}
	};

	useEffect(() => {
		if (callQuality && ["aborted", "failed", "bad", "warning"].includes(callQuality.result)) {
			if ("data" in callQuality) {
				setData(callQuality.data);
			} else {
				setData(null);
			}
		}
	}, [callQuality]);

	const callQualityResultDescription = useCallback((): React.JSX.Element => {
		switch (callQuality?.result) {
			case "aborted":
			case "failed":
			case "bad":
			case "warning":
				return (
					<>
						<div style={{ textAlign: "left" }}>
							<p>{getResultMessage(callQuality?.result)}</p>
							<p>Please run these additional advanced tests to get more information.</p>
						</div>
						<ActionButton tone="neutral" priority="primary" onClick={startAdvanced}>
							Run Advanced Tests
						</ActionButton>
					</>
				);
			case "good":
				return <p>{getResultMessage(callQuality?.result)}</p>;
			default:
				return <p>{getResultMessage()}</p>;
		}
	}, [callQuality, startAdvanced]);

	const displayMessage = useMemo(() => {
		return () => {
			switch (testState) {
				case "starting_call_quality":
					return <h2>Starting call quality test, please wait a few seconds.</h2>;
				case "starting_advanced":
					return <h2>Starting advanced tests, please wait a few seconds.</h2>;
				case "running_advanced":
					return <h2>Running advanced tests, please wait a few seconds.</h2>;
				case "running_call_quality":
					return (
						<div>
							<h2>Running call quality test.</h2>
							<p>This will take up to 30 seconds. It will complete automatically.</p>
						</div>
					);
				case "finished_call_quality":
					return (
						<div>
							<h2>Finished call quality test.</h2>
							{callQualityResultDescription()}
						</div>
					);
				case "finished_advanced":
					return (
						<div>
							<div>
								<h2>Initial call quality test results:</h2>
								<p>{getResultMessage(callQuality?.result)}</p>
								{data ? (
									<>
										<h2>Network statistics results:</h2>
										<div>{data ? <NetworkDataResultsTable data={data} /> : null}</div>
									</>
								) : null}
							</div>
							<div>
								<h2>Websocket connectivity results:</h2>
								<FormattedWebsocketConnectivity data={websocketConnectivity} />
							</div>
							<div>
								<h2>Network connectivity results:</h2>
								<FormattedConnectionState connectionState={connectionState} />
							</div>
						</div>
					);
				default:
					return (
						<>
							<h1>Video call network quality test</h1>
							<ActionButton tone="neutral" priority="primary" onClick={startCallQuality}>
								Test your network
							</ActionButton>
						</>
					);
			}
		};
	}, [
		callQuality,
		callQualityResultDescription,
		connectionState,
		data,
		startCallQuality,
		testState,
		websocketConnectivity,
	]);

	const shouldShowSpinner = useMemo(() => {
		return (
			testState === "running_advanced" ||
			testState === "running_call_quality" ||
			testState === "starting_call_quality" ||
			testState === "starting_advanced"
		);
	}, [testState]);

	return (
		<CardContainer wideCard>
			<Card>
				<div className={styles["testPage"]}>{displayMessage()}</div>
				{shouldShowSpinner && <Spinner />}
			</Card>
		</CardContainer>
	);
};

export default TestPage;
