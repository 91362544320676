/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file Menu for showing all possible per participant actions
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Moderation.ParticipantControlMenu
 */

import { useDispatch } from "@epic/react-redux-booster";
import { FC, default as React, useContext, useEffect, useRef } from "react";
import PictureInPictureButton from "~/components/Header/Buttons/PictureInPictureButton";
import { useWindowSize } from "~/hooks";
import { uiActions, useRoomState, useUIState, useUserState } from "~/state";
import { MinimumScreenHeightForHeader } from "~/types";
import { resolveClassName } from "~/utils/className";
import { isPictureInPictureSupported } from "~/utils/pictureInPicture";
import { VideoSessionContext } from "~/web-core/components";
import { IEVCParticipantConnectionEvent } from "~/web-core/events";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { IUser } from "~/web-core/interfaces";
import { VideoType } from "~/web-core/types";
import { NameBarLocation } from "../ParticipantNameBar";
import LockParticipantCameraButton from "./ModerationButtons/LockParticipantCameraButton";
import LockParticipantMicrophoneButton from "./ModerationButtons/LockParticipantMicrophoneButton";
import LockParticipantScreenShareButton from "./ModerationButtons/LockParticipantScreenShareButton";
import PinButton from "./ModerationButtons/PinButton";
import RemoveParticipantButton from "./ModerationButtons/RemoveParticipantButton";
import styles from "./ParticipantControlMenu.module.scss";

/**
 * Props for ParticipantControlMenu Component
 */
export interface IParticipantControlMenuProps {
	name?: string;
	participant?: IUser;
	videoType?: VideoType;
	inNameBar?: boolean;
	location?: NameBarLocation;
}

export enum ParticipantControlMenuTestIds {
	self = "ParticipantControlMenu",
	pictureInPictureButton = "PictureInPictureButton",
	lockParticipantCameraButton = "LockParticipantCameraButton",
	lockParticipantMicrophoneButton = "LockParticipantMicrophoneButton",
	lockParticipantScreenShareButton = "LockParticipantScreenShareButton",
	pinButton = "PinButton",
	removeParticipantButton = "RemoveParticipantButton",
}

/**
 * The ParticipantControlMenu component
 * @param props The props ;)
 */
const ParticipantControlMenu: FC<IParticipantControlMenuProps> = (props) => {
	const { videoType, name, inNameBar, location, participant } = props;
	const menuData = useUIState((selectors) => selectors.getDynamicMenuData(), []);
	const { session } = useContext(VideoSessionContext);
	const displayName = name || menuData?.title || "";

	// Wrap this in a ref to prevent null participant when the collapse component is closing
	const participantRef = useRef(participant || menuData?.participant);
	const identity = participantRef.current?.getUserIdentity() ?? "";
	const guid = participantRef.current?.getUserGuid() ?? "";
	const participantInfo = useRoomState((selectors) => selectors.getParticipantInfo(identity), [identity]);
	const userType = participantInfo?.userType;

	const dispatch = useDispatch();

	const deviceStream = useStream(participantRef.current, "camera");
	const isMicEnabled = useIsStreamEnabled("audio", deviceStream);
	const isVideoEnabled = useIsStreamEnabled("video", deviceStream);

	const screenShareStream = useStream(participantRef.current, "screen");
	const isScreenShareEnabled = useIsStreamEnabled("video", screenShareStream);

	// Use shared state as source of truth for participant enabled/disabled status
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);

	// hook to get the height of the window to hide the "pin" button from the participant menu for small resolutions
	const { height } = useWindowSize();

	useEffect(() => {
		if (!session || !canModerate) {
			return;
		}

		// Clean-up menu data when a participant disconnects
		const handleParticipantDisconnected = (
			args: IEVCParticipantConnectionEvent<"participantDisconnected">,
		): void => {
			if (menuData && args.participant.getUserIdentity() === menuData.participant.getUserIdentity()) {
				dispatch(uiActions.toggleVisibleMenu({ menu: null }));
			}
		};

		session.on("participantDisconnected", handleParticipantDisconnected);

		return (): void => {
			session.off("participantDisconnected", handleParticipantDisconnected);
		};
	}, [canModerate, dispatch, menuData, session]);

	// If we get a new valid participant, update it in the ref
	useEffect(() => {
		const participantToUse = participant || menuData?.participant;
		if (participantToUse !== undefined) {
			participantRef.current = participantToUse;
		}
	}, [participant, menuData]);

	const menuClass = resolveClassName(styles, {
		moderatorMenu: true,
		menu: !inNameBar,
		reversed: inNameBar,
	});
	const buttonClass = resolveClassName(styles, {
		moderatorRow: true,
		menu: !inNameBar,
		strip: inNameBar && location === "participant-strip",
	});

	const labelClassName = resolveClassName(styles, {
		stripText: inNameBar && location === "participant-strip",
		labelText: true,
	});
	// Handle icon and switch styling / resizing from this parent component instead of from the subcomponents
	const iconClassName = styles["buttonIcon"];
	const toggleSwitchClassName = styles["switch"];

	// Verify we have permission to show this menu as a fallback
	if (
		!canModerate &&
		!(
			isPictureInPictureSupported() &&
			((isVideoEnabled && videoType === "camera") || (isScreenShareEnabled && videoType === "screen"))
		)
	) {
		return null;
	}

	return (
		<div className={menuClass} data-testid={ParticipantControlMenuTestIds.self}>
			<div className={styles["buttonGrouping"]}>
				{height > MinimumScreenHeightForHeader && (
					<PinButton
						identity={identity}
						className={buttonClass}
						videoType={videoType}
						labelClassName={labelClassName}
						toggleSwitchClassName={toggleSwitchClassName}
					/>
				)}
				{isPictureInPictureSupported() &&
					((isVideoEnabled && videoType === "camera") ||
						(isScreenShareEnabled && videoType === "screen")) &&
					inNameBar && (
						<PictureInPictureButton
							className={buttonClass}
							labelClassName={labelClassName}
							userId={identity}
							inHeader={false}
							videoType={videoType}
							toggleSwitchClassName={iconClassName}
							stream={videoType === "camera" ? deviceStream : screenShareStream}
						/>
					)}
			</div>
			{canModerate && (
				<>
					<div className={styles["buttonGrouping"]}>
						<LockParticipantMicrophoneButton
							identity={identity}
							className={buttonClass}
							inNameBar={inNameBar}
							labelClassName={labelClassName}
							enabled={isMicEnabled}
							userType={userType}
							iconClassName={iconClassName}
							toggleSwitchClassName={toggleSwitchClassName}
						/>
						<LockParticipantCameraButton
							identity={identity}
							className={buttonClass}
							inNameBar={inNameBar}
							labelClassName={labelClassName}
							enabled={isVideoEnabled}
							userType={userType}
							iconClassName={iconClassName}
							toggleSwitchClassName={toggleSwitchClassName}
						/>
						<LockParticipantScreenShareButton
							identity={identity}
							className={buttonClass}
							inNameBar={inNameBar}
							labelClassName={labelClassName}
							enabled={isScreenShareEnabled}
							userType={userType}
							iconClassName={iconClassName}
							toggleSwitchClassName={toggleSwitchClassName}
						/>
					</div>
					<div className={styles["buttonGrouping"]}>
						<RemoveParticipantButton
							displayName={displayName}
							className={buttonClass}
							labelClassName={labelClassName}
							identity={identity}
							iconClassName={iconClassName}
							guid={guid}
						/>
					</div>
				</>
			)}
		</div>
	);
};

ParticipantControlMenu.displayName = "ParticipantControlMenu";

export default ParticipantControlMenu;
