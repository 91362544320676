/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file color utilities
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.Color
 */

type ColorDefinition = [number, number, number, number];

export const BLACK: ColorDefinition = [0, 0, 0, 1];
export const WHITE: ColorDefinition = [255, 255, 255, 1];

/**
 * Parse a hex code or rgba color into a color definition
 * @param colorString color string to parse into a color definition
 * @param defaultColor default color to fall back on if parsing fails
 * @returns parsed color definition if successful, default color if unsuccessful
 */
export function parseColor(
	colorString: string,
	defaultColor: "black" | "white" | ColorDefinition = "black",
): ColorDefinition {
	const fallback =
		typeof defaultColor !== "string" ? defaultColor : defaultColor === "black" ? BLACK : WHITE;

	if (colorString.startsWith("#")) {
		return parseHexColor(colorString) ?? fallback;
	}
	if (colorString.startsWith("rgb")) {
		return parseRgbaString(colorString) ?? fallback;
	}
	return fallback;
}

/**
 * Parse a hex code string into a color definition
 * @param hexString hex code string to parse into a color definition
 * @returns parsed color definition if successful, null otherwise
 */
function parseHexColor(hexString: string): ColorDefinition | null {
	if (!/^#(([\da-f]{3}){1,2}|([\da-f]{4}){1,2})$/i.test(hexString)) {
		return null;
	}

	const chunkSize = Math.floor((hexString.length - 1) / 3);
	const hexPieces = hexString.slice(1).match(new RegExp(`.{${chunkSize}}`, "g")); //eslint-disable-line @typescript-eslint/prefer-regexp-exec
	if (!hexPieces) {
		return null;
	}

	const [red, green, blue, alpha] = hexPieces.map((piece) => parseInt(piece.repeat(2 / piece.length), 16));
	return [red, green, blue, getAlphaFloat(alpha)];
}

/**
 * Get the fractional alpha value for a color definition
 * @param a decimal alpha value from hex code to get fractional value from
 * @returns fractional alpha value for color def
 */
function getAlphaFloat(a?: number): number {
	if (typeof a !== "undefined") {
		return a / 255;
	}
	return 1;
}

/**
 * Parse an RGBA color string into a color definition
 * @param rgbaString rgba(RR, GG, BB, AA) string to parse into a color definition
 * @returns parsed color definition if successful, null otherwise
 */
function parseRgbaString(rgbaString: string): ColorDefinition | null {
	if (!rgbaString?.startsWith("rgb(") && !rgbaString?.startsWith("rgba(")) {
		return null;
	}

	const openIndex = rgbaString.indexOf("(");
	const closeIndex = rgbaString.indexOf(")");

	const pieces = rgbaString
		.substring(openIndex + 1, closeIndex)
		.split(",")
		.map((piece) => parseFloat(piece.trim()));

	if (pieces.length < 3 || pieces.length > 4) {
		return null;
	}

	const [red, green, blue, alpha] = pieces;
	return [red, green, blue, alpha ?? 1];
}

/**
 * Parse a color string into a color definition, if it is not already a color definition
 * @param color the color to convert to a color definition
 * @returns the provided color, parsed into a color definition
 */
function toColorDefinition(color: string | ColorDefinition): ColorDefinition {
	return typeof color === "string" ? parseColor(color) : color;
}

/**
 * Get a color's hue (or the wavelength in the visible light spectrum where energy output from the source is greatest)
 * Hue is an indication by position (in degrees) on the RGB color wheel [0, 360] and can be calculated
 * using the following (when r, b, and g are the color's hex component / 255):
 * 		if r is max: H = 60 * (g - b) / (max(r, g, b) - min(r, g, b))
 * 		if g is max: H = 60 * (2 + (b - r) / (max(r, g, b) - min(r, g, b)))
 * 		if b is max: H = 60 * (4 + (r - g) / (max(r, g, b) - min(r, g, b)))
 * Note: the output is an angle, so 360 should be added to negative values
 * Source: https://donatbalipapp.medium.com/colours-maths-90346fb5abda
 *
 * @param color the color to calculate the hue of
 * @returns hue of the provided color
 */
export function getHue(color: string | ColorDefinition): number {
	color = toColorDefinition(color);

	const [red, green, blue] = color;
	const redFractional = red / 255;
	const greenFractional = green / 255;
	const blueFractional = blue / 255;

	const max = Math.max(redFractional, greenFractional, blueFractional);
	const min = Math.min(redFractional, greenFractional, blueFractional);

	let hue: number;
	if (redFractional === max) {
		hue = (greenFractional - blueFractional) / (max - min);
	} else if (greenFractional === max) {
		hue = 2 + (blueFractional - redFractional) / (max - min);
	} else {
		hue = 4 + (redFractional - greenFractional) / (max - min);
	}

	hue *= 60;
	return hue < 0 ? hue + 360 : hue;
}

/**
 * Get a color's luminosity (or brightness)
 * Luminosity is basically how light a color is, is measured on a scale [0, 1], and can be
 * calculated using the following (when r, b, and g are that color's hex component / 255):
 * 		L = 0.5 * (max(r, b, g) + min(r, g, b))
 * Source: https://donatbalipapp.medium.com/colours-maths-90346fb5abda
 *
 * @param color the color to calculate the luminosity of
 * @returns luminosity of the provided color
 */
export function getLuminosity(color: string | ColorDefinition): number {
	const [red, green, blue] = toColorDefinition(color);

	const redFractional = red / 255;
	const greenFractional = green / 255;
	const blueFractional = blue / 255;

	return (
		0.5 *
		(Math.max(redFractional, greenFractional, blueFractional) +
			Math.min(redFractional, greenFractional, blueFractional))
	);
}

/**
 * Get a color's saturation (or the relative bandwidth of the visible output from a light source)
 * Saturation indicates how "pure" a color is (lower values appear more washed out), is measured on a [0, 1]
 * scale, and can be calculated using the following (when r, b, and g are that color's hex component / 255):
 * 		if L < 1: S = (max(r, g, b) - min(r, g, b)) / (1 - |2L - 1|)
 * 		if L == 1: S = 0
 * Source: https://donatbalipapp.medium.com/colours-maths-90346fb5abda
 *
 * @param color the color to calculate the saturation of
 * @returns saturation of the provided color
 */
export function getSaturation(color: string | ColorDefinition): number {
	const [red, green, blue] = toColorDefinition(color);

	const redFractional = red / 255;
	const greenFractional = green / 255;
	const blueFractional = blue / 255;

	const luminosity = getLuminosity(color);

	if (luminosity === 1) {
		return 0;
	}

	return (
		(Math.max(redFractional, greenFractional, blueFractional) -
			Math.min(redFractional, greenFractional, blueFractional)) /
		(1 - Math.abs(2 * luminosity - 1))
	);
}

/**
 * Determine whether or not the provided color is black
 * @param color color to check if it is black
 * @returns true if the color is black, false otherwise
 */
export function isBlack(color: string): boolean {
	const [red, green, blue] = parseColor(color);
	return red === 0 && green === 0 && blue === 0;
}
