/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file circle (page selector buttons)
 * @author Liam Liden
 * @module Epic.VideoApp.Icons.Circle
 */

import React, { FC } from "react";
import { IconProps } from ".";

/**
 * The Circle component
 * @param props The props ;)
 */
const Circle: FC<IconProps> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
			<circle cx="50%" cy="50%" r="6" strokeWidth="2" />
		</svg>
	);
};

Circle.displayName = "Circle";

export default React.memo(Circle);
