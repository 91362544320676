/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to get the media stream track from a AudioTrack or VideoTrack
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseMediaStreamTrack
 */

import { useEffect, useState } from "react";
import { AudioTrack, VideoTrack } from "twilio-video";
/**
 * This hook allows components to reliably use the 'mediaStreamTrack' property of an audio or video track.
 * Whenever localTrack.restart(...) is called, it will replace the mediaStreamTrack property, but the localTrack
 * object will stay the same.
 */
export function useMediaStreamTrack(track: AudioTrack | VideoTrack | null): MediaStreamTrack | null {
	const [mediaStreamTrack, setMediaStreamTrack] = useState<MediaStreamTrack | null>(
		track?.mediaStreamTrack || null,
	);

	useEffect(() => {
		setMediaStreamTrack(track?.mediaStreamTrack || null);

		if (track) {
			const handleStarted = (): void => {
				setMediaStreamTrack(track.mediaStreamTrack);
			};

			track.on("started", handleStarted);
			return () => {
				track.off("started", handleStarted);
			};
		}
	}, [track]);

	return mediaStreamTrack;
}
