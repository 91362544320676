/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file A loading indicator
 * @author Matt Panico
 * @module Epic.VideoApp.Components.Loading.Loading
 */

import React, { FC } from "react";
import { ITestable } from "~/types";
import SimpleCard from "../Cards/SimpleCard";
import CopyrightLinkButton from "../Copyright/CopyrightLinkButton";
import SimpleHeader from "../Header/SimpleHeader";
import Spinner from "./Spinner";

interface IProps extends ITestable {
	loadingMessage: string;
}

/**
 * The Loading component
 * @param _props The props ;)
 */
const Loading: FC<IProps> = (props) => {
	const { loadingMessage, ...mainProps } = props;
	return (
		<>
			<SimpleHeader />
			<main {...mainProps}>
				<SimpleCard>
					<h1>{loadingMessage}</h1>
					<Spinner />
				</SimpleCard>
			</main>
			<CopyrightLinkButton />
		</>
	);
};

Loading.displayName = "Loading";

export default Loading;
