/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Verifying if the users local storage token for skipping the hardware test is valid
 * @author Jonathon Moore
 * @module Epic.VideoApp.Utils.SkipHardwareTestGuardrails
 */

import {
	clearOldGuardOptions,
	loadUserGuardOptions,
	makeGuardOptionsBooleans,
	saveGuardOptions,
} from "~/utils/userGuardOptions";

// Function used to pull previous localStorage values, validate them, then save any updated values back to localStorage
export function getSkipHardwareTestGuardrails(userKey: string): boolean {
	let userGuardOptions = loadUserGuardOptions(userKey);

	userGuardOptions = clearOldGuardOptions(userGuardOptions, 1);
	saveGuardOptions(userKey, userGuardOptions);
	return makeGuardOptionsBooleans(userGuardOptions).skipHardwareTest;
}
