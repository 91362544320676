/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file shared state to centrally manage some aspects of the UI
 * @author Colin Walters
 * @module Epic.VideoApp.State.Ui
 */

import { buildSharedState } from "@epic/react-redux-booster";
import React from "react";
import store from "~/app/store";
import { IParticipantVideo, IPinningState, IPinSource, Menu, VideoUILayoutType } from "~/types";
import { IUser } from "~/web-core/interfaces";

/// TYPES ///

type VisibleMenu = Menu | null;
type MenuActionType = "mouse" | "keyboard" | null;
type SidebarStatus = "open" | "closed";

export interface IDynamicMenuData {
	title: string;
	participant: IUser;
}

export interface IUIState {
	readonly visibleMenuHistory: [VisibleMenu, VisibleMenu];
	readonly menuActionType: MenuActionType;
	readonly buttonTrayCollapsed: boolean;
	readonly pinningState: IPinningState;
	readonly lastFocusedParticipant: IParticipantVideo | null;
	readonly waitingForFocus: boolean;
	readonly imCapHovered: boolean;
	readonly videoLayout: VideoUILayoutType;
	readonly preferredLayout: VideoUILayoutType;
	readonly totalGridPageCount: number;
	readonly currentGridPage: number;
	readonly focusTrapIds: string[];
	readonly menuData: IDynamicMenuData | null;
	readonly requestMenuOpen: boolean;
	readonly sidebarStatus: SidebarStatus;
	readonly isFullScreenMode: boolean;
}

/// INIT ///

export function getInitialState(): IUIState {
	return {
		visibleMenuHistory: [null, null],
		menuActionType: null,
		buttonTrayCollapsed: false,
		pinningState: { lastUpdate: 0, pinnedVideo: null, updateSource: "Default" },
		lastFocusedParticipant: null,
		waitingForFocus: false,
		imCapHovered: false,
		currentGridPage: 0,
		totalGridPageCount: 0,
		videoLayout: "Active Speaker",
		preferredLayout: "Active Speaker",
		focusTrapIds: [],
		menuData: null,
		requestMenuOpen: false,
		sidebarStatus: "closed",
		isFullScreenMode: !!document.fullscreenElement,
	};
}

/// REDUCERS ///

interface IToggleMenuParams {
	menu: VisibleMenu;
	menuData?: IDynamicMenuData;
	actionType?: MenuActionType;
}

function toggleVisibleMenu(state: IUIState, params: IToggleMenuParams): IUIState {
	const { menu, actionType, menuData } = params;
	const [prevVisible] = state.visibleMenuHistory;
	const visibleMenu = menu && menu !== prevVisible ? menu : null;
	const menuInfo = menuData || null;
	return {
		...state,
		visibleMenuHistory: [visibleMenu, prevVisible],
		menuData: visibleMenu ? menuInfo : null,
		menuActionType: actionType ?? null,
	};
}

function toggleButtonTrayCollapsed(state: IUIState): IUIState {
	// set visible menu history to null so it closes instantaneously instead of animating
	return {
		...state,
		buttonTrayCollapsed: !state.buttonTrayCollapsed,
		visibleMenuHistory: [null, null],
		menuActionType: null,
	};
}

function forceButtonTrayOpen(state: IUIState): IUIState {
	return { ...state, buttonTrayCollapsed: false };
}

function toggleVideoLayout(state: IUIState): IUIState {
	const newLayout = state.videoLayout !== "Active Speaker" ? "Active Speaker" : "Grid";
	return { ...state, videoLayout: newLayout, preferredLayout: newLayout };
}

interface IPinnedVideoUpdateParams {
	pinnedVideo: IParticipantVideo | null;
	updateSource: IPinSource;
}

/**
 * Toggle whether or not the participant's video is pinned while ensuring that only one feed is pinned at any given time
 * @param pinnedVideo pinned video containing SID of the participant and video type, or null to remove pinned feed
 * @param updateSource whether this update is related to a direct pin attempt (click, screen share) or to hold the image capture focus
 */
export function toggleVideoPinned(state: IUIState, params: IPinnedVideoUpdateParams | null): IUIState {
	const { pinningState } = state;
	// if this is a participant video toggle and the last pinning action was in the last half second, ignore the action
	if (
		params?.pinnedVideo &&
		params.updateSource === "Default" &&
		pinningState.lastUpdate + 500 > Date.now()
	) {
		return state;
	}

	let newPinnedVideo: IParticipantVideo | null = null;
	const newPinSource = params?.updateSource ?? "Default";
	if (params) {
		const pinnedVideo = params.pinnedVideo;

		// Determine if the 'new' participant to be toggled is different
		if (
			pinnedVideo &&
			(pinnedVideo.identity !== pinningState.pinnedVideo?.identity ||
				pinnedVideo.videoType !== pinningState.pinnedVideo?.videoType)
		) {
			newPinnedVideo = params.pinnedVideo;
		}
	}

	const newPinningState = {
		lastUpdate: Date.now(),
		pinnedVideo: newPinnedVideo,
		updateSource: newPinSource,
	};

	// If we are pinning a new user, confirm the display mode is Active Speaker (pull out of Grid mode)
	// This will also result in a screen share defaulting the view to Active Speaker as well
	// If we are un-pinning go back to whichever was chosen via menu options
	return {
		...state,
		videoLayout: newPinnedVideo ? "Active Speaker" : state.preferredLayout,
		pinningState: newPinningState,
	};
}

function setLastFocusedParticipant(state: IUIState, focusedVideo: IParticipantVideo | null): IUIState {
	return {
		...state,
		lastFocusedParticipant: focusedVideo,
	};
}

function setWaitingForFocus(state: IUIState, waiting: boolean): IUIState {
	return {
		...state,
		waitingForFocus: waiting,
	};
}

export function setImCapHovered(state: IUIState, newIsHovered: boolean): IUIState {
	return {
		...state,
		imCapHovered: newIsHovered,
	};
}

export function addFocusTrap(state: IUIState, newFocusTrapId: string): IUIState {
	const newFocusTrapIds = [...state.focusTrapIds, newFocusTrapId];
	return {
		...state,
		focusTrapIds: newFocusTrapIds,
	};
}

export function removeFocusTrap(state: IUIState, focusTrapIdToRemove: string): IUIState {
	const newFocusTrapIds = [...state.focusTrapIds];

	const index = newFocusTrapIds.indexOf(focusTrapIdToRemove);
	if (index > -1) {
		newFocusTrapIds.splice(index, 1);
	}

	return {
		...state,
		focusTrapIds: newFocusTrapIds,
	};
}

export function setTotalPageCount(state: IUIState, pageCount: number): IUIState {
	// Don't allow the total page count to be out of bounds (higher than the current page)
	let currentPage = state.currentGridPage;
	if (currentPage >= pageCount) {
		currentPage = Math.max(0, pageCount - 1);
	}

	return { ...state, currentGridPage: currentPage, totalGridPageCount: pageCount };
}

export function setCurrentPage(state: IUIState, newPage: number): IUIState {
	if (newPage < 0 || newPage >= state.totalGridPageCount) {
		return state;
	}

	return { ...state, currentGridPage: newPage };
}

function setRequestMenuOpen(state: IUIState, open: boolean): IUIState {
	return { ...state, requestMenuOpen: open };
}

function setSidebarStatus(state: IUIState, newStatus: SidebarStatus): IUIState {
	return { ...state, sidebarStatus: newStatus };
}

function setIsFullScreenMode(state: IUIState, isFullScreen: boolean): IUIState {
	return { ...state, isFullScreenMode: isFullScreen };
}

/// SELECTORS ///

function getVisibleMenu(state: IUIState): VisibleMenu {
	return state.visibleMenuHistory[0];
}

function getVisibleMenuHistory(state: IUIState): [VisibleMenu, VisibleMenu] {
	return state.visibleMenuHistory;
}

function getDynamicMenuData(state: IUIState): IDynamicMenuData | null {
	return state.menuData;
}

function getMenuActionType(state: IUIState): MenuActionType {
	return state.menuActionType;
}

function getButtonTrayCollapsed(state: IUIState): boolean {
	return state.buttonTrayCollapsed;
}

function getPinnedVideo(state: IUIState): IParticipantVideo | null {
	return state.pinningState.pinnedVideo;
}

function getLastFocusedParticipant(state: IUIState): IParticipantVideo | null {
	return state.lastFocusedParticipant;
}

function getWaitingForFocus(state: IUIState): boolean {
	return state.waitingForFocus;
}

function getImCapHovered(state: IUIState): boolean {
	return state.imCapHovered;
}

function getVideoLayout(state: IUIState): VideoUILayoutType {
	return state.videoLayout;
}

function getCurrentPage(state: IUIState): number {
	return state.currentGridPage;
}

function getPageCount(state: IUIState): number {
	return state.totalGridPageCount;
}

function getActiveFocusTrap(state: IUIState): string {
	return state.focusTrapIds[state.focusTrapIds.length - 1];
}

function getRequestMenuOpen(state: IUIState): boolean {
	return state.requestMenuOpen;
}

function getSidebarStatus(state: IUIState): SidebarStatus {
	return state.sidebarStatus;
}

function getIsFullScreenMode(state: IUIState): boolean {
	return state.isFullScreenMode;
}

/// HELPERS ///

/**
 * Helper function to determine the menu action to pass to toggleVisibleMenu
 * Passing the event to toggleVisibleMenu results in some pretty big slow
 * downs, so use this to determine what should be passed in
 * @param event the event that trigger the menu action (i.e. close menu, change page)
 * @returns menu action type that should be passed to toggleVisibleMenu
 */
export function determineMenuActionType(event?: React.MouseEvent<HTMLButtonElement>): MenuActionType {
	if (!event) {
		return null;
	}
	return event.clientX === 0 && event.clientY === 0 ? "keyboard" : "mouse";
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		toggleVisibleMenu,
		forceButtonTrayOpen,
		toggleButtonTrayCollapsed,
		toggleVideoPinned,
		setLastFocusedParticipant,
		setWaitingForFocus,
		setImCapHovered,
		addFocusTrap,
		removeFocusTrap,
		setTotalPageCount,
		setCurrentPage,
		toggleVideoLayout,
		setRequestMenuOpen,
		setSidebarStatus,
		setIsFullScreenMode,
	},
	selectors: {
		getVisibleMenu,
		getVisibleMenuHistory,
		getMenuActionType,
		getButtonTrayCollapsed,
		getPinnedVideo,
		getLastFocusedParticipant,
		getWaitingForFocus,
		getImCapHovered,
		getVideoLayout,
		getActiveFocusTrap,
		getDynamicMenuData,
		getPageCount,
		getCurrentPage,
		getRequestMenuOpen,
		getSidebarStatus,
		getIsFullScreenMode,
	},
});

store.addSharedState(builtState.sharedState, "ui");

export const { actionCreators: uiActions, useSharedState: useUIState, sharedState: state } = builtState;
