/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file participant list
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Participants.ParticipantList.ParticipantList
 */

import { FC, default as React, useContext } from "react";
import { useUserState } from "~/state/user";
import { EpicUserType } from "~/types";
import { ParticipantDataContext } from "~/web-core/components";
import { useParticipantsOfType } from "../hooks/useParticipantsOfType";
import ParticipantMenuSection from "./ParticipantMenuSection";
import WaitingParticipantMenuSection from "./WaitingParticipantMenuSection";

/**
 * The ParticipantList component
 */
const ParticipantList: FC = () => {
	const { participants } = useContext(ParticipantDataContext);
	const canAdmit = useUserState((selectors) => selectors.getUserPermission("canStartEndVisit"), []);

	// Use the active user's type to determine if we can show the list of waiting participants & which user type to show first
	const userType = useUserState((selectors) => selectors.getUserType(), []);
	const careTeam = useParticipantsOfType(participants, [EpicUserType.emp]);
	const patientUsers = useParticipantsOfType(participants, [
		EpicUserType.rla,
		EpicUserType.wpr,
		EpicUserType.unknown,
	]);

	const isEmp = userType === EpicUserType.emp;

	// Users will see users of their own type second, and the opposite group first (wpr/rla/unknown see EMP, EMP see others)
	return (
		<div>
			{canAdmit && <WaitingParticipantMenuSection />}
			<ParticipantMenuSection
				participantList={isEmp ? patientUsers : careTeam}
				sectionUserType={isEmp ? EpicUserType.wpr : EpicUserType.emp}
			/>
			<ParticipantMenuSection
				participantList={isEmp ? careTeam : patientUsers}
				sectionUserType={isEmp ? EpicUserType.emp : EpicUserType.wpr}
			/>
		</div>
	);
};

ParticipantList.displayName = "ParticipantList";

export default ParticipantList;
