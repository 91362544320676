/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file keyboard shortcut component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Utilities.KeyboardShortcut
 */

import React, { FC } from "react";
import { useKeyboardShortcut } from "~/hooks";
import KeyboardShortcutText from "./KeyboardShortcutText";

/**
 * Props for KeyboardShortcut Component
 */
interface IProps {
	keyCombination: string[];
	callback: () => void;
	forModalAlert?: boolean;
	forImagePreviewPane?: boolean;
	className?: string;
	text?: string;
}

/**
 * The KeyboardShortcut component
 * @param props The props ;)
 */
const KeyboardShortcut: FC<IProps> = (props) => {
	const { keyCombination, callback, forModalAlert, forImagePreviewPane, text, className } = props;
	useKeyboardShortcut(keyCombination, callback, { forModalAlert, forImagePreviewPane });
	if (!text) {
		return null;
	}

	return <KeyboardShortcutText keyCombination={keyCombination} text={text} className={className} />;
};

KeyboardShortcut.displayName = "KeyboardShortcut";

export default KeyboardShortcut;
