/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file listen for LocalAudioTrack unmute events; call session.refreshMedia on unmute
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Hooks.LocalTracks.Workarounds.UseIOSMutedWorkaround
 */

import { useContext, useEffect } from "react";
import { LocalAudioTrack } from "twilio-video";
import { VideoSessionContext } from "~/web-core/components";

/**
 * @description Hook to attach listeners for "unmute" events and call refreshMedia. If an iOS device loses audio due to
 * backgrounding, an incoming phone call, etc., the LocalAudioTrack will be muted. When the device returns to EVC, the
 * LocalAudioTrack will be unmuted, but the media stream will not always be refreshed and audio won't always return.
 * @param track the LocalAudioTrack on which to listen for unmute events - non-affected devices should provide 'null'
 */
export function useIOSMutedWorkaround(track: LocalAudioTrack | null): void {
	const { session } = useContext(VideoSessionContext);
	useEffect(() => {
		if (!track) {
			return;
		}

		// If we get an unmuted event after an audio interruption is complete, trigger a media refresh
		const handleUnmuted = (): void => {
			session?.refreshMedia();
		};

		track.on("unmuted", handleUnmuted);
		return () => {
			track.off("unmuted", handleUnmuted);
		};
	}, [track, session]);
}
