/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file tooltip for any element
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Utilities.Tooltip
 */

import React, { FC, useLayoutEffect, useState } from "react";
import { resolveClassName } from "~/utils/className";
import styles from "./Tooltip.module.scss";
import { useSettingsState } from "~/state";
import { ENABLED_LOCALES } from "~/types/locale";

export type OffsetSafeDirection = "left" | "right";

/**
 * Props for Tooltip Component
 */
interface IProps extends ITooltipInfo {
	/** Parent element that the tooltip belongs to. MUST USE `position: relative` FOR THE TOOLTIP TO CENTER ON THE ELEMENT */
	elementWithTooltip: HTMLElement | null;
}

export interface ITooltipInfo {
	/** Text of the tooltip */
	text: string;

	/** Direction of the screen that the tooltip should be prevented from going off */
	direction: OffsetSafeDirection;
}

/**
 * The Tooltip component.
 * @param props The props ;)
 */
const Tooltip: FC<IProps> = (props) => {
	const { elementWithTooltip, text, direction } = props;

	const locale = useSettingsState((selectors) => selectors.getLocale(), []);
	const isRtl = !!ENABLED_LOCALES[locale]?.isRtl;

	const fixDirection = directionTransform(direction, isRtl);
	const offsetParent = elementWithTooltip?.offsetParent;
	const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({});
	const [pointerStyle, setPointerStyle] = useState<React.CSSProperties>({});

	useLayoutEffect(() => {
		if (!elementWithTooltip || !offsetParent) {
			return;
		}

		const parentRect = offsetParent.getBoundingClientRect();
		const elementRect = elementWithTooltip.getBoundingClientRect();
		const elementHeight = elementRect.height;

		const transform = getTooltipTransform(parentRect, elementRect, fixDirection);
		setTooltipStyle({ transform, top: elementHeight + 5 });
		setPointerStyle({ top: elementHeight - 5 });
	}, [elementWithTooltip, fixDirection, offsetParent, text, isRtl]); // include text to ensure offset is recalculated when language is changed

	const tooltipClassName = resolveClassName(styles, {
		tooltip: true,
		left: fixDirection === "left",
		right: fixDirection === "right",
	});

	return (
		<>
			<div className={styles["tooltipPointer"]} style={pointerStyle} />
			<span className={tooltipClassName} style={tooltipStyle}>
				{text}
			</span>
		</>
	);
};

function directionTransform(direction: OffsetSafeDirection, isRtl: boolean): OffsetSafeDirection {
	if (!isRtl) {
		return direction;
	}
	if (direction === "right") {
		return "left";
	}
	return "right";
}

/**
 * Determines the transform string for the css translation in x-direction of a tooltip
 *
 * @param elementRect - element with tooltip's containing rect
 * @param parentRect - element's parent's containing rect
 * @param offsetDirection - the direction in which the tooltip could go offscreen, will be offset in the opposite direction
 * @param margin - the minimum distance the button should be from the edge in offsetDirection
 * @returns transform that should be applied to the tooltip
 */
function getTooltipTransform(
	parentRect: DOMRect,
	elementRect: DOMRect,
	offsetDirection: OffsetSafeDirection,
	margin: number = 10,
): string {
	// Right and left elements start out with "right: 50%" and "left: 50%" in CSS respectively. This starts
	// their right or left edges in the middle of their parent element. Applying "translate(50%)" or
	// "translate(-50%)" will then translate the tooltip 50% of its own width in either direction,
	// centering the button. The math below calculates the distance the tooltip needs to be translated
	// to avoid overflowing the parentRect and translates the smaller distance via min/max.

	if (offsetDirection === "right") {
		const pixelsToRight = Math.ceil(
			parentRect.right - margin - elementRect.right + elementRect.width / 2,
		);
		return `translate(min(50%, ${pixelsToRight}px), 0)`;
	}

	const pixelsToLeft = Math.ceil(parentRect.left + margin - elementRect.left - elementRect.width / 2);
	return `translate(max(-50%, ${pixelsToLeft}px), 0)`;
}

Tooltip.displayName = "Tooltip";

export default Tooltip;
