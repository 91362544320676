/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Indicator with text and an icon that shows a participant has requested access to one of their devices
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Moderation.ModerationButtons.UnlockDeviceRequestIndicator
 */

import React, { FC } from "react";
import { useStrings } from "~/hooks";
import Bell from "~/icons/bell";
import styles from "../ParticipantControlMenu.module.scss";

enum TokenNames {
	request = "Requested",
}

/**
 * RequestIndicator
 */
const UnlockDeviceRequestIndicator: FC = () => {
	const strings = useStrings("ParticipantControlMenu", Object.values(TokenNames));
	return (
		<div className={styles["textIconWrapper"]}>
			<span className={styles["requestText"]}>{strings[TokenNames.request]}</span>
			<Bell className={styles["bell"]} />
		</div>
	);
};

UnlockDeviceRequestIndicator.displayName = "UnlockDeviceRequestIndicator";

export default UnlockDeviceRequestIndicator;
