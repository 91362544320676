/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Menu Page for setting patient admit controls
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Moderation.AdmitOptionsMenu
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import ToggleSwitchButton from "~/components/Utilities/ToggleSwitchButton";
import { useStrings } from "~/hooks";
import { moderationActions, useModerationState } from "~/state";
import styles from "./AdmitMenu.module.scss";

enum TokenNames {
	allowAudio = "AllowAudio",
	allowVideo = "AllowVideo",
	allowScreenShare = "AllowScreenShare",
}

export enum AdmitOptionsMenuTestIds {
	self = "AdmitOptionsMenu",
	allowAudioButton = "AdmitOptionsMenu-AllowAudioButton",
	allowVideoButton = "AdmitOptionsMenu-AllowVideoButton",
	allowScreenShareButton = "AdmitOptionsMenu-AllowScreenShareButton",
}

/**
 * AdmitOptionsMenuPage
 */
const AdmitOptionsMenuPage: FC = () => {
	const dispatch = useDispatch();
	const audioFlag = useModerationState((selectors) => selectors.getAudioAdmitOption(), []);
	const videoFlag = useModerationState((selectors) => selectors.getVideoAdmitOption(), []);
	const screenShareFlag = useModerationState((selectors) => selectors.getScreenShareAdmitOption(), []);
	const strings = useStrings("AdmitOptionsMenu", Object.values(TokenNames));

	const handleAudioChecked = useCallback(() => {
		dispatch(
			moderationActions.setAdmitOptions({
				audio: !audioFlag,
				video: videoFlag,
				screenShare: screenShareFlag,
			}),
		);
	}, [audioFlag, dispatch, screenShareFlag, videoFlag]);

	const handleVideoChecked = useCallback(() => {
		dispatch(
			moderationActions.setAdmitOptions({
				audio: audioFlag,
				video: !videoFlag,
				screenShare: screenShareFlag,
			}),
		);
	}, [audioFlag, dispatch, screenShareFlag, videoFlag]);

	const handleScreenShareChecked = useCallback(() => {
		dispatch(
			moderationActions.setAdmitOptions({
				audio: audioFlag,
				video: videoFlag,
				screenShare: !screenShareFlag,
			}),
		);
	}, [audioFlag, dispatch, screenShareFlag, videoFlag]);

	return (
		<div data-testid={AdmitOptionsMenuTestIds.self}>
			<ToggleSwitchButton
				text={strings[TokenNames.allowAudio]}
				onClick={handleAudioChecked}
				on={!audioFlag}
				className={styles["row"]}
				data-testid={AdmitOptionsMenuTestIds.allowAudioButton}
				id="admitAudioSwitch"
			/>
			<ToggleSwitchButton
				text={strings[TokenNames.allowVideo]}
				onClick={handleVideoChecked}
				on={!videoFlag}
				className={styles["row"]}
				data-testid={AdmitOptionsMenuTestIds.allowVideoButton}
				id="admitVideoSwitch"
			/>
			<ToggleSwitchButton
				text={strings[TokenNames.allowScreenShare]}
				onClick={handleScreenShareChecked}
				on={!screenShareFlag}
				className={styles["row"]}
				data-testid={AdmitOptionsMenuTestIds.allowScreenShareButton}
				id="admitScreenShareSwitch"
			/>
		</div>
	);
};

AdmitOptionsMenuPage.displayName = "AdmitOptionsMenuPage";

export default AdmitOptionsMenuPage;
