/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file hooks to get devices of a specific type
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseDevices
 */

import { useContext } from "react";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";

/** Note: These hooks should only ever be used within the DevicesContext within a higher level feature. This covers most components, but double check before using these in a new code path */

/**
 * Get devices of type "videoinput"
 */
export function useCameraDevices(): MediaDeviceInfo[] {
	const { devices } = useContext(DeviceContext);
	return devices.filter((device) => device.kind === "videoinput");
}

/**
 * Get devices of type "audioinput"
 */
export function useMicrophoneDevices(): MediaDeviceInfo[] {
	const { devices } = useContext(DeviceContext);
	return devices.filter((device) => device.kind === "audioinput");
}

/**
 * Get devices of type "audiooutput"
 */
export function useSpeakerDevices(): MediaDeviceInfo[] {
	const { devices } = useContext(DeviceContext);
	return devices.filter((device) => device.kind === "audiooutput");
}
