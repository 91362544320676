/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Device utilities
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.Device
 */

import React from "react";
import Camera from "~/icons/camera";
import DisabledCamera from "~/icons/disabledCamera";
import DisabledMic from "~/icons/disabledMic";
import disabledSpeaker from "~/icons/disabledSpeaker";
import enterPiP from "~/icons/enterPiP";
import exitPiP from "~/icons/exitPiP";
import LockedCamera from "~/icons/lockedCamera";
import LockedMicrophone from "~/icons/lockedMicrophone";
import LockedScreenShare from "~/icons/lockedScreenShare";
import Mic from "~/icons/mic";
import ScreenShareStart from "~/icons/screenShareStart";
import ScreenShareStop from "~/icons/screenShareStop";
import speaker from "~/icons/speaker";
import { isMobile } from "./os";

/**
 * Determine if the device is an audio device that was created by the OS
 * Some OS/Browser combos include a duplicate of their "default" and "communications" devices
 * with a deviceId of "default" or "communications"
 *
 * @param device device to check
 */
export function isOSDefinedAudioDevice(device: MediaDeviceInfo): boolean {
	// can't be an OS-defined audio device, if not an audio device
	if (!device.kind.includes("audio")) {
		return false;
	}

	// some non-mobile OS/Browser combos create duplicates of "default" and "communications" devices
	return !isMobile() && ["communications", "default"].includes(device.deviceId);
}

/**
 * Determine if the device is a video device that has a label containing 'infrared'
 *
 * @param device device to check
 */
export function isInfraredLabeledVideoDevice(device: MediaDeviceInfo): boolean {
	// can't be an infrared video device, if not a video device
	if (!device.kind.includes("video")) {
		return false;
	}

	return device.label.toLowerCase().includes("infrared");
}

/**
 * Convenience method to check isOSDefinedAudioDevice && isInfraredLabeledVideoDevice
 *
 * @param device device to check
 */
export function isExcludedDevice(device: MediaDeviceInfo): boolean {
	return isOSDefinedAudioDevice(device) || isInfraredLabeledVideoDevice(device);
}

interface IDevicePreference {
	label: string;
	id: string;
}

export interface IGetDevicePreference {
	(): IDevicePreference | null;
}

// local storage keys used for different device preferences
const cameraPreferenceKey = "camera-preference";
const micPreferenceKey = "mic-preference";
const speakerPreferenceKey = "speaker-preference";

/**
 * Get a device preference from local storage
 * @param key local storage key
 * @returns device preference or null if there is none
 */
function getDevicePreference(key: string): IDevicePreference | null {
	const value = localStorage.getItem(key);
	if (!value) {
		return null;
	}
	try {
		return JSON.parse(value) as IDevicePreference;
	} catch {
		return null;
	}
}

/**
 * Set a device preference in local storage
 * @param key local storage key
 * @param device the new preferred device
 */
function setDevicePreference(key: string, deviceOrLabel: MediaDeviceInfo): void {
	const { deviceId: id, label } = deviceOrLabel;
	localStorage.setItem(key, JSON.stringify({ id, label }));
}

/**
 * Get the ID of the preferred camera (the last manually selected camera) from cookies
 * @returns ID of the preferred camera
 */
export function getCameraPreference(): IDevicePreference | null {
	return getDevicePreference(cameraPreferenceKey);
}

/**
 * Set the cookie for the preferred camera
 * @param camera camera that is the new preferred camera
 */
export function setCameraPreference(camera?: MediaDeviceInfo): void {
	if (!camera) {
		return;
	}
	setDevicePreference(cameraPreferenceKey, camera);
}

/**
 * Get the ID of the preferred microphone (the last manually selected mic) from cookies
 * @returns ID of the preferred mic
 */
export function getMicPreference(): IDevicePreference | null {
	return getDevicePreference(micPreferenceKey);
}

/**
 * Set the cookie for the preferred mic
 * @param mic microphone that is the new preferred microphone
 */
export function setMicPreference(mic?: MediaDeviceInfo): void {
	if (!mic) {
		return;
	}
	setDevicePreference(micPreferenceKey, mic);
}

/**
 * Get the ID of the preferred speaker (the last manually selected speaker) from cookies
 * @returns ID of the preferred speaker
 */
export function getSpeakerPreference(): IDevicePreference | null {
	return getDevicePreference(speakerPreferenceKey);
}

/**
 * Set the cookie for the preferred speaker
 * @param speaker speaker that is the new preferred speaker
 */
export function setSpeakerPreference(speaker?: MediaDeviceInfo): void {
	if (!speaker) {
		return;
	}
	setDevicePreference(speakerPreferenceKey, speaker);
}

/**
 * Helper function to pick the proper microphone icon to render, based on the user's state
 * @param enabled - Whether the microphone is on or off
 * @param locked - Whether the microphone is locked by a moderator or not. Supersedes enabled status.
 * @returns The React icon to pass as a child
 */
export function getMicIcon(enabled: boolean, locked?: boolean): React.FC {
	if (locked) {
		return LockedMicrophone;
	}

	if (!enabled) {
		return DisabledMic;
	}

	return Mic;
}

/**
 * Helper function to pick the proper camera icon to render, based on the user's state
 * @param enabled - Whether the camera is on or off
 * @param locked - Whether the camera is locked by a moderator or not. Supersedes enabled status.
 * @returns The React icon to pass as a child
 */
export function getCameraIcon(enabled: boolean, locked?: boolean): React.FC {
	if (locked) {
		return LockedCamera;
	}

	if (!enabled) {
		return DisabledCamera;
	}

	return Camera;
}

/**
 * Helper function to pick the proper picture in picture icon to render, based on the user's state
 * @param enabled - Whether the video is in pip mode or not
 * @returns The React icon to pass as a child
 */
export function getPipIcon(isPIPOn: boolean): React.FC {
	if (!isPIPOn) {
		return enterPiP;
	}
	return exitPiP;
}

export function getScreenShareIcon(enabled: boolean, locked: boolean): React.FC {
	if (locked) {
		return LockedScreenShare;
	}
	if (enabled) {
		return ScreenShareStop;
	}
	return ScreenShareStart;
}

export function getScreenShareAudioIcon(enabled: boolean): React.FC {
	if (enabled) {
		return speaker;
	}
	return disabledSpeaker;
}
