/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file the Error Card component
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.Cards.ErrorCard
 */

import React, { FC, useCallback, useMemo } from "react";
import Card from "./Card";
import styles from "./ErrorCard.module.scss";
import SimpleCard from "./SimpleCard";

/**
 * Props for ErrorCard Component
 */
interface IProps {
	titleText: string;
	bodyText?: string;
	skipFocus?: boolean;
	skipPositioning?: boolean;
}

export enum ErrorCardTestIds {
	header = "ErrorCardHeader",
	body = "ErrorCardBody",
}

/**
 * The ErrorCard component
 * @param _props The props ;)
 */
const ErrorCard: FC<IProps> = (props) => {
	const { titleText, bodyText, skipFocus = false, skipPositioning = false, children } = props;

	const focusHeader = useCallback((headerRef: HTMLHeadingElement) => headerRef && headerRef.focus(), []);

	const cardContent = useMemo(() => {
		return (
			<>
				<h2
					className={styles["header"]}
					tabIndex={-1}
					ref={skipFocus ? undefined : focusHeader}
					data-testid={ErrorCardTestIds.header}
				>
					{titleText}
				</h2>

				{bodyText && <p data-testid={ErrorCardTestIds.body}>{bodyText}</p>}
				{children}
			</>
		);
	}, [bodyText, children, focusHeader, skipFocus, titleText]);

	return skipPositioning ? <Card>{cardContent}</Card> : <SimpleCard>{cardContent}</SimpleCard>;
};

ErrorCard.displayName = "ErrorCard";

export default ErrorCard;
