/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file branding state
 * @author Colin Walters
 * @module Epic.VideoApp.State.Branding
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { IBrandedColors, IBrandingConfig, IRemoteResource } from "~/types/branding";

/// TYPES ///

export type IBrandingState = IBrandingConfig;

/// INIT ///

export function getInitialState(): IBrandingState {
	return { logo: null, backgroundImage: null, colors: null, virtualBackgroundImages: null };
}

/// REDUCERS ///

export function setBrandingConfig(state: IBrandingState, config: IBrandingState | null): IBrandingState {
	return { ...state, ...config };
}

/// SELECTORS ///

function getLogo(state: IBrandingState): string | null {
	return state.logo ?? null;
}

function getBackgroundImage(state: IBrandingState): string | null {
	return state.backgroundImage ?? null;
}

function getColors(state: IBrandingState): IBrandedColors | null {
	return state.colors ?? null;
}

function getVirtualBackgroundImages(state: IBrandingState): IRemoteResource[] {
	return state.virtualBackgroundImages ?? [];
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	selectors: { getLogo, getBackgroundImage, getColors, getVirtualBackgroundImages },
});
store.addSharedState(builtState.sharedState, "branding");

export const {
	actionCreators: brandingActions,
	useSharedState: useBrandingState,
	sharedState: state,
} = builtState;
