/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Hook to get the media stream track from a Stream
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Hooks.UseMediaTrack
 */

import { useEffect, useState } from "react";
import { IEVCMediaTrackEvent } from "~/web-core/events";
import { IStream } from "~/web-core/interfaces";
import { DeviceKind } from "~/web-core/types";

/**
 * This hook allows components to reliably use the 'mediaStreamTrack' property of an audio or video stream.
 */
export function useMediaTrack(stream: IStream | undefined, kind: DeviceKind): MediaStreamTrack | null {
	const [mediaStreamTrack, setMediaStreamTrack] = useState<MediaStreamTrack | null>(
		stream?.getMediaStreamTrack(kind) || null,
	);

	useEffect(() => {
		setMediaStreamTrack(stream?.getMediaStreamTrack(kind) || null);

		const handleStarted = (args: IEVCMediaTrackEvent): void => {
			setMediaStreamTrack(args.track);
		};
		const eventType = kind === "audio" ? "audioReady" : "videoReady";

		stream?.on(eventType, handleStarted);
		return () => {
			stream?.off(eventType, handleStarted);
		};
	}, [kind, stream]);

	return mediaStreamTrack;
}
