/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file Button for moderators to allow or block screen sharing access
 * @author Trevor Roussel
 * @module Epic.VideoApp.Components.Participants.Moderation.ModerationButtons.LockParticipantScreenShareButton
 */
import React, { FC, useCallback } from "react";
import BaseButton from "~/components/Utilities/BaseButton";
import ToggleSwitchButton from "~/components/Utilities/ToggleSwitchButton";
import { useStrings } from "~/hooks";
import { useSendModeratorAction } from "~/hooks/messaging/index";
import { useModerationState, useRoomState } from "~/state";
import { EpicUserType } from "~/types";
import { getScreenShareIcon } from "~/utils/device";
import UnlockDeviceRequestIndicator from "./UnlockDeviceRequestIndicator";

interface IDisableProps {
	identity: string;
	className: string;
	enabled: boolean;
	labelClassName?: string;
	inNameBar?: boolean;
	userType?: EpicUserType;
	iconClassName?: string;
	toggleSwitchClassName?: string;
}

/** String tokens used by the ParticipantControlMenu -> LockParticipantScreenShareButton  component */
enum TokenNames {
	allowShare = "AllowShare",
	deviceCannotShare = "DeviceCannotShare",
	turnOffShare = "TurnOffShare",
	shareBlocked = "ShareBlocked",
}

export enum LockParticipantScreenShareButtonTestIds {
	toggleButton = "LockParticipantScreenShareButton-ToggleButton",
	baseButton = "LockParticipantScreenShareButton-BaseButton",
}

const LockParticipantScreenShareButton: FC<IDisableProps> = (props: IDisableProps) => {
	const {
		className,
		labelClassName,
		enabled,
		identity,
		userType,
		iconClassName,
		toggleSwitchClassName,
		inNameBar,
	} = props;
	const strings = useStrings("LockParticipantScreenShareButton", Object.values(TokenNames));

	const sendModeratorMessage = useSendModeratorAction();

	const participantModeration = useRoomState(
		(selectors) => selectors.getParticipantModerationLocks(identity),
		[identity],
	);
	const requestInfo = useModerationState(
		(selectors) => selectors.getParticipantRequest(identity),
		[identity],
	);
	const screenShareRequested = requestInfo.screenShare;

	const participantInfo = useRoomState((selectors) => selectors.getParticipantInfo(identity), [identity]);
	const participantCanShare = participantInfo?.deviceSupportsScreenShare;
	const participantShareAllowed = participantInfo?.screenShareAllowed;

	const { screenShare: screenShareLock } = participantModeration;

	// Only show the switch button for participants who can be moderated and have screen share capabilities
	const showSwitchButton = participantCanShare && participantShareAllowed && userType !== EpicUserType.emp;

	const onDisableClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			sendModeratorMessage({ screenShareLock: !screenShareLock }, identity, true);

			// stop propagation to avoid also triggering the onClick
			event?.stopPropagation();
		},
		[sendModeratorMessage, screenShareLock, identity],
	);

	// Show a different string based on configuration support for screen share and device capabilities
	const shareText = !participantShareAllowed
		? strings[TokenNames.shareBlocked]
		: participantCanShare
		? strings[TokenNames.turnOffShare]
		: strings[TokenNames.deviceCannotShare];

	// If the user's device doesn't support sharing or they are an emp-type user not currently sharing, then disable the button
	const disableButton =
		!participantCanShare || !participantShareAllowed || (userType === EpicUserType.emp && !enabled);

	// In the header, we display the 'start' icon when the user is not currently sharing,
	// but in this moderation context, we want to display the 'stop' icon and vice versa
	// If the user cannot share due to device compatibility or config, show the lock icon
	const screenShareIcon = getScreenShareIcon(
		!enabled,
		screenShareLock || !participantShareAllowed || !participantCanShare,
	);
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{showSwitchButton ? (
				<ToggleSwitchButton
					text={strings[TokenNames.allowShare]}
					className={className}
					onClick={onDisableClick}
					on={!screenShareLock}
					labelClassName={labelClassName}
					toggleSwitchClassName={toggleSwitchClassName}
					data-testid={LockParticipantScreenShareButtonTestIds.toggleButton}
					id="lockScreenShareSwitch"
				>
					{screenShareRequested && !inNameBar && <UnlockDeviceRequestIndicator />}
				</ToggleSwitchButton>
			) : (
				<BaseButton
					className={className}
					icon={screenShareIcon}
					text={shareText}
					onClick={onDisableClick}
					labelClassName={labelClassName}
					aria-label={shareText}
					disabledTabbable={disableButton}
					iconClassName={iconClassName}
					data-testid={LockParticipantScreenShareButtonTestIds.baseButton}
				/>
			)}
		</>
	);
};

LockParticipantScreenShareButton.displayName = "LockParticipantScreenShareButton";

export default LockParticipantScreenShareButton;
