/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Hardware Test Component
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.HardwareTest
 */

import React, { FC, PropsWithChildren, ReactElement } from "react";
import { useWindowSize } from "~/hooks";
import { useLocalTrackState } from "~/state";
import { ScreenWidthInflectionPoint, StyleContext } from "~/types";
import { resolveClassName } from "~/utils/className";
import Card from "../Cards/Card";
import CardContainer from "../Cards/CardContainer";
import CopyrightLinkButton from "../Copyright/CopyrightLinkButton";
import SimpleHeader from "../Header/SimpleHeader";
import { BackgroundStyleContextProvider } from "../Utilities/BackgroundStyleContext";
import HardwareCardContents from "./HardwareCardContents";
import styles from "./HardwareTest.module.scss";

interface IProps {
	mainButton: ReactElement;
	isLobby?: boolean;
	hideHardwareTest?: boolean;
}

/**
 * The HardwareTest component
 *
 * Shows a dynamic hardware test for mobile and web views that lets users verify their hardware and display name before joining a visit
 *
 * @param props the props ;)
 */
const HardwareTest: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { isLobby, mainButton, hideHardwareTest } = props;

	const localTrackAcquisitionStatus = useLocalTrackState(
		(selectors) => selectors.getLocalTrackAcquisitionStatus(),
		[],
	);
	const showingWarning = localTrackAcquisitionStatus === "showingWarning"; // True if the alert requesting hardware permissions is being shown.
	const { width } = useWindowSize();

	const hardwareTestStyling = resolveClassName(styles, { hiddenHardwareTest: hideHardwareTest });

	return (
		<>
			{!hideHardwareTest && <SimpleHeader />}
			<main className={hardwareTestStyling}>
				{width > ScreenWidthInflectionPoint ? (
					<>
						<CardContainer hidden={showingWarning} wideCard>
							<Card rawClass={styles["hardwareCard"]}>
								<HardwareCardContents isLobby={isLobby} mainButton={mainButton} />
							</Card>
						</CardContainer>
						<CopyrightLinkButton />
					</>
				) : (
					<BackgroundStyleContextProvider style={StyleContext.light}>
						<div className={styles["mobileHardwareBackground"]}>
							<HardwareCardContents isLobby={isLobby} mainButton={mainButton} />
						</div>
					</BackgroundStyleContextProvider>
				)}
			</main>
		</>
	);
};

HardwareTest.displayName = "HardwareTest";

export default HardwareTest;
