/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Image Capture Icon
 * @author Spencer Eanes
 * @module Epic.VideoApp.Icons.ImageCapture
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The ImageCapture component
 * @param props The props ;)
 */
const ImageCapture: FC<IconProps> = memo((props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
			<g>
				<path d="M23.87,23.44H8.11A2.1,2.1,0,0,1,6,21.33V12a2.1,2.1,0,0,1,2.11-2.1H23.87A2.1,2.1,0,0,1,26,12v9.34A2.1,2.1,0,0,1,23.87,23.44ZM16.08,12.15a4.52,4.52,0,1,0,4.51,4.51h0A4.51,4.51,0,0,0,16.08,12.15Zm6.77-.35a1,1,0,0,0,0,2.08,1,1,0,0,0,1-1h0a1,1,0,0,0-1-1Z" />
				<rect x="10" y="8" width="11.99" height="4.17" rx="2.08" />
				<circle cx="16.08" cy="16.66" r="3.3" />
			</g>
		</svg>
	);
});

ImageCapture.displayName = "ImageCapture";

export default ImageCapture;
