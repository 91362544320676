/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook for URL Search params
 * @author Matt Panico
 * @module Epic.VideoApp.Hooks.UseSearchParams
 */
import { useLocation } from "@reach/router";
import { useMemo } from "react";

export function useSearchParams(): URLSearchParams {
	const location = useLocation();

	return useMemo(() => {
		return new URLSearchParams(location.search);
	}, [location]);
}

export function useCaseInsensitiveSearchParam(search: string): string | null {
	const searchParams = useSearchParams();

	return useMemo(() => {
		for (const [key, value] of searchParams) {
			if (key.toLowerCase() === search.toLowerCase()) {
				return value;
			}
		}
		return null;
	}, [searchParams, search]);
}
