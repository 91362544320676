/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Manages the state of the chat users in the application
 * @author Noah Allen
 * @module Epic.VideoApp.State.ChatUsers
 */
import { IUser, IUserMap } from "@epic/chat";
import { buildSharedState } from "@epic/react-redux-booster";
import { userIdHash } from "~/utils/userIdHash";
import store from "../app/store";

/// INIT ///

export function getInitialState(): IUserMap {
	const users = {};
	return users;
}

/// REDUCERS ///

export function addOrUpdateUser(state: IUserMap, user: Partial<IUser> & { id: string }): IUserMap {
	const existingUser = state[user.id];

	return {
		...state,
		[user.id]: {
			...existingUser,
			...user,
			color: userIdHash(user.id),
			isTyping: user?.isTyping ?? false,
		},
	};
}

export function addOrUpdateUsers(state: IUserMap, users: IUser[]): IUserMap {
	let newState = { ...state };
	users.forEach((user) => {
		const existingUser = newState[user.id];
		newState = {
			...newState,
			[user.id]: {
				...existingUser,
				...user,
				color: userIdHash(user.id),
				isTyping: existingUser?.isTyping,
			},
		};
	});
	return newState;
}

export function setUserProperty(
	state: IUserMap,
	action: { userId: string; property: keyof IUser; value: boolean },
): IUserMap {
	const { userId, property, value } = action;
	const user = state[userId];
	if (!user) {
		return state;
	}
	const updatedUser = { ...user, [property]: value };
	return addOrUpdateUser(state, updatedUser);
}

/// SELECTORS ///

export function getUsers(state: IUserMap): IUserMap {
	return state;
}

export function getTypingUsersArray(state: IUserMap): IUser[] {
	return Object.values(state).filter((user) => user.isTyping);
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		addOrUpdateUser,
		setUserProperty,
		addOrUpdateUsers,
	},
	selectors: {
		getUsers,
		getTypingUsersArray,
	},
});

store.addSharedState(builtState.sharedState, "chatUsers");

export const {
	actionCreators: chatUserActions,
	useSharedState: useChatUserState,
	sharedState: state,
} = builtState;
