/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to follow 'enabled' status of a Track
 * @author Matt Panico / Colin Walters
 * @module Epic.VideoApp.Hooks.TrackUtilities.UseIsTrackEnabled
 */

import { useEffect, useState } from "react";
import { AudioTrack, VideoTrack } from "twilio-video";

type TrackType = AudioTrack | VideoTrack | null | undefined;

export function useIsTrackEnabled(track: TrackType): boolean {
	const [isEnabled, setIsEnabled] = useState(track?.isEnabled ?? false);

	useEffect(() => {
		setIsEnabled(track?.isEnabled ?? false);

		if (track) {
			const setEnabled = (): void => setIsEnabled(true);
			const setDisabled = (): void => setIsEnabled(false);

			track.on("enabled", setEnabled);
			track.on("disabled", setDisabled);

			return () => {
				track.off("enabled", setEnabled);
				track.off("disabled", setDisabled);
			};
		}
	}, [track]);

	return isEnabled;
}
