/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file leave icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Leave
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";
import styles from "../styles/_directional.scss";

/**
 * The Leave component
 * @param props The props ;)
 */
const Leave: FC<IconProps> = memo((props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			className={styles["flipWithPage"]}
			{...props}
		>
			<g>
				<path d="M28.65,15.66l-4.84-4.84a.48.48,0,0,0-.82.33v3.37H16a.47.47,0,0,0-.47.47v2a.47.47,0,0,0,.47.48h7v3.35a.48.48,0,0,0,.82.33l4.84-4.84A.48.48,0,0,0,28.65,15.66Z" />
				<path d="M18,22H11.48a.48.48,0,0,1-.48-.48v-11a.48.48,0,0,1,.48-.48H18a.49.49,0,0,1,.48.48v2A.47.47,0,0,0,19,13H21a.47.47,0,0,0,.47-.48v-5A.48.48,0,0,0,21,7H8.48A.48.48,0,0,0,8,7.48v17a.48.48,0,0,0,.48.48H21a.48.48,0,0,0,.47-.48v-5A.47.47,0,0,0,21,19H19a.47.47,0,0,0-.47.48v2A.49.49,0,0,1,18,22Z" />
			</g>
		</svg>
	);
});

Leave.displayName = "Leave";

export default Leave;
