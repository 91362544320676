/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file The area of the participants menu reserved for showing participants not yet in the call
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.ParticipantList.WaitingParticipantMenuSection
 */

import React, { FC, useContext } from "react";
import { useBucketedParticipants, useStrings } from "~/hooks";
import { useUserState } from "~/state";
import { ParticipantDataContext } from "~/web-core/components";
import AdmitParticipantsButton from "./AdmitParticipantsButton";
import ParticipantSectionHeader from "./ParticipantSectionHeader";
import WaitingParticipantRow from "./WaitingParticipantRow";

export enum TokenNames {
	waitingHeader = "WaitingHeader",
	admitAll = "AdmitAll",
}

/**
 * The ParticipantMenuSection component
 */
const WaitingParticipantMenuSection: FC = () => {
	const { waitingParticipants } = useContext(ParticipantDataContext);
	const canAdmit = useUserState((selectors) => selectors.getUserPermission("canStartEndVisit"), []);
	const strings = useStrings("WaitingParticipantMenuSection", Object.values(TokenNames));
	const sortedParticipants = useBucketedParticipants(waitingParticipants);

	if (!sortedParticipants || sortedParticipants.length < 1 || !canAdmit) {
		return null;
	}

	return (
		<div>
			<ParticipantSectionHeader headerText={strings[TokenNames.waitingHeader]}>
				{sortedParticipants.length > 1 && (
					<AdmitParticipantsButton waitingParticipants={sortedParticipants} />
				)}
			</ParticipantSectionHeader>
			{sortedParticipants.map(({ participant, displayName }) => (
				<WaitingParticipantRow
					key={participant.getUserIdentity()}
					participant={participant}
					displayName={displayName}
				/>
			))}
		</div>
	);
};

WaitingParticipantMenuSection.displayName = "WaitingParticipantMenuSection";

export default WaitingParticipantMenuSection;
