/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Combined reducer for setting participant specific information
 * @author Will Cooper
 * @module Epic.VideoApp.State.Combined.Participant
 */

import { withSharedStates } from "@epic/react-redux-booster";
import { IParticipantInfo } from "~/types";
import * as alerts from "../alerts";
import * as moderation from "../moderation";
import * as room from "../room";
import { getRemoteScreenShareToast } from "./screenSharing";

/// COMBINED STATE TYPES ///

type CombinedParticipantState = [room.IRoomState, alerts.IAlertState, moderation.IModerationState];
/// COMBINED REDUCERS ///

/**
 * Set a participant's info in shared state and update any toasts that belong to that user
 * @param state shared state prior to this action
 * @param info participant info to update
 * @returns the new shared state after this action is applied
 */
function setParticipantInfo(
	state: CombinedParticipantState,
	info: IParticipantInfo,
): CombinedParticipantState {
	const [prevRoomState, prevAlertState, prevModerationState] = state;

	const newRoomState = room.setParticipantInfo(prevRoomState, info);

	// if there is a toast that this user is sharing their screen, update it with their new name
	let newAlertState = prevAlertState;
	const toast = Object.values(prevAlertState.toasts).find(
		(toast) => toast.type === "remote-screen-shared" && toast.extraInfo.identity === info.identity,
	);
	if (toast) {
		newAlertState = alerts.postToastAlert(prevAlertState, getRemoteScreenShareToast(info.identity, info));
	}

	const oldRequest = prevModerationState.requestedParticipants.find(
		(participant) => participant.identity === info.identity,
	);

	let newModerationState;

	// Manage requests when we received stateful updates
	if (oldRequest) {
		const { audio, video, screenShare } = oldRequest;
		const newRequestEntry: room.IParticipantRequest = {
			identity: info.identity,
			audio: audio,
			video: video,
			screenShare: screenShare,
		};

		// Check individual update status
		if (audio && !info.micLocked) {
			newRequestEntry.audio = false;
		}
		if (video && !info.cameraLocked) {
			newRequestEntry.video = false;
		}
		if (screenShare && !info.screenShareLocked) {
			newRequestEntry.screenShare = false;
		}
		if (!newRequestEntry.audio && !newRequestEntry.video && !newRequestEntry.screenShare) {
			// Clear state if we are there are no unfilled requests
			newModerationState = moderation.clearParticipantRequest(prevModerationState, info.identity);
		} else {
			// Update state otherwise
			newModerationState = moderation.postParticipantRequest(prevModerationState, newRequestEntry);
		}
	}

	return [newRoomState, newAlertState, newModerationState || prevModerationState];
}

/// BUILD IT ///

export const participantCombinedReducers = {
	setParticipantInfo: withSharedStates(room.state, alerts.state, moderation.state).buildReducer(
		setParticipantInfo,
	),
};
