/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file hook to get survey questionnaire
 * @author Ben Vuong
 * @module Epic.VideoApp.Components.FeedbackSurvey.Hooks.UseLoadFeedbackSurveyQuestionnaire
 */

import { useDispatch } from "@epic/react-redux-booster/dist/use-dispatch";
import { useRef } from "react";
import { combinedActions, useAuthState } from "~/state";
import { feedbackSurveyActions, useFeedbackSurveyState } from "~/state/feedbackSurvey";
import { FeedbackPageStep, FeedbackSurveyLoadingStatus, ISurvey } from "~/types/survey";
import { makeRequest } from "~/utils/request";

/**
 * Attempts to grab the feedback survey questionnaire
 */
export function useLoadFeedbackSurveyQuestionnaire(): void {
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);
	const initialStep = useFeedbackSurveyState((selectors) => selectors.getFeedbackStep(), []);
	const feedbackSurveyQuestionnaire = useFeedbackSurveyState(
		(selectors) => selectors.getFeedbackSurveyQuestionnaire(),
		[],
	);
	const dispatch = useDispatch();
	const hasRequestedSurvey = useRef<boolean>(false);

	if (
		JWT &&
		!feedbackSurveyQuestionnaire &&
		!hasRequestedSurvey.current &&
		initialStep !== FeedbackPageStep.hidden
	) {
		hasRequestedSurvey.current = true;
		getFeedbackSurveyQuestionnaire(JWT)
			.then((loadedFeedbackQuestionnaire) => {
				if (loadedFeedbackQuestionnaire) {
					dispatch(
						feedbackSurveyActions.setFeedbackSurveyQuestionnaire(loadedFeedbackQuestionnaire),
					);
				}
			})
			.catch(() => {
				dispatch(
					feedbackSurveyActions.setFeedbackLoadingState(FeedbackSurveyLoadingStatus.failedToLoad),
				);
				dispatch(combinedActions.clearSurveyState(false));
			});
	}
}

async function getFeedbackSurveyQuestionnaire(jwt: string): Promise<ISurvey> {
	return makeRequest("/api/FeedbackSurvey/GetSurveyQuestionnaire", "POST", jwt);
}
