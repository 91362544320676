/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Image capture button
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.ImageCapture.ImageCaptureButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext, useMemo } from "react";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import Error from "~/icons/error";
import ImageCaptureCamera from "~/icons/imageCapture";
import { combinedActions, imageActions, uiActions, useUIState } from "~/state";
import { resolveClassName } from "~/utils/className";
import { isMobile } from "~/utils/os";
import { ParticipantStatusContext } from "~/web-core/components";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { IUser } from "~/web-core/interfaces";
import { VideoType } from "~/web-core/types";
import { useStrings } from "../../hooks";
import BaseButton from "../Utilities/BaseButton";
import styles from "./ImageCaptureButton.module.scss";

export enum TokenNames {
	captureMode = "CaptureMode",
	takeCapture = "TakeCapture",
	captureImageTooltip = "CaptureImageTooltipNoHotkey",
	cancelButton = "CancelButton",
}

/**
 * Props for Image Capture Component
 */
interface IProps {
	participant: IUser;
	videoType: VideoType;
}

const ImageCaptureButton: FC<IProps> = (props: IProps) => {
	const { participant, videoType } = props;
	const isHovered = useUIState((selectors) => selectors.getImCapHovered(), []);
	const dispatch = useDispatch();
	const mobile = isMobile();

	const { callLayout } = useContext(ParticipantStatusContext);
	const twoParticipantCall = callLayout === "two-feeds";

	const stream = useStream(participant, videoType);
	const isEnabled = useIsStreamEnabled("video", stream);

	const strings = useStrings("ImageCaptureButton", Object.values(TokenNames));

	const id = participant.getUserIdentity();

	const streamCanBeCaptured = useMemo(() => {
		return !!stream && isEnabled;
	}, [isEnabled, stream]);

	const captureKeyboardShortcut = ["alt", "i"];
	const captureTooltipFormatted = useGetFormattedHotkeyString(
		strings[TokenNames.captureImageTooltip],
		captureKeyboardShortcut,
	);

	const onClick = useCallback(
		async (event?: React.MouseEvent<HTMLButtonElement>) => {
			// Stop propagation, so this doesn't click on the main feed and toggle pinning
			event?.stopPropagation();

			if (!streamCanBeCaptured) {
				return;
			}
			dispatch(uiActions.setSidebarStatus("closed"));

			if (mobile) {
				if (!isHovered) {
					dispatch(
						combinedActions.setImageCaptureActive({
							newPin: { identity: id, videoType: videoType },
						}),
					);
					return;
				}
			}

			if (stream) {
				const image = await stream.captureImage();
				dispatch(
					imageActions.setImageData({
						imageData: image ?? "",
						participantId: id,
					}),
				);
			}
		},
		[streamCanBeCaptured, mobile, stream, dispatch, id, isHovered, videoType],
	);

	const redXOnClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			dispatch(combinedActions.clearImageCaptureActive());
			event?.stopPropagation();
		},
		[dispatch],
	);

	const mouseEnter = useCallback(() => {
		if (!mobile || !streamCanBeCaptured) {
			dispatch(
				combinedActions.setImageCaptureActive({
					newPin: { identity: id, videoType: videoType },
				}),
			);
		}
	}, [mobile, streamCanBeCaptured, dispatch, id, videoType]);

	const mouseLeave = useCallback(() => {
		if (!mobile || !streamCanBeCaptured) {
			dispatch(combinedActions.clearImageCaptureActive());
		}
	}, [mobile, streamCanBeCaptured, dispatch]);

	const captureButtonStyle = resolveClassName(styles, {
		captureButton: true,
		hoverCaptureButton: isHovered,
	});

	const captureContainer = resolveClassName(styles, {
		captureContainer: true,
		twoPartyCall: twoParticipantCall,
	});

	const captureButtonAriaLabel =
		isHovered || !mobile ? strings[TokenNames.takeCapture] : strings[TokenNames.captureMode];
	const captureButtonLabelFormatted = useGetFormattedHotkeyString(
		captureButtonAriaLabel,
		captureKeyboardShortcut,
	);

	return (
		<div className={captureContainer}>
			<BaseButton
				aria-label={captureButtonLabelFormatted}
				rawClass={captureButtonStyle}
				onMouseEnter={mouseEnter}
				onMouseLeave={mouseLeave}
				onClick={onClick}
				icon={ImageCaptureCamera}
				keyboardShortcut={captureKeyboardShortcut}
				tooltipInfo={{ text: captureTooltipFormatted, direction: "left" }}
			/>
			{mobile && isHovered && (
				<BaseButton
					aria-label={strings[TokenNames.cancelButton]}
					rawClass={styles["redX"]}
					onClick={redXOnClick}
					icon={Error}
					keyboardShortcut={["alt", "x"]}
				/>
			)}
		</div>
	);
};

ImageCaptureButton.displayName = "ImageCaptureButton";

export default ImageCaptureButton;
