/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Image Capture Picture Preview
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.ImageCapture.ImageCapturePreview
 */

import React, { FC, RefObject } from "react";
import { useStrings } from "~/hooks";
import styles from "./ImagePreviewPane.module.scss";

enum TokenNames {
	capturePreview = "CapturePreview",
}

/**
 * Props for ImageCapturePreview Component
 */
interface IProps {
	image: string;
	imageWrapperRef?: RefObject<HTMLDivElement>;
}

/**
 * The ImageCapturePreview component
 * @param props The props
 */
const ImageCapturePreview: FC<IProps> = (props) => {
	const { image, imageWrapperRef } = props;
	const strings = useStrings("ImagePreviewPane", Object.values(TokenNames));

	return (
		<div className={styles["imageWrapper"]} ref={imageWrapperRef} tabIndex={-1}>
			<img alt={strings[TokenNames.capturePreview]} src={image} />
		</div>
	);
};

ImageCapturePreview.displayName = "ImageCapturePreview";

export default ImageCapturePreview;
