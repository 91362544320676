/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file hook to get the room's current dominant speaker
 * @author Colin Walters
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UseDominantSpeaker
 */

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUIState } from "~/state";
import { Timeout } from "~/types";
import { IEVCDominantSpeakerEvent, IEVCParticipantConnectionEvent } from "~/web-core/events";
import { IRemoteUser, ISession } from "~/web-core/interfaces";

interface IDominantSpeakerUpdate {
	timeout: Timeout;
	newDominantSpeaker: IRemoteUser;
}

/**
 * Hook to get the session's current dominant speaker
 * @param session the video vendor session
 * @param inCallParticipants the participants currently in the call
 * @returns the session's current dominant speaker, or undefined for no dominant speaker
 */
export function useDominantSpeaker(
	session: ISession,
	inCallParticipants: IRemoteUser[],
): IRemoteUser | undefined {
	const [dominantSpeaker, setDominantSpeaker] = useState<IRemoteUser | null>(session.getDominantSpeaker());
	const pendingUpdate = useRef<IDominantSpeakerUpdate | null>(null);

	// the duration of time (in milliseconds) that a user must be the dominant speaker before we trigger a switch
	// Be more aggressive with this in grid view because it just creates a popup rather than a call layout change
	const displayMode = useUIState((selectors) => selectors.getVideoLayout(), []);
	const dominantSpeakerChangeDelay = displayMode === "Active Speaker" ? 3000 : 500;

	// Get the set of participant IDs in-call to validate received dominant speaker updates
	const inCallParticipantIds = useMemo<Set<string>>(() => {
		return new Set(inCallParticipants.map((participant) => participant.getUserIdentity()));
	}, [inCallParticipants]);

	const updateDominantSpeaker = useCallback((newDominantSpeaker: IRemoteUser): void => {
		// update the dominant speaker and tracking ref
		setDominantSpeaker(newDominantSpeaker);
		pendingUpdate.current = null;
	}, []);

	const onPendingUpdateExpire = useCallback(() => {
		if (pendingUpdate.current?.newDominantSpeaker) {
			updateDominantSpeaker(pendingUpdate.current.newDominantSpeaker);
		}
	}, [updateDominantSpeaker]);

	useEffect(() => {
		const handleDominantSpeakerChanged = (args: IEVCDominantSpeakerEvent): void => {
			const newDominantSpeaker = args.participant;
			// In general, we want to only switch active speakers when there has been a noticeable shift in active speaker.
			// To become the new active speaker, one must be the active speaker without interruption
			if (!newDominantSpeaker) {
				// sometimes, the new dominant speaker will be null meaning there's no dominant speaker, clear any pending
				// updates and continue to display the current dominant speaker until we have one clear dominant speaker again
				if (pendingUpdate.current) {
					clearTimeout(pendingUpdate.current.timeout);
					pendingUpdate.current = null;
				}
			} else {
				if (!inCallParticipantIds.has(newDominantSpeaker.getUserIdentity())) {
					// if the new dominant speaker is not yet admitted to the call, ignore the update
					return;
				}
				// If the dominant speaker is anything but the currently pended, restart the timer
				if (pendingUpdate.current) {
					clearTimeout(pendingUpdate.current.timeout);
					pendingUpdate.current = null;
				}
				// If the new dominant speaker is different than the current, start a new timeout
				if (newDominantSpeaker.getUserIdentity() !== dominantSpeaker?.getUserIdentity()) {
					// pend the dominant speaker switch
					const timeout = setTimeout(onPendingUpdateExpire, dominantSpeakerChangeDelay);
					pendingUpdate.current = { timeout, newDominantSpeaker };
				}
				// If there is not a new and different dominant speaker, do not start a new loop
			}
		};

		// because "null" is ignored for "dominantSpeakerChanged", we need to listen for the dominant speaker to disconnect
		const handleParticipantDisconnected = (
			args: IEVCParticipantConnectionEvent<"participantDisconnected">,
		): void => {
			setDominantSpeaker((prevDominantSpeaker) => {
				// if there's a pending update to set this participant as the dominant speaker, clear it
				if (
					pendingUpdate.current?.newDominantSpeaker.getUserIdentity() ===
					args.participant.getUserIdentity()
				) {
					clearTimeout(pendingUpdate.current?.timeout);
					pendingUpdate.current = null;
				}
				return prevDominantSpeaker?.getUserIdentity() === args.participant.getUserIdentity()
					? null
					: prevDominantSpeaker;
			});
		};

		session.on("dominantSpeakerChanged", handleDominantSpeakerChanged);
		session.on("participantDisconnected", handleParticipantDisconnected);
		return (): void => {
			session.off("dominantSpeakerChanged", handleDominantSpeakerChanged);
			session.off("participantDisconnected", handleParticipantDisconnected);
		};
	}, [
		session,
		updateDominantSpeaker,
		onPendingUpdateExpire,
		dominantSpeaker,
		dominantSpeakerChangeDelay,
		inCallParticipantIds,
	]);

	return dominantSpeaker || undefined;
}
