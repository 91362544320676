/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file success icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Success
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The Success component
 * @param props The props ;)
 */
const Success: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160" {...props}>
			{title && <title>{title}</title>}
			<g transform="translate(80,0)">
				<path d="m34.1 38.5l20 20-69.9 70-40-40 20-20 20 20 49.9-50z" />
			</g>
		</svg>
	);
});

Success.displayName = "Success";

export default Success;
