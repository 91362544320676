/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file hook to get speaker device message
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareSetup.Hooks.UseSpeakerDeviceMessage
 */

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSpeakerDevices, useStrings } from "~/hooks";
import { useHardwareTestState, useLocalTrackState } from "~/state";
import { DeviceStatus, Timeout } from "~/types";
import { IS_SET_SINK_ID_SUPPORTED } from "~/utils/browser";
import { secondsToMs } from "~/utils/dateTime";
import { VideoSessionContext } from "~/web-core/components";
import { isPermissionsError } from "~/web-core/interfaces";

enum TokenNames {
	rowLabel = "Speaker",
	noSpeakerDetected = "NoSpeakerDetected",
	issueResolution = "IssueResolution",
	issueResolutionMobile = "IssueResolution_Mobile",
}

/**
 * Hook used to get an speaker device message.
 *
 * Returns: the message as a string, and a callback function which should be triggered when the audio is tested
 */
export function useSpeakerDeviceMessage(): { message: string; onSpeakerTest: () => void } {
	const status = useHardwareTestState((selectors) => selectors.getSpeakerStatus(), []);
	const speakerCount = useSpeakerDevices().length;

	const [showStatusMsg, setShowStatusMsg] = useState(false);
	const msgTimer = useRef<Timeout | null>(null);

	const strings = useStrings("SpeakerSetup", Object.values(TokenNames));

	const { localDeviceStream } = useContext(VideoSessionContext);
	const permissionsError = isPermissionsError(
		localDeviceStream?.getDeviceInitializationError()?.error.vendorErrorCode,
	);

	const hasAutoSelectedDevices = useLocalTrackState((selectors) => selectors.getHasAutoSelected(), []);

	const showAudioTestMsg = useCallback(() => {
		if (msgTimer.current) {
			clearTimeout(msgTimer.current);
			msgTimer.current = null;
		}

		setShowStatusMsg(true);
		msgTimer.current = setTimeout(() => setShowStatusMsg(false), secondsToMs(20));
	}, []);

	useEffect(() => {
		return () => {
			if (msgTimer.current) {
				clearTimeout(msgTimer.current);
				msgTimer.current = null;
			}
		};
	}, []);

	const statusMsg = useMemo(() => {
		if (status === DeviceStatus.warning && IS_SET_SINK_ID_SUPPORTED && !speakerCount) {
			// If we are missing a device but have a device permissions issue delay to they all appear at the same time.
			if (permissionsError && !hasAutoSelectedDevices) {
				return "";
			}
			return strings[TokenNames.noSpeakerDetected];
		}
		if (showStatusMsg) {
			return IS_SET_SINK_ID_SUPPORTED
				? strings[TokenNames.issueResolution]
				: strings[TokenNames.issueResolutionMobile];
		}
		return "";
	}, [status, speakerCount, showStatusMsg, permissionsError, hasAutoSelectedDevices, strings]);

	return { message: statusMsg, onSpeakerTest: showAudioTestMsg };
}
