/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Handle showing a popup on timeout for feedback surveys
 * @author Max Harkins
 * @module Epic.VideoApp.Components.FeedbackSurvey.Hooks.UseFeedbackTimeoutWarning
 */

import { useDispatch } from "@epic/react-redux-booster/dist/use-dispatch";
import { useEffect, useRef, useState } from "react";
import { useStrings } from "~/hooks";
import { alertActions, combinedActions } from "~/state";
import { feedbackSurveyActions, useFeedbackSurveyState } from "~/state/feedbackSurvey";
import { AlertType, FeedbackSurveyPopupTimeMs, IGenericCountdownAlert } from "~/types";

enum TokenNames {
	timeoutWarningMessage = "TimeoutWarningMessage",
}

/**
 * Handle feedback survey timeout warning
 */
export function useFeedbackTimeoutWarning(onTimeout?: () => void): void {
	const hasShown = useFeedbackSurveyState((selectors) => selectors.getHasShownTimeoutWarning(), []);
	const endSurveyTimestamp = useFeedbackSurveyState(
		(selectors) => selectors.getSurveyTimeoutTimestamp(),
		[],
	);
	const dispatch = useDispatch();
	const [endFeedbackTimeout, setEndFeedbackTimeout] = useState<number>();
	const [warningTimeout, setWarningTimeout] = useState<number>();
	const strings = useStrings("useFeedbackTimeoutWarning", [TokenNames.timeoutWarningMessage]);

	const onTimeoutRef = useRef(onTimeout);
	const dispatchRef = useRef(dispatch);
	const stringsRef = useRef(strings);
	const hasShownRef = useRef(hasShown);
	const endFeedbackTimeoutRef = useRef(endFeedbackTimeout);
	const warningTimeoutRef = useRef(warningTimeout);
	const hasCreatedTimersRef = useRef(false);
	useEffect(() => {
		onTimeoutRef.current = onTimeout;
		dispatchRef.current = dispatch;
		stringsRef.current = strings;
		hasShownRef.current = hasShown;
		endFeedbackTimeoutRef.current = endFeedbackTimeout;
		warningTimeoutRef.current = warningTimeout;
	}, [onTimeout, dispatch, strings, hasShown, endFeedbackTimeout, warningTimeout]);

	// Clean up timer before leaving the component
	useEffect(() => {
		return () => {
			if (endFeedbackTimeout) {
				clearTimeout(endFeedbackTimeout);
			}
		};
	}, [endFeedbackTimeout]);

	useEffect(() => {
		const now = Date.now();
		if (endSurveyTimestamp && now < endSurveyTimestamp && !hasCreatedTimersRef.current) {
			hasCreatedTimersRef.current = true;
			// Set up warning pop-up timeout if we haven't shown the popup yet
			if (!hasShownRef.current) {
				const warning = window.setTimeout(() => {
					// Build the warning pop-up, and use a separate timeout to handle cleanup after the warning ends.
					const WarningAlert: IGenericCountdownAlert = {
						message: stringsRef.current[TokenNames.timeoutWarningMessage],
						countdown: FeedbackSurveyPopupTimeMs,
						type: AlertType.genericCountdown,
					};
					dispatchRef.current(feedbackSurveyActions.setHasShownTimeoutWarning(true));
					dispatchRef.current(alertActions.postGenericCountdownAlert(WarningAlert));
				}, endSurveyTimestamp - now - FeedbackSurveyPopupTimeMs);
				setWarningTimeout(warning);
			}

			// End survey timeout
			const end = window.setTimeout(() => {
				if (onTimeoutRef.current) {
					onTimeoutRef.current();
				}
				dispatchRef.current(combinedActions.clearSurveyState(false));
				dispatchRef.current(alertActions.clearAlert()); // Clear the warning alert if present
			}, endSurveyTimestamp - now);
			setEndFeedbackTimeout(end);
		}

		return () => {
			if (endFeedbackTimeoutRef.current) {
				clearTimeout(endFeedbackTimeoutRef.current);
			}
			if (warningTimeoutRef.current) {
				clearTimeout(warningTimeoutRef.current);
			}
		};
	}, [endSurveyTimestamp]);
}
