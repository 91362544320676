/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file button to toggle the button tray
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.ToggleButtonTrayButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import BaseButton from "~/components/Utilities/BaseButton";
import { useStrings } from "~/hooks";
import Chevron from "~/icons/chevron";
import { uiActions, useMessageState, useUIState, useUserState } from "~/state";
import { resolveClassName } from "~/utils/className";
import styles from "../ControlsHeader.module.scss";

/**
 * The ToggleButtonTrayButton component
 */
const ToggleButtonTrayButton: FC = () => {
	const collapsed = useUIState((selectors) => selectors.getButtonTrayCollapsed(), []);
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const sidebarOpen = useUIState((selectors) => selectors.getSidebarStatus(), []) === "open";
	const hasNotification = useMessageState((selectors) => selectors.getNumUnreadMessages() > 0, []);
	const dispatch = useDispatch();

	const strings = useStrings("ToggleButtonTrayButton", ["AriaLabel"]);

	const onClick = useCallback(() => {
		dispatch(uiActions.toggleButtonTrayCollapsed());
	}, [dispatch]);

	const className = resolveClassName(styles, {
		toggleChevron: true,
		collapsed,
		notification: collapsed && hasNotification && !inWaitingRoom && !sidebarOpen,
	});

	return (
		<BaseButton
			rawClass={className}
			onClick={onClick}
			aria-label={strings["AriaLabel"]}
			aria-pressed={collapsed}
			keyboardShortcut={["alt", "t"]}
			icon={Chevron}
		/>
	);
};

ToggleButtonTrayButton.displayName = "ToggleButtonTrayButton";

export default ToggleButtonTrayButton;
