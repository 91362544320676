/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Network test websocket connectivity
 * @author Noah Allen
 * @module Epic.VideoApp.Features.TestNetwork.FormattedWebsocketConnectivity
 */

import { DailyWebsocketConnectivityTestResults } from "@daily-co/daily-js";
import React from "react";

interface IProps {
	data: DailyWebsocketConnectivityTestResults | null;
}

const FormattedWebsocketConnectivity: React.FC<IProps> = ({ data }) => {
	return (
		<div style={{ textAlign: "left" }}>
			<div>
				<strong>Aborted Regions: </strong> {JSON.stringify(data?.abortedRegions)}
			</div>
			<div>
				<strong>Failed Regions: </strong> {JSON.stringify(data?.failedRegions)}
			</div>
			<div>
				<strong>Passed Regions: </strong> {JSON.stringify(data?.passedRegions)}
			</div>
			<div>
				<strong>Result: </strong> {data?.result}
			</div>
		</div>
	);
};

export default FormattedWebsocketConnectivity;
