/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Preview Pane for Image Captures
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.ImageCapture.ImagePreviewPane
 */

import React, { FC, useRef, useState } from "react";
import { useStrings } from "~/hooks";
import { ITelemedicineDocType } from "~/types";
import { isMobile } from "~/utils/os";
import FocusTrap from "../Alerts/FocusTrap";
import DescriptionInput from "./Controls/DescriptionInput";
import DiscardButton from "./Controls/DiscardButton";
import DocumentTypeSelector from "./Controls/DocumentTypeSelector";
import ExitButton from "./Controls/ExitButton";
import RetakeButton from "./Controls/RetakeButton";
import SaveButton from "./Controls/SaveButton";
import ImageCapturePreview from "./ImageCapturePreview";
import styles from "./ImagePreviewPane.module.scss";

interface IImagePaneProps {
	image: string;
}

export interface IValidatedDescription {
	text: string;
	isValid?: boolean;
}

enum TokenNames {
	title = "Title",
}

export enum ImagePreviewPaneTestIds {
	self = "ImagePreviewPane",
}

/**
 * The ImagePreviewPane component
 */
const ImagePreviewPane: FC<IImagePaneProps> = (props: IImagePaneProps) => {
	const { image } = props;
	const mobile = isMobile();
	const strings = useStrings("ImagePreviewPane", Object.values(TokenNames));

	const [validatedDescription, setValidatedDescription] = useState<IValidatedDescription>({
		text: "",
		isValid: true,
	});

	const [selectedDocument, setDocType] = useState<ITelemedicineDocType | null>(null);

	const exitRef = useRef<HTMLButtonElement>(null);
	const saveRef = useRef<HTMLButtonElement>(null);
	const imageWrapperRef = useRef<HTMLDivElement>(null);

	if (!image) {
		return null;
	}

	return (
		<FocusTrap firstElement={exitRef} lastElement={saveRef} defaultElement={imageWrapperRef}>
			<div className={styles["imageCaptureContainer"]} data-testid={ImagePreviewPaneTestIds.self}>
				<div className={styles["imageCapturePane"]}>
					<span className={styles["header"]}>{strings[TokenNames.title]}</span>
					<ExitButton exitRef={exitRef} />
					<div className={styles["imageSection"]}>
						<ImageCapturePreview image={image} imageWrapperRef={imageWrapperRef} />
						<div className={styles["retakeDiscardGroup"]}>
							{mobile && <RetakeButton />}
							<DiscardButton />
						</div>
					</div>

					<div>
						<div className={styles["inputGroup"]}>
							<DocumentTypeSelector setDocType={setDocType} />
							<DescriptionInput setValidatedDescription={setValidatedDescription} />
						</div>
						<div className={styles["saveContainer"]}>
							<SaveButton
								image={image}
								validatedDescription={validatedDescription}
								docType={selectedDocument}
								saveRef={saveRef}
							/>
						</div>
					</div>
				</div>
			</div>
		</FocusTrap>
	);
};

ImagePreviewPane.displayName = "ImagePreviewPane";

export default ImagePreviewPane;
