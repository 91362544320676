/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file withSharedStates function
 * @author Roland Scott
 * @module react-redux-booster\src\with-shared-states
 */

import { ISharedState, ReducerWithMetadata, SelectorWithMetadata } from "./internal/types";
import { Reducer, Selector } from "./types";

/**
 * Shape of the value returned by `withSharedState`
 */
export interface ISharedStateBuilder<TSharedStates extends any[]> {
	/**
	 * Builds a reducer that will use multiple pieces of shared state.
	 * @reducer The reducer function implementation
	 */
	buildReducer<TReducer extends Reducer<TSharedStates, any>>(reducer: TReducer): TReducer;

	/**
	 * Builds a selector that will use multiple pieces of shared state.
	 * @param selector The selector function implementation
	 */
	buildSelector<TSelector extends Selector<TSharedStates, any, any>>(selector: TSelector): TSelector;
}

/**
 * Creates an object that can be used to build reducers and selectors that use multiple pieces of shared state.
 * @param sharedStates The pieces of shared state that will be used by the reducers and/or selectors.
 * @returns An object that can be used to build reducers and selectors that use multiple pieces of shared state.
 */
export function withSharedStates<TSharedStates extends any[]>(
	...sharedStates: TSharedStates
): ISharedStateBuilder<TSharedStates> {
	return {
		buildReducer: (reducer: any) => {
			(reducer as ReducerWithMetadata).requiredSliceIds = sharedStates.map(
				(arg: ISharedState) => arg.id,
			);
			return reducer;
		},
		buildSelector: (selector: any) => {
			(selector as SelectorWithMetadata).requiredSliceIds = sharedStates.map(
				(arg: ISharedState) => arg.id,
			);
			return selector;
		},
	};
}
