/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file A menu page in the same style as the more options menu, that can be rendered and controlled separately.
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Menu.GenericMenuPage
 */

import React, { FC } from "react";
import ClickOutsideSection from "~/components/Utilities/ClickOutsideSection";
import { resolveClassName } from "~/utils/className";
import Collapse from "../../Utilities/Collapse";
import { useMaxMenuPageHeight } from "../hooks/useMaxMenuPageHeight";
import styles from "./GenericMenuPage.module.scss";

/**
 * Props for GenericMenuPage component
 */
interface IProps {
	closeCallback: () => void;
	enabled: boolean;
	wrapperClassName?: string;
}

/**
 * The GenericMenuPage component
 * @param props The props ;)
 */
const GenericMenuPage: FC<IProps> = (props) => {
	const { closeCallback, enabled, wrapperClassName, children } = props;

	const maxPageHeight = useMaxMenuPageHeight();
	const outerClassName = resolveClassName(styles, { wrapper: true }, wrapperClassName);

	return (
		<ClickOutsideSection onClickOutside={closeCallback}>
			<div className={outerClassName}>
				<Collapse collapsed={!enabled}>
					<div className={styles["menuPage"]} style={{ maxHeight: maxPageHeight }}>
						{children}
					</div>
				</Collapse>
			</div>
		</ClickOutsideSection>
	);
};

GenericMenuPage.displayName = "GenericMenuPage";

export default GenericMenuPage;
