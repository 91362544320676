/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file chat state
 * @author Noah Allen
 * @module Epic.VideoApp.State.Chat
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { IChatConfig } from "~/types/chat";

/// TYPES ///

export type IChatState = IChatConfig;

/// INIT ///

export function getInitialState(): IChatState {
	return { chatEnabled: false };
}

/// REDUCERS ///

export function setChatConfig(state: IChatState, config: IChatConfig): IChatState {
	return { ...state, ...config };
}

/// SELECTORS ///

function getChatEnabled(state: IChatState): boolean {
	return state.chatEnabled;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: { setChatConfig },
	selectors: { getChatEnabled },
});
store.addSharedState(builtState.sharedState, "chat");

export const { actionCreators: chatActions, useSharedState: useChatState, sharedState: state } = builtState;
