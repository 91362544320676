/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Locked Camera Icon
 * @author Will Cooper
 * @module Epic.VideoApp.Icons.LockedCamera
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum LockedCameraIconTestIds {
	self = "LockedCamera",
}

/**
 * The LockedCamera component
 * @param props The props ;)
 */
const LockedCamera: FC<IconProps> = memo((props) => {
	return (
		<svg
			data-testid={LockedCameraIconTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 39.06 38"
			{...props}
		>
			<g>
				<path
					fill="#848484"
					d="M22.5,22.5a1.62,1.62,0,0,1-1.62,1.62H6.25A1.61,1.61,0,0,1,4.63,22.5V9.5A1.61,1.61,0,0,1,6.25,7.88H20.88A1.62,1.62,0,0,1,22.5,9.5Z"
				/>
				<path fill="#848484" d="M24.13,12.75,27.38,9.5H29v13H27.38l-3.25-3.25H20.88v-6.5Z" />
			</g>
			<g>
				<path
					fill="#fff"
					d="M35.54,25.43H35A1.47,1.47,0,0,1,33.56,24c0-.61,0-1.38,0-1.94,0-.31,0-.53,0-.64a2.89,2.89,0,1,0-5.78,0c0,.11,0,.33,0,.64,0,.56,0,1.33,0,1.94a1.47,1.47,0,0,1-1.47,1.46h-.5A1.47,1.47,0,0,1,24.23,24c0-.63,0-1.44,0-2,0-.28,0-.51,0-.6a6.36,6.36,0,0,1,12.71,0c0,.09,0,.32,0,.6,0,.54,0,1.35,0,2A1.47,1.47,0,0,1,35.54,25.43Z"
				/>
				<path
					fill="#171718"
					d="M35.54,24.85a.88.88,0,0,0,.89-.88c0-.95,0-2.31,0-2.58a5.78,5.78,0,0,0-11.55,0c0,.27,0,1.63,0,2.58a.88.88,0,0,0,.89.88h.5a.89.89,0,0,0,.9-.88c0-.95,0-2.3,0-2.58a3.47,3.47,0,0,1,6.94,0c0,.28,0,1.63,0,2.58a.88.88,0,0,0,.89.88h.51m0,1.16H35a2,2,0,0,1-2-2c0-.6,0-1.36,0-1.92,0-.36,0-.55,0-.66a2.32,2.32,0,0,0-4.63,0c0,.11,0,.3,0,.66,0,.56,0,1.32,0,1.92a2.05,2.05,0,0,1-2.05,2h-.5a2.05,2.05,0,0,1-2.05-2c0-.63,0-1.45,0-2,0-.27,0-.51,0-.59a6.93,6.93,0,1,1,13.86,0c0,.08,0,.32,0,.59,0,.54,0,1.36,0,2A2,2,0,0,1,35.54,26Z"
				/>
				<rect fill="#fff" x="23.69" y="26.01" width="13.86" height="8.09" rx="1.16" />
				<rect
					fill="none"
					stroke="#171718"
					x="23.11"
					y="25.43"
					width="15.01"
					height="9.25"
					rx="1.73"
				/>
			</g>
		</svg>
	);
});

LockedCamera.displayName = "LockedCamera";

export default LockedCamera;
