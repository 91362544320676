/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Button for moderators to disable and lock remote audio
 * @author Will Cooper & Trevor Roussel
 * @module Epic.VideoApp.Components.Participants.Moderation.ModerationButtons.LockParticipantMicrophoneButton
 */
import React, { FC, useCallback } from "react";
import BaseButton from "~/components/Utilities/BaseButton";
import ToggleSwitchButton from "~/components/Utilities/ToggleSwitchButton";
import { useStrings } from "~/hooks";
import { useSendModeratorAction } from "~/hooks/messaging/index";
import { useModerationState, useRoomState } from "~/state";
import { EpicUserType } from "~/types";
import { getMicIcon } from "~/utils/device";
import UnlockDeviceRequestIndicator from "./UnlockDeviceRequestIndicator";

interface IMuteProps {
	identity: string;
	className: string;
	enabled: boolean;
	inNameBar?: boolean;
	labelClassName?: string;
	userType?: EpicUserType;
	iconClassName?: string;
	toggleSwitchClassName?: string;
}

/** String tokens used by the LockParticipantMicrophoneButton component */
enum TokenNames {
	allowMic = "AllowMicrophone",
	turnOffMicrophone = "TurnOffMicrophone",
	audioAriaLabel = "AudioAriaLabel",
}

export enum LockParticipantMicrophoneButtonTestIds {
	toggleButton = "LockParticipantMicrophoneButton-ToggleButton",
	baseButton = "LockParticipantMicrophoneButton-BaseButton",
}

const LockParticipantMicrophoneButton: FC<IMuteProps> = (props: IMuteProps) => {
	const {
		identity,
		className,
		inNameBar,
		enabled,
		userType,
		iconClassName,
		labelClassName,
		toggleSwitchClassName,
	} = props;
	const strings = useStrings("LockParticipantMicrophoneButton", Object.values(TokenNames));

	const sendModeratorMessage = useSendModeratorAction();
	const requestInfo = useModerationState(
		(selectors) => selectors.getParticipantRequest(identity),
		[identity],
	);

	const participantModeration = useRoomState(
		(selectors) => selectors.getParticipantModerationLocks(identity),
		[identity],
	);

	const { audio: micLocked } = participantModeration;
	const { audio: micRequested } = requestInfo;

	const onMuteClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			sendModeratorMessage({ audioLock: !micLocked }, identity, true);

			// stop propagation to avoid also triggering the onClick
			event?.stopPropagation();
		},
		[micLocked, sendModeratorMessage, identity],
	);

	const useToggleSwitch = userType !== EpicUserType.emp;

	const buttonDisabled = userType === EpicUserType.emp && !enabled;
	const micIcon = getMicIcon(enabled);

	const ariaLabel = micRequested ? strings[TokenNames.audioAriaLabel] : undefined;

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{useToggleSwitch ? (
				<ToggleSwitchButton
					text={strings[TokenNames.allowMic]}
					className={className}
					onClick={onMuteClick}
					on={!micLocked}
					aria-label={ariaLabel}
					labelClassName={labelClassName}
					toggleSwitchClassName={toggleSwitchClassName}
					data-testid={LockParticipantMicrophoneButtonTestIds.toggleButton}
					id="lockMicToggle"
				>
					{micRequested && !inNameBar && <UnlockDeviceRequestIndicator />}
				</ToggleSwitchButton>
			) : (
				<BaseButton
					onClick={onMuteClick}
					className={className}
					icon={micIcon}
					text={strings[TokenNames.turnOffMicrophone]}
					disabledTabbable={buttonDisabled}
					iconClassName={iconClassName}
					labelClassName={labelClassName}
					data-testid={LockParticipantMicrophoneButtonTestIds.baseButton}
				>
					{micRequested && !inNameBar && <UnlockDeviceRequestIndicator />}
				</BaseButton>
			)}
		</>
	);
};

LockParticipantMicrophoneButton.displayName = "LockParticipantMicrophoneButton";

export default LockParticipantMicrophoneButton;
