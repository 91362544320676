/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Component used to select a camera
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.CameraSelector
 */

import React, { FC, useCallback } from "react";
import { useCameraDevices, useSelectedCameraId, useStrings } from "~/hooks";
import { useVideoTrackActions } from "~/hooks/localTracks";
import DeviceSelector from "./DeviceSelector";

/**
 * The CameraSelector component
 */
const CameraSelector: FC = () => {
	const { switchVideoDevice } = useVideoTrackActions();
	const cameras = useCameraDevices();
	const selectedCameraId = useSelectedCameraId();

	const onCameraChange = useCallback(
		(camera: MediaDeviceInfo) => {
			// no need to wait for errors - if the switch fails, the local track will be reset.
			void switchVideoDevice(camera, { manual: true });
		},
		[switchVideoDevice],
	);

	const tokenNames = ["Camera"];
	const strings = useStrings("CameraSelector", tokenNames);

	return (
		<DeviceSelector
			selectedDeviceId={selectedCameraId || ""}
			devices={cameras}
			fallbackDeviceName={strings["Camera"]}
			onDeviceSelectionChange={onCameraChange}
		/>
	);
};

CameraSelector.displayName = "CameraSelector";

export default CameraSelector;
