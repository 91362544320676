/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Utilities for network quality icons
 * @author Will Cooper
 * @module Epic.VideoApp.Utils.NetworkQualityIcon
 */

/**
 * Returns the expected color based upon a given participant's network quality
 * @param level - The participant's network quality (0-5).
 * 	We display only four options. A level of 4 or 5 corresponds to four bars and a level of 0 or 1 corresponds to to one bar.
 * @returns - The color code for  the specific shade of red, yellow, or white that will be displayed
 */
export function getNetworkLevelColor(level: number): string {
	switch (level) {
		case 0:
		case 1:
			return "#ff0000";
		case 2:
			return "#daa520";
		case 3:
		case 4:
		case 5:
			return "#ffffff";
		default:
			return "#ffffff";
	}
}
