/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Display Mode Button
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Buttons.DisplayModeButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import Collaborative from "~/icons/collaborative";
import Grid from "~/icons/grid";
import { combinedActions, uiActions, useUIState } from "~/state";
import ControlButton from "./ControlButton";

enum TokenNames {
	switchToActiveSpeakerText = "ToggleBase",
	switchToGridText = "ToggleGrid",
}
/**
 * DisplayMode toggle button
 */
const DisplayModeButton: FC = () => {
	const currentDisplayType = useUIState((selectors) => selectors.getVideoLayout(), []);
	const dispatch = useDispatch();

	const strings = useStrings("DisplayModeButton", Object.values(TokenNames));

	const toggleViewMode = useCallback(() => {
		dispatch(uiActions.toggleVideoLayout());
		dispatch(uiActions.toggleVisibleMenu({ menu: null }));
		// Prevents image capture ready toast from sticking around
		dispatch(combinedActions.clearImageCaptureActive());
	}, [dispatch]);

	const icon = currentDisplayType === "Active Speaker" ? Grid : Collaborative;
	const toggleText =
		currentDisplayType !== "Active Speaker"
			? strings[TokenNames.switchToActiveSpeakerText]
			: strings[TokenNames.switchToGridText];

	return (
		<ControlButton
			icon={icon}
			onClick={toggleViewMode}
			ariaLabel={toggleText}
			keyboardShortcut={["alt", "w"]}
			buttonText={toggleText}
			pressed={false}
		/>
	);
};

DisplayModeButton.displayName = "DisplayModeButton";

export default DisplayModeButton;
