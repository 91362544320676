/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file PageArrowButton
 * @author Liam Liden
 * @module Epic.VideoApp.Components.Utilities.Carousel.PageArrowButton
 */

import React, { FC } from "react";
import Chevron from "~/icons/chevron";
import { resolveClassName } from "~/utils/className";
import styles from "./PageArrowButton.module.scss";

type PageDirection = "previous" | "next";

interface IProps {
	/** Function to run on button click*/
	onClick: () => void;

	/** Sets direction of the chevron inside the button */
	pageDirection: PageDirection;

	/** Aria-label to use for the underlying button */
	ariaLabel: string;

	/** Flag for whether the button will function or not */
	disabled?: boolean;

	/** Bool for if the button should lighten rather than darken on hover */
	lighten?: boolean;
}

const PageArrowButton: FC<IProps> = (props) => {
	const { onClick, pageDirection, ariaLabel, disabled, lighten } = props;

	const buttonClass = resolveClassName(styles, { changePageButton: true, lighten: lighten });

	return (
		<button
			onClick={onClick}
			className={buttonClass}
			aria-label={ariaLabel}
			aria-disabled={disabled}
			disabled={disabled}
			title={ariaLabel}
		>
			<Chevron
				chevronDirection={pageDirection === "previous" ? "left" : "right"}
				aria-hidden
				aria-disabled
			/>
		</button>
	);
};

PageArrowButton.displayName = "PageArrowButton";

export default PageArrowButton;
