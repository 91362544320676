/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Reactive interval where state stays up to date
 * @author Spencer Eanes
 * @module Epic.VideoApp.Hooks.UseInterval
 */
import { useEffect, useRef, useState } from "react";

type IntervalHandle = ReturnType<typeof setInterval> | undefined;

/**
 * A hook that will keep track of an up to date callback for better managing React state
 * within the Javascript Interval function.
 *
 * @param callback The function to run at a fixed interval.
 * @param delay The number of milliseconds between executions of the callback.
 * @param tearDownCallback Optional callback invoked when the effect for the interval is cleaned up.
 * @param doNotRunUnderCondition Optional boolean to determine if the interval should be created.
 * @returns The handle used to cancel the interval
 */
export function useInterval(
	callback: () => void,
	delay: number,
	teardownCallback?: () => void,
	doNotRunUnderCondition?: boolean,
): IntervalHandle {
	const [handle, setHandle] = useState<ReturnType<typeof setInterval>>();
	const savedCallback = useRef(callback);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		if (doNotRunUnderCondition) {
			// since hooks can't be called conditionally, allow consumers to provide a condition to not setup the interval.
			return;
		}

		const doInterval = (): void => {
			savedCallback.current();
		};
		const interval = setInterval(doInterval, delay);
		//Save off the handle to allow consuming components to end the interval without being un-rendered
		setHandle(interval);
		return () => {
			clearInterval(interval);
			if (teardownCallback) {
				teardownCallback();
			}
		};
	}, [delay, doNotRunUnderCondition, teardownCallback]);

	return handle;
}
