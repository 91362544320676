/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file informational banner to be displayed under the call header
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Banner.Banner
 */

import React, { FC } from "react";
import { useAlertState } from "~/state";
import ScreenShareBanner from "./ScreenShareBanner";

/**
 * The Banner component
 */
const Banner: FC = () => {
	const banner = useAlertState((selectors) => selectors.getBanner(), []);

	if (!banner) {
		return null;
	}

	switch (banner) {
		case "local-screen-share":
			return <ScreenShareBanner />;
	}
};

Banner.displayName = "Banner";

export default Banner;
