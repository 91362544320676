/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Feedback survey prompting user to report technical issues
 * @author Max Harkins
 * @module Epic.VideoApp.Components.FeedbackSurvey.SurveyCard
 */

import { useDispatch } from "@epic/react-redux-booster";
import { RouteComponentProps } from "@reach/router";
import React, { FC, useCallback, useEffect, useRef } from "react";
import Card from "~/components/Cards/Card";
import SurveyWorkflow from "~/components/FeedbackSurvey/SurveyWorkflow";
import { useSaveFeedbackSurvey } from "~/components/FeedbackSurvey/hooks/useSaveFeedbackSurvey";
import { combinedActions } from "~/state";
import { useFeedbackSurveyState } from "~/state/feedbackSurvey";
import { FeedbackSurveyLoadingStatus, ISurvey } from "~/types/survey";
import { useStrings } from "../../hooks";
import Spinner from "../Loading/Spinner";
import styles from "./SurveyCard.module.scss";

enum TokenNames {
	title = "Title",
	heading = "Heading",
}

export enum SurveyCardTestIds {
	self = "SurveyCard",
	heading = "SurveyCardHeading",
	surveyContainer = "SurveyCardContainer",
}

/**
 * A simple screen after the user disconnects
 */
const SurveyCard: FC<RouteComponentProps> = () => {
	const strings = useStrings("SurveyPage", Object.values(TokenNames));
	const headingRef = useRef<HTMLHeadingElement>(null);

	// Set up a callback to allow setting focus
	const focusHeader = useCallback(() => {
		headingRef?.current?.focus();
	}, []);

	// Focus the heading after the first render
	useEffect(() => {
		headingRef.current?.focus();
	}, []);

	const saveSurvey = useSaveFeedbackSurvey();
	const dispatch = useDispatch();

	const survey: ISurvey | null = useFeedbackSurveyState(
		(selectors) => selectors.getFeedbackSurveyQuestionnaire(),
		[],
	);

	const surveyLoadingStatus: FeedbackSurveyLoadingStatus = useFeedbackSurveyState(
		(selectors) => selectors.getFeedbackSurveyLoadingStatus(),
		[],
	);

	const onEnd = useCallback(() => {
		dispatch(combinedActions.clearSurveyState(true));
	}, [dispatch]);

	const onExit = useCallback(() => {
		dispatch(combinedActions.clearSurveyState(false));
	}, [dispatch]);

	if (surveyLoadingStatus === FeedbackSurveyLoadingStatus.failedToLoad) {
		dispatch(combinedActions.clearSurveyState(false)); // Clear state if we failed to load the survey and the step was not reset
		return null;
	}

	return (
		<div className={styles["surveyContainer"]} data-testid={SurveyCardTestIds.self}>
			<Card centered rawClass={styles["survey-card"]}>
				<h1 tabIndex={-1} ref={headingRef} data-testid={SurveyCardTestIds.heading}>
					{strings[TokenNames.title]}
				</h1>
				<p>{strings[TokenNames.heading]}</p>
				{survey === null || surveyLoadingStatus === FeedbackSurveyLoadingStatus.notYetLoaded ? (
					<Spinner />
				) : (
					<div className={styles["surveyWorkflow"]} data-testid={SurveyCardTestIds.surveyContainer}>
						<SurveyWorkflow
							survey={survey}
							onExit={onExit}
							onEnd={onEnd}
							saveResponses={saveSurvey}
							setFocusOnPageChange={focusHeader}
						/>
					</div>
				)}
			</Card>
		</div>
	);
};

SurveyCard.displayName = "SurveyCard";

export default SurveyCard;
