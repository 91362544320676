/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Sidebar for the video call page
 * @author Noah Allen
 * @module Epic.VideoApp.Components.VideoCall.Sidebar
 */

import React, { FC } from "react";
import { useUIState } from "~/state";
import ChatView from "../Chat/ChatView";
import styles from "./Sidebar.module.scss";

export enum SidebarTestIds {
	self = "Sidebar",
}

/**
 * The component used to render the chat in the sidebar of the video call page
 */
const Sidebar: FC = () => {
	const sidebarOpen = useUIState((selectors) => selectors.getSidebarStatus(), []) === "open";
	const sidebarClass = sidebarOpen ? "sidebarOpen" : "sidebarClose";

	return (
		<div className={styles[sidebarClass]} data-testid={SidebarTestIds.self}>
			<ChatView isOpen={sidebarOpen} />
		</div>
	);
};

Sidebar.displayName = "Sidebar";

export default Sidebar;
