/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file Sets up a useEffect to watch for moderator state to change to post warning toasts to the user
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UseModeratorAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useEffect, useRef } from "react";
import { alertActions, useUserState, userActions } from "~/state";
import { Timeout } from "~/types";
import { secondsToMs } from "~/utils/dateTime";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";

enum TokenNames {
	audio = "AudioModerated",
	video = "VideoModerated",
	screenShare = "ScreenShareModerated",
	videoAndAudio = "VideoAndAudioModerated",
	multiple = "MultipleModerated",
}

interface IUseModeratorAlerts {
	postDeviceModerationAlerts: (
		micLock: boolean | undefined,
		cameraLock: boolean | undefined,
		screenShareLock: boolean | undefined,
	) => void;
}

/**
 * The ModeratorAlert component
 */
export function useModeratorAlert(): IUseModeratorAlerts {
	const [requestState, screenShareAllowed, oldMicLockState, oldCameraLockState, oldScreenShareLockState] =
		useUserState(
			(selectors) => [
				selectors.getDeviceRequestStatus(),
				selectors.getUserPermission("canShareScreen"),
				selectors.getMicLock(),
				selectors.getCameraLock(),
				selectors.getScreenShareLock(),
			],
			[],
		);
	const showScreenShareAlerts = useIsScreenShareSupported() && screenShareAllowed;
	const dispatch = useDispatch();
	const timeoutRef = useRef<Timeout>();

	const { audio: audioRequested, video: videoRequested, screenShare: screenShareRequested } = requestState;

	// Timer callback
	// If it runs state has not updated in the last X time
	// Use it to allow a new request
	const timerCallback = useCallback(() => {
		dispatch(userActions.setRequestTimeout({ audio: false, video: false, screenShare: false }));
	}, [dispatch]);

	useEffect(() => {
		// If this flag is set, kick-off timer
		// If the underlying state updates, clear the timer, we can request again with new state
		if (audioRequested || videoRequested || screenShareRequested) {
			timeoutRef.current = setTimeout(timerCallback, secondsToMs(30));
		} else if (timeoutRef.current) {
			window.clearTimeout(timeoutRef.current);
		}
	}, [audioRequested, timerCallback, videoRequested, screenShareRequested]);

	// Post guest/patient alerts for mic, camera or screen share moderator changes
	const postDeviceModerationAlerts = useCallback(
		(
			newMicLockState: boolean | undefined,
			newCameraLockState: boolean | undefined,
			newScreenShareLockState: boolean | undefined,
		) => {
			// If no updates to mic, camera or screen share state, return
			if (
				newMicLockState === undefined &&
				newCameraLockState === undefined &&
				(!showScreenShareAlerts || newScreenShareLockState === undefined)
			) {
				return;
			}

			// When posting the lock toast, check if the current moderator message is locking the device
			// or if it is already locked and not being changed with this message
			const isMicLocked = newMicLockState || (newMicLockState === undefined && oldMicLockState);
			const isCamLocked =
				newCameraLockState || (newCameraLockState === undefined && oldCameraLockState);
			const isScreenShareLocked =
				newScreenShareLockState || (newScreenShareLockState === undefined && oldScreenShareLockState);

			const messageToken = getModeratedMessageToken(
				isMicLocked,
				isCamLocked,
				showScreenShareAlerts && isScreenShareLocked,
			);
			if (messageToken) {
				// Your camera / microphone / screen share has been disabled
				dispatch(
					alertActions.postToastAlert({
						type: "user-moderated",
						messageToken: messageToken,
					}),
				);
			} else {
				// Clear the toast
				dispatch(alertActions.clearToasts("user-moderated"));
			}
			// Check to clear the timer if a request was made
			if (timeoutRef.current) {
				dispatch(userActions.setRequestTimeout({ audio: false, video: false, screenShare: false }));
				window.clearTimeout(timeoutRef.current);
			}
		},
		[showScreenShareAlerts, oldMicLockState, oldCameraLockState, oldScreenShareLockState, dispatch],
	);

	return { postDeviceModerationAlerts };
}

/**
 * Helper function to pick which Toast string to display with a moderated alert
 * @param audio - Whether the user's audio is disabled
 * @param video - Whether the user's video is disabled
 * @param share - Whether the user's screen share is disabled
 * @returns - The matching string token, or undefined if the user is not moderated
 */
export function getModeratedMessageToken(audio: boolean, video: boolean, share: boolean): string | undefined {
	if (share && (audio || video)) {
		return TokenNames.multiple;
	} else if (audio && video) {
		return TokenNames.videoAndAudio;
	} else if (audio) {
		return TokenNames.audio;
	} else if (video) {
		return TokenNames.video;
	} else if (share) {
		return TokenNames.screenShare;
	}
	return undefined;
}
