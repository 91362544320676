/**
 * @copyright Copyright 2023 - 2024 Epic Systems Corporation
 * @file the organization's branded background
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Branding.BrandedBackground
 */

import React, { FC, useCallback, useState } from "react";
import styles from "./BrandedBackground.module.scss";

/**
 * Props for BrandedBackground Component
 */
interface IProps {
	/** The background image source, retrieved from branding state */
	src: string | null;
}

export enum BrandedBackgroundTestIds {
	self = "BrandedBackground",
}

/**
 * The BrandedBackground component
 * @param props The props ;)
 */
const BrandedBackground: FC<IProps> = (props) => {
	const { src } = props;
	const [hideBrandedBackground, setHideBrandedBackground] = useState(false);

	const loadFailed = useCallback(() => setHideBrandedBackground(true), []);

	if (hideBrandedBackground || !src) {
		return null;
	}

	return (
		<img
			src={src}
			className={styles["brandedBackground"]}
			onError={loadFailed}
			alt=""
			data-testid={BrandedBackgroundTestIds.self}
		/>
	);
};

BrandedBackground.displayName = "BrandedBackground";

export default BrandedBackground;
