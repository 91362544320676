/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Implementation for typed event emitter class
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Events.Implementations.EvcEmitter
 */

import { EventEmitter } from "events";
import { IEVCEmitter } from "../interfaces/evcEmitter";

export class EVCEmitter<TEventMap> implements IEVCEmitter<TEventMap> {
	private eventEmitter: EventEmitter;

	constructor() {
		this.eventEmitter = new EventEmitter();
	}

	on<TEventType extends keyof TEventMap & string>(
		type: TEventType,
		handler: (args: TEventMap[TEventType]) => void,
	): void {
		this.eventEmitter.on(type, handler);
	}

	once<TEventType extends keyof TEventMap & string>(
		type: TEventType,
		handler: (args: TEventMap[TEventType]) => void,
	): void {
		this.eventEmitter.once(type, handler);
	}

	off<TEventType extends keyof TEventMap & string>(
		type: TEventType,
		handler: (args: TEventMap[TEventType]) => void,
	): void {
		this.eventEmitter.off(type, handler);
	}

	removeAllListeners(type?: keyof TEventMap & string): void {
		this.eventEmitter.removeAllListeners(type);
	}

	emit<TEventType extends keyof TEventMap & string>(type: TEventType, event: TEventMap[TEventType]): void {
		this.eventEmitter.emit(type, event);
	}

	listenerCount(event: keyof TEventMap & string): number {
		return this.eventEmitter.listenerCount(event);
	}
}
