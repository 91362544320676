/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file hook to get functions to manage screen sharing track
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.LocalTracks.UseScreenShareTrackActions
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { IScreenShareAuditEvent, useAuditFeatureUse, useStrings } from "~/hooks";
import { alertActions, pipActions, useUIState, useUserState } from "~/state";
import { EventFlag, EventType, IParticipantVideo } from "~/types";
import { isPictureInPictureSupported, isPiPWindowOpen } from "~/utils/pictureInPicture";
import { getDisplayMedia } from "~/utils/screenSharing";
import { VideoSessionContext } from "~/web-core/components";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import { useStream } from "~/web-core/hooks/useStream";
import { ILocalUser, IRemoteUser } from "~/web-core/interfaces";

export interface IScreenShareTrackActions {
	/** Present a dialog to the user to allow them to choose content to share with other participants and publish the selected track */
	requestScreenShareTrack: (replaceTrack?: boolean) => Promise<void>;

	/** Remove the user's screen sharing track */
	removeScreenShareTrack: () => Promise<void>;
}

/**
 * Get functions to manipulate screen sharing for the local participant
 * @returns an object containing a function to start and stop screen sharing
 */
export function useScreenShareTrackActions(): IScreenShareTrackActions {
	const screenShareLock = useUserState((selectors) => selectors.getScreenShareLock(), []);
	const { session } = useContext(VideoSessionContext);
	const pinnedVideo = useUIState((selectors) => selectors.getPinnedVideo(), []);
	const localUser = session?.localUser;
	const localShareStream = useStream<ILocalUser>(localUser, "screen");
	const auditFeatureUse = useAuditFeatureUse();

	const dispatch = useDispatch();
	const screenShareSupport = useIsScreenShareSupported();

	const tokenNames = ["ProblemSharingScreen"];
	const strings = useStrings("useScreenShareTrackActions", tokenNames);

	const screenSharingTrackConstraints = useMemo(() => {
		return { audio: true, video: { frameRate: 10 } };
	}, []);

	const removeScreenShareTrack = useCallback(
		async (retainPiP?: boolean) => {
			if (!localShareStream || !session) {
				return;
			}

			const hasAudio = localShareStream.hasAudio();
			try {
				await session.removeScreenShare(localShareStream, hasAudio);
			} catch (error) {
				console.warn("Failed to unpublish screen share track", error);
			} finally {
				session?.localUser.cleanupShareStream();
			}

			if (isPiPWindowOpen() && !retainPiP) {
				// If the user is in PiP mode and screen-sharing kicked off their PiP, leave PiP mode
				dispatch(pipActions.leaveAutoPiPMode());
			}

			// remove the track from state
			dispatch(alertActions.setBanner(null));

			// audit that the user stopped sharing
			// Include a screen share audio flag when also unpublishing an audio track
			const screenShareFlags: EventFlag[] = hasAudio ? [EventFlag.hasScreenShareAudio] : [];
			const screenShareEvent: IScreenShareAuditEvent = {
				feature: EventType.screenSharingStopped,
				eventFlags: screenShareFlags,
			};
			void auditFeatureUse([screenShareEvent]);
		},
		[session, localShareStream, dispatch, auditFeatureUse],
	);

	const lockRef = useRef(screenShareLock);
	useEffect(() => {
		lockRef.current = screenShareLock;
	}, [screenShareLock]);

	const removeTrackRef = useRef(removeScreenShareTrack);
	useEffect(() => {
		removeTrackRef.current = removeScreenShareTrack;
	}, [removeScreenShareTrack]);

	const requestScreenShareTrack = useCallback(
		async (replaceTrack: boolean = false) => {
			if (!session || (localShareStream && !replaceTrack) || !screenShareSupport) {
				return;
			}

			if (localShareStream && replaceTrack) {
				await removeScreenShareTrack(true);
			}

			let needsToCleanPiP = replaceTrack;

			try {
				// Take Notice: Since we are using the getDisplayMedia API, we will give up User Activation (https://developer.mozilla.org/en-US/docs/Web/Security/User_activation)
				// for the initial click that triggers screen sharing. This means that we can't programmatically start PiP unless the user's screen share actions happens in ~5 seconds.
				// For this reason, attempt to start PiP first before screen sharing begins. If the user doesn't share, or fails to, we can clean this up automatically.
				if (!isPiPWindowOpen() && isPictureInPictureSupported()) {
					const videoId = getVideoIdForUser(session.getRemoteParticipants(), pinnedVideo);
					// Quality of life improvement, if PiP is available when screen sharing begins, trigger it automatically.
					// If PiP is already active, don't make any changes.
					if (videoId) {
						dispatch(
							pipActions.togglePiPMode({
								activeMode: true,
								videoID: videoId,
								videoType: "camera",
								source: "Auto",
							}),
						);
						needsToCleanPiP = true;
					}
				}

				const mediaStream = await getDisplayMedia(screenSharingTrackConstraints);
				const videoStream = mediaStream.getVideoTracks()[0];
				const audioStream = mediaStream.getAudioTracks()[0] ?? undefined;

				// If access was revoked between calling requestScreenShareTrack and getDisplayMedia return,
				// stop screen share track and do not publish
				if (lockRef.current) {
					videoStream.stop();
					audioStream?.stop();
					return;
				}

				const shareStream = await session.localUser.createShareStream(
					videoStream,
					audioStream,
					mediaStream,
				);

				// Listen for user stopping screen sharing through the browser itself
				// Wrap in a ref to avoid stale track state
				videoStream.onended = () => {
					void removeTrackRef.current();
				};
				// This event is needed for an awkward edge case on Daily when an audio stream is not available
				shareStream.on("videoDisabled", () => {
					void removeTrackRef.current();
				});

				try {
					await session.addScreenShare(shareStream);
				} catch (error) {
					// Do not audit that we published a track when it failed
					session.localUser.cleanupShareStream();
					videoStream.stop();
					audioStream?.stop();
					if (needsToCleanPiP) {
						dispatch(pipActions.togglePiPMode({ activeMode: false }));
					}
					return;
				}
				// Once a user starts sharing their screen, dismiss the toast to let them know they have
				// screen share access if it still exists
				dispatch(alertActions.postScreenShareStartedAlerts());

				// If access was revoked while the track was being published to the room, the local track state will not
				// have been set yet so removeScreenShareTrack will return early and cause the moderation state to be
				// misaligned. This is less ideal than the earlier accessRef check because the user will still initially
				// publish to the room, but this will make sure the moderator action is respected if it called in the narrow
				// race condition window
				if (lockRef.current) {
					void removeTrackRef.current();
				}

				// audit that the user started sharing their screen
				// Include a screen share audio flag when an audio track is also being published
				const screenShareFlags: EventFlag[] = screenSharingTrackConstraints.audio
					? [EventFlag.hasScreenShareAudio]
					: [];
				const screenShareEvent: IScreenShareAuditEvent = {
					feature: EventType.screenSharingStarted,
					eventFlags: screenShareFlags,
				};

				await auditFeatureUse([screenShareEvent]);
				return;
			} catch (error) {
				const logError = error as Error;
				// ignore when the user manually cancels out of the dialog
				if (logError.name !== "AbortError" && logError.name !== "NotAllowedError") {
					dispatch(alertActions.postGenericAlert(strings["ProblemSharingScreen"]));
				}
				session.localUser.cleanupShareStream();
				if (needsToCleanPiP) {
					dispatch(pipActions.togglePiPMode({ activeMode: false }));
				}
			}
		},
		[
			session,
			localShareStream,
			screenShareSupport,
			removeScreenShareTrack,
			screenSharingTrackConstraints,
			dispatch,
			auditFeatureUse,
			pinnedVideo,
			strings,
		],
	);

	return {
		requestScreenShareTrack,
		removeScreenShareTrack,
	};
}

/**
 * Helper function to get the User Id of the participant to show in the PiP window
 * @param participants list of participants
 * @param pinnedVideo the pinned user
 * @returns the user id of the participant to show in the PiP window (or undefined if none are available)
 */
function getVideoIdForUser(
	participants: IRemoteUser[],
	pinnedVideo: IParticipantVideo | null,
): string | undefined {
	// We use the participant identity to determine which participant to show in the PiP window
	// Start with trying to capture the pinned participant's video
	const pinnedUser = participants.find((p) => p.getUserIdentity() === pinnedVideo?.identity);
	if (pinnedUser && pinnedUser.deviceStream.isEnabled("video")) {
		return pinnedUser.getUserIdentity();
	}

	// If the pinned participant is not enabled, we will default to the first participant with a video track enabled
	// We specifically check for device stream since a screen-share action on behalf of the local user will interrupt a remote user's screen share.
	return participants.find((participant) => participant.deviceStream.isEnabled("video"))?.getUserIdentity();
}
