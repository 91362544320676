/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to manage saving client logs at a set interval
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.Auditing.UseClientLogging
 */

import { useEffect } from "react";
import { useAuthState, useRoomState } from "~/state";
import { secondsToMs } from "~/utils/dateTime";
import { saveClientLogs, warn } from "~/utils/logging";

export function useClientLogging(): void {
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);
	const clientLoggingInterval = useRoomState((selectors) => selectors.getClientLoggingInterval(), []);

	useEffect(() => {
		if (!JWT || clientLoggingInterval < 0) {
			return;
		}

		let handle = 0;
		function storeClientLogs(): void {
			if (JWT) {
				saveClientLogs(JWT)
					.then((_result) => {
						if (clientLoggingInterval > 0) {
							handle = window.setTimeout(storeClientLogs, secondsToMs(clientLoggingInterval));
						}
					})
					.catch((error: Error) => {
						warn(error);
					});
			}
		}
		handle = window.setTimeout(storeClientLogs, secondsToMs(clientLoggingInterval));

		return (): void => {
			// when unmounting, try to log anything after the previous interval
			if (handle !== 0) {
				clearTimeout(handle);
			}
			void saveClientLogs(JWT);
		};
	}, [JWT, clientLoggingInterval]);
}
