/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file screen sharing utilities
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.ScreenSharing
 */

import { LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import { IRemoteUser } from "~/web-core/interfaces";

export const ScreenShareTrackName = "SHARED_SCREEN";
export const ScreenShareAudioTrackName = "SHARED_AUDIO";

/**
 * Wrapper function for navigator.mediaDevices.getDisplayMedia to handle when it's undefined
 * @param constraints constraints to be applied to the returned media stream
 * @returns the MediaStream containing a video track of the user's selected screen area
 */
export function getDisplayMedia(constraints?: MediaStreamConstraints): Promise<MediaStream> {
	if (!navigator.mediaDevices?.getDisplayMedia) {
		throw Error("getDisplayMedia is undefined, call useIsScreenShareSupported() first");
	}
	// eslint-disable-next-line compat/compat
	return navigator.mediaDevices.getDisplayMedia(constraints);
}

/**
 * Check if a participant is publishing a video track that is a shared screen
 * @param participant participant to check if any of their published video tracks are a shared screen
 * @returns true if the participant is sharing a screen, false otherwise
 */
export function isSharingScreen(participant: IRemoteUser): boolean {
	return participant.shareStream !== null;
}

/**
 * Wrap constructor call for unit tests and to simplify arguments
 * @param mediaTrack MediaStreamTrack pulled from getDisplayMedia
 * @returns Twilio LocalVideoTrack constructed from the MediaStreamTrack
 */
export function makeLocalVideoTrackFromStream(mediaTrack: MediaStreamTrack): LocalVideoTrack {
	return new LocalVideoTrack(mediaTrack, { name: ScreenShareTrackName });
}

/**
 * Wrap constructor call for unit tests and to simplify arguments
 * @param mediaTrack MediaStreamTrack pulled from getDisplayMedia
 * @returns Twilio LocalAudioTrack constructed from the MediaStreamTrack
 */
export function makeLocalAudioTrackFromStream(mediaTrack: MediaStreamTrack): LocalAudioTrack | undefined {
	if (!mediaTrack) {
		return undefined;
	}
	return new LocalAudioTrack(mediaTrack, { name: ScreenShareAudioTrackName });
}
