/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Fallback render components for when video is not available for a given user
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.UserVideo
 */

import React, { FC } from "react";
import { useIsStreamSwitchedOff } from "~/hooks/useIsStreamBandwidthAffected";
import { useLocalTrackState } from "~/state";
import { CameraTogglingState } from "~/state/localTracks";
import { IDimensions } from "~/types";
import VideoStream from "~/web-core/components/VideoStream";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { IUser } from "~/web-core/interfaces";
import { VideoType } from "~/web-core/types";
import TrackSwitchedOffOverlay from "./Video/TrackSwitchedOffOverlay";
import VideoPlaceholder from "./Video/VideoPlaceholder";

/**
 * Props for UserVideo Component
 */
interface IProps {
	participant: IUser;
	videoType: VideoType;
	videoSize?: IDimensions;
	isLocal?: boolean;
	mainParticipant?: boolean;
	displayName?: string;
}

/**
 * The UserVideo component
 * @param props The props ;)
 */
const UserVideo: FC<IProps> = (props) => {
	const { participant, mainParticipant, isLocal, videoType, videoSize, displayName } = props;
	const id = participant.getUserIdentity();

	// Ensure we have a valid stream
	const stream = useStream(participant, videoType);
	const isEnabled = useIsStreamEnabled("video", stream);
	const isVideoSwitchedOff = useIsStreamSwitchedOff(stream);
	const isTogglingOn =
		useLocalTrackState((selectors) => selectors.getTogglingCamera(), []) ===
		CameraTogglingState.togglingOn;

	// Return early if we can only show the disabled/loading camera icon
	if (!stream || !isEnabled) {
		return <VideoPlaceholder displayName={displayName} showSpinner={!!isLocal && isTogglingOn} />;
	}

	return (
		<>
			{isVideoSwitchedOff && <TrackSwitchedOffOverlay />}
			<VideoStream
				stream={stream}
				videoType={videoType}
				isLocal={isLocal}
				mainParticipant={mainParticipant}
				userId={id}
				videoSize={videoSize}
			/>
		</>
	);
};

UserVideo.displayName = "UserVideo";

export default UserVideo;
