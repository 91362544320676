/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Shared constants for Twilio's vendor implementation
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.TwilioValues
 */

// Common Twilio room error codes. See node_modules/twilio-video/lib/util/twilio-video-errors.js for codes
export enum RoomErrorCodes {
	participantDuplicateIdentityError = 53205,
	roomCompletedError = 53118,
	roomMaxParticipantsExceededError = 53105,
}
