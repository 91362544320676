/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Network test connection state
 * @author Noah Allen
 * @module Epic.VideoApp.Features.TestNetwork.FormattedConnectionState
 */

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React from "react";
import { CONNECTION_MODES } from "./TestPage";

interface IceCandidate {
	candidate: string;
	sdpMid: string;
	sdpMLineIndex: number;
	usernameFragment: string;
}

interface ConnectionType {
	result: string;
	iceCandidates: IceCandidate[];
}

interface IProps {
	connectionState: any | null;
}

const renderIceCandidates = (iceCandidates: IceCandidate[], keyPrefix: string): JSX.Element => {
	if (iceCandidates.length === 0) {
		return <span />;
	}
	return (
		<ul>
			{iceCandidates.map((candidate, index) => (
				<li key={`${keyPrefix}-candidate-${index}`}>
					<div>
						<strong>Candidate:</strong> {candidate.candidate}
					</div>
					<div>
						<strong>SDP Mid:</strong> {candidate.sdpMid}
					</div>
					<div>
						<strong>SDP MLine Index:</strong> {candidate.sdpMLineIndex}
					</div>
					<div>
						<strong>Username Fragment:</strong> {candidate.usernameFragment}
					</div>
				</li>
			))}
		</ul>
	);
};

const renderConnectionType = (connectionType: ConnectionType, keyPrefix: string): JSX.Element => {
	return (
		<div>
			<div>
				<strong>Result:</strong> {connectionType.result}
			</div>
			<div>
				<strong>ICE Candidates:</strong>{" "}
				{renderIceCandidates(connectionType.iceCandidates, keyPrefix)}
			</div>
		</div>
	);
};

const renderData = (data: any, keyPrefix: string = ""): JSX.Element => {
	return (
		<div>
			<div>
				<h3>All:</h3>
				{renderConnectionType(data["all"], `${keyPrefix}-${CONNECTION_MODES.ALL}`)}
			</div>
			<div>
				<h3>Relay:</h3>
				{renderConnectionType(data["relay"], `${keyPrefix}-${CONNECTION_MODES.RELAY_ONLY}`)}
			</div>
			<div>
				<h3>Stun:</h3>
				{renderConnectionType(data["stun"], `${keyPrefix}-${CONNECTION_MODES.STUN}`)}
			</div>
			<div>
				<h3>Turn-UDP:</h3>
				{renderConnectionType(data["turn-udp"], `${keyPrefix}-${CONNECTION_MODES.TURN_UDP}`)}
			</div>
			<div>
				<h3>Turn-TCP:</h3>
				{renderConnectionType(data["turn-tcp"], `${keyPrefix}-${CONNECTION_MODES.TURN_TCP}`)}
			</div>
			<div>
				<h3>Turn-TLS:</h3>
				{renderConnectionType(data["turn-tls"], `${keyPrefix}-${CONNECTION_MODES.TURN_TLS}`)}
			</div>
		</div>
	);
};

const FormattedConnectionState: React.FC<IProps> = ({ connectionState }) => {
	if (!connectionState) {
		return <div>No connection state available</div>;
	}
	return <div style={{ textAlign: "left" }}>{renderData(connectionState)}</div>;
};

export default FormattedConnectionState;
