/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file File not found page component
 * @author Erv Walter
 * @module Epic.VideoApp.Features.NotFound.NotFound
 */

import { RouteComponentProps } from "@reach/router";
import React, { FC } from "react";
import SimpleCard from "~/components/Cards/SimpleCard";
import CopyrightLinkButton from "~/components/Copyright/CopyrightLinkButton";
import SimpleHeader from "~/components/Header/SimpleHeader";
import { useDocumentTitle, useStrings } from "~/hooks";

/**
 * File Not Found (404) page component
 * @param _props The props ;)
 */
const NotFound: FC<RouteComponentProps> = (_props) => {
	const tokenNames = ["PageNotFound", "BodyText", "Header"];
	const strings = useStrings("NotFound", tokenNames);

	useDocumentTitle(strings["PageNotFound"]);

	return (
		<>
			<SimpleHeader />
			<main>
				<SimpleCard>
					<h1>{strings["Header"]}</h1>
					<span>{strings["BodyText"]}</span>
				</SimpleCard>
				<CopyrightLinkButton />
			</main>
		</>
	);
};

export default NotFound;
