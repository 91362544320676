/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Button that triggers an access request to all moderators
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Toasts.Buttons.RequestAccessButtonGroup
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { useBroadcastMediaAccessRequest } from "~/hooks/messaging";
import { useUserState, userActions } from "~/state";
import { EpicUserType } from "~/types";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import ToastDismissButton from "./ToastDismissButton";

export enum RequestAccessButtonGroupTestIds {
	self = "RequestAccessButtonGroup",
}

enum TokenNames {
	requestAccess = "RequestAccess",
	enable = "Enable",
}

interface IProps {
	id: string;
}

/**
 * The RequestAccessButtonGroup component
 * @param props The props ;)
 */
const RequestAccessButtonGroup: FC<IProps> = (props: IProps) => {
	const { id } = props;

	const [requestState, userType, cameraLocked, micLocked, screenShareLocked, screenShareAllowed] =
		useUserState(
			(selectors) => [
				selectors.getDeviceRequestStatus(),
				selectors.getUserType(),
				selectors.getCameraLock(),
				selectors.getMicLock(),
				selectors.getScreenShareLock(),
				selectors.getUserPermission("canShareScreen"),
			],
			[],
		);
	// Do not request screen sharing permissions if the user cannot share their screen due to device or config
	const canShareScreen = useIsScreenShareSupported() && screenShareAllowed;

	const strings = useStrings("Toasts", Object.values(TokenNames));
	const dispatch = useDispatch();
	const broadcastRequest = useBroadcastMediaAccessRequest();

	const { audio: audioRequested, video: videoRequested, screenShare: screenShareRequested } = requestState;

	const sendRequestMessage = useCallback(() => {
		// The timer will wait thirty seconds, then clear this state allowing another message to be sent
		// If intermediate changes to the user's moderation state occur, this timeout ends early.
		if (!audioRequested && !videoRequested && !screenShareRequested) {
			const params = {
				audio: micLocked,
				video: cameraLocked,
				screenShare: screenShareLocked && canShareScreen,
			};
			dispatch(userActions.setRequestTimeout(params));
			broadcastRequest(params);
		}
	}, [
		audioRequested,
		broadcastRequest,
		cameraLocked,
		canShareScreen,
		dispatch,
		micLocked,
		screenShareLocked,
		screenShareRequested,
		videoRequested,
	]);

	const showRequestButton =
		!audioRequested && !videoRequested && !screenShareRequested && userType !== EpicUserType.emp;

	return (
		<>
			{/* Do not show a button if the user has recently requested access (~30 seconds) */}
			{showRequestButton && (
				<ActionButton
					tone="neutral"
					priority="primary"
					onClick={sendRequestMessage}
					text={strings[TokenNames.requestAccess]}
					data-testid={RequestAccessButtonGroupTestIds.self}
				/>
			)}
			<ToastDismissButton id={id} />
		</>
	);
};

RequestAccessButtonGroup.displayName = "RequestAccessButtonGroup";

export default React.memo(RequestAccessButtonGroup);
