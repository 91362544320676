/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Helper function to bucket participants by their waiting room status
 * @author William Cooper
 * @module Epic.VideoApp.WebCore.Helpers.BucketWaitingParticipants
 */

import { IParticipantInfo } from "~/types";
import { IRemoteUser } from "../interfaces";

export function bucketWaitingParticipants(
	allUsers: IRemoteUser[],
	allParticipantInfo: IParticipantInfo[],
	requiresAccess: boolean,
): IRemoteUser[][] {
	/** NOTE THAT BY HIDING PARTICIPANTS WHO HAVE NOT YET PROVIDED PARTICIPANT INFO, THIS LEAVES OPEN THE POSSIBILITY OF INVISIBLE PARTICIPANTS!
	 *  Consider:
	 *  1) Participant A joins the call
	 *  2) Participant B, who is a moderator, joins the call, and their client requests participant info but DOES NOT SEND THEIR OWN INFO (maybe they are malicious)
	 *  3) Once participant B gets A's info, participant A can see B but B cannot see A
	 *
	 *  We could resolve this by using pub sub and a single entity responsible for providing room info. In that case, we could wait until a user provides their info
	 *  before giving them the info of others in the room. Anyways, this is how it was implemented before the vendor abstraction, so sticking with the same strategy.
	 */
	if (requiresAccess) {
		const participants: IRemoteUser[] = [];
		const waitingParticipants: IRemoteUser[] = [];
		allUsers.forEach((current) => {
			const info = allParticipantInfo.find((info) => info.identity === current.getUserIdentity());
			if (info === undefined) {
				return;
			}
			if (info.inWaitingRoom) {
				waitingParticipants.push(current);
			} else {
				participants.push(current);
			}
		});
		return [participants, waitingParticipants];
	} else {
		return [allUsers, []];
	}
}
