/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Combined localTrack / speaker / hardwareTest state
 * @author Colin Walters
 * @module Epic.VideoApp.State.Combined.Hardware
 */

import { withSharedStates } from "@epic/react-redux-booster";
import { DeviceStatus, DeviceStatusSubtype, IStreamDevices } from "~/types";
import * as alerts from "../alerts";
import * as hardwareTest from "../hardwareTest";
import * as localTracks from "../localTracks";
import * as speaker from "../speakers";

/// COMBINED STATE TYPES ///

type SpeakerStatusState = [speaker.ISpeakerState, hardwareTest.IHardwareTestState];
type DeviceStatusState = [
	localTracks.ILocalTrackState,
	speaker.ISpeakerState,
	hardwareTest.IHardwareTestState,
];
type NewDeviceState = [localTracks.ILocalTrackState, hardwareTest.IHardwareTestState, alerts.IAlertState];
type StreamDeviceState = [localTracks.ILocalTrackState, hardwareTest.IHardwareTestState];

/// COMBINED REDUCERS ///

/**
 * Set the selected speaker into shared state and update the speaker status to reflect the newly selected speaker
 *
 * @param state shared state prior to this action
 * @param newSpeaker the new speaker media device to use, or null to indicate there is no selected speaker
 * @returns the new shared state after this action is applied
 */
function setSelectedSpeaker(
	state: SpeakerStatusState,
	newSpeaker: MediaDeviceInfo | null,
): SpeakerStatusState {
	const [prevSpeakerState, prevHardwareTestState] = state;

	// if there is no old or new speaker and the status is already error, don't make any updates
	if (
		!newSpeaker &&
		!prevSpeakerState.selectedSpeaker &&
		prevHardwareTestState.speakerState.status === DeviceStatus.warning
	) {
		return [prevSpeakerState, prevHardwareTestState];
	}

	const newSpeakerState = speaker.setSelectedSpeaker(prevSpeakerState, newSpeaker);

	let newHardwareTestState: hardwareTest.IHardwareTestState;
	if (newSpeaker) {
		// if there is a speaker, update status to success
		newHardwareTestState = hardwareTest.setSpeakerSuccess(prevHardwareTestState);
	} else {
		// otherwise, indicate that there is an error
		newHardwareTestState = hardwareTest.setSpeakerState(prevHardwareTestState, {
			status: DeviceStatus.warning,
			errorType: DeviceStatusSubtype.noDevice,
		});
	}

	return [newSpeakerState, newHardwareTestState];
}

/**
 * When successfully switching the video device, update the local track state and hardware test state to reflect the new device
 *
 * @param state shared state prior to this action
 * @param device new device switched to. If undefined, then assume we're still using the existing device.
 * @returns new shared state after this action is applied
 */
function switchVideoDevice(state: NewDeviceState, device: MediaDeviceInfo | undefined): NewDeviceState {
	const [prevLocalTracksState, prevHardwareTestState, prevAlertState] = state;
	const newLocalTrackState = localTracks.switchVideoDevice(prevLocalTracksState, device);
	const newHardwareTestState = hardwareTest.setCameraSuccess(prevHardwareTestState);
	const newAlertState = alerts.clearToasts(prevAlertState, "camera-switch-failed");

	return [newLocalTrackState, newHardwareTestState, newAlertState];
}

/**
 * Mark devices as having been auto-selected (allowing flow to proceed as usual when auto-selection may not be desirable)
 *
 * @param state shared state prior to this action
 * @returns the new shared state after this action is applied
 */
function setHasAutoSelectedDevicesIOS(state: DeviceStatusState): DeviceStatusState {
	const [prevLocalTracksState, ...prevSpeakerStatusState] = state;

	// mark auto-selection as having occurred (for a consistent flow, even though we're not auto-selecting on iOS)
	let newLocalTrackState = localTracks.setHasAutoSelectedVideo(prevLocalTracksState);
	newLocalTrackState = localTracks.setHasAutoSelectedAudio(newLocalTrackState);

	// for speaker, use this combined state's reducer (so any changes implemented above are utilized here)
	const newSpeakerStatusState = setSelectedSpeaker(prevSpeakerStatusState, null);

	return [newLocalTrackState, ...newSpeakerStatusState];
}

/**
 * Sets the initial hardware test state based on the initial state of the user's devices
 *
 * @param state shared state prior to this action
 * @param initialDeviceInfo the initial device info used for setting the initial hardware test state
 * @returns the new shared state after this action is applied
 */
function setInitialHardwareTestState(
	state: StreamDeviceState,
	initialDeviceInfo: IStreamDevices,
): StreamDeviceState {
	const [prevLocalTracksState, prevHardwareTestState] = state;

	let newHardwareTestState = { ...prevHardwareTestState };

	if (initialDeviceInfo.isAudioEnabled) {
		newHardwareTestState = hardwareTest.setMicrophoneState(prevHardwareTestState, {
			status: DeviceStatus.success,
			errorType: DeviceStatusSubtype.none,
		});
	}

	if (initialDeviceInfo.isVideoEnabled) {
		newHardwareTestState = hardwareTest.setCameraSuccess(newHardwareTestState);
	}

	return [prevLocalTracksState, newHardwareTestState];
}

/// BUILD IT ///

export const hardwareCombinedReducers = {
	setSelectedSpeaker: withSharedStates(speaker.state, hardwareTest.state).buildReducer(setSelectedSpeaker),
	setHasAutoSelectedDevicesIOS: withSharedStates(
		localTracks.state,
		speaker.state,
		hardwareTest.state,
	).buildReducer(setHasAutoSelectedDevicesIOS),
	switchVideoDevice: withSharedStates(localTracks.state, hardwareTest.state, alerts.state).buildReducer(
		switchVideoDevice,
	),
	setInitialHardwareTestState: withSharedStates(localTracks.state, hardwareTest.state).buildReducer(
		setInitialHardwareTestState,
	),
};
