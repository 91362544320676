/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file layout related constants
 * @author Colin Walters
 * @module Epic.VideoApp.Types.Layout
 */

import { isMobile } from "~/utils/os";

// all of the menu pages in the drop down menu
export type Menu =
	| "menu"
	| "devices"
	| "participants"
	| "languages"
	| "background"
	| "admitOptions"
	| "participantOptions"
	| "about";

// The video UI layout
export type VideoUILayoutType = "Active Speaker" | "Grid";

// HTML ID of the fullscreen-able <div> element
export const FullScreenContainerId = "full-screen-container";

// inflection point between when controls are shown in header vs. in button tray
// this should match $screen-width-inflection in _utilities.scss
export const ScreenWidthInflectionPoint = 850;

// minimum screen height to display the header bar
// this should match $small-screen-width-inflection in _utilities.scss
export const SmallScreenWidthInflectionPoint = ScreenWidthInflectionPoint - 300;

// minimum screen height to display the header bar
// this should match $minimum-screen-size-for-header in _utilities.scss
export const MinimumScreenHeightForHeader = 525;

// minimum height / width for the participant strip to hold its max capacity
export const MinSizeForMaxStripCapacity = 500;

// minimum dimensions for a video within our Grid display mode (4:3 aspect ratio)
// used to calculate a safe maximum number of videos that will fit based on screen size
// mobile devices are allowed to have thinner, shorter videos
// we may display smaller video on incredibly small screens in order to guarantee that we can render at least one remote feed per grid page
export const MinVideoWidth = isMobile() ? 150 : 200;
export const MinVideoHeight = MinVideoWidth * 0.75;

export const VideoMargin = 1;
export const VideoAspectRatio = 0.75;

// Context that a component should be rendered in
export enum DisplayContext {
	standAlone,
	lobby,
	devicesTray,
}

export interface IDimensions {
	height: number;
	width: number;
}

export enum StyleContext {
	light,
	dark,
}

export interface IMenuContext {
	isInMenu: boolean;
}
