/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Combined settings / userPreferences state
 * @author Spencer Eanes
 * @module Epic.VideoApp.State.Combined.Locale
 */

import { withSharedStates } from "@epic/react-redux-booster";
import * as settings from "../settings";
import * as user from "../user";

/// COMBINED STATE TYPES ///

type CombinedState = [settings.ISettingsState, user.IUserState];

/// COMBINED REDUCERS ///

/**
 * Update a users current and preferred language and publishes user preferences to local storage
 * @param state shared state prior to this action
 * @param newJwt the new locale to set into state
 * @returns the new shared state after this action is applied
 */
function setLocale(state: CombinedState, newLocale: string): CombinedState {
	if (!newLocale) {
		return state;
	}

	const [prevSettingState, prevPrefState] = state;
	const newSettingState = settings.setLocale(prevSettingState, newLocale);
	const newPrefState = user.setPreferredLocale(prevPrefState, newLocale);

	return [newSettingState, newPrefState];
}

/// BUILD IT ///

export const localeCombinedReducers = {
	setLocale: withSharedStates(settings.state, user.state).buildReducer(setLocale),
};
