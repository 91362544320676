/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Indicator of Participant's Network Quality
 * @author Colin Walters
 * @module Epic.VideoApp.Components.NetworkQualityIndicator.NetworkQualityIndicator
 */

import React, { FC } from "react";
import { useParticipantNetworkQuality, useStrings } from "~/hooks";
import { getNetworkLevelColor } from "~/utils/networkQualityIcon";
import { stringFormat } from "~/utils/strings";
import { IUser } from "~/web-core/interfaces";
import NetworkQualityFilled from "../../icons/networkQuality";

/**
 * Props for NetworkQualityIndicator Component
 */
interface IProps {
	participant?: IUser;
	backgroundColor: string;
	overrideFill?: string;
	hideLabel?: boolean;
	height?: number;
	width?: number;
}

/**
 * The NetworkQualityIndicator component
 * @param props The props ;)
 */
const NetworkQualityIndicator: FC<IProps> = (props) => {
	const { participant, backgroundColor, hideLabel, overrideFill, height, width } = props;

	const networkQualityLevel = useParticipantNetworkQuality(participant) ?? -1;

	const tokenNames = ["QualityLevel"];
	const strings = useStrings("NetworkQualityIndicator", tokenNames);

	const label = !hideLabel
		? stringFormat(strings["QualityLevel"], Math.min(networkQualityLevel, 4), "4")
		: undefined;

	const levelColor = overrideFill || getNetworkLevelColor(networkQualityLevel);

	return (
		<NetworkQualityFilled
			level={networkQualityLevel}
			height={height || 25}
			width={width || 25}
			aria-label={label}
			aria-hidden={hideLabel}
			fillColor={levelColor}
			baseColor={backgroundColor}
			role="img"
		/>
	);
};

NetworkQualityIndicator.displayName = "NetworkQualityIndicator";

export default NetworkQualityIndicator;
