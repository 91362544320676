import { ThemeProvider } from "@epic/chat";
import React, { FC, ReactNode, useEffect, useRef } from "react";
import { useUIState } from "~/state";
import { isIOS } from "~/utils/os";
import styles from "./ChatWrapper.module.scss";

interface IChatWrapperProps {
	children: ReactNode;
}

export enum ChatWrapperTestIds {
	self = "ChatWrapper",
}

/**
 * The ChatWrapper component that injects the theme into the chat library components
 */
const ChatWrapper: FC<IChatWrapperProps> = (props) => {
	const { children } = props;

	const isSidebarOpen = useUIState((selectors) => selectors.getSidebarStatus() === "open", []);
	const sidebarOpenRef = useRef<boolean>(isSidebarOpen);

	useEffect(() => {
		sidebarOpenRef.current = isSidebarOpen;
	}, [isSidebarOpen]);

	useEffect(() => {
		if (isIOS()) {
			const handleResize = (): void => {
				setTimeout(() => {
					if (window.visualViewport) {
						const htmlHeight = `${window.visualViewport.height}px`;
						document.documentElement.style.height = htmlHeight;
						document.body.style.height = htmlHeight;
					}
					scrollUpInstant();
				}, 0);
			};

			const handleOrientationChange = (): void => {
				if (sidebarOpenRef.current) {
					handleResize();
				}
			};

			const handleFocus = (): void => {
				if (sidebarOpenRef.current) {
					handleResize();
				}
			};

			const scrollUpInstant = (): void => {
				if (sidebarOpenRef.current) {
					setTimeout(() => {
						window.scrollTo({ top: -50 });
					}, 0);
				}
			};
			const scrollUpSmooth = (): void => {
				if (sidebarOpenRef.current) {
					setTimeout(() => {
						if ("scrollBehavior" in document.documentElement.style) {
							// Smooth scrolling is supported
							window.scrollTo({ top: -50, behavior: "smooth" });
						} else {
							// Smooth scrolling is not supported
							window.scrollTo(0, -50);
						}
					}, 0);
				}
			};
			if (window.visualViewport) {
				window.visualViewport.addEventListener("resize", handleResize);
			} else {
				window.addEventListener("resize", handleResize);
			}
			window.addEventListener("touchend", scrollUpSmooth);
			window.addEventListener("touchcancel", scrollUpSmooth);
			window.addEventListener("focus", handleFocus, true);
			window.addEventListener("orientationchange", handleOrientationChange);
			if (sidebarOpenRef.current) {
				handleResize();
			}
			return () => {
				if (window.visualViewport) {
					window.visualViewport.removeEventListener("resize", handleResize);
				} else {
					window.removeEventListener("resize", handleResize);
				}
				window.removeEventListener("touchend", scrollUpSmooth);
				window.removeEventListener("touchcancel", scrollUpSmooth);
				window.removeEventListener("focus", handleFocus, true);
				window.removeEventListener("orientationchange", handleOrientationChange);
			};
		}
	}, []);

	return (
		<ThemeProvider theme="darkMode">
			<div
				data-testid={ChatWrapperTestIds.self}
				className={styles["ChatWrapper"] + " innerChatContent"}
			>
				{children}
			</div>
		</ThemeProvider>
	);
};

export default ChatWrapper;
