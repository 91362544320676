/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hardware Test component
 * @author Matt Panico
 * @module Epic.VideoApp.Features.TestMode.TestMode
 */

import { RouteComponentProps } from "@reach/router";
import React, { FC } from "react";
import StandaloneHardwareTest from "~/components/HardwareTest/StandaloneHardwareTest";
import Loading from "~/components/Loading/Loading";
import FeatureWrapper from "~/components/StandardLayout/FeatureWrapper";
import { DeviceContextProvider } from "~/components/VideoCall/DeviceContext";
import {
	useDocumentTitle,
	useHardwareTestAuthentication,
	useSharedStrings,
	useStoreHardwareTest,
} from "~/hooks";
import { useAuthState } from "~/state";
import { SharedStringTokens } from "~/utils/strings";
import { VideoSessionProvider } from "~/web-core/components";

/**
 * The TestMode component
 * Handles authentication and server communications for the previsit hardware test
 *
 * @param _props The props ;)
 */
const TestMode: FC<RouteComponentProps> = (_props) => {
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);

	useHardwareTestAuthentication();

	useStoreHardwareTest(false);

	const strings = useSharedStrings([
		SharedStringTokens.defaultPageTitle,
		SharedStringTokens.connectingToOrganization,
	]);
	useDocumentTitle(strings[SharedStringTokens.defaultPageTitle]);

	if (!JWT) {
		return (
			<FeatureWrapper>
				<Loading loadingMessage={strings[SharedStringTokens.connectingToOrganization]} />
			</FeatureWrapper>
		);
	}

	return (
		<FeatureWrapper>
			<VideoSessionProvider>
				<DeviceContextProvider>
					<StandaloneHardwareTest />
				</DeviceContextProvider>
			</VideoSessionProvider>
		</FeatureWrapper>
	);
};

TestMode.displayName = "TestMode";

export default TestMode;
