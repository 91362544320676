/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to provide an interface for sending a request for a remote user
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Messaging.UseRequestParticipantInfo
 */

import { useCallback, useContext } from "react";
import { IInfoRequestMessage, MessageActionType, MessageType } from "~/types";
import { VideoSessionContext } from "~/web-core/components";
import { sendMessage } from "../../utils/sendMessage";

export interface IRequestName {
	(recipientIdentity: string): void;
}

/**
 * Request a name from a single user in the call
 * @returns a function to request a remote participant broadcast their name to all other users in the video visit.
 */
export function useRequestParticipantInfo(): IRequestName {
	const { session } = useContext(VideoSessionContext);

	const requestParticipantInfo = useCallback(
		(recipIdentity: string) => {
			const message: IInfoRequestMessage = {
				action: MessageActionType.request,
				payload: null,
				recipients: [recipIdentity],
				type: MessageType.infoRequest,
				needsACK: false,
			};
			// Ideally we have already verified we are missing this name, let's ensure they have a little bit of time to respond
			sendMessage(session, message);
		},
		[session],
	);

	return requestParticipantInfo;
}
