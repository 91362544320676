/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file the BackgroundStyleContext Context
 * @author Max Harkins
 * @module Epic.VideoApp.Components.Utilities.BackgroundStyleContext
 */

import React, { FC } from "react";
import { StyleContext } from "../../types/layout";

export const BackgroundStyleContext = React.createContext<StyleContext>(StyleContext.dark);

/**
 * Props for StyleContextProvider Component
 */
interface IProps {
	style: StyleContext;
}

/**
 * Context to determine whether light or dark themed components should be used
 * @param props the props ;)
 */
export const BackgroundStyleContextProvider: FC<IProps> = (props) => {
	const { style, children } = props;

	return <BackgroundStyleContext.Provider value={style}>{children}</BackgroundStyleContext.Provider>;
};
BackgroundStyleContextProvider.displayName = "BackgroundStyleContextProvider";
