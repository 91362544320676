/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file camera icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Camera
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum CameraIconTestIds {
	self = "Camera",
}

/**
 * The Camera component
 * @param props The props ;)
 */
const Camera: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
			data-testid={CameraIconTestIds.self}
		>
			{title && <title>{title}</title>}
			<path d="M22.5,22.5a1.62,1.62,0,0,1-1.62,1.62H6.25A1.61,1.61,0,0,1,4.63,22.5V9.5A1.61,1.61,0,0,1,6.25,7.88H20.88A1.62,1.62,0,0,1,22.5,9.5Z" />
			<path d="M24.13,12.75,27.38,9.5H29v13H27.38l-3.25-3.25H20.88v-6.5Z" />
		</svg>
	);
});

Camera.displayName = "Camera";

export default Camera;
