/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Displays the main (other) participant in the video call
 * @author Matt Panico and Colin Walters
 * @module Epic.VideoApp.Components.Participants.MainParticipant
 */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext, useMemo } from "react";
import { uiActions, useUIState, useUserState } from "~/state";
import { resolveClassName } from "~/utils/className";
import { ParticipantStatusContext, VideoSessionContext } from "~/web-core/components";
import ImageCaptureButton from "../ImageCapture/ImageCaptureButton";
import styles from "./MainParticipant.module.scss";
import ParticipantNameBar from "./ParticipantNameBar";
import UserVideo from "./UserVideo";

export enum MainParticipantTestIds {
	self = "MainParticipant",
}

/**
 * The MainParticipant component
 */

const MainParticipant: FC = () => {
	const { mainParticipant } = useContext(ParticipantStatusContext);
	const { session } = useContext(VideoSessionContext);
	const pinnedVideo = useUIState((selectors) => selectors.getPinnedVideo(), []);
	const canCaptureImage = useUserState((selectors) => selectors.getUserPermission("canCaptureImage"), []);
	const dispatch = useDispatch();
	const imCapHovered = useUIState((selectors) => selectors.getImCapHovered(), []);

	const videoType =
		pinnedVideo?.identity === mainParticipant?.getUserIdentity() && pinnedVideo?.videoType === "screen"
			? "screen"
			: "camera";

	const isLocal = useMemo(() => {
		return mainParticipant?.getUserIdentity() === session?.localUser.getUserIdentity();
	}, [mainParticipant, session]);

	const onClick = useCallback(() => {
		// don't allow pinning when there is only one participant in the room
		if (mainParticipant) {
			dispatch(
				uiActions.toggleVideoPinned({
					pinnedVideo: { identity: mainParticipant.getUserIdentity(), videoType },
					updateSource: "Default",
				}),
			);
		}
	}, [dispatch, mainParticipant, videoType]);

	if (!mainParticipant) {
		return null;
	}

	const mainParticipantStyle = resolveClassName(styles, {
		mainParticipant: true,
		highlight: imCapHovered,
		screenSharePreview: isLocal && videoType === "screen",
	});

	return (
		<div className={mainParticipantStyle} onClick={onClick} data-testid={MainParticipantTestIds.self}>
			<ParticipantNameBar
				participant={mainParticipant}
				isLocal={isLocal}
				location="main"
				videoType={videoType}
			/>
			{mainParticipant && (
				<UserVideo
					participant={mainParticipant}
					isLocal={isLocal}
					mainParticipant
					videoType={videoType}
				/>
			)}
			{canCaptureImage && <ImageCaptureButton participant={mainParticipant} videoType={videoType} />}
		</div>
	);
};

MainParticipant.displayName = "MainParticipant";

export default MainParticipant;
