/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Alerts Container Component
 * @author Tara Feldstein
 * @module Epic.VideoApp.Components.Alerts.GenericAlert
 */
import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useRef } from "react";
import { useStrings } from "~/hooks";
import { IGenericAlert } from "~/types";
import { alertActions } from "../../state/alerts";
import ActionButton from "../Utilities/ActionButton";
import KeyboardShortcut from "../Utilities/KeyboardShortcut";
import styles from "./Alerts.module.scss";
import FocusTrap from "./FocusTrap";

interface IProps {
	alert: IGenericAlert;
}

export enum GenericAlertTestIds {
	self = "GenericAlert",
}

/**
 * Generic Alert component
 *
 * Renders a generic alert, with a message and an Ok button to dismiss the message
 */
const GenericAlert: FC<IProps> = (props) => {
	const { alert, children } = props;

	const dispatch = useDispatch();
	const buttonRef = useRef<HTMLButtonElement>(null);

	const clearAlert = useCallback(() => {
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	const tokenNames = ["OK"];
	const strings = useStrings("GenericAlert", tokenNames);

	const { message } = alert;
	return (
		<div data-testid={GenericAlertTestIds.self} className={styles["modalCard"]}>
			<span id="message" className={styles["bodyText"]}>
				{message}
			</span>
			<div>{children}</div>
			<div className={styles["buttons"]}>
				<FocusTrap firstElement={buttonRef}>
					<ActionButton
						tone="neutral"
						priority="primary"
						onClick={clearAlert}
						buttonRef={buttonRef}
					>
						<KeyboardShortcut
							callback={clearAlert}
							keyCombination={["alt", "o"]}
							forModalAlert
							text={strings["OK"]}
						/>
					</ActionButton>
				</FocusTrap>
			</div>
		</div>
	);
};

GenericAlert.displayName = "GenericAlert";

export default GenericAlert;
