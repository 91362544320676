/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Exit pip icon
 * @author Arun Vasireddy
 * @module Epic.VideoApp.Icons.ExitPiP
 */

import React, { FC } from "react";
import { IconProps } from ".";
import styles from "../styles/_directional.scss";

/**
 * The ExitPiP component
 * @param props The props ;)
 */
const ExitPiP: FC<IconProps> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="30"
			viewBox="-2 -3.75 20 20"
			className={styles["flipWithPage"]}
			{...props}
		>
			<g id="White_PIP_Out" data-name="White PIP Out" transform="translate(-69.511 -21.764)">
				<path
					id="Path_6"
					data-name="Path 6"
					d="M13.779,5.055V-.025H-.005V10.654H5.347"
					transform="translate(70.016 22.289)"
					fill="none"
					strokeLinejoin="round"
					strokeWidth="1.5"
				/>
				<g id="arrowsbidirectional" transform="translate(88.413 19.422) rotate(180)">
					<path
						id="Path_2"
						data-name="Path 2"
						d="M5.631,1.989,3.7.056a.191.191,0,0,0-.327.135V1.531H.187A.191.191,0,0,0,0,1.722v.8a.191.191,0,0,0,.187.191H3.375V4.041a.191.191,0,0,0,.327.135L5.631,2.244A.187.187,0,0,0,5.631,1.989Z"
						transform="translate(14.385 -9.853) rotate(45)"
						stroke="none"
					/>
				</g>
				<g
					id="Rectangle_5"
					data-name="Rectangle 5"
					transform="translate(76.5 28.462)"
					fill="none"
					strokeLinejoin="round"
					strokeWidth="1.25"
				>
					<rect width="9" height="6" stroke="none" />
					<rect x="0.5" y="0.5" width="8" height="5" fill="none" />
				</g>
			</g>
		</svg>
	);
};

ExitPiP.displayName = "ExitPiP";

export default React.memo(ExitPiP);
