/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file types used for branding
 * @author Colin Walters
 * @module Epic.VideoApp.Types.Branding
 */

export interface IBrandingConfig {
	logo: string | null;
	backgroundImage: string | null;
	colors: IBrandedColors | null;
	virtualBackgroundImages: IRemoteResource[] | null;
}

export interface IRemoteResource {
	name: string;
	id: string;
}

export interface IBrandedColors {
	brandPrimary: string | null;
	brandAccentBack: string | null;
	brandBackground: string | null;
	overridePalette: string | null;
}

enum MyChartColor {
	brandPrimary = "--WPColor-BrandPrimary",
	brandAccentBack = "--WPColor-BrandAccentBack",
	brandBackground = "--WPColor-BrandBackground",
}

enum PaletteColor {
	headerBack = "--EVCColor-HeaderBack",
	headerButtonFore = "--EVCColor-HeaderButtonFore",
	headerButtonBack = "--EVCColor-HeaderButtonBack",
	headerButtonHoverBack = "--EVCColor-HeaderButtonHoverBack",
	headerButtonHoverBorder = "--EVCColor-HeaderButtonHoverBorder",
	menuBack = "--EVCColor-MenuBack",
	menuButtonBack = "--EVCColor-MenuButtonBack",
	menuButtonHoverBack = "--EVCColor-MenuButtonHoverBack",
	menuButtonHoverBorder = "--EVCColor-MenuButtonHoverBorder",
}

export type BrandedColor = MyChartColor | PaletteColor;
export const BrandedColor = { ...MyChartColor, ...PaletteColor };

export enum PaletteName {
	black = "black",
	red = "red",
	orange = "orange",
	yellow = "yellow",
	green = "green",
	blue = "blue",
	purple = "purple",
	pink = "pink",
}

export type Palette = Record<PaletteColor, string>;

const DEFAULT_PALETTE: Palette = {
	[BrandedColor.headerBack]: "#000000",
	[BrandedColor.headerButtonFore]: "#ffffff",
	[BrandedColor.headerButtonBack]: "#000000",
	[BrandedColor.headerButtonHoverBack]: "#111111",
	[BrandedColor.headerButtonHoverBorder]: "#333333",
	[BrandedColor.menuBack]: "#222222",
	[BrandedColor.menuButtonBack]: "#222222",
	[BrandedColor.menuButtonHoverBack]: "#333333",
	[BrandedColor.menuButtonHoverBorder]: "#444444",
};

const RED_PALETTE: Palette = {
	[BrandedColor.headerBack]: "#2a1012",
	[BrandedColor.headerButtonFore]: "#ffffff",
	[BrandedColor.headerButtonBack]: "#2a1012",
	[BrandedColor.headerButtonHoverBack]: "#5d3f3b",
	[BrandedColor.headerButtonHoverBorder]: "#5b4446",
	[BrandedColor.menuBack]: "#442a26",
	[BrandedColor.menuButtonBack]: "#442a26",
	[BrandedColor.menuButtonHoverBack]: "#5d3f3b",
	[BrandedColor.menuButtonHoverBorder]: "#5b4446",
};

const ORANGE_PALETTE: Palette = {
	[BrandedColor.headerBack]: "#342210",
	[BrandedColor.headerButtonFore]: "#ffffff",
	[BrandedColor.headerButtonBack]: "#342210",
	[BrandedColor.headerButtonHoverBack]: "#584d3c",
	[BrandedColor.headerButtonHoverBorder]: "#695c47",
	[BrandedColor.menuBack]: "#413626",
	[BrandedColor.menuButtonBack]: "#413626",
	[BrandedColor.menuButtonHoverBack]: "#584d3c",
	[BrandedColor.menuButtonHoverBorder]: "#695c47",
};

const YELLOW_PALETTE: Palette = {
	[BrandedColor.headerBack]: "#2a2a10",
	[BrandedColor.headerButtonFore]: "#ffffff",
	[BrandedColor.headerButtonBack]: "#2a2a10",
	[BrandedColor.headerButtonHoverBack]: "#58583c",
	[BrandedColor.headerButtonHoverBorder]: "#696647",
	[BrandedColor.menuBack]: "#414026",
	[BrandedColor.menuButtonBack]: "#414026",
	[BrandedColor.menuButtonHoverBack]: "#58583c",
	[BrandedColor.menuButtonHoverBorder]: "#696647",
};

const GREEN_PALETTE: Palette = {
	[BrandedColor.headerBack]: "#0b2715",
	[BrandedColor.headerButtonFore]: "#ffffff",
	[BrandedColor.headerButtonBack]: "#0b2715",
	[BrandedColor.headerButtonHoverBack]: "#394b3a",
	[BrandedColor.headerButtonHoverBorder]: "#445945",
	[BrandedColor.menuBack]: "#243425",
	[BrandedColor.menuButtonBack]: "#243425",
	[BrandedColor.menuButtonHoverBack]: "#394b3a",
	[BrandedColor.menuButtonHoverBorder]: "#445945",
};

const BLUE_PALETTE: Palette = {
	[BrandedColor.headerBack]: "#0b233a",
	[BrandedColor.headerButtonFore]: "#ffffff",
	[BrandedColor.headerButtonBack]: "#0b233a",
	[BrandedColor.headerButtonHoverBack]: "#3c4758",
	[BrandedColor.headerButtonHoverBorder]: "#475469",
	[BrandedColor.menuBack]: "#263141",
	[BrandedColor.menuButtonBack]: "#263141",
	[BrandedColor.menuButtonHoverBack]: "#3c4758",
	[BrandedColor.menuButtonHoverBorder]: "#475469",
};

const PURPLE_PALETTE: Palette = {
	[BrandedColor.headerBack]: "#310B4A",
	[BrandedColor.headerButtonFore]: "#ffffff",
	[BrandedColor.headerButtonBack]: "#310B4A",
	[BrandedColor.headerButtonHoverBack]: "#4e4256",
	[BrandedColor.headerButtonHoverBorder]: "#5d4e66",
	[BrandedColor.menuBack]: "#372c3f",
	[BrandedColor.menuButtonBack]: "#372c3f",
	[BrandedColor.menuButtonHoverBack]: "#4e4256",
	[BrandedColor.menuButtonHoverBorder]: "#5d4e66",
};

const PINK_PALETTE: Palette = {
	[BrandedColor.headerBack]: "#2f0c26",
	[BrandedColor.headerButtonFore]: "#ffffff",
	[BrandedColor.headerButtonBack]: "#2f0c26",
	[BrandedColor.headerButtonHoverBack]: "#4b2b41",
	[BrandedColor.headerButtonHoverBorder]: "#5a374d",
	[BrandedColor.menuBack]: "#341d2c",
	[BrandedColor.menuButtonBack]: "#341d2c",
	[BrandedColor.menuButtonHoverBack]: "#4b2b41",
	[BrandedColor.menuButtonHoverBorder]: "#5a374d",
};

export const PALETTE_MAP: Record<PaletteName, Palette> = {
	black: DEFAULT_PALETTE,
	blue: BLUE_PALETTE,
	green: GREEN_PALETTE,
	orange: ORANGE_PALETTE,
	pink: PINK_PALETTE,
	purple: PURPLE_PALETTE,
	red: RED_PALETTE,
	yellow: YELLOW_PALETTE,
};

export const ALL_COLOR_PALETTES = Object.entries(PALETTE_MAP)
	.filter(([paletteName]) => paletteName !== "black")
	.map(([, palette]) => palette);
