/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file A hook to provide the document's visibility state
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UseIsDocumentVisible
 */

import { useEffect, useState } from "react";

export function useIsDocumentVisible(): boolean {
	const [isVisible, setVisibility] = useState<boolean>(!document.hidden);

	useEffect(() => {
		const onVisibilityChange = (): void => {
			setVisibility(!document.hidden);
		};

		document.addEventListener("visibilitychange", onVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", onVisibilityChange);
		};
	}, []);

	return isVisible;
}
