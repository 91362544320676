/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file hook to determine the max height of a menu page
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Hooks.UseMaxMenuPageHeight
 */

import { useWindowSize } from "~/hooks";

const menuScreenSizePercentage = 0.65;

/**
 * Get the maximum height for a menu page (either for full drop down menu or single page menu)
 * @returns the maximum height for a menu page given the current screen dimensions
 */
export function useMaxMenuPageHeight(): number {
	const { height } = useWindowSize();
	return height * menuScreenSizePercentage;
}
