/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Returns any video feeds that will exist on every page of the grid
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Grid.Hooks.UseStaticVideoFeeds
 */

import { useMemo } from "react";
import { useStream } from "~/web-core/hooks/useStream";
import { ISession, IUser } from "~/web-core/interfaces";

export interface IVideoParticipant {
	participant: IUser;
	isScreenShare: boolean;
}

/**
 * Hook that will track updates to the in-call participants to return whichever video feeds should always be displayed within the grid.
 *
 * @param localParticipant - The room's local participant if one exists
 * @param screenShareParticipant - The participant that is shared their screen
 * @returns - The concatenated list of participants that we want to render on every grid page.
 */
export function useStaticVideoFeeds(
	session: ISession | undefined,
	screenShareParticipant: IUser | null,
): IVideoParticipant[] {
	const localUser = session?.localUser;
	const localScreenShareStream = useStream(localUser, "screen");
	const localShareExists = !!localScreenShareStream;
	const staticVideos = useMemo(() => {
		const staticFeeds: IVideoParticipant[] = [];
		if (screenShareParticipant) {
			staticFeeds.push({ participant: screenShareParticipant, isScreenShare: true });
		}
		if (localUser) {
			// Display the local participant's camera preview. If they have no camera track, it'll display a no-camera icon.
			staticFeeds.push({ participant: localUser, isScreenShare: false });
			// If the local user is screen sharing, then display a screen share preview. We don't want to show anything if there's no current share.
			if (localShareExists) {
				staticFeeds.push({ participant: localUser, isScreenShare: true });
			}
		}

		return staticFeeds;
	}, [localShareExists, localUser, screenShareParticipant]);

	return staticVideos;
}
