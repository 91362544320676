/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file hook to store initial reaction response
 * @author Max Harkins
 * @module Epic.VideoApp.Components.FeedbackSurvey.Hooks.UseSaveUserReaction
 */

import { useCallback } from "react";
import { useDispatch } from "~/../node_modules/@epic/react-redux-booster/dist/use-dispatch.js";
import { feedbackSurveyActions, useFeedbackSurveyState } from "~/state/feedbackSurvey";
import { IAccessTokenUpdate } from "~/types";
import { FeedbackReaction } from "~/types/survey";
import { warn } from "~/utils/logging";
import { makeRequest } from "~/utils/request";

/**
 * Saves the user's initial feedback reaction (either positive or negative) to the server. Server takes different
 * action if the reaction is from a workflow that's optional for a user, e.g. the provider button to optionally submit
 * feedback if the frequency check is failed.
 *
 * The callback is asynchronous and finishes once the web service finishes.
 */
export function useSaveUserReaction(): (
	reaction: FeedbackReaction,
	isOptionalQuestion: boolean,
) => Promise<void> {
	const JWT = useFeedbackSurveyState((selectors) => selectors.getFeedbackJWT(), []);
	const dispatch = useDispatch();

	const saveReaction = useCallback(
		async (reaction: FeedbackReaction, isOptionalQuestion: boolean) => {
			if (!JWT) {
				return;
			}
			try {
				const result = await storeSurveyReaction(reaction, JWT, isOptionalQuestion);

				const { jwt: newJWT } = result;
				if (newJWT) {
					dispatch(feedbackSurveyActions.setFeedbackJWT(newJWT));
				}
			} catch (error) {
				warn("Could not save feedback reaction", error);
			}
		},
		[JWT, dispatch],
	);

	return saveReaction;
}
/**
 * Stores back to the server that the user reacted positively or negatively to the survey (by clicking the happy or sad face).
 * This is stored in the database and used for metrics and reporting
 * @param reaction Whether the user reacted positively or negatively to the survey (which face icon they clicked)
 * @param jwt Used to authenticate the request, must be valid in order for the web server to process the request
 * @param isOptionalQuestion Whether the reaction is coming from an optional question that not all users may see, or from the default user survey
 * @returns A void promise that resolves when the request is complete
 */
export async function storeSurveyReaction(
	reaction: FeedbackReaction,
	jwt: string,
	isOptionalQuestion: boolean,
): Promise<IAccessTokenUpdate> {
	return makeRequest<IAccessTokenUpdate>("/api/FeedbackSurvey/StoreSurveyReaction", "POST", jwt, {
		reaction: reaction,
		fromOptionalWorkflow: isOptionalQuestion,
	});
}
