/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file The selector used to pick the document type associated with image capture
 * @author Will Cooper
 * @module Epic.VideoApp.Components.ImageCapture.Controls.DocumentTypeSelector
 */

import React, { FC, useCallback, useEffect, useState } from "react";
import { useStrings } from "~/hooks";
import { useImageState } from "~/state";
import { ITelemedicineDocType } from "~/types";
import { selectDefaultDocumentType } from "~/utils/imageCapture";
import { stringFormat } from "~/utils/strings";
import styles from "../ImagePreviewPane.module.scss";

interface ISelectorProps {
	setDocType: (docType: ITelemedicineDocType | null) => void;
}

enum TokenNames {
	imageType = "ImageType",
	documentType = "DocumentType",
}

export enum DocumentTypeSelectorTestIds {
	self = "DocumentTypeSelector",
}

/**
 * Drop down selector to choose different image type category values
 * @param props - The list of available document types for the current user
 */
const DocumentTypeSelector: FC<ISelectorProps> = (props) => {
	const { setDocType: setParentDocType } = props;
	const strings = useStrings("DocumentTypeSelector", Object.values(TokenNames));
	const docTypes = useImageState((selectors) => selectors.getDocumentTypes(), []);
	const [selectedDocumentType, setDocType] = useState<ITelemedicineDocType | null>(
		selectDefaultDocumentType(docTypes),
	);

	// Keep ImagePreviewPane doc type state in sync with this components state
	useEffect(() => {
		setParentDocType(selectedDocumentType);
	}, [selectedDocumentType, setParentDocType]);

	const onSelectorChange = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement>) => {
			const chosenType = docTypes.find((type) => type.id === event.target.value);
			if (chosenType) {
				setDocType(chosenType);
			}
		},
		[docTypes, setDocType],
	);

	// with 0 or 1 options, show placeholder text or nothing
	if (!docTypes || !docTypes.length || (docTypes && docTypes.length === 1) || !selectedDocumentType) {
		return null;
	}

	return (
		<div className={styles["verticalStack"]}>
			<label className={styles["docType"]} htmlFor="docTypeSelector">
				{strings[TokenNames.documentType]}
			</label>
			<select
				onChange={onSelectorChange}
				value={selectedDocumentType.id}
				id="docTypeSelector"
				data-testid={DocumentTypeSelectorTestIds.self}
			>
				{docTypes.map((doc) => {
					const label = doc.title || stringFormat(strings[TokenNames.imageType], doc.id);

					return (
						<option key={doc.id} value={doc.id} label={label}>
							{label}
						</option>
					);
				})}
			</select>
		</div>
	);
};

DocumentTypeSelector.displayName = "DocumentTypeSelector";

export default DocumentTypeSelector;
