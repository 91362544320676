/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Menu Page for setting patient admit controls
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Moderation.SimpleRequestMenu
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import BaseButton from "~/components/Utilities/BaseButton";
import { useStrings } from "~/hooks";
import { useBroadcastMediaAccessRequest } from "~/hooks/messaging";
import Camera from "~/icons/camera";
import Mic from "~/icons/mic";
import ScreenShareStart from "~/icons/screenShareStart";
import { useUserState, userActions } from "~/state/user";
import { resolveClassName } from "~/utils/className";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import styles from "./ParticipantControlMenu.module.scss";

enum TokenNames {
	videoRequest = "VideoRequest",
	audioRequest = "AudioRequest",
	screenShareRequest = "ScreenShareRequest",
}

export enum SimpleRequestMenuTestIds {
	micButton = "micRequestButton",
	cameraButton = "cameraRequestButton",
	screenShareButton = "screenShareRequestButton",
}

/**
 * SimpleRequestMenu
 */
const SimpleRequestMenu: FC = () => {
	const [micLocked, cameraLocked, screenShareLocked, requestState, screenShareAllowed] = useUserState(
		(selectors) => [
			selectors.getMicLock(),
			selectors.getCameraLock(),
			selectors.getScreenShareLock(),
			selectors.getDeviceRequestStatus(),
			selectors.getUserPermission("canShareScreen"),
		],
		[],
	);
	const screenShareSupported = useIsScreenShareSupported();
	const hideScreenShare = !screenShareSupported || !screenShareAllowed;
	const broadcastRequest = useBroadcastMediaAccessRequest();

	const strings = useStrings("RequestOptions", Object.values(TokenNames));
	const dispatch = useDispatch();

	const { audio: audioRequested, video: videoRequested, screenShare: screenShareRequested } = requestState;

	const sendRequestMessage = useCallback(
		(audio?: boolean, video?: boolean, screenShare?: boolean) => {
			dispatch(
				userActions.setRequestTimeout({
					audio: audio ?? audioRequested,
					video: video ?? videoRequested,
					screenShare: screenShare ?? screenShareRequested,
				}),
			);
			broadcastRequest({ audio: audio, video: video, screenShare: screenShare });
		},
		[audioRequested, broadcastRequest, dispatch, screenShareRequested, videoRequested],
	);

	const sendAudioRequestMessage = useCallback(() => {
		sendRequestMessage(true, undefined, undefined);
	}, [sendRequestMessage]);

	const sendVideoRequestMessage = useCallback(() => {
		sendRequestMessage(undefined, true, undefined);
	}, [sendRequestMessage]);

	const sendScreenShareRequestMessage = useCallback(() => {
		sendRequestMessage(undefined, undefined, true);
	}, [sendRequestMessage]);

	const menuClass = resolveClassName(styles, {
		moderatorMenu: true,
		menu: true,
	});
	const buttonClass = resolveClassName(styles, {
		moderatorRow: true,
		menu: true,
	});

	return (
		<div className={menuClass}>
			<BaseButton
				onClick={sendAudioRequestMessage}
				text={strings[TokenNames.audioRequest]}
				className={buttonClass}
				icon={Mic}
				iconClassName={styles["buttonIcon"]}
				disabledTabbable={!micLocked || audioRequested}
				data-testid={SimpleRequestMenuTestIds.micButton}
			/>
			<BaseButton
				onClick={sendVideoRequestMessage}
				text={strings[TokenNames.videoRequest]}
				className={buttonClass}
				icon={Camera}
				iconClassName={styles["buttonIcon"]}
				disabledTabbable={!cameraLocked || videoRequested}
				data-testid={SimpleRequestMenuTestIds.cameraButton}
			/>
			{!hideScreenShare && (
				<BaseButton
					onClick={sendScreenShareRequestMessage}
					text={strings[TokenNames.screenShareRequest]}
					className={buttonClass}
					icon={ScreenShareStart}
					iconClassName={styles["buttonIcon"]}
					disabledTabbable={!screenShareLocked || screenShareRequested}
					data-testid={SimpleRequestMenuTestIds.screenShareButton}
				/>
			)}
		</div>
	);
};

SimpleRequestMenu.displayName = "SimpleRequestMenu";

export default SimpleRequestMenu;
