/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Component holding a group of related questions
 * @author Max Harkins
 * @module Epic.VideoApp.Components.Utilities.Survey.QuestionGroup
 */

import React, { FC } from "react";
import { IQuestion, IQuestionGroup, ISurveyResponses } from "../../../types/survey";
import Question from "./Question";
import styles from "./QuestionGroup.module.scss";
import QuestionIcon from "./QuestionIcon";

/**
 * Props for QuestionGroup Component
 */
interface IProps {
	/** Question group containing question objects and info which should be shown */
	group: IQuestionGroup;
	/** Callback for questions, to be called when a question input changes */
	handleInputChange: (question: IQuestion) => void;
	/** Raw class name to be used for styling */
	className: string;
	/** Answers to questions that are currently visible */
	currentlySelected: ISurveyResponses;
	/** Strings to use for question and group names */
	strings: Record<string, string>;
}

export enum QuestionGroupTestIds {
	self = "QuestionGroup",
}

/**
 * The QuestionGroup component
 * @param _props The props ;)
 */
const QuestionGroup: FC<IProps> = (props) => {
	const { className, group, handleInputChange, currentlySelected, strings } = props;

	return (
		<fieldset id={group.id} className={className} data-testid={QuestionGroupTestIds.self}>
			<legend className={styles["legendDropdownContainer"]}>
				<h2>
					<div className={styles["groupLegend"]}>
						<QuestionIcon iconName={group.iconName ?? ""} shrinkMobileIcons />
						<div>{strings[group.name]}</div>
					</div>
				</h2>
			</legend>
			<ul className={styles["questionList"]}>
				{group.subQuestions.map((question) => (
					<li key={question.id}>
						<Question
							question={question}
							handleInputChange={handleInputChange}
							isChecked={currentlySelected[question.id]}
							name={strings[question.name]}
							key={question.id}
						/>
					</li>
				))}
			</ul>
		</fieldset>
	);
};

QuestionGroup.displayName = "QuestionGroup";

export default React.memo(QuestionGroup);
