/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Helper component to determine the buttons, if any, that should be rendered within a toast.
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Toasts.ToastButtons
 */

import React, { FC, useMemo } from "react";
import { ToastWithId } from "~/types";
import ChatToastButtonGroup from "./Buttons/ChatToastButtonGroup";
import HandleAdmissionButtonGroup from "./Buttons/HandleAdmissionButtonGroup";
import HandleMultipleRequestButtonGroup from "./Buttons/HandleMultipleRequestButtonGroup";
import HandleRequestButtonGroup from "./Buttons/HandleSingleDeviceRequestButtonGroup";
import OpenMenuButton from "./Buttons/OpenMenuButton";
import RequestAccessButtonGroup from "./Buttons/RequestAccessButtonGroup";
import ToastDismissButton from "./Buttons/ToastDismissButton";
import styles from "./Toasts.module.scss";

export enum ToastButtonsTestIds {
	toastContent = "ToastContent",
}

/**
 * Props for ToastButtons Component
 */
interface IProps {
	toast: ToastWithId;
}

/**
 * The ToastButtons component
 * @param props The props ;)
 */
const ToastButtons: FC<IProps> = (props) => {
	const { id, type, dismissTimeMS, ttlSeconds } = props.toast;

	// Toast Buttons can be specific actions or groups of actions
	const toastContent = useMemo(() => {
		switch (type) {
			case "user-moderated":
				return <RequestAccessButtonGroup id={id} />;
			case "single-participant-request":
				return <HandleRequestButtonGroup toastId={id} identity={props.toast.extraInfo.identity} />;
			case "multi-participant-request":
				return <HandleMultipleRequestButtonGroup />;
			case "single-participant-waiting":
				return <HandleAdmissionButtonGroup identity={props.toast.extraInfo.identity} />;
			case "multi-participant-waiting":
				return <OpenMenuButton menu="participants" />;
			case "chat-unread-message":
				return <ChatToastButtonGroup id={id} ttlSeconds={ttlSeconds} />;
			default:
				return null;
		}
	}, [id, props.toast, type, ttlSeconds]);

	if (toastContent != null) {
		return (
			<div className={styles["buttonRow"]} data-testid={ToastButtonsTestIds.toastContent}>
				{toastContent}
			</div>
		);
	}

	// The default button is just a dismiss option
	if (dismissTimeMS !== undefined) {
		return <ToastDismissButton id={id} noText />;
	}

	// Otherwise return null
	return null;
};

ToastButtons.displayName = "ToastButtons";

export default React.memo(ToastButtons);
