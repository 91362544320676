/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file the CardContainer component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Cards.CardContainer
 */

import React, { FC } from "react";
import { resolveClassName } from "~/utils/className";
import styles from "./CardContainer.module.scss";

interface IProps {
	hidden?: boolean;
	/* If the card inside the container can be assumed to be short, for calculating overlap */
	shortCard?: boolean;
	/* If the card should be extra wide */
	wideCard?: boolean;
}

/**
 * The CardContainer component
 * @param props The props ;)
 */
const CardContainer: FC<IProps> = (props) => {
	const { children, hidden, shortCard, wideCard } = props;

	const className = resolveClassName(styles, { cardContainer: true, shortCard, wideCard });

	return (
		<div className={className} aria-hidden={hidden}>
			{children}
		</div>
	);
};

CardContainer.displayName = "CardContainer";

export default CardContainer;
