/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file buildAccessors function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\build-accessors
 */

import { ISelectorCollection } from "../../types";
import { ISliceKeyCollection, SelectorWithMetadata } from "../types";
import { getStateSlice } from "../create-meta-reducer/get-state-slice";

interface AccessorCollection {
	[key: string]: (...args: []) => any;
}

/**
 * Given a set of selectors, build the corresponding accessors and return them.
 * @param selectors The selectors to use
 * @param getState A function that will return the current state when called
 * @param id The id for this selector group
 * @param sliceKeys Map of store entity IDs to state slice keys
 * @returns The collection of accessors (selectors bound to a particular state slice) that will be available to the developer
 */
export function buildAccessors(
	selectors: ISelectorCollection<any>,
	getState: () => any,
	id: number,
	sliceKeys: ISliceKeyCollection,
): AccessorCollection {
	const retVal: AccessorCollection = {};
	Object.keys(selectors).forEach(selectorName => {
		if (!selectorName.startsWith("__$")) {
			const selector = selectors[selectorName] as SelectorWithMetadata;
			retVal[selectorName] = (...args: any[]) => {
				const slice = getStateSlice(getState(), sliceKeys, id, selector.requiredSliceIds);
				return (selectors[selectorName] as any).apply(undefined, [slice, ...args]);
			};
		}
	});
	return retVal;
}
