/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file grow/shrink arrow icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.GrowShrinkArrow
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The GrowShrinkArrow component
 * @param props The props ;)
 */
const GrowShrinkArrow: FC<IconProps> = memo((props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
			<defs>
				<clipPath id="clip-downArrow">
					<rect width="24" height="24" />
				</clipPath>
			</defs>
			<g id="downArrow" clipPath="url(#clip-downArrow)">
				<g id="Group_4066" data-name="Group 4066" transform="translate(-1476.694 -843.282)">
					<path
						id="Path_1632"
						data-name="Path 1632"
						d="M-1338.435-1381.846l13.829,13.829"
						transform="translate(2819.128 2229.128)"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth="4"
					/>
					<path
						id="Path_1634"
						data-name="Path 1634"
						d="M0,0,7.941,7.941,15.883,0"
						transform="translate(1484.21 861.667) rotate(-45)"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth="4"
					/>
				</g>
			</g>
		</svg>
	);
});

GrowShrinkArrow.displayName = "GrowShrinkArrow";

export default GrowShrinkArrow;
