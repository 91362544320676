/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Handles messages from remote data tracks (one per remote participant)
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.DataTracks.DataTrackListener
 */

import { FC, memo, useContext, useEffect } from "react";
import { DataTrack as IDataTrack } from "twilio-video";
import { useRoomState } from "~/state";
import { ParticipantDataContext } from "~/web-core/components";
import { useRequestPeerInfoInterval } from "~/web-core/hooks/useRequestPeerInfoInterval";

interface IDataTrackProps {
	track: IDataTrack;
	participantIdentity: string;
	messageHandler: (message: string, participantIdentity: string) => void;
}

const DataTrackListener: FC<IDataTrackProps> = memo((props: IDataTrackProps) => {
	const { track, participantIdentity, messageHandler } = props;
	const { namelessParticipantData } = useContext(ParticipantDataContext);
	const participantInfo =
		useRoomState((selectors) => selectors.getParticipantInfo(participantIdentity), []) ?? null;

	useRequestPeerInfoInterval(participantIdentity, namelessParticipantData, participantInfo);

	// Initialize the listener to receive messages from remote data track of this participant
	useEffect(() => {
		const handleMessage = (message: string): void => {
			//Pass message down to stateful interface
			messageHandler(message, participantIdentity);
		};
		track.on("message", handleMessage);
		return () => {
			track.off("message", handleMessage);
		};
	}, [messageHandler, participantIdentity, track]);

	return null; // This component does not return any HTML, so we will return 'null' instead.
});

export default DataTrackListener;
