/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Data Track hook to send messages that mute or disable the camera of remote participants
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Messaging.Moderation.UseSendModeratorAction
 */

import { useCallback, useContext } from "react";
import { IBasicAuditEvent, useAuditFeatureUse } from "~/hooks";
import {
	EventType,
	IModeratorActionMessage,
	IModeratorPayload,
	MessageActionType,
	MessageType,
} from "~/types";
import { VideoSessionContext } from "~/web-core/components";
import { sendMessage } from "../../../utils/sendMessage";

export interface ISendModeratorMessage {
	(moderatorAction: IModeratorPayload, recipientID: string, shouldAudit?: boolean): void;
}

/**
 * Sends a data track message to a specific participant to have them lock or unlock various media
 * @returns a function used to send moderator data track messages
 */
export function useSendModeratorAction(): ISendModeratorMessage {
	const { session } = useContext(VideoSessionContext);

	const auditFeatureUse = useAuditFeatureUse();

	const requestModeratorAction = useCallback(
		(moderatorAction: IModeratorPayload, recipientID: string, shouldAudit?: boolean) => {
			// Determine payload type
			const { videoLock, audioLock, screenShareLock } = moderatorAction;

			// Only audit built in events when they happen individually to save on out-going web requests
			if (shouldAudit) {
				const events: IBasicAuditEvent[] = [];

				// Audit features (an undefined value is a no-op so don't log an event)
				if (audioLock !== undefined) {
					events.push({
						feature: audioLock ? EventType.microphoneLocked : EventType.microphoneUnlocked,
						targetParticipantId: recipientID,
					});
				}
				if (videoLock !== undefined) {
					events.push({
						feature: videoLock ? EventType.cameraLocked : EventType.cameraUnlocked,
						targetParticipantId: recipientID,
					});
				}
				if (screenShareLock !== undefined) {
					events.push({
						feature: screenShareLock
							? EventType.screenSharingLocked
							: EventType.screenSharingUnlocked,
						targetParticipantId: recipientID,
					});
				}
				if (events.length > 0) {
					void auditFeatureUse(events);
				}
			}

			const message: IModeratorActionMessage = {
				action: MessageActionType.request,
				payload: moderatorAction,
				recipients: [recipientID],
				type: MessageType.moderate,
				needsACK: false,
			};
			sendMessage(session, message);
		},
		[auditFeatureUse, session],
	);

	return requestModeratorAction;
}
