/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Functions used in applying background effects or manipulating video processors
 * @author Liam Liden
 * @module Epic.VideoApp.Utils.BackgroundEffects
 */

import { isSupported } from "twilio-video";
import { Month } from "~/types";
import { DefaultImageNames } from "~/types/backgrounds";
import { isSafari } from "./browser";
import { isMobile } from "./os";

/**
 * Gets the current seasonal background image
 * @returns The path of the seasonal background image that should be used
 */
export function getSeasonalImagePath(): DefaultImageNames {
	const month = new Date().getMonth();
	// Dec-Feb Winter; Mar-May Spring; June-Aug Summer; Sept-Nov Fall
	if (month < Month.march) {
		return DefaultImageNames.winter;
	} else if (month < Month.june) {
		return DefaultImageNames.spring;
	} else if (month < Month.september) {
		return DefaultImageNames.summer;
	} else if (month < Month.december) {
		return DefaultImageNames.fall;
	}
	return DefaultImageNames.winter;
}

export interface IBackgroundsAvailable {
	blur: boolean;
	epic: boolean;
	branded: boolean;
}

const supportsImageBackgrounds = isSupported && !isMobile() && !isSafari();
const supportsBlur = supportsImageBackgrounds;

export function getAvailableBackgrounds(
	blurAccess: boolean,
	epicBackgroundsAccess: boolean,
	brandedBackgroundAccess: boolean,
	areBackgroundsDisabled: boolean = false,
): IBackgroundsAvailable {
	// Chrome 122 has a memory leak with the underlying WebRTC background effects implementation
	const blurAvailable = blurAccess && supportsBlur && !areBackgroundsDisabled;
	const epicBackgroundsAvailable =
		epicBackgroundsAccess && supportsImageBackgrounds && !areBackgroundsDisabled;
	const brandedBackgroundsAvailable =
		brandedBackgroundAccess && supportsImageBackgrounds && !areBackgroundsDisabled;
	return { blur: blurAvailable, epic: epicBackgroundsAvailable, branded: brandedBackgroundsAvailable };
}
