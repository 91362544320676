/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file hook to update document title
 * @author Matt Panico
 * @module Epic.VideoApp.Hooks.UseDocumentTitle
 */

import { useEffect } from "react";

/**
 * set the document's title when it changes
 *
 * @param title the document title to display
 */
export function useDocumentTitle(title: string): void {
	useEffect(() => {
		document.title = title;
	}, [title]);
}
