/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file utility functions used to brand the page
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.Branding
 */

import {
	ALL_COLOR_PALETTES,
	BrandedColor,
	IBrandedColors,
	Palette,
	PaletteName,
	PALETTE_MAP,
} from "~/types/branding";
import { getHue, getSaturation, isBlack } from "./color";

/** Threshold used to determine when the default (gray-scale) header palette should be used */
const SATURATION_THRESHOLD = 0.2;

/**
 * Get color variables that should be applied based on the organization's branding
 * @param brandedColors map of organizations branded colors
 * @returns map of color variables that should be applied to the page
 */
export function getEVCColorsFromBrandedColors(brandedColors: IBrandedColors): Record<string, string> {
	return { ...getPaletteColors(brandedColors), ...getBrandedColors(brandedColors) };
}

/**
 * Get the set of branded colors that are used and any colors derived from an organization's branding
 * @param brandedColors the organization's branded colors
 * @returns set of colors derived directly from branded colors
 */
function getBrandedColors(brandedColors: IBrandedColors): Record<string, string> {
	const { brandPrimary, brandAccentBack, brandBackground } = brandedColors;

	const colors: Record<string, string> = {};

	if (brandPrimary) {
		colors[BrandedColor.brandPrimary] = brandPrimary;
	}
	if (brandAccentBack) {
		colors[BrandedColor.brandAccentBack] = brandAccentBack;
	}
	if (brandBackground) {
		colors[BrandedColor.brandBackground] = brandBackground;
	}

	return colors;
}

/**
 * Get the palette to used given an organization's branding
 * @param brandedColors organization's branded colors
 * @returns the palette to use
 */
function getPaletteColors(brandedColors: IBrandedColors): Palette | null {
	const { brandBackground, overridePalette } = brandedColors;

	// first check for a valid override palette
	const lowerOverridePalette = overridePalette?.toLowerCase();
	if (lowerOverridePalette && lowerOverridePalette in PALETTE_MAP) {
		return PALETTE_MAP[lowerOverridePalette as PaletteName];
	}

	// if no color was provided or saturation is too low, don't return a palette to use the default
	if (!brandBackground || shouldUseDefaultPalette(brandBackground)) {
		return null;
	}

	// for all color palettes, determine the header with the closest hue to the provided color
	const [firstPalette, ...otherPalettes] = ALL_COLOR_PALETTES;

	const backgroundHue = getHue(brandBackground);
	let closestPaletteInHue = firstPalette;
	let smallestHueDiff = getHueDiffWithPalette(backgroundHue, firstPalette);

	otherPalettes.forEach((palette) => {
		const diff = getHueDiffWithPalette(backgroundHue, palette);

		if (diff < smallestHueDiff) {
			closestPaletteInHue = palette;
			smallestHueDiff = diff;
		}
	});

	return closestPaletteInHue;
}

/**
 * Determine whether or not the default, gray-scale header palette should be used
 * @param color the organization's branded color being used to determine the header palette to use
 * @returns true if the default, gray-scale palette should be used, false otherwise
 */
function shouldUseDefaultPalette(color: string): boolean {
	return isBlack(color) || getSaturation(color) < SATURATION_THRESHOLD;
}

/**
 * Get the difference between a given hue and a color palette's header color
 * @param hue the hue to compare
 * @param palette the color palette whose header color should be compared
 * @returns the angle difference between the provided hue and the palette's header color's hue
 */
function getHueDiffWithPalette(hue: number, palette: Palette): number {
	const headerBackHue = getHue(palette[BrandedColor.headerBack]);
	return Math.abs(calculateAngleDiff(hue, headerBackHue));
}

/**
 * Calculate the difference between two angles
 * @param degA the first angle to compare (in degrees)
 * @param degB the second angle to compare (in degrees)
 * @returns the difference between the angles
 */
function calculateAngleDiff(degA: number, degB: number): number {
	const radA = (degA * Math.PI) / 180;
	const radB = (degB * Math.PI) / 180;

	const minAngle = Math.atan2(Math.sin(radA - radB), Math.cos(radA - radB));

	return (minAngle * 180) / Math.PI;
}
