/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file audio track component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Participants.Audio.AudioOutput
 */

import React, { FC, useEffect, useMemo, useRef } from "react";
import { RemoteAudioTrack } from "twilio-video";
import { useSpeakerState } from "~/state";
import { handlePause } from "~/utils/audio";
import { isMacOS } from "~/utils/os";

/**
 * Props for AudioOutput Component
 */
interface IProps {
	track: RemoteAudioTrack;
	muted: boolean;
}

/**
 * The AudioOutput component
 * @param props The props ;)
 */
const AudioOutput: FC<IProps> = (props) => {
	const { track, muted } = props;
	const selectedSpeakerId = useSpeakerState((selectors) => selectors.getSelectedSpeakerId(), []);
	const macOS = useMemo(() => isMacOS(), []);

	const audioRef = useRef<HTMLAudioElement | null>(null);

	useEffect(() => {
		const audioElement = audioRef.current;
		if (!audioElement) {
			return;
		}
		track.attach(audioElement).addEventListener("pause", handlePause);

		return () => {
			audioElement.removeEventListener("pause", handlePause);
			track.detach(audioElement);
		};
	}, [track]);

	useEffect(() => {
		if (selectedSpeakerId && !macOS) {
			void audioRef.current?.setSinkId?.(selectedSpeakerId);
		}
	}, [macOS, selectedSpeakerId]);

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.muted = muted;
		}
	}, [muted]);

	return <audio id={track.sid || "local-audio"} ref={audioRef} autoPlay muted={muted} />;
};

AudioOutput.displayName = "AudioOutput";

export default AudioOutput;
