/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Generic error page for Video Client
 * @author Will Cooper
 * @module Epic.VideoApp.Features.GenericError.GenericError
 */

import { RouteComponentProps } from "@reach/router";
import React, { FC } from "react";
import ErrorCard from "~/components/Cards/ErrorCard";
import CopyrightLinkButton from "~/components/Copyright/CopyrightLinkButton";
import SimpleHeader from "~/components/Header/SimpleHeader";
import FeatureWrapper from "~/components/StandardLayout/FeatureWrapper";
import { useDocumentTitle, useStrings } from "~/hooks";
import { useErrorPageState } from "~/state";
import { SharedStringTokens } from "~/utils/strings";

/** String tokens used by the GenericError component */
export enum ErrorTokenNames {
	// Generic Errors
	genericHeader = "SomethingWentWrong",
	genericMessage = "RelaunchTheCall",
	standaloneMessage = "RelaunchTheTest",

	// ParticipantRemoved
	participantRemovedTitle = "ParticipantRemovedHeader",
	participantRemovedBody = "ParticipantRemovedBody",
	participantRemovedBodyRejoin = "ParticipantRemovedBodyRejoin",

	// ConcurrentSessionChoiceAlert
	concurrentErrorHeader = "ReturnToOngoingCallHeader",
	concurrentErrorMessage = "ReturnToOngoingCallMessage",

	// TimeoutChoiceAlert
	timeoutHeader = "LaunchCallAgainHeader",
	timeoutMessage = "LaunchCallAgainMessage",

	// useActiveSession
	activeSessionHeader = "ConnectionClosed",
	activeSessionMessage = "ReturnToOtherOpenCall",

	// useJoinRoom
	joinRoomFullHeader = "CallFullHeader",
	joinRoomFullBody = "CallFullBody",

	// useRebuildSessionFromCookies
	refreshFailedBody = "FailedToReconnect",

	// useVideoCallAuthentication
	authTooEarlyHeader = "TooEarlyHeader",
	authTooEarlyBody = "TooEarlyBody",
	authExpiredHeader = "VisitExpiredHeader",
	authExpiredBody = "VisitExpiredBody",
	authFailedToConnect = "FailedToConnect",
	authRemovedHeader = "AuthRemovedHeader",
	authRemovedBody = "AuthRemovedBody",

	// VideoRoom (Room status messages)
	endedTitle = "CallEndedHeader",
	endedBody = "CallEndedBody",
}

/**
 * A generic error component that can render a title and message for each error
 */
const GenericError: FC<RouteComponentProps> = () => {
	const strings = useStrings("GenericError", Object.values(ErrorTokenNames), [
		SharedStringTokens.defaultPageTitle,
	]);

	useDocumentTitle(strings[SharedStringTokens.defaultPageTitle]);

	const errorTitleToken = useErrorPageState((selectors) => selectors.getErrorPageTitle(), []) ?? "";
	const errorMessageToken = useErrorPageState((selectors) => selectors.getErrorPageText(), []) ?? "";

	// always fallback to the generic error if the tokens are invalid. If  the token is an empty string, the lookup will be undefined at runtime.
	const errorTitle = strings[errorTitleToken] ?? strings[ErrorTokenNames.genericHeader];
	const errorMessage = strings[errorMessageToken] ?? strings[ErrorTokenNames.genericMessage];

	return (
		<FeatureWrapper>
			<SimpleHeader />
			<main>
				<ErrorCard titleText={errorTitle} bodyText={errorMessage} />
				<CopyrightLinkButton />
			</main>
		</FeatureWrapper>
	);
};

GenericError.displayName = "GenericError";

export default GenericError;
