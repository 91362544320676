/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file disabled camera icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.DisabledCamera
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum DisabledCameraIconTestIds {
	self = "DisabledCamera",
}

/**
 * The DisabledCamera component
 * @param props The props ;)
 */
const DisabledCamera: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg
			data-testid={DisabledCameraIconTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
		>
			{title && <title>{title}</title>}
			<rect
				x="-0.23"
				y="14.06"
				width="27.58"
				height="2.3"
				transform="translate(-6.78 14.04) rotate(-45)"
			/>
			<path d="M27.38,9.52l-3.25,3.25H22.5V10.19l-13.95,14H20.88a1.63,1.63,0,0,0,1.62-1.63V19.27h1.63l3.25,3.25H29v-13Z" />
			<path d="M6.25,7.9A1.61,1.61,0,0,0,4.63,9.52v10.7L17,7.9Z" />
		</svg>
	);
});

DisabledCamera.displayName = "DisabledCamera";

export default DisabledCamera;
