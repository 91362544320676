/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Function to determine the layout of the call
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Functions.DetermineCallLayout
 */

import { CallLayout } from "~/types";
import { IRemoteUser, IUser } from "~/web-core/interfaces";

/**
 * Determines the layout of video feeds in the call
 * @param participants list of remote users in the call
 * @param screenShareParticipant the user who is sharing their screen
 * @param localShareExists whether the local user is sharing their screen
 * @returns the layout of video feeds in the call
 */
export function determineCallLayout(
	participants: IRemoteUser[],
	screenShareParticipant: IUser | null,
	localShareExists: boolean,
): CallLayout {
	if (participants.length > 1) {
		return "multi-feed";
	}

	if (screenShareParticipant) {
		return "multi-feed";
	}

	if (participants.length === 1 && localShareExists) {
		return "multi-feed";
	}

	return "two-feeds";
}
