/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Trash can icon
 * @author Spencer Eanes
 * @module Epic.VideoApp.Icons.Trash
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The Trash component
 * @param props The props ;)
 */
const Trash: FC<IconProps> = memo((props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
			<path d="M20,27.63H12a2,2,0,0,1-2-2l-1-14H23l-1,14A2,2,0,0,1,20,27.63Z" />
			<path d="M24,7.63H8a1,1,0,0,0,0,2H24a1,1,0,0,0,0-2Z" />
			<path d="M12.21,9.05,11,8.2,12.8,5.56a2.75,2.75,0,0,1,2.26-1.18H17a2.74,2.74,0,0,1,2.25,1.16L21,8.09,19.79,9,18,6.4a1.27,1.27,0,0,0-1-.52H15.06a1.26,1.26,0,0,0-1,.53Z" />
		</svg>
	);
});

Trash.displayName = "Trash";

export default Trash;
