/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Sad Smiley, used for feedback surveys
 * @author Max Harkins
 * @module Epic.VideoApp.Icons.SadSmiley
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The Sad Smiley component
 * @param props The props ;)
 */
const SadSmiley: FC<IconProps> = memo((props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
			<circle fill="#eb595d" cx="32" cy="32" r="27.98" />
			<circle fill="#3f0400" cx="19.54" cy="28.18" r="4.46" />
			<circle fill="#3f0400" cx="44.46" cy="28.18" r="4.46" />
			<path
				fill="#3f0400"
				d="M26.03,41.52c-.6,0-1.15-.42-1.28-1.03-.15-.71,.3-1.41,1-1.56l13.29-2.88c.71-.15,1.41,.3,1.56,1,.15,.71-.3,1.41-1,1.56l-13.29,2.88c-.09,.02-.19,.03-.28,.03Z"
			/>
		</svg>
	);
});

SadSmiley.displayName = "SadSmiley";

export default SadSmiley;
