/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Exports for local tracks hooks
 * @author Matt Panico
 * @module Epic.VideoApp.Hooks.LocalTracks.Index
 */

export * from "./useAudioTrackActions";
export * from "./useLocalAudioRecovery";
export * from "./useScreenShareTrackActions";
export * from "./useVideoTrackActions";
export * from "./workarounds/useIOSMutedWorkaround";
export * from "./workarounds/useLocalAudioRestartInterval";
export * from "./workarounds/useRestartContextWhenTrackStarts";
