/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook used to get network quality from a participant
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseParticipantNetworkQuality
 */

import { useEffect, useState } from "react";
import { IEVCParticipantNetworkQualityChangedEvent } from "~/web-core/events";
import { IUser } from "~/web-core/interfaces";

/**
 * Get the network quality of a remote or local participant
 * @param participant room participant
 * @returns the participants network quality level or null for no participant
 */
export function useParticipantNetworkQuality(participant?: IUser): number | null {
	const [networkQualityLevel, setNetworkQualityLevel] = useState<number | null>(
		participant?.getNetworkQualityLevel() ?? null,
	);

	useEffect(() => {
		if (!participant) {
			setNetworkQualityLevel(null);
			return;
		}

		setNetworkQualityLevel(participant.getNetworkQualityLevel());

		const handleLevelChange = (args: IEVCParticipantNetworkQualityChangedEvent): void => {
			setNetworkQualityLevel(args.newValue);
		};
		participant.on("networkQualityLevelChanged", handleLevelChange);

		return () => {
			participant.off("networkQualityLevelChanged", handleLevelChange);
		};
	}, [participant]);

	return networkQualityLevel;
}
