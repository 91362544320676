/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Standard settings for connecting to Twilio
 * @author Matt Panico
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.TwilioSettings
 */

import { ImageFit, Pipeline } from "@twilio/video-processors";
import {
	BandwidthProfileOptions,
	ConnectOptions,
	CreateLocalTrackOptions,
	LocalDataTrack,
	LocalTrack,
	VideoBandwidthProfileOptions,
} from "twilio-video";
import { LocalDataTrackOptions } from "twilio-video/tsdef/LocalDataTrackOptions";
import { DebuggingLogLevel } from "~/state/room";
import { ICallRoomInfo } from "~/types";
import { isSafari } from "~/utils/browser";
import { makeResourceLink } from "~/utils/general";
import { iOSDetectedAtVersion, isMobile } from "~/utils/os";

const idealFramerate = { ideal: 24 };

/** 480p standard (4:3) - use values that are divisible by 16 */
const mobileResolutionVideoSize: CreateLocalTrackOptions = {
	width: { ideal: 640 },
	height: { ideal: 480 },
	frameRate: idealFramerate,
};

/** 480p wide (16:9) - use values that are divisible by 16 */
const lowResolutionVideoSize: CreateLocalTrackOptions = {
	width: { ideal: 848 },
	height: { ideal: 480 },
	frameRate: idealFramerate,
};

/** 720p wide - use values that are divisible by 16 */
const traditionalResolutionVideoSize: CreateLocalTrackOptions = {
	width: { ideal: 1280 },
	height: { ideal: 720 },
	frameRate: idealFramerate,
};

/**
 * Get the video track creation options
 *
 * @param lowBandwidth - Whether the current visit or user is configured to use lower bandwidth video
 * @param overrideOptions option overrides
 */
export function getVideoTrackOptions(
	useLowBandwidth: boolean,
	overrideOptions?: CreateLocalTrackOptions,
): CreateLocalTrackOptions {
	const videoSize: CreateLocalTrackOptions = getVideoSize(isMobile(), useLowBandwidth);
	return {
		...videoSize,
		name: `camera-${Date.now()}`,
		...overrideOptions,
	};
}

/**
 * Helper function to resolve which set of track dimensions we should publish at
 *
 * @param mobile - Whether the local user is on a mobile device
 * @param lowBandwidth - Whether the current visit or user is configured to use lower bandwidth video
 * @returns The corresponding video size based upon inputs
 */
function getVideoSize(mobile: boolean, lowBandwidth: boolean): CreateLocalTrackOptions {
	// Ignore low bandwidth configuration on a mobile device
	if (mobile) {
		return mobileResolutionVideoSize;
	} else if (lowBandwidth) {
		return lowResolutionVideoSize;
	} else {
		return traditionalResolutionVideoSize;
	}
}

/**
 * Get default audio track options
 * @param overrideOptions option overrides
 */
export function standardAudioTrackOptions(
	overrideOptions?: CreateLocalTrackOptions,
): CreateLocalTrackOptions {
	return {
		autoGainControl: { ideal: true },
		noiseSuppression: { ideal: true },
		workaroundWebKitBug180748: isSafari() && !iOSDetectedAtVersion("14+"), // in older versions of Safari, getUserMedia returns a silent audio MediaStreamTrack (WebKit Bug 180748)
		echoCancellation: { ideal: true },
		...overrideOptions,
	};
}

/**
 * Get default data track options
 */
function standardDataTrackOptions(): LocalDataTrackOptions {
	// From twilio docs
	// localDataTrack.reliable - This is true if both maxPacketLifeTime and maxRetransmits are set to null.
	// In other words, if this is true, there is no bound on packet lifetime or the number of times the LocalDataTrack will attempt to send data, ensuring "reliable" transmission.
	// Early one we should focus on ensuring reliable transport
	return { maxPacketLifeTime: null, maxRetransmits: null, ordered: true, logLevel: "warn" };
}

/**
 * Get default connection options
 * @param roomInfo Details on the room to be joined
 * @param tracks User's video and audio tracks from the hardware test
 * @param debuggingLogLevel - Determines which level of Twilio logging this user will log to CosmosDB
 * @param lowBandwidth - Whether the current visit or user is configured to use lower bandwidth video
 * @param beginWaiting - Whether or not the user will join the room in a waiting state (no track publications or subscriptions)
 */
export function getConnectionOptions(
	roomInfo: ICallRoomInfo,
	tracks: LocalTrack[],
	debuggingLogLevel: DebuggingLogLevel,
	lowBandwidthMode: boolean,
	beginWaiting: boolean,
): ConnectOptions {
	const dataTrack = new LocalDataTrack(standardDataTrackOptions());
	const localTracks = beginWaiting ? [dataTrack] : [...tracks, dataTrack];
	const connectionOptions: ConnectOptions = {
		automaticSubscription: !beginWaiting,
		name: roomInfo.roomID,
		bandwidthProfile: getBandwidthProfile(lowBandwidthMode),
		// save bandwidth when audio is human speech
		maxAudioBitrate: 16000,
		dominantSpeaker: true,
		preferredVideoCodecs: "auto",
		// these are the defaults- easier to test alternatives when they are present
		preferredAudioCodecs: [{ codec: "opus", dtx: true }],
		tracks: localTracks,
		networkQuality: {
			local: debuggingLogLevel === DebuggingLogLevel.verbose ? 3 : 1,
			remote: debuggingLogLevel === DebuggingLogLevel.verbose ? 3 : 1,
		},
	};

	if (roomInfo.regionID) {
		connectionOptions.region = roomInfo.regionID;
	}

	return connectionOptions;
}

/**
 * Get default bandwidth profile options
 */
function getBandwidthProfile(lowBandwidthMode: boolean): BandwidthProfileOptions {
	const videoBandwidthProfileOptions: VideoBandwidthProfileOptions = {
		mode: "collaboration",
		clientTrackSwitchOffControl: "auto",
		contentPreferencesMode: "auto",
		dominantSpeakerPriority: "standard",
		trackSwitchOffMode: "detected",
	};

	if (isMobile()) {
		videoBandwidthProfileOptions.maxSubscriptionBitrate = 2500000;
	} else if (lowBandwidthMode) {
		videoBandwidthProfileOptions.maxSubscriptionBitrate = 1500000;
	}

	return { video: videoBandwidthProfileOptions };
}

const PROCESSOR_ASSET_PATH = makeResourceLink("Processor", "");

/**
 * Default options for all processors, including shared settings for blur and image processors
 */
export const defaultProcessorOptions = {
	assetsPath: PROCESSOR_ASSET_PATH,
	maskBlurRadius: 5,
	pipeline: Pipeline.Canvas2D,
};

/**
 * Gaussian blur specific processor options
 */
export const defaultGaussianOptions = {
	blurFilterRadius: 20,
	...defaultProcessorOptions,
};

/**
 * Image-specific processor options. Note that these options do not specify the image resource to load
 */
export const defaultImageOptions = {
	fitType: ImageFit.Fill,
	...defaultProcessorOptions,
};
