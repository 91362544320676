/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file utility function related to optional user guardrails
 * @author Trevor Roussel
 * @module Epic.VideoApp.Utils.UserGuardOptions
 */

import { GuardOptionProperties, ISaveUserGuardOptions, IUserGuardOptions } from "~/types/user";

export const defaultGuardOptions: ISaveUserGuardOptions = {
	dismissedScreenShareWarning: null,
	skipHardwareTest: null,
};

export function loadUserGuardOptions(userKey: string): ISaveUserGuardOptions {
	if (userKey === undefined || userKey === "") {
		return defaultGuardOptions;
	}

	const serializedGuardOptions = localStorage.getItem("userGuardOptions_" + userKey);
	if (!serializedGuardOptions) {
		return defaultGuardOptions;
	}

	let newGuardOptions: ISaveUserGuardOptions;
	try {
		newGuardOptions = JSON.parse(serializedGuardOptions) as ISaveUserGuardOptions;
	} catch {
		return defaultGuardOptions;
	}

	if (!newGuardOptions) {
		return defaultGuardOptions;
	}

	return newGuardOptions;
}

// Check if stored dismissal date is older than the dismissal time. If it is, then update it to null
export function clearOldGuardOptions(
	guardOptions: ISaveUserGuardOptions,
	dismissalTime: number,
): ISaveUserGuardOptions {
	const currentTime = new Date();
	//Refresh skipHardwareTest Regardless of refresh build
	if (
		guardOptions.skipHardwareTest !== null &&
		(guardOptions.skipHardwareTest === undefined ||
			getRefreshDate(guardOptions.skipHardwareTest, 1) < currentTime)
	) {
		guardOptions.skipHardwareTest = null;
	}

	// Negative value means never refresh the guard option
	if (dismissalTime < 0) {
		return guardOptions;
	}
	// getRefreshDate returns the date an option should be refreshed given a time of dismissal and the dismissal time
	// if the current time is later than the refresh date, then we set it back to null
	if (
		guardOptions.dismissedScreenShareWarning !== null &&
		(guardOptions.dismissedScreenShareWarning === undefined ||
			getRefreshDate(guardOptions.dismissedScreenShareWarning, dismissalTime) < currentTime)
	) {
		guardOptions.dismissedScreenShareWarning = null;
	}

	return guardOptions;
}

// Return a corresponding IUserGuardOptions with boolean properties given an ISaveUserGuardOptions
export function makeGuardOptionsBooleans(timeOptions: ISaveUserGuardOptions): IUserGuardOptions {
	const guardOptions = {
		dismissedScreenShareWarning: false,
		skipHardwareTest: false,
	};

	if (timeOptions.dismissedScreenShareWarning) {
		guardOptions.dismissedScreenShareWarning = true;
	}

	if (timeOptions.skipHardwareTest) {
		guardOptions.skipHardwareTest = true;
	}

	return guardOptions;
}

// Given a dismissal time, return the date that the guard option should be refreshed
export function getRefreshDate(dismissedTime: string, daysDismissed: number): Date {
	const returnTime = new Date(dismissedTime);
	returnTime.setDate(returnTime.getDate() + daysDismissed);
	return returnTime;
}

export function saveGuardOptions(userKey: string, userGuardOptions: ISaveUserGuardOptions): void {
	if (userKey) {
		localStorage.setItem("userGuardOptions_" + userKey, JSON.stringify(userGuardOptions));
	}
}

// Load old parameters from localStorage and update property indicated by updateProperty
export function saveUpdatedGuardOptions(userKey: string, updateProperty: GuardOptionProperties): void {
	if (!userKey) {
		return;
	}

	const storeOptions = loadUserGuardOptions(userKey);

	const currentTime = new Date();
	let currentTimeString;
	let currentDateString;

	switch (updateProperty) {
		case GuardOptionProperties.dismissedScreenShareWarning:
			currentTimeString = currentTime.toUTCString();
			storeOptions.dismissedScreenShareWarning = currentTimeString;
			break;
		case GuardOptionProperties.skipHardwareTest:
			currentTime.setHours(0, 0, 0, 0);
			currentDateString = currentTime.toUTCString();
			storeOptions.skipHardwareTest = currentDateString;
			break;
		case GuardOptionProperties.stopSkipHardwareTest:
			storeOptions.skipHardwareTest = null;
			break;
	}

	localStorage.setItem("userGuardOptions_" + userKey, JSON.stringify(storeOptions));
}

// IUserGuardOptions interface with all guardrails disabled for hideGuardrails configuration
export const noGuardrailsOptions: IUserGuardOptions = {
	dismissedScreenShareWarning: true,
	skipHardwareTest: true,
};
