/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file base page of the drop down more menu
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Menu.BaseMenu
 */

import React, { FC, useEffect } from "react";
import { useWindowSize } from "~/hooks";
import { useUIState } from "~/state";
import AboutEvcButton from "../Buttons/AboutEvcButton";
import AdmitOptionsButton from "../Buttons/AdmitOptionsButton";
import ChangeBackgroundButton from "../Buttons/ChangeBackgroundButton";
import ChangeCameraButton from "../Buttons/ChangeCameraButton";
import DisplayModeButton from "../Buttons/DisplayModeButton";
import FullscreenButton from "../Buttons/FullscreenButton";
import LanguageSelectorButton from "../Buttons/LanguageSelectorButton";
import ManageDevicesButton from "../Buttons/ManageDevicesButton";
import ParticipantsButton from "../Buttons/ParticipantsButton";
import PictureInPictureButton from "../Buttons/PictureInPictureButton";
import ScreenShareButton from "../Buttons/ScreenShareButton";
import styles from "../ControlsHeader.module.scss";
import { useHeaderDisplayMode } from "../hooks/useHeaderDisplayMode";
import { baseMenuButtonIds } from "../hooks/useMenuPages";
import { useShowParticipantButtonInHeader } from "../hooks/useShowParticipantButtonInHeader";

// when using style modules CSS class names are modified to remain unique between modules
// export the actual selector that can be used to find the BaseMenu in the DOM
const baseClassName = "moreOptionsMenu";
export const baseMenuSelector = `.${styles[baseClassName]}`;

export const MinWidthForChangeCamInDrawer = 350;
export const MinWidthForChangeCamInHeader = 1000;
export const MinWidthForPiPButtonInHeader = 1000;

/**
 * Props for the BaseMenu component
 */
interface IProps {
	/** Whether or not the base menu is the active menu */
	active: boolean;
}

/**
 * The BaseMenu component
 */
const BaseMenu: FC<IProps> = (props) => {
	const { active } = props;
	const { width } = useWindowSize();
	const [, prevVisibleMenu] = useUIState((selectors) => selectors.getVisibleMenuHistory(), []);
	const menuActionType = useUIState((selectors) => selectors.getMenuActionType(), []);
	const headerDisplayMode = useHeaderDisplayMode();

	// when this page becomes active, if we're backing up from a child menu, focus that menu's button
	useEffect(() => {
		if (!active || !prevVisibleMenu) {
			return;
		}

		const id: string | null = baseMenuButtonIds[prevVisibleMenu];
		if (!id) {
			return;
		}

		let element = document.getElementById(id);
		// focus the button itself for keyboard events, otherwise focus the inner div
		// to maintain tab order, but without showing focus styling
		if (element && menuActionType !== "keyboard") {
			const divs = element.getElementsByTagName("div");
			element = divs.length ? divs[0] : null;
		}
		element?.focus();
	}, [active, prevVisibleMenu, menuActionType]);

	// determine if optionally included buttons should be included
	const showChangeCamera =
		headerDisplayMode === "controls-in-header"
			? width < MinWidthForChangeCamInHeader
			: width < MinWidthForChangeCamInDrawer;
	const showScreenShare = headerDisplayMode !== "controls-in-header";

	// Only show the participants button here if there isn't space to show it in the header bar
	const participantsInHeader = useShowParticipantButtonInHeader();
	const showParticipantButtonInMenu = !participantsInHeader;
	const showPiPButtonInMenu = width < MinWidthForPiPButtonInHeader;

	return (
		<div className={styles[baseClassName]}>
			<ManageDevicesButton />
			<ChangeBackgroundButton />
			{showChangeCamera && <ChangeCameraButton noTooltip />}
			{showScreenShare && <ScreenShareButton noTooltip />}
			<FullscreenButton />
			<AdmitOptionsButton />
			{showParticipantButtonInMenu && <ParticipantsButton noTooltip />}
			{showPiPButtonInMenu && <PictureInPictureButton inHeader />}
			<DisplayModeButton />
			<LanguageSelectorButton context="menu" />
			<AboutEvcButton />
		</div>
	);
};

BaseMenu.displayName = "BaseMenu";

export default React.memo(BaseMenu);
