/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Component To Handle Grouping in Call Video Feeds
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.ParticipantSection
 */

import React, { FC, ReactElement, useContext, useEffect, useState } from "react";
import { useHiddenUsers } from "~/hooks/useHiddenUsers";
import { usePictureInPictureState } from "~/state";
import { resolveClassName } from "~/utils/className";
import { ParticipantDataContext, ParticipantStatusContext, VideoSessionContext } from "~/web-core/components";
import { IRemoteUser } from "~/web-core/interfaces";
import { usePinnedParticipant } from "../VideoCall/hooks/usePinnedParticipant";
import MainParticipant from "./MainParticipant";
import styles from "./ParticipantSection.module.scss";
import VideoStrip from "./VideoStrip";

/**
 * The ParticipantSection component
 */
const ParticipantSection: FC = (): ReactElement | null => {
	// For a vertical display want to wrap the Main Participant and the Strip in one div to allow them to be side by side in flex
	// On smaller screens we want to stack them, so we follow the overlaying flex column designation

	const [, setOrientation] = useState(false);

	// Get the necessary data to determine who should be visible in main speaker view
	const { participants } = useContext(ParticipantDataContext);
	const { dominantSpeaker, screenShareParticipant } = useContext(ParticipantStatusContext);
	const localUser = useContext(VideoSessionContext).session?.localUser ?? null;

	const remoteScreenShareParticipant = screenShareParticipant?.isLocal
		? null
		: (screenShareParticipant as IRemoteUser);
	const pinned = usePinnedParticipant(localUser ? [localUser, ...participants] : participants);
	const pipParticipant = usePictureInPictureState((selectors) => selectors.getPipVideoID(), []);

	const hiddenUsers = useHiddenUsers(
		participants,
		pinned,
		pipParticipant,
		remoteScreenShareParticipant,
		dominantSpeaker,
		localUser,
	);

	useEffect(() => {
		const handleOrientationChange = (): void => {
			setTimeout(() => {
				setOrientation((prevState) => !prevState);
			}, 20);
		};

		window.addEventListener("orientationchange", handleOrientationChange);

		return () => {
			window.removeEventListener("orientationchange", handleOrientationChange);
		};
	}, []);

	const className = resolveClassName(styles, { participantView: true });

	return (
		<div className={className} data-testid="ParticipantSection" id="participantView">
			<MainParticipant />
			<VideoStrip hiddenUsers={hiddenUsers} />
		</div>
	);
};

export default ParticipantSection;
