/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Wrapper for non-main participant videos to be rendered
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.VideoStrip
 */

import React, { FC, useContext, useMemo } from "react";
import { useUIState } from "~/state";
import { resolveClassName } from "~/utils/className";
import { isIOS } from "~/utils/os";
import { ParticipantDataContext, ParticipantStatusContext, VideoSessionContext } from "~/web-core/components";
import { useStream } from "~/web-core/hooks/useStream";
import CallParticipant from "./CallParticipant";
import FloatingParticipant from "./FloatingParticipant";
import MoreParticipantsIndicator from "./MoreParticipantsIndicator";
import styles from "./VideoStrip.module.scss";

interface IProps {
	hiddenUsers: string[];
}

/**
 * VideoStrip Component
 */
const VideoStrip: FC<IProps> = (props: IProps) => {
	const { hiddenUsers } = props;
	const { session } = useContext(VideoSessionContext);
	const localParticipant = session?.localUser;
	const localScreenShareStream = useStream(session?.localUser, "screen");
	const localShareExists = !!localScreenShareStream;

	const pinnedVideo = useUIState((selectors) => selectors.getPinnedVideo(), []);

	const { participants } = useContext(ParticipantDataContext);
	const { mainParticipant, screenShareParticipant, callLayout } = useContext(ParticipantStatusContext);

	const twoParticipantCall = callLayout === "two-feeds";
	const localParticipantIsMain = mainParticipant?.getUserIdentity() === localParticipant?.getUserIdentity();
	const remoteSharedScreenIsMain = useMemo(() => {
		return (
			screenShareParticipant &&
			screenShareParticipant.getUserIdentity() === pinnedVideo?.identity &&
			pinnedVideo?.videoType === "screen"
		);
	}, [pinnedVideo, screenShareParticipant]);

	const localScreenShareIsMain = useMemo(() => {
		return (
			localParticipant &&
			localShareExists &&
			localParticipant.getUserIdentity() === pinnedVideo?.identity &&
			pinnedVideo?.videoType === "screen"
		);
	}, [pinnedVideo, localParticipant, localShareExists]);

	const className = resolveClassName(styles, {
		videoStrip: true,
		twoParticipantCall,
	});

	// in a two-way call, show the preview of the non-main participant instead of a call participant
	return (
		<>
			{twoParticipantCall && localParticipant && (
				<FloatingParticipant
					participant={localParticipantIsMain ? participants[0] : localParticipant}
					isLocal={!localParticipantIsMain}
				/>
			)}
			<div className={className}>
				<MoreParticipantsIndicator hiddenParticipants={hiddenUsers} />
				{participants.map((currentParticipant) => {
					const currentId = currentParticipant.getUserIdentity() ?? "";
					// Don't render any participants that should not be shown
					if (hiddenUsers.includes(currentId)) {
						return null;
					}

					if (
						currentParticipant.getUserIdentity() === mainParticipant?.getUserIdentity() &&
						(currentId !== pinnedVideo?.identity || pinnedVideo.videoType !== "screen")
					) {
						return null;
					}
					return (
						<CallParticipant
							key={currentParticipant.getUserIdentity()}
							participant={currentParticipant}
						/>
					);
				})}
				{/** shared screen only shown when screen isn't main feed */}
				{!remoteSharedScreenIsMain && screenShareParticipant && (
					<CallParticipant participant={screenShareParticipant} screenOnly />
				)}
				{!localScreenShareIsMain && localShareExists && localParticipant && (
					<CallParticipant participant={localParticipant} screenOnly isLocal />
				)}
				{/** some iOS devices can't handle camera output to multiple elements concurrently,
				 * so tear down local preview when localParticipantIsMain on iOS by returning null
				 * rather than flagging hideParticipant. See bugs:
				 * https://bugs.webkit.org/show_bug.cgi?id=240014
				 * repro: https://codepen.io/mpatinods/pen/yLvygRe
				 */}
				{!twoParticipantCall &&
					localParticipant &&
					(isIOS() && localParticipantIsMain ? null : (
						<CallParticipant
							participant={localParticipant}
							hideParticipant={localParticipantIsMain && !localScreenShareIsMain}
							isLocal
						/>
					))}
			</div>
		</>
	);
};

VideoStrip.displayName = "VideoStrip";

export default VideoStrip;
