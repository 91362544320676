/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hardware row to inform the user of specific iOSAudio issues that may occur in the call.
 * @author Will Cooper
 * @module Epic.VideoApp.Components.HardwareSetup.AudioWarningBanner
 */

import React, { FC, useContext } from "react";
import { useStrings } from "~/hooks";
import { DisplayContext } from "~/types";
import { isOnIOSVersion } from "~/utils/os";
import { HardwareSetupDisplayContext } from "./HardwareSetup";
import WarningBanner from "./WarningBanner";

const AudioWarningBanner: FC = () => {
	const displayContext = useContext(HardwareSetupDisplayContext);

	const tokenNames = ["Header", "LobbyText", "HardwareTestText", "AriaLabel"];
	const strings = useStrings("AudioWarningBanner", tokenNames);

	//Determine if we should render the iOS 14.2 warning
	//Only show to users on this version and tell them to upgrade
	const onIOSIssueVersion = isOnIOSVersion("14_2");
	const displayBanner = displayContext !== DisplayContext.devicesTray && onIOSIssueVersion;

	if (!displayBanner) {
		return null;
	}
	const warningHeader = strings["Header"];
	const warningText =
		displayContext === DisplayContext.lobby ? strings["LobbyText"] : strings["HardwareTestText"];
	const ariaLabel = strings["AriaLabel"];

	return (
		<WarningBanner warningTitle={warningHeader} ariaLabel={ariaLabel}>
			<p>{warningText}</p>
		</WarningBanner>
	);
};

AudioWarningBanner.displayName = "AudioWarningBanner";

export default AudioWarningBanner;
