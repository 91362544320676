/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interface for client errors for video vendors
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.VendorError
 */

export class VendorError extends Error {
	vendorErrorCode: string; // error code from the vendor
	sessionErrorCode: SessionErrorCodes; // EVC client error we map it to
	message: string; // error message from the vendor
	updateConnectionStatus?: boolean; // whether to update the connection status
	isJoinRoomError?: boolean; // whether the error occurred when trying to join the room

	constructor(
		vendorErrorCode: string,
		sessionErrorCode: SessionErrorCodes,
		message: string,
		name?: string,
		updateConnectionStatus?: boolean,
		isJoinRoomError?: boolean,
	) {
		super(message);
		this.vendorErrorCode = vendorErrorCode;
		this.sessionErrorCode = sessionErrorCode;
		this.message = message;
		this.name = name ?? "";
		this.updateConnectionStatus = updateConnectionStatus;
		this.isJoinRoomError = isJoinRoomError;
	}
}

/**
 * Error codes used to control certain client behavior, like showing the correct error card.
 */
export enum SessionErrorCodes {
	unknown = 0,
	participantDuplicateIdentityError = 1,
	roomCompletedError = 2,
	roomMaxParticipantsExceededError = 3,
	participantRemovedFromSession = 4,
}

/**
 * Check if the provided vendor error code is a permissions denied error
 */
export function isPermissionsError(vendorErrorCode?: string): boolean {
	if (!vendorErrorCode) {
		return false;
	}
	return vendorErrorCode === "permissions" || vendorErrorCode === "0";
}
