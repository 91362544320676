/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Twilio-based video call interface
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.Implementations.TwilioSession
 */

import {
	LocalTrack,
	LocalTrackPublishOptions,
	RemoteParticipant,
	Room,
	TwilioError,
	connect as twilioConnect,
} from "twilio-video";
import { iOSVerMediaRecycleWorkarounds } from "~/utils/os";
import { makeRequest } from "~/utils/request";
import { EVCEmitter, IEVCSessionEventMap } from "~/web-core/events";
import { SessionErrorCodes, VendorError } from "~/web-core/interfaces";
import { DeviceKind, SessionConnectionStatus, VideoType } from "~/web-core/types";
import { IConnectOptions, IConnectResponseDTO } from "~/web-core/types/connectOptions";
import { ILocalStream } from "../../../interfaces/localStream";
import { ILocalUser } from "../../../interfaces/localUser";
import { IRemoteUser } from "../../../interfaces/remoteUser";
import { ISession } from "../../../interfaces/session";
import { twilioRoomErrorToSessionError } from "../functions/processTwilioError";
import { getConnectionOptions } from "../twilioSettings";
import { TwilioLocalStream } from "./twilioLocalStream";
import { TwilioLocalUser } from "./twilioLocalUser";
import { TwilioRemoteUser } from "./twilioRemoteUser";

export class TwilioSession extends EVCEmitter<IEVCSessionEventMap> implements ISession {
	room?: Room;
	roomGuid?: string;
	localUser: TwilioLocalUser;
	connectionStatus: SessionConnectionStatus;
	participants: TwilioRemoteUser[];
	cleanupFunction?: () => void;

	constructor(user: TwilioLocalUser) {
		super();
		this.connectionStatus = "not-connected";
		this.participants = [];
		this.localUser = user;
	}

	async connect(connectOptions: IConnectOptions): Promise<boolean> {
		const { info, logLevel, isLowBandwidth, isInWaitingRoom, jwt } = connectOptions;

		const localTracks: LocalTrack[] = [];

		const stream = this.localUser?.deviceStream;
		if (stream?.localAudioTrack) {
			localTracks.push(stream.localAudioTrack);
		}

		if (stream?.localVideoTrack) {
			localTracks.push(stream.localVideoTrack);
		}

		const options = getConnectionOptions(info, localTracks, logLevel, isLowBandwidth, isInWaitingRoom);

		const room = await twilioConnect(info.token ?? "", options);
		this.room = room;

		this.roomGuid = room.sid ?? undefined;

		this.participants = Array.from(room.participants.values()).map((t) => new TwilioRemoteUser(t));
		for (const participant of this.participants) {
			this.setupUserEventListener(participant);
		}
		this.connectionStatus = "connected";
		this.cleanupFunction = this.constructEmitterInterface();
		this.localUser.setParticipant(room.localParticipant);
		this.emit("sessionConnected", { type: "sessionConnected", session: this });

		// Handle users that need to be admitted to the visit
		if (isInWaitingRoom && jwt) {
			// We set the default subscribe rule to be none to ensure there isn't a race condition with receiving remote video/audio
			// Now allow data track messages to be subscribed to, in order to ensure we can catch a join call signal
			await allowDataTrackMessages(jwt);
		}

		// On affected iOS versions, a user's audio or video track may be enabled for remote users even though the user has disabled it.
		// To prevent this, we enable and disable the track, if the track is supposed to be disabled.
		if (iOSVerMediaRecycleWorkarounds) {
			this.room?.localParticipant.audioTracks.forEach((publication) => {
				if (!publication.track.isEnabled) {
					publication.track.enable();
					publication.track.disable();
				}
			});
		}

		this.room?.localParticipant.videoTracks.forEach((publication) => {
			// All video tracks are published with 'low' priority to begin
			// The Main Participant's video track will be set to 'high' as the call progresses
			publication.setPriority("low");

			// On affected iOS versions, a user's audio or video track may be enabled for remote users even though the user has disabled it.
			// To prevent this, we enable and disable the track, if the track is supposed to be disabled.
			if (iOSVerMediaRecycleWorkarounds && !publication.track.isEnabled) {
				publication.track.enable();
				publication.track.disable();
			}
		});

		return true;
	}

	disconnect(): void {
		this.cleanupFunction?.();
		if (this.room) {
			this.room = this.room.disconnect();
		}
	}

	refreshMedia(): void {
		this.room?.refreshInactiveMedia();
	}

	async publish(
		kind: DeviceKind,
		expectedState: boolean,
		stream: ILocalStream,
		recycleMedia?: boolean,
		videoType?: VideoType,
	): Promise<boolean> {
		if (this.connectionStatus !== "connected" || !this.room) {
			return Promise.resolve(false);
		}

		if (!(stream instanceof TwilioLocalStream)) {
			throw new Error("Attempted to publish for the wrong vendor!! Expected TwilioDevice");
		}

		const trackToUpdate = kind === "audio" ? stream.localAudioTrack : stream.localVideoTrack;
		const screenShareTrackOptions: LocalTrackPublishOptions = {
			priority: "high",
		};

		if (expectedState && trackToUpdate) {
			const screenShareOptions = videoType === "screen" ? screenShareTrackOptions : {};
			const publication = await this.room.localParticipant.publishTrack(
				trackToUpdate,
				screenShareOptions,
			);
			this.room.localParticipant.emit("publish", publication);
		} else if (!expectedState && trackToUpdate) {
			const publication = this.room.localParticipant.unpublishTrack(trackToUpdate);
			this.room?.localParticipant.emit("unpublish", publication);
		}

		// On affected iOS versions, a user's audio or video track may be enabled for remote users even though the user has disabled it.
		// To prevent this, we enable and disable the track, if the track is supposed to be disabled.
		if (recycleMedia && !stream.isEnabled(kind)) {
			stream.toggleState(kind, true);
			stream.toggleState(kind, false);
		}

		return Promise.resolve(true);
	}

	async getRoomInfo(jwt: string): Promise<IConnectOptions> {
		const info = await makeRequest<IConnectResponseDTO>("/api/VideoCall/GetRoomInfo", "GET", jwt);
		const options: IConnectOptions = {
			logLevel: 0,
			isLowBandwidth: false,
			isInWaitingRoom: false,
			jwt: jwt,
			info: info,
		};
		return options;
	}

	getRemoteParticipants(): IRemoteUser[] {
		if (!this.room) {
			return [];
		}

		return this.participants;
	}

	getRemoteParticipant(identity: string): IRemoteUser | null {
		const participant = this.participants.find((p) => p.getUserIdentity() === identity);
		return participant ?? null;
	}

	getLocalParticipant(): ILocalUser | null {
		return this.localUser ?? null;
	}

	getDominantSpeaker(): IRemoteUser | null {
		const dominantSpeaker = this.room?.dominantSpeaker;

		if (!dominantSpeaker) {
			return null;
		}

		const dominantUser = this.participants.find(
			(participant) => participant.getUserIdentity() === dominantSpeaker.identity,
		);

		return dominantUser ?? null;
	}

	sendDataMessage(message: string): void {
		// Send the message
		// Get the LocalDataTrack that we published to the room.
		if (!this.room) {
			return;
		}

		const [localDataTrackPublication] = [...this.room.localParticipant.dataTracks.values()];
		if (localDataTrackPublication) {
			localDataTrackPublication.track.send(message);
		}
	}

	processError(error: unknown): VendorError | undefined {
		const twilioError = error as TwilioError;
		if (twilioError?.code !== undefined) {
			return new VendorError(
				twilioError.code.toString(),
				twilioRoomErrorToSessionError(twilioError.code),
				twilioError.message,
				twilioError.name,
			);
		}

		return undefined;
	}

	async addScreenShare(stream: ILocalStream): Promise<boolean> {
		const result =
			(await this.publish("video", true, stream, false, "screen")) &&
			(await this.publish("audio", true, stream, false, "screen"));
		return result;
	}

	async removeScreenShare(stream: ILocalStream, hasAudio?: boolean): Promise<boolean> {
		const result = await this.publish("video", false, stream);
		if (hasAudio) {
			return result && this.publish("audio", false, stream);
		}
		return result;
	}

	async enableUserMedia(identity: string, jwt: string): Promise<boolean> {
		const sid = this.participants.find((p) => p.getUserIdentity() === identity)?.getUserGuid();
		if (!sid) {
			return false;
		}

		try {
			await subscribeToRemoteMedia(jwt, identity);
		} catch {
			return false;
		}
		return true;
	}

	/**
	 * Constructs an interface layer to convert vendor-constructed events into shared events as defined by evcEvent
	 */
	private constructEmitterInterface(): () => void {
		/// CALLBACKS ///
		const handleParticipantConnected = (participant: RemoteParticipant): void => {
			const user = new TwilioRemoteUser(participant);
			this.emit("participantConnected", {
				type: "participantConnected",
				participant: user,
			});
			this.setupUserEventListener(user);
			this.participants.push(user);
		};

		const handleParticipantDisconnected = (participant: RemoteParticipant): void => {
			const user = this.participants.find((value) => value.getUserIdentity() === participant.identity);
			if (user) {
				this.emit("participantDisconnected", {
					type: "participantDisconnected",
					participant: user,
				});
				this.cleanupUserEventListener(user);
				user.cleanupFunction?.();
				this.participants = this.participants.filter(
					(value) => value.getUserIdentity() !== user?.getUserIdentity(),
				);
			}
		};

		const handleDominantSpeakerChanged = (dominantSpeaker: RemoteParticipant | null): void => {
			this.emit("dominantSpeakerChanged", {
				type: "dominantSpeakerChanged",
				participant:
					this.participants.find(
						(current) => current.getUserIdentity() === dominantSpeaker?.identity,
					) ?? null,
			});
		};

		const handleRoomDisconnection = (_: Room, error: TwilioError): void => {
			// A null error implies that the user was kicked.
			if (error === null) {
				const vendorError: VendorError = {
					vendorErrorCode: "",
					sessionErrorCode: SessionErrorCodes.participantRemovedFromSession,
					message: "Participant removed from session",
					name: "ParticipantRemoved",
				};
				this.emit("disconnected", { type: "disconnected", error: vendorError });
				return;
			}

			const vendorError: VendorError | undefined = this.processError(error);
			this.emit("disconnected", { type: "disconnected", error: vendorError });
		};

		const handleRoomReconnecting = (error: TwilioError, disconnected?: boolean): void => {
			const vendorError = this.processError(error);
			this.emit("reconnecting", {
				type: "reconnecting",
				error: vendorError,
				wasDisconnected: disconnected,
			});
		};

		/// EVENT LISTENERS ///
		this.room?.on("participantConnected", handleParticipantConnected.bind(this));
		this.room?.on("participantDisconnected", handleParticipantDisconnected.bind(this));
		this.room?.on("dominantSpeakerChanged", handleDominantSpeakerChanged.bind(this));
		this.room?.on("disconnected", handleRoomDisconnection.bind(this));
		this.room?.on("reconnecting", handleRoomReconnecting.bind(this));

		// Returns a function that can be used to clean up all the event listeners
		return (): void => {
			/// CLEANUP ///
			this.room?.off("participantConnected", handleParticipantConnected.bind(this));
			this.room?.off("participantDisconnected", handleParticipantDisconnected.bind(this));
			this.room?.off("dominantSpeakerChanged", handleDominantSpeakerChanged.bind(this));
			this.room?.off("disconnected", handleRoomDisconnection.bind(this));
			this.room?.off("reconnecting", handleRoomReconnecting.bind(this));
		};
	}

	/**
	 * Sets up event listeners for a given remote user
	 */
	private setupUserEventListener(user: IRemoteUser): void {
		user.on("screenShareStarted", (args) => {
			this.emit("screenShareStarted", { type: "screenShareStarted", participant: args.participant });
		});

		user.on("screenShareStopped", (args) => {
			this.emit("screenShareStopped", { type: "screenShareStopped", participant: args.participant });
		});
	}

	/**
	 * Removes event listeners for a given remote user
	 */
	private cleanupUserEventListener(user: IRemoteUser): void {
		user.removeAllListeners("screenShareStarted");
		user.removeAllListeners("screenShareStopped");
	}
}

/**
 * Updates track subscription for the current user to allow them to receive data track messages
 */
async function allowDataTrackMessages(jwt: string): Promise<void> {
	return makeRequest<void>("api/VideoCall/MediaSubscriptions/DataOnly", "POST", jwt);
}

/**
 * Updates track subscription for the remote user to allow them to receive data track messages
 */
async function subscribeToRemoteMedia(jwt: string, identity: string): Promise<void> {
	return makeRequest<void>("api/VideoCall/MediaSubscriptions/EnableAll", "POST", jwt, { identity });
}
