/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Locked Screen Share Icon
 * @author Trevor Roussel
 * @module Epic.VideoApp.Icons.LockedScreenShare
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum LockedScreenShareIconTestIds {
	self = "LockedScreenShare",
}

/**
 * The LockedScreenShare component
 * @param props The props ;)
 */
const LockedScreenShare: FC<IconProps> = memo((props) => {
	return (
		<svg
			data-testid={LockedScreenShareIconTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			width="35.178"
			height="25.619"
			viewBox="0 0 35.178 25.619"
			{...props}
		>
			<g id="Group_13821" data-name="Group 13821" transform="translate(-4626 -4620)">
				<g id="Group_13814" data-name="Group 13814" transform="translate(14 31)">
					<path
						d="M25.52,7.27h-19A1.6,1.6,0,0,0,4.89,8.86V20a1.59,1.59,0,0,0,1.59,1.58h6.35v1.59H9.65v1.59h12.7V23.14H19.17V21.55h6.35A1.59,1.59,0,0,0,27.11,20V8.86a1.6,1.6,0,0,0-1.59-1.59Zm-6,6.16H17.09v5.81a.35.35,0,0,1-.35.35H15.27a.35.35,0,0,1-.35-.35V13.43H12.49a.35.35,0,0,1-.25-.59l3.52-3.51a.33.33,0,0,1,.48,0l3.52,3.51a.35.35,0,0,1-.25.59Z"
						transform="translate(4607.11 4581.73)"
						fill="#848484"
					/>
				</g>
				<g id="Group_13820" data-name="Group 13820" transform="translate(4623.058 4610.938)">
					<path
						data-name="Path 11137"
						d="M35.54,25.43H35A1.47,1.47,0,0,1,33.56,24c0-.61,0-1.38,0-1.94v-.64a2.89,2.89,0,1,0-5.78,0c0,.11,0,.33,0,.64V24a1.47,1.47,0,0,1-1.47,1.46h-.5A1.47,1.47,0,0,1,24.23,24V21.4a6.36,6.36,0,0,1,12.71,0c0,.09,0,.32,0,.6v2A1.47,1.47,0,0,1,35.54,25.43Z"
						fill="#fff"
					/>
					<path
						data-name="Path 11138"
						d="M35.54,24.85a.88.88,0,0,0,.89-.88V21.39a5.78,5.78,0,0,0-11.55,0v2.58a.88.88,0,0,0,.89.88h.5a.89.89,0,0,0,.9-.88V21.39a3.47,3.47,0,0,1,6.94,0v2.58a.88.88,0,0,0,.89.88h.51m0,1.16H35a2,2,0,0,1-2-2V21.43a2.32,2.32,0,0,0-4.63,0v2.58a2.05,2.05,0,0,1-2.05,2h-.5a2.05,2.05,0,0,1-2.05-2c0-.63,0-1.45,0-2v-.59a6.93,6.93,0,1,1,13.86,0v2.59A2,2,0,0,1,35.54,26Z"
						fill="#171718"
					/>
					<rect
						data-name="Rectangle 3690"
						width="13.86"
						height="8.09"
						rx="1.16"
						transform="translate(23.69 26.01)"
						fill="#fff"
					/>
					<rect
						data-name="Rectangle 3691"
						width="15.01"
						height="9.25"
						rx="1.73"
						transform="translate(23.11 25.43)"
						fill="none"
					/>
				</g>
			</g>
		</svg>
	);
});

LockedScreenShare.displayName = "LockedScreenShare";

export default LockedScreenShare;
