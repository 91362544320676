/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Combined auth / room state
 * @author Colin Walters
 * @module Epic.VideoApp.State.Combined.Connection
 */

import { withSharedStates } from "@epic/react-redux-booster";
import * as auth from "../auth";
import * as room from "../room";

/// COMBINED STATE TYPES ///

type CombinedState = [auth.IAuthState, room.IRoomState];

/// COMBINED REDUCERS ///

/**
 * Indicate that the user can connect to their visit and update the JWT, if necessary
 * @param state shared state prior to this action
 * @param newJwt the new JWT to set into state, or "" if the previous JWT should continue to be used
 * @returns the new shared state after this action is applied
 */
function setCanConnect(state: CombinedState, newJwt: string): CombinedState {
	const [prevAuthState, prevRoomState] = state;

	let newAuthState = prevAuthState;
	if (newJwt) {
		newAuthState = auth.setJWT(newAuthState, newJwt);
	}

	const newRoomState = room.setCanConnect(prevRoomState);

	return [newAuthState, newRoomState];
}

/// BUILD IT ///

export const connectionCombinedReducers = {
	setCanConnect: withSharedStates(auth.state, room.state).buildReducer(setCanConnect),
};
