/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file BackgroundGalleryToggleRow
 * @author Liam Liden
 * @module Epic.VideoApp.Components.BackgroundSelector.BackgroundGalleryToggleRow
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useContext, useEffect, useRef } from "react";
import { useStrings } from "~/hooks";
import { useLoadBackgroundProcessors } from "~/hooks/backgroundEffects";
import { alertActions, useUserState } from "~/state";
import { backgroundProcessorsActions, useBackgroundProcessorsState } from "~/state/backgroundProcessors";
import { useCombinedSelectors } from "~/state/combined";
import { DefaultImageNames } from "~/types/backgrounds";
import { getSeasonalImagePath } from "~/utils/backgroundEffects";
import { VideoSessionContext } from "~/web-core/components";
import BackgroundGallery from "./BackgroundGallery";

enum TokenNames {
	toggleRowLabel = "ToggleRowLabel",
	enableBackgroundEffects = "EnableBackgroundEffects",
	blurOnlyLabel = "BlurOnlyLabel",
	failedLoad = "FailedLoad",
}

export enum BackgroundGalleryToggleRowTestIds {
	label = "BackgroundGalleryToggleRowLabel",
}

/**
 * The BackgroundGalleryToggleRow component
 */
const BackgroundGalleryToggleRow: FC = () => {
	const { localDeviceStream = null, session } = useContext(VideoSessionContext);
	const dispatch = useDispatch();

	const localUser = session?.localUser;
	const lastBackgroundProcessor = useUserState((selectors) => selectors.getLastBackgroundProcessor(), []);
	const backgroundMap = useBackgroundProcessorsState(
		(selectors) => selectors.getBackgroundProcessors(),
		[],
	);

	const isBackgroundsSupported = useCombinedSelectors(
		(selectors) => selectors.getIsBackgroundsSupported(),
		[],
	);

	const processorLoadStatus = useBackgroundProcessorsState(
		(selectors) => selectors.getProcessorLoadStatus(),
		[],
	);
	const loadProcessors = useLoadBackgroundProcessors();

	const strings = useStrings("BackgroundGalleryToggleRow", Object.values(TokenNames));
	const stringsRef = useRef(strings);

	// Load processors once we've received backgroundAccess from auth request
	useEffect(() => {
		if (isBackgroundsSupported && processorLoadStatus === "notStarted") {
			loadProcessors();
		}
	}, [loadProcessors, isBackgroundsSupported, processorLoadStatus]);

	// If processor load fails, close gallery and dispatch an alert
	useEffect(() => {
		if (processorLoadStatus === "failed") {
			dispatch(alertActions.postGenericAlert(stringsRef.current[TokenNames.failedLoad]));
		}
	}, [dispatch, processorLoadStatus]);

	// If user used a background effect last call, default to that background
	useEffect(() => {
		const runEffect = async (): Promise<void> => {
			if (
				localUser &&
				lastBackgroundProcessor && // account for null in case of stale user preferences
				lastBackgroundProcessor !== DefaultImageNames.none &&
				localDeviceStream?.isEnabled("video") &&
				processorLoadStatus === "finished"
			) {
				let path = lastBackgroundProcessor;
				// If seasonal background was last used, make sure we are using the up-to-date season
				if (lastBackgroundProcessor.search(/(winter|spring|summer|fall)/) !== -1) {
					path = getSeasonalImagePath();
				}
				const settings = backgroundMap.get(path)?.processor ?? null;
				await localUser.applyVideoBackground(settings, localDeviceStream);
				dispatch(backgroundProcessorsActions.setPublishedBackgroundProcessor(path));
			}
		};

		void runEffect();
	}, [backgroundMap, dispatch, lastBackgroundProcessor, localUser, processorLoadStatus, localDeviceStream]);

	return <BackgroundGallery applyStream={localDeviceStream} scrollGallery />;
};

BackgroundGalleryToggleRow.displayName = "BackgroundGalleryToggleRow";

export default BackgroundGalleryToggleRow;
