/**
 * @copyright Copyright 2022 - 2024 Epic Systems Corporation
 * @file the organization's branded logo
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Branding.BrandedLogo
 */

import React, { FC, useCallback, useState } from "react";
import { useBrandingState } from "~/state";
import styles from "./BrandedLogo.module.scss";

export enum BrandedLogoTestIds {
	self = "BrandedBackground",
}

/**
 * The BrandedLogo component
 */
const BrandedLogo: FC = () => {
	const brandedLogoPath = useBrandingState((sel) => sel.getLogo(), []);
	const [hideLogo, setHideLogo] = useState(false);

	const loadFailed = useCallback(() => setHideLogo(true), []);

	if (!brandedLogoPath || hideLogo) {
		return null;
	}

	return (
		<div className={styles["logoContainer"]}>
			<img
				src={brandedLogoPath}
				className={styles["logo"]}
				onError={loadFailed}
				aria-hidden
				alt=""
				data-testid={BrandedLogoTestIds.self}
			/>
		</div>
	);
};

BrandedLogo.displayName = "BrandedLogo";

export default BrandedLogo;
