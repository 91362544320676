/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Picture in Picture utilities
 * @author Arun Vasireddy
 * @module Epic.VideoApp.Utils.PictureInPicture
 */

import { isSafari } from "./browser";
import { secondsToMs } from "./dateTime";
import { warn } from "./logging";
import { isIOS, isMobile } from "./os";
import { delay } from "./timer";

const VIDEO_PLAY_BUFFER_SECONDS = 1;

/* eslint-disable compat/compat */

/**
 * Determine if the picture in picture feature is supported
 * @returns true if browser supports PiP feature and if user is not on mobile device or desktop safari
 */
export function isPictureInPictureSupported(): boolean {
	const isMacSafari = !isIOS() && isSafari(); // PiP is disabled in desktop safari. See QAN 7195941.
	return !isMobile() && !isMacSafari && document.pictureInPictureEnabled;
}

/**
 * Checks if a video element is playing in the picture-in-picture window
 */
export function isPiPWindowOpen(): boolean {
	return document.pictureInPictureElement != null;
}

/**
 * Checks if the given video element is being played in the PiP window
 */
export function isElementInPiP(videoEl: HTMLVideoElement | null): boolean {
	if (videoEl === null) {
		return false;
	}
	return document.pictureInPictureElement === videoEl;
}

/**
 * Close the PiP window if it's up
 */
export async function exitPictureInPictureMode(): Promise<void> {
	if (isPiPWindowOpen()) {
		await document.exitPictureInPicture().catch((error) => {
			warn("Failed to leave Picture in Picture mode", error);
		});
	}
}

/**
 * Moves the given video element to the PiP window
 */
export async function enterPictureInPictureMode(videoEl?: HTMLVideoElement | null): Promise<boolean> {
	if (!videoEl) {
		return false;
	}

	try {
		if (shouldBufferElement(videoEl)) {
			const wasBufferingSuccessful = await bufferPictureInPictureAction(videoEl);
			if (wasBufferingSuccessful) {
				return true;
			}
		}

		const result = await videoEl.requestPictureInPicture();
		return !!result;
	} catch (error) {
		const pipError = error as Error;
		warn("Failed to enter Picture in Picture mode", pipError);
		return false;
	}
}

/**
 * Delays the video element from entering picture in picture until it is ready to play
 * @param element video element to buffer
 * @returns true if the video element is successfully entered into pip, false otherwise
 */
async function bufferPictureInPictureAction(element: HTMLVideoElement): Promise<boolean> {
	element.onplay = async () => {
		element.onplay = null;
		const result = await element.requestPictureInPicture();
		return !!result;
	};
	await delay(secondsToMs(VIDEO_PLAY_BUFFER_SECONDS));
	element.onplay = null;
	return false;
}

/**
 * Helper function to determine if the video element that we are about to PiP is ready to play
 * @param element The video element
 * @returns true if the element is not ready to play, false otherwise
 */
function shouldBufferElement(element: HTMLVideoElement): boolean {
	const activeTrack = getMediaStreamTrackFromElement(element);

	return activeTrack?.readyState !== "live";
}

/**
 * Helper function to determine the underlying media stream track from a video element (with video type information)
 * @param element The video element
 * @returns The media stream track if it exists, null otherwise
 */
function getMediaStreamTrackFromElement(element: HTMLVideoElement): MediaStreamTrack | null {
	return (element.srcObject as MediaStream)?.getVideoTracks()[0];
}
