/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file hook to get Remote User list from Session
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Hooks.UseRemoteUsers
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useEffect, useState } from "react";
import { roomActions } from "~/state/room";
import { IEVCParticipantConnectionEvent, IEVCRemoteParticipantUpdatedEvent } from "~/web-core/events";
import { IRemoteUser, ISession } from "~/web-core/interfaces";

/**
 * A hook to serve as the source of truth on all participants connected to the current video session
 *
 * @param session - The core session to use as a source of truth on participants and their connections/disconnections
 * @returns - The list of participants
 */
export function useRemoteUsers(session: ISession): IRemoteUser[] {
	const [participants, setParticipants] = useState(Array.from(session.getRemoteParticipants()));
	const dispatch = useDispatch();

	useEffect(() => {
		const participantConnected = (args: IEVCParticipantConnectionEvent<"participantConnected">): void => {
			const { participant } = args;
			if (participant.getUserIdentity() !== session.localUser.getUserIdentity()) {
				dispatch(roomActions.clearParticipantInfo(participant.getUserIdentity()));
				setParticipants((prevParticipants) => [participant, ...prevParticipants]);
			}
		};
		const participantDisconnected = (
			args: IEVCParticipantConnectionEvent<"participantDisconnected">,
		): void => {
			const { participant } = args;
			setParticipants((prevParticipants) =>
				prevParticipants.filter((p) => p.getUserIdentity() !== participant.getUserIdentity()),
			);
		};
		const participantUpdated = (args: IEVCRemoteParticipantUpdatedEvent): void => {
			const { participant } = args;
			setParticipants((prevParticipants) => [
				...prevParticipants.filter((p) => p.getUserIdentity() !== participant.getUserIdentity()),
				participant,
			]);
		};

		// First setup event listeners to ensure that we don't miss any participant connections
		// If we first read values from the room there is potential for a race condition that will have a user connected to the room but not represented in state
		session.on("participantConnected", participantConnected);
		session.on("participantDisconnected", participantDisconnected);
		session.on("remoteParticipantUpdated", participantUpdated);

		setParticipants(Array.from(session.getRemoteParticipants()));

		return () => {
			session.off("participantConnected", participantConnected);
			session.off("participantDisconnected", participantDisconnected);
			session.off("remoteParticipantUpdated", participantUpdated);
		};
		// The room and dispatch dependencies are referentially stable, so this effect "should" only run once.
		// Be careful about adding additional dependencies here since that could cause setParticipants to be run excessively
	}, [dispatch, session]);

	return participants;
}
