/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file utility function to hash the user ID and return a number for color assignment
 * @author Noah Allen
 * @module Epic.VideoApp.Utils.UserIdHash
 */

export function userIdHash(userId: string): number {
	return Math.abs(userId.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0));
}
