/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Status icons for the standalone hardware test
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareSetup.HardwareSetupStatusIcon
 */

import React, { ComponentType, FC, PropsWithChildren } from "react";
import { useStrings } from "~/hooks";
import { IconProps } from "~/icons";
import Error from "~/icons/error";
import Success from "~/icons/success";
import Warning from "~/icons/warning";
import { DeviceStatus } from "~/types";
import { resolveClassName } from "~/utils/className";
import styles from "./HardwareSetupRow.module.scss";

/**
 * Props for HardwareSetupRow Component
 */
interface IProps {
	icon: ComponentType<IconProps>;
	status?: DeviceStatus;
}

interface IStatusIconAndClass {
	icon?: ComponentType<IconProps>;
	className: string;
	title?: string;
}

enum TokenNames {
	success = "Success",
	warning = "Warning",
	error = "Error",
}

/**
 * Get a hardware status' corresponding icon
 * @param status The hardware status for the current row
 * @returns the correct status icon for the passed in hardware status
 */
function getIconForStatus(
	strings: Record<string, string>,
	status: DeviceStatus | undefined,
): IStatusIconAndClass {
	let icon: ComponentType<IconProps> | undefined = undefined;
	const classes: Record<string, boolean> = { statusIcon: true };
	let title: string | undefined = undefined;

	switch (status) {
		case DeviceStatus.testing:
			icon = undefined;
			break;
		case DeviceStatus.success:
			icon = Success;
			classes["success"] = true;
			title = strings[TokenNames.success];
			break;
		case DeviceStatus.warning:
			icon = Warning;
			classes["warning"] = true;
			title = strings[TokenNames.warning];
			break;
		case DeviceStatus.error:
		case DeviceStatus.unknown:
		default:
			icon = Error;
			classes["error"] = true;
			title = strings[TokenNames.error];
			break;
	}

	return { icon, className: resolveClassName(styles, classes), title };
}

/**
 * The HardwareSetupStatusIcon component
 * @param _props The props ;)
 */
const HardwareSetupStatusIcon: FC<IProps> = (props: PropsWithChildren<IProps>) => {
	const { icon: Icon, status } = props;

	const strings = useStrings("HardwareSetupRow", Object.values(TokenNames));

	const {
		icon: StatusIcon,
		className: statusIconClassName,
		title: iconTitle,
	} = getIconForStatus(strings, status);
	const rowIconClassName = resolveClassName(styles, { hardwareRowIcon: true });

	return (
		<div className={styles["StatusIconContainer"]}>
			{StatusIcon && (
				<StatusIcon
					className={statusIconClassName}
					height={16}
					width={16}
					title={iconTitle}
					role="img"
					aria-label={iconTitle}
				/>
			)}
			<Icon className={rowIconClassName} height={32} width={32} title={iconTitle} aria-hidden />
		</div>
	);
};

HardwareSetupStatusIcon.displayName = "HardwareSetupStatusIcon";

export default HardwareSetupStatusIcon;
