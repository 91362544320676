/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file the MenuContext Context
 * @author Max Harkins
 * @module Epic.VideoApp.Components.Utilities.MenuContext
 */

import React, { FC } from "react";
import { IMenuContext } from "../../types/layout";

export const MenuContext = React.createContext<IMenuContext>({ isInMenu: false });

/**
 * Props for MenuContextProvider Component
 */
interface IProps {
	isInMenu: boolean;
}

/**
 * Context to determine whether a component is within a menu
 * @param props the props ;)
 */
export const MenuContextProvider: FC<IProps> = (props) => {
	const { isInMenu, children } = props;

	return <MenuContext.Provider value={{ isInMenu: isInMenu }}>{children}</MenuContext.Provider>;
};
MenuContextProvider.displayName = "MenuContextProvider";
