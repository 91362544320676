/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Local User for Twilio Sessions
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.Implementations.TwilioLocalUser
 */

import { LocalParticipant, LocalTrack, NetworkQualityLevel, createLocalVideoTrack } from "twilio-video";
import { makeLocalAudioTrackFromStream, makeLocalVideoTrackFromStream } from "~/utils/screenSharing";
import { EVCEmitter, IEVCUserEventMap } from "~/web-core/events";
import { ILocalUser } from "~/web-core/interfaces";
import { BackgroundSettings } from "../../../../types/backgrounds";
import { ILocalStream } from "../../../interfaces/localStream";
import { TwilioBackgroundManager } from "../helpers/twilioBackgroundManager";
import { getVideoTrackOptions } from "../twilioSettings";
import { TwilioLocalStream } from "./twilioLocalStream";

export class TwilioLocalUser extends EVCEmitter<IEVCUserEventMap> implements ILocalUser {
	deviceStream: TwilioLocalStream;
	shareStream: TwilioLocalStream | null;
	participant?: LocalParticipant;
	isLocal: true = true;
	cleanupFunction?: () => void;

	private _backgroundManager: TwilioBackgroundManager;

	constructor(stream: TwilioLocalStream) {
		super();
		this.deviceStream = stream;
		this.shareStream = null;
		this._backgroundManager = new TwilioBackgroundManager();
	}

	getUserIdentity(): string {
		return this.participant?.identity || "";
	}

	getUserGuid(): string {
		return this.participant?.sid || "";
	}

	async createDevicePreviewStream(disabledCameraId: string | null): Promise<ILocalStream> {
		let deviceId;
		if (this.deviceStream.isEnabled("video") || !disabledCameraId) {
			deviceId = this.deviceStream.getDeviceId("video");
		} else {
			deviceId = disabledCameraId;
		}
		const options = getVideoTrackOptions(false);
		const videoTrack = await createLocalVideoTrack({ deviceId: deviceId, ...options });
		const stream = new TwilioLocalStream([videoTrack]);
		return stream;
	}

	initializeVirtualBackgrounds(
		backgroundSettings: BackgroundSettings[] | undefined,
		availableBackgroundsCount: number | undefined,
	): Promise<void> {
		this._backgroundManager.once("backgroundProcessorsDone", (args) => {
			this.emit("backgroundProcessorsDone", args);
		});
		if (!backgroundSettings || !availableBackgroundsCount) {
			throw new Error("Must initialize twilio backgrounds with all arguments");
		}
		void this._backgroundManager.initializeBackgroundProcessors(
			backgroundSettings,
			availableBackgroundsCount,
		);
		return Promise.resolve();
	}

	async applyVideoBackground(
		settings: BackgroundSettings | null,
		stream: ILocalStream | null,
	): Promise<void> {
		// Only apply a background when the stream is defined
		// We still call into this method when the stream is null to make sure internal state is updated on other vendors
		if (!stream) {
			return;
		}
		if (!(stream instanceof TwilioLocalStream)) {
			throw new Error("TwilioLocalUser.applyVideoBackground: stream must be a TwilioLocalStream");
		}

		const processor = settings
			? this._backgroundManager.backgroundProcessorMap.get(settings.path) ?? null
			: null;
		stream.applyVideoBackground(processor);
		return Promise.resolve();
	}

	async createShareStream(
		videoStream: MediaStreamTrack,
		audioStream: MediaStreamTrack,
	): Promise<TwilioLocalStream> {
		// Construct twilio-type tracks out of the given MediaStreamTrack for more reusability with other APIs
		const videoTrack = makeLocalVideoTrackFromStream(videoStream);
		const audioTrack = makeLocalAudioTrackFromStream(audioStream);

		const localTracks: LocalTrack[] = [videoTrack];
		if (audioTrack) {
			localTracks.push(audioTrack);
		}

		this.shareStream = new TwilioLocalStream(localTracks);
		this.emit("participantUpdated", {
			type: "participantUpdated",
			participant: this,
			videoType: "screen",
		});
		return Promise.resolve(this.shareStream);
	}

	cleanupShareStream(): void {
		void this.shareStream?.cleanUp();
		this.shareStream = null;
		this.emit("participantUpdated", {
			type: "participantUpdated",
			participant: this,
			videoType: "screen",
		});
	}

	isSharingScreen(): boolean {
		if (!this.participant) {
			return false;
		}
		return this.shareStream !== null;
	}

	getNetworkQualityLevel(): number {
		return this.participant?.networkQualityLevel ?? -1;
	}

	/**
	 * Sets the local participant and constructs the emitter interface
	 * @param input The local participant to set
	 */
	setParticipant(input: LocalParticipant): void {
		this.participant = input;
		this.cleanupFunction = this.constructEmitterInterface();
	}

	// Not needed for Twilio
	setAudioOutput(_speaker: MediaDeviceInfo): void {
		return;
	}

	/**
	 * Constructs an interface layer to convert vendor-constructed events into shared events as defined by evcEvent
	 */
	private constructEmitterInterface(): () => void {
		this.participant?.on("networkQualityLevelChanged", (networkQualityLevel: NetworkQualityLevel) => {
			this.emit("networkQualityLevelChanged", {
				type: "networkQualityLevelChanged",
				newValue: networkQualityLevel,
			});
		});

		return () => {
			this.participant?.removeAllListeners("networkQualityLevelChanged");
		};
	}
}
