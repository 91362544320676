/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Context for more dynamic participant status, like the pinned participant and active speaker. May update frequently.
 *       Consuming components should be be used thoughtfully to avoid unnecessary rerenders.
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Components.ParticipantStatusProvider
 */

import React, { useContext } from "react";
import { useDominantSpeaker } from "~/components/VideoCall/hooks/useDominantSpeaker";
import { usePinnedParticipant } from "~/components/VideoCall/hooks/usePinnedParticipant";
import { useScreenShareParticipant } from "~/components/VideoCall/hooks/useScreenShareParticipant";
import { CallLayout } from "~/types";
import { determineCallLayout } from "../functions";
import { useMainParticipant } from "../hooks/useMainParticipant";
import { useStream } from "../hooks/useStream";
import { IRemoteUser, ISession, IUser } from "../interfaces";
import { ParticipantDataContext } from "./ParticipantDataProvider";

export interface IParticipantStatusContext {
	mainParticipant: IUser | null;
	dominantSpeaker: IRemoteUser | null;
	screenShareParticipant: IUser | null;
	callLayout: CallLayout;
}

export const ParticipantStatusContext = React.createContext<IParticipantStatusContext>({
	mainParticipant: null,
	dominantSpeaker: null,
	screenShareParticipant: null,
	callLayout: "two-feeds",
});

interface IProps {
	session: ISession;
}

export const ParticipantStatusProvider: React.FC<IProps> = (props) => {
	const { session } = props;
	const { participants } = useContext(ParticipantDataContext);

	const dominantSpeaker = useDominantSpeaker(session, participants) ?? null;
	const localUser = session.getLocalParticipant();
	const pinnedParticipant = usePinnedParticipant(localUser ? [localUser, ...participants] : participants);

	const mainParticipant = useMainParticipant(participants, pinnedParticipant, dominantSpeaker);
	const screenShareParticipant = useScreenShareParticipant(session);
	const localShareExists = !!useStream(localUser ?? undefined, "screen");

	const callLayout = determineCallLayout(participants, screenShareParticipant, localShareExists);

	return (
		<ParticipantStatusContext.Provider
			value={{ mainParticipant, dominantSpeaker, screenShareParticipant, callLayout }}
		>
			{props.children}
		</ParticipantStatusContext.Provider>
	);
};
