/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file initializeState function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\combine-reducers\initialize-state
 */

import { IWholeState, ISliceInfoCollection, ISliceKeyCollection } from "../types";

/**
 * Create a new version of the state by running the initializer function for each uninitialized slice
 * and return the new state (if anything changed), or the old state (if nothing changed).
 *
 * @param prevState The previous state
 * @param sliceInfoArr The state slices to initialize
 * @returns A new state if anything got initialized/changed, the exact same state if nothing changed.
 */
export function initializeState<TState extends IWholeState>(
	prevState: TState,
	sliceInfoCollection: ISliceInfoCollection,
	sliceKeys: ISliceKeyCollection,
): TState {
	const nextState = {} as any;
	let hasChanged = false;

	Object.keys(sliceInfoCollection).forEach((prop) => {
		const sliceInfo = sliceInfoCollection[prop as any];
		const sliceKey = sliceKeys[sliceInfo.id];
		const prevSlice = prevState[sliceKey];
		if (!prevSlice) {
			nextState[sliceKey] = sliceInfo.initializer();
			hasChanged = true;
		} else {
			nextState[sliceKey] = prevSlice;
		}
	});
	return hasChanged ? nextState : prevState;
}
