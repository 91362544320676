/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file participants icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Participants
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The Participants component
 * @param props The props ;)
 */
const Participants: FC<IconProps> = memo((props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
			<path d="m 4.75,23.5 v -2.9475 c 0,0 0,-3.0525 3,-3.0525 h 1.5 c 0,0 3,0 3,3 v 3 h -1.5 V 28 h -4.5 v -4.5 z" />
			<circle cx="8.5" cy="13.75" r="2.25" />
			<path d="m 12.25,16 v -2.9475 c 0,0 0,-3.0525 3,-3.0525 h 1.5 c 0,0 3,0 3,3 v 3 h -1.5 v 4.5 h -4.5 V 16 Z" />
			<circle cx="16" cy="6.25" r="2.25" />
			<path d="m 19.75,23.5 v -2.9475 c 0,0 0,-3.0525 3,-3.0525 h 1.5 c 0,0 3,0 3,3 v 3 h -1.5 V 28 h -4.5 v -4.5 z" />
			<circle cx="23.5" cy="13.75" r="2.25" />
		</svg>
	);
});

Participants.displayName = "Participants";

export default Participants;
