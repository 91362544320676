/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file hook to toggle local audio track mute/unmute
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseLocalAudioToggle
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useRef } from "react";
import { hardwareTestActions, useHardwareTestState, useLocalTrackState, useUserState } from "~/state";
import { DeviceStatus, DeviceStatusSubtype, ToggleState } from "~/types";
import { boolMatchesToggleState } from "~/utils/general";
import { iOSDetectedAtVersion, iOSVerRenderingWorkarounds } from "~/utils/os";
import { VideoSessionContext } from "~/web-core/components/VideoSessionProvider";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useAudioTrackActions } from "./localTracks";
import useHandleAudioContext from "./useHandleAudioContext";

/**
 * Hook to get the status of if the local audio track is enabled and toggle enable/disable
 * @returns tuple with the current enabled/disabled status and function to toggle that status
 */
export function useLocalAudioToggle(): [boolean, (forceState?: ToggleState) => Promise<void>] {
	const { session, localDeviceStream } = useContext(VideoSessionContext);
	const isEnabled = useIsStreamEnabled("audio", localDeviceStream);

	const micStatus = useHardwareTestState((selectors) => selectors.getMicrophoneStatus(false), []);
	const isAcquiringTracks =
		useLocalTrackState((selectors) => selectors.getLocalTrackAcquisitionStatus(), []) !== "finished";

	const dispatch = useDispatch();
	const tryHandleAudioContext = useHandleAudioContext();

	const isToggling = useRef(false);
	const micLock = useUserState((selectors) => selectors.getMicLock(), []);

	const { restartLocalAudioTrack } = useAudioTrackActions();

	const toggleMicEnabled = useCallback(
		async (forceState?: ToggleState) => {
			/** on iOS 15+ sometimes we still come back from Siri w/disabled mic and no metering or possibly no audio output-
				However in iOS14+ we sometimes come back from backgrounding to not have ASD. This is safe to trigger regardless as
				it will take no action if the AudioContext is running. However iOS15.0-15.1 are bad enough that we force close
			 */
			if (iOSDetectedAtVersion("14+")) {
				await tryHandleAudioContext(iOSVerRenderingWorkarounds);
			}

			if (
				isAcquiringTracks ||
				isToggling.current ||
				boolMatchesToggleState(isEnabled, forceState) ||
				micLock
			) {
				return;
			}

			/** iOS-inspired failsafe to re-acquire a LocalAudioTrack if we've lost it somehow */
			if (forceState === "on" && !isEnabled) {
				isToggling.current = true;
				session?.refreshMedia();
				await restartLocalAudioTrack(true, true).finally(() => (isToggling.current = false));
			}
			// typical expected AudioToggle flow
			else if (isEnabled) {
				localDeviceStream?.toggleState("audio", false);
				if (micStatus === DeviceStatus.testing) {
					dispatch(
						hardwareTestActions.setMicrophoneState({
							status: DeviceStatus.warning,
							errorType: DeviceStatusSubtype.testInterrupted,
						}),
					);
				}
			} else {
				localDeviceStream?.toggleState("audio", true);
			}
		},
		[
			isAcquiringTracks,
			isEnabled,
			micLock,
			tryHandleAudioContext,
			session,
			restartLocalAudioTrack,
			localDeviceStream,
			micStatus,
			dispatch,
		],
	);

	return [isEnabled, toggleMicEnabled];
}
