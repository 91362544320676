/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Implements virtual background initialization and management for Daily
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Helpers.DailyBackgroundManager
 */

import { BackgroundSettings } from "~/types/backgrounds";
import { ImageBackgroundOnLoad, VirtualBackgroundLoader } from "~/web-core/helpers/virtualBackgroundLoader";

export class DailyBackgroundManager extends VirtualBackgroundLoader {
	backgroundImageBytesMap: Map<string, ArrayBuffer>;

	constructor() {
		super();
		this.backgroundImageBytesMap = new Map<string, ArrayBuffer>();
	}

	private imageProcessorInitialize: ImageBackgroundOnLoad = async (
		background: BackgroundSettings,
		_img: HTMLImageElement,
	): Promise<boolean> => {
		if (background.type !== "image") {
			return false;
		}
		try {
			const backgroundImageBytes = await fetch(background.src).then((response) =>
				response.arrayBuffer(),
			);
			this.backgroundImageBytesMap.set(background.path, backgroundImageBytes);
			return true;
		} catch (error) {
			return false;
		}
	};

	initializeBackgroundProcessors(
		backgrounds: BackgroundSettings[],
		availableBackgroundsCount: number,
	): Promise<void> {
		return this.validateProcessors(
			backgrounds,
			availableBackgroundsCount,
			undefined,
			this.imageProcessorInitialize,
		);
	}
}
