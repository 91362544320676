/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interface for a local user's media
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.LocalStream
 */

import { IDeviceInitialization } from "~/state/hardwareTest";
import { DeviceKind, ISwitchDeviceResponse } from "../types";
import { IStream } from "./stream";

/**
 * Interface for mocking a local user's audio/video and the underlying types that represent their devices.
 */
export interface ILocalStream extends IStream {
	readonly isLocal: true;

	/**
	 * Get the device id for the active camera or microphone
	 * @param kind Whether to get the camera or microphone device id
	 * @returns Specified device id
	 */
	getDeviceId: (kind: DeviceKind) => string;

	/**
	 * Get the device name for the active camera or microphone
	 * @param kind Whether to get the camera or microphone device name
	 * @returns Specified device name
	 */
	getDeviceName: (kind: DeviceKind) => string;

	/**
	 * Given a new device, switch the active camera
	 * @param device the new camera
	 * @param useLowBandwidth Configuration flag to use lower resolution video
	 * @param constraints Additional override constraints for the new video track
	 * @returns A response object indicating the result of the switch
	 */
	switchVideoDeviceAsync: (
		device: MediaDeviceInfo,
		useLowBandwidth?: boolean,
		constraints?: MediaTrackConstraintSet,
	) => Promise<ISwitchDeviceResponse>;

	/**
	 * Disables the local video track and releases the camera
	 * @returns Device id of the disabled camera
	 */
	removeLocalVideoTrack: () => Promise<string | undefined>;

	/**
	 * Given a new device, switch the active microphone
	 * @param device the new microphone
	 * @param constraints Additional override constraints for the new audio track
	 * @returns A response object indicating the result of the switch
	 */
	switchAudioDeviceAsync: (
		device?: MediaDeviceInfo | undefined,
		constraints?: MediaTrackConstraintSet,
	) => Promise<ISwitchDeviceResponse>;

	/**
	 * Stops the local audio track
	 */
	removeLocalAudioTrack: () => void;

	/**
	 * Toggles the enable / disable state of the audio or video track
	 * @param kind Specifies whether to toggle the audio or video track
	 * @param turnOn Specifies whether to force enable or disable the track
	 */
	toggleState: (kind: DeviceKind, turnOn: boolean) => void;

	/**
	 * Get the underlying MediaStreamTrack for the audio or video tracks
	 * @param kind Specifies whether to get the audio or video track
	 * @returns MediaStreamTrack for the stream's audio or video
	 */
	getMediaStreamTrack: (kind: DeviceKind) => MediaStreamTrack | undefined;

	/**
	 * Get the error object from device initialization if it exists
	 * @returns Device initialization error object
	 */
	getDeviceInitializationError: () => IDeviceInitialization | undefined;

	/**
	 * Remove all event listeners and stop all MediaStreamTracks
	 */
	cleanUp: () => Promise<void>;
}
