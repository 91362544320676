/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file hook to load strings for a survey
 * @author Max Harkins
 * @module Epic.VideoApp.Components.Utilities.Survey.Hooks.UseSurveyStrings
 */

import { useMemo } from "react";
import { useStrings } from "~/hooks";
import { IQuestion, IQuestionGroup, isQuestionGroup } from "~/types/survey";

/**
 * Get strings for survey questions
 */
export function useSurveyStrings(questionsOrGroups: (IQuestion | IQuestionGroup)[]): Record<string, string> {
	// Retrieve token names
	const tokenNames = useMemo(() => {
		const tokenNames: string[] = [];
		questionsOrGroups.forEach((questionOrGroup) => {
			if (isQuestionGroup(questionOrGroup)) {
				questionOrGroup.subQuestions.forEach((question: IQuestion) => {
					tokenNames.push(question.name);
				});
			}
			tokenNames.push(questionOrGroup.name);
		});
		return tokenNames;
	}, [questionsOrGroups]);

	//fetch strings for questions/question groups
	return useStrings("Survey", tokenNames);
}
