/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Route Registration for the Disconnection Page
 * @author Will Cooper
 * @module Epic.VideoApp.Features.DisconnectionPage.DisconnectionPage
 */

import { IAddRouteFunction } from "../../app/routes";
import DisconnectionPage from "./DisconnectionPage";
/*
 *
 * IMPORTANT! READ THIS!
 *
 * The contents of this file are loaded and executed during the initial load of the application
 * in the browser even if the route is never actually accessed. DO NOT put any additional code
 * in this file, or import any addition modules as doing so would just add extra bloat to the
 * initial load / startup time for the application.
 *
 * IMPORTANT! READ THIS!
 *
 */

/**
 * Return the URL used to access this page
 */
export function getDisconnectionURL(): string {
	return "/Disconnected";
}

/**
 * Register the routes associated with this page
 *
 * @param addRoute A function used to add a route
 */
export default function register(addRoute: IAddRouteFunction): void {
	addRoute(getDisconnectionURL(), DisconnectionPage);
}
