/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file hook to apply a hotkey to tooltips when appropriate
 * @author Trevor Roussel
 * @module Epic.VideoApp.Hooks.UseGetFormattedHotkeyString
 */

import { useMemo } from "react";
import { isMobile } from "~/utils/os";
import { SharedStringTokens, stringFormat } from "~/utils/strings";
import { capitalizeFirstLetter } from "~/utils/text";
import { useSharedStrings } from "./useStrings";

export function useGetFormattedHotkeyString(baseString: string | undefined, hotkey: string[]): string {
	const shouldAppendHotkey = useMemo(() => {
		return !isMobile();
	}, []);
	const hotkeyString = useMemo(() => getHotkeyString(hotkey), [hotkey]);
	const formattingString = useSharedStrings([SharedStringTokens.formatLabelWithHotkey])[
		SharedStringTokens.formatLabelWithHotkey
	];

	if (!baseString) {
		return "";
	}

	if (!shouldAppendHotkey || hotkey.length === 0) {
		return baseString;
	}

	return stringFormat(formattingString, baseString, hotkeyString);
}

/**
 * Given a string of keys in a hotkey, return a string that can be used in a tooltip
 * @param hotkey String array of keys in a hotkey.
 * @returns Hotkey string formatted like (Ctrl+Shift+A)
 */
function getHotkeyString(hotkey: string[]): string {
	const formattedHotkey = hotkey.map((key) => capitalizeFirstLetter(key));
	const internalString = formattedHotkey.join("+");
	return `(${internalString})`;
}
