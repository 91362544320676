/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Util to obtain strings saved in i18n
 * @author Razi Rais
 * @module Epic.VideoApp.Utils.Strings
 */
import React from "react";
import { I18n } from "./i18n";

export enum SharedStringTokens {
	// pre-auth
	connectingToOrganization = "ConnectingToOrganization",
	defaultPageTitle = "DefaultPageTitle",

	// post-auth
	localParticipantIndicator = "LocalParticipantIndicator",
	localScreenShareIndicator = "LocalScreenShareIndicator",
	screenShareWarning = "ScreenShareWarning",
	systemDefault = "SystemDefault",
	warningIconAriaLabel = "WarningIconAriaLabel",
	formatLabelWithHotkey = "FormatLabelWithHotkey",
}

/**
 * Helper function to get a dictionary of strings for a given namespace
 *
 * @param namespace Namespace of the strings (i.e. the name of the component or "Shared")
 * @param tokenNames The strings that should be loaded for that namespace
 * @param locale The locale to get the strings in
 * @returns A dictionary containing the string values for each token
 */
function getStringsInternal(namespace: string, tokenNames: string[], locale: string): Record<string, string> {
	const strings: Record<string, string> = {};

	tokenNames.forEach((tokenName: string) => {
		const formattedTokenName = `${namespace}_${tokenName}`;
		strings[tokenName] = I18n.getString(formattedTokenName, locale);
	});

	return strings;
}

/**
 * Get a dictionary of translated strings that are shared between components
 *
 * @param tokenNames The shared strings that should be loaded
 * @param locale The locale to get the strings in
 * @returns A dictionary containing the string values for each token
 */
export function getSharedStrings(tokenNames: string[], locale: string): Record<string, string> {
	return getStringsInternal("Shared", tokenNames, locale);
}

/**
 * Get a dictionary of translated strings for a component
 *
 * @param component the component name
 * @param tokenNames A string array of the token names
 * @param sharedTokenNames a string array of token names shared between components
 * @returns A dictionary containing the string values for each token
 */
export function getStrings(
	component: string,
	tokenNames: string[],
	sharedTokenNames: string[] = [],
	locale: string,
): Record<string, string> {
	const componentStrings = getStringsInternal(component, tokenNames, locale);
	const sharedStrings = getSharedStrings(sharedTokenNames, locale);

	return { ...componentStrings, ...sharedStrings };
}

/**
 * Function used to format template strings
 *
 * @param template the template string
 * @param args the strings to be inserted into the placeholder values
 * @returns the formatted string
 */
export function stringFormat(template: string, ...args: (number | string | undefined)[]): string {
	template = `${template}`;
	return template.replace(/{(\d+)}/g, (match: string, number: number): string => {
		const value = args[number];
		return typeof value === "undefined" ? match : typeof value !== "string" ? value.toString() : value;
	});
}

/**
 * Function used to format template strings with JSX content inside the string
 * Returns an array of content, which should be added into jsx via the spread operator
 *   Ex. <div> {...formattedJSX} </div>
 *
 * @param template The template string, with {0}, {1}, ...{9} for the placeholder slots
 * @param args The elements to be inserted in the corresponding placeholder values
 * @returns An array of JSX content (elements, string, and numbers)
 */
export type JSXPiece = JSX.Element | string | number;
export function jsxFormat(template: string, ...args: JSXPiece[]): JSXPiece[] {
	const regex = /{\d+}/g;
	const textSegments = template.split(regex);
	const patternSegments = template.match(regex)?.map((match) => parseInt(match.slice(1, -1), 10)) ?? [];

	const result: JSXPiece[] = [];
	for (let index = 0; index < textSegments.length; index++) {
		result.push(textSegments[index]);
		if (index < textSegments.length - 1) {
			const element = args[patternSegments[index]] ?? index.toString();
			// Wrap the element in a fragment so we can assign it a key
			const fragment = React.createElement(React.Fragment, { key: index }, element);
			result.push(fragment);
		}
	}
	return result;
}
