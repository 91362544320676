/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file hook to send participant count iframe messages
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseParticipantCountIframeMessage
 */

import { useEffect, useMemo, useRef, useState } from "react";
import { useRoomState, useUserState } from "~/state";
import { EpicUserType, IParticipantInfo } from "~/types";
import frameMessager from "~/utils/frameMessager";
import { IEVCParticipantConnectionEvent } from "~/web-core/events";
import { IRemoteUser, ISession } from "~/web-core/interfaces";

const LOCAL_PARTICIPANT_COUNT = 1;

/**
 * Hook that tracks the participant list and emits changes in the room size to an iframe
 */
export function useParticipantCountIframeMessage(session: ISession): void {
	const { participantInfos } = useRoomState(
		(sel) => ({ participantInfos: sel.getAllParticipantInfo() }),
		[],
	);
	const { userType, inWaitingRoom } = useUserState(
		(sel) => ({ userType: sel.getUserType(), inWaitingRoom: sel.getIsUserInWaitingRoom() }),
		[],
	);
	const [disconnectedParticipantIds, setDisconnectedParticipantIds] = useState<string[]>([]);

	const previousCount = useRef(-1);
	const previousProviderCount = useRef(-1);

	// to avoid looping below, preload a dictionary mapping SID to user info
	const participantInfoMap = useMemo(() => {
		const map: Record<string, IParticipantInfo> = {};
		participantInfos.forEach((info) => {
			map[info.identity] = info;
		});
		return map;
	}, [participantInfos]);

	useEffect(() => {
		if (!session) {
			return;
		}

		const participantDisconnected = (
			args: IEVCParticipantConnectionEvent<"participantDisconnected">,
		): void => {
			const user: IRemoteUser = args.participant;
			setDisconnectedParticipantIds((prevParticipants) =>
				prevParticipants.concat(user.getUserIdentity()),
			);
		};

		session.on("participantDisconnected", participantDisconnected);
		return () => {
			session.off("participantDisconnected", participantDisconnected);
		};
	}, [session]);

	useEffect(() => {
		// don't emit participant update messages while in the waiting room
		if (!session || inWaitingRoom) {
			return;
		}

		let participantCount = LOCAL_PARTICIPANT_COUNT;
		let providerCount = userType === EpicUserType.emp ? 1 : 0;

		// This hook doesn't use RoomContext's availableParticipants and uses room.participants because
		// participant info updates also trigger this hook. When participant info is updated this needs
		// the current state of the participant, not an up-to-date list of participants at all times.
		for (const participant of session.getRemoteParticipants().values()) {
			const info: IParticipantInfo | undefined = participantInfoMap[participant.getUserIdentity()];

			// if the participant hasn't sent their info, we can't correctly categorize them
			// return out of the effect to avoid sending participant update iframe messages
			if (!info) {
				return;
			}

			// do not count participants that have left or are in the waiting room
			// the disconnected check is technically redundant, but this effect needs to depend on
			// disconnectedParticipantIds to send updates when participants leave the call
			if (
				disconnectedParticipantIds.some((identity) => info.identity === identity) ||
				info.inWaitingRoom
			) {
				continue;
			}

			participantCount += 1;
			if (info.userType === EpicUserType.emp) {
				providerCount += 1;
			}
		}

		// don't emit participant update messages when both the total and provider count remained the same
		if (participantCount === previousCount.current && providerCount === previousProviderCount.current) {
			return;
		}

		frameMessager.postMessage("Epic.Video.ParticipantUpdate", { participantCount, providerCount });
		previousCount.current = participantCount;
		previousProviderCount.current = providerCount;
	}, [inWaitingRoom, userType, participantInfoMap, session, disconnectedParticipantIds]);
}
