/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file restart the AudioContext when the provided LocalTrack starts (iOS Workaround)
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Hooks.LocalTracks.Workarounds.UseRestartContextWhenTrackStarts
 */

import { useEffect } from "react";
import { LocalAudioTrack, LocalVideoTrack } from "twilio-video";

/** AUDIO: Whenever our localAudioTrack fires a started event, we have a new MediaStreamTrack, and on iOS the sample rate can change
 *  as devices change (i.e. pairing a BT device) restarting AudioContext triggers browser logic to match sample rates
 *	VIDEO: Somehow certain iOS devices fail to tear down WebAudio chains even though no reference can be found via the console
 * 	queryObject(GainNode) or similar command. New chains seem to be created on LocalVideoTrack changes and are connected,
 * 	resulting in perceived echo. Restarting the AudioContext when the LocalVideoTrack changes ensures each graph connects once. */
export const useRestartContextWhenTrackStarts = (
	track: LocalAudioTrack | LocalVideoTrack | null,
	context: AudioContext | null,
): void => {
	useEffect(() => {
		if (!track || !context) {
			return;
		}
		const closeContext = (): void => {
			void context.close();
		};
		track.on("started", closeContext);

		return () => {
			track.off("started", closeContext);
		};
	}, [context, track]);
};
