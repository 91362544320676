/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to return a function that will log the disconnection attempt to the server. Reusable outside of the main disconnect event handlers.
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.UseDisconnect
 */

import { IAction, useDispatch } from "@epic/react-redux-booster";
import { navigate } from "@reach/router";
import { useCallback, useContext } from "react";
import { getEndpointUrl } from "~/app/routes";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";
import { WebSocketContext } from "~/components/WebSocket/WebSocketConnection";
import { combinedActions, roomActions, useAuthState, useRoomState, useUserState, userActions } from "~/state";
import { useBackgroundProcessorsState } from "~/state/backgroundProcessors";
import { feedbackSurveyActions, useFeedbackSurveyState } from "~/state/feedbackSurvey";
import { IAccessTokenUpdate, QueryParameters, disconnectTrackCallbackMs } from "~/types";
import { FeedbackPageStep } from "~/types/survey";
import { secondsToMs } from "~/utils/dateTime";
import frameMessager from "~/utils/frameMessager";
import { expireSessionCookies } from "~/utils/jwt";
import { warn } from "~/utils/logging";
import { exitPictureInPictureMode } from "~/utils/pictureInPicture";
import { makeRequest } from "~/utils/request";
import { VideoSessionContext } from "~/web-core/components";
import { useCaseInsensitiveSearchParam } from ".";
import { useAudioTrackActions, useScreenShareTrackActions, useVideoTrackActions } from "./localTracks";

interface IDisconnect {
	(goToErrorPage: boolean, preventReconnect?: boolean): void;
}

export function useDisconnect(): IDisconnect {
	const JWT: string | null = useAuthState((selectors) => selectors.getJWT(), []);
	const { session } = useContext(VideoSessionContext);
	const isDisconnecting = useRoomState((selectors) => selectors.getIsDisconnecting(), []);
	const refreshTimer = useAuthState((selectors) => selectors.getRefreshTokenTimer(), []);
	const feedbackStep = useFeedbackSurveyState((selectors) => selectors.getFeedbackStep(), []);
	const backgroundProcessor = useBackgroundProcessorsState(
		(selectors) => selectors.getPublishedBackgroundProcessor(),
		[],
	);
	const userPreferences = useUserState((selectors) => selectors.getPreferences(), []);
	const clientLoggingInterval = useRoomState((selectors) => selectors.getClientLoggingInterval(), []);
	const sessionID = useCaseInsensitiveSearchParam(QueryParameters.sessionId) ?? "";
	const dispatch = useDispatch();
	const { removeLocalAudioTrack } = useAudioTrackActions();
	const { removeLocalVideoTrack } = useVideoTrackActions();
	const { removeScreenShareTrack } = useScreenShareTrackActions();
	const { audioContext } = useContext(DeviceContext);
	const { closeSocket } = useContext(WebSocketContext);

	const disconnect = useCallback(
		(goToErrorPage: boolean, preventReconnect = false) => {
			if (isDisconnecting) {
				return;
			}

			// Save preferences before disconnecting
			userPreferences.lastBackgroundProcessor = backgroundProcessor;
			dispatch(userActions.setPreferences(userPreferences));

			// clear shared state when disconnecting
			dispatch(combinedActions.clearStateForDisconnect());

			// Clean-up audio and video tracks before exiting
			// The underlying remove hooks will update the room when tracks are unpublished
			removeLocalAudioTrack();
			removeLocalVideoTrack();
			void removeScreenShareTrack();
			closeSocket();

			// Exit Picture in Picture before disconnecting
			void exitPictureInPictureMode();

			// it's possible tracks aren't acquired yet, (e.g. queued calls to autoselect)
			// so re-remove in three second in case we get any between now and then
			setTimeout(removeLocalVideoTrack, disconnectTrackCallbackMs);
			setTimeout(removeLocalAudioTrack, disconnectTrackCallbackMs);
			if (audioContext?.state !== "closed") {
				void audioContext?.close();
			}

			// Navigate
			if (goToErrorPage) {
				void navigate(getEndpointUrl("/Error"));
			} else {
				void navigate(getEndpointUrl("/Disconnected"));
			}

			// Remove session specific cookies
			expireSessionCookies(sessionID, preventReconnect, !!refreshTimer);

			// Disconnect from the current room and notify any host pages
			if (session) {
				session.disconnect();
				frameMessager.postMessage("Epic.Video.Disconnected");
			}

			// log the disconnection
			if (JWT) {
				void logDisconnection(JWT, preventReconnect)
					.then((result) =>
						afterDisconnectionResponse(result, dispatch, goToErrorPage, feedbackStep),
					)
					.catch((error: Error) => {
						warn("Did not receive JWT for post-disconnect actions", error);
					});
			}

			// Clear the refresh token timer
			if (refreshTimer) {
				clearTimeout(refreshTimer);
			}

			if (clientLoggingInterval > 0) {
				dispatch(roomActions.setClientLoggingInterval(0));
			}
		},
		[
			isDisconnecting,
			userPreferences,
			backgroundProcessor,
			dispatch,
			removeLocalAudioTrack,
			removeLocalVideoTrack,
			removeScreenShareTrack,
			closeSocket,
			audioContext,
			sessionID,
			refreshTimer,
			session,
			JWT,
			clientLoggingInterval,
			feedbackStep,
		],
	);

	return disconnect;
}

/**
 * Processes the response from the disconnection endpoint, and updates the authentication state if a new JWT is returned.
 * @param response Response from the web server with new authentication state.
 * @param dispatch The dispatch function used to change redux state
 * @param goToErrorPage Boolean flag whether we are navigating to the error page after disconnecting.
 * @param feedbackStep What the status is of the user feedback survey at the time when the disconnection was logged.
 */
async function afterDisconnectionResponse(
	response: Partial<IAccessTokenUpdate> | null,
	dispatch: <T extends IAction>(action: T) => T,
	goToErrorPage: boolean,
	feedbackStep: FeedbackPageStep,
): Promise<void> {
	if (!response) {
		return;
	}
	// Update the JWT with the new session to allow for submitting survey responses. The new session will not allow communicating via IC, only saving data to CosmosDB
	const { jwt: newJWT, expirationSeconds } = response;

	if (newJWT && expirationSeconds && !goToErrorPage) {
		// Store JWT directly to feedback survey state. We do not store JWT as session cookies, so it will not persist after a refresh.
		dispatch(feedbackSurveyActions.setFeedbackJWT(newJWT));

		// Give a 10 second buffer between the client survey expiration time and the expiration time of the JWT, so
		// we can send information to the server "on expiration"
		const surveyEndTimestamp = Date.now() + secondsToMs(expirationSeconds) - secondsToMs(10);
		dispatch(feedbackSurveyActions.setSurveyTimeoutTimestamp(surveyEndTimestamp));

		// Log that the survey was seen if the intro step was shown after disconnecting
		if (feedbackStep === FeedbackPageStep.intro) {
			try {
				const response = await logSurveyWasShown(newJWT);

				if (response.jwt) {
					// Store JWT directly to feedback survey state. We do not store JWT as session cookies, so it will not persist after a refresh.
					dispatch(feedbackSurveyActions.setFeedbackJWT(response.jwt));
				}
			} catch (error) {
				warn("Did not save survey was shown", error);
			}
		}
	}
}

/**
 * Public access point to private methods. Should only be called in unit tests.
 */
export const localMethodsForTesting = { afterDisconnectionResponse };

/**
 * Marks the user as disconnected on the server
 */
async function logDisconnection(jwt: string, preventReconnect: boolean): Promise<IAccessTokenUpdate> {
	return makeRequest<IAccessTokenUpdate>(
		"/api/VideoCall/Disconnect",
		"POST",
		jwt,
		{ preventReconnect },
		{
			keepalive: true,
		},
	);
}

/**
 * Save to the web server that the positive/negative question which kicks off the user feedback survey was shown to a user.
 * This can be determined by the user feedback state at the time when the disconnection occurs
 * @param jwt Used to authenticate the request, must be valid in order for the web server to process the request
 * @returns A void promise that resolves when the request is complete
 */
async function logSurveyWasShown(jwt: string): Promise<IAccessTokenUpdate> {
	return makeRequest<IAccessTokenUpdate>("/api/FeedbackSurvey/SurveyWasShown", "POST", jwt);
}
