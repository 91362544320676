/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Indicator icon component for screen share feeds currently sharing screen share audio
 * @author Trevor Roussel
 * @module Epic.VideoApp.Components.Participants.ScreenShareAudioIndicator
 */

import React, { FC } from "react";
import { useStrings } from "~/hooks";
import Speaker from "~/icons/speaker";
import { stringFormat } from "~/utils/strings";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { IUser } from "~/web-core/interfaces";
import styles from "./ParticipantNameBar.module.scss";

export enum TokenNames {
	remoteSharedAudio = "RemoteSharedAudio",
	remoteSharedAudioWithName = "RemoteSharedAudioWithName",
	localSharedAudioLabel = "LocalSharedAudioLabel",
}

interface IProps {
	participant: IUser;
	displayName: string;
	isLocal?: boolean;
}

export enum ScreenShareAudioIndicatorTestIds {
	self = "ScreenShareAudioIndicator",
	muteButton = "ScreenShareAudioMuteButton",
}

/**
 * Indicator icon component for screen share feeds currently sharing screen share audio
 * @param participant - The participant to check for screen share audio
 * @param displayName - The display name of the participant
 * @param isLocal - Whether or not the participant is local, if undefined assume remote
 */
const ScreenShareAudioIndicator: FC<IProps> = (props) => {
	const { participant, displayName, isLocal } = props;
	const strings = useStrings("ScreenShareAudioIndicator", Object.values(TokenNames));

	const stream = useStream(participant, "screen");
	const isShareAudioEnabled = useIsStreamEnabled("audio", stream);

	const remoteAudioLabel = displayName
		? stringFormat(strings[TokenNames.remoteSharedAudioWithName], displayName)
		: strings[TokenNames.remoteSharedAudio];

	const localAudioLabel = strings[TokenNames.localSharedAudioLabel];

	const indicatorLabel = isLocal ? localAudioLabel : remoteAudioLabel;

	return (
		<span data-testid={ScreenShareAudioIndicatorTestIds.self}>
			{isShareAudioEnabled && (
				<div className={styles["spacer"]} title={indicatorLabel} aria-label={indicatorLabel}>
					<Speaker />
				</div>
			)}
		</span>
	);
};

ScreenShareAudioIndicator.displayName = "ScreenShareAudioIndicator";

export default ScreenShareAudioIndicator;
