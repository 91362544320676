/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file getSliceKey function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\get-slice-key
 */

/**
 * Create the string key for this slice of the store.
 * @param sliceId the ID of this slice
 * @param displayName the user-friendly name of this slice
 */
export function getSliceKey(sliceId: number, displayName: string): string {
	return `${sliceId}_${displayName}`;
}
