/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Component holding a feedback survey question
 * @author Max Harkins
 * @module Epic.VideoApp.Components.Utilities.Survey.Question
 */

import React, { FC, useCallback } from "react";
import { IQuestion } from "../../../types/survey";
import SelectionChoice from "../SelectionChoice";
import QuestionIcon from "./QuestionIcon";

/**
 * Props for Question Component
 */
interface IProps {
	/** Question object -- this stores information about the question that gets rendered */
	question: IQuestion;
	/** Callback to handle an input change from the question */
	handleInputChange: (question: IQuestion) => void;
	/** Indicates if the question checkbox is checked */
	isChecked: boolean;
	/** Displayable name string for the question */
	name: string;
}

/**
 * The Question component
 * @param _props The props ;)
 */
const Question: FC<IProps> = (props) => {
	const { question, isChecked, handleInputChange, name } = props;

	const onInputChange = useCallback(() => {
		handleInputChange(question);
	}, [handleInputChange, question]);

	return (
		<SelectionChoice
			id={question.id}
			label={name}
			onClick={onInputChange}
			checked={isChecked}
			selectType="multi"
			useButtonCheck
			icon={question.iconName ? <QuestionIcon iconName={question.iconName} /> : undefined}
			wide={!question.iconName}
		/>
	);
};

Question.displayName = "Question";

export default React.memo(Question);
