/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file addCombinedReducers function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\store\add-combined-reducers
 */

import { IBoosterInfo, ICombinedReducersMetadata } from "../types";
import { createMetaReducer } from "../create-meta-reducer/create-meta-reducer";
import { Reducer } from "react";
import { AnyAction } from "redux";

/**
 * Add the given collection of combined reducers to the store.
 * @param prevBoosterInfo The "old" booster information from the store
 * @param reducersInfo The selectors to be added
 * @param displayName The display name to give to this group of selectors
 */
export function addCombinedReducers(
	prevBoosterInfo: IBoosterInfo,
	reducersInfo: ICombinedReducersMetadata,
	_displayName: string,
	replaceReducer: (nextReducer: Reducer<any, AnyAction>) => void,
): IBoosterInfo {
	const { id, reducers } = reducersInfo;
	if (!prevBoosterInfo.reducerCollection[id]) {
		// Make sure to create a new copy
		const nextBoosterInfo: IBoosterInfo = { ...prevBoosterInfo };
		nextBoosterInfo.reducerCollection = { ...prevBoosterInfo.reducerCollection };
		// Add the reducers to the collection
		nextBoosterInfo.reducerCollection[id] = { ...reducers };
		// Tag the group of reducers with a display name in case we ever need it
		// ((nextBoosterInfo.reducerCollection[id] as any) as IHaveReducerMetadata).__$rmd = { displayName };
		// Rebuild the meta reducer
		replaceReducer(
			createMetaReducer(
				nextBoosterInfo.sliceInfoCollection,
				nextBoosterInfo.sliceKeyCollection,
				nextBoosterInfo.reducerCollection,
			) as any,
		);
		// Done
		return nextBoosterInfo;
	} else {
		throw Error(`Combined reducers with id ${id} have already been added to the store.`);
	}
}
