/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Hook to get some functions to update hardware test state
 * @author Tara Feldstein
 * @module Epic.VideoApp.Hooks.UseHardwareTestActions
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback } from "react";
import { hardwareTestActions } from "~/state";
import { DeviceStatus, DeviceStatusSubtype } from "~/types";

interface IHardwareTestActions {
	updateMicTestStatus: (isWorking: boolean, hasWorked: boolean) => void;
}

export function useHardwareTestActions(): IHardwareTestActions {
	const dispatch = useDispatch();

	const updateMicTestStatus = useCallback(
		(isWorking: boolean, hasWorked: boolean) => {
			if (isWorking) {
				// Only update state to success if it hasWorked, since isWorking defaults true
				if (hasWorked) {
					dispatch(
						hardwareTestActions.setMicrophoneState({
							status: DeviceStatus.success,
							errorType: DeviceStatusSubtype.none,
						}),
					);
				}
			} else {
				if (hasWorked) {
					// Mic stopped working
					dispatch(
						hardwareTestActions.setMicrophoneState({
							status: DeviceStatus.warning,
							errorType: DeviceStatusSubtype.stoppedWorking,
						}),
					);
				} else {
					// Mic never worked
					dispatch(
						hardwareTestActions.setMicrophoneState({
							status: DeviceStatus.warning,
							errorType: DeviceStatusSubtype.neverStarted,
						}),
					);
				}
			}
		},
		[dispatch],
	);

	return { updateMicTestStatus };
}
