/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Hook that provides the callback to run when a participant's image is captured
 * @author Chance Overberg
 * @module Epic.VideoApp.Components.ImageCapture.Hooks.UseImageCapturedHandler
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useRef } from "react";
import { alertActions } from "~/state";
import { Timeout } from "~/types";

export interface IImageCapturedHandled {
	(displayName: string): void;
}

/**
 * Sends a data track message to a specific participant, notifying them that a provider captured
 * an image of their video feed. Provider's display name is included if it isn't empty.
 * @returns a function used to send image captured data track messages.
 */
export function useImageCapturedHandler(): IImageCapturedHandled {
	const timeoutRef = useRef<Timeout>();
	const dispatch = useDispatch();
	const handleImageCapturedMessage = useCallback(
		(displayName: string) => {
			dispatch(
				alertActions.postToastAlert({
					type: "image-captured",
					messageToken: displayName ? "ImageCaptured" : "ImageCaptured_DefaultName",
					messageArgs: displayName ? [displayName] : undefined,
					dismissTimeMS: 0,
				}),
			);

			/**
			 * If there is already a timeout running, clear it before setting a new one.
			 * This prevents toasts after the first one from clearing too soon.
			 */
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				dispatch(alertActions.clearToasts("image-captured"));
			}, 7000);
		},
		[dispatch],
	);

	return handleImageCapturedMessage;
}
