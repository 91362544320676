/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Component to show a single device information
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.SingleDeviceMessage
 */

import React, { FC, useContext } from "react";
import { MenuContext } from "~/components/Utilities/MenuContext";
import styles from "./DeviceSelector.module.scss";

interface IProps {
	deviceLabel: string;
}

/**
 * The NoDevicesMessage component
 * @param props The props ;)
 */
const SingleDeviceMessage: FC<IProps> = (props) => {
	const { deviceLabel } = props;
	const { isInMenu: isFromMenu } = useContext(MenuContext);

	const label = deviceLabel;
	const labelComponent = (
		<span className={styles["deviceSelectorSpan"]} title={label}>
			{label}
		</span>
	);
	// if the label is too long, it could get cut off, so put the device name in the tooltip
	return isFromMenu ? (
		<div className={styles["deviceSelectorOptionContainer"]} role="menuitemradio" aria-checked="true">
			{labelComponent}
		</div>
	) : (
		// Fragment is needed so that Typescript can correctly infer the return type
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>{labelComponent}</>
	);
};

SingleDeviceMessage.displayName = "SingleDeviceMessage";

export default SingleDeviceMessage;
