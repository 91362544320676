/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Hook to monitor changes to an instance of media's audio or video status
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Hooks.UseIsStreamEnabled
 */

import { useEffect, useState } from "react";
import { DeviceKind } from "~/web-core/types";
import { IStream } from "../interfaces";

/**
 * Monitor changes to an instance of media's audio or video status
 * @param kind the kind of device to monitor
 * @param stream the stream to monitor
 * @returns true if the media is enabled, false otherwise
 */
export function useIsStreamEnabled(kind: DeviceKind, stream?: IStream): boolean {
	const [isEnabled, setIsEnabled] = useState<boolean>(stream?.isEnabled(kind) ?? false);

	useEffect(() => {
		setIsEnabled(stream?.isEnabled(kind) ?? false);
		const eventEnabled = kind === "video" ? "videoEnabled" : "audioEnabled";
		const eventDisabled = kind === "video" ? "videoDisabled" : "audioDisabled";
		const setEnabled = (): void => {
			setIsEnabled(true);
		};
		const setDisabled = (): void => {
			setIsEnabled(false);
		};

		if (stream) {
			stream.on(eventEnabled, setEnabled);
			stream.on(eventDisabled, setDisabled);
		}

		return () => {
			if (stream) {
				stream.off(eventEnabled, setEnabled);
				stream.off(eventDisabled, setDisabled);
			}
		};
	}, [stream, kind]);

	return isEnabled;
}
