/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Button with a switch to handle binary-toggle options in a menu
 * @author Trevor Roussel
 * @module Epic.VideoApp.Components.Utilities.ToggleSwitchButton
 */

import React, { FC, RefObject, useRef } from "react";
import Success from "~/icons/success";
import { resolveClassName } from "~/utils/className";
import switchStyles from "../HardwareSetup/Devices/ToggleSwitch.module.scss";
import KeyboardShortcut from "./KeyboardShortcut";
import styles from "./ToggleSwitchButton.module.scss";

/**
 * Core props for ToggleSwitchButton Component
 */
export interface IToggleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
	text: string;
	on: boolean;
	keyboardShortcut?: string[];
	buttonRef?: RefObject<HTMLButtonElement>;
	forImagePreviewPane?: boolean;
	labelClassName?: string;
	toggleSwitchClassName?: string;
	id: string;
	title?: string;
}

/**
 * Toggle-able button component that includes a switch indicator and a string label
 * @param props The props
 */
const ToggleSwitchButton: FC<IToggleButtonProps> = (props) => {
	const {
		onClick,
		on,
		keyboardShortcut,
		forImagePreviewPane,
		text,
		buttonRef,
		labelClassName,
		children,
		className,
		toggleSwitchClassName,
		id,
		...buttonProps
	} = props;

	const buttonClass = resolveClassName(
		styles,
		{
			baseButton: true,
		},
		className,
	);
	const labelClassFull = resolveClassName(styles, { labelText: true }, labelClassName);
	const toggleIndicatorClassName = resolveClassName(
		switchStyles,
		{ wrapper: true, on },
		toggleSwitchClassName,
	);

	const button = useRef<HTMLButtonElement>(null);
	const refToButton = buttonRef ?? button;

	return (
		<button
			onClick={onClick}
			className={buttonClass}
			ref={refToButton}
			{...buttonProps}
			role="switch"
			aria-checked={on}
			id={id}
			type="button"
		>
			<div tabIndex={-1}>
				<div className={styles["switchAndLabelWrapper"]}>
					{/** We don't want the functional elements of the toggle switch, so we just parrot its styling */}
					<div className={toggleIndicatorClassName}>
						<Success className={switchStyles["onIcon"]} aria-hidden />
						<div className={switchStyles["slider"]} />
					</div>

					{keyboardShortcut && (
						<KeyboardShortcut
							keyCombination={keyboardShortcut}
							callback={onClick}
							text={text}
							forImagePreviewPane={forImagePreviewPane}
						/>
					)}
					<label htmlFor={id} className={labelClassFull}>
						{text}
					</label>
				</div>
			</div>
			{children}
		</button>
	);
};

ToggleSwitchButton.displayName = "ToggleSwitchButton";

export default ToggleSwitchButton;
