/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Embedded waiting room for custom waiting room feature
 * @author Liam Liden
 * @module Epic.VideoApp.Components.VideoCall.EmbeddedWaitingRoom
 */

import React, { ReactElement, useCallback, useRef, useState } from "react";
import { useStrings } from "~/hooks";
import { resolveClassName } from "~/utils/className";
import Spinner from "../Loading/Spinner";
import styles from "./EmbeddedWaitingRoom.module.scss";

interface IProps {
	/** URL of page to load in embedded iFrame */
	url: string;
}

enum TokenNames {
	title = "Title",
}

/**
 * The EmbeddedWaitingRoom component
 */
const EmbeddedWaitingRoom = (props: IProps): ReactElement | null => {
	const { url } = props;
	const strings = useStrings("EmbeddedWaitingRoom", Object.values(TokenNames));

	const [waitingRoomHidden, setWaitingRoomHidden] = useState(true);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const showWaitingRoom = useCallback(() => {
		setWaitingRoomHidden(false);

		// Screen readers have inconsistent behavior when focusing the iframe element
		// Changing focus to the wrapping div will consistently read the iframe's title
		if (wrapperRef.current) {
			wrapperRef.current.focus();
		}
	}, [setWaitingRoomHidden]);

	const iframeClass = resolveClassName(styles, { waitingRoomEmbed: true, hidden: waitingRoomHidden });

	return (
		<div className={styles["waitingRoomWrapper"]} tabIndex={-1} ref={wrapperRef}>
			{waitingRoomHidden && <Spinner />}
			<iframe
				onLoad={showWaitingRoom}
				className={iframeClass}
				title={strings[TokenNames.title]}
				src={url}
			/>
		</div>
	);
};

EmbeddedWaitingRoom.displayName = "EmbeddedWaitingRoom";

export default EmbeddedWaitingRoom;
