/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Base interface for device streams
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.Stream
 */

import { IDimensions } from "~/types";
import { IEVCEmitter, IEVCStreamEventMap } from "../events";
import { DeviceKind } from "../types";

export interface IStream extends IEVCEmitter<IEVCStreamEventMap> {
	/**
	 * Property that will be true if a stream belongs to the local user, false otherwise
	 */
	readonly isLocal: boolean;

	/**
	 * Method to check if the specified audio or video stream is enabled
	 * @param kind Specifies either audio or video
	 * @returns true if the specified stream is enabled, false otherwise
	 */
	isEnabled: (kind: DeviceKind) => boolean;

	/**
	 * Method to check if a stream contains audio, used for some screen share features
	 * @returns true if the stream has audio, false otherwise
	 */
	hasAudio: () => boolean;

	/**
	 * Method to get the dimensions of a video stream
	 * @returns a dimensions object with the width and height of the video
	 */
	getVideoDimensions: () => IDimensions | undefined;

	/**
	 * Method to capture an image from the video stream
	 * @returns the image as a base64 string, or null if the image could not be captured
	 */
	captureImage(): Promise<string | null>;

	/**
	 * Renders a stream's video to the DOM in a specified HTMLVideoElement
	 * @param element The HTMLVideoElement to render the video stream to
	 */
	renderVideo(element: HTMLVideoElement): HTMLVideoElement | null;

	/**
	 * Removes a stream's video from the DOM
	 * @param element The HTMLVideoElement to remove the video stream from
	 */
	cleanupVideo(element: HTMLVideoElement): HTMLVideoElement | null;

	/**
	 * Attach stream to an audio element in the DOM
	 * @param element
	 */
	attachAudio(element: HTMLAudioElement): HTMLAudioElement | null;

	/**
	 * Remove a stream from an audio element in the DOM
	 * @param element
	 */
	detachAudio(element: HTMLAudioElement): HTMLAudioElement | null;

	/**
	 * Method to get the media stream track from a stream
	 */
	getMediaStreamTrack(kind: DeviceKind): MediaStreamTrack | undefined;
}
