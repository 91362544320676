/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Locked Camera Icon
 * @author Will Cooper
 * @module Epic.VideoApp.Icons.LockedMicrophone
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

export enum LockedMicrophoneIconTestIds {
	self = "LockedMicrophone",
}

/**
 * The LockedMicrophone component
 * @param props The props ;)
 */
const LockedMicrophone: FC<IconProps> = memo(() => {
	return (
		<svg
			data-testid={LockedMicrophoneIconTestIds.self}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 38 38"
		>
			<g>
				<path
					fill="#848484"
					d="M16,4a4.49,4.49,0,0,0-4.5,4.5v9a4.5,4.5,0,0,0,9,0v-9A4.49,4.49,0,0,0,16,4Z"
				/>
				<path
					fill="#848484"
					d="M23.35,16H21.8a6.17,6.17,0,0,1,.2,1.5,6,6,0,0,1-12,0,5.71,5.71,0,0,1,.19-1.5H5.5v1.5h3a7.5,7.5,0,0,0,6,7.35V28h3V24.85a7.5,7.5,0,0,0,6-7.35h3V16Z"
				/>
			</g>
			<g>
				<path
					fill="#fff"
					d="M30.43,25.35h-.52a1.5,1.5,0,0,1-1.51-1.48c0-.61,0-1.38,0-1.95,0-.31,0-.54,0-.64a3,3,0,0,0-5.93,0c0,.1,0,.33,0,.64,0,.57,0,1.34,0,1.95a1.5,1.5,0,0,1-1.51,1.48h-.51a1.5,1.5,0,0,1-1.51-1.48c0-.63,0-1.45,0-2,0-.29,0-.52,0-.6a6.52,6.52,0,0,1,13,0c0,.08,0,.31,0,.6,0,.54,0,1.36,0,2A1.5,1.5,0,0,1,30.43,25.35Z"
				/>
				<path
					fill="#171718"
					d="M30.43,24.77a.9.9,0,0,0,.91-.9c0-.95,0-2.32,0-2.59a5.93,5.93,0,0,0-11.85,0c0,.27,0,1.64,0,2.59a.91.91,0,0,0,.92.9h.51a.91.91,0,0,0,.92-.9c0-.95.05-2.31.05-2.59a3.56,3.56,0,0,1,7.12,0c0,.28.06,1.64.06,2.59a.9.9,0,0,0,.91.9h.52m0,1.16h-.52a2.08,2.08,0,0,1-2.1-2.06c0-.6,0-1.37,0-1.93,0-.36,0-.55,0-.66a2.38,2.38,0,0,0-4.75,0c0,.11,0,.3,0,.66,0,.56,0,1.33,0,1.93a2.08,2.08,0,0,1-2.1,2.06h-.51a2.08,2.08,0,0,1-2.1-2.06c0-.63,0-1.45,0-2,0-.27,0-.51,0-.59a7.11,7.11,0,0,1,14.22,0c0,.08,0,.32,0,.59,0,.55,0,1.37,0,2A2.08,2.08,0,0,1,30.43,25.93Z"
				/>
			</g>
			<g>
				<path
					fill="#fff"
					d="M19.46,25.93H31.3a1.18,1.18,0,0,1,1.19,1.17v5.83a1.17,1.17,0,0,1-1.19,1.16H19.46a1.17,1.17,0,0,1-1.19-1.16V27.1A1.18,1.18,0,0,1,19.46,25.93Z"
				/>
				<path
					fill="none"
					stroke="#171718"
					d="M19.46,25.35H31.3a1.76,1.76,0,0,1,1.78,1.75v5.83a1.76,1.76,0,0,1-1.78,1.74H19.46a1.76,1.76,0,0,1-1.78-1.74V27.1A1.76,1.76,0,0,1,19.46,25.35Z"
				/>
			</g>
		</svg>
	);
});

LockedMicrophone.displayName = "LockedMicrophone";

export default LockedMicrophone;
