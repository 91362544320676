/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Types for user feedback surveys
 * @author Max Harkins
 * @module Epic.VideoApp.Types.Survey
 */

import { IUserPermissions } from ".";

// Keep in sync with UserFeedbackState in ClientConfigurationDTO, since the initial feedback step must be part of a subset of these values
export enum FeedbackPageStep {
	hidden = 0,
	optional = 1,
	intro = 2,
	survey = 3,
	completed = 4,
}

// Enum that represents the status of loading the feedback survey questionnaire
export enum FeedbackSurveyLoadingStatus {
	notYetLoaded = 0,
	successfullyLoaded = 1,
	failedToLoad = 2,
}

/**
 * Interface for survey responses, where the index represents the question ID
 * and the value is the response (true for answered, false for not answered)
 */
export interface ISurveyResponses {
	[index: string]: boolean;
}

export interface ISaveSurvey {
	(responses: ISurveyResponses, isFinalSubmission: boolean): void;
}

/** Given a question or question group, determines whether the object is of type IQuestionGroup */
export const isQuestionGroup = (q: IQuestion | IQuestionGroup): q is IQuestionGroup =>
	"subQuestions" in q && q.subQuestions != null;

export interface ISurvey {
	pages: ISurveyPage[];
}

export interface ISurveyPage {
	questions: (IQuestionGroup | IQuestion)[];
}
export interface IQuestionBase {
	name: string;
	id: string;
	requiredPermissions?: (keyof IUserPermissions)[];
	iconName?: string;
}

export interface IQuestionGroup extends IQuestionBase {
	/** List a question as a dependency. The question group should not be shown unless
	 *  the question with ID specified by this property was answered 'yes' */
	dependsOnQuestion?: string;
	subQuestions: IQuestion[];
}

export enum FeedbackReaction {
	notAnswered = 0,
	positive,
	negative,
}

export type IQuestion = IQuestionBase;
