/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Utils file for i18n
 * @author Razi Rais
 * @module Epic.VideoApp.Utils.I18n.I18nUtils
 */

import { IDomElements } from "~/types/locale";
import { IUserPreferences } from "~/types/user";
import { getCookie, recentLanguageKey } from "../cookies";
import preAuthStrings from "./locales/strings.json";

/**
 * Normalizes the locale by replacing underscores and converting the characters to lowercase
 *
 * @param locale the input locale
 *
 * @return the normalized locale
 */
export function normalize(locale: string): string {
	return locale.replace("_", "-").toLowerCase();
}

/**
 * Gets the language code from the locale
 *
 * @param locale the input locale
 *
 * @return the language code
 */
export function languageCode(locale: string): string {
	return locale.split("-")[0];
}

/**
 * Determine the locale that should be used based on user preference, cookies, and browser language
 *
 * @param userPreferences optional user preferences to get the preferred language from
 *
 * @return the language code
 */
export function getPreferredLocale(userPreferences?: IUserPreferences): string {
	// prefer user preference value, then cookie value, then browser locale
	return (
		userPreferences?.preferredLocale ||
		getCookie(recentLanguageKey) ||
		resolveLocale(navigator.language) ||
		"en-us"
	);
}

/**
 * Gets the language code from the locale
 *
 * @param supportedLocales array of possible locales
 * @param localeCode the input locale
 *
 * @return The closest matching locale in supportedLocales to locale or null if there is none
 */
export function resolveLocale(localeCode: string): string | null {
	const supportedLocales = getSupportedLocales();
	const normalizedLocale = normalize(localeCode);

	const exactMatch = supportedLocales.find(
		(currentLocale) => normalize(currentLocale) === normalizedLocale,
	);

	if (exactMatch) {
		return exactMatch;
	}

	const userLanguageCode = languageCode(normalizedLocale);

	const languageCodeMatch = supportedLocales.find(
		(currentLocale) => normalize(languageCode(currentLocale)) === userLanguageCode,
	);

	if (languageCodeMatch) {
		return languageCodeMatch;
	}

	return null;
}

/**
 * Gets translations from strings.json
 *
 * @return pre-auth strings from strings.json
 */
export function getTranslationJson(): Record<string, Record<string, string>> {
	return preAuthStrings;
}

/**
 * Gets the language code from the locale
 *
 * @return List of supported locales from strings.json
 */
export function getSupportedLocales(): string[] {
	return Object.keys(preAuthStrings);
}

/**
 * Gets references to this page's HTML header and stylesheet link element.
 *
 * @returns Required HTMLElements and attributes.
 */
export function getDomElements(): IDomElements {
	return {
		htmlEl: document.querySelector("html"),
	};
}
