/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file getStateSlice function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\combine-reducers\get-state-slice
 */

import { IWholeState, ISliceKeyCollection } from "../types";

/**
 * Given the entire state object and either a slice ID or an array of slice IDs, return just those slices.
 * @param state The entire state object
 * @param sliceId The slice ID. Ignored if requiredSliceIds is set.
 * @param requiredSliceIds The array of slice IDs. If set, sliceId is ignored.
 * @returns A single slice if only sliceId is set. An array of slices if requiredSliceIds is set
 */
export function getStateSlice<TState extends IWholeState>(
	state: TState,
	sliceKeys: ISliceKeyCollection,
	sliceId: number,
	requiredSliceIds?: number[],
): any {
	return requiredSliceIds ? requiredSliceIds.map(id => state[sliceKeys[id]]) : state[sliceKeys[sliceId]];
}
