/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file hook to retrieve the currently selected mic ID from the localDeviceStream
 * @author Max Harkins
 * @module Epic.VideoApp.Hooks.UseSelectedMicId
 */

import { useContext } from "react";
import { VideoSessionContext } from "~/web-core/components";

/**
 * Get the selected microphone ID from the localDeviceStream
 */
export function useSelectedMicId(): string {
	const { localDeviceStream } = useContext(VideoSessionContext);
	return localDeviceStream?.getDeviceId("audio") ?? "";
}
