/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Shared state for the speakers
 * @author Colin Walters
 * @module Epic.VideoApp.State.Speakers
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";

/// TYPES ///

interface ISpeaker {
	id: string | null;
	label: string | null;
}

export interface ISpeakerState {
	selectedSpeaker: ISpeaker | null;
}

/// INIT ///

export function getInitialState(): ISpeakerState {
	return { selectedSpeaker: null };
}

/// REDUCERS ///

// set both the device ID and label at the same time, but don't store the whole MediaDeviceInfo because we don't need all of it's properties
export function setSelectedSpeaker(state: ISpeakerState, newSpeaker: MediaDeviceInfo | null): ISpeakerState {
	return {
		...state,
		selectedSpeaker: {
			id: newSpeaker?.deviceId ?? null,
			label: newSpeaker?.label ?? null,
		},
	};
}

/// SELECTORS ///

function getSelectedSpeakerId(state: ISpeakerState): string | null {
	return state.selectedSpeaker?.id ?? null;
}

function getSelectedSpeakerLabel(state: ISpeakerState): string | null {
	return state.selectedSpeaker?.label ?? null;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	selectors: { getSelectedSpeakerId, getSelectedSpeakerLabel },
});
store.addSharedState(builtState.sharedState, "speakers");

export const { useSharedState: useSpeakerState, sharedState: state } = builtState;
