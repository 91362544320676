/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interface for remote daily media.
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Implementations.DailyRemoteStream
 */

import { DailyParticipantTracks, DailyTrackState } from "@daily-co/daily-js";
import { IDimensions } from "~/types";
import { captureImage } from "~/utils/imageCapture";
import { EVCEmitter, IEVCStreamEventMap } from "~/web-core/events";
import { IRemoteStream } from "~/web-core/interfaces";
import { DeviceKind, VideoType } from "~/web-core/types";

export class DailyRemoteStream extends EVCEmitter<IEVCStreamEventMap> implements IRemoteStream {
	audioDevice: DailyTrackState | null;
	videoDevice: DailyTrackState | null;
	readonly isLocal: false = false;

	constructor(tracks: DailyParticipantTracks, videoType?: VideoType) {
		super();
		if (videoType === "screen") {
			this.videoDevice = tracks.screenVideo || null;
			this.audioDevice = tracks.screenAudio || null;
		} else {
			this.audioDevice = tracks?.audio || null;
			this.videoDevice = tracks?.video || null;
		}
	}

	isBandwidthLimited(): boolean {
		const videoTrackOffReasons = this.videoDevice?.off;

		// See here for accepted reasons for the track to be off: https://docs.daily.co/reference/daily-js/instance-methods/participants#participant-tracks-properties
		if (!videoTrackOffReasons) {
			return false;
		}

		return videoTrackOffReasons.byBandwidth || videoTrackOffReasons.byServerLimit || false;
	}

	isEnabled(kind: DeviceKind): boolean {
		if (kind === "audio") {
			const state = this.audioDevice?.state;
			return state === "playable" || state === "sendable";
		}
		if (kind === "video") {
			const state = this.videoDevice?.state;
			return state === "playable" || state === "sendable";
		}
		return false;
	}

	hasAudio(): boolean {
		return this.audioDevice !== null;
	}

	getVideoDimensions(): IDimensions | undefined {
		const videoSettings = this.videoDevice?.persistentTrack?.getSettings();
		if (videoSettings?.height && videoSettings?.width) {
			return {
				height: videoSettings.height,
				width: videoSettings.width,
			};
		}
		return undefined;
	}

	async captureImage(): Promise<string | null> {
		if (this.videoDevice?.persistentTrack) {
			return captureImage(this.videoDevice.persistentTrack);
		}

		return Promise.resolve(null);
	}

	getMediaStreamTrack(kind: DeviceKind): MediaStreamTrack | undefined {
		return kind === "audio" ? this.audioDevice?.persistentTrack : this.videoDevice?.persistentTrack;
	}

	renderVideo(element: HTMLVideoElement): HTMLVideoElement | null {
		if (this.videoDevice?.persistentTrack) {
			element.srcObject = new MediaStream([this.videoDevice.persistentTrack]);
			element.load();
		}

		element.onresize = this.handleResizeEvent.bind(this);
		return element;
	}

	cleanupVideo(element: HTMLVideoElement): HTMLVideoElement | null {
		element.srcObject = null;
		element.load();
		return element;
	}

	attachAudio(element: HTMLAudioElement): HTMLAudioElement | null {
		if (this.audioDevice?.persistentTrack) {
			element.srcObject = new MediaStream([this.audioDevice.persistentTrack]);
			element.load();
		}

		return element;
	}

	detachAudio(element: HTMLAudioElement): HTMLAudioElement | null {
		element.srcObject = null;
		return element;
	}

	private handleResizeEvent(): void {
		const newDim = this.getVideoDimensions() ?? { height: 0, width: 0 };
		this.emit("videoDimensionsChanged", { type: "videoDimensionsChanged", newDim });
	}
}
