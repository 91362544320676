/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Hook to determine if a video stream's bandwidth is limited
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.UseIsStreamBandwidthAffected
 */

import { useEffect, useRef, useState } from "react";
import { Timeout } from "~/types";
import { secondsToMs } from "~/utils/dateTime";
import { IRemoteStream, IStream } from "~/web-core/interfaces";

const switchOffSyncSeconds = 2;

/**
 * Monitors a given stream for bandwidth limitations
 * @param stream the stream to monitor
 * @returns true if the stream is bandwidth limited, false otherwise
 */
export function useIsStreamSwitchedOff(stream: IStream | undefined): boolean {
	// The useEffect will start a timeout to change this to true if the stream is indeed switched off
	const [isSwitchedOff, setSwitchedOff] = useState<boolean>(false);

	const timeoutRef = useRef<Timeout | null>();

	useEffect(() => {
		const performSwitchOff = (): void => {
			setSwitchedOff(true);
		};

		// Make sure we initialize the overlay status properly to ensure the overlay moves between visual participants
		if (checkSwitchedOff(stream)) {
			timeoutRef.current = setTimeout(performSwitchOff, secondsToMs(switchOffSyncSeconds));
		} else {
			setSwitchedOff(false);
		}

		if (stream) {
			// Setup event listeners for switch-off/switch-on
			const handleSwitchedOff = (): void => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
					timeoutRef.current = null;
				}
				// Delay the handling of track switch offs for a few seconds to confirm that a given participant's video remains switched off
				timeoutRef.current = setTimeout(performSwitchOff, secondsToMs(switchOffSyncSeconds));
			};
			const handleSwitchedOn = (): void => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
					timeoutRef.current = null;
				}
				setSwitchedOff(false);
			};
			stream.on("videoBandwidthLow", handleSwitchedOff);
			stream.on("videoBandwidthNormal", handleSwitchedOn);
			return () => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
					timeoutRef.current = null;
				}
				stream.off("videoBandwidthLow", handleSwitchedOff);
				stream.off("videoBandwidthNormal", handleSwitchedOn);
			};
		}
	}, [stream]);

	return isSwitchedOff;
}

/**
 * Checks if a remote stream has had quality reduced due to low bandwidth
 * @param stream the stream to check
 * @returns true if the stream is bandwidth limited, false otherwise
 */
function checkSwitchedOff(stream?: IStream): boolean {
	if (!stream) {
		return false;
	}

	if ("isBandwidthLimited" in stream) {
		return (stream as IRemoteStream).isBandwidthLimited();
	}

	return false;
}
