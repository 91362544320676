/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file createStore function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\get-store-enhancers
 */

/* istanbul ignore next */
export function getStoreEnhancers(): any {
	if (typeof window !== "undefined") {
		return (
			(window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
			(window as any).__REDUX_DEVTOOLS_EXTENSION__()
		);
	}
	return undefined;
}
