/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Button for moderators to disable and lock remote video
 * @author Will Cooper & Trevor Roussel
 * @module Epic.VideoApp.Components.Participants.Moderation.ModerationButtons.LockParticipantCameraButton
 */
import React, { FC, useCallback } from "react";
import BaseButton from "~/components/Utilities/BaseButton";
import ToggleSwitchButton from "~/components/Utilities/ToggleSwitchButton";
import { useStrings } from "~/hooks";
import { useSendModeratorAction } from "~/hooks/messaging/index";
import { useModerationState, useRoomState } from "~/state";
import { EpicUserType } from "~/types";
import { getCameraIcon } from "~/utils/device";
import UnlockDeviceRequestIndicator from "./UnlockDeviceRequestIndicator";

interface IDisableProps {
	identity: string;
	className: string;
	enabled: boolean;
	labelClassName?: string;
	inNameBar?: boolean;
	userType?: EpicUserType;
	iconClassName?: string;
	toggleSwitchClassName?: string;
}

/** String tokens used by the LockParticipantCameraButton  component */
enum TokenNames {
	allowCamera = "AllowCamera",
	turnOffCamera = "TurnOffCamera",
	videoAriaLabel = "VideoAriaLabel",
}

export enum LockParticipantCameraButtonTestIds {
	toggleButton = "LockParticipantCameraButton-ToggleButton",
	baseButton = "LockParticipantCameraButton-BaseButton",
}

const LockParticipantCameraButton: FC<IDisableProps> = (props: IDisableProps) => {
	const {
		identity,
		className,
		inNameBar,
		labelClassName,
		enabled,
		userType,
		iconClassName,
		toggleSwitchClassName,
	} = props;
	const strings = useStrings("LockParticipantCameraButton", Object.values(TokenNames));

	const sendModeratorMessage = useSendModeratorAction();
	const requestInfo = useModerationState(
		(selectors) => selectors.getParticipantRequest(identity),
		[identity],
	);

	const participantModeration = useRoomState(
		(selectors) => selectors.getParticipantModerationLocks(identity),
		[identity],
	);

	const { video: cameraLocked } = participantModeration;
	const { video: videoRequested } = requestInfo;

	const onDisableClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			sendModeratorMessage({ videoLock: !cameraLocked }, identity, true);

			// stop propagation to avoid also triggering the onClick
			event?.stopPropagation();
		},
		[cameraLocked, sendModeratorMessage, identity],
	);
	const useToggleSwitch = userType !== EpicUserType.emp;

	const buttonDisabled = userType === EpicUserType.emp && !enabled;
	const cameraIcon = getCameraIcon(enabled);

	const ariaLabel = videoRequested ? strings[TokenNames.videoAriaLabel] : undefined;
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{useToggleSwitch ? (
				<ToggleSwitchButton
					text={strings[TokenNames.allowCamera]}
					className={className}
					onClick={onDisableClick}
					on={!cameraLocked}
					aria-label={ariaLabel}
					labelClassName={labelClassName}
					toggleSwitchClassName={toggleSwitchClassName}
					data-testid={LockParticipantCameraButtonTestIds.toggleButton}
					id="lockCameraToggle"
				>
					{videoRequested && !inNameBar && <UnlockDeviceRequestIndicator />}
				</ToggleSwitchButton>
			) : (
				<BaseButton
					className={className}
					icon={cameraIcon}
					text={strings[TokenNames.turnOffCamera]}
					onClick={onDisableClick}
					disabledTabbable={buttonDisabled}
					labelClassName={labelClassName}
					aria-label={ariaLabel}
					iconClassName={iconClassName}
					data-testid={LockParticipantCameraButtonTestIds.baseButton}
				>
					{videoRequested && !inNameBar && <UnlockDeviceRequestIndicator />}
				</BaseButton>
			)}
		</>
	);
};

LockParticipantCameraButton.displayName = "LockParticipantCameraButton";

export default LockParticipantCameraButton;
