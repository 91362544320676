/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file call duration timer destination component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.CallDurationTimer.CallDurationTimerDestination
 */

import React from "react";
import styles from "./CallDurationTimer.module.scss";

export const destinationElementId = "duration-timer";

/**
 * The CallDurationTimerDestination component
 */
export const CallDurationTimerDestination: React.FunctionComponent = () => (
	<span id={destinationElementId} className={styles["timer"]} />
);
