/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file utility function related to the user preferences
 * @author Steven Anderson
 * @module Epic.VideoApp.Utils.UserPreferences
 */

import { DefaultImageNames } from "~/types/backgrounds";
import { IUserPreferences, IUserPreferencesWithEncryption } from "~/types/user";
import { getCookie, preferencesCookieKey, setCookie } from "./cookies";
import { hoursToMs } from "./dateTime";
import { makeRequest } from "./request";

export function loadUserPreferences(userKey: string): IUserPreferences | null {
	if (!userKey) {
		return null;
	}

	let userPreferences: IUserPreferences = {
		encryptedDisplayName: null,
		lastBackgroundProcessor: DefaultImageNames.none,
		preferredLocale: null,
	};

	const serializedPreferences = localStorage.getItem("userPreferences_" + userKey);
	if (!serializedPreferences) {
		return userPreferences;
	}

	try {
		userPreferences = JSON.parse(serializedPreferences) as IUserPreferences;
	} catch {
		return userPreferences;
	}

	if (!userPreferences) {
		return userPreferences;
	}

	if (userPreferences.encryptedDisplayName === undefined) {
		userPreferences.encryptedDisplayName = null;
	}

	if (userPreferences.preferredLocale === undefined) {
		userPreferences.preferredLocale = null;
	}

	return userPreferences;
}

export function saveUserPreferences(userKey: string, userPreferences: IUserPreferences): void {
	if (!userKey || !userPreferences) {
		return;
	}

	localStorage.setItem("userPreferences_" + userKey, JSON.stringify(userPreferences));
}

export function getUserPreferencesKeyFromCookie(sessionKey: string): string {
	if (!sessionKey) {
		return "";
	}
	return getCookie(preferencesCookieKey + sessionKey);
}

export function storeUserPreferencesKeyInCookie(sessionKey: string, userKey: string): void {
	setCookie(preferencesCookieKey + sessionKey, userKey, hoursToMs(4));
}

/**
 * After a refresh get the user's display name from cookies and decrypt on the server
 */
export async function decryptUserPreferences(
	jwt: string,
	encryptedUserPreferences: IUserPreferencesWithEncryption,
): Promise<IUserPreferencesWithEncryption> {
	return makeRequest<IUserPreferencesWithEncryption>("/api/VideoCall/DecryptUserPreferences", "POST", jwt, {
		encryptedUserPreferences,
	});
}
