/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Discard Button
 * @author SpencerEanes
 * @module Epic.VideoApp.Components.ImageCapture.Controls.DiscardButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import trashIcon from "~/icons/trash";
import { combinedActions, imageActions } from "~/state";

enum TokenNames {
	discardButtonAria = "DiscardButtonAria",
	tooltip = "Tooltip",
}

export enum DiscardButtonTestIds {
	self = "DiscardButton",
}

/**
 * The DiscardButton component
 */
const DiscardButton: FC = () => {
	const dispatch = useDispatch();

	const strings = useStrings("DiscardButton", Object.values(TokenNames));

	const discard = useCallback(() => {
		dispatch(imageActions.setImageData(null));
		dispatch(combinedActions.clearImageCaptureActive());
	}, [dispatch]);

	const discardKeyboardShortcut = ["alt", "t"];
	const tooltipFormatted = useGetFormattedHotkeyString(
		strings[TokenNames.tooltip],
		discardKeyboardShortcut,
	);
	const ariaFormatted = useGetFormattedHotkeyString(
		strings[TokenNames.discardButtonAria],
		discardKeyboardShortcut,
	);

	return (
		<ActionButton
			aria-label={ariaFormatted}
			tone="negative"
			priority="secondary"
			onClick={discard}
			icon={trashIcon}
			keyboardShortcut={["alt", "t"]}
			tooltipInfo={{ text: tooltipFormatted, direction: "left" }}
			forImagePreviewPane
			data-testid={DiscardButtonTestIds.self}
		/>
	);
};

DiscardButton.displayName = "DiscardButton";

export default DiscardButton;
