/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file leave call button
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.LeaveCallButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import Leave from "~/icons/leave";
import { alertActions, useUserState } from "~/state";
import { AlertType, IChoiceAlert } from "~/types";
import ControlButton from "./ControlButton";

/**
 * Props for LeaveCallButton component
 */
interface IProps {
	/** Whether or not the button should be rendered without a label */
	noLabel?: boolean;

	/** Whether or not the button should be rendered without a tooltip */
	noTooltip?: boolean;
}

enum TokenNames {
	message = "Message",
	leaveCall = "LeaveCall",
	stayConnected = "StayConnected",
}

/**
 * Leave call button
 * @param props the props ;)
 */
const LeaveCallButton: FC<IProps> = (props: IProps) => {
	const { noLabel, noTooltip } = props;

	const dispatch = useDispatch();

	const strings = useStrings("LeaveCallButton", Object.values(TokenNames));
	const canEndVisit = useUserState((selectors) => selectors.getUserPermission("canStartEndVisit"), []);

	const stringsRef = useRef(strings);
	useEffect(() => {
		stringsRef.current = strings;
	}, [strings]);

	const leaveCallKeyboardShortcut = ["alt", "l"];
	const leaveCallTooltipFormatted = useGetFormattedHotkeyString(
		strings[TokenNames.leaveCall],
		leaveCallKeyboardShortcut,
	);

	const onLeaveCall = useCallback(() => {
		if (canEndVisit) {
			dispatch(alertActions.postEndVisitAlert());
		} else {
			// Setup a modal to dispatch to the user
			const disconnectAlert: IChoiceAlert = {
				message: stringsRef.current["Message"],
				confirmText: stringsRef.current[TokenNames.leaveCall],
				confirmHotkey: "L",
				cancelText: stringsRef.current[TokenNames.stayConnected],
				cancelHotkey: "S",
				type: AlertType.disconnectChoice,
			};
			// The resolution of the alert pop-up handles the potential disconnection
			// and routing of the user to the generic "you left the call page"
			dispatch(alertActions.postChoiceAlert(disconnectAlert));
		}
	}, [canEndVisit, dispatch]);

	return (
		<ControlButton
			icon={Leave}
			onClick={onLeaveCall}
			ariaLabel={leaveCallTooltipFormatted}
			keyboardShortcut={leaveCallKeyboardShortcut}
			buttonText={!noLabel ? strings["LeaveCall"] : undefined}
			tooltipText={!noTooltip ? leaveCallTooltipFormatted : undefined}
			tone="negative"
		/>
	);
};

LeaveCallButton.displayName = "LeaveCallButton";

export default LeaveCallButton;
