/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Provides mostly static data about current call participants. Should update infrequently.
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Components.ParticipantDataProvider
 */

import React, { FC, useMemo } from "react";
import {
	INamelessParticipantData,
	useNamelessParticipantData,
} from "~/components/VideoCall/hooks/useNamelessParticipantData";
import { useModerationState, useRoomState } from "~/state";
import { bucketWaitingParticipants } from "../helpers/bucketWaitingParticipants";
import { useRemoteUsers } from "../hooks/useRemoteUsers";
import { IRemoteUser } from "../interfaces/remoteUser";
import { ISession } from "../interfaces/session";
import { usePeerConnectionStats } from "../vendor/twilio/hooks/usePeerConnectionStats";
import PeerSignaler from "./PeerSignaler";

export interface IParticipantDataContext {
	participants: IRemoteUser[];
	namelessParticipantData: INamelessParticipantData;
	waitingParticipants: IRemoteUser[];
}

export const ParticipantDataContext = React.createContext<IParticipantDataContext>({
	participants: [],
	namelessParticipantData: {
		timestamp: 0,
		dataIsShared: false,
		idList: [],
	},
	waitingParticipants: [],
});

interface IProps {
	session: ISession;
}

/**
 * Context provider to surface information about the video call to app components
 * @param session the session
 */
export const ParticipantDataProvider: FC<IProps> = (props) => {
	const { children, session } = props;

	const allParticipantInfo = useRoomState((selectors) => selectors.getAllParticipantInfo(), []);

	// State to clean-up on disconnection
	const requiresAccess = useModerationState((selectors) => selectors.getDoesVisitRequireAccess(), []);

	const allParticipants = useRemoteUsers(session);
	const [participants, waitingParticipants] = useMemo(() => {
		return bucketWaitingParticipants(allParticipants, allParticipantInfo, requiresAccess);
	}, [allParticipantInfo, allParticipants, requiresAccess]);

	const localUser = session.getLocalParticipant();
	const localIdentity = localUser?.getUserIdentity() ?? "";

	// Only use this hook once to ensure one copy of the nameless participant data exists
	// Explicitly return message handlers to attach with to the data track listener
	const [namelessParticipantData, handleNamelessParticipantRequest, handleNamelessParticipantResponse] =
		useNamelessParticipantData(localIdentity);

	usePeerConnectionStats(session);

	return (
		<ParticipantDataContext.Provider
			value={{
				participants,
				namelessParticipantData,
				waitingParticipants,
			}}
		>
			{children}
			{/* Pass in allParticipants to the PeerSignaler to make sure waiting room participants are included */}
			<PeerSignaler
				handleNamelessRequest={handleNamelessParticipantRequest}
				handleNamelessResponse={handleNamelessParticipantResponse}
				localUserIdentity={localIdentity}
				participants={allParticipants}
				session={session}
			/>
		</ParticipantDataContext.Provider>
	);
};

ParticipantDataProvider.displayName = "ParticipantDataProvider";
