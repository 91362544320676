/**
 * @copyright
 * @file Loading Ellipse
 * @author
 * @module Epic.VideoApp.Icons.LoadingEllipsis
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";
import styles from "./loadingEllipsis.module.scss";

/**
 * The LockedCamera component
 * @param props The props ;)
 */
const LoadingEllipsis: FC<IconProps> = memo(() => {
	return (
		<div className={styles["loadingEllipsis"]} aria-hidden>
			<span />
			<span />
			<span />
		</div>
	);
});

LoadingEllipsis.displayName = "LoadingEllipsis";

export default LoadingEllipsis;
