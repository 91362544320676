/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file shared authentication state
 * @author Colin Walters
 * @module Epic.VideoApp.State.Auth
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";

/// TYPES ///

export interface IAuthState {
	readonly jwt: string | null;
	readonly refreshTokenTimer: number | null;
}

/// INIT ///

export function getInitialState(): IAuthState {
	return {
		jwt: null,
		refreshTokenTimer: null,
	};
}

/// REDUCERS ///
export function clearAuthState(): IAuthState {
	return getInitialState();
}

export function setJWT(state: IAuthState, newJWT: string | null): IAuthState {
	return { ...state, jwt: newJWT };
}

function setRefreshTokenTimer(state: IAuthState, refreshTokenTimer: number | null): IAuthState {
	return { ...state, refreshTokenTimer: refreshTokenTimer };
}

/// SELECTORS ///

function getJWT(state: IAuthState): string | null {
	return state.jwt;
}

function getRefreshTokenTimer(state: IAuthState): number | null {
	return state.refreshTokenTimer;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: { setJWT, setRefreshTokenTimer, clearAuthState },
	selectors: { getJWT, getRefreshTokenTimer },
});

store.addSharedState(builtState.sharedState, "auth");

export const { actionCreators: authActions, useSharedState: useAuthState, sharedState: state } = builtState;
