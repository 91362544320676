/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Confirmation alert that will appear when a moderator attempts to end the visit for all connected parties
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Alerts.EndVisitConfirmationAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext } from "react";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import { useDisconnect, useStrings } from "~/hooks";
import { alertActions, errorPageActions, roomActions, useAuthState } from "~/state";
import { IChoiceAlert, MessageActionType, MessageType } from "~/types";
import { makeRequest } from "~/utils/request";
import { sendMessage } from "~/utils/sendMessage";
import { VideoSessionContext } from "~/web-core/components";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IChoiceAlert;
}

enum TokenNames {
	errorMessage = "ErrorMessage",
}

export enum EndVisitConfirmationAlertTestIds {
	self = "EndVisitConfirmationAlert",
}

/**
 * EndVisitConfirmation alert component
 *
 * Renders a final confirmation message to allow the user to choose to end the visit for all or return to the visit
 */
const EndVisitConfirmationAlert: FC<IProps> = (props: IProps) => {
	const { alert } = props;
	const { session } = useContext(VideoSessionContext);
	const dispatch = useDispatch();
	const disconnect = useDisconnect();
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);
	const strings = useStrings("EndVisitAlert", Object.values(TokenNames));

	const endCall = useCallback(() => {
		async function endCallForAll(): Promise<void> {
			try {
				// Mark the user as disconnecting to prevent the visit completed room error in VideoRoom.tsx from also disconnecting them.
				dispatch(roomActions.setIsDisconnecting(true));
				await endVisit(JWT || "");
				sendMessage(session, {
					action: MessageActionType.broadcast,
					payload: null,
					type: MessageType.visitEnded,
					needsACK: false,
				});
				// The sender of a broadcast message doesn't receive it themselves, so manually disconnect them after sending it.
				dispatch(
					errorPageActions.setErrorCard({
						title: ErrorTokenNames.endedTitle,
						message: ErrorTokenNames.endedBody,
					}),
				);
				disconnect(false);
			} catch {
				// If the API call fails, clear the disconnecting status
				dispatch(roomActions.setIsDisconnecting(false));
				dispatch(alertActions.postGenericAlert(strings[TokenNames.errorMessage]));
			}
		}

		void endCallForAll();
		dispatch(alertActions.clearAlert());
	}, [JWT, disconnect, dispatch, session, strings]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	return (
		<ChoiceAlertBase
			data-testid={EndVisitConfirmationAlertTestIds.self}
			alert={alert}
			confirm={endCall}
			cancel={cancel}
		/>
	);
};

function endVisit(jwt: string): Promise<void> {
	return makeRequest("/api/VideoCall/EndVisit", "POST", jwt);
}

EndVisitConfirmationAlert.displayName = "EndVisitConfirmationAlert";

export default EndVisitConfirmationAlert;
