/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Centralized handler to reinit audio for iOS backgrounding/Siri interruptions
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Hooks.UseHandleAudioContext
 */

import { useCallback, useContext } from "react";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";
import { iOSDetectedAtVersion } from "~/utils/os";

export default function useHandleAudioContext(): (forceRestart?: boolean) => Promise<void> {
	const { audioContext, restartAudioContext } = useContext(DeviceContext);

	const tryHandleAudioContext = useCallback(
		async (forceRestart?: boolean): Promise<void> => {
			// we sometimes did not get ASD after backgrounding on iOS14 as well
			if (iOSDetectedAtVersion("14+")) {
				/**	if we don't have an AudioContext, or it is closed, or we got a shouldRestart argument,
					trigger restartAudioContext to have useSingletonAudioContext generate a new one */
				if (!audioContext || audioContext.state === "closed" || forceRestart) {
					await restartAudioContext();
					return;
				}
				/**	otherwise, look to resume; if that fails, use the restart function  */
				try {
					switch (audioContext.state) {
						case "suspended":
							await audioContext.resume();
							break;
						case "interrupted":
							await audioContext.close();
							break;
					}
				} catch {
					await restartAudioContext();
				}
			}
		},
		[audioContext, restartAudioContext],
	);

	return tryHandleAudioContext;
}
