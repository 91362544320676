/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Returns stateful updates to a video stream's dimensions
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.TrackUtilities.UseVideoStreamDimensions
 */

import { useEffect, useState } from "react";
import { IDimensions } from "~/types";
import { IEVCDimensionEvent } from "~/web-core/events";
import { IStream } from "~/web-core/interfaces";

/**
 * Returns the current dimensions of the video track. Sets up event listeners to ensure this value stays up to date despite changes (orientation, resizing, or pausing/playing)
 * @param videoTrack video track to check size of
 * @returns the current dimensions of the video track
 */
export default function useVideoStreamDimensions(stream?: IStream): IDimensions {
	const [dimensions, setDimensions] = useState(stream?.getVideoDimensions() ?? { height: 0, width: 0 });

	useEffect(() => {
		setDimensions(stream?.getVideoDimensions() ?? { height: 0, width: 0 });

		const handleDimensionsChanged = (args: IEVCDimensionEvent): void => setDimensions(args.newDim);

		stream?.on("videoDimensionsChanged", handleDimensionsChanged);

		return () => {
			stream?.off("videoDimensionsChanged", handleDimensionsChanged);
		};
	}, [stream]);

	return dimensions;
}
