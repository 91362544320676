/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file Utility to validate and sanitize user text input
 * @author Razi Rais
 * @module Epic.VideoApp.Utils.Text
 */

/**
 * @param input The user's text input to sanitize
 * @param maxInputLength The maximum number of characters that the input can contain
 * @param supportedCharacters The characters which should be kept in the output - win1252 as default
 * @returns Text input with instances of possible code injection, unsupported characters, and extra whitespace removed
 */
export function sanitizeInputForCharSet(
	input: string,
	maxInputLength: number,
	supportedCharacters?: SupportedCharacters,
): string {
	return sanitizeInputHelper(input, maxInputLength, supportedCharacters ?? "win1252");
}

/**
 * @param input The user's text input to partially sanitize
 * @param maxInputLength The maximum number of characters that the input can contain
 * @returns Text input with instances of possible code injection and extra whitespace removed.
 */
export function sanitizeInput(input: string, maxInputLength: number): string {
	return sanitizeInputHelper(input, maxInputLength);
}

/**
 * Helper function
 * @param input The user's text input to sanitize
 * @param maxInputLength The maximum number of characters that the input can contain
 * @param supportedCharacters The characters which should be kept in the output, if specified
 * @returns Text input with instances of possible code injection, extra whitespace, and unsupported characters (if specified) removed
 */
function sanitizeInputHelper(
	input: string,
	maxInputLength: number,
	supportedCharacters?: SupportedCharacters,
): string {
	let sanitizedInput = escapeMarkup(input);
	if (supportedCharacters) {
		const isSupported = (input: string): boolean => {
			return isSupportedCharacters(input, supportedCharacters);
		};
		sanitizedInput = sanitizedInput.split("").filter(isSupported).join("");
	}
	return truncateInput(sanitizedInput, maxInputLength);
}

/**
 * Parses a string by
 *      - breaking XML by adding a space between angle bracket and the following letter or control character
 *      - eliminate sequences of &# in user input to avoid the user encoding something in HTML
 * Based on MyChart's implementation of parseString (https://codesearch/Default.aspx?view=search/client#a=xmlCharRegExp&versionid=1&name=PatientEngagement%5CPackages%5Cclient-sdk%5Csrc%5Cform%5Capi%5Cparsers%5Cparse-string.ts)
 * @param input the string to parse.
 * @returns the parsed string value.
 */
function escapeMarkup(input: string): string {
	const xmlCharRegExp = /<([A-Za-z/])/gm;
	const xmlReplacementValue = "< $1";

	const htmlElementRegExp = /&#/gm;
	const htmlReplacementValue = "& #";

	//breaks XML by adding a space between angle bracket and the following letter or control character
	let newInput = input.replace(xmlCharRegExp, xmlReplacementValue);
	//eliminate sequences of &# in user input to avoid the user encoding something in HTML
	newInput = newInput.replace(htmlElementRegExp, htmlReplacementValue);

	return newInput;
}

/**
 * @param input The user's text input to check.
 * @param supportedCharacters The characters that should be allowed, or win1252 by default.
 * @returns A string of all unsupported characters in the given input.
 */
export function getUnsupportedCharacters(input: string, supportedCharacters?: SupportedCharacters): string {
	if (supportedCharacters === "all") {
		return "";
	}

	return Array.from(input)
		.filter((char) => !isSupportedCharacters(char, supportedCharacters))
		.join(", ");
}

/**
 * @param input The user's text input to check.
 * @param supportedCharacters The characters that should be allowed, or win1252 by default.
 * @returns True if the input is supported by the given character set.
 */
export function isSupportedCharacters(input: string, supportedCharacters?: SupportedCharacters): boolean {
	if (input.length === 0) {
		return true;
	}

	if (supportedCharacters === "all") {
		// never allow breaking control characters to be included in input.
		const result = new RegExp(badControlRegEx).test(input);
		return !result;
	}
	// default assumes that supportedCharacters = win1252
	const result = new RegExp(win1252RegEx).test(input);
	return result;
}

// https://en.wikipedia.org/wiki/List_of_Unicode_characters
// windows-1252 and non-control characters
const win1252RegEx =
	"^[" +
	"\\x09\\x0A\\x0D" +
	"\\x20-\\x7E" +
	"\\xA0-\\xFF" +
	"\\u0152\\u0153\\u0160\\u0161\\u0178\\u017D\\u017E\\u0192\\u02C6\\u02DC\\u2013\\u2014\\u2018-\\u201A" +
	"\\u201C-\\u201E\\u2020-\\u2022\\u2026\\u2030\\u2039\\u203A\\u20AC\\u2122" +
	"]*$";

const badControlRegEx = "^[\\x00-\\x08\\x0B\\x0C\\x0E-\\x0F]*$";

export type SupportedCharacters = "win1252" | "all";

/**
 * @param input Text input
 * @param maxInputLength The maximum number of characters that the input can contain
 * @returns Text input that has been shortened to the specified max length
 */
export function truncateInput(input: string, maxInputLength: number): string {
	if (input.length <= maxInputLength) {
		return input;
	}

	return input.slice(0, maxInputLength);
}

/**
 * Formats a string to capitalize the first letter
 * @param string
 * @returns string with first letter capitalized
 */
export function capitalizeFirstLetter(string: string): string {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
