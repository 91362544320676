/**
 * @copyright Copyright 2019 Epic Systems Corporation
 * @file addCombinedSelectors function
 * @author Roland Scott
 * @module react-redux-booster\src\internal\store\add-combined-selectors
 */

import { ICombinedSelectorsMetadata, IBoosterInfo } from "../types";
import { buildAccessors } from "./build-accessors";
import { buildCombinedAccessorDependencies } from "./build-combined-accessor-dependencies";

/**
 * Add the given collection of combined selectors to the store.
 * @param prevBoosterInfo The "old" booster information from the store
 * @param selectorsInfo The selectors to be added
 * @param displayName The display name to give to this group of selectors
 */
export function addCombinedSelectors(
	prevBoosterInfo: IBoosterInfo,
	selectorsInfo: ICombinedSelectorsMetadata,
	_displayName: string,
	getState: () => any,
): IBoosterInfo {
	const { id, selectors } = selectorsInfo;
	if (!prevBoosterInfo.accessorCollection[id] && !prevBoosterInfo.accessorDependencies[id]) {
		// Make sure to create a new copy
		const nextBoosterInfo: IBoosterInfo = { ...prevBoosterInfo };
		nextBoosterInfo.accessorCollection = { ...prevBoosterInfo.accessorCollection };
		nextBoosterInfo.accessorDependencies = { ...prevBoosterInfo.accessorDependencies };
		// Build the accessors and add them to the store
		nextBoosterInfo.accessorCollection[id] = buildAccessors(
			selectors,
			getState,
			-1,
			nextBoosterInfo.sliceKeyCollection,
		);
		// Mark the accessor dependencies so we can memoize
		nextBoosterInfo.accessorDependencies[id] = buildCombinedAccessorDependencies(selectors);
		// Tag the group of accessors with a display name in case we ever need it
		// nextBoosterInfo.accessorCollection[id].__$amd = { displayName };
		// Done
		return nextBoosterInfo;
	} else {
		throw Error(`Combined selectors with id ${id} have already been added to the store.`);
	}
}
