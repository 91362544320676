/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Remote User in Daily Sessions
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Implementations.DailyRemoteUser
 */

import { DailyCall, DailyParticipant } from "@daily-co/daily-js";
import { EVCEmitter, IEVCUserEventMap } from "~/web-core/events";
import { IRemoteUser } from "~/web-core/interfaces";
import { IStreamSubscriptionStatus, Quality, SubscriptionStatus, VideoType } from "~/web-core/types";
import { DailyRemoteStream } from "./dailyRemoteStream";

export class DailyRemoteUser extends EVCEmitter<IEVCUserEventMap> implements IRemoteUser {
	private static qualityMap: Record<Quality, number | undefined> = {
		[Quality.undefined]: undefined,
		[Quality.low]: 0,
		[Quality.standard]: 1,
		[Quality.high]: 2,
	};

	private static subscriptionMap: Record<SubscriptionStatus, boolean | "staged"> = {
		[SubscriptionStatus.subscribed]: true,
		[SubscriptionStatus.staged]: "staged",
		[SubscriptionStatus.unsubscribed]: false,
	};

	deviceStream: DailyRemoteStream;
	shareStream: DailyRemoteStream | null;
	participant: DailyParticipant;
	readonly isLocal: false = false;
	private _subscriptionStatus: IStreamSubscriptionStatus;
	private _isUsingLowQualityMode: boolean = false;
	private _qualityStatus: number;
	call: DailyCall;

	constructor(participant: DailyParticipant, call: DailyCall) {
		super();
		this.shareStream = null;
		this.deviceStream = new DailyRemoteStream(participant.tracks);
		this.participant = participant;
		this._subscriptionStatus = {
			camera: SubscriptionStatus.unsubscribed,
			screen: SubscriptionStatus.unsubscribed,
		};
		this._qualityStatus = DailyRemoteUser.qualityMap[Quality.standard] ?? -1;
		this.call = call;
	}

	// Static method to be called if the session should reduce quality settings for mobile devices
	static setUseMobileQuality(): void {
		DailyRemoteUser.qualityMap[Quality.high] = 1;
		// TODO: 1 might be fine for standard quality, test this on a few devices
		DailyRemoteUser.qualityMap[Quality.standard] = 0;
	}

	getUserIdentity(): string {
		return this.participant.user_id;
	}

	isSharingScreen(): boolean {
		return this.shareStream !== null;
	}

	// This isn't well-defined for remote participants for Daily.
	getNetworkQualityLevel(): number {
		return 4;
	}

	getUserGuid(): string {
		return this.participant.session_id;
	}

	/**
	 * Semi-private. Switches low quality mode on or off, which will adjust the quality level of displayed streams
	 * @param useLowQuality Whether to use low quality mode
	 */
	setUseLowQuality(useLowQuality: boolean): void {
		if (this._isUsingLowQualityMode === useLowQuality) {
			return;
		}
		this._isUsingLowQualityMode = useLowQuality;
		void this.setStreamQuality(this._qualityStatus);
	}

	async setStreamQuality(quality: Quality): Promise<void> {
		// If we're in low quality mode, drop the input quality by one level
		const lowQualityModeAdjustment = this._isUsingLowQualityMode
			? (Math.max(quality - 1, Quality.low) as Quality)
			: quality;
		// Map the generic Quality enum to Daily's quality levels
		// If we're on mobile, the qualityMap is adjusted to reduce quality
		const mappedQuality = DailyRemoteUser.qualityMap[lowQualityModeAdjustment];
		if (mappedQuality === undefined || mappedQuality === this._qualityStatus) {
			return;
		}
		const guid = this.getUserGuid();
		const settings = await this.call.updateReceiveSettings({
			[guid]: { video: { layer: mappedQuality } },
		});
		const layer = settings?.video?.video?.layer;
		if (layer !== undefined) {
			this._qualityStatus = layer;
		}
	}

	setStreamSubscribed(type: VideoType, subscribed: SubscriptionStatus): void {
		if (this._subscriptionStatus[type] === subscribed) {
			return;
		}
		const mappedSubscribed = DailyRemoteUser.subscriptionMap[subscribed];
		const guid = this.getUserGuid();
		const dailyVideoType = type === "camera" ? "video" : "screenVideo";
		this.call.updateParticipant(guid, { setSubscribedTracks: { [dailyVideoType]: mappedSubscribed } });
		this._subscriptionStatus[type] = subscribed;
	}
}
