/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Loading Screen used when skipping the hardware test
 * @author Jonathon Moore
 * @module Epic.VideoApp.Components.HardwareTest.SkipHardwareTestLoadingScreen
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { hardwareTestActions } from "~/state";
import { HardwareTestStatus } from "~/types";
import Loading from "../Loading/Loading";

interface IProps {
	status: HardwareTestStatus;
	displayLoadingScreen: boolean;
	onJoinClick: () => void;
}

enum TokenNames {
	joining = "Joining",
	devices = "Devices",
}

export enum SkipHardwareTestLoadingScreenTestIds {
	joining = "JoiningScreen",
	devices = "CheckingDevicesScreen",
}

const SkipHardwareTestLoadingScreen: FC<IProps> = (props: IProps) => {
	const { status, displayLoadingScreen, onJoinClick } = props;

	const dispatch = useDispatch();

	const strings = useStrings("SkipHardwareTestLoadingScreen", Object.values(TokenNames));

	const hardwareTestFailed = useCallback(() => {
		dispatch(hardwareTestActions.setSkipHardwareTest(false));
	}, [dispatch]);

	if (!displayLoadingScreen) {
		return null;
	}

	if (status === HardwareTestStatus.failed) {
		hardwareTestFailed();
		return null;
	}

	if (status === HardwareTestStatus.passed) {
		onJoinClick();
		return (
			<Loading
				loadingMessage={strings[TokenNames.joining]}
				data-testid={SkipHardwareTestLoadingScreenTestIds.joining}
			/>
		);
	}

	if (status === HardwareTestStatus.testing) {
		return (
			<Loading
				loadingMessage={strings[TokenNames.devices]}
				data-testid={SkipHardwareTestLoadingScreenTestIds.devices}
			/>
		);
	}
	return null;
};

SkipHardwareTestLoadingScreen.displayName = "SkipHardwareTestLoadingScreen";

export default SkipHardwareTestLoadingScreen;
