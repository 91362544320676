/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file New Hardware Test preview panel with video preview, toggle buttons, test audio, and name input
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.HardwarePreviewPanel
 */

import React, { FC } from "react";
import { useWindowSize } from "~/hooks";
import { ScreenWidthInflectionPoint } from "~/types";
import { useSpeakerDeviceMessage } from "../HardwareSetup/hooks/useSpeakerDeviceMessage";
import styles from "./HardwareTest.module.scss";
import InlineVideoPreview from "./InlineVideoPreview";
import ToggleRow from "./ToggleRow";

/**
 * The HardwarePreviewPanel component
 *
 * Shows the video and mic preview, toggle controls, name input, and status messages for the hardware test
 * @param props the props ;)
 */
const HardwarePreviewPanel: FC = () => {
	const { width } = useWindowSize();

	// For message panel
	const { onSpeakerTest } = useSpeakerDeviceMessage();

	return (
		<div className={styles["actionContent"]}>
			{width > ScreenWidthInflectionPoint ? (
				<div className={styles["videoAndToggles"]}>
					<InlineVideoPreview />
					<ToggleRow onSpeakerTest={onSpeakerTest} />
				</div>
			) : (
				<>
					<InlineVideoPreview />
					<div className={styles["mobileToggleRow"]}>
						<ToggleRow onSpeakerTest={onSpeakerTest} />
					</div>
				</>
			)}
		</div>
	);
};

HardwarePreviewPanel.displayName = "HardwarePreviewPanel";

export default HardwarePreviewPanel;
