/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Language loading toast message
 * @author Hanita Haller
 * @module Epic.VideoApp.Components.Header.Toasts.LanguageLoadingToast
 */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React, { FC } from "react";
import LoadingEllipsis from "~/icons/loadingEllipsis";
import { ENABLED_LOCALES } from "~/types/locale";
import { resolveClassName } from "~/utils/className";
import { I18n } from "~/utils/i18n";
import { stringFormat } from "~/utils/strings";
import styles from "./Toasts.module.scss";

/**
 * Props for language loading toast component
 */
interface IProps {
	messageArgs?: string[];
}

export enum LanguageLoadingToastTestIds {
	self = "LanguageLoadingToast",
}

/**
 * The language loading toast component
 * @param props The props ;)
 */
const LanguageLoadingToast: FC<IProps> = (props) => {
	const { messageArgs } = props;

	const localeCode = messageArgs && messageArgs[0] ? messageArgs[0] : "en-us";
	const displayName = messageArgs && messageArgs[1] ? messageArgs[1] : "en-us";

	// Using I18n.getString instead of useString so that the toast is written in the user's
	// selected language (the one that will be applied soon) instead of the current language because:
	//		1. The user may not understand this one.
	//		2. It might have an alphabet incompatible with the selected language's display name.
	const string = I18n.getString("LoadingLanguageToast_LoadingLanguage", localeCode);
	const message = stringFormat(string, displayName);

	const direction = ENABLED_LOCALES[localeCode]?.isRtl ? "rtl" : "ltr";

	const className = resolveClassName(styles, {
		toast: true,
		solid: true,
	});

	return (
		<div
			role="alert"
			className={className}
			dir={direction}
			tabIndex={0}
			data-testid={LanguageLoadingToastTestIds.self}
		>
			<label>{message}</label>
			<LoadingEllipsis height={20} width={20} aria-hidden />
		</div>
	);
};

LanguageLoadingToast.displayName = "LanguageLoadingToast";

export default React.memo(LanguageLoadingToast);
