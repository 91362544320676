/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Interface used to handle messaging between EVC and parent windows
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.FrameMessager
 */

interface IMessagePayload {
	/* eslint-disable @typescript-eslint/naming-convention */
	"Epic.Video.AuthSuccess": undefined;
	"Epic.Video.AuthFailure": IAuthFailurePayload;
	"Epic.Video.WaitingForAdmission": undefined;
	"Epic.Video.Connected": undefined;
	"Epic.Video.ParticipantUpdate": IStatusPayload;
	"Epic.Video.Disconnected": undefined;
	/* eslint-enable @typescript-eslint/naming-convention */
}

type MessageType = keyof IMessagePayload;

interface IStatusPayload {
	/** The number of participants currently in the room */
	participantCount: number;
	/** The number of EMP users currently in the room */
	providerCount: number;
}

export type IFrameAuthFailureReason = "too-early" | "visit-expired" | "unauthenticated";

interface IAuthFailurePayload {
	/** The reason that authentication failed */
	errorReason: IFrameAuthFailureReason;
}

interface IMessage<T extends MessageType> {
	type: T;
	payload?: IMessagePayload[T];
}

const TARGET_WINDOW: Window = window.parent !== window.self ? window.parent : window.opener || window.parent; // eslint-disable-line @typescript-eslint/no-unsafe-assignment

/**
 * Send a message to the parent window
 * @param type the type of message being sent
 * @param payload the message's payload
 */
function postMessage<T extends MessageType>(type: T, payload?: IMessagePayload[T]): void {
	if (!TARGET_WINDOW) {
		return;
	}
	const message: IMessage<T> = { type, payload };
	TARGET_WINDOW.postMessage(message, "*");
}

export default { postMessage };
