/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file grid menu icon
 * @author Will Cooper
 * @module Epic.VideoApp.Icons.Grid
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The Grid component
 * @param props The props ;)
 */
const Grid: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
			{title && <title>{title}</title>}
			<rect x="5.79" y="6.6" width="5.46" height="5.46" rx="0.96" />
			<rect x="13.27" y="6.6" width="5.46" height="5.46" rx="0.96" />
			<rect x="20.78" y="6.6" width="5.46" height="5.46" rx="0.96" />
			<rect x="5.79" y="13.3" width="5.46" height="5.46" rx="0.96" />
			<rect x="13.27" y="13.3" width="5.46" height="5.46" rx="0.96" />
			<rect x="20.78" y="13.3" width="5.46" height="5.46" rx="0.96" />
			<rect x="5.79" y="20" width="5.46" height="5.46" rx="0.96" />
			<rect x="13.27" y="20" width="5.46" height="5.46" rx="0.96" />
			<rect x="20.78" y="20" width="5.46" height="5.46" rx="0.96" />
		</svg>
	);
});

Grid.displayName = "Grid";

export default Grid;
