/**
 * @copyright Copyright 2020-2023 Epic Systems Corporation
 * @file Alerts Container Component
 * @author Tara Feldstein
 * @module Epic.VideoApp.Components.Alerts.Alerts
 */
import React, { FC, useEffect, useRef, useState } from "react";
import { useStrings } from "~/hooks";
import { useAlertState } from "~/state/alerts";
import { AlertType, StyleContext } from "~/types";
import { BackgroundStyleContextProvider } from "../Utilities/BackgroundStyleContext";
import styles from "./Alerts.module.scss";
import ConcurrentSessionChoiceAlert from "./ConcurrentSessionChoiceAlert";
import DiscardAlert from "./DiscardAlert";
import DisconnectionChoiceAlert from "./DisconnectionChoiceAlert";
import EndVisitAlert from "./EndVisitAlert";
import EndVisitConfirmationAlert from "./EndVisitConfirmationAlert";
import GenericAlert from "./GenericAlert";
import GenericCountdownAlert from "./GenericCountdownAlert";
import ImageCaptureRetryAlert from "./ImageCaptureRetryAlert";
import RemoveParticipantChoiceAlert from "./RemoveParticipantChoiceAlert";
import ScreenShareWarningAlert from "./ScreenShareWarningAlert";
import TimeoutChoiceAlert from "./TimeoutChoiceAlert";

enum TokenNames {
	existingConnectionAlertLabel = "ExistingConnectionAlertLabel",
	genericAlertLabel = "GenericAlertLabel",
	imageCaptureAlertLabel = "ImageCaptureAlertLabel",
	leaveCallAlertLabel = "LeaveCallAlertLabel",
	screenSharingAlertLabel = "ScreenSharingAlertLabel",
	sessionTimeoutAlertLabel = "SessionTimeoutAlertLabel",
	screenSharingWarningLabel = "ScreenSharingWarningLabel",
	removeParticipantAlertLabel = "RemoveParticipantAlertLabel",
	endVisitAlertLabel = "EndVisitAlertLabel",
	endVisitConfirmationAlertLabel = "EndVisitConfirmationAlertLabel",
}

export enum AlertsTestIds {
	self = "Alerts",
}

/**
 * The Alerts container component
 *
 * Reads alerts data from state and displays the appropriate modal, if any
 */
const Alerts: FC = () => {
	const currentAlert = useAlertState((selectors) => selectors.getCurrentAlert(), []);

	const alertDivRef = useRef<HTMLDivElement>(null);
	const [alertParentNode, setAlertParentNode] = useState<ParentNode | null>(null);

	const strings = useStrings("Alerts", Object.values(TokenNames));

	// Keep track of parent node of alertDivRef so that it's siblings can be updated for both cases:
	// - to set aria-hidden to true when alert div is present.
	// - to set aria-hidden to false when alert div is not present (i.e. while hiding an alert).
	useEffect(() => {
		if (alertDivRef.current) {
			setAlertParentNode(alertDivRef.current.parentNode);
		}
	}, [currentAlert]);

	// Update aria-hidden value to true or false for all siblings of alertDivRef
	useEffect(() => {
		const ariaHiddenValue = currentAlert ? "true" : "false";
		let sibling = alertParentNode?.firstElementChild;
		while (sibling) {
			if (sibling !== alertDivRef.current) {
				sibling.setAttribute("aria-hidden", ariaHiddenValue);
			}
			sibling = sibling.nextElementSibling;
		}
	}, [currentAlert, alertParentNode]);

	if (!currentAlert) {
		return null;
	}

	let alert = null;
	let ariaLabel: string;

	// As we add different alerts we will need to add more specific types to the alert enum
	switch (currentAlert.type) {
		case AlertType.disconnectChoice:
			alert = <DisconnectionChoiceAlert alert={currentAlert} />;
			ariaLabel = strings[TokenNames.leaveCallAlertLabel];
			break;
		case AlertType.concurrentSessionChoice:
			alert = <ConcurrentSessionChoiceAlert alert={currentAlert} />;
			ariaLabel = strings[TokenNames.existingConnectionAlertLabel];
			break;
		case AlertType.timeoutChoice:
			alert = <TimeoutChoiceAlert alert={currentAlert} />;
			ariaLabel = strings[TokenNames.sessionTimeoutAlertLabel];
			break;
		case AlertType.discardChoice:
			alert = <DiscardAlert alert={currentAlert} />;
			ariaLabel = strings[TokenNames.imageCaptureAlertLabel];
			break;
		case AlertType.screenShareWarning:
			alert = <ScreenShareWarningAlert screenShareParticipant={currentAlert.shareParticipant} />;
			ariaLabel = strings[TokenNames.screenSharingWarningLabel];
			break;
		case AlertType.removeParticipant:
			alert = <RemoveParticipantChoiceAlert alert={currentAlert} />;
			ariaLabel = strings[TokenNames.removeParticipantAlertLabel];
			break;
		case AlertType.endVisit:
			alert = <EndVisitAlert />;
			ariaLabel = strings[TokenNames.endVisitAlertLabel];
			break;
		case AlertType.endVisitConfirmation:
			alert = <EndVisitConfirmationAlert alert={currentAlert} />;
			ariaLabel = strings[TokenNames.endVisitConfirmationAlertLabel];
			break;
		case AlertType.imageCaptureRetry:
			alert = <ImageCaptureRetryAlert alert={currentAlert} />;
			ariaLabel = strings[TokenNames.imageCaptureAlertLabel];
			break;
		case AlertType.genericCountdown:
			alert = <GenericCountdownAlert alert={currentAlert} />;
			ariaLabel = strings[TokenNames.genericAlertLabel];
			break;
		default:
			alert = <GenericAlert alert={currentAlert} />;
			ariaLabel = strings[TokenNames.genericAlertLabel];
	}

	return (
		<BackgroundStyleContextProvider style={StyleContext.light}>
			<div
				data-testid={AlertsTestIds.self}
				className={styles["modalContainer"]}
				ref={alertDivRef}
				role="dialog"
				aria-modal="true"
				aria-label={ariaLabel}
				aria-describedby="header message"
			>
				{alert}
			</div>
		</BackgroundStyleContextProvider>
	);
};

Alerts.displayName = "Alerts";

export default Alerts;
