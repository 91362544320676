/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file component to own context for screen sharing
 * @author Trevor Roussel
 * @module Epic.VideoApp.Components.ScreenShareContext
 */

import React, { FC } from "react";
import { IScreenShareTrackActions, useScreenShareTrackActions } from "~/hooks/localTracks";

export const ShareContext = React.createContext<IScreenShareTrackActions>({
	requestScreenShareTrack: () => Promise.resolve(),
	removeScreenShareTrack: () => Promise.resolve(),
});

/**
 * Context component that ensures screen share hooks can be called without unmounting mid-execution
 * @param props The props ;)
 * @returns
 */
const ScreenShareContext: FC = (props) => {
	const { children } = props;
	const { requestScreenShareTrack, removeScreenShareTrack } = useScreenShareTrackActions();

	return (
		<ShareContext.Provider
			value={{
				requestScreenShareTrack,
				removeScreenShareTrack,
			}}
		>
			{children}
		</ShareContext.Provider>
	);
};

ScreenShareContext.displayName = "ScreenShareContext";

export default ScreenShareContext;
