/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Hook to determine state of HTML vs WebAudio
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Components.Participants.Audio.Hooks.UseShouldMuteHTML
 */

import { useContext, useEffect, useState } from "react";
import { RemoteAudioTrack } from "twilio-video";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";
import { useIsTrackEnabled } from "~/hooks/trackUtilities";
import { useLocalTrackState } from "~/state";
import { iOSWebAudioWorkarounds } from "~/utils/os";

export default function useShouldMuteHTML(track: RemoteAudioTrack): boolean {
	const enabled = useIsTrackEnabled(track);
	const { audioContext, overrideIOSRoute } = useContext(DeviceContext);
	const audioContextRunning = useLocalTrackState((sel) => sel.getIsAudioContextRunning(), []);

	const [shouldMuteHTML, setShouldMuteHTML] = useState(
		!enabled ? true : iOSWebAudioWorkarounds ? !!audioContext && audioContextRunning : false,
	);

	/** iOS: mute/unmute HTMLAudioElement in response to AudioContext override or state changes */
	useEffect(() => {
		// all other platforms
		if (!iOSWebAudioWorkarounds) {
			setShouldMuteHTML(!enabled);
			return;
		}
		// affected iOS
		if (!enabled) {
			setShouldMuteHTML(true);
		} else if (overrideIOSRoute) {
			setShouldMuteHTML(false);
		} else {
			setShouldMuteHTML(audioContextRunning);
		}
	}, [audioContextRunning, enabled, overrideIOSRoute]);

	return shouldMuteHTML;
}
