/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Audio Output for the Video Call
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Components.AudioStream
 */

import React, { FC, useEffect, useRef } from "react";
import { useSpeakerState } from "~/state";
import { handlePause } from "~/utils/audio";
import { IStream } from "../interfaces";

interface IProps {
	audioStream: IStream;
}

/**
 * The AudioStream component
 */
const AudioStream: FC<IProps> = (props: IProps) => {
	const { audioStream } = props;
	const audioRef = useRef<HTMLAudioElement>(null);
	const selectedSpeakerId = useSpeakerState((selectors) => selectors.getSelectedSpeakerId(), []);

	useEffect(() => {
		const audioElement = audioRef.current;
		if (!audioStream || !audioElement) {
			return;
		}

		const renderAudio = (): void => {
			audioStream.attachAudio(audioElement)?.addEventListener("pause", handlePause);
		};

		audioStream.on("audioReady", renderAudio);
		// This is only necessary if this component rerenders or if a race condition causes the component to mount after the audio is ready
		// But if this component needs to rerender, then memoization logic should be fixed because it's wasteful
		renderAudio();

		return () => {
			audioStream.off("audioReady", renderAudio);
			audioStream.detachAudio(audioElement)?.removeEventListener("pause", handlePause);
		};
	}, [audioStream]);

	useEffect(() => {
		if (selectedSpeakerId) {
			void audioRef.current?.setSinkId?.(selectedSpeakerId);
		}
	}, [selectedSpeakerId]);

	return <audio ref={audioRef} autoPlay />;
};

export default AudioStream;
