/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Display name input row for the hardware test page
 * @author Razi Rais
 * @module Epic.VideoApp.Components.HardwareSetup.DisplayNameInput
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext } from "react";
import { useStrings } from "~/hooks";
import { roomActions, useRoomState, useUserState } from "~/state";
import { DisplayContext, DisplayNameInputMaxLength } from "~/types";
import { sanitizeInput } from "~/utils/text";
import { HardwareSetupDisplayContext } from "../HardwareSetup/HardwareSetup";
import { useKeepInputInView } from "../HardwareSetup/hooks/useKeepInputInView";
import TextInput from "../Utilities/TextInput";
import styles from "./DisplayNameInput.module.scss";

interface IProps {
	/* If the control should be disabled */
	disabled?: boolean;
}

export enum DisplayNameInputTestIds {
	disabledLocaleWarning = "DisabledLocaleWarning",
	self = "DisplayNameInput",
}

/**
 * The DisplayNameInput component
 */
const DisplayNameInput: FC<IProps> = (props) => {
	const { disabled } = props;
	const displayContext = useContext(HardwareSetupDisplayContext);
	const defaultName = useRoomState((selectors) => selectors.getLocalDisplayName(), []);
	const displayNameBlocked = useUserState(
		(selectors) => selectors.getUserPermission("canNotSetDisplayName"),
		[],
	);
	const dispatch = useDispatch();

	const tokenNames = ["Label"];
	const strings = useStrings("DisplayNameInput", tokenNames);

	// try to make sure that the input doesn't get blocked by the keyboard
	useKeepInputInView();

	// make sure that the input is scrolled into view when focused
	const scrollIntoView = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
		event.target.scrollIntoView();
	}, []);

	//Update the local display name when the user edits it
	const updateDisplayName = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			const currentInput = sanitizeInput(event.currentTarget.value, DisplayNameInputMaxLength);
			event.currentTarget.value = currentInput;
			dispatch(roomActions.setLocalDisplayName(event.currentTarget.value));
		},
		[dispatch],
	);

	if (displayContext !== DisplayContext.lobby) {
		return null;
	}
	return (
		<div className={styles["displayNameInputRow"]} data-testid={DisplayNameInputTestIds.self}>
			<label className={styles["displayNameInputLabel"]} htmlFor="displayNameInput">
				{strings["Label"]}
			</label>
			{displayNameBlocked ? (
				<span>{defaultName}</span>
			) : (
				<TextInput
					id="displayNameInput"
					className={styles["displayNameInput"]}
					type="text"
					disabled={disabled}
					defaultValue={defaultName}
					onFocus={scrollIntoView}
					onInput={updateDisplayName}
					maxLength={DisplayNameInputMaxLength}
				/>
			)}
		</div>
	);
};

DisplayNameInput.displayName = "DisplayNameInput";

export default DisplayNameInput;
