/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Warning icon
 * @author Max Harkins
 * @module Epic.VideoApp.Icons.WarningYieldLight
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The Warning component, but with an inverted color theme so that the exclamation is light on a dark background
 * @param props The props ;)
 */
const WarningYieldLight: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
			{title && <title>{title}</title>}
			<path
				xmlns="http://www.w3.org/2000/svg"
				d="M 26.1 10.35 a 6.82 6.82 0 0 1 11.81 0 L 48 27.81 L 58.08 45.28 a 6.82 6.82 0 0 1 -5.91 10.23 H 11.84 A 6.82 6.82 0 0 1 5.93 45.28 L 16 27.81 Z"
				aria-hidden
			/>
			<g xmlns="http://www.w3.org/2000/svg" fill="white" aria-hidden>
				<circle cx="32" cy="43.79" r="3.48" />
				<path d="M 32 19.4 a 3.49 3.49 0 0 0 -3.48 3.48 a 3.44 3.44 0 0 0 0.1 0.84 l 1.64 13.1 h 3.48 l 1.64 -13.1 A 3.45 3.45 0 0 0 32 19.4 Z" />
			</g>
		</svg>
	);
});

WarningYieldLight.displayName = "WarningYieldLight";

export default WarningYieldLight;
