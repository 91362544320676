/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Alert that confirms whether a user wishes to re-attempt a failed image capture workflow
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Alerts.ImageCaptureRetryAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { alertActions, combinedActions, useImageState } from "~/state";
import { IImageCaptureAlert } from "~/types";
import { useSaveImageToChart } from "../ImageCapture/hooks/useSaveImageToChart";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IImageCaptureAlert;
}

export enum ImageCaptureRetryAlertTestIds {
	self = "ImageCaptureRetryAlert",
}

/**
 * Image Capture Retry Alert
 *
 * Alert to allow the Save Media web request to be attempted again
 */
const ImageCaptureRetryAlert: FC<IProps> = (props) => {
	const { alert } = props;
	const dispatch = useDispatch();
	const saveImage = useSaveImageToChart();
	const image = useImageState((selectors) => selectors.getImageData(), []);

	const confirm = useCallback(() => {
		dispatch(combinedActions.clearImageCaptureAlert());
	}, [dispatch]);

	const cancel = useCallback(() => {
		if (!alert.alertData || !image) {
			dispatch(combinedActions.clearImageCaptureAlert());
			return;
		}

		const { description, docTypeId } = alert.alertData;
		// saveImage will handle web service errors
		void saveImage(image, docTypeId, description, true);
		dispatch(alertActions.clearAlert());
	}, [alert.alertData, dispatch, image, saveImage]);

	return (
		<ChoiceAlertBase
			data-testid={ImageCaptureRetryAlertTestIds.self}
			alert={alert}
			confirm={confirm}
			cancel={cancel}
		/>
	);
};

ImageCaptureRetryAlert.displayName = "ImageCaptureRetryAlert";

export default ImageCaptureRetryAlert;
