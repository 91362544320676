/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file hook to manage active session and ensure there is only ever one per browser
 * @author Tara Feldstein
 * @module Epic.VideoApp.Hooks.UseActiveSession
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import { useCaseInsensitiveSearchParam } from "~/hooks";
import { errorPageActions } from "~/state";
import { QueryParameters, Timeout } from "~/types";
import { isInIframe } from "~/utils/browser";
import { expireCookie, getCookie, sessionStatusCookieKey, setCookie } from "~/utils/cookies";
import { hoursToMs, secondsToMs } from "~/utils/dateTime";
import { getSessionExpiration } from "~/utils/jwt";
import { useDisconnect } from "./useDisconnect";

export function useActiveSession(): void {
	const dispatch = useDispatch();

	const sessionID = useCaseInsensitiveSearchParam(QueryParameters.sessionId) ?? "";
	const inIframeParam = useCaseInsensitiveSearchParam(QueryParameters.inIframe)?.toLowerCase();
	const inIframe = useMemo(() => inIframeParam === "true" || inIframeParam === "1", [inIframeParam]);

	const disconnect = useDisconnect();
	const disconnectRef = useRef(disconnect);
	useEffect(() => {
		disconnectRef.current = disconnect;
	}, [disconnect]);

	const reviveSessionCookies = useCallback((sessionID: string) => {
		setCookie("active-session", sessionID, secondsToMs(3));
		let expirationInstant = getSessionExpiration(sessionID);
		if (!expirationInstant) {
			expirationInstant = Date.now() + hoursToMs(1);
		}

		setCookie(sessionStatusCookieKey + sessionID, "active", expirationInstant - Date.now());
	}, []);

	useEffect(() => {
		// create an active session as soon as we create a session ID
		// skip setting active session cookies in an iframe because it won't work
		if (sessionID === "" || (inIframe && isInIframe())) {
			return;
		}
		// Set this session's status to "connected"
		const statusCookieName = sessionStatusCookieKey + sessionID;
		setCookie(statusCookieName, "active", hoursToMs(1));

		// Function to set the active session cookie, with a 3s lifetime
		function refreshSessionCookie(handle: Timeout): void {
			const status = getCookie(statusCookieName);
			if (status === "active") {
				reviveSessionCookies(sessionID);
				return;
			}
			expireCookie(statusCookieName);
			expireCookie("active-session");
			//clear interval when we first kill the session, don't wait until the page unloads
			dispatch(
				errorPageActions.setErrorCard({
					title: ErrorTokenNames.activeSessionHeader,
					message: ErrorTokenNames.activeSessionMessage,
				}),
			);
			disconnectRef.current(true);
			clearInterval(handle);
		}

		const handle = setInterval(() => {
			refreshSessionCookie(handle);
		}, 1000);
		// Set the active session cookie now, and again every second
		refreshSessionCookie(handle);

		// Clear the interval when this component unloads
		return () => {
			expireCookie(statusCookieName);
			clearInterval(handle);
		};
	}, [sessionID, inIframe, reviveSessionCookies, dispatch]);
}
