/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Event definitions for helper components
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Events.HelperEvents
 */

import { IEVCEvent } from "./interfaces/evcEmitter";

/* Audio level updated events (not used by Twilio implementation) */
export interface IEVCAudioLevelEvent extends IEVCEvent<"audioLevelUpdated"> {
	volumeLevel: number;
}

export interface IEVCAudioEventMap {
	audioLevelUpdated: IEVCAudioLevelEvent;
}
