/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file warning icon
 * @author Colin Walters
 * @module Epic.VideoApp.Icons.Warning
 */

import React, { FC, memo } from "react";
import { IconProps } from ".";

/**
 * The Warning component
 * @param props The props ;)
 */
const Warning: FC<IconProps> = memo((props) => {
	const { title } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
			{title && <title>{title}</title>}
			<path
				d="M 526 150C 576 150 602 175 601 224C 600 300 600 350 575 525C 570 560 560 575 525 575C 525 575 475 575 475 575C 440 575 430 560 425 525C 400 355 400 300 400
          226C 400 175 425 150 475 150M 500 650C 527 650 552 661 571 679C 589 698 600 723 600 750C 600 805 555 850 500 850C 445 850 400 805 400 750C 400 723 411 698 429
          679C 448 661 473 650 500 650C 500 650 500 650 500 650"
			/>
		</svg>
	);
});

Warning.displayName = "Warning";

export default Warning;
