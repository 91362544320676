/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Log vendor errors to the server when failure occurs
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Hooks.UseStoreError
 */

import { useCallback } from "react";
import { useAuthState } from "~/state";
import { makeRequest } from "~/utils/request";
import { VendorError } from "~/web-core/interfaces";

/**
 * Makes a server request that logs vendor errors
 */
export function useStoreError(): (error: VendorError) => void {
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);

	const storeError = useCallback(
		(error: VendorError): void => {
			if (!JWT) {
				return;
			}
			// Log the vendor error
			// At this point an error has occurred that will likely disconnect the user
			// Free to ignore if this server request fails
			void storeVendorError(
				{
					errorCode: error.vendorErrorCode,
					errorMessage: error.message,
					updateConnectionStatus: error.updateConnectionStatus ?? false,
					isJoinRoomError: error.isJoinRoomError ?? false,
				},
				JWT,
			);
		},
		[JWT],
	);

	return storeError;
}

/**
 * Json Body for VendorError
 */
interface IVendorErrorDTO {
	errorCode: string;
	errorMessage: string | null;
	updateConnectionStatus: boolean;
	isJoinRoomError: boolean;
}

/**
 * Stores the vendor error to the server
 */
async function storeVendorError(data: IVendorErrorDTO, jwt: string): Promise<void> {
	return makeRequest("/api/VideoCall/StoreError", "POST", jwt, data);
}
