/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file drop down menu with paging
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Menu.DropDownMenus
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import ClickOutsideSection from "~/components/Utilities/ClickOutsideSection";
import Collapse from "~/components/Utilities/Collapse";
import { uiActions, useUIState } from "~/state";
import { Menu } from "~/types";
import { IMenuPage, useMenuPages } from "../hooks/useMenuPages";
import styles from "./Menu.module.scss";
import MenuPage from "./MenuPage";

export const MoreOptionsMenuWrapperId = "moreMenuToolbarWrapper";

type VisibleMenu = Menu | null;

// make sure this matches $page-transition-duration in _menuUtils.scss
const pageTransitionDurationMs = 200;

// make sure this matches $drop-down-padding * 2 in _menuUtils.scss
const dropDownMenuPadding = 10;

type DrawerState = "open" | "opening" | "closed" | "closing";

/**
 * The DropDownMenus component
 */
const DropDownMenus: FC = () => {
	/// ADD NEW PAGES IN THIS HOOK ///
	const { pageRefs, menuPages } = useMenuPages();
	/// The following code should (hopefully) not have to be updated for new pages ///

	// the currently and previously visible menus from shared state
	const [visibleMenu, prevVisibleMenu] = useUIState((selectors) => selectors.getVisibleMenuHistory(), []);

	// state used to track the menu that is transitioning in
	const [transitioningMenu, setTransitioningMenu] = useState<VisibleMenu>(null);
	// ref to the timeout to stop transitioning
	const transitionEndTimeout = useRef<number | null>(null);

	// internal value for ui state's visibleMenu to give delay between changing a menu and that taking affect
	const [internalVisibleMenu, setInternalVisibleMenu] = useState<VisibleMenu>(visibleMenu);
	// the most recently visible menu, used to keep something in view while the menu closes
	const lastVisibleMenu = useRef(internalVisibleMenu);

	// state tracking for if the menu is collapsed altogether
	const [drawerState, setDrawerState] = useState<DrawerState>(internalVisibleMenu ? "open" : "closed");

	const wrapperRef = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();

	/// INITIATING USE EFFECT ///
	// process updates to the visible menu, determine the next menu state, and start the transition in motion
	useEffect(() => {
		// clear any existing timeouts to stop the previous transition
		if (transitionEndTimeout.current) {
			clearTimeout(transitionEndTimeout.current);
			transitionEndTimeout.current = null;
		}
		if (visibleMenu && prevVisibleMenu) {
			// if this is a page transition, kick that off
			// but wait for the TRANSITIONING USE EFFECT to change the page
			setTransitioningMenu(visibleMenu);
		} else if (visibleMenu) {
			// update the visible menu and last visible menu right away if no previous menu
			setInternalVisibleMenu(visibleMenu);
			setTransitioningMenu(null);
			lastVisibleMenu.current = visibleMenu;
		} else {
			// without a visible menu, change internal visible right away and collapse
			setInternalVisibleMenu(null);
			setTransitioningMenu(null);

			// if both current and previous are nulled out, don't animate closing the menu
			setDrawerState(prevVisibleMenu ? "closing" : "closed");
		}

		if (wrapperRef.current) {
			const headerBar = document.querySelector("#" + MoreOptionsMenuWrapperId);
			if (headerBar) {
				wrapperRef.current.style.top =
					(headerBar.clientTop + headerBar.clientHeight).toString() + "px";
			}
		}
	}, [visibleMenu, prevVisibleMenu]);

	/// DRAWER OPENING USE EFFECT ///
	const shouldOpen = (drawerState === "closed" || drawerState === "closing") && !!internalVisibleMenu;
	useEffect(() => {
		// if the menu is closed/closing, but we have a visible menu, start opening the menu
		if (shouldOpen) {
			setDrawerState("opening");
		}
	}, [shouldOpen]);

	/// TRANSITIONING USE EFFECT ///
	useEffect(() => {
		// when the transition has started, update our internal copy of the visible menu
		// and create a timeout to end transitioning in 0.2s
		if (transitioningMenu) {
			setInternalVisibleMenu(transitioningMenu);
			lastVisibleMenu.current = transitioningMenu;
			transitionEndTimeout.current = setTimeout(setTransitioningMenu, pageTransitionDurationMs, null);

			return () => {
				if (transitionEndTimeout.current) {
					clearTimeout(transitionEndTimeout.current);
					transitionEndTimeout.current = null;
				}
			};
		}
	}, [transitioningMenu]);

	// if a transition has started, but the next page is not yet active, hide the next page off screen for accurate sizing
	const nextUp = visibleMenu && visibleMenu !== internalVisibleMenu ? transitioningMenu : null;

	// if the drawer is opening/closing, the next/last menu should remain visible as the dropdown opens/closes
	const collapseBackground =
		drawerState === "opening" || drawerState === "closing" ? lastVisibleMenu.current : null;

	// internalVisibleMenu is needed because CSS transitions have to go from one set height to another, but
	// menu pages can change size (devices' muted indicator, participant list growing/shrinking etc.). To
	// account for this, transitioningMenu is used to render the next page off screen while internalVisibleMenu
	// is set to the old value, setting min/max to the old height. Immediately after transitioningMenu is set,
	// the TRANSITIONING USE EFFECT will change internalVisibleMenu, min/max height for new page.
	const visibleMenuPage = (internalVisibleMenu && pageRefs.current[internalVisibleMenu]?.current) || null;
	const height =
		transitioningMenu && visibleMenuPage ? visibleMenuPage.clientHeight + dropDownMenuPadding : "unset";

	// set both min and max height to account for both growing and shrinking
	const style: React.CSSProperties = {
		minHeight: height,
		maxHeight: height,
	};

	// function to render a menu page based on current state
	const renderMenuPage = useCallback(
		(menuPage: IMenuPage) => {
			const { menu, parentMenu, ref, contents, title, dynamicTitle, backButtonLabel, alwaysRender } =
				menuPage;
			const isActive = menu === internalVisibleMenu;
			const isNextUp = menu === nextUp;
			const isCollapseBackground = menu === collapseBackground;

			// avoid rendering pages when not taking an active role in the menu's current display or an animation
			if (!isActive && !isNextUp && !isCollapseBackground && !alwaysRender) {
				return null;
			}

			return (
				<MenuPage
					key={menu}
					ref={ref}
					parentMenu={parentMenu}
					title={title}
					dynamicTitle={dynamicTitle}
					active={isActive}
					nextUp={isNextUp}
					collapseBackground={isCollapseBackground}
					backButtonLabel={backButtonLabel}
				>
					{typeof contents === "function" ? contents(isActive && !transitioningMenu) : contents}
				</MenuPage>
			);
		},
		[internalVisibleMenu, nextUp, collapseBackground, transitioningMenu],
	);

	// update the drawer state to open/closed once the Collapse animation ends
	const onAnimationFinished = useCallback((collapsed: boolean) => {
		setDrawerState(collapsed ? "closed" : "open");
	}, []);

	const onClickOutsideTrays = useCallback(() => {
		if (visibleMenu !== null) {
			dispatch(uiActions.toggleVisibleMenu({ menu: null }));
		}
	}, [dispatch, visibleMenu]);

	return (
		<ClickOutsideSection onClickOutside={onClickOutsideTrays}>
			<div style={style} className={styles["dropDown"]} ref={wrapperRef}>
				<Collapse
					collapsed={drawerState === "closed" || drawerState === "closing"}
					innerClassName={styles["wrapper"]}
					onAnimationFinished={onAnimationFinished}
				>
					{menuPages.map(renderMenuPage)}
				</Collapse>
			</div>
		</ClickOutsideSection>
	);
};

DropDownMenus.displayName = "DropDownMenus";

export default DropDownMenus;
