/**
 * @copyright Copyright 2020 - 2024 Epic Systems Corporation
 * @file Volume indicator component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.VolumeIndicator
 */

import React, { FC, useContext } from "react";
import { DisplayContext, ITestable } from "~/types";
import { resolveClassName } from "~/utils/className";
import { HardwareSetupDisplayContext } from "../HardwareSetup";
import styles from "./VolumeIndicator.module.scss";

/**
 * Props for Volume indicator Component
 */
interface IProps extends ITestable {
	volumeLevel: number;
	isTrackEnabled: boolean;
}

export enum VolumeIndicatorTestIds {
	self = "self",
	container = "Container",
	graphic = "Graphic",
}

/**
 * Volume indicator level component
 * @param props the props
 * @returns the volume indicator component
 */
const VolumeIndicator: FC<IProps> = (props) => {
	const { volumeLevel, isTrackEnabled, ...mainProps } = props;
	const inDevicesTray = useContext(HardwareSetupDisplayContext) === DisplayContext.devicesTray;

	const className = resolveClassName(styles, {
		disabledVolumeIndicatorContainer: !isTrackEnabled,
		volumeIndicatorContainer: isTrackEnabled,
		darkTheme: inDevicesTray,
	});

	return (
		<div className={className} {...mainProps}>
			<div className={styles["transparentBackground"]} data-testid={VolumeIndicatorTestIds.container} />
			{isTrackEnabled && (
				<div
					className={styles["volumeIndicator"]}
					style={{ height: `${volumeLevel * 10}%` }}
					data-testid={VolumeIndicatorTestIds.graphic}
				/>
			)}
		</div>
	);
};

export default VolumeIndicator;
