/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Timer functions
 * @author Erv Walter
 * @module Epic.VideoApp.Utils.Timer
 */

/**
 * Waits for the specified time in milliseconds
 *
 * @export
 * @param duration How many milliseconds to wait
 */
export function delay(duration: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, duration));
}
