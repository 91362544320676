/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Maintains updated data on participants who have a blank display name
 * @author Tara Feldstein
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UseNamelessParticipantData
 */
import { useCallback, useState } from "react";
import {
	useSendNamelessParticipantSyncRequest,
	useSendNamelessParticipantSyncResponse,
} from "~/hooks/messaging";
import { useRoomState } from "~/state";
import { INamelessParticipantSyncRequestPayload, INamelessParticipantSyncResponsePayload } from "~/types";
import { warn } from "~/utils/logging";

export interface INamelessParticipantData {
	timestamp: number;
	dataIsShared: boolean;
	idList: string[];
}

type RequestMessageHandler = (payload: INamelessParticipantSyncRequestPayload) => void;
type ResponseMessageHandler = (payload: INamelessParticipantSyncResponsePayload) => void;

// Makes a default nameless participants data model
function defaultNamelessParticipantData(localIdentity: string, hasName: boolean): INamelessParticipantData {
	return {
		timestamp: Date.now(),
		idList: hasName ? [] : [localIdentity],
		dataIsShared: false,
	};
}

export function useNamelessParticipantData(
	localIdentity: string,
): [INamelessParticipantData, RequestMessageHandler, ResponseMessageHandler] {
	const localDisplayName = useRoomState((selectors) => selectors.getLocalDisplayName(), []);
	const hasLocalName = localDisplayName.length > 0;
	const [localParticipantData, setLocalParticipantData] = useState<INamelessParticipantData>(
		defaultNamelessParticipantData(localIdentity, hasLocalName),
	);

	const sendNamelessParticipantSyncRequest = useSendNamelessParticipantSyncRequest();
	const sendNamelessParticipantSyncResponse = useSendNamelessParticipantSyncResponse();

	const handleSyncRequest = useCallback(
		(payload: INamelessParticipantSyncRequestPayload) => {
			const receivedData = payload.namelessParticipantData;
			// This should have been validated before it was sent
			if (receivedData.idList.length !== 0 && receivedData.idList.length !== 1) {
				warn("Received invalid list");
				return;
			}

			const newID = receivedData.idList[0];
			// See !1101 -- whenever we send a sync response, we should always update the timestamp to let the requesting user know to use our list.
			if (newID && !localParticipantData.idList.includes(newID)) {
				// Request from a new participant with no name set - update our list with the new entry
				const newParticipantData = {
					timestamp: receivedData.timestamp,
					dataIsShared: true,
					idList: localParticipantData.idList,
				};
				newParticipantData.idList.push(newID);

				// Send out the new list
				sendNamelessParticipantSyncResponse(newParticipantData);
				setLocalParticipantData(newParticipantData);
			} else {
				// Participant joined with no updates to our local list, just share the data
				// We must set the timestamp for our data to the requesters' timestamp, otherwise the requester
				// will ignore our data! (Consider if person A and B join, exchange info, then person C joins. C will
				// have a later timestamp than the localParticipant data of A & B)
				const newParticipantData = { ...localParticipantData, timestamp: receivedData.timestamp };

				// If our list was still unshared, update it
				if (!localParticipantData.dataIsShared) {
					newParticipantData.dataIsShared = true;
					setLocalParticipantData(newParticipantData);
				}

				// Send the updated data
				sendNamelessParticipantSyncResponse(newParticipantData);
			}
		},
		[localParticipantData, sendNamelessParticipantSyncResponse],
	);

	const handleSyncResponse = useCallback(
		(payload: INamelessParticipantSyncResponsePayload) => {
			const receivedData = payload.namelessParticipantData;
			const receivedIdsLength = receivedData.idList.length;
			const localIdsLength = localParticipantData.idList.length;

			// Check if the received id list is newer than ours (more entries, or same + newer timestamp)
			let listContainsNewEntries = receivedIdsLength > localIdsLength;
			if (
				receivedIdsLength === localIdsLength &&
				receivedData.timestamp > localParticipantData.timestamp
			) {
				listContainsNewEntries = true;
			}

			if (listContainsNewEntries) {
				// Update our local copy of the data
				setLocalParticipantData(receivedData);

				// If somehow we're not on the list (race condition), send out a new request
				if (!hasLocalName && !receivedData.idList.includes(localIdentity)) {
					sendNamelessParticipantSyncRequest(defaultNamelessParticipantData(localIdentity, false));
				}
			}
		},
		[
			hasLocalName,
			localIdentity,
			localParticipantData.idList.length,
			localParticipantData.timestamp,
			sendNamelessParticipantSyncRequest,
		],
	);

	return [localParticipantData, handleSyncRequest, handleSyncResponse];
}
